import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, modifiers } from '@/lib/vue';

interface IProps {
  handleImprimir: () => any;
  large?: boolean;
}

export const BtnImprimir = createComponent<IProps>({
  name: 'BtnImprimir',
  props: {
    handleImprimir: { type: Function, required: true },
    large: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const [$loading, setLoading] = useValue(false);

    async function handleClick() {
      setLoading(true);

      await props.handleImprimir();

      setLoading(false);
    }

    return () => (
      <v-btn
        outlined
        small={!props.large}
        color="secondary"
        onClick={modifiers.stop(handleClick)}
        loading={$loading.value}
        disabled={$loading.value}
      >
        <v-icon left>{MyIcons.imprimir}</v-icon>
        Imprimir
      </v-btn>
    );
  },
});
