import { apolloClient } from '@/plugins/apollo';

import AssinaturaQuery from '@/graphql/system/assinatura/assinatura.graphql';
import AssinaturasQuery from '@/graphql/system/assinatura/assinaturas.graphql';

import {
  IAssinaturaQuery,
  IAssinaturaQueryVariables,
  IAssinaturasQuery,
  IAssinaturasQueryVariables,
} from '@/typings';

export const AssinaturaGraphql = {
  query: {
    AssinaturaQuery,
    AssinaturasQuery,
  },

  async assinatura(variables: IAssinaturaQueryVariables) {
    return apolloClient
      .query<IAssinaturaQuery>({
        query: AssinaturaQuery,
        variables,
      })
      .then(({ data }) => data?.assinatura);
  },

  async assinaturas(variables: IAssinaturasQueryVariables) {
    return apolloClient
      .query<IAssinaturasQuery>({
        query: AssinaturasQuery,
        variables,
      })
      .then(({ data }) => data?.assinaturas);
  },
};
