import { createComponent } from '@/lib/vue';

interface IProps {
  title?: string | null;
  text?: string | null;
  noPadding?: boolean;
}

export const FormHeader = createComponent<IProps>({
  name: 'FormHeader',
  props: {
    title: { type: String },
    text: { type: String },
    noPadding: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      if (!props.title && !props.text) {
        return null;
      }

      return (
        <div class="flex flex-col">
          {props.title && (
            <h2
              class={[
                'text-lg tracking-wide font-normal text-coolGray-700 uppercase',
                { 'pb-2': !props.noPadding },
              ]}
            >
              {props.title}
            </h2>
          )}

          {props.text && (
            <h3 class="mb-2 text-body-sm text-coolGray-500">{props.text}</h3>
          )}
        </div>
      );
    };
  },
});

export default FormHeader;
