import { ExameModeloGraphql } from '@/graphql/profissional/exameModelo/ExameModeloGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  ExameModeloDataInput,
  IExameModeloFormModel,
  IServiceOnSuccessProfissionalArgs,
} from '@/typings';

export const ExameModeloService = {
  async getById(id: string) {
    try {
      return await ExameModeloGraphql.exameModelo({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(
    {
      profissionalId,
      model,
    }: {
      profissionalId: string;
      model: IExameModeloFormModel;
    },
    goToIndex = true,
  ) {
    try {
      const result = await ExameModeloGraphql.createExameModelo({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toExameModeloDataInput(model),
      });

      onSuccess(
        {
          result,
          msg: 'criado',
          profissionalId,
        },
        goToIndex,
      );
    } catch (error) {
      handleError(error);
    }
  },

  async delete({ id, profissionalId }: { id: string; profissionalId: string }) {
    try {
      await validateDelete({ text: 'este exame modelo' }, async () => {
        const result = await ExameModeloGraphql.deleteExameModelo({ id });

        onSuccess({
          result,
          msg: 'excluído',
          profissionalId,
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os exames modelos selecionados',
            'o exame modelo selecionado',
          ),
        },
        async () => {
          const result = await ExameModeloGraphql.deleteManyExamesModelos({
            ids,
          });

          if (result) {
            const msg = pluralize(
              ids,
              'Exames modelos excluídos',
              'Exame modelo excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    profissionalId,
    model,
  }: {
    id: string;
    profissionalId: string;
    model: IExameModeloFormModel;
  }) {
    try {
      const result = await ExameModeloGraphql.updateExameModelo({
        id: Validate.require(id, 'id'),
        data: toExameModeloDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterada',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },
};

function toExameModeloDataInput({
  nome,
  items,
}: IExameModeloFormModel): ExameModeloDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    items: items.map((v, ordem) => ({
      id: v.new ? null : v.id,
      nome: Validate.require(v.nome, 'nome'),
      codigo: Validate.require(v.codigo, 'codigo'),
      quantidade: Validate.require(v.quantidade, 'quantidade'),
      observacao: v.observacao,
      ordem,
    })),
  };
}

function onSuccess<T>(
  { result, msg, profissionalId }: IServiceOnSuccessProfissionalArgs<T>,
  goToIndex = true,
) {
  if (result) {
    if (goToIndex) {
      router.push(
        Routes.app.configProfissionais(profissionalId).prontuario.exames.index,
      );
    }

    toastSuccess(`Exame modelo ${msg} com sucesso`);
  }
}
