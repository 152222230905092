import { useState } from '@/lib/composables';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import {
  AgendamentoStatus,
  AgendamentoViewProcedimentoTableMode,
  IAgendamentoFragment,
} from '@/typings';
import { computed, Ref } from '@vue/composition-api';
import { AgendamentoViewActions } from './agendamentoViewDialog/actions';
import { AgendamentoBloqueado } from './agendamentoViewDialog/AgendamentoBloqueado';
import { AgendamentoBottomRow } from './agendamentoViewDialog/AgendamentoBottomRow';
import { AgendamentoStatusSelect } from './agendamentoViewDialog/AgendamentoStatusSelect';
import { AgendamentoViewHeader } from './agendamentoViewDialog/AgendamentoViewHeader';
import { AgendamentoViewPaciente } from './agendamentoViewDialog/AgendamentoViewPaciente';
import { AgendamentoProcedimentosTable } from './table/AgendamentoProcedimentosTable';

export const AgendamentoViewDialog = createComponent({
  name: 'AgendamentoViewDialog',
  setup(props, ctx) {
    const { $agendamento, $tableMode } = useComputeds();

    const { handleStatusChange } = useEvents($agendamento);

    return () => {
      const { bloqueado, paciente, transacoes } = $agendamento.value;

      if (bloqueado) return <AgendamentoBloqueado />;

      return (
        <div class="flex flex-col">
          <AgendamentoViewHeader />

          <div
            class="flex flex-col mb-6 overflow-y-auto"
            style="max-height: 70vh"
          >
            <div class="flex">
              <v-spacer />

              <AgendamentoStatusSelect
                value={$agendamento.value.status || AgendamentoStatus.AGENDADO}
                onInput={handleStatusChange}
              />
            </div>

            {paciente && (
              <AgendamentoViewPaciente
                onNomeClick={AgendamentoViewActions.handleNomeClick}
              />
            )}

            <AgendamentoProcedimentosTable
              agendamento={$agendamento.value}
              mode={$tableMode.value}
              transacoes={transacoes}
            />
          </div>

          <AgendamentoBottomRow />
        </div>
      );
    };
  },
});

function useComputeds() {
  const $agendamento = useState(s => s.agenda.agendamento!);

  const $hasRecebimento = computed(
    () => !!$agendamento.value.transacoes?.length,
  );

  const $tableMode = computed(() =>
    $hasRecebimento.value
      ? AgendamentoViewProcedimentoTableMode.VALORES
      : AgendamentoViewProcedimentoTableMode.NONE,
  );

  return { $agendamento, $hasRecebimento, $tableMode };
}

function useEvents($agendamento: Ref<IAgendamentoFragment>) {
  function handleStatusChange(status: AgendamentoStatus) {
    return AgendamentoService.updateStatus({
      id: $agendamento.value.id,
      status,
    });
  }

  return { handleStatusChange };
}
