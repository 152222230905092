const black = '#000';
const white = '#fff';

const cyan = {
  cyan50: '#ecfeff',
  cyan100: '#cffafe',
  cyan200: '#a5f3fc',
  cyan300: '#67e8f9',
  cyan400: '#22d3ee',
  cyan500: '#06b6d4',
  cyan600: '#0891b2',
  cyan700: '#0e7490',
  cyan800: '#155e75',
  cyan900: '#164e63',
};

const yellow = {
  yellow50: '#fffbeb',
  yellow100: '#fef3c7',
  yellow200: '#fde68a',
  yellow300: '#fcd34d',
  yellow400: '#fbbf24',
  yellow500: '#f59e0b',
  yellow600: '#d97706',
  yellow700: '#b45309',
  yellow800: '#92400e',
  yellow900: '#78350f',
};

const teal = {
  teal50: '#f0fdfa',
  teal100: '#ccfbf1',
  teal200: '#99f6e4',
  teal300: '#5eead4',
  teal400: '#2dd4bf',
  teal500: '#14b8a6',
  teal600: '#0d9488',
  teal700: '#0f766e',
  teal800: '#115e59',
  teal900: '#134e4a',
};

const green = {
  green50: '#ECFDF5',
  green100: '#D1FAE5',
  green200: '#A7F3D0',
  green300: '#6ee7b7',
  green400: '#34d399',
  green500: '#10b981',
  green600: '#059669',
  green700: '#047857',
  green800: '#065F46',
  green900: '#064E3B',
};

const lime = {
  lime300: '#bef264',
  lime400: '#a3e635',
  lime500: '#84cc16',
  lime600: '#65a30d',
  lime700: '#4d7c0f',
};

const indigo = {
  indigo300: '#a5b4fc',
  indigo400: '#818cf8',
  indigo500: '#6366f1',
  indigo600: '#4f46e5',
  indigo700: '#4338ca',
};

const blue = {
  blue50: '#eff6ff',
  blue100: '#dbeafe',
  blue200: '#bfdbfe',
  blue300: '#93c5fd',
  blue400: '#60a5fa',
  blue500: '#3b82f6',
  blue600: '#2563eb',
  blue700: '#1d4ed8',
  blue800: '#1e40af',
  blue900: '#1e3a8a',
};

const gray = {
  gray50: '#f9fafb',
  gray100: '#f3f4f6',
  gray200: '#e5e7eb',
  gray300: '#d1d5db',
  gray400: '#9ca3af',
  gray500: '#6b7280',
  gray600: '#4b5563',
  gray700: '#374151',
  gray800: '#1f2937',
  gray900: '#111827',
};

const coolGray = {
  coolGray50: '#f8fafc',
  coolGray100: '#f0f4f8',
  coolGray200: '#d9e2ec',
  coolGray300: '#bcccdc',
  coolGray400: '#829ab1',
  coolGray500: '#627d98',
  coolGray600: '#486581',
  coolGray700: '#334e68',
  coolGray800: '#243b53',
  coolGray900: '#102a43',
};

const red = {
  red50: '#fef2f2',
  red100: '#fee2e2',
  red200: '#fecaca',
  red300: '#fca5a5',
  red400: '#f87171',
  red500: '#ef4444',
  red600: '#dc2626',
  red700: '#b91c1c',
  red800: '#991b1b',
  red900: '#7f1d1d',
};

const rose = {
  rose300: '#fda4af',
  rose400: '#fb7185',
  rose500: '#f43f5e',
  rose600: '#e11d48',
  rose700: '#be123c',
};

const fuchsia = {
  fuchsia300: '#f0abfc',
  fuchsia400: '#e879f9',
  fuchsia500: '#d946ef',
  fuchsia600: '#c026d3',
  fuchsia700: '#a21caf',
};

const purple = {
  purple300: '#d8b4fe',
  purple400: '#c084fc',
  purple500: '#a855f7',
  purple600: '#9333ea',
  purple700: '#7e22ce',
};

export const MyTheme = {
  white,
  black,

  ...gray,
  ...coolGray,
  ...cyan,
  ...yellow,
  ...teal,
  ...green,
  ...lime,
  ...red,
  ...indigo,
  ...blue,
  ...rose,
  ...fuchsia,
  ...purple,

  primary: cyan.cyan700,
  accent: cyan.cyan600,
  secondary: coolGray.coolGray800,
  error: red.red600,
  success: teal.teal600,
  warning: yellow.yellow600,
  info: blue.blue600,
} as const;
