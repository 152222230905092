import CategoriaFinanceiraQuery from '@/graphql/financas/categoriaFinanceira/categoriaFinanceira.graphql';
import CategoriasFinanceirasQuery from '@/graphql/financas/categoriaFinanceira/categoriasFinanceiras.graphql';
import CreateCategoriaFinanceiraMutation from '@/graphql/financas/categoriaFinanceira/createCategoriaFinanceira.graphql';
import DeleteCategoriaFinanceiraMutation from '@/graphql/financas/categoriaFinanceira/deleteCategoriaFinanceira.graphql';
import DeleteManyCategoriasFinanceirasMutation from '@/graphql/financas/categoriaFinanceira/deleteManyCategoriasFinanceiras.graphql';
import UpdateCategoriaFinanceiraMutation from '@/graphql/financas/categoriaFinanceira/updateCategoriaFinanceira.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICategoriaFinanceiraQuery,
  ICategoriaFinanceiraQueryVariables,
  ICategoriasFinanceirasQuery,
  ICategoriasFinanceirasQueryVariables,
  ICreateCategoriaFinanceiraMutation,
  ICreateCategoriaFinanceiraMutationVariables,
  IDeleteCategoriaFinanceiraMutation,
  IDeleteCategoriaFinanceiraMutationVariables,
  IDeleteManyCategoriasFinanceirasMutation,
  IDeleteManyCategoriasFinanceirasMutationVariables,
  IUpdateCategoriaFinanceiraMutation,
  IUpdateCategoriaFinanceiraMutationVariables,
} from '@/typings';

export const CategoriaFinanceiraGraphql = {
  query: {
    CategoriaFinanceiraQuery,
    CategoriasFinanceirasQuery,
  },

  async categoriaFinanceira(variables: ICategoriaFinanceiraQueryVariables) {
    return apolloClient
      .query<ICategoriaFinanceiraQuery>({
        query: CategoriaFinanceiraQuery,
        variables,
      })
      .then(({ data }) => data?.categoriaFinanceira);
  },

  async categoriasFinanceiras(variables: ICategoriasFinanceirasQueryVariables) {
    return apolloClient
      .query<ICategoriasFinanceirasQuery>({
        query: CategoriasFinanceirasQuery,
        variables,
      })
      .then(({ data }) => data?.categoriasFinanceiras.nodes);
  },

  async createCategoriaFinanceira(
    variables: ICreateCategoriaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateCategoriaFinanceiraMutation>({
        mutation: CreateCategoriaFinanceiraMutation,
        variables,
      })
      .then(({ data }) => data?.createCategoriaFinanceira);
  },

  async deleteCategoriaFinanceira(
    variables: IDeleteCategoriaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteCategoriaFinanceiraMutation>({
        mutation: DeleteCategoriaFinanceiraMutation,
        variables,
        refetchQueries: getRefetchQueries([CategoriasFinanceirasQuery]),
      })
      .then(({ data }) => data?.deleteCategoriaFinanceira);
  },

  async deleteManyCategoriasFinanceiras(
    variables: IDeleteManyCategoriasFinanceirasMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyCategoriasFinanceirasMutation>({
        mutation: DeleteManyCategoriasFinanceirasMutation,
        variables,
        refetchQueries: getRefetchQueries([CategoriasFinanceirasQuery]),
      })
      .then(({ data }) => data?.deleteManyCategoriasFinanceiras);
  },

  async updateCategoriaFinanceira(
    variables: IUpdateCategoriaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateCategoriaFinanceiraMutation>({
        mutation: UpdateCategoriaFinanceiraMutation,
        variables,
      })
      .then(({ data }) => data?.updateCategoriaFinanceira);
  },
};
