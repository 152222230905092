import { DateRangePresets } from '@/lib/constants/dateRangePresets';
import { createMutation, makeCommit } from '@/store/utils/commit';
import {
  IAppState,
  IContaFinanceiraFragment,
  IDateRangeModel,
  IFinancasDashboardData,
  IFinancasDashboardPageQuery,
  IFinancasDashboardState,
  IFinancasDashboardTransacoesPeriodo,
  ITransacaoFragment,
} from '@/typings';
import { Module } from 'vuex';

const stateFn = (): IFinancasDashboardState => ({
  header: { periodo: null },
  contasFinanceirasSaldo: {
    loading: false,
    data: [],
  },
  transacoesPeriodo: {
    loading: false,
    data: [],
  },
  receitasPorConvenios: {
    loading: false,
    data: [],
  },
  receitasPorProcedimentos: {
    loading: false,
    data: [],
  },
  despesasPendentes: {
    loading: false,
    data: [],
  },
  receitasPendentes: {
    loading: false,
    data: [],
  },
});

export const FinancasDashboardModule: Module<
  IFinancasDashboardState,
  IAppState
> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IFinancasDashboardState>('financas/dashboard');

export class FinancasDashboardState {
  static setPeriodo(periodo: IDateRangeModel | null) {
    commit(s => {
      s.header.periodo = periodo || DateRangePresets.ultimos30dias;
    });
  }

  static setLoadingAll(loading = true) {
    commit(s => {
      s.contasFinanceirasSaldo.loading = loading;
      s.transacoesPeriodo.loading = loading;
      s.receitasPorConvenios.loading = loading;
      s.receitasPorProcedimentos.loading = loading;
      s.despesasPendentes.loading = loading;
      s.receitasPendentes.loading = loading;
    });
  }

  static loadPageQuery(res: IFinancasDashboardPageQuery) {
    FinancasDashboardState.setContasFinanceirasSaldo({
      data: res.contasFinanceirasSaldo.nodes,
    });

    FinancasDashboardState.setTransacoesPeriodo({
      data: res.transacoesPeriodo,
    });

    FinancasDashboardState.setReceitasPorConvenios({
      data: res.receitasPorConvenios,
    });

    FinancasDashboardState.setReceitasPorProcedimentos({
      data: res.receitasPorProcedimentos,
    });

    FinancasDashboardState.setDespesasPendentes({
      data: res.despesasPendentes.nodes,
    });

    FinancasDashboardState.setReceitasPendentes({
      data: res.receitasPendentes.nodes,
    });
  }

  static setContasFinanceirasSaldo({
    data,
    loading = false,
  }: ISetOptions<IContaFinanceiraFragment>) {
    commit(s => {
      const contas = data || s.contasFinanceirasSaldo.data || [];

      s.contasFinanceirasSaldo.data = [...contas].sort(
        (a, b) => b.saldo - a.saldo,
      );

      s.contasFinanceirasSaldo.loading = loading;
    });
  }

  static setTransacoesPeriodo({
    data,
    loading = false,
  }: ISetOptions<IFinancasDashboardTransacoesPeriodo>) {
    commit(s => {
      s.transacoesPeriodo.data = data || s.transacoesPeriodo.data || [];

      s.transacoesPeriodo.loading = loading;
    });
  }

  static setReceitasPorConvenios({
    data,
    loading = false,
  }: ISetOptions<IFinancasDashboardData>) {
    commit(s => {
      s.receitasPorConvenios.data = data || s.receitasPorConvenios.data || [];

      s.receitasPorConvenios.loading = loading;
    });
  }

  static setReceitasPorProcedimentos({
    data,
    loading = false,
  }: ISetOptions<IFinancasDashboardData>) {
    commit(s => {
      s.receitasPorProcedimentos.data =
        data || s.receitasPorProcedimentos.data || [];

      s.receitasPorProcedimentos.loading = loading;
    });
  }

  static setDespesasPendentes({
    data,
    loading = false,
  }: ISetOptions<ITransacaoFragment>) {
    commit(s => {
      s.despesasPendentes.data = data || s.despesasPendentes.data || [];

      s.despesasPendentes.loading = loading;
    });
  }

  static setReceitasPendentes({
    data,
    loading = false,
  }: ISetOptions<ITransacaoFragment>) {
    commit(s => {
      s.receitasPendentes.data = data || s.receitasPendentes.data || [];

      s.receitasPendentes.loading = loading;
    });
  }
}

interface ISetOptions<T> {
  data?: T[] | null;
  loading?: boolean;
}
