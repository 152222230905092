import {
  IFormInputSelect,
  IFormInputType,
  IFormSchema,
  IInputType,
  IProntuarioSecaoFragment,
  IProntuarioSecaoFragment_fields,
  ProntuarioSecaoFieldType,
} from '@/typings';
import { ProntuarioState } from '..';

export const ProntuarioTabsState = {
  init(secoes?: IProntuarioSecaoFragment[]) {
    ProntuarioState.commit(s => {
      s.tabs = (secoes || []).map(v => ({
        id: v.id,
        nome: v.nome,
        ordem: v.ordem,
        fields: v.fields || [],
        schema: fieldsToSchema(v.fields),
        model: fieldsToModel(v.fields),
      }));
    });
  },

  setModel({ tabId, model }: { tabId: string; model: any }) {
    ProntuarioState.commit(s => {
      const tab = s.tabs.find(f => f.id === tabId);

      if (tab) {
        tab.model = model;
      }
    });
  },
};

function fieldsToSchema(fields: IProntuarioSecaoFragment_fields[] | null) {
  return (fields || []).reduce<IFormSchema<any>>((obj: IFormSchema<any>, v) => {
    const type = secaoFieldTypeToInputType(v.type);

    const input: IFormInputType = {
      label: v.label,
      type: type as any,
      hideDetails: true,
      layout: {
        marginBottom: 5,
      },
    };

    if (['select', 'group_checkbox', 'radio'].includes(input.type)) {
      (input as IFormInputSelect).items = v.items || [];
    } else if (input.type === 'text') {
      input.suffix = v.suffix || undefined;
    } else if (input.type === 'number') {
      input.suffix = v.suffix || undefined;
      input.decimalPlaces = v.decimalPlaces || 0;
      input.integer = input.decimalPlaces === 0;
    }

    return {
      ...obj,
      [v.id]: input,
    };
  }, {});
}

function fieldsToModel(fields: IProntuarioSecaoFragment_fields[] | null) {
  return (fields || []).reduce((obj, v) => {
    let value: any[] | boolean | null = null;

    if (v.type === ProntuarioSecaoFieldType.CHECKBOX) {
      value = false;
    } else if (v.type === ProntuarioSecaoFieldType.GROUP_CHECKBOX) {
      value = [];
    }

    return {
      ...obj,
      [v.id]: value,
    };
  }, {});
}

function secaoFieldTypeToInputType(
  fieldType: ProntuarioSecaoFieldType,
): IInputType {
  switch (fieldType) {
    case ProntuarioSecaoFieldType.TEXT:
    default:
      return 'text';
    case ProntuarioSecaoFieldType.RICH_TEXT:
      return 'rich-text';
    case ProntuarioSecaoFieldType.SELECT:
      return 'select';
    case ProntuarioSecaoFieldType.CHECKBOX:
      return 'checkbox';
    case ProntuarioSecaoFieldType.GROUP_CHECKBOX:
      return 'group_checkbox';
    case ProntuarioSecaoFieldType.RADIO:
      return 'radio';
    case ProntuarioSecaoFieldType.DATE:
      return 'date';
    case ProntuarioSecaoFieldType.NUMBER:
      return 'number';
    case ProntuarioSecaoFieldType.TIME:
      return 'time';
  }
}
