import {
  IDashboardAniversariantesData,
  IDashboardPacienteFragment,
} from '@/typings';

export function mapDashboardAniversariantes(
  nodes: IDashboardPacienteFragment[],
): IDashboardAniversariantesData[] {
  return nodes.map(v => ({
    id: v.id,
    nome: v.nome,
    dataNascimento: v.dataNascimento,
    telefoneCasa: v.telefoneCasa,
    celular: v.celular,
    imagemUrl: v.imagem?.thumbnailUrl || v.imagem?.url || null,
  }));
}
