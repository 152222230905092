import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { handleError, Validate } from '@/lib/helpers/error';
import { AgendamentoOrderBy } from '@/typings';

export const AgendamentoGet = {
  async getById(id: string) {
    try {
      return await AgendamentoGraphql.agendamento({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async getProximoHorario({
    duracaoMinutos,
    data,
    hora,
    profissionalId,
  }: {
    duracaoMinutos: number;
    data: string;
    hora: string;
    profissionalId: string;
  }) {
    try {
      return await AgendamentoGraphql.agendamentoProximoHorario({
        input: {
          duracaoMinutos: Validate.min(duracaoMinutos, 1, 'duracaoMinutos'),
          data: Validate.date(data, 'data'),
          hora: Validate.time(hora, 'hora'),
          profissionalId: Validate.require(profissionalId, 'profissionalId'),
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  async getAgendamentosDia(profissionalId: string) {
    try {
      return await AgendamentoGraphql.agendamentos(
        {
          where: {
            data: DateHelpers.today().toSQLDate(),
            bloqueado: false,
          },
          profissionalId,
          orderBy: [AgendamentoOrderBy.horaInicial_ASC],
        },
        true,
      );
    } catch (error) {
      handleError(error);
    }
  },
};
