import { AuthGraphql } from '@/graphql/auth/AuthGraphql';
import { getAccessTokenPayload } from '@/lib/auth/auth';
import { TokenService } from '@/lib/auth/token';
import { Validate } from '@/lib/helpers/error';
import {
  authFailed,
  loadClinicaFromUser,
  setTokensAndRedirect,
} from '@/lib/services/auth/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { store } from '@/store';
import { AuthState } from '@/store/modules/auth.store';
import { AppState } from '@/store/modules/root.store';
import { getClinica } from '@/store/utils/auth';
import {
  IAuthClinicaState,
  IAuthLoginModel,
  IPasswordDialogModel,
  IUserClinica,
} from '@/typings';

export const AuthLoginService = {
  async login({ email, password }: IAuthLoginModel) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.login({
        email: Validate.require(email, 'email'),
        password: Validate.require(password, 'password'),
      });

      setTokensAndRedirect(result);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },

  logout() {
    try {
      AppState.loadingOn();

      TokenService.clearAll();

      location.reload();
    } catch (error) {
      authFailed(error);
    }
  },

  async checkPassword({ password }: IPasswordDialogModel) {
    try {
      const user = store.state.auth.user;
      if (!user) {
        return false;
      }

      const result = await AuthGraphql.login({
        email: Validate.require(user.email, 'email'),
        password: Validate.require(password, 'password'),
      });

      return !!result?.accessToken;
    } catch (error) {
      throw 'senha inválida';
    }
  },

  async loadMyUser(refetch = false) {
    try {
      Validate.require(store.state.auth.accessToken, 'accessToken');

      const user = await AuthGraphql.me(refetch);

      if (!user) {
        throw 'Invalid token';
      }

      AuthState.setUser(user);

      if (refetch) {
        // if refetch update clinica state
        loadClinicaFromUser({
          user,
          clinicaId: getClinica().id,
        });
      }

      return user;
    } catch (error) {
      authFailed(error);

      AuthLoginService.logout();
    }
  },

  async selectClinica(userClinica?: IUserClinica | null) {
    try {
      const clinicaState = Validate.require(userClinica, 'clinica');
      const accessToken = Validate.require(
        store.state.auth.accessToken,
        'accessToken',
      );

      const clinica: IAuthClinicaState = {
        ...clinicaState.clinica,
        adminClinica: clinicaState.adminClinica,
        allowSelect: false,
      };

      AuthState.setClinica(clinica);

      const tokenPayload = getAccessTokenPayload(accessToken)!;

      if (tokenPayload.clinicaId !== clinica.id) {
        await AuthLoginService.refreshToken(clinica.id);
      }

      return router.replace(Routes.app.home);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },

  async refreshToken(clinicaId?: string | null) {
    try {
      const refreshToken = Validate.require(
        store.state.auth.refreshToken,
        'refreshToken',
      );

      AppState.loadingOn();

      const result = await AuthGraphql.refreshToken({
        clinicaId,
        refreshToken,
      });
      if (result) {
        AuthState.setAuthPayload(result);

        TokenService.refreshToken.save(result.refreshToken);
      }

      return result;
    } catch (error) {
      authFailed(error);
    }
  },
};
