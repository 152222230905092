import { TissProcedimentoGraphql } from '@/graphql/tiss/tissProcedimento/TissProcedimentoGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissProcedimentoModel,
  TissProcedimentoDataInput,
} from '@/typings';

export const TissProcedimentoService = {
  async getById(id: string) {
    try {
      return await TissProcedimentoGraphql.tissProcedimento({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: ITissProcedimentoModel) {
    try {
      const result = await TissProcedimentoGraphql.createTissProcedimento({
        data: toTissProcedimentoDataInput(model),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'este procedimento próprio' }, async () => {
        const result = await TissProcedimentoGraphql.deleteTissProcedimento({
          id,
        });

        onSuccess({ result, msg: 'excluído' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os procedimentos próprios selecionados',
            'o procedimento próprio selecionado',
          ),
        },
        async () => {
          const result =
            await TissProcedimentoGraphql.deleteManyTissProcedimentos({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Procedimentos próprios excluídos',
              'Procedimento próprio excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissProcedimentoModel) {
    try {
      const result = await TissProcedimentoGraphql.updateTissProcedimento({
        id: Validate.require(id, 'id'),
        data: toTissProcedimentoDataInput(model),
      });

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },
};

function toTissProcedimentoDataInput({
  codigo,
  nome,
  tabela,
  sexo,
}: ITissProcedimentoModel): TissProcedimentoDataInput {
  return {
    codigo: Validate.require(codigo, 'codigo'),
    nome: Validate.require(nome, 'nome'),
    tabela: Validate.require(tabela, 'tabela'),
    sexo: Validate.require(sexo, 'sexo'),
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).procedimentos.index);

    toastSuccess(`Procedimento próprio ${msg} com sucesso`);
  }
}
