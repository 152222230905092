import { Tabs } from '@/components/utils/Tabs';
import { useValue } from '@/lib/composables';
import { dateRangePresetsDefaults } from '@/lib/constants/dateRangePresets';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, modifiers, watchRun } from '@/lib/vue';
import {
  IDateRangeDates,
  IDateRangeModel,
  IFormSchema,
  IFormSubmitted,
  ITab,
} from '@/typings';
import { computed, ref, SetupContext } from '@vue/composition-api';
import { FormFields } from '../fields/FormFields';

interface IProps {
  // model
  value?: IDateRangeModel | null;
  presets?: IDateRangeModel[];
  min?: Date | string | null;
  max?: Date | string | null;
}

interface IEvents {
  onInput: (value: IDateRangeModel | null) => void;
}

export const DateRange = createComponent<IProps, IEvents>({
  name: 'DateRange',
  props: {
    value: { type: Object },
    presets: { type: Array },
    min: { type: [String, Date] },
    max: { type: [String, Date] },
  },
  setup(props, ctx) {
    const [$activeTab] = useValue(0);

    const tabs: ITab[] = [
      {
        id: 'rapido',
        text: 'Rápido',
        icon: MyIcons.dateRangeRapido,
      },
      {
        id: 'periodo',
        text: 'Período',
        icon: MyIcons.dateRangePeriodo,
      },
    ];

    const { $form, $schema, $dates, handleOk, emitInput } = useDateRageForm(
      props,
      ctx,
    );

    return () => (
      <v-sheet width="320">
        <Tabs items={tabs} v-model={$activeTab.value}>
          <v-list slot="rapido" dense>
            {(props.presets || dateRangePresetsDefaults).map(preset =>
              presetTile({ props, preset, emitInput }),
            )}
          </v-list>

          <v-form
            slot="periodo"
            ref="form"
            onSubmit={modifiers.prevent(handleOk)}
          >
            <FormFields
              form={$form.value}
              schema={$schema.value}
              v-model={$dates.value}
              class="px-4 pt-6"
            />

            <div class="flex px-6 pt-2 pb-4">
              <v-spacer />

              <v-btn outlined color="primary" type="submit">
                OK
              </v-btn>
            </div>
          </v-form>
        </Tabs>
      </v-sheet>
    );
  },
});

function useDateRageForm(props: IProps, ctx: SetupContext) {
  const $form = ref<IFormSubmitted>({
    submitted: false,
  });

  const [$dates] = useValue<IDateRangeDates>({
    start: null,
    end: null,
  });

  const $minSelectedDate = computed(() => $dates.value.start || undefined);

  const $maxSelectedDate = computed(() => $dates.value.end || undefined);

  const $schema = computed<IFormSchema<IDateRangeDates>>(() => ({
    start: {
      label: 'Data inicial',
      type: 'date',
      noTitle: true,
      validations: {
        required: true,
        maxDate: $maxSelectedDate.value,
        minDate: props.min || undefined,
      },
      layout: {
        minWidth: 180,
      },
    },
    end: {
      label: 'Data final',
      type: 'date',
      noTitle: true,
      validations: {
        required: true,
        minDate: $minSelectedDate.value,
        maxDate: props.max || undefined,
      },
      layout: {
        minWidth: 180,
      },
    },
  }));

  function handleOk() {
    $form.value.submitted = true;

    const { start: startDate, end: endDate } = $dates.value;

    if (startDate && endDate) {
      emitInput({
        label: `${DateHelpers.formatDate(startDate)} - ${DateHelpers.formatDate(
          endDate,
        )}`,
        startDate,
        endDate,
      });
    }
  }

  function emitInput(value: IDateRangeModel) {
    ctx.emit('input', value);
  }

  watchRun($dates, ({ start: startDate, end: endDate }) => {
    if (startDate && endDate) {
      const startMaxDiff = DateHelpers.dateDiff(
        startDate,
        $maxSelectedDate.value,
        'days',
      );
      if (startMaxDiff && startMaxDiff?.days < 0) {
        $dates.value.start = null;
      }

      const endMinDiff = DateHelpers.dateDiff(
        $minSelectedDate.value,
        endDate,
        'days',
      );
      if (endMinDiff && endMinDiff.days < 0) {
        $dates.value.end = null;
      }
    }
  });

  watchRun(
    () => props.value,
    v => {
      $form.value.submitted = false;

      if (!v) {
        $dates.value.start = null;
        $dates.value.end = null;

        return;
      }

      $dates.value.start = v.startDate;
      $dates.value.end = v.endDate;
    },
    { deep: true },
  );

  return { $form, $schema, $dates, handleOk, emitInput };
}

function presetClasses(props: IProps, preset: IDateRangeModel) {
  return {
    'text-primary': props.value?.label === preset.label,
  };
}

const presetTile = ({
  props,
  preset,
  emitInput,
}: {
  props: IProps;
  preset: IDateRangeModel;
  emitInput: (value: IDateRangeModel) => void;
}) => (
  <v-list-item
    key={preset.label}
    class={presetClasses(props, preset)}
    onClick={() => emitInput(preset)}
  >
    <v-list-item-title>{preset.label}</v-list-item-title>
  </v-list-item>
);
