import { IAppState } from '@/typings';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { AgendaModule } from './modules/agenda.store';
import { AuthModule } from './modules/auth.store';
import { DashboardModule } from './modules/dashboard.store';
import { DialogModule } from './modules/dialog.store';
import { FinancasDashboardModule } from './modules/financas/financasDashboard.store';
import { FinancasRelatoriosModule } from './modules/financas/financasRelatorios.store';
import { FluxoCaixaModule } from './modules/financas/fluxoCaixa';
import { FlashMessagesModule } from './modules/flashMessages/flashMessages.store';
import { LayoutModule } from './modules/layout.store';
import { PacienteModule } from './modules/paciente.store';
import { ProntuarioModule } from './modules/prontuario';
import { RootModule } from './modules/root.store';
import { TissModule } from './modules/tiss.store';

Vue.use(Vuex);

export const store = new Vuex.Store({
  ...RootModule,

  modules: {
    agenda: AgendaModule,
    auth: AuthModule,
    dashboard: DashboardModule,
    dialog: DialogModule,
    financas: {
      namespaced: true,
      modules: {
        dashboard: FinancasDashboardModule,
        relatorios: FinancasRelatoriosModule,
        fluxoCaixa: FluxoCaixaModule,
      },
    },
    flashMessages: FlashMessagesModule,
    layout: LayoutModule,
    paciente: PacienteModule,
    prontuario: ProntuarioModule,
    tiss: TissModule,
  },
}) as Store<IAppState>;
