import { DateHelpers } from '@/lib/helpers/date.helpers';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment, IAtendimentoFragment_exames } from '@/typings';
import { BtnImprimir } from '../../utils/BtnImprimir';
import { TimelineBodyItem } from '../body/TimelineBodyItem';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineExames = createComponent<IProps>({
  name: 'TimelineExames',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { handleImprimir } = useEvents(props);

    return () => {
      const { exames } = props.atendimento;

      if (!exames || !exames.length) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Solicitação de Exames</h2>

          {exames.map(v => (
            <v-card outlined key={v.id} class="p-4 mt-2">
              <div class="flex items-center">
                <div class="flex text-lg text-title">
                  <span class="mr-2">{v.indicacaoClinica || 'Exames'} -</span>

                  <span>{v.tipo}</span>

                  <span class="ml-2 font-normal">
                    ({DateHelpers.formatDate(v.data)})
                  </span>
                </div>

                <BtnImprimir
                  class="mr-6"
                  handleImprimir={() => handleImprimir(v)}
                />
              </div>

              <v-divider class="my-2" />

              {v.items.map((i, idx) => (
                <div class="flex flex-col" key={i.id}>
                  {idx > 0 && <v-divider class="my-2 dashed" />}

                  <div class="flex flex-wrap">
                    <TimelineBodyItem text={i.nome} class="flex-none mr-4" />

                    <TimelineBodyItem
                      label="Quantidade"
                      text={i.quantidade.toFixed(0)}
                    />
                  </div>

                  <TimelineBodyItem
                    label="Observação"
                    text={i.observacao}
                    newLine
                    noPadding
                    class="px-4 pb-2 text-gray-700 text-body-sm"
                  />
                </div>
              ))}
            </v-card>
          ))}
        </div>
      );
    };
  },
});

function useEvents(props: IProps) {
  function handleImprimir(v: IAtendimentoFragment_exames) {
    DialogHelpers.system.options('Imprimir exame', {
      text: 'Selecione o tipo de impressão',
      options: [
        {
          text: 'Particular',
          async: true,
          handleClick: () =>
            ProntuarioService.pdf.exameParticular({
              atendimentoId: props.atendimento.id,
              exameId: v.id,
              pacienteId: props.atendimento.paciente.id,
            }),
        },
        {
          text: 'SADT',
          async: true,
          handleClick: () =>
            ProntuarioService.pdf.exameSADT({
              atendimentoId: props.atendimento.id,
              exameId: v.id,
              pacienteId: props.atendimento.paciente.id,
            }),
        },
      ],
    });
  }

  return { handleImprimir };
}
