import { AuthGraphql } from '@/graphql/auth/AuthGraphql';
import { Validate } from '@/lib/helpers/error';
import { authFailed, setTokensAndRedirect } from '@/lib/services/auth/utils';
import { AppState } from '@/store/modules/root.store';
import {
  IAuthEsqueceuSenhaPart1Model,
  ICheckEsqueceuSenhaQueryVariables,
} from '@/typings';

export const AuthEsqueceuSenhaService = {
  async checkEsqueceuSenha(variables: ICheckEsqueceuSenhaQueryVariables) {
    try {
      const result = await AuthGraphql.checkEsqueceuSenha(variables);

      return result?.valid;
    } catch (error) {
      authFailed(error);
    }
  },

  async esqueceuSenhaPart1({ email }: IAuthEsqueceuSenhaPart1Model) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.esqueceuSenhaPart1({
        email: Validate.require(email, 'email'),
      });

      AppState.loadingOff();

      return result?.ok && email;
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },

  async esqueceuSenhaPart2({
    esqueceuSenhaToken,
    password,
  }: {
    esqueceuSenhaToken: string;
    password: string;
  }) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.esqueceuSenhaPart2({
        esqueceuSenhaToken,
        newPassword: Validate.require(password, 'password'),
      });

      setTokensAndRedirect(result);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },
};
