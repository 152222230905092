import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment } from '@/typings';
import { CidsList } from '../../utils/CidsList';
import { TimelineBodyItem } from '../body/TimelineBodyItem';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineHipoteseDiagnostica = createComponent<IProps>({
  name: 'TimelineHipoteseDiagnostica',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { hipoteseDiagnostica: hd } = props.atendimento;

      if (!hd) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Hipótese diagnóstica</h2>

          <CidsList value={hd.cids} />

          <div class="flex">
            <TimelineBodyItem
              label="Observação"
              richText
              text={hd.observacao}
            />
          </div>
        </div>
      );
    };
  },
});
