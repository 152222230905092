import { DateHelpers } from '@/lib/helpers/date.helpers';
import { Validate } from '@/lib/helpers/error';

export function isRecemNascido(
  dataNascimento: string | undefined | null,
  fieldName: string,
) {
  const diff = DateHelpers.dateDiff(
    Validate.require(dataNascimento, fieldName),
    new Date(),
    'days',
  );

  if (!diff) return false;

  // Recém-nascido: do nascimento até ao 28º dia de vida.
  return diff.days <= 28;
}
