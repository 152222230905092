import { IInputItems, TissTabela } from '@/typings';

export const tissTabelas: IInputItems[] = [
  {
    label: '18 - TUSS 18 - Terminologia de Diárias Taxas e Gases Medicinais',
    value: TissTabela.TB_18,
  },
  {
    label:
      '19 - TUSS 19 - Terminologia de Materiais e Órteses Próteses e Materiais Especiais',
    value: TissTabela.TB_19,
  },
  {
    label: '20 - TUSS 20 - Terminologia de Medicamentos',
    value: TissTabela.TB_20,
  },
  {
    label: '22 - TUSS 22 - Terminologia de Procedimentos e Eventos em Saúde',
    value: TissTabela.TB_22,
  },
  {
    label: '90 - Tabela Própria Pacote Odontológico',
    value: TissTabela.TB_90,
  },
  {
    label: '98 - Tabela Própria de Pacotes',
    value: TissTabela.TB_98,
  },
  {
    label: '00 - Tabela Própria das Operadoras',
    value: TissTabela.TB_00,
  },
];
