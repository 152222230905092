import { Tabs } from '@/components/utils/Tabs';
import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { ITab } from '@/typings';
import { ImageDropzone } from './ImageDropzone';
import { MyWebcam } from './MyWebcam';

interface IProps {}

interface IEvents {
  onInput(image: File): void;
}

export const SelectImageTabs = createComponent<IProps, IEvents>({
  name: 'SelectImageTabs',
  setup(props, ctx) {
    const [$activeTab] = useValue(0);

    const tabs: ITab[] = [
      {
        id: 'webcam',
        text: 'Webcam',
        icon: MyIcons.webcam,
      },
      {
        id: 'upload',
        text: 'Upload',
        icon: MyIcons.upload,
      },
    ];

    function emitInput(image: File) {
      ctx.emit('input', image);
    }

    return () => (
      <Tabs items={tabs} v-model={$activeTab.value}>
        <MyWebcam slot="webcam" onInput={emitInput} />

        <ImageDropzone slot="upload" onInput={emitInput} class="my-4" />
      </Tabs>
    );
  },
});
