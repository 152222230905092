import { DataTable } from '@/components/datatable/DataTable';
import { InputSearch } from '@/components/form/inputs/InputSearch';
import { CircularLoading } from '@/components/loading/CircularLoading';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import {
  IDataTableHeader,
  IProntuarioModeloDialogData,
  IProntuarioModeloDialogParams,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IProps {
  params: IProntuarioModeloDialogParams;
}

export const ProntuarioModeloDialog = createComponent<IProps>({
  name: 'ProntuarioModeloDialog',
  props: {
    params: { type: Object, required: true },
  },
  setup(props, ctx) {
    const [$search] = useValue<string | null>(null);

    const { $data, $loading, headers } = useModeloQuery({
      props,
      $search,
    });

    const { handleCadastrarNovo, handleSelect } = useEvents(props, ctx);

    return () => {
      return (
        <div class="flex flex-col">
          <InputSearch placeholder="Pesquisar..." v-model={$search.value} />

          <div style="min-height: 300px;">
            <CircularLoading loading={$loading.value}>
              <DataTable
                height={300}
                hideHeaders
                noMarginBottom
                headers={headers}
                items={$data.value}
                onRowClick={handleSelect}
              />
            </CircularLoading>
          </div>

          <PageSection divider>
            <v-btn
              outlined
              color="primary"
              class="self-start"
              onClick={handleCadastrarNovo}
            >
              <v-icon left>{MyIcons.new}</v-icon>
              Cadastrar novo
            </v-btn>
          </PageSection>
        </div>
      );
    };
  },
});

function useModeloQuery({
  props,
  $search,
}: {
  props: IProps;
  $search: Ref<string | null>;
}) {
  const headers: IDataTableHeader<IProntuarioModeloDialogData>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
      sortable: false,
      class: 'cursor-pointer',
    },
  ];

  return { ...useQueryConfig(props.params.mapQueryConfig($search)), headers };
}

function useEvents(props: IProps, ctx: SetupContext) {
  function handleSelect(value: IProntuarioModeloDialogData) {
    DialogState.confirm();

    return props.params.onSelect(value);
  }

  function handleCadastrarNovo() {
    DialogState.cancel();

    return useRouter().push(props.params.cadastroRoute);
  }

  return { handleSelect, handleCadastrarNovo };
}
