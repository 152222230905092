import { ClinicaGraphql } from '@/graphql/system/clinica/ClinicaGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { appMainScrollToTop } from '@/lib/helpers/scroll';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import { getClinica } from '@/store/utils/auth';
import {
  ClinicaDataInput,
  IClinicaFormModel,
  IServiceOnSuccessArgs,
} from '@/typings';

export const ClinicaService = {
  async getById(id: string) {
    try {
      return await ClinicaGraphql.clinica({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IClinicaFormModel) {
    try {
      const result = await ClinicaGraphql.createClinica({
        data: toClinicaDataInput(model),
      });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      if (id === getClinica().id) {
        return FlashMessagesState.alertWarning({
          text:
            'Não foi possível excluir esta clínica.' +
            ' Remova a clínica do seu usuário antes de excluí-la',
        });
      }

      await validateDelete(
        {
          text: 'esta clínica',
          aviso:
            'pacientes, prontuários, agendas, dados financeiros entre outros',
          requirePassword: true,
        },
        async password => {
          const result = await ClinicaGraphql.deleteClinica({ id, password });

          onSuccess({ result, msg: 'excluída' });
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      const text = pluralize(
        ids,
        'as clínicas selecionadas',
        'a clínica selecionada',
      );

      if (ids.includes(getClinica().id)) {
        return FlashMessagesState.alertWarning({
          text:
            `Não foi possível excluir ${text}.` +
            ' Remova a clínica do seu usuário antes de excluí-la',
        });
      }

      await validateDelete(
        {
          text,
          aviso:
            'pacientes, prontuários, agendas, dados financeiros entre outros',
          requirePassword: true,
        },
        async password => {
          const result = await ClinicaGraphql.deleteManyClinicas({
            ids,
            password,
          });

          if (result) {
            const msg = pluralize(
              ids,
              'Clínicas excluídas',
              'Clínica excluída',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: IClinicaFormModel) {
    try {
      const result = await ClinicaGraphql.updateClinica({
        id: Validate.require(id, 'id'),
        data: toClinicaDataInput(model),
      });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },

  async perfilUpdate(model: IClinicaFormModel) {
    try {
      const result = await ClinicaGraphql.updateClinica({
        id: getClinica().id,
        data: toClinicaDataInput(model),
      });

      if (result) {
        toastSuccess('Clínica alterada com sucesso');

        appMainScrollToTop();
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toClinicaDataInput({
  dados: { nome, cnes, email },
  telefones,
  endereco: {
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
  },
}: IClinicaFormModel): ClinicaDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    email,
    cnes,
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
    telefones: telefones.filter(v => v.numero).map(v => v.numero!),
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configAccount.clinicas.index);

    toastSuccess(`Clínica ${msg} com sucesso`);
  }
}
