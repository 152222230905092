import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IProntuarioHipoteseDiagnosticaCidModel } from '@/typings';

interface IProps {
  value: readonly IProntuarioHipoteseDiagnosticaCidModel[];
  remove?: boolean;
}

interface IEvents {
  onRemove: (id: string) => void;
}

export const CidsList = createComponent<IProps, IEvents>({
  name: 'CidsList',
  props: {
    value: { type: Array, required: true },
    remove: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const emitRemove = (id: string) => ctx.emit('remove', id);

    return () =>
      !!props.value.length && (
        <v-card outlined class="mt-2">
          <v-list dense subheader>
            <v-subheader>CIDS</v-subheader>

            {props.value.map(v => (
              <v-list-item
                key={v.id}
                onClick={noop}
                ripple={false}
                dense
                class="cursor-auto"
              >
                <v-list-item-content class="text-body-sm">
                  {v.descricao}
                </v-list-item-content>

                {props.remove && (
                  <v-list-item-action class="my-1">
                    <v-btn
                      small
                      icon
                      onClick={() => emitRemove(v.id)}
                      aria-label="Remover"
                      data-balloon-pos="left"
                    >
                      <v-icon>{MyIcons.remove}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                )}
              </v-list-item>
            ))}
          </v-list>
        </v-card>
      );
  },
});

const noop = () => null;
