import ContasFinanceirasSaldoQuery from '@/graphql/financas/dashboard/contasFinanceirasSaldoQuery.graphql';
import DespesasPendentesQuery from '@/graphql/financas/dashboard/despesasPendentesQuery.graphql';
import FinancasDashboardPageQuery from '@/graphql/financas/dashboard/financasDashboardPageQuery.graphql';
import ReceitasPendentesQuery from '@/graphql/financas/dashboard/receitasPendentesQuery.graphql';
import ReceitasPorConveniosQuery from '@/graphql/financas/dashboard/receitasPorConveniosQuery.graphql';
import ReceitasPorProcedimentosQuery from '@/graphql/financas/dashboard/receitasPorProcedimentosQuery.graphql';
import TransacoesPeriodoQuery from '@/graphql/financas/dashboard/transacoesPeriodoQuery.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  IContasFinanceirasSaldoQuery,
  IDespesasPendentesQuery,
  IDespesasPendentesQueryVariables,
  IFinancasDashboardPageQuery,
  IFinancasDashboardPageQueryVariables,
  IReceitasPendentesQuery,
  IReceitasPendentesQueryVariables,
  IReceitasPorConveniosQuery,
  IReceitasPorConveniosQueryVariables,
  IReceitasPorProcedimentosQuery,
  IReceitasPorProcedimentosQueryVariables,
  ITransacoesPeriodoQuery,
  ITransacoesPeriodoQueryVariables,
} from '@/typings';

export const FinancasDashboardGraphql = {
  query: {
    ContasFinanceirasSaldoQuery,
    DespesasPendentesQuery,
    FinancasDashboardPageQuery,
    ReceitasPendentesQuery,
    ReceitasPorConveniosQuery,
    ReceitasPorProcedimentosQuery,
    TransacoesPeriodoQuery,
  },

  async contasFinanceirasSaldo(refetch = false) {
    return apolloClient
      .query<IContasFinanceirasSaldoQuery>({
        query: ContasFinanceirasSaldoQuery,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.contasFinanceirasSaldo.nodes);
  },

  async despesasPendentes(
    variables: IDespesasPendentesQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<IDespesasPendentesQuery, IDespesasPendentesQueryVariables>({
        query: DespesasPendentesQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.despesasPendentes.nodes);
  },

  async financasDashboardPage(
    variables: IFinancasDashboardPageQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<IFinancasDashboardPageQuery, IFinancasDashboardPageQueryVariables>(
        {
          query: FinancasDashboardPageQuery,
          variables,
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        },
      )
      .then(({ data }) => data);
  },

  async receitasPendentes(
    variables: IReceitasPendentesQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<IReceitasPendentesQuery, IReceitasPendentesQueryVariables>({
        query: ReceitasPendentesQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.receitasPendentes.nodes);
  },

  async receitasPorConvenios(
    variables: IReceitasPorConveniosQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<IReceitasPorConveniosQuery, IReceitasPorConveniosQueryVariables>({
        query: ReceitasPorConveniosQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.receitasPorConvenios);
  },

  async receitasPorProcedimentos(
    variables: IReceitasPorProcedimentosQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<
        IReceitasPorProcedimentosQuery,
        IReceitasPorProcedimentosQueryVariables
      >({
        query: ReceitasPorProcedimentosQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.receitasPorProcedimentos);
  },

  async transacoesPeriodo(
    variables: ITransacoesPeriodoQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<ITransacoesPeriodoQuery, ITransacoesPeriodoQueryVariables>({
        query: TransacoesPeriodoQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPeriodo);
  },
};
