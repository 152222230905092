import {
  IProntuarioAtestadoFormModel,
  IProntuarioExameFormModel,
  IProntuarioPrescricaoFormModel,
} from '@/typings';

export const examesFilterValid = (f: IProntuarioExameFormModel): boolean =>
  !!f.indicacaoClinica ||
  f.items.some(i => !!i.codigo || !!i.quantidade || !!i.observacao);

export const atestadosFilterValid = (
  f: IProntuarioAtestadoFormModel,
): boolean => !!f.titulo || !!f.texto;

export const prescricoesFilterValid = (
  f: IProntuarioPrescricaoFormModel,
): boolean => f.medicamentos.some(m => !!m.medicamento || !!m.quantidade);
