import { toastSuccess } from '@/lib/helpers/toast';
import { authFailed, setTokensAndRedirect } from '@/lib/services/auth/utils';

import { AuthGraphql } from '@/graphql/auth/AuthGraphql';

export const AuthConfirmarEmailService = {
  async confirmEmail(emailToken: string) {
    try {
      const result = await AuthGraphql.confirmEmail({ emailToken });

      await setTokensAndRedirect(result);

      toastSuccess('Seu email foi confirmardo com sucesso.');

      return true;
    } catch (error) {
      return false;
    }
  },

  async resendConfirmationEmail(userId?: string) {
    try {
      const result = await AuthGraphql.resendConfirmationEmail({ userId });

      return result?.ok;
    } catch (error) {
      authFailed(error);
    }
  },
};
