import CreatePacienteMutation from '@/graphql/paciente/paciente/createPaciente.graphql';
import DeleteManyPacientesMutation from '@/graphql/paciente/paciente/deleteManyPacientes.graphql';
import DeletePacienteMutation from '@/graphql/paciente/paciente/deletePaciente.graphql';
import MergePacientesMutation from '@/graphql/paciente/paciente/mergePacientes.graphql';
import PacienteQuery from '@/graphql/paciente/paciente/paciente.graphql';
import PacientesQuery from '@/graphql/paciente/paciente/pacientes.graphql';
import PacientesHomonimosQuery from '@/graphql/paciente/paciente/pacientesHomonimos.graphql';
import PacienteStatsQuery from '@/graphql/paciente/paciente/pacienteStats.graphql';
import UpdatePacienteMutation from '@/graphql/paciente/paciente/updatePaciente.graphql';
import CreatePacienteConvenioMutation from '@/graphql/paciente/pacienteConvenio/createPacienteConvenio.graphql';
import DeleteManyPacienteConveniosMutation from '@/graphql/paciente/pacienteConvenio/deleteManyPacienteConvenios.graphql';
import DeletePacienteConvenioMutation from '@/graphql/paciente/pacienteConvenio/deletePacienteConvenio.graphql';
import UpdatePacienteConvenioMutation from '@/graphql/paciente/pacienteConvenio/updatePacienteConvenio.graphql';
import CreatePacienteParenteMutation from '@/graphql/paciente/pacienteParente/createPacienteParente.graphql';
import DeleteManyPacienteParentesMutation from '@/graphql/paciente/pacienteParente/deleteManyPacienteParentes.graphql';
import DeletePacienteParenteMutation from '@/graphql/paciente/pacienteParente/deletePacienteParente.graphql';
import UpdatePacienteParenteMutation from '@/graphql/paciente/pacienteParente/updatePacienteParente.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreatePacienteConvenioMutation,
  ICreatePacienteConvenioMutationVariables,
  ICreatePacienteMutation,
  ICreatePacienteMutationVariables,
  ICreatePacienteParenteMutation,
  ICreatePacienteParenteMutationVariables,
  IDeleteManyPacienteConveniosMutation,
  IDeleteManyPacienteConveniosMutationVariables,
  IDeleteManyPacienteParentesMutation,
  IDeleteManyPacienteParentesMutationVariables,
  IDeleteManyPacientesMutation,
  IDeleteManyPacientesMutationVariables,
  IDeletePacienteConvenioMutation,
  IDeletePacienteConvenioMutationVariables,
  IDeletePacienteMutation,
  IDeletePacienteMutationVariables,
  IDeletePacienteParenteMutation,
  IDeletePacienteParenteMutationVariables,
  IMergePacientesMutation,
  IMergePacientesMutationVariables,
  IPacienteQuery,
  IPacienteQueryVariables,
  IPacientesHomonimosQuery,
  IPacientesHomonimosQueryVariables,
  IPacientesQuery,
  IPacientesQueryVariables,
  IPacienteStatsQuery,
  IPacienteStatsQueryVariables,
  IUpdatePacienteConvenioMutation,
  IUpdatePacienteConvenioMutationVariables,
  IUpdatePacienteMutation,
  IUpdatePacienteMutationVariables,
  IUpdatePacienteParenteMutation,
  IUpdatePacienteParenteMutationVariables,
} from '@/typings';
import { getRefetchQueries } from '../utils';

export const PacienteGraphql = {
  query: {
    PacienteQuery,
    PacienteStatsQuery,
    PacientesHomonimosQuery,
    PacientesQuery,
  },

  async createPaciente(variables: ICreatePacienteMutationVariables) {
    return apolloClient
      .mutate<ICreatePacienteMutation>({
        mutation: CreatePacienteMutation,
        variables,
      })
      .then(({ data }) => data?.createPaciente);
  },

  async deleteManyPacientes(variables: IDeleteManyPacientesMutationVariables) {
    return apolloClient
      .mutate<IDeleteManyPacientesMutation>({
        mutation: DeleteManyPacientesMutation,
        variables,
        refetchQueries: getRefetchQueries([PacientesQuery]),
      })
      .then(({ data }) => data?.deleteManyPacientes);
  },

  async deletePaciente(variables: IDeletePacienteMutationVariables) {
    return apolloClient
      .mutate<IDeletePacienteMutation>({
        mutation: DeletePacienteMutation,
        variables,
        refetchQueries: getRefetchQueries([PacientesQuery]),
      })
      .then(({ data }) => data?.deletePaciente);
  },

  async mergePacientes(variables: IMergePacientesMutationVariables) {
    return apolloClient
      .mutate<IMergePacientesMutation>({
        mutation: MergePacientesMutation,
        variables,
        refetchQueries: getRefetchQueries([PacientesQuery]),
      })
      .then(({ data }) => data?.mergePacientes);
  },

  async paciente(variables: IPacienteQueryVariables) {
    return apolloClient
      .query<IPacienteQuery>({
        query: PacienteQuery,
        variables,
      })
      .then(({ data }) => data?.paciente);
  },

  async pacienteStats(variables: IPacienteStatsQueryVariables) {
    return apolloClient
      .query<IPacienteStatsQuery>({
        query: PacienteStatsQuery,
        variables,
      })
      .then(({ data }) => data?.pacienteStats);
  },

  async pacientes(variables: IPacientesQueryVariables) {
    return apolloClient
      .query<IPacientesQuery>({
        query: PacientesQuery,
        variables,
      })
      .then(({ data }) => data?.pacientes);
  },

  async updatePaciente(variables: IUpdatePacienteMutationVariables) {
    return apolloClient
      .mutate<IUpdatePacienteMutation>({
        mutation: UpdatePacienteMutation,
        variables,
      })
      .then(({ data }) => data?.updatePaciente);
  },

  async createPacienteConvenio(
    variables: ICreatePacienteConvenioMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreatePacienteConvenioMutation>({
        mutation: CreatePacienteConvenioMutation,
        variables,
      })
      .then(({ data }) => data?.createPacienteConvenio);
  },

  async deleteManyPacienteConvenios(
    variables: IDeleteManyPacienteConveniosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyPacienteConveniosMutation>({
        mutation: DeleteManyPacienteConveniosMutation,
        variables,
        refetchQueries: getRefetchQueries([PacienteQuery]),
      })
      .then(({ data }) => data?.deleteManyPacienteConvenios);
  },

  async deletePacienteConvenio(
    variables: IDeletePacienteConvenioMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeletePacienteConvenioMutation>({
        mutation: DeletePacienteConvenioMutation,
        variables,
        refetchQueries: getRefetchQueries([PacienteQuery]),
      })
      .then(({ data }) => data?.deletePacienteConvenio);
  },

  async updatePacienteConvenio(
    variables: IUpdatePacienteConvenioMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdatePacienteConvenioMutation>({
        mutation: UpdatePacienteConvenioMutation,
        variables,
      })
      .then(({ data }) => data?.updatePacienteConvenio);
  },

  async createPacienteParente(
    variables: ICreatePacienteParenteMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreatePacienteParenteMutation>({
        mutation: CreatePacienteParenteMutation,
        variables,
      })
      .then(({ data }) => data?.createPacienteParente);
  },

  async deleteManyPacienteParentes(
    variables: IDeleteManyPacienteParentesMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyPacienteParentesMutation>({
        mutation: DeleteManyPacienteParentesMutation,
        variables,
        refetchQueries: getRefetchQueries([PacienteQuery]),
      })
      .then(({ data }) => data?.deleteManyPacienteParentes);
  },

  async deletePacienteParente(
    variables: IDeletePacienteParenteMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeletePacienteParenteMutation>({
        mutation: DeletePacienteParenteMutation,
        variables,
        refetchQueries: getRefetchQueries([PacienteQuery]),
      })
      .then(({ data }) => data?.deletePacienteParente);
  },

  async pacientesHomonimos(variables: IPacientesHomonimosQueryVariables) {
    return apolloClient
      .query<IPacientesHomonimosQuery>({
        query: PacientesHomonimosQuery,
        variables,
      })
      .then(({ data }) => data?.pacientes);
  },

  async updatePacienteParente(
    variables: IUpdatePacienteParenteMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdatePacienteParenteMutation>({
        mutation: UpdatePacienteParenteMutation,
        variables,
      })
      .then(({ data }) => data?.updatePacienteParente);
  },
};
