import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const PacientesPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacientesPage'
  );
const PacienteNewSidebar = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/components/sidebars/PacienteNewSidebar'
  );
const PacienteEditSidebar = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/components/sidebars/PacienteEditSidebar'
  );
const PacienteDadosPessoaisPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteDadosPessoaisPage'
  );
const PacienteDadosComplementaresPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteDadosComplementaresPage'
  );
const PacienteConveniosPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteConveniosPage'
  );
const PacienteParentesPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteParentesPage'
  );
const PacienteHistoricoConsultasPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteHistoricoConsultasPage'
  );
const PacienteLogAlteracoesPage = () =>
  import(
    /* webpackChunkName: "pacientes" */ '@/modules/pacientes/pages/PacienteLogAlteracoesPage'
  );

export const pacientesRoutes: IRouteConfig[] = routeGroup({
  prefix: 'pacientes',
  routes: [
    {
      path: '',
      component: PacientesPage,
    },

    ...routeGroup({
      prefix: 'new',
      routes: [
        {
          path: '',
          components: {
            default: PacienteDadosPessoaisPage,
            sidebar: PacienteNewSidebar,
          },
        },
        {
          path: 'dados-complementares',
          components: {
            default: PacienteDadosComplementaresPage,
            sidebar: PacienteNewSidebar,
          },
        },
        {
          path: 'convenios',
          components: {
            default: PacienteConveniosPage,
            sidebar: PacienteNewSidebar,
          },
        },
        {
          path: 'parentes',
          components: {
            default: PacienteParentesPage,
            sidebar: PacienteNewSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: ':id',
      routes: [
        {
          path: '',
          components: {
            default: PacienteDadosPessoaisPage,
            sidebar: PacienteEditSidebar,
          },
        },
        {
          path: 'dados-complementares',
          components: {
            default: PacienteDadosComplementaresPage,
            sidebar: PacienteEditSidebar,
          },
        },
        {
          path: 'convenios',
          components: {
            default: PacienteConveniosPage,
            sidebar: PacienteEditSidebar,
          },
        },
        {
          path: 'parentes',
          components: {
            default: PacienteParentesPage,
            sidebar: PacienteEditSidebar,
          },
        },
        {
          path: 'historico-consultas',
          components: {
            default: PacienteHistoricoConsultasPage,
            sidebar: PacienteEditSidebar,
          },
        },
        {
          path: 'log-alteracoes',
          components: {
            default: PacienteLogAlteracoesPage,
            sidebar: PacienteEditSidebar,
          },
        },
      ],
    }),
  ],
});
