import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { QueryGraphql } from '@/graphql/query';
import { useState, useValue } from '@/lib/composables';
import { IFormEvents } from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { ProntuarioState } from '@/store/modules/prontuario';
import { getUser } from '@/store/utils/auth';
import {
  IExameModeloFragment,
  IExamesModelosQuery,
  IExamesModelosQueryVariables,
  IForm,
  IFormSchema,
  IProntuarioExameFormModel,
  IProntuarioExameItemModel,
  IProntuarioExameModel,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProntuarioExameItems } from './prontuarioExamesForm/ProntuarioExameItems';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {
  value: IProntuarioExameFormModel;
}

interface IEvents extends IFormEvents<IProntuarioExameFormModel> {}

export const ProntuarioExamesForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioExamesForm',
  props: {
    value: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $showDelete, $schema, $form, $submitting, emitSubmit } =
      useComputeds(props, ctx);

    const {
      handleItemsInput,
      handleSalvarModelo,
      handleUsarModelo,
      handleValueInput,
    } = useEvents(props);

    return () => (
      <ProntuarioCardForm>
        <MyForm form={$form.value} noCancel noSummary onSubmit={emitSubmit}>
          <TopActionsRow
            id={props.value.id}
            showDelete={$showDelete.value}
            submitting={$submitting.value}
            handleUsarModelo={handleUsarModelo}
          />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            class="m-4"
            value={props.value}
            onInput={handleValueInput}
          />

          <ProntuarioExameItems
            form={$form.value}
            value={props.value.items}
            onInput={handleItemsInput}
          />

          <BottomActionsRow
            slot="actions"
            submitting={$submitting.value}
            handleSalvarModelo={handleSalvarModelo}
          />
        </MyForm>
      </ProntuarioCardForm>
    );
  },
});

function useComputeds(props: IProps, ctx: SetupContext) {
  const $showDelete = useState(s => s.prontuario.forms.exames.model.length > 1);

  const [$submitting] = useValue(false);

  const $form = useState<IForm<any>>(s => ({
    id: null,
    model: props.value,
    submitted: s.prontuario.forms.exames.submitted,
    loading: false,
    validationErrors: [],
    page: {
      id: null,
      submitting: false,
      error: null,
    },
  }));

  const $schema = computed<IFormSchema<IProntuarioExameModel>>(() => ({
    tipo: {
      label: null,
      type: 'radio',
      items: ConstantsHelper.prontuarioExameTipos,
      layout: {
        sm: 2,
        width: 120,
      },
    },
    data: {
      label: 'Data',
      type: 'date',
      layout: {
        sm: 3,
        width: 180,
      },
      validations: { required: true },
    },
    indicacaoClinica: {
      label: 'Indicação clínica',
      type: 'text',
      layout: {
        sm: 6,
        minWidth: 360,
      },
    },
  }));

  function emitSubmit() {
    ProntuarioState.model.exames.setSubmitted(true);

    ctx.emit('submit');
  }

  return {
    $showDelete,
    $form,
    $schema,
    $submitting,
    emitSubmit,
  };
}

function useEvents(props: IProps) {
  const profissionalId = getUser().id;

  function handleValueInput(value: IProntuarioExameFormModel) {
    ProntuarioState.model.exames.setModel(value);
  }

  function handleItemsInput(items: IProntuarioExameItemModel[]) {
    ProntuarioState.model.exames.setModel({
      ...props.value,
      items,
    });
  }

  function handleUsarModelo() {
    DialogHelpers.prontuario.modelo({
      title: 'Modelos de exames',
      params: {
        mapQueryConfig: $search => ({
          query: QueryGraphql.ExamesModelosQuery,
          variables(): IExamesModelosQueryVariables {
            return {
              where: { nome_contains: $search.value },
              profissionalId,
            };
          },
          mapData(result: IExamesModelosQuery) {
            return result?.examesModelos.nodes;
          },
        }),
        onSelect(v: IExameModeloFragment) {
          ProntuarioState.model.exames.setModel({
            ...props.value,
            items: v.items || [],
          });
        },
        cadastroRoute:
          Routes.app.configProfissionais(profissionalId).prontuario.exames.new,
      },
    });
  }

  const { handleSalvarModelo } = useSalvarModelo(props);

  return {
    handleItemsInput,
    handleSalvarModelo,
    handleUsarModelo,
    handleValueInput,
  };
}

function useSalvarModelo(props: IProps) {
  function validateForm(): boolean {
    ProntuarioState.model.exames.setSubmitted(true);

    const { data, items } = props.value;

    return !!data && items.every(i => !!i.codigo && !!i.quantidade);
  }

  function handleSalvarModelo() {
    if (validateForm()) {
      ProntuarioService.modelo.saveExame(props.value.items);
    }
  }

  return { handleSalvarModelo };
}

const TopActionsRow = createComponent({
  name: 'ProntuarioExamesFormTopActionsRow',
  props: {
    id: { type: String, required: true },
    showDelete: { type: Boolean, required: true },
    submitting: { type: Boolean, required: true },
    handleUsarModelo: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center px-4 pt-4 pb-2">
        <v-btn
          outlined
          color="secondary"
          disabled={props.submitting}
          onClick={props.handleUsarModelo}
        >
          <v-icon left>{MyIcons.document}</v-icon>
          Usar modelo
        </v-btn>

        <v-spacer />

        {props.showDelete && (
          <v-btn
            color="secondary"
            icon
            small
            class="ml-3"
            aria-label="Remover"
            data-balloon-pos="up"
            onClick={() => ProntuarioState.model.exames.remove(props.id)}
          >
            <v-icon>{MyIcons.remove}</v-icon>
          </v-btn>
        )}
      </div>
    );
  },
});

const BottomActionsRow = createComponent({
  name: 'ProntuarioExamesFormBottomActionsRow',
  props: {
    submitting: { type: Boolean, required: true },
    handleSalvarModelo: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center">
        <v-btn
          outlined
          color="primary"
          disabled={props.submitting}
          onClick={props.handleSalvarModelo}
        >
          <v-icon left>{MyIcons.saveAll}</v-icon>
          Salvar como modelo
        </v-btn>

        <v-spacer />

        <v-btn
          type="submit"
          color="primary"
          loading={props.submitting}
          disabled={props.submitting}
        >
          Salvar
        </v-btn>
      </div>
    );
  },
});
