import {
  checkIsAccessTokenValidOrUndefined,
  getAccessToken,
} from '@/lib/auth/auth';
import { ApolloHelpers } from '@/lib/helpers/apollo.helpers';
import { GRAPHQL_URI, isDev } from '@/lib/helpers/env';
import { AuthLoginService } from '@/lib/services';
import {
  fetchAccessToken,
  handleAccessTokenResponse,
} from '@/lib/services/auth/utils';
import { AuthState } from '@/store/modules/auth.store';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

const tokenRefreshLink: any = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: checkIsAccessTokenValidOrUndefined,
  fetchAccessToken,
  handleResponse: () => response => handleAccessTokenResponse(response),
  handleFetch: accessToken => {
    AuthState.setAccessToken(accessToken);
  },
  handleError: err => {
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);

    AuthLoginService.logout();
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken();

  if (token) {
    operation.setContext(() => ({
      headers: {
        Authorization: token,
      },
    }));
  }

  return forward?.(operation) || null;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    // To avoid cache lost error
    CategoriaFinanceira: ApolloHelpers.cacheArrayMerge('children'),
    Agendamento: ApolloHelpers.cacheArrayMerge('procedimentos'),
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    tokenRefreshLink,
    authLink,
    new RetryLink(),
    errorLink,
    new BatchHttpLink({ uri: GRAPHQL_URI }),
  ]),
  cache,
  connectToDevTools: isDev,
});

export async function resetApollo() {
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // Potential errors
  }
}
