import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { handleError, Validate } from '@/lib/helpers/error';
import { AgendamentoCancelamentoMotivo } from '@/typings';
import { agendamentoOnSuccess } from './agendamento.service';

export const AgendamentoDelete = {
  async delete({
    id,
    profissionalId,
    hasRecorrencia,
    bloqueado,
    dataFinal,
  }: {
    id: string;
    profissionalId: string;
    hasRecorrencia: boolean;
    bloqueado: boolean;
    dataFinal: string;
  }) {
    const { confirmed, deleteMode, motivo } =
      await DialogHelpers.agendamento.delete({ bloqueado, hasRecorrencia });

    if (!confirmed) return;

    switch (deleteMode) {
      case 'all':
        return AgendamentoDelete.deleteAll({
          id,
          profissionalId,
          bloqueado,
          motivo,
        });

      case 'until':
        return AgendamentoDelete.deleteUntil({
          id,
          profissionalId,
          bloqueado,
          dataFinal,
          motivo,
        });

      case 'one':
      default:
        return AgendamentoDelete.deleteOne({ id, profissionalId, motivo });
    }
  },

  async deleteOne({
    id,
    profissionalId,
    motivo,
  }: {
    id: string;
    profissionalId: string;
    motivo: AgendamentoCancelamentoMotivo | null;
  }) {
    try {
      const result = await AgendamentoGraphql.deleteAgendamento({ id, motivo });

      agendamentoOnSuccess({
        result,
        msg: 'excluído',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteAll({
    id,
    motivo,
    bloqueado,
    profissionalId,
  }: {
    id: string;
    bloqueado: boolean;
    motivo: AgendamentoCancelamentoMotivo | null;
    profissionalId: string;
  }) {
    try {
      if (!bloqueado) {
        Validate.require(motivo, 'motivo');
      }

      const result = await AgendamentoGraphql.deleteAgendamentoRecorrencia({
        input: {
          agendamentoId: id,
          motivo,
          all: true,
        },
      });

      agendamentoOnSuccess({
        result,
        msg: 'Agendamentos excluídos com sucesso',
        full: true,
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteUntil({
    id,
    bloqueado,
    motivo,
    dataFinal,
    profissionalId,
  }: {
    id: string;
    bloqueado: boolean;
    motivo: AgendamentoCancelamentoMotivo | null;
    dataFinal: string;
    profissionalId: string;
  }) {
    if (!bloqueado) {
      Validate.require(motivo, 'motivo');
    }

    try {
      const result = await AgendamentoGraphql.deleteAgendamentoRecorrencia({
        input: {
          agendamentoId: id,
          motivo,
          dataFinal,
        },
      });

      agendamentoOnSuccess({
        result,
        msg: 'Agendamentos excluídos com sucesso',
        full: true,
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },
};
