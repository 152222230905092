import { IFormPage } from '@/typings';
import { reactive, SetupContext } from '@vue/composition-api';
import { useRoute } from '../utils/useRouter';

export function useFormPage<TValue>({
  ctx,
  submitCallback,
  id,
}: {
  ctx: SetupContext;
  submitCallback: (model: TValue) => Promise<any>;
  id?: string | null;
}) {
  const page: IFormPage = reactive<IFormPage>({
    id: id || useRoute().params.id || null,
    submitting: false,
    error: null,
  });

  async function handleSubmit(model: TValue) {
    try {
      page.error = null;

      page.submitting = true;

      await submitCallback(model);
    } catch (error) {
      page.error = error;
    }

    page.submitting = false;
  }

  const isEdit = !!page.id;

  return { page, isEdit, handleSubmit };
}
