import { getPacienteConvenio } from '@/lib/helpers/models/paciente';
import { examesFilterValid } from '@/lib/helpers/models/prontuario/atendimento';
import { uuid } from '@/lib/helpers/uuid';
import {
  AtendimentoExameTipo,
  IProntuarioExameFormModel,
  IProntuarioState,
} from '@/typings';
import { ProntuarioState } from '..';
import { todayDate } from '../utils';

export const ProntuarioModelExamesState = {
  add() {
    ProntuarioState.commit(s => {
      s.forms.exames.model.push({
        id: uuid(),
        data: todayDate(),
        indicacaoClinica: null,
        tipo:
          s.paciente && getPacienteConvenio(s.paciente.data)
            ? AtendimentoExameTipo.SADT
            : AtendimentoExameTipo.PARTICULAR,
        items: [],
      });

      setHasError(s);
    });
  },

  setModel({ id, ...model }: IProntuarioExameFormModel) {
    ProntuarioState.commit(s => {
      s.forms.exames.model = s.forms.exames.model.map(v => {
        if (v.id !== id) {
          return v;
        }

        return {
          id,
          ...model,
        };
      });

      setHasError(s);
    });
  },

  remove(id: string) {
    ProntuarioState.commit(s => {
      s.forms.exames.model = s.forms.exames.model.filter(f => f.id !== id);

      setHasError(s);
    });
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.forms.exames.submitted = submitted;
    });
  },
};

function setHasError(s: IProntuarioState) {
  s.forms.exames.hasError = checkHasError(s);

  if (s.atendimento.submitted && s.forms.exames.hasError) {
    s.forms.exames.submitted = true;
  }
}

function checkHasError(s: IProntuarioState): boolean {
  const { model } = s.forms.exames;

  const isValid = model
    .filter(examesFilterValid)
    .every(
      v =>
        !!v.data &&
        v.items
          .filter(f => !!f.codigo || !!f.quantidade || !!f.observacao)
          .every(e => !!e.nome && !!e.quantidade) &&
        v.items.some(f => !!f.codigo || !!f.quantidade || !!f.observacao),
    );

  return !isValid;
}
