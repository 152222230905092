import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { AppState } from '@/store/modules/root.store';

export default createComponent({
  name: 'DevPage',
  setup(props, ctx) {
    AppState.loadingOff();

    return () => <MyPage title="Development" class="p-4"></MyPage>;
  },
});
