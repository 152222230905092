import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { useState } from '@/lib/composables';
import { IFormEvents } from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import {
  IForm,
  IFormSchema,
  IProntuarioHipoteseDiagnosticaFormModel,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { CidsInput } from './prontuarioHipoteseDiagnosticaForm/CidsInput';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {}

interface IEvents
  extends IFormEvents<IProntuarioHipoteseDiagnosticaFormModel> {}

export const ProntuarioHipoteseDiagnosticaForm = createComponent<
  IProps,
  IEvents
>({
  name: 'ProntuarioHipoteseDiagnosticaForm',
  setup(props, ctx) {
    const { $model, $form, $schema, emitSubmit } = useComputeds(ctx);

    const { handleObservacaoChange } = useEvents();

    return () => (
      <ProntuarioCardForm>
        <MyForm form={$form.value} noCancel noSummary onSubmit={emitSubmit}>
          <CidsInput />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            class="m-4"
            value={$model.value}
            onInput={handleObservacaoChange}
          />
        </MyForm>
      </ProntuarioCardForm>
    );
  },
});

function useComputeds(ctx: SetupContext) {
  const $model = useState(s => s.prontuario.forms.hipoteseDiagnostica.model);

  const $form = useState<IForm<any>>(s => ({
    id: null,
    model: $model.value,
    submitted: s.prontuario.forms.hipoteseDiagnostica.submitted,
    loading: false,
    validationErrors: [],
    page: {
      id: null,
      submitting: false,
      error: null,
    },
  }));

  const $schema = computed<
    IFormSchema<Omit<IProntuarioHipoteseDiagnosticaFormModel, 'cids'>>
  >(() => ({
    observacao: {
      label: 'Observação',
      type: 'rich-text',
    },
  }));

  function emitSubmit() {
    ProntuarioState.model.hipoteseDiagnostica.setSubmitted(true);

    ctx.emit('submit');
  }

  return {
    $model,
    $form,
    $schema,
    emitSubmit,
  };
}

function useEvents() {
  function handleObservacaoChange({
    observacao,
  }: IProntuarioHipoteseDiagnosticaFormModel) {
    ProntuarioState.model.hipoteseDiagnostica.setObservacao(observacao);
  }

  return { handleObservacaoChange };
}
