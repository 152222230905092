import { TissGuiaConsultaGraphql } from '@/graphql/tiss/tissGuiaConsulta/TissGuiaConsultaGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { TissSimNao } from '@/lib/constants/helper/tiss/simNao';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissGuiaConsultaModel,
  TissGuiaConsultaDataInput,
  TissTipoDocumento,
  TissTipoPessoa,
} from '@/typings';
import { isRecemNascido } from './utils';

export const TissGuiaConsultaService = {
  async getById(id: string) {
    try {
      return await TissGuiaConsultaGraphql.tissGuiaConsulta({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    model: ITissGuiaConsultaModel;
    profissionalId: string;
  }) {
    try {
      const result = await TissGuiaConsultaGraphql.createTissGuiaConsulta({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: mapTissGuiaConsultaDataInput(model),
      });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'esta guia de consulta' }, async () => {
        const result = await TissGuiaConsultaGraphql.deleteTissGuiaConsulta({
          id,
        });

        onSuccess({ result, msg: 'excluída' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissGuiaConsultaModel) {
    try {
      const result = await TissGuiaConsultaGraphql.updateTissGuiaConsulta({
        id: Validate.require(id, 'id'),
        data: mapTissGuiaConsultaDataInput(model),
      });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function mapTissGuiaConsultaDataInput({
  geral,
  paciente,
  contratado,
  atendimento,
  procedimentos,
}: ITissGuiaConsultaModel): TissGuiaConsultaDataInput {
  const contratadoDocumento = Validate.require(
    contratado.documento,
    'contratado.documento',
  );

  return {
    convenioId: Validate.require(geral.convenioId, 'convenioId'),
    registroANS: Validate.require(geral.registroANS, 'geral.registroANS'),
    numeroGuiaPrestador: Validate.require(
      geral.numeroGuiaPrestador,
      'geral.numeroGuiaPrestador',
    ),
    numeroGuiaOperadora: geral.numeroGuiaOperadora,
    // ausenciaCodValidacao?: string | null;
    // codValidacao?: string | null;
    pacienteId: Validate.require(paciente.pacienteId, 'pacienteId'),
    beneficiarioNumeroCarteira: Validate.require(
      paciente.numeroCarteira,
      'paciente.numeroCarteira',
    ),
    pacienteDataNascimento: Validate.require(
      paciente.dataNascimento,
      'paciente.dataNascimento',
    ),
    beneficiarioAtendimentoRN: isRecemNascido(
      paciente.dataNascimento,
      'paciente.dataNascimento',
    )
      ? TissSimNao.SIM
      : TissSimNao.NAO,
    beneficiarioNome: Validate.require(paciente.nome, 'paciente.nome'),
    beneficiarioNumeroCNS: paciente.cns,
    contratadoTipoDocumento: Validate.require(
      contratado.tipoDocumento,
      'contratado.tipoDocumento',
    ),
    contratadoTipoPessoa: Validate.require(
      contratado.tipoPessoa,
      'contratado.tipoPessoa',
    ),
    contratadoCodigoPrestadorNaOperadora:
      contratado.tipoDocumento === TissTipoDocumento.CODIGO_NA_OPERADORA
        ? contratadoDocumento
        : null,
    contratadoCpf:
      contratado.tipoDocumento === TissTipoDocumento.CPF
        ? contratadoDocumento
        : null,
    contratadoCnpj:
      contratado.tipoDocumento === TissTipoDocumento.CNPJ
        ? contratadoDocumento
        : null,
    contratadoNome: Validate.require(
      contratado.tipoPessoa === TissTipoPessoa.JURIDICA
        ? contratado.contratadoNome
        : contratado.profissionalNome,
      'contratadoNome',
    ),
    contratadoCNES: Validate.require(contratado.cnes, 'contratado.cnes'),
    profissionalNome:
      contratado.tipoPessoa === TissTipoPessoa.JURIDICA
        ? contratado.profissionalNome
        : null,
    profissionalConselho: Validate.require(
      contratado.conselho,
      'contratado.conselho',
    ),
    profissionalNumeroConselho: Validate.require(
      contratado.numeroConselho,
      'contratado.numeroConselho',
    ),
    profissionalUF: Validate.require(contratado.uf, 'contratado.uf'),
    profissionalCBOS: Validate.require(contratado.cbos, 'contratado.cbos'),
    atendimentoData: Validate.require(atendimento.data, 'atendimento.data'),
    indicacaoAcidente: Validate.require(
      atendimento.indicacaoAcidente,
      'atendimento.indicacaoAcidente',
    ),
    atendimentoTipoConsulta: Validate.require(
      atendimento.tipoConsulta,
      'atendimento.tipoConsulta',
    ),
    procedimentoCodigoTabela: procedimentos[0].tabela!.slice(3, 5), // "TB_22".slice(3,5) => "22"
    procedimentoCodigo: procedimentos[0].codigo!,
    procedimentoValor: Validate.require(
      procedimentos[0].valorTotal,
      'procedimentos[0].valorTotal',
    ),
    observacao: atendimento.observacao,
    agendamentoId: geral.agendamentoId,
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).guiasConsultas.index);

    toastSuccess(`Guia de consulta ${msg} com sucesso`);
  }
}
