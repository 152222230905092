import { IInputItems } from '@/typings';

export const ausenciaCodValidacao: IInputItems[] = [
  { label: '01 - Beneficiário internado', value: '01' },
  {
    label: '02 - Beneficiário em situação de urgência/emergência',
    value: '02',
  },
  { label: '03 - Intermitência/Instabilidade de sistemas', value: '03' },
  {
    label: '04 - Beneficiário se negou a transmitir o número do token',
    value: '04',
  },
  { label: '05 - Beneficiário em coleta domiciliar', value: '05' },
  {
    label: '06 - Material para exames enviado ao prestador por terceiros',
    value: '06',
  },
  { label: '07 - Beneficiário não possui celular', value: '07' },
];
