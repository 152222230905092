import { IRootState } from '@/typings';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): IRootState => ({
  loading: true,
});

export const RootModule = {
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IRootState>('');

export class AppState {
  static loadingOn() {
    commit(s => {
      s.loading = true;
    });
  }

  static loadingOff() {
    commit(s => {
      s.loading = false;
    });
  }
}
