import { FileHelpers } from '@/lib/helpers/file.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { MyTheme } from '@/lib/helpers/MyTheme';

interface IProps {}

interface IEvents {
  onInput(image: File): void;
}

export const ImageDropzone = createComponent<IProps, IEvents>({
  name: 'ImageDropzone',
  setup(props, ctx) {
    function handleChange(e: Event) {
      const inputEl = e.target as HTMLInputElement;

      const file = inputEl.files?.[0] || null;

      const maxAllowedSize = 10 * 1024 * 1024; // 10 MB

      if (
        !file ||
        !FileHelpers.isImage(file.type) ||
        file.size > maxAllowedSize ||
        typeof FileReader !== 'function' // FileReader API not supported
      ) {
        return;
      }

      emitInput(file);
    }

    function emitInput(image: File) {
      ctx.emit('input', image);
    }

    return () => (
      <div
        class="relative flex items-center text-center"
        style={{
          backgroundColor: MyTheme.coolGray100,
          border: `2px dashed ${MyTheme.coolGray300}`,
          borderRadius: '8px',
          height: '280px',
        }}
      >
        <div class="flex flex-col">
          <div class="text-title text-coolGray-700">
            Arraste uma imagem para cá
          </div>

          <div class="mb-2 font-medium text-coolGray-600">ou</div>

          <v-btn outlined color="secondary" class="mb-2 mx-auto">
            <v-icon left>{MyIcons.folderOpen}</v-icon>
            Selecionar imagem
          </v-btn>

          <div class="text-small text-coolGray-500">
            A imagem deve ter no máximo 10MB
          </div>
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handleChange}
          class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          title=""
        />
      </div>
    );
  },
});
