import { recepcionistaOrOwnerGuard } from '@/routes/guards/recepcionistaOrOwner.guard';
import { routeGroup, sidebarAgenda } from '@/routes/utils';
import { IRouteConfig } from '@/typings';

const AgendaPage = () =>
  import(/* webpackChunkName: "agenda" */ '@/modules/agenda/pages/AgendaPage');
const AgendamentoFormPage = () =>
  import(
    /* webpackChunkName: "agenda" */ '@/modules/agenda/pages/AgendamentoFormPage'
  );
const AgendamentoRecebimentoPage = () =>
  import(
    /* webpackChunkName: "agenda" */ '@/modules/agenda/pages/AgendamentoRecebimentoPage'
  );
const ListaEsperaPage = () =>
  import(
    /* webpackChunkName: "agenda" */ '@/modules/agenda/pages/ListaEsperaPage'
  );

export const agendaRoutes: IRouteConfig[] = routeGroup({
  prefix: 'agenda/:profissionalId',
  beforeEnter: recepcionistaOrOwnerGuard,
  routes: [
    {
      path: '',
      components: {
        default: AgendaPage,
        sidebar: sidebarAgenda,
      },
    },

    ...routeGroup({
      prefix: 'agendamento',
      routes: [
        {
          path: 'new',
          components: {
            default: AgendamentoFormPage,
            sidebar: sidebarAgenda,
          },
        },
        {
          path: ':id',
          components: {
            default: AgendamentoFormPage,
            sidebar: sidebarAgenda,
          },
        },
        {
          path: ':id/recebimento',
          components: {
            default: AgendamentoRecebimentoPage,
            sidebar: sidebarAgenda,
          },
        },
      ],
    }),

    {
      path: 'lista-espera',
      components: {
        default: ListaEsperaPage,
        sidebar: sidebarAgenda,
      },
    },
  ],
});
