import { routeGroup } from '@/routes/utils';
import { IRouteConfig } from '@/typings';
import { recepcionistaOrOwnerGuard } from '@/routes/guards/recepcionistaOrOwner.guard';

const TissSidebar = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/components/TissSidebar'
  );
const TissGuiasConsultasPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaConsulta/TissGuiasConsultasPage'
  );
const TissGuiaConsultaFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaConsulta/TissGuiaConsultaFormPage'
  );
const TissGuiaHonorariosFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaHonorarios/TissGuiaHonorariosFormPage'
  );
const TissGuiasHonorariosPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaHonorarios/TissGuiasHonorariosPage'
  );
const TissGuiaOdontologicaFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaOdontologica/TissGuiaOdontologicaFormPage'
  );
const TissGuiasOdontologicasPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaOdontologica/TissGuiasOdontologicasPage'
  );
const TissGuiaSpSadtFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaSpSadt/TissGuiaSpSadtFormPage'
  );
const TissGuiasSpSadtPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/guiaSpSadt/TissGuiasSpSadtPage'
  );
const TissLotesPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/lote/TissLotesPage'
  );
const TissLoteFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/lote/TissLoteFormPage'
  );
const TissProcedimentosPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/procedimento/TissProcedimentosPage'
  );
const TissProcedimentoFormPage = () =>
  import(
    /* webpackChunkName: "tiss" */ '@/modules/tiss/pages/procedimento/TissProcedimentoFormPage'
  );

export const tissRoutes: IRouteConfig[] = routeGroup({
  prefix: 'tiss/:profissionalId',
  beforeEnter: recepcionistaOrOwnerGuard,
  routes: [
    ...routeGroup({
      prefix: 'guias-consultas',
      routes: [
        {
          path: '',
          components: {
            default: TissGuiasConsultasPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissGuiaConsultaFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissGuiaConsultaFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'guias-honorarios',
      routes: [
        {
          path: '',
          components: {
            default: TissGuiasHonorariosPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissGuiaHonorariosFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissGuiaHonorariosFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'guias-sp-sadt',
      routes: [
        {
          path: '',
          components: {
            default: TissGuiasSpSadtPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissGuiaSpSadtFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissGuiaSpSadtFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'guias-odontologicas',
      routes: [
        {
          path: '',
          components: {
            default: TissGuiasOdontologicasPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissGuiaOdontologicaFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissGuiaOdontologicaFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'lotes',
      routes: [
        {
          path: '',
          components: {
            default: TissLotesPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissLoteFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissLoteFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'procedimentos',
      routes: [
        {
          path: '',
          components: {
            default: TissProcedimentosPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: TissProcedimentoFormPage,
            sidebar: TissSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: TissProcedimentoFormPage,
            sidebar: TissSidebar,
          },
        },
      ],
    }),
  ],
});
