import { MyIcons } from '@/lib/helpers/MyIcons';
import { modifiers, watchRun } from '@/lib/vue';
import { apolloClient } from '@/plugins/apollo';
import { IFormInputAutocomplete } from '@/typings';
import { computed, nextTick, Ref, SetupContext } from '@vue/composition-api';
import deburr from 'lodash/deburr';
import throttle from 'lodash/throttle';
import { useValue } from '..';
import { IInputProps, useInputClearable } from './useInput';

export interface IAutocompleteProps extends IInputProps {
  // model
  value?: string | object | null;
  input: IFormInputAutocomplete;
}

export interface IAutocompleteEvents {
  onInput: (value: string | object | null) => void;
}

export function useAutocomplete({
  props,
  ctx,
  $inputEl,
  isCombobox,
}: {
  props: IAutocompleteProps;
  ctx: SetupContext;
  $inputEl: Ref<HTMLInputElement | null>;
  isCombobox: boolean;
}) {
  const [$searchValue, setSearchValue] = useValue<string | null>(null);

  const [$searchResult, setSearchResult] = useValue<any[]>([]);

  const [$searchLoading, setSearchLoading] = useValue(false);

  const [$loadedAll, setLoadedAll] = useValue(false);

  const throttleSearch = throttle(handleSearch, 500);

  watchRun($searchValue, throttleSearch);

  if (props.input.lookup) {
    watchRun(
      () => props.input.lookup,
      () => throttleSearch($searchValue.value),
    );
  }

  async function handleSearch(searchValue: string | null) {
    const input = props.input;

    if (
      !input.lookup ||
      input.hidden ||
      (!input.lookup.noCache && $loadedAll.value) ||
      input.items ||
      input.disabled
    ) {
      return;
    }

    try {
      setSearchLoading(true);

      const { query, getVariables, mapData, mapPageInfo, fetchPolicy } =
        input.lookup;

      const { data } = await apolloClient.query({
        query,
        variables: getVariables(searchValue),
        fetchPolicy: fetchPolicy || 'no-cache',
      });

      if (data) {
        setSearchResult(data ? mapData(data) : []);

        const pageInfo = mapPageInfo(data);

        setLoadedAll(
          !searchValue &&
            !!pageInfo &&
            (pageInfo.count <= pageInfo.take || !pageInfo.hasNextPage),
        );
      } else {
        setSearchResult([]);

        setLoadedAll(false);
      }
    } catch (error) {
      console.error(`Autocomplete "${input.label}" search error`, error);
    }

    setSearchLoading(false);
  }

  function filter(item, queryText, itemText) {
    const text = removeAcentos(itemText).toLowerCase();
    const query = removeAcentos(queryText).toLowerCase();

    return text.includes(query);
  }

  const computeds = useComputeds({
    props,
    $searchResult,
    $searchLoading,
    isCombobox,
  });

  const events = useEvents({
    props,
    ctx,
    $searchValue,
    $inputEl,
    setSearchValue,
    throttleSearch,
  });

  return {
    $searchValue,
    setSearchValue,
    $searchResult,
    setSearchResult,
    $searchLoading,
    setSearchLoading,
    $loadedAll,
    setLoadedAll,
    throttleSearch,
    filter,
    ...computeds,
    ...events,
  };
}

function useComputeds({
  props,
  $searchResult,
  $searchLoading,
  isCombobox,
}: {
  props: IAutocompleteProps;
  $searchResult: Ref<any[]>;
  $searchLoading: Ref<boolean>;
  isCombobox: boolean;
}) {
  const $items = computed(() => {
    const items =
      props.input.list || props.input.items || $searchResult.value || [];

    if (props.input.editValue) {
      return [...items, props.input.editValue];
    }

    return [...items];
  });

  const $loading = computed(() => props.input.loading || $searchLoading.value);

  const $lookup = computed(() => !!props.input.lookup);

  const $classObj = computed(() => ({ lookup: $lookup.value }));

  const $menuProps = computed(() => ({ ...props.input.menuProps }));

  const $appendIcon = computed(() => {
    if (props.input.appendIcon) {
      return props.input.appendIcon;
    }

    return $lookup.value && !isCombobox ? MyIcons.search : undefined;
  });

  const $noList = computed(() => !props.input.list);

  const $itemValue = computed(() =>
    $noList.value
      ? props.input.itemValue || ($lookup.value ? 'id' : 'value')
      : undefined,
  );

  const $itemText = computed(() =>
    $noList.value ? props.input.itemLabel || 'label' : undefined,
  );

  const $returnObject = computed(() => !!props.input.returnObject);

  const $cache = computed(
    () => !props.input.lookup || !props.input.lookup.noCache,
  );

  function scopedSlots() {
    return { item: props.input.itemSlot?.() };
  }

  return {
    $items,
    $loading,
    $lookup,
    $classObj,
    $menuProps,
    $appendIcon,
    $itemText,
    $itemValue,
    $returnObject,
    $cache,
    scopedSlots,
  };
}

function useEvents({
  props,
  ctx,
  $searchValue,
  $inputEl,
  setSearchValue,
  throttleSearch,
}: {
  props: IAutocompleteProps;
  ctx: SetupContext;
  $searchValue: Ref<string | null>;
  $inputEl: Ref<HTMLInputElement | null>;
  setSearchValue: (v: string | null) => void;
  throttleSearch: (searchValue: string | null) => any;
}) {
  const { handleClear } = useInputClearable({ ctx, $inputEl });

  function handleInput(value) {
    emitInput(value);

    handleModelChange();
  }

  function handleModelChange() {
    const input: any | null = $inputEl.value;

    if (props.input.lookup?.onModelChange && input) {
      // have to use nextTick so $input.selectedItem can have the right value
      nextTick(() => props.input.lookup?.onModelChange?.(input.selectedItem));
    }
  }

  function emitInput(value: string | object | null) {
    ctx.emit('input', value);
  }

  function handleFocus() {
    throttleSearch($searchValue.value);
  }

  const events = {
    on: {
      'click:clear': handleClear,
      input: handleInput,
      focus: handleFocus,
      'update:search-input': setSearchValue,
    },
    nativeOn: {
      keydown: modifiers.enter.prevent,
    },
  };

  return { emitInput, events };
}

function removeAcentos(val) {
  return deburr(hasValue(val));
}

function hasValue(val) {
  return val != null ? val : '';
}
