import {
  ConfiguracaoImpressaoLogoPosition,
  ConfiguracaoImpressaoMargem,
  ConfiguracaoImpressaoTamanhoPagina,
  IInputItems,
} from '@/typings';

export const configuracaoImpressaoTamanhosPaginas: IInputItems[] = [
  {
    label: 'A4',
    value: ConfiguracaoImpressaoTamanhoPagina.A4,
  },
  {
    label: 'A5',
    value: ConfiguracaoImpressaoTamanhoPagina.A5,
  },
];

export const configuracaoImpressaoMargens: IInputItems[] = [
  {
    label: 'Estreitas',
    value: ConfiguracaoImpressaoMargem.ESTREITA,
  },
  {
    label: 'Padrão',
    value: ConfiguracaoImpressaoMargem.PADRAO,
  },
  {
    label: 'Largas',
    value: ConfiguracaoImpressaoMargem.LARGA,
  },
];

export const configuracaoImpressaoLogoPositions: IInputItems[] = [
  // {
  //   label: 'Sem logo',
  //   value: ConfiguracaoImpressaoLogoPosition.NONE,
  // },
  {
    label: 'Esquerda',
    value: ConfiguracaoImpressaoLogoPosition.LEFT,
  },
  {
    label: 'Direita',
    value: ConfiguracaoImpressaoLogoPosition.RIGHT,
  },
];
