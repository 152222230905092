import { InputNumber } from '@/components/form/inputs/InputNumber';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFormInputNumber } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  value?: number;
  input: IFormInputNumber;
  header: IDataTableHeader;
  width?: number;
}

interface IEvents {
  onInput: (value: number | null) => void;
}

export const TdInputNumber = createComponent<IProps, IEvents>({
  name: 'TdInputNumber',
  props: {
    value: Number,
    input: { type: Object as PropType<IFormInputNumber>, required: true },
    header: { type: Object as PropType<IDataTableHeader>, required: true },
    width: Number,
  },
  setup(props, ctx) {
    function emitInput(value: number | null) {
      ctx.emit('input', value);
    }

    return () => (
      <div
        key={props.header.value}
        style={{ width: `${props.width || props.header.width || 200}px` }}
      >
        <InputNumber
          input={props.input}
          value={props.value}
          onInput={emitInput}
        />
      </div>
    );
  },
});
