import { DateHelpers } from '@/lib/helpers/date.helpers';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import {
  IAtendimentoFragment,
  IAtendimentoFragment_prescricoes,
} from '@/typings';
import { BtnImprimir } from '../../utils/BtnImprimir';
import { TimelineBodyItem } from '../body/TimelineBodyItem';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelinePrescricoes = createComponent<IProps>({
  name: 'TimelinePrescricoes',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { handleImprimir } = useEvents(props);

    return () => {
      const { prescricoes } = props.atendimento;

      if (!prescricoes || !prescricoes.length) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Prescrições</h2>

          {prescricoes.map(v => (
            <v-card outlined key={v.id} class="p-4 mt-2">
              <div class="flex items-center">
                <div class="flex text-title text-lg">
                  <span>Prescrição - </span>

                  <span>
                    {v.controleEspecial ? 'Controle especial' : 'Normal'}
                  </span>

                  <span class="ml-2 font-normal">
                    ({DateHelpers.formatDate(v.data)})
                  </span>
                </div>

                <BtnImprimir
                  class="mr-6"
                  handleImprimir={() => handleImprimir(v)}
                />
              </div>

              <v-divider class="my-2" />

              {v.medicamentos.map((m, idx) => (
                <div class="flex flex-col" key={m.id}>
                  {idx > 0 && <v-divider class="my-2 dashed" />}

                  <div class="flex">
                    <TimelineBodyItem
                      text={m.medicamento}
                      class="flex-none mr-4"
                    />

                    <TimelineBodyItem label="Quantidade" text={m.quantidade} />
                  </div>

                  <div class="flex">
                    <TimelineBodyItem
                      label="Posologia"
                      text={m.posologia}
                      class="ml-4 text-gray-700 text-body-sm"
                    />
                  </div>

                  <TimelineBodyItem
                    label="Observação"
                    text={m.observacao}
                    newLine
                    noPadding
                    class="px-4 pb-2 text-gray-700 text-body-sm"
                  />
                </div>
              ))}
            </v-card>
          ))}
        </div>
      );
    };
  },
});

function useEvents(props: IProps) {
  function handleImprimir(v: IAtendimentoFragment_prescricoes) {
    if (v.controleEspecial) {
      return ProntuarioService.pdf.prescricaoEspecial({
        atendimentoId: props.atendimento.id,
        prescricaoId: v.id,
        pacienteId: props.atendimento.paciente.id,
      });
    }

    return ProntuarioService.pdf.prescricaoNormal({
      atendimentoId: props.atendimento.id,
      prescricaoId: v.id,
      pacienteId: props.atendimento.paciente.id,
    });
  }

  return { handleImprimir };
}
