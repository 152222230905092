import { IInputItems } from '@/typings';

export const tipoAtendimento: IInputItems[] = [
  { label: '01 - Remoção', value: '01' },
  { label: '02 - Pequena Cirurgia', value: '02' },
  { label: '03 - Terapias', value: '03' },
  { label: '04 - Consulta', value: '04' },
  { label: '05 - Exames (englobando exame radiológico)', value: '05' },
  { label: '06 - Atendimento Domiciliar', value: '06' },
  { label: '07 - Internação', value: '07' },
  { label: '08 - Quimioterapia', value: '08' },
  { label: '09 - Radioterapia', value: '09' },
  { label: '10 - Terapia Renal Substitutiva (TRS)', value: '10' },
  { label: '11 - Pronto Socorro', value: '11' },
  // 12 - Ocupacional - Inativado na versão 3.01.00
  { label: '13 - Pequenos atendimentos', value: '13' },
  // *** Incluidos na versão 3.01.00
  { label: '14 - Admissional / Saúde Ocupacional - Admissional', value: '14' },
  { label: '15 - Demissional / Saúde Ocupacional - Demissional', value: '15' },
  { label: '16 - Periódico / Saúde Ocupacional - Periódico', value: '16' },
  {
    label: '17 - Retorno ao trabalho / Saúde Ocupacional - Retorno ao trabalho',
    value: '17',
  },
  {
    label: '18 - Mudança de função / Saúde Ocupacional - Mudança de função',
    value: '18',
  },
  { label: '19 - Saúde Ocupacional - Promoção a saúde', value: '19' },
  { label: '20 - Saúde Ocupacional - Beneficiário novo', value: '20' },
  { label: '21 - Saúde Ocupacional - Assistência a demitidos', value: '21' },
  { label: '22 - Telessaúde', value: '22' },
];
