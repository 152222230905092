import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { DialogState } from '@/store/modules/dialog.store';
import { TissState } from '@/store/modules/tiss.store';
import { ITussLookupSelectedState, TissTabela } from '@/typings';

export const TissTussLookupService = {
  showDialog({
    title,
    tabelas = [],
  }: {
    title: string;
    tabelas?: TissTabela[];
  }) {
    TissState.tussLookup.reset(tabelas);

    DialogHelpers.tiss.tussLookup(title);
  },

  select(v: ITussLookupSelectedState | null) {
    TissState.tussLookup.setSelected(v);

    DialogState.confirm();
  },
};
