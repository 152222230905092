import { useFormPage } from '@/lib/composables/form/useFormPage';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import {
  IProntuarioSalvarModeloDialogParams,
  ISalvarModeloDialogFormModel,
} from '@/typings';
import { SalvarModeloDialogForm } from './salvarModeloDialog/SalvarModeloDialogForm';

interface IProps {
  params: IProntuarioSalvarModeloDialogParams<any>;
}

export const SalvarModeloDialog = createComponent<IProps>({
  name: 'SalvarModeloDialog',
  props: {
    params: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback({ nome }: ISalvarModeloDialogFormModel) {
        DialogState.confirm({
          nome,
          items: props.params.items,
        });
      },
    });

    return () => (
      <div>
        <SalvarModeloDialogForm
          page={page}
          onSubmit={handleSubmit}
          onCancel={DialogState.cancel}
        />
      </div>
    );
  },
});
