import { Td } from '@/components/datatable/columns/Td';
import { TdInputNumber } from '@/components/datatable/columns/TdInputNumber';
import { DataTable } from '@/components/datatable/DataTable';
import { useValue } from '@/lib/composables';
import { createComponent, watchRun } from '@/lib/vue';
import {
  AgendamentoViewProcedimentoTableMode,
  IAgendamentoFragment,
  IAgendamentoFragment_transacoes,
  IAgendamentoRecebimentoProcedimentoModel,
  IFormInputNumber,
  ITransacaoFragment_agendamento,
  IDataTableHeader,
  IDataTableHeaderSlot,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { AgendamentoProcedimentosTableTotalsRow } from './agendamentoProcedimentosTable/AgendamentoProcedimentosTableTotalsRow';

interface IProps {
  agendamento: IAgendamentoFragment | ITransacaoFragment_agendamento;
  mode: AgendamentoViewProcedimentoTableMode;
  transacoes?: IAgendamentoFragment_transacoes[] | null;
}

interface IEvents {
  onProcedimentosChange: (
    procedimentos: IAgendamentoRecebimentoProcedimentoModel[],
  ) => void;
  onDescontoChange: (value: number) => void;
  onValorTotalChange: (value: number) => void;
}

export interface IAgendamentoProcedimentosTableSchema {
  quantidade: IFormInputNumber;
  valorUnitario: IFormInputNumber;
  desconto: IFormInputNumber;
}

export const AgendamentoProcedimentosTable = createComponent<IProps, IEvents>({
  name: 'AgendamentoProcedimentosTable',
  props: {
    agendamento: { type: Object, required: true },
    mode: {
      type: String as any,
      default: AgendamentoViewProcedimentoTableMode.NONE,
    },
    transacoes: { type: Array },
  },
  setup(props, ctx) {
    const [$procedimentos] = useValue<
      IAgendamentoRecebimentoProcedimentoModel[]
    >(
      (props.agendamento.procedimentos || []).map(v => ({
        procedimentoId: v.procedimento.id,
        nome: v.procedimento.nome,
        quantidade: v.quantidade,
        valorUnitario: v.valorUnitario,
        valorTotal: v.valorTotal,
      })),
    );

    const [$desconto, setDesconto] = useValue(props.agendamento.desconto);

    const { $subtotal, $valorTotal, $isNone, $isEdit, $isValores, schema } =
      useComputeds({
        $procedimentos,
        $desconto,
        props,
      });

    const { handleDescontoChange, handleTdInputChange } = useEvents({
      ctx,
      $valorTotal,
      $procedimentos,
      $desconto,
      setDesconto,
    });

    const $headers = useHeaders({
      $isEdit,
      $isNone,
      schema,
      handleTdInputChange,
    });

    return () => (
      <div class="flex flex-col p-4">
        <DataTable
          noMarginBottom
          headers={$headers.value}
          items={$procedimentos.value}
        />

        {!$isNone.value && <v-divider class="mb-2" />}

        <AgendamentoProcedimentosTableTotalsRow
          isNone={$isNone.value}
          subtotal={$subtotal.value}
          isValores={$isValores.value}
          desconto={$desconto.value}
          valorTotal={$valorTotal.value}
          schema={schema}
          transacoes={props.transacoes}
          handleDescontoChange={handleDescontoChange}
        />
      </div>
    );
  },
});

function useComputeds({
  $procedimentos,
  $desconto,
  props,
}: {
  $procedimentos: Ref<IAgendamentoRecebimentoProcedimentoModel[]>;
  $desconto: Ref<number>;
  props: IProps;
}) {
  const $subtotal = computed(() =>
    $procedimentos.value.reduce(
      (res, v) => res + v.quantidade * v.valorUnitario,
      0,
    ),
  );

  const $valorTotal = computed(() => $subtotal.value - $desconto.value);

  const $isNone = computed(
    () => props.mode === AgendamentoViewProcedimentoTableMode.NONE,
  );

  const $isEdit = computed(
    () => props.mode === AgendamentoViewProcedimentoTableMode.EDIT,
  );

  const $isValores = computed(
    () => props.mode === AgendamentoViewProcedimentoTableMode.VALORES,
  );

  const schema: IAgendamentoProcedimentosTableSchema = {
    quantidade: {
      label: null,
      integer: true,
      type: 'number',
      hideDetails: true,
      validations: { gt: 0 },
    },
    valorUnitario: {
      label: null,
      type: 'money',
      hideDetails: true,
    },
    desconto: {
      label: null,
      type: 'money',
      hideDetails: true,
    },
  };

  return {
    $subtotal,
    $valorTotal,
    $isNone,
    $isEdit,
    $isValores,
    schema,
  };
}

function useHeaders({
  $isEdit,
  $isNone,
  schema,
  handleTdInputChange,
}: Pick<ReturnType<typeof useComputeds>, '$isEdit' | '$isNone' | 'schema'> &
  Pick<ReturnType<typeof useEvents>, 'handleTdInputChange'>) {
  return computed<IDataTableHeader<IAgendamentoRecebimentoProcedimentoModel>[]>(
    () => [
      {
        text: 'Procedimento',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Quantidade',
        value: 'quantidade',
        slot: tdInputSlot({
          fieldName: 'quantidade',
          $isEdit,
          schema,
          handleTdInputChange,
        }),
        align: 'center',
        valueAlign: 'center',
        sortable: false,
        width: 60,
      },
      {
        text: 'Valor unitário',
        value: 'valorUnitario',
        slot: tdInputSlot({
          fieldName: 'valorUnitario',
          $isEdit,
          schema,
          handleTdInputChange,
        }),
        align: 'center',
        hide: $isNone.value,
        valueAlign: 'center',
        money: true,
        sortable: false,
        width: $isEdit.value ? 116 : 140,
      },
      {
        text: 'Valor total',
        value: 'valorTotal',
        mapValue: v => v.valorTotal,
        align: 'end',
        hide: $isNone.value,
        valueAlign: 'end',
        money: true,
        sortable: false,
        width: 140,
      },
    ],
  );
}

function useEvents({
  ctx,
  $valorTotal,
  $procedimentos,
  $desconto,
  setDesconto,
}: {
  ctx: SetupContext;
  $valorTotal: Ref<number>;
  $procedimentos: Ref<IAgendamentoRecebimentoProcedimentoModel[]>;
  $desconto: Ref<number>;
  setDesconto: (v: number) => void;
}) {
  function emitProcedimentosChange() {
    ctx.emit('procedimentosChange', $procedimentos.value);
  }

  function emitDescontoChange() {
    ctx.emit('descontoChange', $desconto.value);
  }

  function emitValorTotalChange() {
    ctx.emit('valorTotalChange', $valorTotal.value);
  }

  function handleTdInputChange({
    value,
    procedimentoId,
    fieldName,
  }: {
    value: number | null;
    procedimentoId: string;
    fieldName: 'quantidade' | 'valorUnitario';
  }) {
    const found = $procedimentos.value.find(
      f => f.procedimentoId === procedimentoId,
    );

    if (!found) return;

    found[fieldName] = value || 0;
    found.valorTotal = found.quantidade * found.valorUnitario;

    emitProcedimentosChange();
  }

  function handleDescontoChange(desconto: number | null) {
    setDesconto(desconto || 0);

    emitDescontoChange();
  }

  watchRun($procedimentos, emitProcedimentosChange, { deep: true });
  watchRun($desconto, emitDescontoChange);
  watchRun($valorTotal, emitValorTotalChange);

  return { handleDescontoChange, handleTdInputChange };
}

function tdInputSlot({
  fieldName,
  $isEdit,
  handleTdInputChange,
  schema,
}: {
  fieldName: 'quantidade' | 'valorUnitario';
} & Pick<ReturnType<typeof useEvents>, 'handleTdInputChange'> &
  Pick<ReturnType<typeof useComputeds>, 'schema' | '$isEdit'>) {
  if ($isEdit.value) {
    return ({
      header,
      item,
    }: IDataTableHeaderSlot<IAgendamentoRecebimentoProcedimentoModel>) => (
      <TdInputNumber
        class={{
          'center-input': fieldName === 'quantidade',
          'dense-input': true,
        }}
        header={header}
        input={schema[fieldName] as any}
        value={item[fieldName]}
        onInput={value =>
          handleTdInputChange({
            value,
            fieldName,
            procedimentoId: item.procedimentoId,
          })
        }
      />
    );
  }

  return ({ header, item }) => <Td header={header} item={item} />;
}
