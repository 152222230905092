import { MyIcons } from './MyIcons';

const BASE64_MARKER = ';base64,';

export const FileHelpers = {
  formatSize(size: number) {
    const kb = 1024;
    const mb = 1048576;
    const gb = 1073741824;

    if (size < kb) {
      return `${size} bytes`;
    } else if (size >= kb && size < mb) {
      return `${(size / kb).toFixed(1)} KB`;
    } else if (size >= mb && size < gb) {
      return `${(size / mb).toFixed(1)} MB`;
    } else {
      return `${(size / gb).toFixed(1)} GB`;
    }
  },

  getIcon(type: string) {
    if (FileHelpers.isAudio(type)) {
      return MyIcons.fileAudio;
    } else if (FileHelpers.isVideo(type)) {
      return MyIcons.fileVideo;
    } else if (FileHelpers.isPdf(type)) {
      return MyIcons.filePdf;
    } else if (FileHelpers.isMsWord(type)) {
      return MyIcons.fileMsWord;
    } else if (FileHelpers.isMsExcel(type)) {
      return MyIcons.fileMsExcel;
    } else if (FileHelpers.isMsPowerPoint(type)) {
      return MyIcons.fileMsPowerPoint;
    } else if (FileHelpers.isZip(type)) {
      return MyIcons.fileZip;
    }

    return MyIcons.file;
  },

  isImage(type: string) {
    return type.includes('image');
  },

  isAudio(type: string) {
    return type.includes('audio');
  },

  isVideo(type: string) {
    return type.includes('video');
  },

  isPdf(type: string) {
    return ['application/pdf'].includes(type);
  },

  isMsWord(type: string) {
    return [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(type);
  },

  isMsExcel(type: string) {
    return [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(type);
  },

  isMsPowerPoint(type: string) {
    return [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ].includes(type);
  },

  isZip(type: string) {
    return [
      'application/zip',
      'application/x-7z-compressed',
      'application/vnd.rar',
    ].includes(type);
  },

  isDataURI(url: string) {
    return url.split(BASE64_MARKER).length === 2;
  },

  dataURItoFile({ name, dataURI }: { name?: string; dataURI: string }) {
    if (!FileHelpers.isDataURI(dataURI)) return null;

    // Format of a base64-encoded URL:
    // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
    const mime = dataURI.split(BASE64_MARKER)[0].split(':')[1];

    const filename =
      name || `dataURI-file-${new Date().getTime()}.${mime.split('/')[1]}`;

    const bytes = atob(dataURI.split(BASE64_MARKER)[1]);

    const writer = new Uint8Array(new ArrayBuffer(bytes.length));

    for (let i = 0; i < bytes.length; i++) {
      writer[i] = bytes.charCodeAt(i);
    }

    return new File([writer.buffer], filename, { type: mime });
  },
};
