import CreateListaEsperaMutation from '@/graphql/profissional/listaEspera/createListaEspera.graphql';
import DeleteListaEsperaMutation from '@/graphql/profissional/listaEspera/deleteListaEspera.graphql';
import ListaEsperaQuery from '@/graphql/profissional/listaEspera/listaEspera.graphql';
import ListasEsperasQuery from '@/graphql/profissional/listaEspera/listasEsperas.graphql';
import UpdateListaEsperaOrdemMutation from '@/graphql/profissional/listaEspera/updateListaEsperaOrdem.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateListaEsperaMutation,
  ICreateListaEsperaMutationVariables,
  IDeleteListaEsperaMutation,
  IDeleteListaEsperaMutationVariables,
  IListaEsperaQuery,
  IListaEsperaQueryVariables,
  IListasEsperasQuery,
  IListasEsperasQueryVariables,
  IUpdateListaEsperaOrdemMutation,
  IUpdateListaEsperaOrdemMutationVariables,
} from '@/typings';

export const ListaEsperaGraphql = {
  query: {
    ListaEsperaQuery,
    ListasEsperasQuery,
  },

  async createListaEspera(variables: ICreateListaEsperaMutationVariables) {
    return apolloClient
      .mutate<ICreateListaEsperaMutation>({
        mutation: CreateListaEsperaMutation,
        variables,
      })
      .then(({ data }) => data?.createListaEspera);
  },

  async deleteListaEspera(variables: IDeleteListaEsperaMutationVariables) {
    return apolloClient
      .mutate<IDeleteListaEsperaMutation>({
        mutation: DeleteListaEsperaMutation,
        variables,
      })
      .then(({ data }) => data?.deleteListaEspera);
  },

  async listaEspera(variables: IListaEsperaQueryVariables) {
    return apolloClient
      .query<IListaEsperaQuery>({
        query: ListaEsperaQuery,
        variables,
      })
      .then(({ data }) => data?.listaEspera);
  },

  async listasEsperas(variables: IListasEsperasQueryVariables) {
    return apolloClient
      .query<IListasEsperasQuery>({
        query: ListasEsperasQuery,
        variables,
      })
      .then(({ data }) => data?.listasEsperas);
  },

  async updateListaEsperaOrdem(
    variables: IUpdateListaEsperaOrdemMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateListaEsperaOrdemMutation>({
        mutation: UpdateListaEsperaOrdemMutation,
        variables,
      })
      .then(({ data }) => data?.updateListaEsperaOrdem);
  },
};
