import { IInputItems } from '@/typings';

export const regiao: IInputItems[] = [
  { label: 'AS - Arco Superior', value: 'AI' },
  { label: 'AI - Arco Inferior', value: 'AS' },
  { label: 'HASD - Hemi-Arco Superior Direito', value: 'HASD' },
  { label: 'HASE - Hemi-Arco Superior Esquerdo', value: 'HASE' },
  { label: 'HAID - Hemi-Arco Inferior Direito', value: 'HAID' },
  { label: 'HAIE - Hemi-Arco Inferior Esquerdo', value: 'HAIE' },
  { label: 'ASAI - Arcadas Superiores e inferiores', value: 'ASAI' },
  { label: 'S1 - Sextante superior posterior direito', value: 'S1' },
  { label: 'S2 - Sextante superior anterior', value: 'S2' },
  { label: 'S3 - Sextante superior posterior esquerdo', value: 'S3' },
  { label: 'S4 - Sextante inferior posterior esquerdo', value: 'S4' },
  { label: 'S5 - Sextante inferior anterior', value: 'S5' },
  { label: 'S6 - Sextante inferior posterior direito', value: 'S6' },
  { label: 'LG - Língua', value: 'LG' },
  { label: 'CL - Comissura labial', value: 'CL' },
  { label: 'AB - Assoalho de boca', value: 'AB' },
  { label: 'PA - Palato', value: 'PA' },
  { label: 'MJ - Mucosa jugal', value: 'MJ' },
  { label: 'PD - Palato duro', value: 'PD' },
  { label: 'PM - Palato mole', value: 'PM' },
  { label: 'RM - Região retromolar', value: 'RM' },
  { label: 'MA - Mucosa alveolar', value: 'MA' },
  { label: 'GI - Gengiva inserida', value: 'GI' },
  { label: 'PT - Parótida', value: 'PT' },
  { label: 'TP - Tonsilas palatinas', value: 'TP' },
  { label: 'RIS - Região dos Incisivos centrais superiores', value: 'RIS' },
  {
    label: 'RCSD - Região do canino e lateral superior direito',
    value: 'RCSD',
  },
  { label: 'RPSD - Região dos pré-molares superiores direito', value: 'RPSD' },
  { label: 'RMSD - Região dos molares superiores direito', value: 'RCID' },
  {
    label: 'RCSE - Região do canino e lateral superior esquerdo',
    value: 'RMSD',
  },
  { label: 'RPSE - Região dos pré-molares superiores esquerdo', value: 'RCSE' },
  { label: 'RMSE - Região dos molares superiores esquerdo', value: 'RPSE' },
  { label: 'RII - Região dos incisivos inferiores', value: 'RMSE' },
  { label: 'RCID - Região de canino inferior direito', value: 'RII' },
  { label: 'RPID - Região dos pré-molares inferiores direito', value: 'RPID' },
  { label: 'RMID - Região dos molares inferiores direito', value: 'RMID' },
  { label: 'RCIE - Região de canino inferior esquerdo', value: 'RCIE' },
  { label: 'RPIE - Região dos pré-molares inferiores esquerdo', value: 'RPIE' },
  { label: 'RMIE - Região dos molares inferiores esquerdo', value: 'RMIE' },
  { label: 'RMD - Região dos molares lado direito', value: 'RMD' },
  { label: 'RME - Região dos molares lado esquerdo', value: 'RME' },
  { label: 'RPD - Região dos pré-molares lado direito', value: 'RPD' },
  { label: 'RPE - Região dos pré-molares lado esquerdo', value: 'RPE' },
  {
    label: 'RMPE - Região dos molares e pré-molares lado esquerdo',
    value: 'RMPE',
  },
  {
    label: 'RMPD - Região dos molares e pré-molares lado direito',
    value: 'RMPD',
  },
  { label: 'SM - Região do assoalho do seio maxilar', value: 'SM' },
  { label: 'TU - Região do Túber', value: 'TU' },
  { label: 'SI - Região de Sínfise', value: 'SI' },
  { label: 'FLI - Freio lingual', value: 'FLI' },
  { label: 'FLA - Freios labiais', value: 'FLA' },
  { label: 'UV - Úvula', value: 'UV' },
  { label: 'PP - Pregas palatinas', value: 'PP' },
  { label: 'PI - Papila incisiva', value: 'PI' },
  { label: 'LS - Lábio Superior', value: 'LS' },
  { label: 'LI - Lábio inferior', value: 'LI' },
  { label: 'RL - Região lingual', value: 'RL' },
  { label: 'RP - Região palatina', value: 'RP' },
  { label: 'RV - Região vestibular', value: 'RV' },
  { label: 'RSMD - Região Sub-Mandibular Direita', value: 'RSMD' },
  { label: 'RSME - Região Sub-Mandibular Esquerda', value: 'RSME' },
  { label: 'RSL - Região Sub-Lingual', value: 'RSL' },
];
