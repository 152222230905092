import { createComponent } from '@/lib/vue';
import { IRequireField } from '@/typings';

interface IJsxProps {
  loading: boolean;
  height?: number;
  top?: number;
}

type IProps = IRequireField<IJsxProps, 'loading' | 'height' | 'top'>;

export const LoadingOverlay = createComponent<IJsxProps>({
  name: 'LoadingOverlay',
  props: {
    loading: { type: Boolean, required: true },
    height: { type: Number, default: 7 },
    top: { type: Number, default: 0 },
  },
  setup(props: IProps, ctx) {
    return () => {
      if (!props.loading) {
        return null;
      }

      const style = {
        top: `${props.top}px`,
        height: props.top > 0 ? `calc(100% - ${props.top}px)` : '100%',
      };

      return (
        <div id="LoadingOverlay" style={style}>
          <v-progress-linear indeterminate height={props.height} />
        </div>
      );
    };
  },
});

export default LoadingOverlay;
