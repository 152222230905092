import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { ProntuarioTimeline } from '../../components/timeline/ProntuarioTimeline';

export default createComponent({
  name: 'ProntuarioHistoricoClinicoPage',
  setup(props, ctx) {
    const $events = useState(s => s.prontuario.events);

    return () => {
      if (!$events.value.length) {
        return (
          <div class="flex flex-col mt-12">
            <div class="flex justify-center text-center text-headline text-coolGray-600">
              Este paciente não possui nenhum atendimento anterior
            </div>

            <div class="flex justify-center mt-4">
              <v-icon size={64} color={MyTheme.coolGray600}>
                {MyIcons.prontuarioEmpty}
              </v-icon>
            </div>
          </div>
        );
      }

      return (
        <div class="-mt-4">
          <ProntuarioTimeline />
        </div>
      );
    };
  },
});
