import { ExpandTransition } from '@/components/utils/ExpandTransition';
import { getInputComponent, mapInputValidationsErrors } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import {
  IFormInputType,
  IFormSchema,
  IFormSubmitted,
  IModel,
  IRequireField,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IModel;
  schema: IFormSchema<IModel>;
  form?: IFormSubmitted | null;
  hide?: boolean;
  maxWidth?: number;
  full?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: IModel) => void;
}

export const FormFields = createComponent<IJsxProps, IEvents>({
  name: 'FormFields',
  props: {
    form: { type: Object },
    schema: { type: Object, required: true },
    value: { type: Object, required: true },
    hide: { type: Boolean, default: false },
    maxWidth: { type: Number },
    full: { type: Boolean, default: false },
  },
  setup(props: IProps, ctx) {
    const { $schema, $formFieldStyle } = useComputeds(props);

    return () => (
      <div id="form-fields" style={$formFieldStyle.value}>
        <ExpandTransition hide={props.hide}>
          <v-fade-transition
            group
            tag="div"
            class={[
              'flex flex-wrap items-center flex-auto w-full mt-2',
              { 'max-w-2xl': !props.full },
            ]}
            style={$formFieldStyle.value}
          >
            {Object.entries($schema.value).map(
              ([key, input]: [string, IFormInputType]) => (
                <div
                  key={`input${key}`}
                  class={wrapperClasses(input)}
                  style={wrapperStyle(input)}
                >
                  {inputComponent({ input, key, props, ctx })}
                </div>
              ),
            )}
          </v-fade-transition>
        </ExpandTransition>
      </div>
    );
  },
});

function useComputeds(props: IProps) {
  const $schema = computed<IFormSchema<IModel>>(() =>
    Object.entries(props.schema)
      .filter(([key, f]: [string, IFormInputType]) => !f.hidden)
      .reduce(
        (obj, [key]) => ({
          ...obj,
          [key]: props.schema[key],
        }),
        {},
      ),
  );

  const $formFieldStyle = computed(() =>
    props.maxWidth ? { maxWidth: `${props.maxWidth}px` } : {},
  );

  return { $schema, $formFieldStyle };
}

function inputComponent({
  input,
  key,
  props,
  ctx,
}: {
  input: IFormInputType;
  key: string;
  props: IProps;
  ctx: SetupContext;
}) {
  const FormInput = getInputComponent(input) as any;

  if (!FormInput) {
    return null;
  }

  function emitInput(key: string, value: any) {
    props.value[key] = value;

    ctx.emit('input', props.value);
  }

  const value = props.value[key];

  return (
    <FormInput
      ref={key}
      name={key}
      style={inputStyle(input)}
      input={input}
      errorMessages={mapInputValidationsErrors({
        validations: input.validations,
        form: props.form,
        input,
        value,
      })}
      value={value}
      onInput={newValue => emitInput(key, newValue)}
    />
  );
}

function inputStyle({ type, layout }: IFormInputType) {
  const isRichText = type === 'rich-text';
  const defaults = {
    maxWidth: isRichText ? 800 : 500,
    minWidth: null,
  };

  const { maxWidth, minWidth } = layout?.input || defaults;

  return {
    'max-width': `${maxWidth}px`,
    width: '100%',
    'min-width': minWidth ? `${minWidth}px` : null,
  };
}

function wrapperStyle({ layout }: IFormInputType) {
  if (!layout) {
    return {};
  }

  const maxWidth = layout.maxWidth || layout.width;
  const minWidth = layout.minWidth || layout.width;

  return {
    'max-width': maxWidth ? `${maxWidth}px` : null,
    'min-width': minWidth ? `${minWidth}px` : null,
  };
}

function wrapperClasses({ layout, type }: IFormInputType) {
  const isSelectionType = ['checkbox', 'switch'].includes(type);

  if (layout) {
    const { xs, sm, md, lg, marginBottom } = layout;

    return {
      'px-2': true,
      'w-full': !xs,
      // center selection type inputs
      'mb-6': isSelectionType && !marginBottom,
      // w-1/12 w-2/12 w-3/12 w-4/12 w-5/12 w-6/12
      // w-7/12 w-8/12 w-9/12 w-10/12 w-11/12 w-12/12
      ...(xs && { [`w-${xs}/12`]: true }),
      // sm:w-1/12 sm:w-2/12 sm:w-3/12 sm:w-4/12 sm:w-5/12 sm:w-6/12
      // sm:w-7/12 sm:w-8/12 sm:w-9/12 sm:w-10/12 sm:w-11/12 sm:w-12/12
      ...(sm && { [`sm:w-${sm}/12`]: true }),
      // md:w-1/12 md:w-2/12 md:w-3/12 md:w-4/12 md:w-5/12 md:w-6/12
      // md:w-7/12 md:w-8/12 md:w-9/12 md:w-10/12 md:w-11/12 md:w-12/12
      ...(md && { [`md:w-${md}/12`]: true }),
      // lg:w-1/12 lg:w-2/12 lg:w-3/12 lg:w-4/12 lg:w-5/12 lg:w-6/12
      // lg:w-7/12 lg:w-8/12 lg:w-9/12 lg:w-10/12 lg:w-11/12 lg:w-12/12
      ...(lg && { [`lg:w-${lg}/12`]: true }),
      // mb-1 mb-2 mb-3 mb-4 mb-5 mb-6 mb-7 mb-8 mb-9 mb-10 mb-11 mb-12
      ...(marginBottom && { [`mb-${marginBottom}`]: true }),
    };
  }

  return {
    'px-2 w-full': true,
    // center selection type inputs
    'mb-6': isSelectionType,
  };
}
