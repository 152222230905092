import { Validate } from '@/lib/helpers/error';
import { store } from '@/store';

import { UserTipo } from '@/typings';

export const getUser = () => Validate.require(store.state.auth.user, 'user');

export const getClinica = () =>
  Validate.require(store.state.auth.clinica, 'clinica');

export const userHasClinicas = () => (getUser().clinicas?.length || 0) > 1;

export const userIsProfissionalSaude = () =>
  getUser().tipo === UserTipo.PROFISSIONAL_SAUDE;

export const userIsRecepcionista = () =>
  getUser().tipo === UserTipo.RECEPCIONISTA;

export const userIsAdminAccount = () => getUser().adminAccount;

export const userIsAdminClinica = () => getClinica().adminClinica;

export const userIsOwner = (profissionalId: string) =>
  getUser().id === profissionalId;

export const userAtendeProfissional = (profissionalId: string) => {
  const profissionais = getUser().profissionais;

  return !!profissionais?.find(f => f.id === profissionalId);
};

export const getProfissionalId = () => {
  if (userIsProfissionalSaude()) {
    return getUser().id;
  }

  const { profissionais } = getUser();
  if (profissionais?.length) {
    return profissionais[0].id;
  }

  return null;
};
