import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import throttle from 'lodash/throttle';

interface IProps {
  placeholder: string;
  value?: string | null;
  name?: string;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputSearch = createComponent<IProps, IEvents>({
  name: 'InputSearch',
  props: {
    name: { type: String },
    placeholder: { type: String, required: true },
    value: { type: String },
  },
  setup(props, ctx) {
    function emitInput(value: string) {
      ctx.emit('input', value);
    }

    const inputSearch = throttle(emitInput, 500);

    return () => (
      <div class="flex w-full p-4">
        <v-text-field
          name={props.name}
          outlined
          rounded
          clearable
          hideDetails
          prependInnerIcon={MyIcons.search}
          placeholder={props.placeholder}
          onInput={inputSearch}
        />
      </div>
    );
  },
});
