import {
  AtendimentoAtestadoInput,
  AtendimentoDataInput,
  AtendimentoEventoInput,
  AtendimentoEventoItemInput,
  AtendimentoEventoItemType,
  AtendimentoExameInput,
  AtendimentoHipoteseDiagnosticaInput,
  AtendimentoPreAtendimentoInput,
  AtendimentoPrescricaoInput,
  IProntuarioState,
  ProntuarioSecaoFieldType,
} from '@/typings';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import { DateHelpers } from '../../date.helpers';
import { Validate } from '../../error';
import {
  atestadosFilterValid,
  examesFilterValid,
  prescricoesFilterValid,
} from './atendimento';

export function mapAtendimentoDataInput(
  state: IProntuarioState,
): AtendimentoDataInput | null {
  const preAtendimento = getPreAtendimento(state);
  const hipoteseDiagnostica = getHipoteseDiagnostica(state);
  const prescricoes = getPrescricoes(state);
  const atestados = getAtestados(state);
  const exames = getExames(state);
  const eventos = getEventos(state);

  const files = state.files;

  if (
    !preAtendimento &&
    !hipoteseDiagnostica &&
    !prescricoes?.length &&
    !atestados?.length &&
    !exames?.length &&
    !eventos?.length &&
    !files.length
  ) {
    return null;
  }

  return {
    dataInicial: Validate.dateTime(
      state.atendimento.startAt,
      'atendimento.startAt',
    ),
    dataFinal: Validate.dateTime(state.atendimento.endAt, 'atendimento.endAt'),
    pacienteId: Validate.require(state.paciente?.data.id, 'paciente.id'),
    agendamentoId: state.agendamento?.id,
    preAtendimento,
    hipoteseDiagnostica,
    prescricoes,
    atestados,
    exames,
    eventos,
    files,
  };
}

function getPreAtendimento({
  forms: {
    preAtendimento: { model },
  },
}: IProntuarioState): AtendimentoPreAtendimentoInput | null {
  if (
    !model.peso &&
    !model.altura &&
    !model.imc &&
    !model.pressaoArterialSistolica &&
    !model.pressaoArterialDiastolica &&
    !model.frequenciaCardiaca &&
    !model.frequenciaRespiratoria &&
    !model.temperatura &&
    !model.glicemiaCapilar &&
    !model.dataUltimaMenstruacao &&
    !model.observacao
  ) {
    return null;
  }

  return model;
}

function getHipoteseDiagnostica({
  forms: {
    hipoteseDiagnostica: {
      model: { cids, observacao },
    },
  },
}: IProntuarioState): AtendimentoHipoteseDiagnosticaInput | null {
  if (
    (!cids.length && !observacao) ||
    cids.every(v => !v.codigo && !v.descricao)
  ) {
    return null;
  }

  return {
    cids: cids.map(({ id, ...v }) => v),
    observacao,
  };
}

function getPrescricoes({
  forms: {
    prescricoes: { model },
  },
}: IProntuarioState): AtendimentoPrescricaoInput[] | null {
  const validModel = model.filter(prescricoesFilterValid);

  if (!validModel.length) {
    return null;
  }

  return validModel.map(({ header, medicamentos }) => ({
    data: Validate.date(header.data, 'data'),
    controleEspecial: header.controleEspecial,
    medicamentos: medicamentos.map(m => ({
      medicamento: Validate.require(m.medicamento, 'medicamento'),
      quantidade: Validate.require(m.quantidade, 'quantidade'),
      posologia: m.posologia,
      observacao: m.observacao,
    })),
  }));
}

function getAtestados({
  forms: {
    atestados: { model },
  },
}: IProntuarioState): AtendimentoAtestadoInput[] | null {
  const validModel = model.filter(atestadosFilterValid);

  if (!validModel.length) {
    return null;
  }

  return validModel.map(v => ({
    data: Validate.date(v.data, 'data'),
    titulo: Validate.require(v.titulo, 'titulo'),
    texto: Validate.require(v.texto, 'texto'),
  }));
}

function getExames({
  forms: {
    exames: { model },
  },
}: IProntuarioState): AtendimentoExameInput[] | null {
  const validModel = model.filter(examesFilterValid);

  if (!validModel.filter(examesFilterValid).length) {
    return null;
  }

  return validModel.map(v => ({
    data: Validate.date(v.data, 'data'),
    indicacaoClinica: v.indicacaoClinica,
    tipo: Validate.require(v.tipo, 'tipo'),
    items: v.items.map(i => ({
      codigo: Validate.require(i.codigo, 'codigo'),
      nome: Validate.require(i.nome, 'nome'),
      quantidade: Validate.require(i.quantidade, 'quantidade'),
      observacao: i.observacao,
    })),
  }));
}

function getEventos({
  tabs,
}: IProntuarioState): AtendimentoEventoInput[] | null {
  if (!tabs.length || tabs.every(f => !f.model)) {
    return null;
  }
  return tabs
    .map(v => ({
      nome: v.nome,
      prontuarioSecaoId: v.id,
      items: (v.fields || [])
        .map(f => {
          const value = v.model[f.id];

          if (
            (!value && !isNumber(value) && !isArray(value)) ||
            (isArray(value) && value.length === 0)
          ) {
            return null as any;
          }

          const item: AtendimentoEventoItemInput = {
            type: fieldTypeToEventoItemType(f.type),
            label: f.label,
            originalType: f.type,
            suffix: f.suffix,
            decimalPlaces: f.decimalPlaces,
          };

          if (f.type === ProntuarioSecaoFieldType.NUMBER) {
            item.valueNumber = value;
          } else if (f.type === ProntuarioSecaoFieldType.GROUP_CHECKBOX) {
            item.valueList = value;
          } else if (f.type === ProntuarioSecaoFieldType.DATE) {
            item.valueText = DateHelpers.toISODate(value);
          } else if (f.type === ProntuarioSecaoFieldType.TIME) {
            item.valueText = DateHelpers.toISOTime(value);
          } else if (f.type === ProntuarioSecaoFieldType.CHECKBOX && value) {
            item.valueText = f.label;
          } else {
            item.valueText = value;
          }

          return item;
        })
        .filter(Boolean),
    }))
    .filter(f => !!f.items.length);
}

function fieldTypeToEventoItemType(
  type: ProntuarioSecaoFieldType,
): AtendimentoEventoItemType {
  switch (type) {
    case ProntuarioSecaoFieldType.CHECKBOX:
    case ProntuarioSecaoFieldType.DATE:
    case ProntuarioSecaoFieldType.NUMBER:
    case ProntuarioSecaoFieldType.RADIO:
    case ProntuarioSecaoFieldType.SELECT:
    case ProntuarioSecaoFieldType.TEXT:
    case ProntuarioSecaoFieldType.TIME:
    default:
      return AtendimentoEventoItemType.TEXT;

    case ProntuarioSecaoFieldType.GROUP_CHECKBOX:
      return AtendimentoEventoItemType.LIST;

    case ProntuarioSecaoFieldType.RICH_TEXT:
      return AtendimentoEventoItemType.RICH_TEXT;
  }
}
