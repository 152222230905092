import { createComponent } from '@/lib/vue';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { IFormInputRadio, IInputItems } from '@/typings';
import { computed } from '@vue/composition-api';
import isString from 'lodash/isString';
import { useBreakpoints } from '@/lib/composables/utils/useBreakpoints';

interface IProps extends IInputProps {
  value?: string | null;
  input: IFormInputRadio;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputRadio = createComponent<IProps, IEvents>({
  name: 'InputRadio',
  props: {
    value: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $gtSm } = useBreakpoints();

    const $row = computed(() => !!props.input.layout?.row && $gtSm.value);

    function emitInput(value: string | null) {
      ctx.emit('input', value);
    }

    const { $label } = useInput(props);

    return () => (
      <v-radio-group
        label={$label.value}
        value={props.value}
        errorMessages={props.errorMessages}
        prependIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        disabled={props.input.disabled}
        hideDetails={!!props.input.hideDetails}
        column={!$row.value}
        row={$row.value}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        onChange={emitInput}
      >
        {props.input.items.map(item => radio(item, props))}
      </v-radio-group>
    );
  },
});

const radio = (item: string | IInputItems, props: IProps) => {
  const label = isString(item) ? item : item.label;
  const value = isString(item) ? item : item.value;

  return (
    <v-radio
      disabled={props.input.disabled}
      key={label}
      label={label}
      value={value}
    />
  );
};
