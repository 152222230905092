import { mapAtendimentoCacheData } from '@/lib/helpers/atendimentoCache';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { ProntuarioState } from '@/store/modules/prontuario';
import cloneDeep from 'lodash/cloneDeep';
import { AtendimentoService } from '../../atendimento.service';
import { getProntuarioState } from './prontuario.service';

export const ProntuarioCache = {
  async save(successMessage = true) {
    const state = getProntuarioState();

    if (!state.atendimento.playing) return;

    const result = await AtendimentoService.saveCache(
      mapAtendimentoCacheData(state),
    );

    if (result && successMessage) {
      toastSuccess('Rascunho do atendimento salvo com sucesso');
    }
  },

  async load(pacienteId: string) {
    const data = await AtendimentoService.getCache(pacienteId);

    if (!data) return ProntuarioCache.clear();

    const days =
      DateHelpers.dateDiff(data.dataInicial, new Date(), 'days')?.days || 0;

    if (days <= 2) {
      return ProntuarioState.cache.set(cloneDeep(data.cachePayload));
    }
  },

  async delete() {
    const pacienteId = Validate.require(
      getProntuarioState().paciente?.data.id,
      'pacienteId',
    );

    const deleted = await AtendimentoService.deleteCache({ pacienteId });

    if (deleted) return ProntuarioCache.clear();
  },

  clear() {
    ProntuarioState.cache.set(null);
  },
};
