import { PacienteHomonimosDialog } from '@/modules/agenda/components/dialogs/PacienteHomonimosDialog';
import { IPacienteHomonimosDialogParams } from '@/typings';
import { showDialog } from './dialog.helpers';

export const PacienteDialogs = {
  homonimos(params: IPacienteHomonimosDialogParams) {
    return showDialog<IPacienteHomonimosDialogParams>({
      component: PacienteHomonimosDialog,
      width: 500,
      title: 'Pacientes homônimos',
      params,
    });
  },
};
