import { AuthGraphql } from '@/graphql/auth/AuthGraphql';
import { Validate } from '@/lib/helpers/error';
import { authFailed, setTokensAndRedirect } from '@/lib/services/auth/utils';
import { AppState } from '@/store/modules/root.store';
import { ICheckConviteQueryVariables } from '@/typings';

export const AuthConviteService = {
  async checkConvite(variables: ICheckConviteQueryVariables) {
    try {
      const result = await AuthGraphql.checkConvite(variables);

      return result?.valid;
    } catch (error) {
      authFailed(error);
    }
  },

  async convite({
    conviteToken,
    password,
  }: {
    conviteToken: string;
    password: string;
  }) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.confirmConvite({
        conviteToken,
        newPassword: Validate.require(password, 'password'),
      });

      setTokensAndRedirect(result);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },
};
