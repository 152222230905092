import { IInputItems } from '@/typings';

export const grauPart: IInputItems[] = [
  { label: '00 - Cirurgião', value: '00' },
  { label: '01 - Primeiro Auxiliar', value: '01' },
  { label: '02 - Segundo Auxiliar', value: '02' },
  { label: '03 - Terceiro Auxiliar', value: '03' },
  { label: '04 - Quarto Auxiliar', value: '04' },
  { label: '05 - Instrumentador', value: '05' },
  { label: '06 - Anestesista', value: '06' },
  { label: '07 - Auxiliar de Anestesista', value: '07' },
  { label: '08 - Consultor', value: '08' },
  { label: '09 - Perfusionista', value: '09' },
  { label: '10 - Pediatra na sala de parto', value: '10' },
  { label: '11 - Auxiliar SADT', value: '11' },
  { label: '12 - Clínico', value: '12' },
  { label: '13 - Intensivista', value: '13' },
];
