import { MyIcons } from '@/lib/helpers/MyIcons';
import {
  ConfiguracaoFinanceiraRecebimentoLancarAPartir,
  ContaFinanceiraTipo,
  FinancasRelatoriosGroupBy,
  FormaPagamento,
  IInputItems,
} from '@/typings';

export const fluxoCaixaTypes: IInputItems[] = [
  { label: 'Mês', value: 'month' },
  { label: 'Dia', value: 'week' },
];

export const financasRelatoriosGroupBy: IInputItems[] = [
  { label: 'Categoria', value: FinancasRelatoriosGroupBy.CATEGORIA },
  { label: 'Centro de custo', value: FinancasRelatoriosGroupBy.CENTRO_CUSTO },
  {
    label: 'Conta financeira',
    value: FinancasRelatoriosGroupBy.CONTA_FINANCEIRA,
  },
  { label: 'Convênio', value: FinancasRelatoriosGroupBy.CONVENIO },
  { label: 'Paciente', value: FinancasRelatoriosGroupBy.PACIENTE },
  { label: 'Profissional', value: FinancasRelatoriosGroupBy.PROFISSIONAL },
  { label: 'Procedimento', value: FinancasRelatoriosGroupBy.PROCEDIMENTO },
];

export const contaFinanceiraTipos: IInputItems[] = [
  {
    label: 'Caixa interno',
    value: ContaFinanceiraTipo.CAIXA_INTERNO,
    icon: MyIcons.contaFinanceiraTipo.caixaInterno,
  },
  {
    label: 'Conta corrente',
    value: ContaFinanceiraTipo.CONTA_CORRENTE,
    icon: MyIcons.contaFinanceiraTipo.contaCorrente,
  },
  {
    label: 'Conta poupança',
    value: ContaFinanceiraTipo.CONTA_POUPANCA,
    icon: MyIcons.contaFinanceiraTipo.contaPoupanca,
  },
  {
    label: 'Investimento',
    value: ContaFinanceiraTipo.INVESTIMENTO,
    icon: MyIcons.contaFinanceiraTipo.investimento,
  },
  {
    label: 'Cartão de crédito',
    value: ContaFinanceiraTipo.CARTAO_CREDITO,
    icon: MyIcons.contaFinanceiraTipo.cartaoCredito,
  },
  {
    label: 'Meios de recebimento',
    value: ContaFinanceiraTipo.MEIOS_RECEBIMENTO,
    icon: MyIcons.contaFinanceiraTipo.meiosRecebimento,
  },
  {
    label: 'Outros',
    value: ContaFinanceiraTipo.OUTROS,
    icon: MyIcons.contaFinanceiraTipo.outros,
  },
];

export const formasPagamento: IInputItems[] = [
  { label: 'Boleto', value: FormaPagamento.BOLETO },
  { label: 'Cartão de crédito', value: FormaPagamento.CARTAO_CREDITO },
  { label: 'Cartão de débito', value: FormaPagamento.CARTAO_DEBITO },
  { label: 'Cheque', value: FormaPagamento.CHEQUE },
  { label: 'Depósito', value: FormaPagamento.DEPOSITO },
  { label: 'Dinheiro', value: FormaPagamento.DINHEIRO },
  {
    label: 'Transferência bancária',
    value: FormaPagamento.TRANSFERENCIA_BANCARIA,
  },
];

export const configuracaoFinanceiraRecebimentoLancarAPartir: IInputItems[] = [
  {
    label: 'Agenda',
    value: ConfiguracaoFinanceiraRecebimentoLancarAPartir.AGENDA,
  },
  {
    label: 'Prontuários',
    value: ConfiguracaoFinanceiraRecebimentoLancarAPartir.PRONTUARIOS,
  },
];
