import { uuid } from '@/lib/helpers/uuid';

export function useElementRef<T = HTMLElement>() {
  const elementId = uuid();

  function getElement() {
    return document.getElementById(elementId) as T | null;
  }

  return { elementId, getElement };
}
