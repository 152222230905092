import { ausenciaCodValidacao } from './ausenciaCodValidacao';
import { caraterAtendimento } from './caraterAtendimento';
import { cbo } from './cbo';
import { condicaoClinica } from './condicaoClinica';
import { conselhoProfissional } from './conselhoProfissional ';
import { dente } from './dente ';
import { grauPart } from './grauPart';
import { indicadorAcidente } from './indicadorAcidente';
import { loteTipo } from './loteTipo';
import { loteVersao } from './loteVersao';
import { motivoSaidaObito } from './motivoSaidaObito';
import { outrasDespesas } from './outrasDespesas';
import { regiao } from './regiao';
import { simNao } from './simNao';
import { tecnicaUtilizada } from './tecnicaUtilizada';
import { tipoAtendimento } from './tipoAtendimento';
import { tipoAtendimentoOdonto } from './tipoAtendimentoOdonto';
import { tipoConsulta } from './tipoConsulta';
import { tipoDocumento } from './tipoDocumento';
import { tipoFaturamentoOdonto } from './tipoFaturamentoOdonto';
import { tipoPessoa } from './tipoPessoa';
import { tissDespesaTipos } from './tissDespesaTipos';
import {
  tissProcedimentoSexos,
  tissProcedimentoTabelas,
} from './tissProcedimento';
import { tissTabelas } from './tissTabelas';
import { tissUf } from './uf';
import { unidadeMedida } from './unidadeMedida';
import { viaDeAcesso } from './viaDeAcesso';

export const TissConstants = {
  ausenciaCodValidacao,
  caraterAtendimento,
  cbo,
  condicaoClinica,
  conselhos: conselhoProfissional,
  dente,
  despesaTipos: tissDespesaTipos,
  grauPart,
  indicadorAcidente,
  loteTipo,
  loteVersao,
  motivoSaidaObito,
  outrasDespesas,
  procedimentoSexos: tissProcedimentoSexos,
  procedimentoTabelas: tissProcedimentoTabelas,
  regiao,
  simNao,
  tabelas: tissTabelas,
  tecnicaUtilizada,
  tipoAtendimento,
  tipoAtendimentoOdonto,
  tipoConsulta,
  tipoDocumento,
  tipoFaturamentoOdonto,
  tipoPessoa,
  uf: tissUf,
  unidadeMedida,
  viaDeAcesso,
};
