import { DocumentNode, OperationDefinitionNode } from 'graphql';

export const getQueryName = (query: DocumentNode): string | undefined => {
  const definition = query.definitions.find(
    f => f.kind === 'OperationDefinition',
  ) as OperationDefinitionNode;

  return definition?.name?.value;
};

export const getRefetchQueries = (queries: DocumentNode[] = []) =>
  queries.map(getQueryName).filter(Boolean) as string[];
