import { IAgendamentoFragment } from '@/typings';
import capitalize from 'lodash/capitalize';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { DateTime, DurationObjectUnits, DurationUnit, Settings } from 'luxon';

Settings.defaultLocale = 'pt-br';

export type IDateTypes =
  | string
  | number
  | Date
  | DateTime
  | null
  | undefined
  | Readonly<Date>;

export const DateHelpers = {
  toISO(value: IDateTypes) {
    const date = DateHelpers.parse(value);

    return date ? date.toISO() : null;
  },

  toISOTime(value: IDateTypes) {
    const time = DateHelpers.parse(value);

    return time ? time.toFormat('HH:mm:ss') : null;
  },

  toISODate(value: IDateTypes) {
    const date = DateHelpers.parse(value);

    return date ? date.toISODate() : null;
  },

  formatDate(value: IDateTypes, noYear = false) {
    const date = DateHelpers.parse(value);

    if (!date) return null;

    return noYear ? date.toFormat('dd/MM') : date.toFormat('dd/MM/yyyy');
  },

  formatHour(value: IDateTypes) {
    const time = DateHelpers.parse(value);

    return time ? time.toFormat('HH:mm') : null;
  },

  formatDateAndHour(value: IDateTypes) {
    const dateTime = DateHelpers.parse(value);

    if (!dateTime) return null;

    return dateTime.toFormat("dd/MM/yyyy' às 'HH:mm");
  },

  formatWeekday(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    return dt ? dt.toFormat('EEEE') : null;
  },

  formatDayAndMonth(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    return dt ? dt.toFormat('dd/MMMM') : null;
  },

  formatMonthAndYear(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    return dt ? dt.toFormat('MMMM/yyyy') : null;
  },

  formatMonthAndYearShort(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    return dt ? capitalize(dt.toFormat('MMM/yy').replace('.', '')) : null;
  },

  today: () => DateTime.local(),

  dateToUTC(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    return dt ? dt.toUTC() : null;
  },

  dateDiff(
    startDate: IDateTypes,
    endDate: IDateTypes,
    unit: DurationUnit | DurationUnit[],
  ) {
    const start = DateHelpers.parse(startDate);
    const end = DateHelpers.parse(endDate);

    if (!start || !end) return null;

    return end.diff(start, unit);
  },

  humanizeDiff(startDate: IDateTypes, endDate: IDateTypes): string | null {
    const diff = DateHelpers.dateDiff(startDate, endDate, [
      'hours',
      'minutes',
      'seconds',
      'milliseconds',
    ]);

    if (!diff) return null;

    const { hours, minutes, seconds } = diff;

    const hoursTxt = hours > 1 ? `${hours} horas` : `${hours} hora`;

    const minutesTxt = minutes > 1 ? `${minutes} minutos` : `${minutes} minuto`;

    const secondsTxt =
      seconds > 1 ? `${seconds} segundos` : `${seconds || 1} segundo`;

    if (hours > 0 && minutes === 0) {
      return hoursTxt;
    } else if (hours > 0 && minutes > 0) {
      return `${hoursTxt} e ${minutesTxt}`;
    } else if (minutes > 0) {
      return minutesTxt;
    }

    return secondsTxt;
  },

  weekStart(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    // fix luxon weekday bug (https://github.com/moment/luxon/issues/373)
    return dt ? dt.plus({ day: 1 }).startOf('week').minus({ day: 1 }) : null;
  },

  weekEnd(value: IDateTypes) {
    const dt = DateHelpers.parse(value);

    // fix luxon weekday bug (https://github.com/moment/luxon/issues/373)
    return dt ? dt.plus({ day: 1 }).endOf('week').minus({ day: 1 }) : null;
  },

  dateStartOf: (unit: DurationUnit) => DateTime.local().startOf(unit),

  dateEndOf: (unit: DurationUnit) => DateTime.local().endOf(unit),

  dateAdd(value: IDateTypes, units: DurationObjectUnits) {
    const dt = DateHelpers.parse(value);

    return dt ? dt.plus(units) : null;
  },

  isDateValid(value: IDateTypes): boolean {
    const dt = DateHelpers.parse(value);

    return dt ? dt.isValid : false;
  },

  isHourValid(value: string | null | undefined): boolean {
    if (!value) return false;

    const h =
      value.length === 5
        ? DateHelpers.fromFormat(value, 'HH:mm')
        : DateHelpers.parse(value);

    return h ? h.isValid : false;
  },

  fromFormat(value: string | null | undefined, format: 'dd/MM/yyyy' | 'HH:mm') {
    const dt = DateTime.fromFormat(value || '', format);

    return dt ? dt : null;
  },

  parse(value: IDateTypes) {
    if (!value) return null;

    if (isString(value)) {
      return DateTime.fromISO(value);
    } else if (isNumber(value)) {
      return DateTime.fromMillis(value);
    } else if (value instanceof Date) {
      return DateTime.fromJSDate(value);
    } else if (value instanceof DateTime) {
      return value;
    }

    return null;
  },

  dateMinuteSlots(date: Date) {
    const minutes = date.getMinutes();

    if (minutes >= 0 && minutes < 15) {
      date.setMinutes(0);
    } else if (minutes >= 15 && minutes < 30) {
      date.setMinutes(15);
    } else if (minutes >= 30 && minutes < 45) {
      date.setMinutes(30);
    } else if (minutes >= 45 && minutes <= 59) {
      date.setMinutes(45);
    }

    return date;
  },

  idade(value: IDateTypes, onlyYear = false) {
    const date = DateHelpers.parse(value);

    if (!date) return null;

    const age = date.diffNow(['years', 'months', 'days', 'hours']);

    const years = -age.years;
    const yearsLabel = years > 1 ? 'anos' : 'ano';

    const months = -age.months;
    const monthsLabel = months > 1 ? 'meses' : 'mês';

    const days = -age.days;
    const daysLabel = days > 1 ? 'dias' : 'dia';

    let result = '';

    if (years) {
      result = `${years} ${yearsLabel}`;
    }

    if (months && !onlyYear) {
      if (result) {
        result += `, ${months} ${monthsLabel}`;
      } else {
        result = `${months} ${monthsLabel}`;
      }
    }

    if (days && !onlyYear) {
      if (result) {
        result += `, ${days} ${daysLabel}`;
      } else {
        result = `${days} ${daysLabel}`;
      }
    }

    return result;
  },

  humanize(value: IDateTypes) {
    const date = DateHelpers.parse(value);

    if (!date) return null;

    const relative = date.toRelative()!;

    if (relative.includes('há')) {
      // passado (ex: 20 minutos atrás)
      return `${date.toRelative()!.replace('há ', '')} atrás`;
    }

    // futuro (ex: em 25 minutos)
    return relative;
  },

  dataAgendamento(agendamento: IAgendamentoFragment | null | undefined) {
    if (!agendamento) return null;

    const data = DateHelpers.parse(agendamento.data)!;

    const diaSemana = capitalize(data.toFormat('cccc'));
    const dia = data.toFormat('dd');
    const mes = capitalize(data.toFormat('MMMM'));

    const horaInicial = DateHelpers.formatHour(agendamento.horaInicial);
    const horaFinal = DateHelpers.formatHour(agendamento.horaFinal);

    return `${diaSemana}, ${dia} de ${mes} - ${horaInicial} às ${horaFinal}`;
  },

  daysInMonth(month: number | string) {
    return DateTime.local(new Date().getFullYear(), +month).daysInMonth;
  },
};
