import { BtnSmall } from '@/components/buttons/BtnSmall';
import { createComponent } from '@/lib/vue';

interface IProps {
  showAdd: boolean;
  showRemove: boolean;
}

interface IEvents {
  onAdd: () => void;
  onRemove: () => void;
}

export const FieldButtons = createComponent<IProps, IEvents>({
  name: 'FieldButtons',
  props: {
    showAdd: { type: Boolean, default: false },
    showRemove: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const emitAdd = () => ctx.emit('add');
    const emitRemove = () => ctx.emit('remove');

    return () => (
      <div class="flex w-1/6 pt-4">
        {props.showRemove && (
          <BtnSmall remove tooltip="Remover" onClick={emitRemove} />
        )}

        {props.showAdd && (
          <BtnSmall add tooltip="Adicionar" onClick={emitAdd} />
        )}
      </div>
    );
  },
});
