import { IFormInput } from '@/typings';
import { computed, nextTick, Ref, SetupContext } from '@vue/composition-api';

export interface IInputProps {
  name?: string;
  input: IFormInput;
  errorMessages?: string[];
}

export function useInput(props: IInputProps) {
  const $isRequired = computed(() => !!props.input.validations?.required);

  const $label = computed(() =>
    $isRequired.value ? `${props.input.label}*` : props.input.label,
  );

  const $isValid = computed(() => !props.errorMessages?.length);

  return { $isRequired, $label, $isValid };
}

export function useInputClearable({
  ctx,
  $inputEl,
}: {
  ctx: SetupContext;
  $inputEl: Ref<HTMLInputElement | null>;
}) {
  function setInputFocus(focus = true) {
    nextTick(() => (focus ? $inputEl.value?.focus() : $inputEl.value?.blur()));
  }

  function handleClear() {
    ctx.emit('input', null);

    // need to await vuetify emit the focus after clear
    nextTick(() => setInputFocus(false));
  }

  return { setInputFocus, handleClear };
}
