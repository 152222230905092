import { IInputItems, TissTipoDocumento } from '@/typings';

export const tipoDocumento: IInputItems[] = [
  {
    label: 'Código na Operadora',
    value: TissTipoDocumento.CODIGO_NA_OPERADORA,
  },
  { label: 'CPF', value: TissTipoDocumento.CPF },
  { label: 'CNPJ', value: TissTipoDocumento.CNPJ },
];
