import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const FinancasSidebar = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/components/FinancasSidebar'
  );
const FinancasDashboardPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/pages/FinancasDashboardPage'
  );
const DespesasPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/despesa/DespesasPage'
  );
const DespesaFormPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/despesa/DespesaFormPage'
  );
const ReceitasPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/receita/ReceitasPage'
  );
const ReceitaFormPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/receita/ReceitaFormPage'
  );
const FinancasRelatorioDespesasPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/pages/relatorios/FinancasRelatorioDespesasPage'
  );
const FinancasRelatorioReceitasPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/pages/relatorios/FinancasRelatorioReceitasPage'
  );
const ExtratoPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/ExtratoPage'
  );
const FluxoCaixaPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/pages/FluxoCaixaPage'
  );
const TransferenciaPage = () =>
  import(
    /* webpackChunkName: "financas" */ '@/modules/financas/transacoes/pages/TransferenciaPage'
  );

export const financasRoutes: IRouteConfig[] = routeGroup({
  prefix: 'financas',
  routes: [
    {
      path: '',
      components: {
        default: FinancasDashboardPage,
        sidebar: FinancasSidebar,
      },
    },
    ...routeGroup({
      prefix: 'despesas',
      routes: [
        {
          path: '',
          components: {
            default: DespesasPage,
            sidebar: FinancasSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: DespesaFormPage,
            sidebar: FinancasSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: DespesaFormPage,
            sidebar: FinancasSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'receitas',
      routes: [
        {
          path: '',
          components: {
            default: ReceitasPage,
            sidebar: FinancasSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ReceitaFormPage,
            sidebar: FinancasSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ReceitaFormPage,
            sidebar: FinancasSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'relatorios',
      routes: [
        {
          path: 'despesas',
          components: {
            default: FinancasRelatorioDespesasPage,
            sidebar: FinancasSidebar,
          },
        },
        {
          path: 'receitas',
          components: {
            default: FinancasRelatorioReceitasPage,
            sidebar: FinancasSidebar,
          },
        },
      ],
    }),

    {
      path: 'extrato',
      components: {
        default: ExtratoPage,
        sidebar: FinancasSidebar,
      },
    },
    {
      path: 'fluxo-caixa',
      components: {
        default: FluxoCaixaPage,
        sidebar: FinancasSidebar,
      },
    },
    {
      path: 'transferencia',
      components: {
        default: TransferenciaPage,
        sidebar: FinancasSidebar,
      },
    },
  ],
});
