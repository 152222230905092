import { createComponent } from '@/lib/vue';

interface IProps {
  loading: boolean;
}

export const AppLoading = createComponent<IProps>({
  name: 'AppLoading',
  props: {
    loading: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    return () => (
      <v-fade-transition>
        {props.loading && (
          <div id="app-loading" class="absolute top-0 left-0 z-1000">
            <img id="logo-loading" src="/logo/logo-white.svg" />

            <div class="spinner">
              <div class="bounce1" />
              <div class="bounce2" />
              <div class="bounce3" />
            </div>
          </div>
        )}
      </v-fade-transition>
    );
  },
});
