import { TissGuiaOdontologicaGraphql } from '@/graphql/tiss/tissGuiaOdontologica/TissGuiaOdontologicaGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissGuiaOdontologicaModel,
  TissGuiaOdontologicaDataInput,
} from '@/typings';

export const TissGuiaOdontologicaService = {
  async getById(id: string) {
    try {
      return await TissGuiaOdontologicaGraphql.tissGuiaOdontologica({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    model: ITissGuiaOdontologicaModel;
    profissionalId: string;
  }) {
    try {
      const result =
        await TissGuiaOdontologicaGraphql.createTissGuiaOdontologica({
          profissionalId: Validate.require(profissionalId, 'profissionalId'),
          data: mapTissGuiaOdontologicaDataInput(model),
        });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'esta guia odontológica' }, async () => {
        const result =
          await TissGuiaOdontologicaGraphql.deleteTissGuiaOdontologica({ id });

        onSuccess({ result, msg: 'excluída' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissGuiaOdontologicaModel) {
    try {
      const result =
        await TissGuiaOdontologicaGraphql.updateTissGuiaOdontologica({
          id: Validate.require(id, 'id'),
          data: mapTissGuiaOdontologicaDataInput(model),
        });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function mapTissGuiaOdontologicaDataInput({
  geral,
  paciente,
  contratado,
  atendimento,
}: ITissGuiaOdontologicaModel): TissGuiaOdontologicaDataInput {
  // TODO: implement mapDataInput
  // TODO: remove as any
  return {} as any;
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).guiasOdontologicas.index);

    toastSuccess(`Guia odontológica ${msg} com sucesso`);
  }
}
