import { createComponent } from '@/lib/vue';
import draggable from 'vuedraggable';

interface IProps {
  value: any[];
  tag?: string;
}

interface IEvents {
  onInput: (items: any[]) => any;
}

export const MyDraggable = createComponent<IProps, IEvents>({
  name: 'MyDraggable',
  components: { draggable },
  props: {
    value: { type: Array, required: true },
    tag: { type: String, default: 'div' },
  },
  setup(props, ctx) {
    function emitInput(items) {
      ctx.emit('input', items);
    }

    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <draggable
          value={props.value}
          handle=".drag-icon"
          animation={150}
          ghostClass="ghost"
          onInput={emitInput}
          tag={props.tag}
        >
          {defaultSlot}
        </draggable>
      );
    };
  },
});
