import { PageSection } from '@/components/page/PageSection';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';

export const ProntuarioRascunhoDialog = createComponent({
  name: 'ProntuarioRascunhoDialog',
  setup(props, ctx) {
    function handleExcluir() {
      ProntuarioService.cache.delete();

      DialogState.cancel();
    }

    return () => (
      <div class="flex flex-col">
        <PageSection class="py-2 text-subtitle text-coolGray-800">
          <div>
            Você iniciou um atendimento para este paciente e não finalizou.
          </div>

          <div class="mt-1 font-medium">
            Deseja continuar a edição do prontuário?
          </div>
        </PageSection>

        <PageSection divider>
          <div class="flex">
            <v-spacer />

            <v-btn text color="primary" onClick={handleExcluir}>
              Excluir rascunho
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="ml-2"
              onClick={DialogState.confirm}
            >
              Continuar
            </v-btn>
          </div>
        </PageSection>
      </div>
    );
  },
});
