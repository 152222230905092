import DashboardAgendamentoProcedimentoPeriodoQuery from '@/graphql/dashboard/dashboardAgendamentoProcedimentoPeriodo.graphql';
import DashboardAgendamentosDistribuicaoEtariaQuery from '@/graphql/dashboard/dashboardAgendamentosDistribuicaoEtaria.graphql';
import DashboardAgendamentosStatusCountQuery from '@/graphql/dashboard/dashboardAgendamentosStatusCount.graphql';
import DashboardAtendimentosCardQuery from '@/graphql/dashboard/dashboardAtendimentosCardQuery.graphql';
import DashboardPacientesAniversariantesQuery from '@/graphql/dashboard/dashboardPacientesAniversariantes.graphql';
import DashboardPacientesCardQuery from '@/graphql/dashboard/dashboardPacientesCardQuery.graphql';
import DashboardPageQuery from '@/graphql/dashboard/dashboardPageQuery.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  IDashboardAgendamentoProcedimentoPeriodoQuery,
  IDashboardAgendamentoProcedimentoPeriodoQueryVariables,
  IDashboardAgendamentosDistribuicaoEtariaQuery,
  IDashboardAgendamentosDistribuicaoEtariaQueryVariables,
  IDashboardAgendamentosStatusCountQuery,
  IDashboardAgendamentosStatusCountQueryVariables,
  IDashboardAtendimentosCardQuery,
  IDashboardAtendimentosCardQueryVariables,
  IDashboardPacientesAniversariantesQuery,
  IDashboardPacientesAniversariantesQueryVariables,
  IDashboardPacientesCardQuery,
  IDashboardPacientesCardQueryVariables,
  IDashboardPageQuery,
  IDashboardPageQueryVariables,
} from '@/typings';

export const DashboardGraphql = {
  query: {
    DashboardAgendamentoProcedimentoPeriodoQuery,
    DashboardAgendamentosDistribuicaoEtariaQuery,
    DashboardAgendamentosStatusCountQuery,
    DashboardAtendimentosCardQuery,
    DashboardPacientesAniversariantesQuery,
    DashboardPacientesCardQuery,
    DashboardPageQuery,
  },

  async agendamentoProcedimentoPeriodo(
    {
      dataFinal,
      dataInicial,
      profissionalId,
    }: IDashboardAgendamentoProcedimentoPeriodoQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardAgendamentoProcedimentoPeriodoQuery,
        IDashboardAgendamentoProcedimentoPeriodoQueryVariables
      >({
        query: DashboardAgendamentoProcedimentoPeriodoQuery,
        variables: {
          where: {
            dataFinal,
            dataInicial,
            profissionalId,
          },
        },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.dashboardAgendamentoProcedimentoPeriodo);
  },

  async atendimentosCard(
    variables: IDashboardAtendimentosCardQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardAtendimentosCardQuery,
        IDashboardAtendimentosCardQueryVariables
      >({
        query: DashboardAtendimentosCardQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data);
  },

  async agendamentosDistribuicaoEtaria(
    {
      dataFinal,
      dataInicial,
      profissionalId,
    }: IDashboardAgendamentosDistribuicaoEtariaQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardAgendamentosDistribuicaoEtariaQuery,
        IDashboardAgendamentosDistribuicaoEtariaQueryVariables
      >({
        query: DashboardAgendamentosDistribuicaoEtariaQuery,
        variables: {
          where: {
            dataFinal,
            dataInicial,
            profissionalId,
          },
        },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.dashboardAgendamentosDistribuicaoEtaria);
  },

  async pacientesCard(
    variables: IDashboardPacientesCardQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardPacientesCardQuery,
        IDashboardPacientesCardQueryVariables
      >({
        query: DashboardPacientesCardQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data);
  },

  async agendamentosStatusCount(
    {
      dataFinal,
      dataInicial,
      profissionalId,
    }: IDashboardAgendamentosStatusCountQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardAgendamentosStatusCountQuery,
        IDashboardAgendamentosStatusCountQueryVariables
      >({
        query: DashboardAgendamentosStatusCountQuery,
        variables: {
          where: {
            dataFinal,
            dataInicial,
            profissionalId,
          },
        },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.dashboardAgendamentosStatusCount);
  },

  async pacientesAniversariantes(
    {
      dia,
      mes,
      mesTodo,
      profissionalId,
    }: IDashboardPacientesAniversariantesQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        IDashboardPacientesAniversariantesQuery,
        IDashboardPacientesAniversariantesQueryVariables
      >({
        query: DashboardPacientesAniversariantesQuery,
        variables: {
          where: {
            dia,
            mes,
            mesTodo,
            profissionalId,
          },
        },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.dashboardPacientesAniversariantes.nodes);
  },

  async dashboardPage(
    variables: IDashboardPageQueryVariables,
    refetch = false,
  ) {
    return apolloClient
      .query<IDashboardPageQuery>({
        query: DashboardPageQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data);
  },
};
