export function tooltipSize(tooltip: string | null | undefined) {
  const length = tooltip?.length || 0;

  if (length > 30 && length < 140) {
    return 'medium';
  } else if (length >= 140) {
    return 'large';
  }

  return 'fit';
}
