import { uuid } from '@/lib/helpers/uuid';
import { IAppState, IDialogOpenPayload, IDialogState } from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

// TODO: multiple dialogs
export const stateFn = (): IDialogState => ({
  id: uuid(),
  open: false,
  title: null,
  width: null,
  fullWidth: false,
  component: null,
  params: null,
  noCloseBtn: false,
  resolve: null,
  toolbarBtns: null,
});

export const DialogModule: Module<IDialogState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IDialogState>('dialog');

export class DialogState {
  static open({
    component,
    params = null,
    width,
    fullWidth,
    title,
    toolbarBtns,
    resolve: confirmation,
  }: IDialogOpenPayload<any>) {
    commit(s => {
      s.id = uuid();
      s.component = component;
      s.title = title;
      s.width = width || null;
      s.toolbarBtns = toolbarBtns || null;
      s.fullWidth = !!fullWidth;
      s.params = params;
      s.resolve = confirmation;
      s.open = true;
    });
  }

  static cancel() {
    commit(s => {
      if (s.resolve) {
        s.resolve({
          confirmed: false,
        });
      }

      reset(s);
    });
  }

  static confirm(payload = {}) {
    commit(s => {
      if (s.resolve) {
        s.resolve({
          confirmed: true,
          ...payload,
        });
      }

      reset(s);
    });
  }

  static setNoClose(noClose: boolean) {
    commit(s => {
      s.noCloseBtn = noClose;
    });
  }
}

function reset(state: IDialogState) {
  const padrao = stateFn();

  state.id = padrao.id;
  state.open = padrao.open;
  state.component = padrao.component;
  state.title = padrao.title;
  state.params = padrao.params;
  state.noCloseBtn = padrao.noCloseBtn;
  state.resolve = padrao.resolve;
}
