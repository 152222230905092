import { TissGuiaSpSadtGraphql } from '@/graphql/tiss/tissGuiaSpSadt/TissGuiaSpSadtGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissGuiaSpSadtModel,
  TissGuiaSpSadtDataInput,
} from '@/typings';

export const TissGuiaSpSadtService = {
  async getById(id: string) {
    try {
      return await TissGuiaSpSadtGraphql.tissGuiaSpSadt({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    model: ITissGuiaSpSadtModel;
    profissionalId: string;
  }) {
    try {
      const result = await TissGuiaSpSadtGraphql.createTissGuiaSpSadt({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: mapTissGuiaSpSadtDataInput(model),
      });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'esta guia SP/SADT' }, async () => {
        const result = await TissGuiaSpSadtGraphql.deleteTissGuiaSpSadt({ id });

        onSuccess({ result, msg: 'excluída' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissGuiaSpSadtModel) {
    try {
      const result = await TissGuiaSpSadtGraphql.updateTissGuiaSpSadt({
        id: Validate.require(id, 'id'),
        data: mapTissGuiaSpSadtDataInput(model),
      });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function mapTissGuiaSpSadtDataInput({
  geral,
  paciente,
  contratado,
  atendimento,
}: ITissGuiaSpSadtModel): TissGuiaSpSadtDataInput {
  // TODO: implement mapDataInput
  // TODO: remove as any
  return {} as any;
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).guiasSpSadt.index);

    toastSuccess(`Guia SP/SADT ${msg} com sucesso`);
  }
}
