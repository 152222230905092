import CreateUploadUrlMutation from '@/graphql/system/fileUpload/createUploadUrl.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateUploadUrlMutation,
  ICreateUploadUrlMutationVariables,
  ICreateUploadUrlMutation_createUploadUrl_file,
} from '@/typings';
import axios from 'axios';

export const FileUploadGraphql = {
  async createUploadUrl(
    variables: ICreateUploadUrlMutationVariables,
  ): Promise<IFileUploadUrl | undefined> {
    return apolloClient
      .mutate<ICreateUploadUrlMutation>({
        mutation: CreateUploadUrlMutation,
        variables,
      })
      .then(({ data }) => data?.createUploadUrl);
  },

  async uploadFile({
    url,
    file,
    fields,
  }: {
    url: string;
    file: File | string;
    fields: IFileUploadUrlFields;
  }) {
    return axios.post(
      url,
      mapFormData({
        ...fields,
        file,
      }),
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  },
};

function mapFormData(object: object) {
  return Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);

    return formData;
  }, new FormData());
}

interface IFileUploadUrl {
  file: ICreateUploadUrlMutation_createUploadUrl_file;
  url: string;
  fields: IFileUploadUrlFields;
}

interface IFileUploadUrlFields {
  key: string;
  'content-type': string;
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  Policy: string;
  'X-Amz-Signature': string;
}
