export const onlyNumbers = (input: string | number | null) =>
  (input || '').toString().replace(/\D+/g, '') || '0';

export interface IFormatNumberOptions {
  money?: boolean;
  integer?: boolean;
  locale?: string;
  currency?: string;
  prefix?: string;
  suffix?: string;
  precision?: number;
}

export function formatNumber(
  value: string | number,
  opts?: IFormatNumberOptions,
) {
  value = Number(value) || 0; // if isNaN defaults to 0

  const isMoney = opts?.money ?? defaultOpts.money;
  const locale = opts?.locale || defaultOpts.locale;
  const currency = opts?.currency || defaultOpts.currency;
  const prefix = opts?.prefix || defaultOpts.prefix;
  const suffix = opts?.suffix || defaultOpts.suffix;
  const integer = opts?.integer ?? defaultOpts.integer;

  const precision = integer ? 0 : opts?.precision || defaultOpts.precision;

  const formatted = value.toLocaleString(locale, {
    style: isMoney ? 'currency' : 'decimal',
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
    currency,
  });

  return `${prefix} ${formatted} ${suffix}`.trim();
}

const defaultOpts: IFormatNumberOptions = {
  money: true,
  integer: false,
  locale: 'pt-BR',
  currency: 'BRL',
  prefix: '',
  suffix: '',
  precision: 2,
};

export const formatMoney = (value: string | number) =>
  formatNumber(value, { money: true });

export const formatInteger = (value: string | number) =>
  formatNumber(value, { money: false, integer: true });

export const formatDecimal = (
  value: string | number,
  options: Pick<IFormatNumberOptions, 'precision' | 'suffix'> = {},
) => formatNumber(value, { money: false, ...options });

export function isNumeric(value: string) {
  const n = parseFloat(value);

  return !isNaN(n) && isFinite(n);
}
