import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { AgendaState } from '@/store/modules/agenda.store';
import {
  AgendamentoStatus,
  AgendamentoUpdateDataInput,
  IAgendamentoFormModel,
  IAgendamentoTimeModel,
} from '@/typings';
import { agendamentoOnSuccess } from './agendamento.service';

export const AgendamentoUpdate = {
  async update({
    id,
    profissionalId,
    model: {
      time: { data, horaInicial, horaFinal, diaTodo },
      ...model
    },
  }: {
    id: string;
    profissionalId: string;
    model: Omit<IAgendamentoFormModel, 'recorrencia'>;
  }) {
    try {
      const bloqueado = model.tipo.tipo === 'Horário bloqueado';

      const result = await AgendamentoGraphql.updateAgendamento({
        input: {
          id: Validate.require(id, 'id'),
          data: toAgendamentoUpdateDataInput(model),
          time: {
            data: Validate.date(data, 'data'),
            horaInicial: Validate.time(horaInicial, 'horaInicial'),
            horaFinal: Validate.time(horaFinal, 'horaFinal'),
            diaTodo: bloqueado && diaTodo,
          },
        },
      });

      agendamentoOnSuccess({
        result,
        msg: 'alterado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateTime({
    id,
    profissionalId,
    time: { data, horaInicial, horaFinal, diaTodo },
  }: {
    id: string;
    profissionalId: string;
    time: IAgendamentoTimeModel;
  }) {
    try {
      const result = await AgendamentoGraphql.updateAgendamentoTime({
        id: Validate.require(id, 'id'),
        time: {
          data: Validate.date(data, 'data'),
          horaInicial: Validate.time(horaInicial, 'horaInicial'),
          horaFinal: Validate.time(horaFinal, 'horaFinal'),
          diaTodo,
        },
      });

      agendamentoOnSuccess({
        result,
        msg: 'alterado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateStatus({
    id,
    status,
  }: {
    id: string;
    status: AgendamentoStatus;
  }) {
    try {
      AgendaState.setAgendamentoStatus(status);

      const result = await AgendamentoGraphql.updateAgendamento({
        input: {
          id: Validate.require(id, 'id'),
          status,
        },
      });

      if (result) {
        toastSuccess('Agendamento status alterado com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toAgendamentoUpdateDataInput({
  tipo: { tipo },
  procedimentos,
  paciente: { nome, pacienteId, celular, convenioId, telefoneCasa, email },
  outrasInformacoes: { observacao },
}: Omit<
  IAgendamentoFormModel,
  'time' | 'recorrencia'
>): AgendamentoUpdateDataInput {
  const bloqueado = tipo === 'Horário bloqueado';

  if (!bloqueado && !pacienteId) {
    Validate.require(nome, 'paciente.nome');
    Validate.either(
      [celular, telefoneCasa],
      ['paciente.celular', 'paciente.telefoneCasa'],
    );
  }
  return {
    bloqueado,
    procedimentos: procedimentos?.map(v => ({
      procedimentoId: Validate.require(v.procedimentoId, 'procedimentoId'),
      quantidade: Validate.require(v.quantidade, 'quantidade'),
    })),
    paciente: {
      id: pacienteId,
      nome,
      celular,
      telefoneCasa,
      email,
    },
    observacao,
    convenioId,
  };
}
