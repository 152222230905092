import { apolloClient } from '@/plugins/apollo';

import Cid10LookupsQuery from '@/graphql/lookups/cid10/cid10Lookups.graphql';
import ConvenioLookupsQuery from '@/graphql/lookups/convenio/convenioLookups.graphql';
import TussLookupsQuery from '@/graphql/lookups/tuss/tussLookups.graphql';

import {
  ICid10LookupsQuery,
  ICid10LookupsQueryVariables,
  IConvenioLookupsQuery,
  IConvenioLookupsQueryVariables,
  ITussLookupsQuery,
  ITussLookupsQueryVariables,
} from '@/typings';

export const LookupsGraphql = {
  query: {
    Cid10LookupsQuery,
    ConvenioLookupsQuery,
    TussLookupsQuery,
  },

  async cid10Lookups(variables: ICid10LookupsQueryVariables) {
    return apolloClient
      .query<ICid10LookupsQuery>({
        query: Cid10LookupsQuery,
        variables,
      })
      .then(({ data }) => data?.cid10Lookups);
  },

  async convenioLookups(variables: IConvenioLookupsQueryVariables) {
    return apolloClient
      .query<IConvenioLookupsQuery>({
        query: ConvenioLookupsQuery,
        variables,
      })
      .then(({ data }) => data?.convenioLookups);
  },

  async tussLookups(variables: ITussLookupsQueryVariables) {
    return apolloClient
      .query<ITussLookupsQuery>({
        query: TussLookupsQuery,
        variables,
      })
      .then(({ data }) => data?.tussLookups);
  },
};
