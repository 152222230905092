import { router } from '@/routes';

export function useRouteParams() {
  const route = router.currentRoute;

  const profissionalId = route.params.profissionalId;
  const id = route.params.id;

  return { profissionalId, id };
}

export function useRoute() {
  return router.currentRoute;
}

export function useRouter() {
  return router;
}
