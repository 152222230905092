import { TissGuiaHonorariosGraphql } from '@/graphql/tiss/tissGuiaHonorarios/TissGuiaHonorariosGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissGuiaHonorariosModel,
  TissGuiaHonorariosDataInput,
} from '@/typings';

export const TissGuiaHonorariosService = {
  async getById(id: string) {
    try {
      return await TissGuiaHonorariosGraphql.tissGuiaHonorarios({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    model: ITissGuiaHonorariosModel;
    profissionalId: string;
  }) {
    try {
      const result = await TissGuiaHonorariosGraphql.createTissGuiaHonorarios({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: mapTissGuiaHonorariosDataInput(model),
      });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'esta guia de honorários' }, async () => {
        const result = await TissGuiaHonorariosGraphql.deleteTissGuiaHonorarios(
          { id },
        );

        onSuccess({ result, msg: 'excluída' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissGuiaHonorariosModel) {
    try {
      const result = await TissGuiaHonorariosGraphql.updateTissGuiaHonorarios({
        id: Validate.require(id, 'id'),
        data: mapTissGuiaHonorariosDataInput(model),
      });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function mapTissGuiaHonorariosDataInput({
  geral,
  paciente,
  contratado,
  atendimento,
}: ITissGuiaHonorariosModel): TissGuiaHonorariosDataInput {
  // TODO: implement mapDataInput
  // TODO: remove as any
  return {} as any;
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).guiasHonorarios.index);

    toastSuccess(`Guia de honorários ${msg} com sucesso`);
  }
}
