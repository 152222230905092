import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioHipoteseDiagnosticaForm } from '../../components/forms/ProntuarioHipoteseDiagnosticaForm';

export default createComponent({
  name: 'ProntuarioHipoteseDiagnosticaPage',
  setup(props, ctx) {
    return () => (
      <div class="pt-2">
        <ProntuarioHipoteseDiagnosticaForm
          onSubmit={() => ProntuarioService.cache.save()}
        />
      </div>
    );
  },
});
