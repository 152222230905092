import { dashboardGuard } from '@/routes/guards/dashboard.guard';
import { sidebarAgenda } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const DashboardPage = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/modules/dashboard/DashboardPage'
  );

export const dashboardRoutes: IRouteConfig[] = [
  {
    path: 'dashboard',
    beforeEnter: dashboardGuard,
    components: {
      default: DashboardPage,
      sidebar: sidebarAgenda,
    },
  },
];
