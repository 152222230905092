import {
  IAtendimentoFragment,
  IProntuarioState,
  TimelineFilterItem,
} from '@/typings';
import cloneDeep from 'lodash/cloneDeep';

export function filterTimelineEvents({
  events,
  filter,
}: Readonly<IProntuarioState>): IAtendimentoFragment[] {
  if (!filter) {
    return events;
  }

  return events
    .map(ev => {
      if (filterIsFilterItem(filter)) {
        if (!ev[filter]) {
          return null;
        }

        return mapEvent({ ev, key: filter });
      } else if (ev.eventos?.length) {
        const eventos = ev.eventos.filter(f => f.prontuarioSecaoId === filter);

        if (!eventos.length) {
          return null;
        }

        return mapEvent({
          ev,
          key: 'eventos',
          originalValue: eventos,
        });
      }
      return null;
    })
    .filter(Boolean) as IAtendimentoFragment[];
}

function filterIsFilterItem(filter: any): filter is TimelineFilterItem {
  return [
    TimelineFilterItem.preAtendimento,
    TimelineFilterItem.hipoteseDiagnostica,
    TimelineFilterItem.prescricoes,
    TimelineFilterItem.atestados,
    TimelineFilterItem.exames,
    TimelineFilterItem.files,
  ].includes(filter);
}

function mapEvent<K extends keyof IAtendimentoFragment>({
  ev: oldEv,
  key,
  originalValue,
}: {
  ev: IAtendimentoFragment;
  key: K;
  originalValue?: IAtendimentoFragment[K];
}) {
  const ev = cloneDeep(oldEv);

  ev.preAtendimento = null;
  ev.hipoteseDiagnostica = null;
  ev.prescricoes = null;
  ev.atestados = null;
  ev.exames = null;
  ev.files = [];
  ev.eventos = null;

  ev[key] = originalValue || oldEv[key];

  return ev;
}
