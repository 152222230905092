import {
  AgendamentoStatus,
  IAgendamentoFragment,
  IAgendaState,
  IAppState,
  IConfiguracaoAgendaFragment,
  IPacienteStats,
} from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): IAgendaState => ({
  profissionalId: null,
  config: null,
  agendamento: null,
  pacienteStats: null,
  agendamentosDia: [],
});

export const AgendaModule: Module<IAgendaState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IAgendaState>('agenda');

export class AgendaState {
  static initialLoad({
    profissionalId,
    config,
  }: {
    profissionalId: string;
    config: IConfiguracaoAgendaFragment;
  }) {
    commit(s => {
      s.profissionalId = profissionalId;
      s.config = config;
    });
  }

  static setAgendamento(agendamento: IAgendamentoFragment | null) {
    commit(s => {
      s.agendamento = agendamento ? { ...agendamento } : null;
    });
  }

  static setAgendamentoStatus(status: AgendamentoStatus) {
    commit(s => {
      if (!s.agendamento) return;

      s.agendamento.status = status;
    });
  }

  static setPacienteStats(stats?: IPacienteStats | null) {
    commit(s => {
      s.pacienteStats = stats || null;
    });
  }

  static setAgendamentosDia(agendamentos: IAgendamentoFragment[]) {
    commit(s => {
      s.agendamentosDia = agendamentos;
    });
  }
}
