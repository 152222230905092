import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IFormSchema, IPasswordDialogModel } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IPasswordDialogModel> {}

interface IEvents extends IFormEvents<IPasswordDialogModel> {}

export const PasswordDialogForm = createComponent<IProps, IEvents>({
  name: 'PasswordDialogForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    return () => (
      <MyForm
        submitLabel="Avançar"
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <FormFields
          form={$form.value}
          schema={$schema.value}
          v-model={$form.value.model}
          class="mx-4 mb-4"
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IPasswordDialogModel = { password: null };

  return useDialogFormConfig<
    IPasswordDialogModel,
    IFormSchema<IPasswordDialogModel>
  >({
    page: props.page,
    initialValue: props.initialValue || defaultValue,
    mapSchema: () => ({
      password: {
        label: 'Senha',
        type: 'password',
        autofocus: true,
        validations: {
          required: true,
        },
      },
    }),
    ctx,
  });
}
