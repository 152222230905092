import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { IServiceOnSuccessProfissionalArgs } from '@/typings';
import { AgendamentoConfirmacao } from './agendamentoConfirmacao';
import { AgendamentoCreate } from './agendamentoCreate';
import { AgendamentoDelete } from './agendamentoDelete';
import { AgendamentoGet } from './agendamentoGet';
import { AgendamentoRecebimento } from './agendamentoRecebimento';
import { AgendamentoState } from './agendamentoState';
import { AgendamentoUpdate } from './agendamentoUpdate';

export const AgendamentoService = {
  ...AgendamentoConfirmacao,
  ...AgendamentoCreate,
  ...AgendamentoDelete,
  ...AgendamentoGet,
  ...AgendamentoRecebimento,
  ...AgendamentoState,
  ...AgendamentoUpdate,
};

export function agendamentoOnSuccess<T>({
  result,
  msg,
  profissionalId,
  full,
}: IServiceOnSuccessProfissionalArgs<T> & { full?: boolean }) {
  if (result) {
    const indexRoute = Routes.app.agenda(profissionalId).index;

    if (router.currentRoute.path !== indexRoute) {
      router.push(indexRoute);
    }

    toastSuccess((full && msg) || `Agendamento ${msg} com sucesso`);
  }
}
