import { IProntuarioPreAtendimentoIMC } from '@/typings';
import round from 'lodash/round';

export const calcularIMC = ({
  altura,
  peso,
}: {
  altura: number | null;
  peso: number | null;
}): IProntuarioPreAtendimentoIMC | null => {
  if (!altura || !peso || altura < 1 || altura > 3 || peso < 10) {
    return null;
  }

  const value = round(peso / (altura * altura), 1);
  if (value < 17) {
    return { value, status: 'muito abaixo do peso' };
  } else if (value >= 17 && value < 18.5) {
    return { value, status: 'abaixo do peso' };
  } else if (value >= 18.5 && value < 25) {
    return { value, status: 'peso normal' };
  } else if (value >= 25 && value < 30) {
    return { value, status: 'acima do peso' };
  } else if (value >= 30 && value < 35) {
    return { value, status: 'obesidade I' };
  } else if (value >= 35 && value < 40) {
    return { value, status: 'obesidade II (severa)' };
  } else if (value >= 40) {
    return { value, status: 'obesidade III (mórbida)' };
  }

  return null;
};
