import { useFormPage } from '@/lib/composables/form/useFormPage';
import { AuthLoginService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { IPasswordDialogModel } from '@/typings';
import { PasswordDialogForm } from './passwordDialog/PasswordDialogForm';

export const PasswordDialog = createComponent({
  name: 'PasswordDialog',
  setup(props, ctx) {
    DialogState.setNoClose(true);

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(model: IPasswordDialogModel) {
        const result = await AuthLoginService.checkPassword(model);

        if (result) {
          DialogState.confirm(model);
        }

        DialogState.setNoClose(false);
      },
    });

    return () => (
      <div id="PasswordDialog" class="flex flex-col">
        <div class="m-4 text-subtitle text-coolGray-600">
          Digite sua senha para continuar
        </div>

        <PasswordDialogForm
          page={page}
          onSubmit={handleSubmit}
          onCancel={DialogState.cancel}
        />
      </div>
    );
  },
});

export default PasswordDialog;
