import { atestadosFilterValid } from '@/lib/helpers/models/prontuario/atendimento';
import { uuid } from '@/lib/helpers/uuid';
import { IProntuarioAtestadoFormModel, IProntuarioState } from '@/typings';
import { ProntuarioState } from '..';
import { todayDate } from '../utils';

export const ProntuarioModelAtestadosState = {
  add() {
    ProntuarioState.commit(s => {
      s.forms.atestados.model.push({
        id: uuid(),
        data: todayDate(),
        titulo: null,
        texto: null,
      });

      setHasError(s);
    });
  },

  setModel({ id, ...model }: IProntuarioAtestadoFormModel) {
    ProntuarioState.commit(s => {
      s.forms.atestados.model = s.forms.atestados.model.map(v => {
        if (v.id !== id) {
          return v;
        }

        return {
          id,
          ...model,
        };
      });

      setHasError(s);
    });
  },

  remove(id: string) {
    ProntuarioState.commit(s => {
      s.forms.atestados.model = s.forms.atestados.model.filter(
        f => f.id !== id,
      );

      setHasError(s);
    });
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.forms.atestados.submitted = submitted;
    });
  },
};

function setHasError(s: IProntuarioState) {
  s.forms.atestados.hasError = checkHasError(s);

  if (s.atendimento.submitted && s.forms.atestados.hasError) {
    s.forms.atestados.submitted = true;
  }
}

function checkHasError(s: IProntuarioState): boolean {
  const { model } = s.forms.atestados;

  const isValid = model
    .filter(atestadosFilterValid)
    .every(v => !!v.data && !!v.titulo && !!v.texto);

  return !isValid;
}
