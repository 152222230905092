import {
  IAppState,
  ITissState,
  ITussLookupSearchState,
  ITussLookupSelectedState,
  TissTabela,
} from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): ITissState => ({
  next: [],
  tussLookup: {
    tabelas: [],
    search: {
      tabela: null,
      text: null,
    },
    selected: null,
  },
});

export const TissModule: Module<ITissState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<ITissState>('tiss');

export class TissState {
  static setNext({
    guia,
    lote,
    profissionalId,
  }: {
    guia?: number;
    lote?: number;
    profissionalId: string;
  }) {
    commit(s => {
      const found = s.next.find(f => f.profissionalId === profissionalId);
      s.next = [
        {
          guia: guia ? guia : found?.guia || null,
          lote: lote ? lote : found?.lote || null,
          profissionalId,
        },
        ...s.next.filter(f => f.profissionalId !== profissionalId),
      ];
    });
  }

  static tussLookup = {
    reset(tabelas: TissTabela[] = []) {
      commit(s => {
        s.tussLookup.tabelas = tabelas;

        s.tussLookup.selected = null;

        s.tussLookup.search = {
          text: null,
          tabela: null,
        };
      });
    },

    setSearch({ text, tabela }: Partial<ITussLookupSearchState>) {
      commit(s => {
        const model = s.tussLookup.search;

        s.tussLookup.search = {
          text: text || model.text,
          tabela: tabela || model.tabela,
        };
      });
    },

    setSelected(v: ITussLookupSelectedState | null) {
      commit(s => {
        s.tussLookup.selected = v;
      });
    },
  };
}
