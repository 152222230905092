// tslint:disable: trailing-comma

import { routeGroup } from '@/routes/utils';
import { IRouteConfig } from '@/typings';

const LogsSidebar = () =>
  import(
    /* webpackChunkName: "logs" */ '@/modules/logs/components/LogsSidebar'
  );
const LogsAgendaPage = () =>
  import(/* webpackChunkName: "logs" */ '@/modules/logs/pages/LogsAgendaPage');
const LogsAgendamentoPage = () =>
  import(
    /* webpackChunkName: "logs" */ '@/modules/logs/pages/LogsAgendamentoPage'
  );
const LogsFinancasPage = () =>
  import(
    /* webpackChunkName: "logs" */ '@/modules/logs/pages/LogsFinancasPage'
  );

export const logsRoutes: IRouteConfig[] = routeGroup({
  prefix: 'logs',
  routes: [
    ...routeGroup({
      prefix: 'agenda',
      routes: [
        {
          path: '',
          components: {
            default: LogsAgendaPage,
            sidebar: LogsSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: LogsAgendamentoPage,
            sidebar: LogsSidebar,
          },
        },
      ],
    }),
    {
      path: 'financas',
      components: {
        default: LogsFinancasPage,
        sidebar: LogsSidebar,
      },
    },
  ],
});
