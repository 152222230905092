import { IFluxoCaixaType } from '@/typings';
import { FluxoCaixaState } from '.';
import { mapFluxoCaixaDates } from './utils';

export const FluxoCaixaHeaderState = {
  setTipo(tipo: IFluxoCaixaType) {
    FluxoCaixaState.commit(s => {
      s.header.type = tipo;

      FluxoCaixaHeaderState.setDates(s.header.dates.current);
    });
  },

  setDates(date: string | null) {
    FluxoCaixaState.commit(s => {
      s.header.dates = mapFluxoCaixaDates({
        current: date,
        type: s.header.type,
      });
    });
  },
};
