import { relatoriosGuard } from '@/routes/guards/relatorios.guard';
import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const RelatoriosSidebar = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/components/RelatoriosSidebar'
  );
const RelatorioAtendimentoPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoPage'
  );
const RelatorioAtendimentoCidPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoCidPage'
  );
const RelatorioAtendimentoFaltasPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoFaltasPage'
  );
const RelatorioAtendimentoIndicacaoPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoIndicacaoPage'
  );
const RelatorioAtendimentoPeriodoPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoPeriodoPage'
  );
const RelatorioAtendimentoRetornoPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/atendimento/RelatorioAtendimentoRetornoPage'
  );
const RelatorioFinancasDespesasPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/financas/RelatorioFinancasDespesasPage'
  );
const RelatorioFinancasFluxoCaixaPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/financas/RelatorioFinancasFluxoCaixaPage'
  );
const RelatorioFinancasReceitasPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/financas/RelatorioFinancasReceitasPage'
  );
const RelatorioAniversariantesPage = () =>
  import(
    /* webpackChunkName: "relatorios" */ '@/modules/relatorios/pages/RelatorioAniversariantesPage'
  );

export const relatoriosRoutes: IRouteConfig[] = routeGroup({
  prefix: 'relatorios',
  beforeEnter: relatoriosGuard,
  routes: [
    ...routeGroup({
      prefix: 'atendimento',
      routes: [
        {
          path: '',
          components: {
            default: RelatorioAtendimentoPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'cid',
          components: {
            default: RelatorioAtendimentoCidPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'faltas',
          components: {
            default: RelatorioAtendimentoFaltasPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'indicacao',
          components: {
            default: RelatorioAtendimentoIndicacaoPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'periodo',
          components: {
            default: RelatorioAtendimentoPeriodoPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'retorno',
          components: {
            default: RelatorioAtendimentoRetornoPage,
            sidebar: RelatoriosSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'financas',
      routes: [
        {
          path: 'despesas',
          components: {
            default: RelatorioFinancasDespesasPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'fluxo-caixa',
          components: {
            default: RelatorioFinancasFluxoCaixaPage,
            sidebar: RelatoriosSidebar,
          },
        },
        {
          path: 'receitas',
          components: {
            default: RelatorioFinancasReceitasPage,
            sidebar: RelatoriosSidebar,
          },
        },
      ],
    }),

    {
      path: 'aniversariantes',
      components: {
        default: RelatorioAniversariantesPage,
        sidebar: RelatoriosSidebar,
      },
    },
  ],
});
