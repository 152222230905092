import { IDateRangeModel } from '@/typings';
import { DateHelpers } from '../helpers/date.helpers';

export const DateRangePresets = {
  tudo: {
    label: 'Tudo',
    startDate: null,
    endDate: null,
  },
  hoje: {
    label: 'Hoje',
    startDate: DateHelpers.today().toSQLDate(),
    endDate: DateHelpers.today().toSQLDate(),
  },
  estaSemana: {
    label: 'Esta semana',
    startDate: DateHelpers.weekStart(DateHelpers.today())!.toISODate(),
    endDate: DateHelpers.weekEnd(DateHelpers.today())!.toISODate(),
  },
  esteMes: {
    label: 'Este mês',
    startDate: DateHelpers.dateStartOf('month').toSQLDate(),
    endDate: DateHelpers.dateEndOf('month').toSQLDate(),
  },
  proximoMes: {
    label: 'Próximo mês',
    startDate: DateHelpers.today()
      .plus({ months: 1 })
      .startOf('month')
      .toSQLDate(),
    endDate: DateHelpers.today().plus({ months: 1 }).endOf('month').toSQLDate(),
  },
  proximo90dias: {
    label: 'Próximos 90 dias',
    startDate: DateHelpers.today().toSQLDate(),
    endDate: DateHelpers.today().plus({ days: 90 }).toSQLDate(),
  },
  ultimos30dias: {
    label: 'Últimos 30 dias',
    startDate: DateHelpers.today().minus({ days: 30 }).toSQLDate(),
    endDate: DateHelpers.today().toSQLDate(),
  },
  ultimos90dias: {
    label: 'Últimos 90 dias',
    startDate: DateHelpers.today().minus({ days: 90 }).toSQLDate(),
    endDate: DateHelpers.today().toSQLDate(),
  },
} as const;

export const dateRangePresetsDefaults: IDateRangeModel[] =
  Object.values(DateRangePresets);

export const dateRangePresetsDashboard: IDateRangeModel[] = [
  DateRangePresets.hoje,
  DateRangePresets.estaSemana,
  DateRangePresets.esteMes,
  DateRangePresets.ultimos30dias,
  DateRangePresets.ultimos90dias,
];
