import { store } from '@/store';
import { DialogState } from '@/store/modules/dialog.store';
import {
  IDialogConfirmation,
  IDialogOpenPayload,
  IDialogResolveFn,
} from '@/typings';
import { wait } from '../utils';
import { AgendamentoDialogs } from './agendamento.dialogs';
import { PacienteDialogs } from './paciente.dialogs';
import { ProntuarioDialogs } from './prontuario.dialogs';
import { SystemDialogs } from './system.dialogs';
import { TissDialogs } from './tiss.dialogs';

export const DialogHelpers = {
  system: SystemDialogs,
  agendamento: AgendamentoDialogs,
  paciente: PacienteDialogs,
  prontuario: ProntuarioDialogs,
  tiss: TissDialogs,
};

export function showDialog<T, R = IDialogConfirmation>(
  params: Omit<IDialogOpenPayload<T>, 'resolve'>,
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve: IDialogResolveFn<R>) => {
    if (store.state.dialog.open) {
      DialogState.cancel();

      await wait(100);
    }

    const payload: IDialogOpenPayload<T> = {
      ...params,
      resolve,
    };

    DialogState.open(payload);
  });
}
