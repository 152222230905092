import { ConfiguracaoGraphql } from '@/graphql/configuracao/ConfiguracaoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { IConfiguracaoFinanceiraModel } from '@/typings';

export const ConfiguracaoFinanceiraService = {
  async get() {
    try {
      return await ConfiguracaoGraphql.configuracaoFinanceira();
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    padroes: { formaPagamento, centroCustoId, contaFinanceiraId },
    recebimentosAtendimento: { recebimentoLancamento, lancarAPartir },
  }: IConfiguracaoFinanceiraModel) {
    try {
      const result = await ConfiguracaoGraphql.updateConfiguracaoFinanceira({
        data: {
          contaFinanceiraId: Validate.require(
            contaFinanceiraId,
            'contaFinanceiraId',
          ),
          centroCustoId,
          formaPagamento: Validate.require(formaPagamento, 'formaPagamento'),
          recebimentoLancamento,
          recebimentoLancarAPartir: Validate.require(
            lancarAPartir,
            'lancarAPartir',
          ),
        },
      });

      if (result) {
        toastSuccess('Configuração Financeira atualizada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};
