import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IFormSchema, ISalvarModeloDialogFormModel } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<ISalvarModeloDialogFormModel> {}

interface IEvents extends IFormEvents<ISalvarModeloDialogFormModel> {}

export const SalvarModeloDialogForm = createComponent<IProps, IEvents>({
  name: 'SalvarModeloDialogForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <FormFields
          form={$form.value}
          schema={$schema.value}
          v-model={$form.value.model}
          class="mx-4 mt-6 mb-2"
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: ISalvarModeloDialogFormModel = { nome: null };

  return useDialogFormConfig<
    ISalvarModeloDialogFormModel,
    IFormSchema<ISalvarModeloDialogFormModel>
  >({
    page: props.page,
    initialValue: props.initialValue || defaultValue,
    mapSchema: () => ({
      nome: {
        label: 'Nome',
        type: 'text',
        autofocus: true,
        validations: { required: true },
      },
    }),
    ctx,
  });
}
