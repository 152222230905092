export const appMainScrollToTop = (smooth = true) => {
  const mainContentWrapper = document.getElementsByClassName('v-main__wrap')[0];

  if (mainContentWrapper) {
    mainContentWrapper.scroll({
      top: 0,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};
