import { IContaFinanceiraFragment } from '@/typings';
import { FluxoCaixaState } from '.';

export const FluxoCaixaContasFinanceirasState = {
  setLoading(loading: boolean) {
    FluxoCaixaState.commit(s => {
      s.contasFinanceiras.loading = loading;
    });
  },

  setAll(contas: IContaFinanceiraFragment[]) {
    FluxoCaixaState.commit(s => {
      s.contasFinanceiras.all = contas;

      s.contasFinanceiras.loading = false;
    });
  },

  setIds(ids: string[]) {
    FluxoCaixaState.commit(s => {
      s.contasFinanceiras.ids = ids;
    });
  },
};
