import { IInputItems } from '@/typings';

export const outrasDespesas: IInputItems[] = [
  { label: '01 - Gases medicinais', value: '01' },
  { label: '02 - Medicamentos', value: '02' },
  // 04 - Taxas diversas => Inativo
  { label: '03 - Materiais', value: '03' },
  { label: '05 - Diárias', value: '05' },
  // 06 - Aluguéis => Inativo
  { label: '07 - Taxas e aluguéis', value: '07' },
  { label: '08 - OPME', value: '08' },
];
