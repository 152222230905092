import { formatTelefone } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

interface IOptions {
  small?: boolean;
  classes?: string;
}

interface IPacienteInfoProps {
  value: string | null | undefined;
  icon: string;
  small?: boolean;
  classes?: string;
}

const pacienteInfoProps = {
  small: { type: Boolean, default: false },
  classes: { type: String },
};

export const PacienteInfo = createComponent<IPacienteInfoProps>({
  name: 'PacienteInfo',
  props: {
    ...pacienteInfoProps,
    value: { type: String },
    icon: { type: String },
  },
  setup(props, ctx) {
    return () =>
      props.value && (
        <div
          class={['flex flex-initial whitespace-pre space-x-1', props.classes]}
        >
          <v-icon size={!props.small ? 18 : 16}>{props.icon}</v-icon>

          <span>{props.value}</span>
        </div>
      );
  },
});

export const PacienteFones = createComponent<
  {
    celular: string | null;
    telefoneCasa: string | null;
  } & IOptions
>({
  name: 'PacienteFones',
  props: {
    ...pacienteInfoProps,
    celular: { type: String },
    telefoneCasa: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center flex-none space-x-3">
        <PacienteInfo
          value={formatTelefone(props.celular)}
          icon={MyIcons.cellphone}
          small={props.small}
          classes={props.classes}
        />

        <PacienteInfo
          value={formatTelefone(props.telefoneCasa)}
          icon={MyIcons.phone}
          small={props.small}
          classes={props.classes}
        />
      </div>
    );
  },
});

export const PacienteEmail = createComponent<
  { email: string | null } & IOptions
>({
  name: 'PacienteEmail',
  props: {
    ...pacienteInfoProps,
    email: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <PacienteInfo
        value={props.email}
        icon={MyIcons.at}
        small={props.small}
        classes={props.classes}
      />
    );
  },
});

export const PacienteIdade = createComponent<
  { dataNascimento: string | null } & IOptions
>({
  name: 'PacienteIdade',
  props: {
    ...pacienteInfoProps,
    dataNascimento: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <PacienteInfo
        value={DateHelpers.idade(props.dataNascimento)}
        icon={MyIcons.aniversariantes}
        small={props.small}
        classes={props.classes}
      />
    );
  },
});

export const PacienteDataNascimento = createComponent<
  {
    dataNascimento: string | null;
  } & IOptions
>({
  name: 'PacienteDataNascimento',
  props: {
    ...pacienteInfoProps,
    dataNascimento: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <PacienteInfo
        value={DateHelpers.formatDate(props.dataNascimento)}
        icon={MyIcons.aniversariantes}
        small={props.small}
        classes={props.classes}
      />
    );
  },
});

export const PacienteCidade = createComponent<
  { cidade: string | null } & IOptions
>({
  name: 'PacienteCidade',
  props: {
    ...pacienteInfoProps,
    cidade: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <PacienteInfo
        value={props.cidade}
        icon={MyIcons.homeMarker}
        small={props.small}
        classes={props.classes}
      />
    );
  },
});

export const PacienteFaltasChip = createComponent<{ faltas: number }>({
  name: 'PacienteFaltasChip',
  props: {
    faltas: { type: Number, required: true },
  },
  setup(props, ctx) {
    return () => {
      if (!props.faltas) {
        return null;
      }

      const showRed = props.faltas > 2;

      const color = showRed ? MyTheme.red500 : MyTheme.yellow700;
      const textColor = showRed ? 'white' : MyTheme.yellow700;

      return (
        <v-chip outlined={!showRed} color={color} text-color={textColor} small>
          Faltas: {props.faltas}
        </v-chip>
      );
    };
  },
});

export const PacienteConvenio = createComponent<
  { convenio: string | null | undefined } & IOptions
>({
  name: 'PacienteConvenio',
  props: {
    ...pacienteInfoProps,
    convenio: { type: String },
  },
  setup(props, ctx) {
    return () => (
      <PacienteInfo
        value={props.convenio}
        icon={MyIcons.convenio}
        small={props.small}
        classes={props.classes}
      />
    );
  },
});
