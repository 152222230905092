import { computed } from '@vue/composition-api';
import { useVuetify } from './useVuetify';

export function useBreakpoints() {
  const $xs = computed(() => useVuetify().breakpoint.xs);

  const $gtSm = computed(() => useVuetify().breakpoint.smAndUp);

  const $gtMd = computed(() => useVuetify().breakpoint.mdAndUp);

  const $gtLg = computed(() => useVuetify().breakpoint.lgAndUp);

  const $width = computed(() => useVuetify().breakpoint.width);

  const $showMiniSidebar = computed(() => $width.value <= 960);

  return { $xs, $gtSm, $showMiniSidebar, $gtMd, $gtLg, $width };
}
