import CreateTissLoteMutation from '@/graphql/tiss/tissLote/createTissLote.graphql';
import DeleteTissLoteMutation from '@/graphql/tiss/tissLote/deleteTissLote.graphql';
import TissLoteQuery from '@/graphql/tiss/tissLote/tissLote.graphql';
import TissLotesQuery from '@/graphql/tiss/tissLote/tissLotes.graphql';
import UpdateTissLoteMutation from '@/graphql/tiss/tissLote/updateTissLote.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissLoteMutation,
  ICreateTissLoteMutationVariables,
  IDeleteTissLoteMutation,
  IDeleteTissLoteMutationVariables,
  ITissLoteQuery,
  ITissLoteQueryVariables,
  ITissLotesQuery,
  ITissLotesQueryVariables,
  IUpdateTissLoteMutation,
  IUpdateTissLoteMutationVariables,
} from '@/typings';

export const TissLoteGraphql = {
  query: {
    TissLoteQuery,
    TissLotesQuery,
  },

  async createTissLote(variables: ICreateTissLoteMutationVariables) {
    return apolloClient
      .mutate<ICreateTissLoteMutation>({
        mutation: CreateTissLoteMutation,
        variables,
      })
      .then(({ data }) => data?.createTissLote);
  },

  async deleteTissLote(variables: IDeleteTissLoteMutationVariables) {
    return apolloClient
      .mutate<IDeleteTissLoteMutation>({
        mutation: DeleteTissLoteMutation,
        variables,
        refetchQueries: getRefetchQueries([TissLotesQuery]),
      })
      .then(({ data }) => data?.deleteTissLote);
  },

  async tissLote(variables: ITissLoteQueryVariables) {
    return apolloClient
      .query<ITissLoteQuery>({
        query: TissLoteQuery,
        variables,
      })
      .then(({ data }) => data?.tissLote);
  },

  async tissLotes(variables: ITissLotesQueryVariables) {
    return apolloClient
      .query<ITissLotesQuery>({
        query: TissLotesQuery,
        variables,
      })
      .then(({ data }) => data?.tissLotes);
  },

  async updateTissLote(variables: IUpdateTissLoteMutationVariables) {
    return apolloClient
      .mutate<IUpdateTissLoteMutation>({
        mutation: UpdateTissLoteMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissLote);
  },
};
