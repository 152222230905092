import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment_observacoes } from '@/typings';
import { TimelineBodyHtml } from '../../body/TimelineBodyHtml';

interface IProps {
  observacoes: IAtendimentoFragment_observacoes[] | null;
}

export const TimelineObservacoesList = createComponent<IProps>({
  name: 'TimelineObservacoesList',
  props: {
    observacoes: { type: Array },
  },
  setup(props, ctx) {
    return () => {
      if (!props.observacoes?.length) {
        return null;
      }

      return (
        <div class="flex flex-col mb-4">
          <h2 class="text-headline">Observações</h2>

          <div class="flex flex-col mt-2">
            {props.observacoes.map(v => (
              <v-card outlined key={v.id} class="mb-2">
                <div class="flex flex-col pb-2 bg-coolGray-50">
                  <div class="flex text-coolGray-700">
                    <TimelineBodyHtml html={v.texto} />
                  </div>

                  <div class="flex pl-8 text-small text-coolGray-500">
                    <span>Criado dia</span>

                    <span class="mx-1 font-medium">
                      {DateHelpers.formatDateAndHour(v.data)}
                    </span>

                    <span>por</span>

                    <span class="ml-1 font-medium">{v.profissionalNome}</span>
                  </div>
                </div>
              </v-card>
            ))}
          </div>
        </div>
      );
    };
  },
});
