import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IAlertType } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  text?: string | null;
  type: IAlertType;
  show: boolean;
  dismissible?: boolean;
  noIcon?: boolean;
}

interface IEvents {
  onClose: () => void;
}

export const Alert = createComponent<IProps, IEvents>({
  name: 'Alert',
  props: {
    text: { type: String },
    type: { type: String as any, required: true },
    show: { type: Boolean, required: true },
    dismissible: { type: Boolean, default: true },
    noIcon: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const $color = computed(() => {
      switch (props.type) {
        case 'error':
          return MyTheme.red600;
        case 'success':
          return MyTheme.teal600;
        case 'info':
          return MyTheme.blue600;
        case 'warning':
          return MyTheme.yellow600;
        default:
          return undefined;
      }
    });

    const $icon = computed(() => {
      if (props.noIcon) return undefined;

      switch (props.type) {
        case 'error':
          return MyIcons.alertError;
        case 'success':
          return MyIcons.alertSuccess;
        case 'info':
          return MyIcons.alertInfo;
        case 'warning':
          return MyIcons.alertWarning;
        default:
          return undefined;
      }
    });

    const $style = computed(() => ({
      'background-color': `${backgroundColor(props.type)} !important`,
    }));

    function handleClose() {
      ctx.emit('close');
    }

    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-alert
          id="Alert"
          outlined
          transition="fade-transition"
          dismissible={props.dismissible}
          color={$color.value}
          icon={$icon.value}
          value={props.show}
          style={$style.value}
          class="mb-4"
          onInput={handleClose}
        >
          {defaultSlot || props.text}
        </v-alert>
      );
    };
  },
});

function backgroundColor(type: IAlertType) {
  switch (type) {
    case 'error':
      return MyTheme.red50;
    case 'success':
      return MyTheme.teal50;
    case 'info':
      return MyTheme.blue50;
    case 'warning':
      return MyTheme.yellow50;
    default:
      return 'white';
  }
}
