import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment } from '@/typings';
import { BtnImprimir } from '../../utils/BtnImprimir';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineBodyHeader = createComponent<IProps>({
  name: 'TimelineBodyHeader',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { handleImprimir } = useEvents(props);

    return () => (
      <div class="flex flex-wrap items-center justify-between px-4 py-2 bg-coolGray-100 text-coolGray-700">
        {profissional(props)}

        {horaInicial(props)}

        {duracao(props)}

        <BtnImprimir large handleImprimir={handleImprimir} />
      </div>
    );
  },
});

function useEvents(props: IProps) {
  function handleImprimir() {
    return ProntuarioService.pdf.completoById({
      atendimentoId: props.atendimento.id,
      pacienteId: props.atendimento.paciente.id,
    });
  }

  return { handleImprimir };
}

const profissional = (props: IProps) => (
  <div aria-label="Profissional" data-balloon-pos="down" class="cursor-auto">
    <v-icon color={MyTheme.coolGray700} size="20" class="mr-1">
      {MyIcons.account}
    </v-icon>

    {props.atendimento.profissional.nome}
  </div>
);

const horaInicial = (props: IProps) => (
  <div aria-label="Hora Inicial" data-balloon-pos="down" class="cursor-auto">
    <v-icon color={MyTheme.coolGray700} size="20" class="mr-1">
      {MyIcons.time}
    </v-icon>

    {DateHelpers.formatHour(props.atendimento.dataInicial)}
  </div>
);

const duracao = (props: IProps) => (
  <div aria-label="Duração" data-balloon-pos="down" class="cursor-auto">
    <v-icon color={MyTheme.coolGray700} size="20" class="mr-1">
      {MyIcons.timer}
    </v-icon>

    {calcDuracao(props.atendimento)}
  </div>
);

const calcDuracao = (a: IAtendimentoFragment) =>
  DateHelpers.humanizeDiff(a.dataInicial, a.dataFinal);
