import { createComponent } from '@/lib/vue';

interface IProps {
  hide?: boolean;
}

export const ExpandTransition = createComponent<IProps>({
  name: 'ExpandTransition',
  props: {
    hide: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-expand-transition>
          <div v-show={!props.hide} class="flex flex-col">
            {defaultSlot}
          </div>
        </v-expand-transition>
      );
    };
  },
});
