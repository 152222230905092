import { configuracoesProfissionaisGuard } from '@/routes/guards/configuracoesProfissionais.guard';
import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const ConfiguracaoProfissionaisSidebar = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/components/ConfiguracaoProfissionaisSidebar'
  );
const AlertasPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/alertas/AlertasPage'
  );
const AlertasEmailAniversariantesPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/alertas/AlertasEmailAniversariantesPage'
  );
const AlertasEmailPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/alertas/AlertasEmailPage'
  );
const AlertasSmsPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/alertas/AlertasSmsPage'
  );
const AtestadosModelosPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/atestadoModelo/AtestadosModelosPage'
  );
const AtestadoModeloFormPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/atestadoModelo/AtestadoModeloFormPage'
  );
const PrescricoesModelosPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/prescricaoModelo/PrescricoesModelosPage'
  );
const PrescricaoModeloFormPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/prescricaoModelo/PrescricaoModeloFormPage'
  );
const ExamesModelosPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/exameModelo/ExamesModelosPage'
  );
const ExameModeloFormPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/exameModelo/ExameModeloFormPage'
  );
const ProcedimentosPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/procedimento/ProcedimentosPage'
  );
const ProcedimentoFormPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/procedimento/ProcedimentoFormPage'
  );
const ProntuariosSecoesPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/secao/ProntuariosSecoesPage'
  );
const ProntuarioSecaoFormPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/secao/ProntuarioSecaoFormPage'
  );
const ConfiguracaoAgendaPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/ConfiguracaoAgendaPage'
  );
const CompartilhamentoProntuarioPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/prontuario/pages/CompartilhamentoProntuarioPage'
  );
const ProfissionalConveniosPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/ProfissionalConveniosPage'
  );
const ImpressaoPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/ConfiguracaoImpressaoPage'
  );
const IntegracoesPage = () =>
  import(
    /* webpackChunkName: "config-profissionais" */ '@/modules/configuracoes/profissional/pages/IntegracoesPage'
  );

export const configuracoesProfissionaisRoutes: IRouteConfig[] = routeGroup({
  prefix: 'configuracoes/profissionais/:profissionalId',
  beforeEnter: configuracoesProfissionaisGuard,
  routes: [
    {
      path: 'alertas',
      components: {
        default: AlertasPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
      children: [
        {
          path: '',
          component: AlertasEmailPage,
        },
        {
          path: 'sms',
          component: AlertasSmsPage,
        },
        {
          path: 'email-aniversariantes',
          component: AlertasEmailAniversariantesPage,
        },
      ],
    },

    ...routeGroup({
      prefix: 'atestados',
      routes: [
        {
          path: '',
          components: {
            default: AtestadosModelosPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: AtestadoModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: AtestadoModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'prescricoes',
      routes: [
        {
          path: '',
          components: {
            default: PrescricoesModelosPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: PrescricaoModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: PrescricaoModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'exames',
      routes: [
        {
          path: '',
          components: {
            default: ExamesModelosPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ExameModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ExameModeloFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'procedimentos',
      routes: [
        {
          path: '',
          components: {
            default: ProcedimentosPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ProcedimentoFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ProcedimentoFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'prontuarios',
      routes: [
        {
          path: '',
          components: {
            default: ProntuariosSecoesPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ProntuarioSecaoFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ProntuarioSecaoFormPage,
            sidebar: ConfiguracaoProfissionaisSidebar,
          },
        },
      ],
    }),

    {
      path: 'agenda',
      components: {
        default: ConfiguracaoAgendaPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
    },
    {
      path: 'compartilhamento-de-prontuario',
      components: {
        default: CompartilhamentoProntuarioPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
    },
    {
      path: 'convenios',
      components: {
        default: ProfissionalConveniosPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
    },
    {
      path: 'impressao',
      components: {
        default: ImpressaoPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
    },
    {
      path: 'integracoes',
      components: {
        default: IntegracoesPage,
        sidebar: ConfiguracaoProfissionaisSidebar,
      },
    },
  ],
});
