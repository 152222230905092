import { createMutation, makeCommit } from '@/store/utils/commit';
import { IAppState, IFluxoCaixaState } from '@/typings';
import { Module } from 'vuex';
import { FluxoCaixaCategoriasState } from './categorias';
import { FluxoCaixaContasFinanceirasState } from './contasFinanceiras';
import { FluxoCaixaHeaderState } from './header';
import { FluxoCaixaReportState } from './report';
import { mapFluxoCaixaDates } from './utils';

const stateFn = (): IFluxoCaixaState => ({
  header: {
    type: 'month',
    dates: mapFluxoCaixaDates(),
  },
  contasFinanceiras: {
    all: [],
    ids: [],
    loading: true,
  },
  categorias: {
    despesas: [],
    receitas: [],
    loading: true,
  },
  report: {
    all: [],
    data: {},
    loading: true,
  },
});

export const FluxoCaixaModule: Module<IFluxoCaixaState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IFluxoCaixaState>('financas/fluxoCaixa');

export class FluxoCaixaState {
  static commit = commit;

  static header = FluxoCaixaHeaderState;

  static contasFinanceiras = FluxoCaixaContasFinanceirasState;

  static categorias = FluxoCaixaCategoriasState;

  static report = FluxoCaixaReportState;
}
