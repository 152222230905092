import { IProntuarioCacheState } from '@/typings';
import cloneDeep from 'lodash/cloneDeep';
import { ProntuarioState } from '..';

export const ProntuarioCacheState = {
  set(cache: IProntuarioCacheState | undefined | null) {
    ProntuarioState.commit(s => {
      s.cache = cache || null;
    });
  },

  loadState() {
    ProntuarioState.commit(s => {
      const cache = s.cache && cloneDeep(s.cache);

      if (cache) {
        s.atendimento.showTimer = cache.atendimento.showTimer;

        s.forms.preAtendimento.model = cache.model.preAtendimento;
        s.forms.exames.model = cache.model.exames;
        s.forms.prescricoes.model = cache.model.prescricoes;
        s.forms.atestados.model = cache.model.atestados;
        s.forms.hipoteseDiagnostica.model = cache.model.hipoteseDiagnostica;

        s.tabs = s.tabs.map(v => ({ ...v, model: cache.tabs[v.id] }));
      }
    });
  },
};
