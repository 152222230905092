import { TussLookupDialog } from '@/modules/tiss/components/lookupDialog/TussLookupDialog';
import { showDialog } from './dialog.helpers';

export const TissDialogs = {
  tussLookup(title: string) {
    return showDialog({
      component: TussLookupDialog,
      // width: 400,
      title,
      params: null,
    });
  },
};
