import { apolloClient } from '@/plugins/apollo';

import CreateConvenioMutation from '@/graphql/convenio/mutation/createConvenio.graphql';
import DeleteConvenioMutation from '@/graphql/convenio/mutation/deleteConvenio.graphql';
import DeleteManyConveniosMutation from '@/graphql/convenio/mutation/deleteManyConvenios.graphql';
import UpdateConvenioMutation from '@/graphql/convenio/mutation/updateConvenio.graphql';
import UpdateConvenioPlanosByProfissionalMutation from '@/graphql/convenio/mutation/updateConvenioPlanosByProfissional.graphql';
import UpdateConveniosByProfissionalMutation from '@/graphql/convenio/mutation/updateConveniosByProfissional.graphql';
import ConvenioQuery from '@/graphql/convenio/query/convenio.graphql';
import ConveniosQuery from '@/graphql/convenio/query/convenios.graphql';

import {
  IConvenioQuery,
  IConvenioQueryVariables,
  IConveniosQuery,
  IConveniosQueryVariables,
  ICreateConvenioMutation,
  ICreateConvenioMutationVariables,
  IDeleteConvenioMutation,
  IDeleteConvenioMutationVariables,
  IDeleteManyConveniosMutation,
  IDeleteManyConveniosMutationVariables,
  IUpdateConvenioMutation,
  IUpdateConvenioMutationVariables,
  IUpdateConvenioPlanosByProfissionalMutation,
  IUpdateConvenioPlanosByProfissionalMutationVariables,
  IUpdateConveniosByProfissionalMutation,
  IUpdateConveniosByProfissionalMutationVariables,
} from '@/typings';
import { getRefetchQueries } from '../utils';

export const ConvenioGraphql = {
  query: {
    ConvenioQuery,
    ConveniosQuery,
  },

  async createConvenio(variables: ICreateConvenioMutationVariables) {
    return apolloClient
      .mutate<ICreateConvenioMutation>({
        mutation: CreateConvenioMutation,
        variables,
      })
      .then(({ data }) => data?.createConvenio);
  },

  async deleteConvenio(variables: IDeleteConvenioMutationVariables) {
    return apolloClient
      .mutate<IDeleteConvenioMutation>({
        mutation: DeleteConvenioMutation,
        variables,
        refetchQueries: getRefetchQueries([ConveniosQuery]),
      })
      .then(({ data }) => data?.deleteConvenio);
  },

  async deleteManyConvenios(variables: IDeleteManyConveniosMutationVariables) {
    return apolloClient
      .mutate<IDeleteManyConveniosMutation>({
        mutation: DeleteManyConveniosMutation,
        variables,
        refetchQueries: getRefetchQueries([ConveniosQuery]),
      })
      .then(({ data }) => data?.deleteManyConvenios);
  },

  async updateConvenio(variables: IUpdateConvenioMutationVariables) {
    return apolloClient
      .mutate<IUpdateConvenioMutation>({
        mutation: UpdateConvenioMutation,
        variables,
      })
      .then(({ data }) => data?.updateConvenio);
  },

  async updateConvenioPlanosByProfissional(
    variables: IUpdateConvenioPlanosByProfissionalMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConvenioPlanosByProfissionalMutation>({
        mutation: UpdateConvenioPlanosByProfissionalMutation,
        variables,
      })
      .then(({ data }) => data?.updateConvenioPlanosByProfissional);
  },

  async updateConveniosByProfissional(
    variables: IUpdateConveniosByProfissionalMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConveniosByProfissionalMutation>({
        mutation: UpdateConveniosByProfissionalMutation,
        variables,
      })
      .then(({ data }) => data?.updateConveniosByProfissional);
  },

  async convenio(variables: IConvenioQueryVariables) {
    return apolloClient
      .query<IConvenioQuery>({
        query: ConvenioQuery,
        variables,
      })
      .then(({ data }) => data?.convenio);
  },

  async convenios(variables: IConveniosQueryVariables) {
    return apolloClient
      .query<IConveniosQuery>({
        query: ConveniosQuery,
        variables,
      })
      .then(({ data }) => data?.convenios);
  },
};
