import { FileUpload } from '@/components/upload/files/FileUpload';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { IFileUploadInfo } from '@/typings';

export default createComponent({
  name: 'ProntuarioFilesPage',
  setup(props, ctx) {
    function handleInput(value: IFileUploadInfo[]) {
      ProntuarioState.files.set(
        value
          .filter(f => !!f.id)
          .map(v => ({
            id: v.id!,
            description: v.description || v.file.name,
          })),
      );
    }
    return () => (
      <div>
        <v-card class="p-4">
          <FileUpload onInput={handleInput} />
        </v-card>
      </div>
    );
  },
});
