import { AuthLoginService } from '@/lib/services';
import { notAuthenticatedGuard } from '@/routes/guards/notAuthenticated.guard';
import { requireAuthGuard } from '@/routes/guards/requireAuth.guard';
import { AuthLayout } from '@/routes/modules/layout';
import { routeGroup } from '@/routes/utils';
import { IRouteConfig } from '@/typings';

const AuthLoginPage = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/pages/AuthLoginPage');
const AuthSignUpPart1Page = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/signUp/AuthSignUpPart1Page'
  );
const AuthSignUpPart2Page = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/signUp/AuthSignUpPart2Page'
  );
const AuthConvitePage = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/pages/AuthConvitePage');
const AuthEsqueceuSenhaPart1Page = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/esqueceuSenha/AuthEsqueceuSenhaPart1Page'
  );
const AuthEsqueceuSenhaPart2Page = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/esqueceuSenha/AuthEsqueceuSenhaPart2Page'
  );
const AuthClinicasPage = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/AuthClinicasPage'
  );
const AuthConfirmarEmailPage = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/pages/AuthConfirmarEmailPage'
  );

export const authRoutes: IRouteConfig[] = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: AuthLoginPage,
        beforeEnter: notAuthenticatedGuard,
      },

      ...routeGroup({
        prefix: 'cadastro',
        routes: [
          {
            path: '',
            component: AuthSignUpPart1Page,
            beforeEnter: notAuthenticatedGuard,
          },
          {
            path: 'informacoes',
            component: AuthSignUpPart2Page,
            beforeEnter: requireAuthGuard,
          },
        ],
      }),

      {
        path: 'convite/:token',
        component: AuthConvitePage,
        beforeEnter: notAuthenticatedGuard,
      },

      ...routeGroup({
        prefix: 'esqueceu-senha',
        routes: [
          {
            path: '',
            component: AuthEsqueceuSenhaPart1Page,
            beforeEnter: notAuthenticatedGuard,
          },
          {
            path: ':token',
            component: AuthEsqueceuSenhaPart2Page,
            beforeEnter: notAuthenticatedGuard,
          },
        ],
      }),

      {
        path: 'clinicas',
        component: AuthClinicasPage,
        beforeEnter: requireAuthGuard,
      },
      {
        path: 'logout',
        beforeEnter: () => AuthLoginService.logout(),
      },
      {
        path: 'confirmar-email/:token',
        component: AuthConfirmarEmailPage,
      },
    ],
  },
];
