import { IInputItems } from '@/typings';

export const tissUf: IInputItems[] = [
  // Rondônia
  { label: 'RO', value: '11' },
  // Acre
  { label: 'AC', value: '12' },
  // Amazonas
  { label: 'AM', value: '13' },
  // Roraima
  { label: 'RR', value: '14' },
  // Pará
  { label: 'PA', value: '15' },
  // Amapá
  { label: 'AP', value: '16' },
  // Tocantins
  { label: 'TO', value: '17' },
  // Maranhão
  { label: 'MA', value: '21' },
  // Piauí
  { label: 'PI', value: '22' },
  // Ceará
  { label: 'CE', value: '23' },
  // Rio Grande do Norte
  { label: 'RN', value: '24' },
  // Paraíba
  { label: 'PB', value: '25' },
  // Pernambuco
  { label: 'PE', value: '26' },
  // Alagoas
  { label: 'AL', value: '27' },
  // Sergipe
  { label: 'SE', value: '28' },
  // Bahia
  { label: 'BA', value: '29' },
  // Minas Gerais
  { label: 'MG', value: '31' },
  // Espírito Santo
  { label: 'ES', value: '32' },
  // Rio de Janeiro
  { label: 'RJ', value: '33' },
  // São Paulo
  { label: 'SP', value: '35' },
  // Paraná
  { label: 'PR', value: '41' },
  // Santa Catarina
  { label: 'SC', value: '42' },
  // Rio Grande do Sul
  { label: 'RS', value: '43' },
  // Mato Grosso do Sul
  { label: 'MS', value: '50' },
  // Mato Grosso
  { label: 'MT', value: '51' },
  // Goiás
  { label: 'GO', value: '52' },
  // Distrito Federal
  { label: 'DF', value: '53' },
  // Países Estrangeiros
  { label: 'EX', value: '98' },
];
