import { store } from '@/store';
import { IAppState } from '@/typings';
import { computed, ComputedRef, Ref, ref } from '@vue/composition-api';

export function useValue<T>(initialValue: T): [Ref<T>, (v: T) => void] {
  const $value = ref(initialValue);

  function setValue(v: T) {
    $value.value = v as any;
  }

  return [$value as Ref<T>, setValue];
}

export function useValueMap<T>(
  initialValue: T,
  mapValue: (v: T) => T,
): [Ref<T>, (v: T) => void] {
  const $value = ref(mapValue(initialValue));

  function setValue(v: T) {
    $value.value = mapValue(v) as any;
  }

  return [$value as Ref<T>, setValue];
}

export function useState<T>(mapState: (s: IAppState) => T): ComputedRef<T> {
  return computed(() => mapState(store.state));
}
