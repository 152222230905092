import { useState } from '@/lib/composables';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { ProntuarioExamesForm } from '../../components/forms/ProntuarioExamesForm';
import { DashedRow } from '../../components/utils/DashedRow';

export default createComponent({
  name: 'ProntuarioExamesPage',
  setup(props, ctx) {
    const $exames = useState(s => s.prontuario.forms.exames.model);

    return () => (
      <div class="flex flex-col">
        {$exames.value.map(v => (
          <ProntuarioExamesForm
            key={v.id}
            value={v}
            class="mb-6"
            onSubmit={() => ProntuarioService.cache.save()}
          />
        ))}

        <DashedRow>
          <v-btn
            outlined
            onClick={ProntuarioState.model.exames.add}
            color={MyTheme.coolGray700}
          >
            Adicionar Solicitação
          </v-btn>
        </DashedRow>
      </div>
    );
  },
});
