import CreateProcedimentoMutation from '@/graphql/profissional/procedimento/createProcedimento.graphql';
import DeleteManyProcedimentosMutation from '@/graphql/profissional/procedimento/deleteManyProcedimentos.graphql';
import DeleteProcedimentoMutation from '@/graphql/profissional/procedimento/deleteProcedimento.graphql';
import ProcedimentoQuery from '@/graphql/profissional/procedimento/procedimento.graphql';
import ProcedimentosQuery from '@/graphql/profissional/procedimento/procedimentos.graphql';
import UpdateProcedimentoMutation from '@/graphql/profissional/procedimento/updateProcedimento.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateProcedimentoMutation,
  ICreateProcedimentoMutationVariables,
  IDeleteManyProcedimentosMutation,
  IDeleteManyProcedimentosMutationVariables,
  IDeleteProcedimentoMutation,
  IDeleteProcedimentoMutationVariables,
  IProcedimentoQuery,
  IProcedimentoQueryVariables,
  IProcedimentosQuery,
  IProcedimentosQueryVariables,
  IUpdateProcedimentoMutation,
  IUpdateProcedimentoMutationVariables,
} from '@/typings';

export const ProcedimentoGraphql = {
  query: {
    ProcedimentoQuery,
    ProcedimentosQuery,
  },

  async createProcedimento(variables: ICreateProcedimentoMutationVariables) {
    return apolloClient
      .mutate<ICreateProcedimentoMutation>({
        mutation: CreateProcedimentoMutation,
        variables,
      })
      .then(({ data }) => data?.createProcedimento);
  },

  async deleteManyProcedimentos(
    variables: IDeleteManyProcedimentosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyProcedimentosMutation>({
        mutation: DeleteManyProcedimentosMutation,
        variables,
        refetchQueries: getRefetchQueries([ProcedimentosQuery]),
      })
      .then(({ data }) => data?.deleteManyProcedimentos);
  },

  async deleteProcedimento(variables: IDeleteProcedimentoMutationVariables) {
    return apolloClient
      .mutate<IDeleteProcedimentoMutation>({
        mutation: DeleteProcedimentoMutation,
        variables,
        refetchQueries: getRefetchQueries([ProcedimentosQuery]),
      })
      .then(({ data }) => data?.deleteProcedimento);
  },

  async procedimento(variables: IProcedimentoQueryVariables) {
    return apolloClient
      .query<IProcedimentoQuery>({
        query: ProcedimentoQuery,
        variables,
      })
      .then(({ data }) => data?.procedimento);
  },

  async procedimentos(variables: IProcedimentosQueryVariables) {
    return apolloClient
      .query<IProcedimentosQuery>({
        query: ProcedimentosQuery,
        variables,
      })
      .then(({ data }) => data?.procedimentos);
  },

  async updateProcedimento(variables: IUpdateProcedimentoMutationVariables) {
    return apolloClient
      .mutate<IUpdateProcedimentoMutation>({
        mutation: UpdateProcedimentoMutation,
        variables,
      })
      .then(({ data }) => data?.updateProcedimento);
  },
};
