import { DateHelpers } from '@/lib/helpers/date.helpers';
import {
  IFluxoCaixaData,
  IFluxoCaixaDataTransacao,
  IFluxoCaixaDataTransferencias,
  IFluxoCaixaHeaderDates,
  IFluxoCaixaQuery_fluxoCaixa,
  IFluxoCaixaType,
  TransacaoTipo,
} from '@/typings';
import times from 'lodash/times';
import { FluxoCaixaState } from '.';
import { formatFluxoCaixaDate } from './utils';

export const FluxoCaixaReportState = {
  setLoading(loading = false) {
    FluxoCaixaState.commit(s => {
      s.report.loading = loading;
    });
  },

  setAll(data: IFluxoCaixaQuery_fluxoCaixa[]) {
    FluxoCaixaState.commit(s => {
      const all = data.map(v => ({
        ...v,
        total: v.tipo === TransacaoTipo.DESPESA ? -v.total : v.total,
      }));

      s.report.all = all;

      s.report.data = mapData({ all, ...s.header });

      s.report.loading = false;
    });
  },
};

function mapData({
  all,
  type,
  dates,
}: {
  all: IFluxoCaixaQuery_fluxoCaixa[];
  type: IFluxoCaixaType;
  dates: IFluxoCaixaHeaderDates;
}): IFluxoCaixaData {
  const datesObj = times(
    7,
    i =>
      formatFluxoCaixaDate({
        type,
        value: DateHelpers.dateAdd(dates.start, {
          months: type === 'month' ? i : 0,
          days: type === 'week' ? i : 0,
        }),
      }) || '',
  ).reduce((obj, v) => ({ ...obj, [v]: [] }), {});

  // group by dates
  const groupedByDates = all.reduce<{
    [date: string]: IFluxoCaixaQuery_fluxoCaixa[];
  }>((obj, v) => {
    const date = formatFluxoCaixaDate({ type, value: v.data });

    if (!date) return obj;

    return {
      ...obj,
      [date]: [...(obj[date] || []), v],
    };
  }, datesObj);

  let saldoAnterior = 0;

  return Object.entries(groupedByDates).reduce<IFluxoCaixaData>(
    (obj, [date, value]) => {
      const receitas = mapTransacao({
        value,
        tipo: TransacaoTipo.RECEITA,
      });

      const despesas = mapTransacao({
        value,
        tipo: TransacaoTipo.DESPESA,
      });

      const transferencias = mapTransferencias(value);

      // soma porque despesas é negativa
      const geracaoCaixa =
        receitas.total + despesas.total + transferencias.total;

      const saldoFinal = geracaoCaixa + saldoAnterior;

      const oldSaldoAnterior = saldoAnterior;

      saldoAnterior = saldoFinal;

      return {
        ...obj,
        [date]: {
          receitas,
          despesas,
          transferencias,
          geracaoCaixa,
          saldoAnterior: oldSaldoAnterior,
          saldoFinal,
        },
      };
    },
    {},
  );
}

function mapTransacao({
  value,
  tipo,
}: {
  value: IFluxoCaixaQuery_fluxoCaixa[];
  tipo: TransacaoTipo;
}): IFluxoCaixaDataTransacao {
  const data = value.filter(
    f => f.tipo === tipo && f.categoria !== 'TRANSFERENCIA',
  );

  return {
    categorias: data
      .filter(f => !!f.categoriaId)
      .reduce<{ [categoriaId: string]: number }>(
        (obj, v) => ({
          ...obj,
          [v.categoriaId!]: (obj[v.categoriaId!] || 0) + v.total,
        }),
        {},
      ),
    outros: data
      .filter(
        f => !f.categoria || ['SALDO_INICIAL', 'OUTROS'].includes(f.categoria),
      )
      .reduce((total, v) => (total += v.total), 0),
    total: data.reduce((total, v) => (total += v.total), 0),
  };
}

function mapTransferencias(
  value: IFluxoCaixaQuery_fluxoCaixa[],
): IFluxoCaixaDataTransferencias {
  const transfs = value.filter(f => f.categoria === 'TRANSFERENCIA');

  const entrada = transfs
    .filter(f => f.tipo === TransacaoTipo.RECEITA)
    .reduce((total, v) => (total += v.total), 0);

  const saida = transfs
    .filter(f => f.tipo === TransacaoTipo.DESPESA)
    .reduce((total, v) => (total += v.total), 0);

  return {
    entrada,
    saida,
    // soma porque saida é negativa
    total: entrada + saida,
  };
}
