import { formatMoney } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

interface IProps {
  header: IDataTableHeader;
  item: any;
}

export const Td = createComponent<IProps>({
  name: 'Td',
  props: {
    header: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div
          key={props.header.value}
          style={{
            width: props.header.width ? `${props.header.width}px` : null,
          }}
          class={[
            {
              'text-right': props.header.valueAlign === 'end',
              'text-left': props.header.valueAlign === 'start',
              'text-center': props.header.valueAlign === 'center',
            },
            props.header.class,
            props.header.mapClass?.(props.item),
          ]}
        >
          {defaultSlot || defaultTd(props)}
        </div>
      );
    };
  },
});

const defaultTd = ({ header, item }: IProps) => {
  const value: string | number = header.mapValue
    ? header.mapValue(item)
    : item[header.value!] || '-';

  if (header.to) {
    return <router-link to={header.to(item.id)}>{value}</router-link>;
  } else if (header.money) {
    return formatMoney(value);
  }

  return value;
};
