import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';

import CreateTransacaoMutation from '@/graphql/financas/transacao/createTransacao.graphql';
import CreateTransacaoRecorrenciaMutation from '@/graphql/financas/transacao/createTransacaoRecorrencia.graphql';
import CreateTransferenciaMutation from '@/graphql/financas/transacao/createTransferencia.graphql';
import DeleteManyTransacoesMutation from '@/graphql/financas/transacao/deleteManyTransacoes.graphql';
import DeleteTransacaoMutation from '@/graphql/financas/transacao/deleteTransacao.graphql';
import DeleteTransacaoRecorrenciaMutation from '@/graphql/financas/transacao/deleteTransacaoRecorrencia.graphql';
import TransacaoQuery from '@/graphql/financas/transacao/transacao.graphql';
import TransacoesQuery from '@/graphql/financas/transacao/transacoes.graphql';
import TransacoesSummaryQuery from '@/graphql/financas/transacao/transacoesSummary.graphql';
import UpdateManyTransacoesStatusMutation from '@/graphql/financas/transacao/updateManyTransacoesStatus.graphql';
import UpdateTransacaoMutation from '@/graphql/financas/transacao/updateTransacao.graphql';
import UpdateTransacaoRecorrenciaMutation from '@/graphql/financas/transacao/updateTransacaoRecorrencia.graphql';
import UpdateTransacaoStatusMutation from '@/graphql/financas/transacao/updateTransacaoStatus.graphql';

import {
  ICreateTransacaoMutation,
  ICreateTransacaoMutationVariables,
  ICreateTransacaoRecorrenciaMutation,
  ICreateTransacaoRecorrenciaMutationVariables,
  ICreateTransferenciaMutation,
  ICreateTransferenciaMutationVariables,
  IDeleteManyTransacoesMutation,
  IDeleteManyTransacoesMutationVariables,
  IDeleteTransacaoMutation,
  IDeleteTransacaoMutationVariables,
  IDeleteTransacaoRecorrenciaMutation,
  IDeleteTransacaoRecorrenciaMutationVariables,
  ITransacaoQuery,
  ITransacaoQueryVariables,
  ITransacoesQuery,
  ITransacoesQueryVariables,
  ITransacoesSummaryQuery,
  ITransacoesSummaryQueryVariables,
  IUpdateManyTransacoesStatusMutation,
  IUpdateManyTransacoesStatusMutationVariables,
  IUpdateTransacaoMutation,
  IUpdateTransacaoMutationVariables,
  IUpdateTransacaoRecorrenciaMutation,
  IUpdateTransacaoRecorrenciaMutationVariables,
  IUpdateTransacaoStatusMutation,
  IUpdateTransacaoStatusMutationVariables,
} from '@/typings';

export const TransacaoGraphql = {
  query: {
    TransacaoQuery,
    TransacoesQuery,
    TransacoesSummaryQuery,
  },

  async createTransacao(variables: ICreateTransacaoMutationVariables) {
    return apolloClient
      .mutate<ICreateTransacaoMutation>({
        mutation: CreateTransacaoMutation,
        variables,
        refetchQueries: getRefetchQueries([TransacoesSummaryQuery]),
      })
      .then(({ data }) => data?.createTransacao);
  },

  async createTransacaoRecorrencia(
    variables: ICreateTransacaoRecorrenciaMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTransacaoRecorrenciaMutation>({
        mutation: CreateTransacaoRecorrenciaMutation,
        variables,
        refetchQueries: getRefetchQueries([TransacoesSummaryQuery]),
      })
      .then(({ data }) => data?.createTransacaoRecorrencia);
  },

  async createTransferencia(variables: ICreateTransferenciaMutationVariables) {
    return apolloClient
      .mutate<ICreateTransferenciaMutation>({
        mutation: CreateTransferenciaMutation,
        variables,
        refetchQueries: getRefetchQueries([TransacoesSummaryQuery]),
      })
      .then(({ data }) => data?.createTransferencia);
  },

  async deleteManyTransacoes(
    variables: IDeleteManyTransacoesMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyTransacoesMutation>({
        mutation: DeleteManyTransacoesMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.deleteManyTransacoes);
  },

  async deleteTransacao(variables: IDeleteTransacaoMutationVariables) {
    return apolloClient
      .mutate<IDeleteTransacaoMutation>({
        mutation: DeleteTransacaoMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.deleteTransacao);
  },

  async deleteTransacaoRecorrencia(
    variables: IDeleteTransacaoRecorrenciaMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTransacaoRecorrenciaMutation>({
        mutation: DeleteTransacaoRecorrenciaMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.deleteTransacaoRecorrencia);
  },

  async transacao(variables: ITransacaoQueryVariables) {
    return apolloClient
      .query<ITransacaoQuery>({
        query: TransacaoQuery,
        variables,
      })
      .then(({ data }) => data?.transacao);
  },

  async transacoes(variables: ITransacoesQueryVariables) {
    return apolloClient
      .query<ITransacoesQuery>({
        query: TransacoesQuery,
        variables,
      })
      .then(({ data }) => data?.transacoes);
  },

  async transacoesSummary(variables: ITransacoesSummaryQueryVariables) {
    return apolloClient
      .query<ITransacoesSummaryQuery>({
        query: TransacoesSummaryQuery,
        variables,
      })
      .then(({ data }) => data?.transacoesSummary);
  },

  async updateManyTransacoesStatus(
    variables: IUpdateManyTransacoesStatusMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateManyTransacoesStatusMutation>({
        mutation: UpdateManyTransacoesStatusMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.updateManyTransacoesStatus);
  },

  async updateTransacao(variables: IUpdateTransacaoMutationVariables) {
    return apolloClient
      .mutate<IUpdateTransacaoMutation>({
        mutation: UpdateTransacaoMutation,
        variables,
        refetchQueries: getRefetchQueries([TransacoesSummaryQuery]),
      })
      .then(({ data }) => data?.updateTransacao);
  },

  async updateTransacaoRecorrencia(
    variables: IUpdateTransacaoRecorrenciaMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTransacaoRecorrenciaMutation>({
        mutation: UpdateTransacaoRecorrenciaMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.updateTransacaoRecorrencia);
  },

  async updateTransacaoStatus(
    variables: IUpdateTransacaoStatusMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTransacaoStatusMutation>({
        mutation: UpdateTransacaoStatusMutation,
        variables,
        refetchQueries: getRefetchQueries([
          TransacoesSummaryQuery,
          TransacoesQuery,
        ]),
      })
      .then(({ data }) => data?.updateTransacaoStatus);
  },
};
