import { InputAutocomplete } from '@/components/form/inputs/InputAutocomplete';
import { InputCheckbox } from '@/components/form/inputs/InputCheckbox';
import { InputColor } from '@/components/form/inputs/InputColor';
import { InputCombobox } from '@/components/form/inputs/InputCombobox';
import { InputDate } from '@/components/form/inputs/InputDate';
import { InputDateRange } from '@/components/form/inputs/InputDateRange';
import { InputEmail } from '@/components/form/inputs/InputEmail';
import { InputGroupCheckbox } from '@/components/form/inputs/InputGroupCheckbox';
import { InputNumber } from '@/components/form/inputs/InputNumber';
import { InputPassword } from '@/components/form/inputs/InputPassword';
import { InputRadio } from '@/components/form/inputs/InputRadio';
import { InputRichText } from '@/components/form/inputs/InputRichText';
import { InputSelect } from '@/components/form/inputs/InputSelect';
import { InputSwitch } from '@/components/form/inputs/InputSwitch';
import { InputText } from '@/components/form/inputs/InputText';
import { InputTextarea } from '@/components/form/inputs/InputTextarea';
import { InputTime } from '@/components/form/inputs/InputTime';
import {
  IFormInputModel,
  IFormInputType,
  ProntuarioSecaoFieldType,
} from '@/typings';
import { assertUnreachable } from '../helpers/utils';

export const getInputComponent = ({
  type,
}: IFormInputType | IFormInputModel) => {
  switch (type) {
    case ProntuarioSecaoFieldType.TEXT:
    case 'text':
      return InputText;

    case ProntuarioSecaoFieldType.NUMBER:
    case 'number':
    case 'money':
      return InputNumber;

    case 'autocomplete':
      return InputAutocomplete;

    case 'combobox':
      return InputCombobox;

    case ProntuarioSecaoFieldType.SELECT:
    case 'select':
      return InputSelect;

    case 'email':
      return InputEmail;

    case 'password':
      return InputPassword;

    case ProntuarioSecaoFieldType.CHECKBOX:
    case 'checkbox':
      return InputCheckbox;

    case ProntuarioSecaoFieldType.DATE:
    case 'date':
      return InputDate;

    case ProntuarioSecaoFieldType.RADIO:
    case 'radio':
      return InputRadio;

    case 'switch':
      return InputSwitch;

    case ProntuarioSecaoFieldType.TIME:
    case 'time':
      return InputTime;

    case 'date-range':
      return InputDateRange;

    case 'textarea':
      return InputTextarea;

    case 'color':
      return InputColor;

    case ProntuarioSecaoFieldType.RICH_TEXT:
    case 'rich-text':
      return InputRichText;

    case ProntuarioSecaoFieldType.GROUP_CHECKBOX:
    case 'group_checkbox':
      return InputGroupCheckbox;

    case null:
    case undefined:
      return null;

    default:
      return assertUnreachable(type);
  }
};
