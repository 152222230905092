import { IInputItems } from '@/typings';

export const dente: IInputItems[] = [
  { label: '11 - Incisivo Central Superior Direito', value: '11' },
  { label: '12 - Incisivo Lateral Superior Direito', value: '12' },
  { label: '13 - Canino Superior Direito', value: '13' },
  { label: '14 - Primeiro Pré-molar Superior Direito', value: '14' },
  { label: '15 - Segundo Pré-molar Superior Direito', value: '15' },
  { label: '16 - Primeiro Molar Superior Direito', value: '16' },
  { label: '17 - Segundo Molar Superior Direito', value: '17' },
  { label: '18 - Terceiro Molar Superior Direito', value: '18' },
  {
    label: '19 - Dente Permanente Supranumerário em Hemi-arco Superior Direito',
    value: '19',
  },
  { label: '21 - Incisivo Central Superior Esquerdo', value: '21' },
  { label: '22 - Incisivo Lateral Superior Esquerdo', value: '22' },
  { label: '23 - Canino Superior Esquerdo', value: '23' },
  { label: '24 - Primeiro Pré-molar Superior Esquerdo', value: '24' },
  { label: '25 - Segundo Pré-molar Superior Esquerdo', value: '25' },
  { label: '26 - Primeiro Molar Superior Esquerdo', value: '26' },
  { label: '27 - Segundo Molar Superior Esquerdo', value: '27' },
  { label: '28 - Terceiro Molar Superior Esquerdo', value: '28' },
  {
    label:
      '29 - Dente Permanente Supranumerário em Hemi-arco Superior Esquerdo',
    value: '29',
  },
  { label: '31 - Incisivo Central Inferior Esquerdo', value: '31' },
  { label: '32 - Incisivo Lateral Inferior Esquerdo', value: '32' },
  { label: '33 - Canino Inferior Esquerdo', value: '33' },
  { label: '34 - Primeiro Pré-molar Inferior Esquerdo', value: '34' },
  { label: '35 - Segundo Pré-molar Inferior Esquerdo', value: '35' },
  { label: '36 - Primeiro Molar Inferior Esquerdo', value: '36' },
  { label: '37 - Segundo Molar Inferior Esquerdo', value: '37' },
  { label: '38 - Terceiro Molar Inferior Esquerdo', value: '38' },
  {
    label:
      '39 - Dente Permanente Supranumerário em Hemi-arco Inferior Esquerdo',
    value: '39',
  },
  { label: '41 - Incisivo Central Inferior Direito', value: '41' },
  { label: '42 - Incisivo Lateral Inferior Direito', value: '42' },
  { label: '43 - Canino Inferior Direito', value: '43' },
  { label: '44 - Primeiro Pré-molar Inferior Direito', value: '44' },
  { label: '45 - Segundo Pré-molar Inferior Direito', value: '45' },
  { label: '46 - Primeiro Molar Inferior Direito', value: '46' },
  { label: '47 - Segundo Molar Inferior Direito', value: '47' },
  { label: '48 - Terceiro Molar Inferior Direito', value: '48' },
  {
    label: '49 - Dente Permanente Supranumerário em Hemi-arco Inferior Direito',
    value: '49',
  },
  { label: '51 - Incisivo Central Decíduo Superior Direito', value: '51' },
  { label: '52 - Incisivo Lateral Decíduo Superior Direito', value: '52' },
  { label: '53 - Canino Decíduo Superior Direito', value: '53' },
  { label: '54 - Primeiro Molar Decíduo Superior Direito', value: '54' },
  { label: '55 - Segundo Molar Decíduo Superior Direito', value: '55' },
  {
    label: '59 - Dente Decíduo Supranumerário em Hemi-arco Superior Direito',
    value: '59',
  },
  { label: '61 - Incisivo Central Decíduo Superior Esquerdo', value: '61' },
  { label: '62 - Incisivo Lateral Decíduo Superior Esquerdo', value: '62' },
  { label: '63 - Canino Decíduo Superior Esquerdo', value: '63' },
  { label: '64 - Primeiro Molar Decíduo Superior Esquerdo', value: '64' },
  { label: '65 - Segundo Molar Decíduo Superior Esquerdo', value: '65' },
  {
    label: '69 - Dente Decíduo Supranumerário em Hemi-arco Superior Esquerdo',
    value: '69',
  },
  { label: '71 - Incisivo Central Decíduo Inferior Esquerdo', value: '71' },
  { label: '72 - Incisivo Lateral Decíduo Inferior Esquerdo', value: '72' },
  { label: '73 - Canino Decíduo Inferior Esquerdo', value: '73' },
  { label: '74 - Primeiro Molar Decíduo Inferior Esquerdo', value: '74' },
  { label: '75 - Segundo Molar Decíduo Inferior Esquerdo', value: '75' },
  {
    label: '79 - Dente Decíduo Supranumerário em Hemi-arco Inferior Esquerdo',
    value: '79',
  },
  { label: '81 - Incisivo Central Decíduo Inferior Direito', value: '81' },
  { label: '82 - Incisivo Lateral Decíduo Inferior Direito', value: '82' },
  { label: '83 - Canino Decíduo Inferior Direito', value: '83' },
  { label: '84 - Primeiro Molar Decíduo Inferior Direito', value: '84' },
  { label: '85 - Segundo molar Decíduo Inferior Direito', value: '85' },
  {
    label: ' 89 - Dente Decíduo Supranumerário em Hemi-arco Inferior Direito',
    value: '89',
  },
];
