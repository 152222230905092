import ClinicaQuery from '@/graphql/system/clinica/clinica.graphql';
import ClinicasQuery from '@/graphql/system/clinica/clinicas.graphql';
import CreateClinicaMutation from '@/graphql/system/clinica/createClinica.graphql';
import DeleteClinicaMutation from '@/graphql/system/clinica/deleteClinica.graphql';
import DeleteManyClinicasMutation from '@/graphql/system/clinica/deleteManyClinicas.graphql';
import UpdateClinicaMutation from '@/graphql/system/clinica/updateClinica.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  IClinicaQuery,
  IClinicaQueryVariables,
  IClinicasQuery,
  IClinicasQueryVariables,
  ICreateClinicaMutation,
  ICreateClinicaMutationVariables,
  IDeleteClinicaMutation,
  IDeleteClinicaMutationVariables,
  IDeleteManyClinicasMutation,
  IDeleteManyClinicasMutationVariables,
  IUpdateClinicaMutation,
  IUpdateClinicaMutationVariables,
} from '@/typings';

export const ClinicaGraphql = {
  query: {
    ClinicaQuery,
    ClinicasQuery,
  },

  async clinica(variables: IClinicaQueryVariables) {
    return apolloClient
      .query<IClinicaQuery>({
        query: ClinicaQuery,
        variables,
      })
      .then(({ data }) => data?.clinica);
  },

  async clinicas(variables: IClinicasQueryVariables) {
    return apolloClient
      .query<IClinicasQuery>({
        query: ClinicasQuery,
        variables,
      })
      .then(({ data }) => data?.clinicas);
  },

  async createClinica(variables: ICreateClinicaMutationVariables) {
    return apolloClient
      .mutate<ICreateClinicaMutation>({
        mutation: CreateClinicaMutation,
        variables,
      })
      .then(({ data }) => data?.createClinica);
  },

  async deleteClinica(variables: IDeleteClinicaMutationVariables) {
    return apolloClient
      .mutate<IDeleteClinicaMutation>({
        mutation: DeleteClinicaMutation,
        variables,
        refetchQueries: getRefetchQueries([ClinicasQuery]),
      })
      .then(({ data }) => data?.deleteClinica);
  },

  async deleteManyClinicas(variables: IDeleteManyClinicasMutationVariables) {
    return apolloClient
      .mutate<IDeleteManyClinicasMutation>({
        mutation: DeleteManyClinicasMutation,
        variables,
        refetchQueries: getRefetchQueries([ClinicasQuery]),
      })
      .then(({ data }) => data?.deleteManyClinicas);
  },

  async updateClinica(variables: IUpdateClinicaMutationVariables) {
    return apolloClient
      .mutate<IUpdateClinicaMutation>({
        mutation: UpdateClinicaMutation,
        variables,
      })
      .then(({ data }) => data?.updateClinica);
  },
};
