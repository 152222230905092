import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import {
  AgendamentoCancelamentoMotivo,
  AgendamentoStatus,
  IAgendamentoRecorrenciaTerminaEmTipo,
  IInputItems,
} from '@/typings';
import { IAgendamentoStatusInputItem } from '@/typings/items/agendamento.t';

export const agendamentoStatus: IAgendamentoStatusInputItem[] = [
  {
    label: 'Agendado',
    value: AgendamentoStatus.AGENDADO,
    icon: MyIcons.agendamentoStatus.agendado,
    color: MyTheme.coolGray600,
  },
  {
    label: 'Confirmado',
    value: AgendamentoStatus.CONFIRMADO,
    icon: MyIcons.agendamentoStatus.confirmado,
    color: MyTheme.blue600,
  },
  {
    label: 'Dilatando pupila',
    value: AgendamentoStatus.DILATANDO_PUPILA,
    icon: MyIcons.agendamentoStatus.dilatandoPupila,
    color: MyTheme.indigo600,
  },
  {
    label: 'Aguardando atendimento',
    value: AgendamentoStatus.AGUARDANDO_ATENDIMENTO,
    icon: MyIcons.agendamentoStatus.aguardandoAtendimento,
    color: MyTheme.yellow500,
  },
  {
    label: 'Cancelado',
    value: AgendamentoStatus.CANCELADO,
    icon: MyIcons.agendamentoStatus.cancelado,
    color: MyTheme.yellow700,
  },
  {
    label: 'Não compareceu',
    value: AgendamentoStatus.NAO_COMPARECEU,
    icon: MyIcons.agendamentoStatus.cancelado,
    color: MyTheme.red600,
  },
  {
    label: 'Paciente atendido',
    value: AgendamentoStatus.PACIENTE_ATENDIDO,
    icon: MyIcons.agendamentoStatus.pacienteAtendido,
    color: MyTheme.green600,
  },
];

export const agendamentoRecorrenciaTerminaEmTipos: Array<{
  label: string;
  value: IAgendamentoRecorrenciaTerminaEmTipo;
}> = [
  { label: 'Após ocorrências', value: 'ocorrencias' },
  { label: 'Em data', value: 'dataFinal' },
];

export const agendamentoCancelamentoMotivos: IInputItems[] = [
  {
    label: 'Desmarcado pela clínica',
    value: AgendamentoCancelamentoMotivo.DESMARCADO_PELA_CLINICA,
  },
  {
    label: 'Desmarcado pelo paciente',
    value: AgendamentoCancelamentoMotivo.DESMARCADO_PELO_PACIENTE,
  },
  {
    label: 'Paciente não compareceu',
    value: AgendamentoCancelamentoMotivo.PACIENTE_NAO_COMPARECEU,
  },
  {
    label: 'Outro motivo',
    value: AgendamentoCancelamentoMotivo.OUTRO_MOTIVO,
  },
];
