import CreateTissGuiaOdontologicaMutation from '@/graphql/tiss/tissGuiaOdontologica/createTissGuiaOdontologica.graphql';
import DeleteTissGuiaOdontologicaMutation from '@/graphql/tiss/tissGuiaOdontologica/deleteTissGuiaOdontologica.graphql';
import TissGuiaOdontologicaQuery from '@/graphql/tiss/tissGuiaOdontologica/tissGuiaOdontologica.graphql';
import TissGuiasOdontologicasQuery from '@/graphql/tiss/tissGuiaOdontologica/tissGuiasOdontologicas.graphql';
import UpdateTissGuiaOdontologicaMutation from '@/graphql/tiss/tissGuiaOdontologica/updateTissGuiaOdontologica.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissGuiaOdontologicaMutation,
  ICreateTissGuiaOdontologicaMutationVariables,
  IDeleteTissGuiaOdontologicaMutation,
  IDeleteTissGuiaOdontologicaMutationVariables,
  ITissGuiaOdontologicaQuery,
  ITissGuiaOdontologicaQueryVariables,
  ITissGuiasOdontologicasQuery,
  ITissGuiasOdontologicasQueryVariables,
  IUpdateTissGuiaOdontologicaMutation,
  IUpdateTissGuiaOdontologicaMutationVariables,
} from '@/typings';

export const TissGuiaOdontologicaGraphql = {
  query: {
    TissGuiaOdontologicaQuery,
    TissGuiasOdontologicasQuery,
  },

  async createTissGuiaOdontologica(
    variables: ICreateTissGuiaOdontologicaMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTissGuiaOdontologicaMutation>({
        mutation: CreateTissGuiaOdontologicaMutation,
        variables,
      })
      .then(({ data }) => data?.createTissGuiaOdontologica);
  },

  async deleteTissGuiaOdontologica(
    variables: IDeleteTissGuiaOdontologicaMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTissGuiaOdontologicaMutation>({
        mutation: DeleteTissGuiaOdontologicaMutation,
        variables,
        refetchQueries: getRefetchQueries([TissGuiasOdontologicasQuery]),
      })
      .then(({ data }) => data?.deleteTissGuiaOdontologica);
  },

  async tissGuiaOdontologica(variables: ITissGuiaOdontologicaQueryVariables) {
    return apolloClient
      .query<ITissGuiaOdontologicaQuery>({
        query: TissGuiaOdontologicaQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiaOdontologica);
  },

  async tissGuiasOdontologicas(
    variables: ITissGuiasOdontologicasQueryVariables,
  ) {
    return apolloClient
      .query<ITissGuiasOdontologicasQuery>({
        query: TissGuiasOdontologicasQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiasOdontologicas);
  },

  async updateTissGuiaOdontologica(
    variables: IUpdateTissGuiaOdontologicaMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTissGuiaOdontologicaMutation>({
        mutation: UpdateTissGuiaOdontologicaMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissGuiaOdontologica);
  },
};
