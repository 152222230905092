import { DateHelpers, IDateTypes } from '@/lib/helpers/date.helpers';
import isNumber from 'lodash/isNumber';

export const getGraphqlErrorMessage = error => {
  if (error?.graphQLErrors?.[0]) {
    return error.graphQLErrors[0].message;
  }
  console.error('Handle GraphqlError', error);
};

export const handleError = error => {
  console.error(error);
};

export const Validate = {
  require<T>(value: T | null | undefined, name: string): NonNullable<T> {
    if (!value && !isNumber(value)) {
      throw new Error(`"${name}" is required`);
    }

    return value!;
  },

  dateTime(value: IDateTypes, name: string) {
    return DateHelpers.toISO(Validate.require(value, name))!;
  },

  date(value: IDateTypes, name: string) {
    return DateHelpers.toISODate(Validate.require(value, name))!;
  },

  time(value: IDateTypes, name: string) {
    return DateHelpers.toISOTime(Validate.require(value, name))!;
  },

  min(value: number | null | undefined, min: number, name: string) {
    const reqValue = Validate.require(value, name);

    if (reqValue < min) {
      throw new Error(`"${name}" não pode ser menor do que ${min}`);
    }

    return reqValue;
  },

  either(
    values: Array<string | number | Date | null | undefined | object>,
    fieldsNames: string[],
  ) {
    if (values.every(v => !v)) {
      throw new Error(
        `Ao menos um dos campos "${fieldsNames.join(
          '", "',
        )}" dever ser preenchido`,
      );
    }
  },
};
