import AtendimentoQuery from '@/graphql/profissional/atendimento/atendimento.graphql';
import AtendimentoCacheQuery from '@/graphql/profissional/atendimento/atendimentoCache.graphql';
import AtendimentosQuery from '@/graphql/profissional/atendimento/atendimentos.graphql';
import CreateAtendimentoMutation from '@/graphql/profissional/atendimento/createAtendimento.graphql';
import CreateAtendimentoObservacaoMutation from '@/graphql/profissional/atendimento/createAtendimentoObservacao.graphql';
import DeleteAtendimentoCacheMutation from '@/graphql/profissional/atendimento/deleteAtendimentoCache.graphql';
import SaveAtendimentoCacheMutation from '@/graphql/profissional/atendimento/saveAtendimentoCache.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  IAtendimentoCacheQuery,
  IAtendimentoCacheQueryVariables,
  IAtendimentoQuery,
  IAtendimentoQueryVariables,
  IAtendimentosQuery,
  IAtendimentosQueryVariables,
  ICreateAtendimentoMutation,
  ICreateAtendimentoMutationVariables,
  ICreateAtendimentoObservacaoMutation,
  ICreateAtendimentoObservacaoMutationVariables,
  IDeleteAtendimentoCacheMutation,
  IDeleteAtendimentoCacheMutationVariables,
  ISaveAtendimentoCacheMutation,
  ISaveAtendimentoCacheMutationVariables,
} from '@/typings';

export const AtendimentoGraphql = {
  query: {
    AtendimentoQuery,
    AtendimentosQuery,
  },

  async atendimento(variables: IAtendimentoQueryVariables) {
    return apolloClient
      .query<IAtendimentoQuery>({
        query: AtendimentoQuery,
        variables,
      })
      .then(({ data }) => data?.atendimento);
  },

  async atendimentoCache(variables: IAtendimentoCacheQueryVariables) {
    return apolloClient
      .query<IAtendimentoCacheQuery>({
        query: AtendimentoCacheQuery,
        variables,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data?.atendimentoCache);
  },

  async atendimentos(variables: IAtendimentosQueryVariables, refetch = false) {
    return apolloClient
      .query<IAtendimentosQuery>({
        query: AtendimentosQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.atendimentos);
  },

  async createAtendimento(variables: ICreateAtendimentoMutationVariables) {
    return apolloClient
      .mutate<ICreateAtendimentoMutation>({
        mutation: CreateAtendimentoMutation,
        variables,
      })
      .then(({ data }) => data?.createAtendimento);
  },

  async createAtendimentoObservacao(
    variables: ICreateAtendimentoObservacaoMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateAtendimentoObservacaoMutation>({
        mutation: CreateAtendimentoObservacaoMutation,
        variables,
      })
      .then(({ data }) => data?.createAtendimentoObservacao);
  },

  async deleteAtendimentoCache(
    variables: IDeleteAtendimentoCacheMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteAtendimentoCacheMutation>({
        mutation: DeleteAtendimentoCacheMutation,
        variables,
      })
      .then(({ data }) => data?.deleteAtendimentoCache);
  },

  async saveAtendimentoCache(
    variables: ISaveAtendimentoCacheMutationVariables,
  ) {
    return apolloClient
      .mutate<ISaveAtendimentoCacheMutation>({
        mutation: SaveAtendimentoCacheMutation,
        variables,
      })
      .then(({ data }) => data?.saveAtendimentoCache);
  },
};
