import { createComponent } from '@/lib/vue';
import { TimelineBodyHtml } from './TimelineBodyHtml';

interface IProps {
  label?: string | null;
  text: string | null | undefined;
  newLine?: boolean;
  noPadding?: boolean;
  richText?: boolean;
}

export const TimelineBodyItem = createComponent<IProps>({
  name: 'TimelineBodyItem',
  props: {
    label: { type: String },
    text: { type: String },
    newLine: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    richText: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () =>
      !!props.text && (
        <div class={{ 'py-2': !props.noPadding }}>
          <div class="flex flex-wrap items-center">
            {props.label && (
              <div
                class={[
                  'flex text-body-sm',
                  props.newLine ? 'w-full' : 'flex-none',
                ]}
              >
                {props.label}:
              </div>
            )}

            <div
              class={[
                'flex whitespace-pre-wrap bg-gray-200 py-1 px-2 text-body-sm',
                {
                  'ml-2': !!props.label,
                  'font-medium': !props.richText,
                },
                props.richText ? 'w-11/12' : 'flex-none',
              ]}
            >
              {props.richText ? (
                <TimelineBodyHtml html={props.text} />
              ) : (
                props.text
              )}
            </div>
          </div>
        </div>
      );
  },
});
