import { UserGraphql } from '@/graphql/system/user/UserGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { appMainScrollToTop } from '@/lib/helpers/scroll';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import { getUser } from '@/store/utils/auth';
import {
  CreateUserDataInput,
  IEquipeFormModel,
  IServiceOnSuccessArgs,
  IUserFormModel,
  Sexo,
  UpdateMeDataInput,
  UpdateUserDataInput,
  UserTipo,
} from '@/typings';
import { AuthLoginService } from '../auth/login.service';

export const UserService = {
  async getById(id: string) {
    try {
      return await UserGraphql.user({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IUserFormModel) {
    try {
      const result = await UserGraphql.createUser({
        data: toCreateUserDataInput(model),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      if (id === getUser().id) {
        return FlashMessagesState.alertError({
          text: 'Você não pode excluir seu próprio usuário',
        });
      }

      await validateDelete(
        {
          text: 'este usuário',
          aviso: 'prontuários, agendas, dados financeiros entre outros',
          requirePassword: true,
        },
        async password => {
          const result = await UserGraphql.deleteUser({ id, password });

          onSuccess({ result, msg: 'excluído' });
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      if (ids.includes(getUser().id)) {
        return FlashMessagesState.alertError({
          text: 'Você não pode excluir seu próprio usuário',
        });
      }

      await validateDelete(
        {
          text: pluralize(
            ids,
            'os usuários selecionados',
            'o usuário selecionado',
          ),
          aviso: 'prontuários, agendas, dados financeiros entre outros',
          requirePassword: true,
        },
        async password => {
          const result = await UserGraphql.deleteManyUsers({ ids, password });

          if (result) {
            const msg = pluralize(
              ids,
              'Usuários excluídos',
              'Usuário excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: IUserFormModel) {
    try {
      const result = await UserGraphql.updateUser({
        id: Validate.require(id, 'id'),
        data: toUpdateUserDataInput(model),
      });

      if (id === getUser().id) {
        // update user in store
        AuthLoginService.loadMyUser();
      }

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },

  async updateMe(model: IUserFormModel) {
    try {
      const result = await UserGraphql.updateMe({
        data: toUpdateMeDataInput(model),
      });

      if (result) {
        toastSuccess('Perfil alterado com sucesso');

        appMainScrollToTop();
      }
    } catch (error) {
      handleError(error);
    }
  },

  async updateEquipe({ profissionais, recepcionistas }: IEquipeFormModel) {
    try {
      const result = await UserGraphql.updateEquipe({
        users: [...profissionais, ...recepcionistas],
      });

      if (result) {
        toastSuccess('Equipe alterada com sucesso');

        appMainScrollToTop();
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toCreateUserDataInput({
  usuario: { nome, email, sexo, tipo, adminAccount },
  profissionalSaude: {
    cbo,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    celular,
    tratamento,
  },
  clinicas,
  profissionais,
  imagem: { imagemId },
}: IUserFormModel): CreateUserDataInput {
  return {
    tipo: Validate.require(tipo, 'tipo') as UserTipo,
    nome: Validate.require(nome, 'nome'),
    email: Validate.require(email, 'email'),
    sexo: Validate.require(sexo, 'sexo') as Sexo,
    adminAccount,
    celular,
    tratamento,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    cbo,
    imagemId,
    clinicas: clinicas.map(v => ({
      clinicaId: Validate.require(v.clinicaId, 'clinicaId'),
      adminClinica: v.adminClinica,
    })),
    profissionaisIds: profissionais
      .filter(v => v.profissionalId)
      .map(v => v.profissionalId!),
  };
}

function toUpdateUserDataInput({
  usuario: { nome, email, sexo, tipo, adminAccount },
  profissionalSaude: {
    cbo,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    celular,
    tratamento,
  },
  redefinirSenha: { newPassword },
  clinicas,
  profissionais,
  imagem: { imagemId: imagemId },
}: IUserFormModel): UpdateUserDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    email: Validate.require(email, 'email'),
    sexo: Validate.require(sexo, 'sexo') as Sexo,
    adminAccount,
    celular,
    tratamento,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    cbo,
    imagemId,
    newPassword,
    clinicas: clinicas.map(v => ({
      clinicaId: Validate.require(v.clinicaId, 'clinicaId'),
      adminClinica: v.adminClinica,
    })),
    profissionaisIds: profissionais
      .filter(v => v.profissionalId)
      .map(v => v.profissionalId!),
  };
}

function toUpdateMeDataInput({
  usuario: { nome, email, sexo },
  profissionalSaude: {
    cbo,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    celular,
    tratamento,
  },
  redefinirSenha: { newPassword },
  imagem: { imagemId },
}: IUserFormModel): UpdateMeDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    email: Validate.require(email, 'email'),
    sexo: Validate.require(sexo, 'sexo') as Sexo,
    celular,
    tratamento,
    conselho,
    conselhoUf,
    conselhoRegistro,
    rqe,
    profissao,
    cbo,
    imagemId,
    newPassword,
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configAccount.usuarios.index);

    toastSuccess(`Usuário ${msg} com sucesso`);
  }
}
