import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment } from '@/typings';
import { TimelineObservacoes } from '../sections/observacoes/TimelineObservacoes';
import { TimelineAtestados } from '../sections/TimelineAtestados';
import { TimelineEventos } from '../sections/TimelineEventos';
import { TimelineExames } from '../sections/TimelineExames';
import { TimelineFiles } from '../sections/TimelineFiles';
import { TimelineHipoteseDiagnostica } from '../sections/TimelineHipoteseDiagnostica';
import { TimelinePreAtendimento } from '../sections/TimelinePreAtendimento';
import { TimelinePrescricoes } from '../sections/TimelinePrescricoes';
import { TimelineBodyHeader } from './TimelineBodyHeader';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineBody = createComponent<IProps>({
  name: 'TimelineBody',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <v-card outlined class="mt-6 -ml-4" id="timeline-item-body">
        <TimelineBodyHeader atendimento={props.atendimento} />

        <v-divider />

        <div class="flex flex-col px-4">
          <TimelinePreAtendimento
            atendimento={props.atendimento}
            class="my-4"
          />

          <TimelineEventos atendimento={props.atendimento} />

          <TimelineExames atendimento={props.atendimento} class="my-4" />

          <TimelinePrescricoes atendimento={props.atendimento} class="my-4" />

          <TimelineHipoteseDiagnostica
            atendimento={props.atendimento}
            class="my-4"
          />

          <TimelineAtestados atendimento={props.atendimento} class="my-4" />

          <TimelineFiles atendimento={props.atendimento} class="my-4" />
        </div>

        <v-divider />

        <TimelineObservacoes atendimento={props.atendimento} />
      </v-card>
    );
  },
});
