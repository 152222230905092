import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { QueryGraphql } from '@/graphql/query';
import { useState, useValue } from '@/lib/composables';
import { IFormEvents } from '@/lib/composables/form/useFormConfig';
import { formatCpf } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { ProntuarioState } from '@/store/modules/prontuario';
import { getUser } from '@/store/utils/auth';
import {
  IAtestadoModeloFragment,
  IAtestadosModelosQuery,
  IAtestadosModelosQueryVariables,
  IForm,
  IFormSchema,
  IProntuarioAtestadoFormModel,
  IProntuarioAtestadoModel,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {
  value: IProntuarioAtestadoFormModel;
}

interface IEvents extends IFormEvents<IProntuarioAtestadoFormModel> {}

export const ProntuarioAtestadosForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioAtestadosForm',
  props: {
    value: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $showDelete, $form, $schema, $submitting, emitSubmit } =
      useComputeds(props, ctx);

    const { handleModelInput, handleUsarModelo } = useEvents(props, ctx);

    return () => (
      <ProntuarioCardForm>
        <MyForm
          form={$form.value}
          noCancel
          noDelete
          noSummary
          onSubmit={emitSubmit}
        >
          <TopActionsRow
            id={props.value.id}
            showDelete={$showDelete.value}
            submitting={$submitting.value}
            handleUsarModelo={handleUsarModelo}
          />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            class="m-4"
            value={props.value}
            onInput={handleModelInput}
          />

          <BottomActionsRow slot="actions" submitting={$submitting.value} />
        </MyForm>
      </ProntuarioCardForm>
    );
  },
});

function useComputeds(props: IProps, ctx: SetupContext) {
  const $showDelete = useState(
    s => s.prontuario.forms.atestados.model.length > 1,
  );

  const [$submitting] = useValue(false);

  const $form = useState<IForm<any>>(s => ({
    id: null,
    model: props.value,
    submitted: s.prontuario.forms.atestados.submitted,
    loading: false,
    validationErrors: [],
    page: {
      id: null,
      submitting: false,
      error: null,
    },
  }));

  const $schema = computed<IFormSchema<IProntuarioAtestadoModel>>(() => ({
    data: {
      label: 'Data',
      type: 'date',
      layout: {
        sm: 3,
        width: 182,
      },
      validations: { required: true },
    },
    titulo: {
      label: 'Título',
      type: 'text',
      layout: {
        sm: 8,
        minWidth: 360,
      },
      validations: {
        required: true,
      },
    },
    texto: {
      label: 'Texto',
      type: 'rich-text',
      validations: {
        required: true,
      },
    },
  }));

  function emitSubmit() {
    ProntuarioState.model.atestados.setSubmitted(true);

    ctx.emit('submit');
  }

  return {
    $showDelete,
    $form,
    $schema,
    $submitting,
    emitSubmit,
  };
}

function useEvents(props: IProps, ctx: SetupContext) {
  const profissionalId = getUser().id;

  function handleModelInput(model: IProntuarioAtestadoFormModel) {
    ProntuarioState.model.atestados.setModel(model);
  }

  function handleUsarModelo() {
    DialogHelpers.prontuario.modelo({
      title: 'Modelos de atestados',
      params: {
        mapQueryConfig: $search => ({
          query: QueryGraphql.AtestadosModelosQuery,
          variables(): IAtestadosModelosQueryVariables {
            return {
              where: { nome_contains: $search.value },
              profissionalId,
            };
          },
          mapData(result: IAtestadosModelosQuery) {
            return result?.atestadosModelos.nodes;
          },
        }),
        onSelect(v: IAtestadoModeloFragment) {
          ProntuarioState.model.atestados.setModel({
            ...props.value,
            titulo: v.titulo,
            texto: mapMacros({ text: v.texto }),
          });
        },
        cadastroRoute:
          Routes.app.configProfissionais(profissionalId).prontuario.atestados
            .new,
      },
    });
  }

  return { handleModelInput, handleUsarModelo };
}

const TopActionsRow = createComponent({
  name: 'ProntuarioAtestadosFormTopActionsRow',
  props: {
    id: { type: String, required: true },
    showDelete: { type: Boolean, required: true },
    submitting: { type: Boolean, required: true },
    handleUsarModelo: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center px-4 pt-4 pb-2">
        <v-btn
          outlined
          color="secondary"
          disabled={props.submitting}
          onClick={props.handleUsarModelo}
        >
          <v-icon left>{MyIcons.document}</v-icon>
          Usar modelo
        </v-btn>

        <v-spacer />

        {props.showDelete && (
          <v-btn
            color="secondary"
            icon
            small
            class="ml-3"
            aria-label="Remover"
            data-balloon-pos="up"
            onClick={() => ProntuarioState.model.atestados.remove(props.id)}
          >
            <v-icon>{MyIcons.remove}</v-icon>
          </v-btn>
        )}
      </div>
    );
  },
});

const BottomActionsRow = createComponent({
  name: 'ProntuarioAtestadosFormBottomActionsRow',
  props: {
    submitting: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center">
        <v-spacer />

        <v-btn
          type="submit"
          color="primary"
          loading={props.submitting}
          disabled={props.submitting}
        >
          Salvar
        </v-btn>
      </div>
    );
  },
});

function mapMacros({ text }: { text: string }) {
  const paciente = useState(s => s.prontuario.paciente?.data).value;

  const clinica = useState(s => s.auth.clinica).value;

  const procedimentos = useState(
    s => s.prontuario.agendamento?.procedimentos,
  ).value;

  const cids = useState(
    s => s.prontuario.forms.hipoteseDiagnostica.model.cids,
  ).value;

  const parentes = paciente?.parentes || [];
  const pai = parentes.find(f => f.parentesco === 'Pai');
  const mae = parentes.find(f => f.parentesco === 'Mãe');

  const nomePaciente = paciente?.nome || '#NOME_PACIENTE#';
  const rgPaciente = paciente?.rg || '#RG_PACIENTE#';
  const cpfPaciente = formatCpf(paciente?.cpf) || '#CPF_PACIENTE#';
  const idadePaciente =
    DateHelpers.idade(paciente?.dataNascimento, true) || '#IDADE_PACIENTE#';
  const nomePai = pai?.nome || '#NOME_PAI#';
  const nomeMae = mae?.nome || '#NOME_MAE#';
  const nomeClinica = clinica?.nome || '#NOME_CLINICA#';
  const nomeProcedimento =
    procedimentos?.map(v => v.procedimento.nome).join(', ') ||
    '#NOME_PROCEDIMENTO#';
  const listaCid =
    (cids.length &&
      `<ul>${cids.map(v => `<li>${v.descricao}</li>`).join('')}</ul>`) ||
    '#LISTA_CID#';

  return (
    text
      // #NOME_PACIENTE#
      .replace(/#NOME_PACIENTE#/g, nomePaciente)
      // #RG_PACIENTE#
      .replace(/#RG_PACIENTE#/g, rgPaciente)
      // #CPF_PACIENTE#
      .replace(/#CPF_PACIENTE#/g, cpfPaciente)
      // #IDADE_PACIENTE#
      .replace(/#IDADE_PACIENTE#/g, idadePaciente)
      // #NOME_PAI#
      .replace(/#NOME_PAI#/g, nomePai)
      // #NOME_MAE#
      .replace(/#NOME_MAE#/g, nomeMae)
      // #NOME_CLINICA#
      .replace(/#NOME_CLINICA#/g, nomeClinica)
      // #DATA_ATUAL#
      .replace(/#DATA_ATUAL#/g, DateHelpers.formatDate(new Date())!)
      // #HORA_ATUAL#
      .replace(/#HORA_ATUAL#/g, DateHelpers.formatHour(new Date())!)
      // #NOME_PROCEDIMENTO#
      .replace(/#NOME_PROCEDIMENTO#/g, nomeProcedimento)
      // #LISTA_CID#
      .replace(/#LISTA_CID#/g, listaCid)
  );
}
