import ContaFinanceiraQuery from '@/graphql/financas/contaFinanceira/contaFinanceira.graphql';
import ContasFinanceirasQuery from '@/graphql/financas/contaFinanceira/contasFinanceiras.graphql';
import CreateContaFinanceiraMutation from '@/graphql/financas/contaFinanceira/createContaFinanceira.graphql';
import DeleteContaFinanceiraMutation from '@/graphql/financas/contaFinanceira/deleteContaFinanceira.graphql';
import DeleteManyContasFinanceirasMutation from '@/graphql/financas/contaFinanceira/deleteManyContasFinanceiras.graphql';
import UpdateContaFinanceiraMutation from '@/graphql/financas/contaFinanceira/updateContaFinanceira.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  IContaFinanceiraQuery,
  IContaFinanceiraQueryVariables,
  IContasFinanceirasQuery,
  IContasFinanceirasQueryVariables,
  ICreateContaFinanceiraMutation,
  ICreateContaFinanceiraMutationVariables,
  IDeleteContaFinanceiraMutation,
  IDeleteContaFinanceiraMutationVariables,
  IDeleteManyContasFinanceirasMutation,
  IDeleteManyContasFinanceirasMutationVariables,
  IUpdateContaFinanceiraMutation,
  IUpdateContaFinanceiraMutationVariables,
} from '@/typings';

export const ContaFinanceiraGraphql = {
  query: {
    ContaFinanceiraQuery,
    ContasFinanceirasQuery,
  },

  async contaFinanceira(variables: IContaFinanceiraQueryVariables) {
    return apolloClient
      .query<IContaFinanceiraQuery>({
        query: ContaFinanceiraQuery,
        variables,
      })
      .then(({ data }) => data?.contaFinanceira);
  },

  async contasFinanceiras(variables: IContasFinanceirasQueryVariables) {
    return apolloClient
      .query<IContasFinanceirasQuery>({
        query: ContasFinanceirasQuery,
        variables,
      })
      .then(({ data }) => data?.contasFinanceiras.nodes);
  },

  async createContaFinanceira(
    variables: ICreateContaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateContaFinanceiraMutation>({
        mutation: CreateContaFinanceiraMutation,
        variables,
      })
      .then(({ data }) => data?.createContaFinanceira);
  },

  async deleteContaFinanceira(
    variables: IDeleteContaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteContaFinanceiraMutation>({
        mutation: DeleteContaFinanceiraMutation,
        variables,
        refetchQueries: getRefetchQueries([ContasFinanceirasQuery]),
      })
      .then(({ data }) => data?.deleteContaFinanceira);
  },

  async deleteManyContasFinanceiras(
    variables: IDeleteManyContasFinanceirasMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyContasFinanceirasMutation>({
        mutation: DeleteManyContasFinanceirasMutation,
        variables,
        refetchQueries: getRefetchQueries([ContasFinanceirasQuery]),
      })
      .then(({ data }) => data?.deleteManyContasFinanceiras);
  },

  async updateContaFinanceira(
    variables: IUpdateContaFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateContaFinanceiraMutation>({
        mutation: UpdateContaFinanceiraMutation,
        variables,
      })
      .then(({ data }) => data?.updateContaFinanceira);
  },
};
