import { isDev } from '@/lib/helpers/env';
import { appMainScrollToTop } from '@/lib/helpers/scroll';
import SandboxPage from '@/modules/dev/DevPage';
import { requireAuthGuard } from '@/routes/guards/requireAuth.guard';
import { agendaRoutes } from '@/routes/modules/agenda.routes';
import { authRoutes } from '@/routes/modules/auth.routes';
import { cidRoutes } from '@/routes/modules/cid.routes';
import { configuracoesAccountRoutes } from '@/routes/modules/configuracoesAccount.routes';
import { configuracoesClinicaRoutes } from '@/routes/modules/configuracoesClinica.routes';
import { configuracoesProfissionaisRoutes } from '@/routes/modules/configuracoesProfissionais.routes';
import { confirmarAgendamentoRoutes } from '@/routes/modules/confirmarAgendamento.routes';
import { contatosRoutes } from '@/routes/modules/contatos.routes';
import { dashboardRoutes } from '@/routes/modules/dashboard.routes';
import { financasRoutes } from '@/routes/modules/financas.routes';
import { logsRoutes } from '@/routes/modules/logs.routes';
import { pacientesRoutes } from '@/routes/modules/pacientes.routes';
import { prontuariosRoutes } from '@/routes/modules/prontuarios.routes';
import { relatoriosRoutes } from '@/routes/modules/relatorios.routes';
import { tissRoutes } from '@/routes/modules/tiss.routes';
import { redirectHome } from '@/routes/utils';
import Vue from 'vue';
import Router from 'vue-router';
import { errorsRoutes } from './modules/errors.routes';

const AppLayout = () => import('@/layouts/AppLayout');

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    appMainScrollToTop(false);

    return { x: 0, y: 0 };
  },
  routes: [
    ...authRoutes,
    ...confirmarAgendamentoRoutes,
    {
      path: '/',
      component: AppLayout,
      beforeEnter: requireAuthGuard,
      children: [
        ...dashboardRoutes,
        ...agendaRoutes,
        ...cidRoutes,
        ...configuracoesClinicaRoutes,
        ...configuracoesProfissionaisRoutes,
        ...configuracoesAccountRoutes,
        ...contatosRoutes,
        ...financasRoutes,
        ...logsRoutes,
        ...pacientesRoutes,
        ...prontuariosRoutes,
        ...relatoriosRoutes,
        ...tissRoutes,
        ...errorsRoutes,
        {
          path: '',
          beforeEnter: redirectHome,
        },
      ],
    },
    {
      path: '*',
      beforeEnter: redirectHome,
    },
  ],
});

if (isDev) {
  // Routes only for development
  router.addRoute({
    path: '/dev',
    component: SandboxPage,
  });
}
