/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IConfirmEmailMutation
// ====================================================

export interface IConfirmEmailMutation_confirmEmail_user_account {
  __typename: "Account";
  id: string;
}

export interface IConfirmEmailMutation_confirmEmail_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IConfirmEmailMutation_confirmEmail_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IConfirmEmailMutation_confirmEmail_user_clinicas_clinica_profissionais[];
}

export interface IConfirmEmailMutation_confirmEmail_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IConfirmEmailMutation_confirmEmail_user_clinicas_clinica;
}

export interface IConfirmEmailMutation_confirmEmail_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmEmailMutation_confirmEmail_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IConfirmEmailMutation_confirmEmail_user_profissionais_imagem | null;
}

export interface IConfirmEmailMutation_confirmEmail_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmEmailMutation_confirmEmail_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IConfirmEmailMutation_confirmEmail_user_account;
  clinicas: IConfirmEmailMutation_confirmEmail_user_clinicas[] | null;
  profissionais: IConfirmEmailMutation_confirmEmail_user_profissionais[] | null;
  imagem: IConfirmEmailMutation_confirmEmail_user_imagem | null;
}

export interface IConfirmEmailMutation_confirmEmail {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: IConfirmEmailMutation_confirmEmail_user;
}

export interface IConfirmEmailMutation {
  confirmEmail: IConfirmEmailMutation_confirmEmail;
}

export interface IConfirmEmailMutationVariables {
  emailToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IResendConfirmationEmailMutation
// ====================================================

export interface IResendConfirmationEmailMutation_resendConfirmationEmail {
  __typename: "OkPayload";
  ok: boolean;
}

export interface IResendConfirmationEmailMutation {
  resendConfirmationEmail: IResendConfirmationEmailMutation_resendConfirmationEmail;
}

export interface IResendConfirmationEmailMutationVariables {
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICheckConviteQuery
// ====================================================

export interface ICheckConviteQuery_checkConvite {
  __typename: "CheckPayload";
  valid: boolean;
}

export interface ICheckConviteQuery {
  checkConvite: ICheckConviteQuery_checkConvite;
}

export interface ICheckConviteQueryVariables {
  conviteToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IConfirmConviteMutation
// ====================================================

export interface IConfirmConviteMutation_confirmConvite_user_account {
  __typename: "Account";
  id: string;
}

export interface IConfirmConviteMutation_confirmConvite_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IConfirmConviteMutation_confirmConvite_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IConfirmConviteMutation_confirmConvite_user_clinicas_clinica_profissionais[];
}

export interface IConfirmConviteMutation_confirmConvite_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IConfirmConviteMutation_confirmConvite_user_clinicas_clinica;
}

export interface IConfirmConviteMutation_confirmConvite_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmConviteMutation_confirmConvite_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IConfirmConviteMutation_confirmConvite_user_profissionais_imagem | null;
}

export interface IConfirmConviteMutation_confirmConvite_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmConviteMutation_confirmConvite_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IConfirmConviteMutation_confirmConvite_user_account;
  clinicas: IConfirmConviteMutation_confirmConvite_user_clinicas[] | null;
  profissionais: IConfirmConviteMutation_confirmConvite_user_profissionais[] | null;
  imagem: IConfirmConviteMutation_confirmConvite_user_imagem | null;
}

export interface IConfirmConviteMutation_confirmConvite {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: IConfirmConviteMutation_confirmConvite_user;
}

export interface IConfirmConviteMutation {
  confirmConvite: IConfirmConviteMutation_confirmConvite;
}

export interface IConfirmConviteMutationVariables {
  conviteToken: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICheckEsqueceuSenhaQuery
// ====================================================

export interface ICheckEsqueceuSenhaQuery_checkEsqueceuSenha {
  __typename: "CheckPayload";
  valid: boolean;
}

export interface ICheckEsqueceuSenhaQuery {
  checkEsqueceuSenha: ICheckEsqueceuSenhaQuery_checkEsqueceuSenha;
}

export interface ICheckEsqueceuSenhaQueryVariables {
  esqueceuSenhaToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IEsqueceuSenhaPart1Mutation
// ====================================================

export interface IEsqueceuSenhaPart1Mutation_esqueceuSenhaPart1 {
  __typename: "OkPayload";
  ok: boolean;
}

export interface IEsqueceuSenhaPart1Mutation {
  esqueceuSenhaPart1: IEsqueceuSenhaPart1Mutation_esqueceuSenhaPart1;
}

export interface IEsqueceuSenhaPart1MutationVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IEsqueceuSenhaPart2Mutation
// ====================================================

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_account {
  __typename: "Account";
  id: string;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas_clinica_profissionais[];
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas_clinica;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_profissionais_imagem | null;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_account;
  clinicas: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_clinicas[] | null;
  profissionais: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_profissionais[] | null;
  imagem: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user_imagem | null;
}

export interface IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2 {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2_user;
}

export interface IEsqueceuSenhaPart2Mutation {
  esqueceuSenhaPart2: IEsqueceuSenhaPart2Mutation_esqueceuSenhaPart2;
}

export interface IEsqueceuSenhaPart2MutationVariables {
  esqueceuSenhaToken: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ILoginMutation
// ====================================================

export interface ILoginMutation_login_user_account {
  __typename: "Account";
  id: string;
}

export interface ILoginMutation_login_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface ILoginMutation_login_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: ILoginMutation_login_user_clinicas_clinica_profissionais[];
}

export interface ILoginMutation_login_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: ILoginMutation_login_user_clinicas_clinica;
}

export interface ILoginMutation_login_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ILoginMutation_login_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: ILoginMutation_login_user_profissionais_imagem | null;
}

export interface ILoginMutation_login_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ILoginMutation_login_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: ILoginMutation_login_user_account;
  clinicas: ILoginMutation_login_user_clinicas[] | null;
  profissionais: ILoginMutation_login_user_profissionais[] | null;
  imagem: ILoginMutation_login_user_imagem | null;
}

export interface ILoginMutation_login {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: ILoginMutation_login_user;
}

export interface ILoginMutation {
  login: ILoginMutation_login;
}

export interface ILoginMutationVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IMeQuery
// ====================================================

export interface IMeQuery_me_account {
  __typename: "Account";
  id: string;
}

export interface IMeQuery_me_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IMeQuery_me_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IMeQuery_me_clinicas_clinica_profissionais[];
}

export interface IMeQuery_me_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IMeQuery_me_clinicas_clinica;
}

export interface IMeQuery_me_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IMeQuery_me_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IMeQuery_me_profissionais_imagem | null;
}

export interface IMeQuery_me_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IMeQuery_me {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IMeQuery_me_account;
  clinicas: IMeQuery_me_clinicas[] | null;
  profissionais: IMeQuery_me_profissionais[] | null;
  imagem: IMeQuery_me_imagem | null;
}

export interface IMeQuery {
  me: IMeQuery_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IRefreshTokenMutation
// ====================================================

export interface IRefreshTokenMutation_refreshToken_user_account {
  __typename: "Account";
  id: string;
}

export interface IRefreshTokenMutation_refreshToken_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IRefreshTokenMutation_refreshToken_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IRefreshTokenMutation_refreshToken_user_clinicas_clinica_profissionais[];
}

export interface IRefreshTokenMutation_refreshToken_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IRefreshTokenMutation_refreshToken_user_clinicas_clinica;
}

export interface IRefreshTokenMutation_refreshToken_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IRefreshTokenMutation_refreshToken_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IRefreshTokenMutation_refreshToken_user_profissionais_imagem | null;
}

export interface IRefreshTokenMutation_refreshToken_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IRefreshTokenMutation_refreshToken_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IRefreshTokenMutation_refreshToken_user_account;
  clinicas: IRefreshTokenMutation_refreshToken_user_clinicas[] | null;
  profissionais: IRefreshTokenMutation_refreshToken_user_profissionais[] | null;
  imagem: IRefreshTokenMutation_refreshToken_user_imagem | null;
}

export interface IRefreshTokenMutation_refreshToken {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: IRefreshTokenMutation_refreshToken_user;
}

export interface IRefreshTokenMutation {
  refreshToken: IRefreshTokenMutation_refreshToken;
}

export interface IRefreshTokenMutationVariables {
  clinicaId?: string | null;
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ISignUpPart1Mutation
// ====================================================

export interface ISignUpPart1Mutation_signUpPart1_user_account {
  __typename: "Account";
  id: string;
}

export interface ISignUpPart1Mutation_signUpPart1_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface ISignUpPart1Mutation_signUpPart1_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: ISignUpPart1Mutation_signUpPart1_user_clinicas_clinica_profissionais[];
}

export interface ISignUpPart1Mutation_signUpPart1_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: ISignUpPart1Mutation_signUpPart1_user_clinicas_clinica;
}

export interface ISignUpPart1Mutation_signUpPart1_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ISignUpPart1Mutation_signUpPart1_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: ISignUpPart1Mutation_signUpPart1_user_profissionais_imagem | null;
}

export interface ISignUpPart1Mutation_signUpPart1_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ISignUpPart1Mutation_signUpPart1_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: ISignUpPart1Mutation_signUpPart1_user_account;
  clinicas: ISignUpPart1Mutation_signUpPart1_user_clinicas[] | null;
  profissionais: ISignUpPart1Mutation_signUpPart1_user_profissionais[] | null;
  imagem: ISignUpPart1Mutation_signUpPart1_user_imagem | null;
}

export interface ISignUpPart1Mutation_signUpPart1 {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: ISignUpPart1Mutation_signUpPart1_user;
}

export interface ISignUpPart1Mutation {
  signUpPart1: ISignUpPart1Mutation_signUpPart1;
}

export interface ISignUpPart1MutationVariables {
  data: SignUpPart1DataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ISignUpPart2Mutation
// ====================================================

export interface ISignUpPart2Mutation_signUpPart2_user_account {
  __typename: "Account";
  id: string;
}

export interface ISignUpPart2Mutation_signUpPart2_user_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface ISignUpPart2Mutation_signUpPart2_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: ISignUpPart2Mutation_signUpPart2_user_clinicas_clinica_profissionais[];
}

export interface ISignUpPart2Mutation_signUpPart2_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: ISignUpPart2Mutation_signUpPart2_user_clinicas_clinica;
}

export interface ISignUpPart2Mutation_signUpPart2_user_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ISignUpPart2Mutation_signUpPart2_user_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: ISignUpPart2Mutation_signUpPart2_user_profissionais_imagem | null;
}

export interface ISignUpPart2Mutation_signUpPart2_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ISignUpPart2Mutation_signUpPart2_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: ISignUpPart2Mutation_signUpPart2_user_account;
  clinicas: ISignUpPart2Mutation_signUpPart2_user_clinicas[] | null;
  profissionais: ISignUpPart2Mutation_signUpPart2_user_profissionais[] | null;
  imagem: ISignUpPart2Mutation_signUpPart2_user_imagem | null;
}

export interface ISignUpPart2Mutation_signUpPart2 {
  __typename: "AuthPayload";
  accessToken: string;
  refreshToken: string;
  user: ISignUpPart2Mutation_signUpPart2_user;
}

export interface ISignUpPart2Mutation {
  signUpPart2: ISignUpPart2Mutation_signUpPart2;
}

export interface ISignUpPart2MutationVariables {
  data: SignUpPart2DataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoAgendaQuery
// ====================================================

export interface IConfiguracaoAgendaQuery_configuracaoAgenda_procedimentoPadrao {
  __typename: "Procedimento";
  id: string;
  nome: string;
  duracaoMinutos: number;
}

export interface IConfiguracaoAgendaQuery_configuracaoAgenda_procedimentosCarencias {
  __typename: "Procedimento";
  id: string;
}

export interface IConfiguracaoAgendaQuery_configuracaoAgenda_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoAgendaQuery_configuracaoAgenda {
  __typename: "ConfiguracaoAgenda";
  id: string;
  visualizacaoPadrao: ConfiguracaoAgendaVisualizacaoPadrao;
  alertaPeriodoCarencia: boolean;
  mostrarDiasBloqueados: boolean;
  domingo: boolean;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  horaInicio: any;
  horaFim: any;
  horarioAlmoco: boolean;
  horaAlmocoInicio: any;
  horaAlmocoFim: any;
  observacao: string | null;
  procedimentoPadrao: IConfiguracaoAgendaQuery_configuracaoAgenda_procedimentoPadrao;
  procedimentosCarencias: IConfiguracaoAgendaQuery_configuracaoAgenda_procedimentosCarencias[] | null;
  profissional: IConfiguracaoAgendaQuery_configuracaoAgenda_profissional;
}

export interface IConfiguracaoAgendaQuery {
  configuracaoAgenda: IConfiguracaoAgendaQuery_configuracaoAgenda | null;
}

export interface IConfiguracaoAgendaQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoAgendaMutation
// ====================================================

export interface IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_procedimentoPadrao {
  __typename: "Procedimento";
  id: string;
  nome: string;
  duracaoMinutos: number;
}

export interface IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_procedimentosCarencias {
  __typename: "Procedimento";
  id: string;
}

export interface IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda {
  __typename: "ConfiguracaoAgenda";
  id: string;
  visualizacaoPadrao: ConfiguracaoAgendaVisualizacaoPadrao;
  alertaPeriodoCarencia: boolean;
  mostrarDiasBloqueados: boolean;
  domingo: boolean;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  horaInicio: any;
  horaFim: any;
  horarioAlmoco: boolean;
  horaAlmocoInicio: any;
  horaAlmocoFim: any;
  observacao: string | null;
  procedimentoPadrao: IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_procedimentoPadrao;
  procedimentosCarencias: IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_procedimentosCarencias[] | null;
  profissional: IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda_profissional;
}

export interface IUpdateConfiguracaoAgendaMutation {
  updateConfiguracaoAgenda: IUpdateConfiguracaoAgendaMutation_updateConfiguracaoAgenda | null;
}

export interface IUpdateConfiguracaoAgendaMutationVariables {
  profissionalId: string;
  data: ConfiguracaoAgendaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoEmailQuery
// ====================================================

export interface IConfiguracaoEmailQuery_configuracaoEmail_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoEmailQuery_configuracaoEmail {
  __typename: "ConfiguracaoEmail";
  id: string;
  envioLembreteConsulta: boolean;
  lembreteConsultaAssunto: string;
  lembreteConsultaMensagem: string;
  recebimentoConsultasConfirmadas: boolean;
  recebimentoConsultaModificada: boolean;
  profissional: IConfiguracaoEmailQuery_configuracaoEmail_profissional;
}

export interface IConfiguracaoEmailQuery {
  configuracaoEmail: IConfiguracaoEmailQuery_configuracaoEmail | null;
}

export interface IConfiguracaoEmailQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoEmailMutation
// ====================================================

export interface IUpdateConfiguracaoEmailMutation_updateConfiguracaoEmail_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConfiguracaoEmailMutation_updateConfiguracaoEmail {
  __typename: "ConfiguracaoEmail";
  id: string;
  envioLembreteConsulta: boolean;
  lembreteConsultaAssunto: string;
  lembreteConsultaMensagem: string;
  recebimentoConsultasConfirmadas: boolean;
  recebimentoConsultaModificada: boolean;
  profissional: IUpdateConfiguracaoEmailMutation_updateConfiguracaoEmail_profissional;
}

export interface IUpdateConfiguracaoEmailMutation {
  updateConfiguracaoEmail: IUpdateConfiguracaoEmailMutation_updateConfiguracaoEmail | null;
}

export interface IUpdateConfiguracaoEmailMutationVariables {
  profissionalId: string;
  data: ConfiguracaoEmailDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoEmailAniversariantesQuery
// ====================================================

export interface IConfiguracaoEmailAniversariantesQuery_configuracaoEmailAniversariantes_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoEmailAniversariantesQuery_configuracaoEmailAniversariantes {
  __typename: "ConfiguracaoEmailAniversariantes";
  id: string;
  enviar: boolean;
  assunto: string;
  mensagem: string;
  profissional: IConfiguracaoEmailAniversariantesQuery_configuracaoEmailAniversariantes_profissional;
}

export interface IConfiguracaoEmailAniversariantesQuery {
  configuracaoEmailAniversariantes: IConfiguracaoEmailAniversariantesQuery_configuracaoEmailAniversariantes | null;
}

export interface IConfiguracaoEmailAniversariantesQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoEmailAniversariantesMutation
// ====================================================

export interface IUpdateConfiguracaoEmailAniversariantesMutation_updateConfiguracaoEmailAniversariantes_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConfiguracaoEmailAniversariantesMutation_updateConfiguracaoEmailAniversariantes {
  __typename: "ConfiguracaoEmailAniversariantes";
  id: string;
  enviar: boolean;
  assunto: string;
  mensagem: string;
  profissional: IUpdateConfiguracaoEmailAniversariantesMutation_updateConfiguracaoEmailAniversariantes_profissional;
}

export interface IUpdateConfiguracaoEmailAniversariantesMutation {
  updateConfiguracaoEmailAniversariantes: IUpdateConfiguracaoEmailAniversariantesMutation_updateConfiguracaoEmailAniversariantes | null;
}

export interface IUpdateConfiguracaoEmailAniversariantesMutationVariables {
  profissionalId: string;
  data: ConfiguracaoEmailAniversariantesDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoFinanceiraQuery
// ====================================================

export interface IConfiguracaoFinanceiraQuery_configuracaoFinanceira_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
}

export interface IConfiguracaoFinanceiraQuery_configuracaoFinanceira_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IConfiguracaoFinanceiraQuery_configuracaoFinanceira {
  __typename: "ConfiguracaoFinanceira";
  id: string;
  formaPagamento: FormaPagamento;
  recebimentoLancamento: boolean;
  recebimentoLancarAPartir: ConfiguracaoFinanceiraRecebimentoLancarAPartir;
  contaFinanceira: IConfiguracaoFinanceiraQuery_configuracaoFinanceira_contaFinanceira;
  centroCusto: IConfiguracaoFinanceiraQuery_configuracaoFinanceira_centroCusto | null;
}

export interface IConfiguracaoFinanceiraQuery {
  configuracaoFinanceira: IConfiguracaoFinanceiraQuery_configuracaoFinanceira | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoFinanceiraMutation
// ====================================================

export interface IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira {
  __typename: "ConfiguracaoFinanceira";
  id: string;
  formaPagamento: FormaPagamento;
  recebimentoLancamento: boolean;
  recebimentoLancarAPartir: ConfiguracaoFinanceiraRecebimentoLancarAPartir;
  contaFinanceira: IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira_contaFinanceira;
  centroCusto: IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira_centroCusto | null;
}

export interface IUpdateConfiguracaoFinanceiraMutation {
  updateConfiguracaoFinanceira: IUpdateConfiguracaoFinanceiraMutation_updateConfiguracaoFinanceira | null;
}

export interface IUpdateConfiguracaoFinanceiraMutationVariables {
  data: ConfiguracaoFinanceiraDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoImpressaoQuery
// ====================================================

export interface IConfiguracaoImpressaoQuery_configuracaoImpressao_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoImpressaoQuery_configuracaoImpressao_logo {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfiguracaoImpressaoQuery_configuracaoImpressao {
  __typename: "ConfiguracaoImpressao";
  id: string;
  tamanhoPagina: ConfiguracaoImpressaoTamanhoPagina;
  tamanhoFonte: number;
  margem: ConfiguracaoImpressaoMargem;
  logoPosition: ConfiguracaoImpressaoLogoPosition;
  cabecalho: boolean;
  assinatura: boolean;
  numeracaoAutomatica: boolean;
  rodape: boolean;
  rodapeTexto: string | null;
  profissional: IConfiguracaoImpressaoQuery_configuracaoImpressao_profissional;
  logo: IConfiguracaoImpressaoQuery_configuracaoImpressao_logo | null;
}

export interface IConfiguracaoImpressaoQuery {
  configuracaoImpressao: IConfiguracaoImpressaoQuery_configuracaoImpressao | null;
}

export interface IConfiguracaoImpressaoQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoImpressaoMutation
// ====================================================

export interface IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao_logo {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao {
  __typename: "ConfiguracaoImpressao";
  id: string;
  tamanhoPagina: ConfiguracaoImpressaoTamanhoPagina;
  tamanhoFonte: number;
  margem: ConfiguracaoImpressaoMargem;
  logoPosition: ConfiguracaoImpressaoLogoPosition;
  cabecalho: boolean;
  assinatura: boolean;
  numeracaoAutomatica: boolean;
  rodape: boolean;
  rodapeTexto: string | null;
  profissional: IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao_profissional;
  logo: IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao_logo | null;
}

export interface IUpdateConfiguracaoImpressaoMutation {
  updateConfiguracaoImpressao: IUpdateConfiguracaoImpressaoMutation_updateConfiguracaoImpressao | null;
}

export interface IUpdateConfiguracaoImpressaoMutationVariables {
  profissionalId: string;
  data: ConfiguracaoImpressaoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConfiguracaoSmsQuery
// ====================================================

export interface IConfiguracaoSmsQuery_configuracaoSms_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoSmsQuery_configuracaoSms {
  __typename: "ConfiguracaoSms";
  id: string;
  enviar: boolean;
  periodoEnvio: ConfiguracaoSmsPeriodoEnvio;
  mensagem: string;
  profissional: IConfiguracaoSmsQuery_configuracaoSms_profissional;
}

export interface IConfiguracaoSmsQuery {
  configuracaoSms: IConfiguracaoSmsQuery_configuracaoSms | null;
}

export interface IConfiguracaoSmsQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConfiguracaoSmsMutation
// ====================================================

export interface IUpdateConfiguracaoSmsMutation_updateConfiguracaoSms_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConfiguracaoSmsMutation_updateConfiguracaoSms {
  __typename: "ConfiguracaoSms";
  id: string;
  enviar: boolean;
  periodoEnvio: ConfiguracaoSmsPeriodoEnvio;
  mensagem: string;
  profissional: IUpdateConfiguracaoSmsMutation_updateConfiguracaoSms_profissional;
}

export interface IUpdateConfiguracaoSmsMutation {
  updateConfiguracaoSms: IUpdateConfiguracaoSmsMutation_updateConfiguracaoSms | null;
}

export interface IUpdateConfiguracaoSmsMutationVariables {
  profissionalId: string;
  data: ConfiguracaoSmsDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateConvenioMutation
// ====================================================

export interface ICreateConvenioMutation_createConvenio_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateConvenioMutation_createConvenio_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: ICreateConvenioMutation_createConvenio_planos_profissionais_profissional;
}

export interface ICreateConvenioMutation_createConvenio_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: ICreateConvenioMutation_createConvenio_planos_profissionais[] | null;
}

export interface ICreateConvenioMutation_createConvenio_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateConvenioMutation_createConvenio_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: ICreateConvenioMutation_createConvenio_profissionais_profissional;
}

export interface ICreateConvenioMutation_createConvenio {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: ICreateConvenioMutation_createConvenio_planos[] | null;
  profissionais: ICreateConvenioMutation_createConvenio_profissionais[] | null;
}

export interface ICreateConvenioMutation {
  createConvenio: ICreateConvenioMutation_createConvenio;
}

export interface ICreateConvenioMutationVariables {
  data: ConvenioDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteConvenioMutation
// ====================================================

export interface IDeleteConvenioMutation_deleteConvenio {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteConvenioMutation {
  deleteConvenio: IDeleteConvenioMutation_deleteConvenio;
}

export interface IDeleteConvenioMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyConveniosMutation
// ====================================================

export interface IDeleteManyConveniosMutation_deleteManyConvenios {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyConveniosMutation {
  deleteManyConvenios: IDeleteManyConveniosMutation_deleteManyConvenios;
}

export interface IDeleteManyConveniosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConvenioMutation
// ====================================================

export interface IUpdateConvenioMutation_updateConvenio_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConvenioMutation_updateConvenio_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IUpdateConvenioMutation_updateConvenio_planos_profissionais_profissional;
}

export interface IUpdateConvenioMutation_updateConvenio_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IUpdateConvenioMutation_updateConvenio_planos_profissionais[] | null;
}

export interface IUpdateConvenioMutation_updateConvenio_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateConvenioMutation_updateConvenio_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IUpdateConvenioMutation_updateConvenio_profissionais_profissional;
}

export interface IUpdateConvenioMutation_updateConvenio {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IUpdateConvenioMutation_updateConvenio_planos[] | null;
  profissionais: IUpdateConvenioMutation_updateConvenio_profissionais[] | null;
}

export interface IUpdateConvenioMutation {
  updateConvenio: IUpdateConvenioMutation_updateConvenio | null;
}

export interface IUpdateConvenioMutationVariables {
  id: string;
  data: ConvenioDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConvenioPlanosByProfissionalMutation
// ====================================================

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos_profissionais_profissional;
}

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos_profissionais[] | null;
}

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_profissionais_profissional;
}

export interface IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_planos[] | null;
  profissionais: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional_profissionais[] | null;
}

export interface IUpdateConvenioPlanosByProfissionalMutation {
  updateConvenioPlanosByProfissional: IUpdateConvenioPlanosByProfissionalMutation_updateConvenioPlanosByProfissional | null;
}

export interface IUpdateConvenioPlanosByProfissionalMutationVariables {
  profissionalId: string;
  convenioId: string;
  codigoOperadora?: string | null;
  planos: UpdateConvenioPlanoByProfissionalDataInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateConveniosByProfissionalMutation
// ====================================================

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos_profissionais_profissional;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos_profissionais[] | null;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_profissionais_profissional;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_planos[] | null;
  profissionais: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes_profissionais[] | null;
}

export interface IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional {
  __typename: "ConveniosResult";
  nodes: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional_nodes[];
}

export interface IUpdateConveniosByProfissionalMutation {
  updateConveniosByProfissional: IUpdateConveniosByProfissionalMutation_updateConveniosByProfissional;
}

export interface IUpdateConveniosByProfissionalMutationVariables {
  profissionalId: string;
  convenios: UpdateConvenioByProfissionalDataInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConvenioQuery
// ====================================================

export interface IConvenioQuery_convenio_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IConvenioQuery_convenio_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IConvenioQuery_convenio_planos_profissionais_profissional;
}

export interface IConvenioQuery_convenio_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IConvenioQuery_convenio_planos_profissionais[] | null;
}

export interface IConvenioQuery_convenio_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConvenioQuery_convenio_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IConvenioQuery_convenio_profissionais_profissional;
}

export interface IConvenioQuery_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IConvenioQuery_convenio_planos[] | null;
  profissionais: IConvenioQuery_convenio_profissionais[] | null;
}

export interface IConvenioQuery {
  convenio: IConvenioQuery_convenio | null;
}

export interface IConvenioQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConveniosQuery
// ====================================================

export interface IConveniosQuery_convenios_nodes_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IConveniosQuery_convenios_nodes_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IConveniosQuery_convenios_nodes_planos_profissionais_profissional;
}

export interface IConveniosQuery_convenios_nodes_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IConveniosQuery_convenios_nodes_planos_profissionais[] | null;
}

export interface IConveniosQuery_convenios_nodes_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConveniosQuery_convenios_nodes_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IConveniosQuery_convenios_nodes_profissionais_profissional;
}

export interface IConveniosQuery_convenios_nodes {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IConveniosQuery_convenios_nodes_planos[] | null;
  profissionais: IConveniosQuery_convenios_nodes_profissionais[] | null;
}

export interface IConveniosQuery_convenios_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IConveniosQuery_convenios {
  __typename: "ConveniosResult";
  nodes: IConveniosQuery_convenios_nodes[];
  pageInfo: IConveniosQuery_convenios_pageInfo;
}

export interface IConveniosQuery {
  convenios: IConveniosQuery_convenios;
}

export interface IConveniosQueryVariables {
  where?: ConvenioWhereInput | null;
  orderBy?: ConvenioOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardAgendamentoProcedimentoPeriodoQuery
// ====================================================

export interface IDashboardAgendamentoProcedimentoPeriodoQuery_dashboardAgendamentoProcedimentoPeriodo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardAgendamentoProcedimentoPeriodoQuery {
  dashboardAgendamentoProcedimentoPeriodo: IDashboardAgendamentoProcedimentoPeriodoQuery_dashboardAgendamentoProcedimentoPeriodo[];
}

export interface IDashboardAgendamentoProcedimentoPeriodoQueryVariables {
  where: DashboardWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardAgendamentosDistribuicaoEtariaQuery
// ====================================================

export interface IDashboardAgendamentosDistribuicaoEtariaQuery_dashboardAgendamentosDistribuicaoEtaria {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardAgendamentosDistribuicaoEtariaQuery {
  dashboardAgendamentosDistribuicaoEtaria: IDashboardAgendamentosDistribuicaoEtariaQuery_dashboardAgendamentosDistribuicaoEtaria[];
}

export interface IDashboardAgendamentosDistribuicaoEtariaQueryVariables {
  where: DashboardWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardAgendamentosStatusCountQuery
// ====================================================

export interface IDashboardAgendamentosStatusCountQuery_dashboardAgendamentosStatusCount {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardAgendamentosStatusCountQuery {
  dashboardAgendamentosStatusCount: IDashboardAgendamentosStatusCountQuery_dashboardAgendamentosStatusCount[];
}

export interface IDashboardAgendamentosStatusCountQueryVariables {
  where: DashboardWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardAtendimentosCardQuery
// ====================================================

export interface IDashboardAtendimentosCardQuery_porConvenios {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardAtendimentosCardQuery_procedimentosRealizados {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardAtendimentosCardQuery_duracao {
  __typename: "DashboardAtendimentosDuracaoTotalResult";
  duracaoSegundos: number;
}

export interface IDashboardAtendimentosCardQuery {
  porConvenios: IDashboardAtendimentosCardQuery_porConvenios[];
  procedimentosRealizados: IDashboardAtendimentosCardQuery_procedimentosRealizados[];
  duracao: IDashboardAtendimentosCardQuery_duracao;
}

export interface IDashboardAtendimentosCardQueryVariables {
  profissionalId: string;
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardPacientesAniversariantesQuery
// ====================================================

export interface IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes_nodes {
  __typename: "Paciente";
  id: string;
  nome: string;
  dataNascimento: any | null;
  celular: string | null;
  telefoneCasa: string | null;
  imagem: IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes_nodes_imagem | null;
}

export interface IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes {
  __typename: "PacientesResult";
  nodes: IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes_nodes[];
}

export interface IDashboardPacientesAniversariantesQuery {
  dashboardPacientesAniversariantes: IDashboardPacientesAniversariantesQuery_dashboardPacientesAniversariantes;
}

export interface IDashboardPacientesAniversariantesQueryVariables {
  where: DashboardPacientesAniversariantesWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardPacientesCardQuery
// ====================================================

export interface IDashboardPacientesCardQuery_porSexo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPacientesCardQuery_porTipo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPacientesCardQuery {
  porSexo: IDashboardPacientesCardQuery_porSexo[];
  porTipo: IDashboardPacientesCardQuery_porTipo[];
}

export interface IDashboardPacientesCardQueryVariables {
  profissionalId: string;
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDashboardPageQuery
// ====================================================

export interface IDashboardPageQuery_atendimentosPeriodo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_atendimentosPorConvenios {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_procedimentosRealizados {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_distribuicaoEtaria {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_pacientesPorSexo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_pacientesPorTipo {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_agendamentoStatus {
  __typename: "DashboardResult";
  label: string;
  value: number;
}

export interface IDashboardPageQuery_atendimentosDuracao {
  __typename: "DashboardAtendimentosDuracaoTotalResult";
  duracaoSegundos: number;
}

export interface IDashboardPageQuery_pacientesAniversariantes_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IDashboardPageQuery_pacientesAniversariantes_nodes {
  __typename: "Paciente";
  id: string;
  nome: string;
  dataNascimento: any | null;
  celular: string | null;
  telefoneCasa: string | null;
  imagem: IDashboardPageQuery_pacientesAniversariantes_nodes_imagem | null;
}

export interface IDashboardPageQuery_pacientesAniversariantes {
  __typename: "PacientesResult";
  nodes: IDashboardPageQuery_pacientesAniversariantes_nodes[];
}

export interface IDashboardPageQuery {
  atendimentosPeriodo: IDashboardPageQuery_atendimentosPeriodo[];
  atendimentosPorConvenios: IDashboardPageQuery_atendimentosPorConvenios[];
  procedimentosRealizados: IDashboardPageQuery_procedimentosRealizados[];
  distribuicaoEtaria: IDashboardPageQuery_distribuicaoEtaria[];
  pacientesPorSexo: IDashboardPageQuery_pacientesPorSexo[];
  pacientesPorTipo: IDashboardPageQuery_pacientesPorTipo[];
  agendamentoStatus: IDashboardPageQuery_agendamentoStatus[];
  atendimentosDuracao: IDashboardPageQuery_atendimentosDuracao;
  pacientesAniversariantes: IDashboardPageQuery_pacientesAniversariantes;
}

export interface IDashboardPageQueryVariables {
  profissionalId: string;
  dataInicial: any;
  dataFinal: any;
  dia: number;
  mes: number;
  mesTodo: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICategoriaFinanceiraQuery
// ====================================================

export interface ICategoriaFinanceiraQuery_categoriaFinanceira_parent {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriaFinanceiraQuery_categoriaFinanceira_children {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriaFinanceiraQuery_categoriaFinanceira {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
  tipo: TransacaoTipo;
  parent: ICategoriaFinanceiraQuery_categoriaFinanceira_parent | null;
  children: ICategoriaFinanceiraQuery_categoriaFinanceira_children[] | null;
}

export interface ICategoriaFinanceiraQuery {
  categoriaFinanceira: ICategoriaFinanceiraQuery_categoriaFinanceira | null;
}

export interface ICategoriaFinanceiraQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICategoriasFinanceirasQuery
// ====================================================

export interface ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes_parent {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes_children {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
  tipo: TransacaoTipo;
  parent: ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes_parent | null;
  children: ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes_children[] | null;
}

export interface ICategoriasFinanceirasQuery_categoriasFinanceiras_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ICategoriasFinanceirasQuery_categoriasFinanceiras {
  __typename: "CategoriasFinanceirasResult";
  nodes: ICategoriasFinanceirasQuery_categoriasFinanceiras_nodes[];
  pageInfo: ICategoriasFinanceirasQuery_categoriasFinanceiras_pageInfo;
}

export interface ICategoriasFinanceirasQuery {
  categoriasFinanceiras: ICategoriasFinanceirasQuery_categoriasFinanceiras;
}

export interface ICategoriasFinanceirasQueryVariables {
  where?: CategoriaFinanceiraWhereInput | null;
  orderBy?: CategoriaFinanceiraOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateCategoriaFinanceiraMutation
// ====================================================

export interface ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira_parent {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira_children {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
  tipo: TransacaoTipo;
  parent: ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira_parent | null;
  children: ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira_children[] | null;
}

export interface ICreateCategoriaFinanceiraMutation {
  createCategoriaFinanceira: ICreateCategoriaFinanceiraMutation_createCategoriaFinanceira;
}

export interface ICreateCategoriaFinanceiraMutationVariables {
  data: CategoriaFinanceiraDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteCategoriaFinanceiraMutation
// ====================================================

export interface IDeleteCategoriaFinanceiraMutation_deleteCategoriaFinanceira {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteCategoriaFinanceiraMutation {
  deleteCategoriaFinanceira: IDeleteCategoriaFinanceiraMutation_deleteCategoriaFinanceira;
}

export interface IDeleteCategoriaFinanceiraMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyCategoriasFinanceirasMutation
// ====================================================

export interface IDeleteManyCategoriasFinanceirasMutation_deleteManyCategoriasFinanceiras {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyCategoriasFinanceirasMutation {
  deleteManyCategoriasFinanceiras: IDeleteManyCategoriasFinanceirasMutation_deleteManyCategoriasFinanceiras;
}

export interface IDeleteManyCategoriasFinanceirasMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateCategoriaFinanceiraMutation
// ====================================================

export interface IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira_parent {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira_children {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
  tipo: TransacaoTipo;
  parent: IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira_parent | null;
  children: IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira_children[] | null;
}

export interface IUpdateCategoriaFinanceiraMutation {
  updateCategoriaFinanceira: IUpdateCategoriaFinanceiraMutation_updateCategoriaFinanceira | null;
}

export interface IUpdateCategoriaFinanceiraMutationVariables {
  id: string;
  data: CategoriaFinanceiraDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICentroCustoQuery
// ====================================================

export interface ICentroCustoQuery_centroCusto {
  __typename: "CentroCusto";
  id: string;
  nome: string;
}

export interface ICentroCustoQuery {
  centroCusto: ICentroCustoQuery_centroCusto | null;
}

export interface ICentroCustoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICentrosCustosQuery
// ====================================================

export interface ICentrosCustosQuery_centrosCustos_nodes {
  __typename: "CentroCusto";
  id: string;
  nome: string;
}

export interface ICentrosCustosQuery_centrosCustos_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ICentrosCustosQuery_centrosCustos {
  __typename: "CentrosCustosResult";
  nodes: ICentrosCustosQuery_centrosCustos_nodes[];
  pageInfo: ICentrosCustosQuery_centrosCustos_pageInfo;
}

export interface ICentrosCustosQuery {
  centrosCustos: ICentrosCustosQuery_centrosCustos;
}

export interface ICentrosCustosQueryVariables {
  where?: CentroCustoWhereInput | null;
  orderBy?: CentroCustoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateCentroCustoMutation
// ====================================================

export interface ICreateCentroCustoMutation_createCentroCusto {
  __typename: "CentroCusto";
  id: string;
  nome: string;
}

export interface ICreateCentroCustoMutation {
  createCentroCusto: ICreateCentroCustoMutation_createCentroCusto;
}

export interface ICreateCentroCustoMutationVariables {
  data: CentroCustoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteCentroCustoMutation
// ====================================================

export interface IDeleteCentroCustoMutation_deleteCentroCusto {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteCentroCustoMutation {
  deleteCentroCusto: IDeleteCentroCustoMutation_deleteCentroCusto;
}

export interface IDeleteCentroCustoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyCentrosCustosMutation
// ====================================================

export interface IDeleteManyCentrosCustosMutation_deleteManyCentrosCustos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyCentrosCustosMutation {
  deleteManyCentrosCustos: IDeleteManyCentrosCustosMutation_deleteManyCentrosCustos;
}

export interface IDeleteManyCentrosCustosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateCentroCustoMutation
// ====================================================

export interface IUpdateCentroCustoMutation_updateCentroCusto {
  __typename: "CentroCusto";
  id: string;
  nome: string;
}

export interface IUpdateCentroCustoMutation {
  updateCentroCusto: IUpdateCentroCustoMutation_updateCentroCusto | null;
}

export interface IUpdateCentroCustoMutationVariables {
  id: string;
  data: CentroCustoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IContaFinanceiraQuery
// ====================================================

export interface IContaFinanceiraQuery_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface IContaFinanceiraQuery {
  contaFinanceira: IContaFinanceiraQuery_contaFinanceira | null;
}

export interface IContaFinanceiraQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IContasFinanceirasQuery
// ====================================================

export interface IContasFinanceirasQuery_contasFinanceiras_nodes {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface IContasFinanceirasQuery_contasFinanceiras_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IContasFinanceirasQuery_contasFinanceiras {
  __typename: "ContasFinanceirasResult";
  nodes: IContasFinanceirasQuery_contasFinanceiras_nodes[];
  pageInfo: IContasFinanceirasQuery_contasFinanceiras_pageInfo;
}

export interface IContasFinanceirasQuery {
  contasFinanceiras: IContasFinanceirasQuery_contasFinanceiras;
}

export interface IContasFinanceirasQueryVariables {
  where?: ContaFinanceiraWhereInput | null;
  orderBy?: ContaFinanceiraOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateContaFinanceiraMutation
// ====================================================

export interface ICreateContaFinanceiraMutation_createContaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface ICreateContaFinanceiraMutation {
  createContaFinanceira: ICreateContaFinanceiraMutation_createContaFinanceira;
}

export interface ICreateContaFinanceiraMutationVariables {
  data: ContaFinanceiraDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteContaFinanceiraMutation
// ====================================================

export interface IDeleteContaFinanceiraMutation_deleteContaFinanceira {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteContaFinanceiraMutation {
  deleteContaFinanceira: IDeleteContaFinanceiraMutation_deleteContaFinanceira;
}

export interface IDeleteContaFinanceiraMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyContasFinanceirasMutation
// ====================================================

export interface IDeleteManyContasFinanceirasMutation_deleteManyContasFinanceiras {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyContasFinanceirasMutation {
  deleteManyContasFinanceiras: IDeleteManyContasFinanceirasMutation_deleteManyContasFinanceiras;
}

export interface IDeleteManyContasFinanceirasMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateContaFinanceiraMutation
// ====================================================

export interface IUpdateContaFinanceiraMutation_updateContaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface IUpdateContaFinanceiraMutation {
  updateContaFinanceira: IUpdateContaFinanceiraMutation_updateContaFinanceira | null;
}

export interface IUpdateContaFinanceiraMutationVariables {
  id: string;
  data: ContaFinanceiraDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IContasFinanceirasSaldoQuery
// ====================================================

export interface IContasFinanceirasSaldoQuery_contasFinanceirasSaldo_nodes {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface IContasFinanceirasSaldoQuery_contasFinanceirasSaldo {
  __typename: "ContasFinanceirasResult";
  nodes: IContasFinanceirasSaldoQuery_contasFinanceirasSaldo_nodes[];
}

export interface IContasFinanceirasSaldoQuery {
  contasFinanceirasSaldo: IContasFinanceirasSaldoQuery_contasFinanceirasSaldo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IDespesasPendentesQuery
// ====================================================

export interface IDespesasPendentesQuery_despesasPendentes_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IDespesasPendentesQuery_despesasPendentes_nodes_agendamento_procedimentos_procedimento;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IDespesasPendentesQuery_despesasPendentes_nodes_agendamento_procedimentos[] | null;
}

export interface IDespesasPendentesQuery_despesasPendentes_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IDespesasPendentesQuery_despesasPendentes_nodes_contaFinanceira;
  categoria: IDespesasPendentesQuery_despesasPendentes_nodes_categoria | null;
  profissional: IDespesasPendentesQuery_despesasPendentes_nodes_profissional | null;
  procedimento: IDespesasPendentesQuery_despesasPendentes_nodes_procedimento | null;
  paciente: IDespesasPendentesQuery_despesasPendentes_nodes_paciente | null;
  convenio: IDespesasPendentesQuery_despesasPendentes_nodes_convenio | null;
  centroCusto: IDespesasPendentesQuery_despesasPendentes_nodes_centroCusto | null;
  recorrencia: IDespesasPendentesQuery_despesasPendentes_nodes_recorrencia | null;
  agendamento: IDespesasPendentesQuery_despesasPendentes_nodes_agendamento | null;
}

export interface IDespesasPendentesQuery_despesasPendentes {
  __typename: "TransacoesResult";
  nodes: IDespesasPendentesQuery_despesasPendentes_nodes[];
}

export interface IDespesasPendentesQuery {
  despesasPendentes: IDespesasPendentesQuery_despesasPendentes;
}

export interface IDespesasPendentesQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IFinancasDashboardPageQuery
// ====================================================

export interface IFinancasDashboardPageQuery_contasFinanceirasSaldo_nodes {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface IFinancasDashboardPageQuery_contasFinanceirasSaldo {
  __typename: "ContasFinanceirasResult";
  nodes: IFinancasDashboardPageQuery_contasFinanceirasSaldo_nodes[];
}

export interface IFinancasDashboardPageQuery_transacoesPeriodo {
  __typename: "FinancasDashboardTransacaoPeriodo";
  data: any;
  valor: number;
  tipo: TransacaoTipo;
}

export interface IFinancasDashboardPageQuery_receitasPorConvenios {
  __typename: "FinancasDashboardResult";
  label: string;
  value: number;
}

export interface IFinancasDashboardPageQuery_receitasPorProcedimentos {
  __typename: "FinancasDashboardResult";
  label: string;
  value: number;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento_procedimentos_procedimento;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento_procedimentos[] | null;
}

export interface IFinancasDashboardPageQuery_despesasPendentes_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IFinancasDashboardPageQuery_despesasPendentes_nodes_contaFinanceira;
  categoria: IFinancasDashboardPageQuery_despesasPendentes_nodes_categoria | null;
  profissional: IFinancasDashboardPageQuery_despesasPendentes_nodes_profissional | null;
  procedimento: IFinancasDashboardPageQuery_despesasPendentes_nodes_procedimento | null;
  paciente: IFinancasDashboardPageQuery_despesasPendentes_nodes_paciente | null;
  convenio: IFinancasDashboardPageQuery_despesasPendentes_nodes_convenio | null;
  centroCusto: IFinancasDashboardPageQuery_despesasPendentes_nodes_centroCusto | null;
  recorrencia: IFinancasDashboardPageQuery_despesasPendentes_nodes_recorrencia | null;
  agendamento: IFinancasDashboardPageQuery_despesasPendentes_nodes_agendamento | null;
}

export interface IFinancasDashboardPageQuery_despesasPendentes {
  __typename: "TransacoesResult";
  nodes: IFinancasDashboardPageQuery_despesasPendentes_nodes[];
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento_procedimentos_procedimento;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento_procedimentos[] | null;
}

export interface IFinancasDashboardPageQuery_receitasPendentes_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IFinancasDashboardPageQuery_receitasPendentes_nodes_contaFinanceira;
  categoria: IFinancasDashboardPageQuery_receitasPendentes_nodes_categoria | null;
  profissional: IFinancasDashboardPageQuery_receitasPendentes_nodes_profissional | null;
  procedimento: IFinancasDashboardPageQuery_receitasPendentes_nodes_procedimento | null;
  paciente: IFinancasDashboardPageQuery_receitasPendentes_nodes_paciente | null;
  convenio: IFinancasDashboardPageQuery_receitasPendentes_nodes_convenio | null;
  centroCusto: IFinancasDashboardPageQuery_receitasPendentes_nodes_centroCusto | null;
  recorrencia: IFinancasDashboardPageQuery_receitasPendentes_nodes_recorrencia | null;
  agendamento: IFinancasDashboardPageQuery_receitasPendentes_nodes_agendamento | null;
}

export interface IFinancasDashboardPageQuery_receitasPendentes {
  __typename: "TransacoesResult";
  nodes: IFinancasDashboardPageQuery_receitasPendentes_nodes[];
}

export interface IFinancasDashboardPageQuery {
  contasFinanceirasSaldo: IFinancasDashboardPageQuery_contasFinanceirasSaldo;
  transacoesPeriodo: IFinancasDashboardPageQuery_transacoesPeriodo[];
  receitasPorConvenios: IFinancasDashboardPageQuery_receitasPorConvenios[];
  receitasPorProcedimentos: IFinancasDashboardPageQuery_receitasPorProcedimentos[];
  despesasPendentes: IFinancasDashboardPageQuery_despesasPendentes;
  receitasPendentes: IFinancasDashboardPageQuery_receitasPendentes;
}

export interface IFinancasDashboardPageQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IReceitasPendentesQuery
// ====================================================

export interface IReceitasPendentesQuery_receitasPendentes_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IReceitasPendentesQuery_receitasPendentes_nodes_agendamento_procedimentos_procedimento;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IReceitasPendentesQuery_receitasPendentes_nodes_agendamento_procedimentos[] | null;
}

export interface IReceitasPendentesQuery_receitasPendentes_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IReceitasPendentesQuery_receitasPendentes_nodes_contaFinanceira;
  categoria: IReceitasPendentesQuery_receitasPendentes_nodes_categoria | null;
  profissional: IReceitasPendentesQuery_receitasPendentes_nodes_profissional | null;
  procedimento: IReceitasPendentesQuery_receitasPendentes_nodes_procedimento | null;
  paciente: IReceitasPendentesQuery_receitasPendentes_nodes_paciente | null;
  convenio: IReceitasPendentesQuery_receitasPendentes_nodes_convenio | null;
  centroCusto: IReceitasPendentesQuery_receitasPendentes_nodes_centroCusto | null;
  recorrencia: IReceitasPendentesQuery_receitasPendentes_nodes_recorrencia | null;
  agendamento: IReceitasPendentesQuery_receitasPendentes_nodes_agendamento | null;
}

export interface IReceitasPendentesQuery_receitasPendentes {
  __typename: "TransacoesResult";
  nodes: IReceitasPendentesQuery_receitasPendentes_nodes[];
}

export interface IReceitasPendentesQuery {
  receitasPendentes: IReceitasPendentesQuery_receitasPendentes;
}

export interface IReceitasPendentesQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IReceitasPorConveniosQuery
// ====================================================

export interface IReceitasPorConveniosQuery_receitasPorConvenios {
  __typename: "FinancasDashboardResult";
  label: string;
  value: number;
}

export interface IReceitasPorConveniosQuery {
  receitasPorConvenios: IReceitasPorConveniosQuery_receitasPorConvenios[];
}

export interface IReceitasPorConveniosQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IReceitasPorProcedimentosQuery
// ====================================================

export interface IReceitasPorProcedimentosQuery_receitasPorProcedimentos {
  __typename: "FinancasDashboardResult";
  label: string;
  value: number;
}

export interface IReceitasPorProcedimentosQuery {
  receitasPorProcedimentos: IReceitasPorProcedimentosQuery_receitasPorProcedimentos[];
}

export interface IReceitasPorProcedimentosQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPeriodoQuery
// ====================================================

export interface ITransacoesPeriodoQuery_transacoesPeriodo {
  __typename: "FinancasDashboardTransacaoPeriodo";
  data: any;
  valor: number;
  tipo: TransacaoTipo;
}

export interface ITransacoesPeriodoQuery {
  transacoesPeriodo: ITransacoesPeriodoQuery_transacoesPeriodo[];
}

export interface ITransacoesPeriodoQueryVariables {
  dataInicial: any;
  dataFinal: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IFluxoCaixaQuery
// ====================================================

export interface IFluxoCaixaQuery_fluxoCaixa {
  __typename: "FluxoCaixa";
  data: any;
  tipo: TransacaoTipo;
  categoriaId: string | null;
  categoria: string;
  total: number;
}

export interface IFluxoCaixaQuery {
  fluxoCaixa: IFluxoCaixaQuery_fluxoCaixa[];
}

export interface IFluxoCaixaQueryVariables {
  where: FluxoCaixaWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorCategoriaQuery
// ====================================================

export interface ITransacoesPorCategoriaQuery_transacoesPorCategoria {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorCategoriaQuery {
  transacoesPorCategoria: ITransacoesPorCategoriaQuery_transacoesPorCategoria[];
}

export interface ITransacoesPorCategoriaQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorCentroCustoQuery
// ====================================================

export interface ITransacoesPorCentroCustoQuery_transacoesPorCentroCusto {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorCentroCustoQuery {
  transacoesPorCentroCusto: ITransacoesPorCentroCustoQuery_transacoesPorCentroCusto[];
}

export interface ITransacoesPorCentroCustoQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorContaFinanceiraQuery
// ====================================================

export interface ITransacoesPorContaFinanceiraQuery_transacoesPorContaFinanceira {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorContaFinanceiraQuery {
  transacoesPorContaFinanceira: ITransacoesPorContaFinanceiraQuery_transacoesPorContaFinanceira[];
}

export interface ITransacoesPorContaFinanceiraQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorConvenioQuery
// ====================================================

export interface ITransacoesPorConvenioQuery_transacoesPorConvenio {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorConvenioQuery {
  transacoesPorConvenio: ITransacoesPorConvenioQuery_transacoesPorConvenio[];
}

export interface ITransacoesPorConvenioQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorPacienteQuery
// ====================================================

export interface ITransacoesPorPacienteQuery_transacoesPorPaciente {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorPacienteQuery {
  transacoesPorPaciente: ITransacoesPorPacienteQuery_transacoesPorPaciente[];
}

export interface ITransacoesPorPacienteQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorProcedimentoQuery
// ====================================================

export interface ITransacoesPorProcedimentoQuery_transacoesPorProcedimento {
  __typename: "TransacoesPorProcedimentoResult";
  procedimento: string | null;
  profissional: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorProcedimentoQuery {
  transacoesPorProcedimento: ITransacoesPorProcedimentoQuery_transacoesPorProcedimento[];
}

export interface ITransacoesPorProcedimentoQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesPorProfissionalQuery
// ====================================================

export interface ITransacoesPorProfissionalQuery_transacoesPorProfissional {
  __typename: "FinancasRelatorioResult";
  label: string | null;
  value: number;
  percentage: number;
}

export interface ITransacoesPorProfissionalQuery {
  transacoesPorProfissional: ITransacoesPorProfissionalQuery_transacoesPorProfissional[];
}

export interface ITransacoesPorProfissionalQueryVariables {
  where: FinancasRelatorioWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTransacaoMutation
// ====================================================

export interface ICreateTransacaoMutation_createTransacao_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateTransacaoMutation_createTransacao_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICreateTransacaoMutation_createTransacao_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateTransacaoMutation_createTransacao_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransacaoMutation_createTransacao_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreateTransacaoMutation_createTransacao_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTransacaoMutation_createTransacao_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ICreateTransacaoMutation_createTransacao_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateTransacaoMutation_createTransacao_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransacaoMutation_createTransacao_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateTransacaoMutation_createTransacao_agendamento_procedimentos_procedimento;
}

export interface ICreateTransacaoMutation_createTransacao_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ICreateTransacaoMutation_createTransacao_agendamento_procedimentos[] | null;
}

export interface ICreateTransacaoMutation_createTransacao {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ICreateTransacaoMutation_createTransacao_contaFinanceira;
  categoria: ICreateTransacaoMutation_createTransacao_categoria | null;
  profissional: ICreateTransacaoMutation_createTransacao_profissional | null;
  procedimento: ICreateTransacaoMutation_createTransacao_procedimento | null;
  paciente: ICreateTransacaoMutation_createTransacao_paciente | null;
  convenio: ICreateTransacaoMutation_createTransacao_convenio | null;
  centroCusto: ICreateTransacaoMutation_createTransacao_centroCusto | null;
  recorrencia: ICreateTransacaoMutation_createTransacao_recorrencia | null;
  agendamento: ICreateTransacaoMutation_createTransacao_agendamento | null;
}

export interface ICreateTransacaoMutation {
  createTransacao: ICreateTransacaoMutation_createTransacao;
}

export interface ICreateTransacaoMutationVariables {
  data: TransacaoDataInput;
  tipo: TransacaoTipo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTransacaoRecorrenciaMutation
// ====================================================

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento_procedimentos_procedimento;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento_procedimentos[] | null;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_contaFinanceira;
  categoria: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_categoria | null;
  profissional: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_profissional | null;
  procedimento: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_procedimento | null;
  paciente: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_paciente | null;
  convenio: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_convenio | null;
  centroCusto: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_centroCusto | null;
  recorrencia: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_recorrencia | null;
  agendamento: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes_agendamento | null;
}

export interface ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia {
  __typename: "TransacoesResult";
  nodes: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia_nodes[];
}

export interface ICreateTransacaoRecorrenciaMutation {
  createTransacaoRecorrencia: ICreateTransacaoRecorrenciaMutation_createTransacaoRecorrencia;
}

export interface ICreateTransacaoRecorrenciaMutationVariables {
  data: TransacaoDataInput;
  tipo: TransacaoTipo;
  recorrencia: TransacaoRecorrenciaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTransferenciaMutation
// ====================================================

export interface ICreateTransferenciaMutation_createTransferencia_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateTransferenciaMutation_createTransferencia_nodes_agendamento_procedimentos_procedimento;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ICreateTransferenciaMutation_createTransferencia_nodes_agendamento_procedimentos[] | null;
}

export interface ICreateTransferenciaMutation_createTransferencia_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ICreateTransferenciaMutation_createTransferencia_nodes_contaFinanceira;
  categoria: ICreateTransferenciaMutation_createTransferencia_nodes_categoria | null;
  profissional: ICreateTransferenciaMutation_createTransferencia_nodes_profissional | null;
  procedimento: ICreateTransferenciaMutation_createTransferencia_nodes_procedimento | null;
  paciente: ICreateTransferenciaMutation_createTransferencia_nodes_paciente | null;
  convenio: ICreateTransferenciaMutation_createTransferencia_nodes_convenio | null;
  centroCusto: ICreateTransferenciaMutation_createTransferencia_nodes_centroCusto | null;
  recorrencia: ICreateTransferenciaMutation_createTransferencia_nodes_recorrencia | null;
  agendamento: ICreateTransferenciaMutation_createTransferencia_nodes_agendamento | null;
}

export interface ICreateTransferenciaMutation_createTransferencia {
  __typename: "TransacoesResult";
  nodes: ICreateTransferenciaMutation_createTransferencia_nodes[];
}

export interface ICreateTransferenciaMutation {
  createTransferencia: ICreateTransferenciaMutation_createTransferencia;
}

export interface ICreateTransferenciaMutationVariables {
  data: TransferenciaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyTransacoesMutation
// ====================================================

export interface IDeleteManyTransacoesMutation_deleteManyTransacoes {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyTransacoesMutation {
  deleteManyTransacoes: IDeleteManyTransacoesMutation_deleteManyTransacoes;
}

export interface IDeleteManyTransacoesMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTransacaoMutation
// ====================================================

export interface IDeleteTransacaoMutation_deleteTransacao {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTransacaoMutation {
  deleteTransacao: IDeleteTransacaoMutation_deleteTransacao;
}

export interface IDeleteTransacaoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTransacaoRecorrenciaMutation
// ====================================================

export interface IDeleteTransacaoRecorrenciaMutation_deleteTransacaoRecorrencia {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteTransacaoRecorrenciaMutation {
  deleteTransacaoRecorrencia: IDeleteTransacaoRecorrenciaMutation_deleteTransacaoRecorrencia;
}

export interface IDeleteTransacaoRecorrenciaMutationVariables {
  recorrenciaId: string;
  all: boolean;
  index?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacaoQuery
// ====================================================

export interface ITransacaoQuery_transacao_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ITransacaoQuery_transacao_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ITransacaoQuery_transacao_profissional {
  __typename: "User";
  id: string;
}

export interface ITransacaoQuery_transacao_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacaoQuery_transacao_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ITransacaoQuery_transacao_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITransacaoQuery_transacao_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ITransacaoQuery_transacao_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ITransacaoQuery_transacao_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacaoQuery_transacao_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ITransacaoQuery_transacao_agendamento_procedimentos_procedimento;
}

export interface ITransacaoQuery_transacao_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ITransacaoQuery_transacao_agendamento_procedimentos[] | null;
}

export interface ITransacaoQuery_transacao {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ITransacaoQuery_transacao_contaFinanceira;
  categoria: ITransacaoQuery_transacao_categoria | null;
  profissional: ITransacaoQuery_transacao_profissional | null;
  procedimento: ITransacaoQuery_transacao_procedimento | null;
  paciente: ITransacaoQuery_transacao_paciente | null;
  convenio: ITransacaoQuery_transacao_convenio | null;
  centroCusto: ITransacaoQuery_transacao_centroCusto | null;
  recorrencia: ITransacaoQuery_transacao_recorrencia | null;
  agendamento: ITransacaoQuery_transacao_agendamento | null;
}

export interface ITransacaoQuery {
  transacao: ITransacaoQuery_transacao | null;
}

export interface ITransacaoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesQuery
// ====================================================

export interface ITransacoesQuery_transacoes_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ITransacoesQuery_transacoes_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ITransacoesQuery_transacoes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface ITransacoesQuery_transacoes_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacoesQuery_transacoes_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ITransacoesQuery_transacoes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITransacoesQuery_transacoes_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ITransacoesQuery_transacoes_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ITransacoesQuery_transacoes_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacoesQuery_transacoes_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ITransacoesQuery_transacoes_nodes_agendamento_procedimentos_procedimento;
}

export interface ITransacoesQuery_transacoes_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ITransacoesQuery_transacoes_nodes_agendamento_procedimentos[] | null;
}

export interface ITransacoesQuery_transacoes_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ITransacoesQuery_transacoes_nodes_contaFinanceira;
  categoria: ITransacoesQuery_transacoes_nodes_categoria | null;
  profissional: ITransacoesQuery_transacoes_nodes_profissional | null;
  procedimento: ITransacoesQuery_transacoes_nodes_procedimento | null;
  paciente: ITransacoesQuery_transacoes_nodes_paciente | null;
  convenio: ITransacoesQuery_transacoes_nodes_convenio | null;
  centroCusto: ITransacoesQuery_transacoes_nodes_centroCusto | null;
  recorrencia: ITransacoesQuery_transacoes_nodes_recorrencia | null;
  agendamento: ITransacoesQuery_transacoes_nodes_agendamento | null;
}

export interface ITransacoesQuery_transacoes {
  __typename: "TransacoesResult";
  nodes: ITransacoesQuery_transacoes_nodes[];
}

export interface ITransacoesQuery {
  transacoes: ITransacoesQuery_transacoes;
}

export interface ITransacoesQueryVariables {
  where?: TransacaoWhereInput | null;
  orderBy?: TransacaoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITransacoesSummaryQuery
// ====================================================

export interface ITransacoesSummaryQuery_transacoesSummary_overall {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_despesas_notPaid {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_despesas_paid {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_despesas_total {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_despesas {
  __typename: "TransacoesSummaryTotals";
  notPaid: ITransacoesSummaryQuery_transacoesSummary_despesas_notPaid;
  paid: ITransacoesSummaryQuery_transacoesSummary_despesas_paid;
  total: ITransacoesSummaryQuery_transacoesSummary_despesas_total;
}

export interface ITransacoesSummaryQuery_transacoesSummary_receitas_notPaid {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_receitas_paid {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_receitas_total {
  __typename: "TransacoesSummaryValues";
  count: number;
  sum: number;
}

export interface ITransacoesSummaryQuery_transacoesSummary_receitas {
  __typename: "TransacoesSummaryTotals";
  notPaid: ITransacoesSummaryQuery_transacoesSummary_receitas_notPaid;
  paid: ITransacoesSummaryQuery_transacoesSummary_receitas_paid;
  total: ITransacoesSummaryQuery_transacoesSummary_receitas_total;
}

export interface ITransacoesSummaryQuery_transacoesSummary {
  __typename: "TransacoesSummary";
  overall: ITransacoesSummaryQuery_transacoesSummary_overall;
  despesas: ITransacoesSummaryQuery_transacoesSummary_despesas;
  receitas: ITransacoesSummaryQuery_transacoesSummary_receitas;
}

export interface ITransacoesSummaryQuery {
  transacoesSummary: ITransacoesSummaryQuery_transacoesSummary;
}

export interface ITransacoesSummaryQueryVariables {
  where?: TransacaoWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateManyTransacoesStatusMutation
// ====================================================

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento_procedimentos_procedimento;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento_procedimentos[] | null;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_contaFinanceira;
  categoria: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_categoria | null;
  profissional: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_profissional | null;
  procedimento: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_procedimento | null;
  paciente: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_paciente | null;
  convenio: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_convenio | null;
  centroCusto: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_centroCusto | null;
  recorrencia: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_recorrencia | null;
  agendamento: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes_agendamento | null;
}

export interface IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus {
  __typename: "TransacoesResult";
  nodes: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus_nodes[];
}

export interface IUpdateManyTransacoesStatusMutation {
  updateManyTransacoesStatus: IUpdateManyTransacoesStatusMutation_updateManyTransacoesStatus;
}

export interface IUpdateManyTransacoesStatusMutationVariables {
  ids: string[];
  pago: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTransacaoMutation
// ====================================================

export interface IUpdateTransacaoMutation_updateTransacao_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateTransacaoMutation_updateTransacao_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoMutation_updateTransacao_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateTransacaoMutation_updateTransacao_agendamento_procedimentos_procedimento;
}

export interface IUpdateTransacaoMutation_updateTransacao_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IUpdateTransacaoMutation_updateTransacao_agendamento_procedimentos[] | null;
}

export interface IUpdateTransacaoMutation_updateTransacao {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IUpdateTransacaoMutation_updateTransacao_contaFinanceira;
  categoria: IUpdateTransacaoMutation_updateTransacao_categoria | null;
  profissional: IUpdateTransacaoMutation_updateTransacao_profissional | null;
  procedimento: IUpdateTransacaoMutation_updateTransacao_procedimento | null;
  paciente: IUpdateTransacaoMutation_updateTransacao_paciente | null;
  convenio: IUpdateTransacaoMutation_updateTransacao_convenio | null;
  centroCusto: IUpdateTransacaoMutation_updateTransacao_centroCusto | null;
  recorrencia: IUpdateTransacaoMutation_updateTransacao_recorrencia | null;
  agendamento: IUpdateTransacaoMutation_updateTransacao_agendamento | null;
}

export interface IUpdateTransacaoMutation {
  updateTransacao: IUpdateTransacaoMutation_updateTransacao | null;
}

export interface IUpdateTransacaoMutationVariables {
  id: string;
  data: TransacaoDataInput;
  agendamento?: TransacaoAgendamentoDataInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTransacaoRecorrenciaMutation
// ====================================================

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento_procedimentos_procedimento;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento_procedimentos[] | null;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_contaFinanceira;
  categoria: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_categoria | null;
  profissional: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_profissional | null;
  procedimento: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_procedimento | null;
  paciente: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_paciente | null;
  convenio: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_convenio | null;
  centroCusto: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_centroCusto | null;
  recorrencia: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_recorrencia | null;
  agendamento: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes_agendamento | null;
}

export interface IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia {
  __typename: "TransacoesResult";
  nodes: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia_nodes[];
}

export interface IUpdateTransacaoRecorrenciaMutation {
  updateTransacaoRecorrencia: IUpdateTransacaoRecorrenciaMutation_updateTransacaoRecorrencia;
}

export interface IUpdateTransacaoRecorrenciaMutationVariables {
  recorrenciaId: string;
  data: TransacaoDataInput;
  index: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTransacaoStatusMutation
// ====================================================

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento_procedimentos_procedimento;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento_procedimentos[] | null;
}

export interface IUpdateTransacaoStatusMutation_updateTransacaoStatus {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IUpdateTransacaoStatusMutation_updateTransacaoStatus_contaFinanceira;
  categoria: IUpdateTransacaoStatusMutation_updateTransacaoStatus_categoria | null;
  profissional: IUpdateTransacaoStatusMutation_updateTransacaoStatus_profissional | null;
  procedimento: IUpdateTransacaoStatusMutation_updateTransacaoStatus_procedimento | null;
  paciente: IUpdateTransacaoStatusMutation_updateTransacaoStatus_paciente | null;
  convenio: IUpdateTransacaoStatusMutation_updateTransacaoStatus_convenio | null;
  centroCusto: IUpdateTransacaoStatusMutation_updateTransacaoStatus_centroCusto | null;
  recorrencia: IUpdateTransacaoStatusMutation_updateTransacaoStatus_recorrencia | null;
  agendamento: IUpdateTransacaoStatusMutation_updateTransacaoStatus_agendamento | null;
}

export interface IUpdateTransacaoStatusMutation {
  updateTransacaoStatus: IUpdateTransacaoStatusMutation_updateTransacaoStatus | null;
}

export interface IUpdateTransacaoStatusMutationVariables {
  id: string;
  pago: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICid10LookupsQuery
// ====================================================

export interface ICid10LookupsQuery_cid10Lookups_nodes {
  __typename: "Cid10Lookup";
  id: string;
  capitulo: number | null;
  categoria: string | null;
  codigo: string;
  descricao: string;
  grupo: string | null;
  tipo: Cid10Tipo | null;
}

export interface ICid10LookupsQuery_cid10Lookups_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ICid10LookupsQuery_cid10Lookups {
  __typename: "Cid10LookupsResult";
  nodes: ICid10LookupsQuery_cid10Lookups_nodes[];
  pageInfo: ICid10LookupsQuery_cid10Lookups_pageInfo;
}

export interface ICid10LookupsQuery {
  cid10Lookups: ICid10LookupsQuery_cid10Lookups;
}

export interface ICid10LookupsQueryVariables {
  where?: Cid10LookupWhereInput | null;
  orderBy?: Cid10LookupOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IConvenioLookupsQuery
// ====================================================

export interface IConvenioLookupsQuery_convenioLookups_nodes {
  __typename: "ConvenioLookup";
  id: string;
  nomeFantasia: string;
  razaoSocial: string;
  registroAns: string;
}

export interface IConvenioLookupsQuery_convenioLookups_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IConvenioLookupsQuery_convenioLookups {
  __typename: "ConvenioLookupsResult";
  nodes: IConvenioLookupsQuery_convenioLookups_nodes[];
  pageInfo: IConvenioLookupsQuery_convenioLookups_pageInfo;
}

export interface IConvenioLookupsQuery {
  convenioLookups: IConvenioLookupsQuery_convenioLookups;
}

export interface IConvenioLookupsQueryVariables {
  where?: ConvenioLookupWhereInput | null;
  orderBy?: ConvenioLookupOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITussLookupsQuery
// ====================================================

export interface ITussLookupsQuery_tussLookups_nodes {
  __typename: "TussLookup";
  id: string;
  codigo: string;
  nome: string;
  tabela: TussLookupTabela;
}

export interface ITussLookupsQuery_tussLookups_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ITussLookupsQuery_tussLookups {
  __typename: "TussLookupsResult";
  nodes: ITussLookupsQuery_tussLookups_nodes[];
  pageInfo: ITussLookupsQuery_tussLookups_pageInfo;
}

export interface ITussLookupsQuery {
  tussLookups: ITussLookupsQuery_tussLookups;
}

export interface ITussLookupsQueryVariables {
  where?: TussLookupWhereInput | null;
  orderBy?: TussLookupOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreatePacienteMutation
// ====================================================

export interface ICreatePacienteMutation_createPaciente_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface ICreatePacienteMutation_createPaciente_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreatePacienteMutation_createPaciente_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface ICreatePacienteMutation_createPaciente_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: ICreatePacienteMutation_createPaciente_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: ICreatePacienteMutation_createPaciente_convenios_plano | null;
}

export interface ICreatePacienteMutation_createPaciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreatePacienteMutation_createPaciente {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: ICreatePacienteMutation_createPaciente_parentes[] | null;
  convenios: ICreatePacienteMutation_createPaciente_convenios[] | null;
  imagem: ICreatePacienteMutation_createPaciente_imagem | null;
}

export interface ICreatePacienteMutation {
  createPaciente: ICreatePacienteMutation_createPaciente;
}

export interface ICreatePacienteMutationVariables {
  data: PacienteDataInput;
  parentes: PacienteParenteDataInput[];
  convenios: PacienteConvenioDataInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyPacientesMutation
// ====================================================

export interface IDeleteManyPacientesMutation_deleteManyPacientes {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyPacientesMutation {
  deleteManyPacientes: IDeleteManyPacientesMutation_deleteManyPacientes;
}

export interface IDeleteManyPacientesMutationVariables {
  ids: string[];
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeletePacienteMutation
// ====================================================

export interface IDeletePacienteMutation_deletePaciente {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeletePacienteMutation {
  deletePaciente: IDeletePacienteMutation_deletePaciente;
}

export interface IDeletePacienteMutationVariables {
  id: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IMergePacientesMutation
// ====================================================

export interface IMergePacientesMutation_mergePacientes_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IMergePacientesMutation_mergePacientes_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IMergePacientesMutation_mergePacientes_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IMergePacientesMutation_mergePacientes_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IMergePacientesMutation_mergePacientes_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IMergePacientesMutation_mergePacientes_convenios_plano | null;
}

export interface IMergePacientesMutation_mergePacientes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IMergePacientesMutation_mergePacientes {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IMergePacientesMutation_mergePacientes_parentes[] | null;
  convenios: IMergePacientesMutation_mergePacientes_convenios[] | null;
  imagem: IMergePacientesMutation_mergePacientes_imagem | null;
}

export interface IMergePacientesMutation {
  mergePacientes: IMergePacientesMutation_mergePacientes | null;
}

export interface IMergePacientesMutationVariables {
  keepId: string;
  mergeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPacienteQuery
// ====================================================

export interface IPacienteQuery_paciente_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IPacienteQuery_paciente_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IPacienteQuery_paciente_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IPacienteQuery_paciente_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IPacienteQuery_paciente_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IPacienteQuery_paciente_convenios_plano | null;
}

export interface IPacienteQuery_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IPacienteQuery_paciente {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IPacienteQuery_paciente_parentes[] | null;
  convenios: IPacienteQuery_paciente_convenios[] | null;
  imagem: IPacienteQuery_paciente_imagem | null;
}

export interface IPacienteQuery {
  paciente: IPacienteQuery_paciente | null;
}

export interface IPacienteQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPacientesQuery
// ====================================================

export interface IPacientesQuery_pacientes_nodes_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IPacientesQuery_pacientes_nodes_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IPacientesQuery_pacientes_nodes_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IPacientesQuery_pacientes_nodes_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IPacientesQuery_pacientes_nodes_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IPacientesQuery_pacientes_nodes_convenios_plano | null;
}

export interface IPacientesQuery_pacientes_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IPacientesQuery_pacientes_nodes {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IPacientesQuery_pacientes_nodes_parentes[] | null;
  convenios: IPacientesQuery_pacientes_nodes_convenios[] | null;
  imagem: IPacientesQuery_pacientes_nodes_imagem | null;
}

export interface IPacientesQuery_pacientes_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IPacientesQuery_pacientes {
  __typename: "PacientesResult";
  nodes: IPacientesQuery_pacientes_nodes[];
  pageInfo: IPacientesQuery_pacientes_pageInfo;
}

export interface IPacientesQuery {
  pacientes: IPacientesQuery_pacientes;
}

export interface IPacientesQueryVariables {
  where?: PacienteWhereInput | null;
  orderBy?: PacienteOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPacientesHomonimosQuery
// ====================================================

export interface IPacientesHomonimosQuery_pacientes_nodes_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IPacientesHomonimosQuery_pacientes_nodes_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IPacientesHomonimosQuery_pacientes_nodes_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IPacientesHomonimosQuery_pacientes_nodes_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IPacientesHomonimosQuery_pacientes_nodes_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IPacientesHomonimosQuery_pacientes_nodes_convenios_plano | null;
}

export interface IPacientesHomonimosQuery_pacientes_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IPacientesHomonimosQuery_pacientes_nodes {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IPacientesHomonimosQuery_pacientes_nodes_parentes[] | null;
  convenios: IPacientesHomonimosQuery_pacientes_nodes_convenios[] | null;
  imagem: IPacientesHomonimosQuery_pacientes_nodes_imagem | null;
}

export interface IPacientesHomonimosQuery_pacientes_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IPacientesHomonimosQuery_pacientes {
  __typename: "PacientesResult";
  nodes: IPacientesHomonimosQuery_pacientes_nodes[];
  pageInfo: IPacientesHomonimosQuery_pacientes_pageInfo;
}

export interface IPacientesHomonimosQuery {
  pacientes: IPacientesHomonimosQuery_pacientes;
}

export interface IPacientesHomonimosQueryVariables {
  nome: string;
  orderBy?: PacienteOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPacienteStatsQuery
// ====================================================

export interface IPacienteStatsQuery_pacienteStats {
  __typename: "PacienteStats";
  faltas: number;
  atendimentos: number;
  dataUltimoAtendimento: any | null;
}

export interface IPacienteStatsQuery {
  pacienteStats: IPacienteStatsQuery_pacienteStats;
}

export interface IPacienteStatsQueryVariables {
  pacienteId: string;
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdatePacienteMutation
// ====================================================

export interface IUpdatePacienteMutation_updatePaciente_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IUpdatePacienteMutation_updatePaciente_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdatePacienteMutation_updatePaciente_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IUpdatePacienteMutation_updatePaciente_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IUpdatePacienteMutation_updatePaciente_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IUpdatePacienteMutation_updatePaciente_convenios_plano | null;
}

export interface IUpdatePacienteMutation_updatePaciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdatePacienteMutation_updatePaciente {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IUpdatePacienteMutation_updatePaciente_parentes[] | null;
  convenios: IUpdatePacienteMutation_updatePaciente_convenios[] | null;
  imagem: IUpdatePacienteMutation_updatePaciente_imagem | null;
}

export interface IUpdatePacienteMutation {
  updatePaciente: IUpdatePacienteMutation_updatePaciente | null;
}

export interface IUpdatePacienteMutationVariables {
  id: string;
  data: PacienteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreatePacienteConvenioMutation
// ====================================================

export interface ICreatePacienteConvenioMutation_createPacienteConvenio_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreatePacienteConvenioMutation_createPacienteConvenio_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreatePacienteConvenioMutation_createPacienteConvenio_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface ICreatePacienteConvenioMutation_createPacienteConvenio {
  __typename: "PacienteConvenio";
  id: string;
  vencimento: any | null;
  semVencimento: boolean | null;
  numeroCarteirinha: string;
  acomodacao: string | null;
  paciente: ICreatePacienteConvenioMutation_createPacienteConvenio_paciente;
  convenio: ICreatePacienteConvenioMutation_createPacienteConvenio_convenio;
  plano: ICreatePacienteConvenioMutation_createPacienteConvenio_plano | null;
}

export interface ICreatePacienteConvenioMutation {
  createPacienteConvenio: ICreatePacienteConvenioMutation_createPacienteConvenio;
}

export interface ICreatePacienteConvenioMutationVariables {
  pacienteId: string;
  data: PacienteConvenioDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyPacienteConveniosMutation
// ====================================================

export interface IDeleteManyPacienteConveniosMutation_deleteManyPacienteConvenios {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyPacienteConveniosMutation {
  deleteManyPacienteConvenios: IDeleteManyPacienteConveniosMutation_deleteManyPacienteConvenios;
}

export interface IDeleteManyPacienteConveniosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeletePacienteConvenioMutation
// ====================================================

export interface IDeletePacienteConvenioMutation_deletePacienteConvenio {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeletePacienteConvenioMutation {
  deletePacienteConvenio: IDeletePacienteConvenioMutation_deletePacienteConvenio;
}

export interface IDeletePacienteConvenioMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdatePacienteConvenioMutation
// ====================================================

export interface IUpdatePacienteConvenioMutation_updatePacienteConvenio_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdatePacienteConvenioMutation_updatePacienteConvenio_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdatePacienteConvenioMutation_updatePacienteConvenio_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IUpdatePacienteConvenioMutation_updatePacienteConvenio {
  __typename: "PacienteConvenio";
  id: string;
  vencimento: any | null;
  semVencimento: boolean | null;
  numeroCarteirinha: string;
  acomodacao: string | null;
  paciente: IUpdatePacienteConvenioMutation_updatePacienteConvenio_paciente;
  convenio: IUpdatePacienteConvenioMutation_updatePacienteConvenio_convenio;
  plano: IUpdatePacienteConvenioMutation_updatePacienteConvenio_plano | null;
}

export interface IUpdatePacienteConvenioMutation {
  updatePacienteConvenio: IUpdatePacienteConvenioMutation_updatePacienteConvenio | null;
}

export interface IUpdatePacienteConvenioMutationVariables {
  id: string;
  data: PacienteConvenioDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreatePacienteParenteMutation
// ====================================================

export interface ICreatePacienteParenteMutation_createPacienteParente_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreatePacienteParenteMutation_createPacienteParente {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
  paciente: ICreatePacienteParenteMutation_createPacienteParente_paciente;
}

export interface ICreatePacienteParenteMutation {
  createPacienteParente: ICreatePacienteParenteMutation_createPacienteParente;
}

export interface ICreatePacienteParenteMutationVariables {
  pacienteId: string;
  data: PacienteParenteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyPacienteParentesMutation
// ====================================================

export interface IDeleteManyPacienteParentesMutation_deleteManyPacienteParentes {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyPacienteParentesMutation {
  deleteManyPacienteParentes: IDeleteManyPacienteParentesMutation_deleteManyPacienteParentes;
}

export interface IDeleteManyPacienteParentesMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeletePacienteParenteMutation
// ====================================================

export interface IDeletePacienteParenteMutation_deletePacienteParente {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeletePacienteParenteMutation {
  deletePacienteParente: IDeletePacienteParenteMutation_deletePacienteParente;
}

export interface IDeletePacienteParenteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdatePacienteParenteMutation
// ====================================================

export interface IUpdatePacienteParenteMutation_updatePacienteParente_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdatePacienteParenteMutation_updatePacienteParente {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
  paciente: IUpdatePacienteParenteMutation_updatePacienteParente_paciente;
}

export interface IUpdatePacienteParenteMutation {
  updatePacienteParente: IUpdatePacienteParenteMutation_updatePacienteParente | null;
}

export interface IUpdatePacienteParenteMutationVariables {
  id: string;
  data: PacienteParenteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAgendamentoQuery
// ====================================================

export interface IAgendamentoQuery_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IAgendamentoQuery_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IAgendamentoQuery_agendamento_procedimentos_procedimento;
}

export interface IAgendamentoQuery_agendamento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAgendamentoQuery_agendamento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IAgendamentoQuery_agendamento_paciente_imagem | null;
}

export interface IAgendamentoQuery_agendamento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IAgendamentoQuery_agendamento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentoQuery_agendamento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentoQuery_agendamento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentoQuery_agendamento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentoQuery_agendamento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IAgendamentoQuery_agendamento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IAgendamentoQuery_agendamento_transacoes_recorrencia | null;
  contaFinanceira: IAgendamentoQuery_agendamento_transacoes_contaFinanceira;
}

export interface IAgendamentoQuery_agendamento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IAgendamentoQuery_agendamento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IAgendamentoQuery_agendamento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IAgendamentoQuery_agendamento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IAgendamentoQuery_agendamento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IAgendamentoQuery_agendamento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IAgendamentoQuery_agendamento_procedimentos[] | null;
  paciente: IAgendamentoQuery_agendamento_paciente | null;
  convenio: IAgendamentoQuery_agendamento_convenio | null;
  profissional: IAgendamentoQuery_agendamento_profissional;
  createdBy: IAgendamentoQuery_agendamento_createdBy | null;
  recorrencia: IAgendamentoQuery_agendamento_recorrencia | null;
  transacoes: IAgendamentoQuery_agendamento_transacoes[] | null;
  atendimento: IAgendamentoQuery_agendamento_atendimento | null;
  tissGuiaConsulta: IAgendamentoQuery_agendamento_tissGuiaConsulta | null;
  tissGuiaHonorarios: IAgendamentoQuery_agendamento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IAgendamentoQuery_agendamento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IAgendamentoQuery_agendamento_tissGuiaSpSadt | null;
}

export interface IAgendamentoQuery {
  agendamento: IAgendamentoQuery_agendamento | null;
}

export interface IAgendamentoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAgendamentoProximoHorarioQuery
// ====================================================

export interface IAgendamentoProximoHorarioQuery_agendamentoProximoHorario {
  __typename: "AgendamentoProximoHorario";
  data: any;
  horaInicial: any;
  horaFinal: any;
}

export interface IAgendamentoProximoHorarioQuery {
  agendamentoProximoHorario: IAgendamentoProximoHorarioQuery_agendamentoProximoHorario;
}

export interface IAgendamentoProximoHorarioQueryVariables {
  input: AgendamentoProximoHorarioInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAgendamentosQuery
// ====================================================

export interface IAgendamentosQuery_agendamentos_nodes_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IAgendamentosQuery_agendamentos_nodes_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IAgendamentosQuery_agendamentos_nodes_procedimentos_procedimento;
}

export interface IAgendamentosQuery_agendamentos_nodes_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAgendamentosQuery_agendamentos_nodes_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IAgendamentosQuery_agendamentos_nodes_paciente_imagem | null;
}

export interface IAgendamentosQuery_agendamentos_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentosQuery_agendamentos_nodes_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentosQuery_agendamentos_nodes_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IAgendamentosQuery_agendamentos_nodes_transacoes_recorrencia | null;
  contaFinanceira: IAgendamentosQuery_agendamentos_nodes_transacoes_contaFinanceira;
}

export interface IAgendamentosQuery_agendamentos_nodes_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IAgendamentosQuery_agendamentos_nodes {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IAgendamentosQuery_agendamentos_nodes_procedimentos[] | null;
  paciente: IAgendamentosQuery_agendamentos_nodes_paciente | null;
  convenio: IAgendamentosQuery_agendamentos_nodes_convenio | null;
  profissional: IAgendamentosQuery_agendamentos_nodes_profissional;
  createdBy: IAgendamentosQuery_agendamentos_nodes_createdBy | null;
  recorrencia: IAgendamentosQuery_agendamentos_nodes_recorrencia | null;
  transacoes: IAgendamentosQuery_agendamentos_nodes_transacoes[] | null;
  atendimento: IAgendamentosQuery_agendamentos_nodes_atendimento | null;
  tissGuiaConsulta: IAgendamentosQuery_agendamentos_nodes_tissGuiaConsulta | null;
  tissGuiaHonorarios: IAgendamentosQuery_agendamentos_nodes_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IAgendamentosQuery_agendamentos_nodes_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IAgendamentosQuery_agendamentos_nodes_tissGuiaSpSadt | null;
}

export interface IAgendamentosQuery_agendamentos_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IAgendamentosQuery_agendamentos {
  __typename: "AgendamentosResult";
  nodes: IAgendamentosQuery_agendamentos_nodes[];
  pageInfo: IAgendamentosQuery_agendamentos_pageInfo;
}

export interface IAgendamentosQuery {
  agendamentos: IAgendamentosQuery_agendamentos;
}

export interface IAgendamentosQueryVariables {
  profissionalId: string;
  where?: AgendamentoWhereInput | null;
  orderBy?: AgendamentoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IConfirmacaoCancelAgendamentoMutation
// ====================================================

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_procedimentos_procedimento;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_paciente_imagem | null;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes_recorrencia | null;
  contaFinanceira: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes_contaFinanceira;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_procedimentos[] | null;
  paciente: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_paciente | null;
  convenio: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_convenio | null;
  profissional: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_profissional;
  createdBy: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_createdBy | null;
  recorrencia: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_recorrencia | null;
  transacoes: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_transacoes[] | null;
  atendimento: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_atendimento | null;
  tissGuiaConsulta: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaConsulta | null;
  tissGuiaHonorarios: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento_tissGuiaSpSadt | null;
}

export interface IConfirmacaoCancelAgendamentoMutation {
  confirmacaoCancelAgendamento: IConfirmacaoCancelAgendamentoMutation_confirmacaoCancelAgendamento | null;
}

export interface IConfirmacaoCancelAgendamentoMutationVariables {
  confirmacaoToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IConfirmacaoCheckAgendamentoMutation
// ====================================================

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_procedimentos_procedimento;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_paciente_imagem | null;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes_recorrencia | null;
  contaFinanceira: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes_contaFinanceira;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_procedimentos[] | null;
  paciente: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_paciente | null;
  convenio: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_convenio | null;
  profissional: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_profissional;
  createdBy: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_createdBy | null;
  recorrencia: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_recorrencia | null;
  transacoes: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_transacoes[] | null;
  atendimento: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_atendimento | null;
  tissGuiaConsulta: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaConsulta | null;
  tissGuiaHonorarios: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento_tissGuiaSpSadt | null;
}

export interface IConfirmacaoCheckAgendamentoMutation {
  confirmacaoCheckAgendamento: IConfirmacaoCheckAgendamentoMutation_confirmacaoCheckAgendamento | null;
}

export interface IConfirmacaoCheckAgendamentoMutationVariables {
  confirmacaoToken: string;
  confirm: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAgendamentoMutation
// ====================================================

export interface ICreateAgendamentoMutation_createAgendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface ICreateAgendamentoMutation_createAgendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateAgendamentoMutation_createAgendamento_procedimentos_procedimento;
}

export interface ICreateAgendamentoMutation_createAgendamento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateAgendamentoMutation_createAgendamento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: ICreateAgendamentoMutation_createAgendamento_paciente_imagem | null;
}

export interface ICreateAgendamentoMutation_createAgendamento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoMutation_createAgendamento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoMutation_createAgendamento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: ICreateAgendamentoMutation_createAgendamento_transacoes_recorrencia | null;
  contaFinanceira: ICreateAgendamentoMutation_createAgendamento_transacoes_contaFinanceira;
}

export interface ICreateAgendamentoMutation_createAgendamento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ICreateAgendamentoMutation_createAgendamento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: ICreateAgendamentoMutation_createAgendamento_procedimentos[] | null;
  paciente: ICreateAgendamentoMutation_createAgendamento_paciente | null;
  convenio: ICreateAgendamentoMutation_createAgendamento_convenio | null;
  profissional: ICreateAgendamentoMutation_createAgendamento_profissional;
  createdBy: ICreateAgendamentoMutation_createAgendamento_createdBy | null;
  recorrencia: ICreateAgendamentoMutation_createAgendamento_recorrencia | null;
  transacoes: ICreateAgendamentoMutation_createAgendamento_transacoes[] | null;
  atendimento: ICreateAgendamentoMutation_createAgendamento_atendimento | null;
  tissGuiaConsulta: ICreateAgendamentoMutation_createAgendamento_tissGuiaConsulta | null;
  tissGuiaHonorarios: ICreateAgendamentoMutation_createAgendamento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: ICreateAgendamentoMutation_createAgendamento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: ICreateAgendamentoMutation_createAgendamento_tissGuiaSpSadt | null;
}

export interface ICreateAgendamentoMutation {
  createAgendamento: ICreateAgendamentoMutation_createAgendamento;
}

export interface ICreateAgendamentoMutationVariables {
  profissionalId: string;
  data: AgendamentoCreateDataInput;
  listaEsperaId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAgendamentoRecebimentoMutation
// ====================================================

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_procedimentos_procedimento;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_paciente_imagem | null;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes_recorrencia | null;
  contaFinanceira: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes_contaFinanceira;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_procedimentos[] | null;
  paciente: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_paciente | null;
  convenio: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_convenio | null;
  profissional: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_profissional;
  createdBy: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_createdBy | null;
  recorrencia: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_recorrencia | null;
  transacoes: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_transacoes[] | null;
  atendimento: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_atendimento | null;
  tissGuiaConsulta: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaConsulta | null;
  tissGuiaHonorarios: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento_tissGuiaSpSadt | null;
}

export interface ICreateAgendamentoRecebimentoMutation {
  createAgendamentoRecebimento: ICreateAgendamentoRecebimentoMutation_createAgendamentoRecebimento | null;
}

export interface ICreateAgendamentoRecebimentoMutationVariables {
  agendamentoId: string;
  data: AgendamentoRecebimentoDataInput;
  parcelas?: AgendamentoRecebimentoParcelasInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAgendamentoRecorrenciaMutation
// ====================================================

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_procedimentos_procedimento;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_paciente_imagem | null;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes_recorrencia | null;
  contaFinanceira: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes_contaFinanceira;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_procedimentos[] | null;
  paciente: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_paciente | null;
  convenio: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_convenio | null;
  profissional: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_profissional;
  createdBy: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_createdBy | null;
  recorrencia: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_recorrencia | null;
  transacoes: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_transacoes[] | null;
  atendimento: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_atendimento | null;
  tissGuiaConsulta: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaConsulta | null;
  tissGuiaHonorarios: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaHonorarios | null;
  tissGuiaOdontologica: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaOdontologica | null;
  tissGuiaSpSadt: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes_tissGuiaSpSadt | null;
}

export interface ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia {
  __typename: "AgendamentosResult";
  nodes: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia_nodes[];
}

export interface ICreateAgendamentoRecorrenciaMutation {
  createAgendamentoRecorrencia: ICreateAgendamentoRecorrenciaMutation_createAgendamentoRecorrencia;
}

export interface ICreateAgendamentoRecorrenciaMutationVariables {
  profissionalId: string;
  data: AgendamentoCreateDataInput;
  recorrencia: AgendamentoRecorrenciaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteAgendamentoMutation
// ====================================================

export interface IDeleteAgendamentoMutation_deleteAgendamento {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteAgendamentoMutation {
  deleteAgendamento: IDeleteAgendamentoMutation_deleteAgendamento;
}

export interface IDeleteAgendamentoMutationVariables {
  id: string;
  motivo?: AgendamentoCancelamentoMotivo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteAgendamentoRecorrenciaMutation
// ====================================================

export interface IDeleteAgendamentoRecorrenciaMutation_deleteAgendamentoRecorrencia {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteAgendamentoRecorrenciaMutation {
  deleteAgendamentoRecorrencia: IDeleteAgendamentoRecorrenciaMutation_deleteAgendamentoRecorrencia;
}

export interface IDeleteAgendamentoRecorrenciaMutationVariables {
  input: AgendamentoDeleteRecorreciaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateAgendamentoMutation
// ====================================================

export interface IUpdateAgendamentoMutation_updateAgendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateAgendamentoMutation_updateAgendamento_procedimentos_procedimento;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IUpdateAgendamentoMutation_updateAgendamento_paciente_imagem | null;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IUpdateAgendamentoMutation_updateAgendamento_transacoes_recorrencia | null;
  contaFinanceira: IUpdateAgendamentoMutation_updateAgendamento_transacoes_contaFinanceira;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IUpdateAgendamentoMutation_updateAgendamento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IUpdateAgendamentoMutation_updateAgendamento_procedimentos[] | null;
  paciente: IUpdateAgendamentoMutation_updateAgendamento_paciente | null;
  convenio: IUpdateAgendamentoMutation_updateAgendamento_convenio | null;
  profissional: IUpdateAgendamentoMutation_updateAgendamento_profissional;
  createdBy: IUpdateAgendamentoMutation_updateAgendamento_createdBy | null;
  recorrencia: IUpdateAgendamentoMutation_updateAgendamento_recorrencia | null;
  transacoes: IUpdateAgendamentoMutation_updateAgendamento_transacoes[] | null;
  atendimento: IUpdateAgendamentoMutation_updateAgendamento_atendimento | null;
  tissGuiaConsulta: IUpdateAgendamentoMutation_updateAgendamento_tissGuiaConsulta | null;
  tissGuiaHonorarios: IUpdateAgendamentoMutation_updateAgendamento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IUpdateAgendamentoMutation_updateAgendamento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IUpdateAgendamentoMutation_updateAgendamento_tissGuiaSpSadt | null;
}

export interface IUpdateAgendamentoMutation {
  updateAgendamento: IUpdateAgendamentoMutation_updateAgendamento | null;
}

export interface IUpdateAgendamentoMutationVariables {
  input: AgendamentoUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateAgendamentoRecebimentoMutation
// ====================================================

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_procedimentos_procedimento;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_paciente_imagem | null;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes_recorrencia | null;
  contaFinanceira: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes_contaFinanceira;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_procedimentos[] | null;
  paciente: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_paciente | null;
  convenio: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_convenio | null;
  profissional: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_profissional;
  createdBy: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_createdBy | null;
  recorrencia: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_recorrencia | null;
  transacoes: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_transacoes[] | null;
  atendimento: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_atendimento | null;
  tissGuiaConsulta: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaConsulta | null;
  tissGuiaHonorarios: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento_tissGuiaSpSadt | null;
}

export interface IUpdateAgendamentoRecebimentoMutation {
  updateAgendamentoRecebimento: IUpdateAgendamentoRecebimentoMutation_updateAgendamentoRecebimento | null;
}

export interface IUpdateAgendamentoRecebimentoMutationVariables {
  agendamentoId: string;
  data: AgendamentoRecebimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateAgendamentoRecebimentoStatusMutation
// ====================================================

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento_procedimentos_procedimento;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento_procedimentos[] | null;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_contaFinanceira;
  categoria: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_categoria | null;
  profissional: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_profissional | null;
  procedimento: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_procedimento | null;
  paciente: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_paciente | null;
  convenio: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_convenio | null;
  centroCusto: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_centroCusto | null;
  recorrencia: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_recorrencia | null;
  agendamento: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus_agendamento | null;
}

export interface IUpdateAgendamentoRecebimentoStatusMutation {
  updateAgendamentoRecebimentoStatus: IUpdateAgendamentoRecebimentoStatusMutation_updateAgendamentoRecebimentoStatus | null;
}

export interface IUpdateAgendamentoRecebimentoStatusMutationVariables {
  agendamentoId: string;
  transacaoId: string;
  pago: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateAgendamentoTimeMutation
// ====================================================

export interface IUpdateAgendamentoTimeMutation_updateAgendamento {
  __typename: "Agendamento";
  id: string;
}

export interface IUpdateAgendamentoTimeMutation {
  updateAgendamento: IUpdateAgendamentoTimeMutation_updateAgendamento | null;
}

export interface IUpdateAgendamentoTimeMutationVariables {
  id: string;
  time?: AgendamentoTimeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAtendimentoQuery
// ====================================================

export interface IAtendimentoQuery_atendimento_preAtendimento {
  __typename: "AtendimentoPreAtendimento";
  id: string;
  peso: number | null;
  altura: number | null;
  imc: number | null;
  pressaoArterialSistolica: number | null;
  pressaoArterialDiastolica: number | null;
  frequenciaCardiaca: number | null;
  frequenciaRespiratoria: number | null;
  temperatura: number | null;
  glicemiaCapilar: number | null;
  dataUltimaMenstruacao: any | null;
  observacao: string | null;
}

export interface IAtendimentoQuery_atendimento_hipoteseDiagnostica_cids {
  __typename: "AtendimentoHipoteseDiagnosticaCid";
  id: string;
  codigo: string;
  descricao: string;
}

export interface IAtendimentoQuery_atendimento_hipoteseDiagnostica {
  __typename: "AtendimentoHipoteseDiagnostica";
  id: string;
  cids: IAtendimentoQuery_atendimento_hipoteseDiagnostica_cids[];
  observacao: string | null;
}

export interface IAtendimentoQuery_atendimento_prescricoes_medicamentos {
  __typename: "AtendimentoPrescricaoMedicamento";
  id: string;
  medicamento: string;
  quantidade: string;
  posologia: string | null;
  observacao: string | null;
}

export interface IAtendimentoQuery_atendimento_prescricoes {
  __typename: "AtendimentoPrescricao";
  id: string;
  data: any;
  controleEspecial: boolean;
  medicamentos: IAtendimentoQuery_atendimento_prescricoes_medicamentos[];
}

export interface IAtendimentoQuery_atendimento_atestados {
  __typename: "AtendimentoAtestado";
  id: string;
  data: any;
  titulo: string;
  texto: string;
}

export interface IAtendimentoQuery_atendimento_exames_items {
  __typename: "AtendimentoExameItem";
  id: string;
  codigo: string;
  nome: string;
  quantidade: number;
  observacao: string | null;
}

export interface IAtendimentoQuery_atendimento_exames {
  __typename: "AtendimentoExame";
  id: string;
  data: string;
  indicacaoClinica: string | null;
  tipo: AtendimentoExameTipo;
  items: IAtendimentoQuery_atendimento_exames_items[];
}

export interface IAtendimentoQuery_atendimento_eventos_items {
  __typename: "AtendimentoEventoItem";
  id: string;
  type: AtendimentoEventoItemType;
  label: string;
  valueText: string | null;
  valueNumber: number | null;
  valueList: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
}

export interface IAtendimentoQuery_atendimento_eventos {
  __typename: "AtendimentoEvento";
  id: string;
  nome: string;
  prontuarioSecaoId: string;
  items: IAtendimentoQuery_atendimento_eventos_items[];
}

export interface IAtendimentoQuery_atendimento_observacoes {
  __typename: "AtendimentoObservao";
  id: string;
  profissionalId: string;
  profissionalNome: string;
  texto: string;
  data: string;
}

export interface IAtendimentoQuery_atendimento_files {
  __typename: "FileUpload";
  id: string;
  contentType: string;
  description: string | null;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAtendimentoQuery_atendimento_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface IAtendimentoQuery_atendimento_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IAtendimentoQuery_atendimento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAtendimentoQuery_atendimento {
  __typename: "Atendimento";
  id: string;
  dataInicial: any;
  dataFinal: any;
  preAtendimento: IAtendimentoQuery_atendimento_preAtendimento | null;
  hipoteseDiagnostica: IAtendimentoQuery_atendimento_hipoteseDiagnostica | null;
  prescricoes: IAtendimentoQuery_atendimento_prescricoes[] | null;
  atestados: IAtendimentoQuery_atendimento_atestados[] | null;
  exames: IAtendimentoQuery_atendimento_exames[] | null;
  eventos: IAtendimentoQuery_atendimento_eventos[] | null;
  observacoes: IAtendimentoQuery_atendimento_observacoes[] | null;
  files: IAtendimentoQuery_atendimento_files[];
  agendamento: IAtendimentoQuery_atendimento_agendamento | null;
  paciente: IAtendimentoQuery_atendimento_paciente;
  profissional: IAtendimentoQuery_atendimento_profissional;
}

export interface IAtendimentoQuery {
  atendimento: IAtendimentoQuery_atendimento | null;
}

export interface IAtendimentoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAtendimentoCacheQuery
// ====================================================

export interface IAtendimentoCacheQuery_atendimentoCache {
  __typename: "AtendimentoCache";
  id: string;
  dataInicial: any;
  cachePayload: any;
}

export interface IAtendimentoCacheQuery {
  atendimentoCache: IAtendimentoCacheQuery_atendimentoCache | null;
}

export interface IAtendimentoCacheQueryVariables {
  pacienteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAtendimentosQuery
// ====================================================

export interface IAtendimentosQuery_atendimentos_nodes_preAtendimento {
  __typename: "AtendimentoPreAtendimento";
  id: string;
  peso: number | null;
  altura: number | null;
  imc: number | null;
  pressaoArterialSistolica: number | null;
  pressaoArterialDiastolica: number | null;
  frequenciaCardiaca: number | null;
  frequenciaRespiratoria: number | null;
  temperatura: number | null;
  glicemiaCapilar: number | null;
  dataUltimaMenstruacao: any | null;
  observacao: string | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_hipoteseDiagnostica_cids {
  __typename: "AtendimentoHipoteseDiagnosticaCid";
  id: string;
  codigo: string;
  descricao: string;
}

export interface IAtendimentosQuery_atendimentos_nodes_hipoteseDiagnostica {
  __typename: "AtendimentoHipoteseDiagnostica";
  id: string;
  cids: IAtendimentosQuery_atendimentos_nodes_hipoteseDiagnostica_cids[];
  observacao: string | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_prescricoes_medicamentos {
  __typename: "AtendimentoPrescricaoMedicamento";
  id: string;
  medicamento: string;
  quantidade: string;
  posologia: string | null;
  observacao: string | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_prescricoes {
  __typename: "AtendimentoPrescricao";
  id: string;
  data: any;
  controleEspecial: boolean;
  medicamentos: IAtendimentosQuery_atendimentos_nodes_prescricoes_medicamentos[];
}

export interface IAtendimentosQuery_atendimentos_nodes_atestados {
  __typename: "AtendimentoAtestado";
  id: string;
  data: any;
  titulo: string;
  texto: string;
}

export interface IAtendimentosQuery_atendimentos_nodes_exames_items {
  __typename: "AtendimentoExameItem";
  id: string;
  codigo: string;
  nome: string;
  quantidade: number;
  observacao: string | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_exames {
  __typename: "AtendimentoExame";
  id: string;
  data: string;
  indicacaoClinica: string | null;
  tipo: AtendimentoExameTipo;
  items: IAtendimentosQuery_atendimentos_nodes_exames_items[];
}

export interface IAtendimentosQuery_atendimentos_nodes_eventos_items {
  __typename: "AtendimentoEventoItem";
  id: string;
  type: AtendimentoEventoItemType;
  label: string;
  valueText: string | null;
  valueNumber: number | null;
  valueList: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_eventos {
  __typename: "AtendimentoEvento";
  id: string;
  nome: string;
  prontuarioSecaoId: string;
  items: IAtendimentosQuery_atendimentos_nodes_eventos_items[];
}

export interface IAtendimentosQuery_atendimentos_nodes_observacoes {
  __typename: "AtendimentoObservao";
  id: string;
  profissionalId: string;
  profissionalNome: string;
  texto: string;
  data: string;
}

export interface IAtendimentosQuery_atendimentos_nodes_files {
  __typename: "FileUpload";
  id: string;
  contentType: string;
  description: string | null;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAtendimentosQuery_atendimentos_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface IAtendimentosQuery_atendimentos_nodes_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IAtendimentosQuery_atendimentos_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAtendimentosQuery_atendimentos_nodes {
  __typename: "Atendimento";
  id: string;
  dataInicial: any;
  dataFinal: any;
  preAtendimento: IAtendimentosQuery_atendimentos_nodes_preAtendimento | null;
  hipoteseDiagnostica: IAtendimentosQuery_atendimentos_nodes_hipoteseDiagnostica | null;
  prescricoes: IAtendimentosQuery_atendimentos_nodes_prescricoes[] | null;
  atestados: IAtendimentosQuery_atendimentos_nodes_atestados[] | null;
  exames: IAtendimentosQuery_atendimentos_nodes_exames[] | null;
  eventos: IAtendimentosQuery_atendimentos_nodes_eventos[] | null;
  observacoes: IAtendimentosQuery_atendimentos_nodes_observacoes[] | null;
  files: IAtendimentosQuery_atendimentos_nodes_files[];
  agendamento: IAtendimentosQuery_atendimentos_nodes_agendamento | null;
  paciente: IAtendimentosQuery_atendimentos_nodes_paciente;
  profissional: IAtendimentosQuery_atendimentos_nodes_profissional;
}

export interface IAtendimentosQuery_atendimentos_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IAtendimentosQuery_atendimentos {
  __typename: "AtendimentosResult";
  nodes: IAtendimentosQuery_atendimentos_nodes[];
  pageInfo: IAtendimentosQuery_atendimentos_pageInfo;
}

export interface IAtendimentosQuery {
  atendimentos: IAtendimentosQuery_atendimentos;
}

export interface IAtendimentosQueryVariables {
  pacienteId: string;
  where?: AtendimentoWhereInput | null;
  orderBy?: AtendimentoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAtendimentoMutation
// ====================================================

export interface ICreateAtendimentoMutation_createAtendimento_preAtendimento {
  __typename: "AtendimentoPreAtendimento";
  id: string;
  peso: number | null;
  altura: number | null;
  imc: number | null;
  pressaoArterialSistolica: number | null;
  pressaoArterialDiastolica: number | null;
  frequenciaCardiaca: number | null;
  frequenciaRespiratoria: number | null;
  temperatura: number | null;
  glicemiaCapilar: number | null;
  dataUltimaMenstruacao: any | null;
  observacao: string | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_hipoteseDiagnostica_cids {
  __typename: "AtendimentoHipoteseDiagnosticaCid";
  id: string;
  codigo: string;
  descricao: string;
}

export interface ICreateAtendimentoMutation_createAtendimento_hipoteseDiagnostica {
  __typename: "AtendimentoHipoteseDiagnostica";
  id: string;
  cids: ICreateAtendimentoMutation_createAtendimento_hipoteseDiagnostica_cids[];
  observacao: string | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_prescricoes_medicamentos {
  __typename: "AtendimentoPrescricaoMedicamento";
  id: string;
  medicamento: string;
  quantidade: string;
  posologia: string | null;
  observacao: string | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_prescricoes {
  __typename: "AtendimentoPrescricao";
  id: string;
  data: any;
  controleEspecial: boolean;
  medicamentos: ICreateAtendimentoMutation_createAtendimento_prescricoes_medicamentos[];
}

export interface ICreateAtendimentoMutation_createAtendimento_atestados {
  __typename: "AtendimentoAtestado";
  id: string;
  data: any;
  titulo: string;
  texto: string;
}

export interface ICreateAtendimentoMutation_createAtendimento_exames_items {
  __typename: "AtendimentoExameItem";
  id: string;
  codigo: string;
  nome: string;
  quantidade: number;
  observacao: string | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_exames {
  __typename: "AtendimentoExame";
  id: string;
  data: string;
  indicacaoClinica: string | null;
  tipo: AtendimentoExameTipo;
  items: ICreateAtendimentoMutation_createAtendimento_exames_items[];
}

export interface ICreateAtendimentoMutation_createAtendimento_eventos_items {
  __typename: "AtendimentoEventoItem";
  id: string;
  type: AtendimentoEventoItemType;
  label: string;
  valueText: string | null;
  valueNumber: number | null;
  valueList: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_eventos {
  __typename: "AtendimentoEvento";
  id: string;
  nome: string;
  prontuarioSecaoId: string;
  items: ICreateAtendimentoMutation_createAtendimento_eventos_items[];
}

export interface ICreateAtendimentoMutation_createAtendimento_observacoes {
  __typename: "AtendimentoObservao";
  id: string;
  profissionalId: string;
  profissionalNome: string;
  texto: string;
  data: string;
}

export interface ICreateAtendimentoMutation_createAtendimento_files {
  __typename: "FileUpload";
  id: string;
  contentType: string;
  description: string | null;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateAtendimentoMutation_createAtendimento_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ICreateAtendimentoMutation_createAtendimento_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreateAtendimentoMutation_createAtendimento_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAtendimentoMutation_createAtendimento {
  __typename: "Atendimento";
  id: string;
  dataInicial: any;
  dataFinal: any;
  preAtendimento: ICreateAtendimentoMutation_createAtendimento_preAtendimento | null;
  hipoteseDiagnostica: ICreateAtendimentoMutation_createAtendimento_hipoteseDiagnostica | null;
  prescricoes: ICreateAtendimentoMutation_createAtendimento_prescricoes[] | null;
  atestados: ICreateAtendimentoMutation_createAtendimento_atestados[] | null;
  exames: ICreateAtendimentoMutation_createAtendimento_exames[] | null;
  eventos: ICreateAtendimentoMutation_createAtendimento_eventos[] | null;
  observacoes: ICreateAtendimentoMutation_createAtendimento_observacoes[] | null;
  files: ICreateAtendimentoMutation_createAtendimento_files[];
  agendamento: ICreateAtendimentoMutation_createAtendimento_agendamento | null;
  paciente: ICreateAtendimentoMutation_createAtendimento_paciente;
  profissional: ICreateAtendimentoMutation_createAtendimento_profissional;
}

export interface ICreateAtendimentoMutation {
  createAtendimento: ICreateAtendimentoMutation_createAtendimento;
}

export interface ICreateAtendimentoMutationVariables {
  data: AtendimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAtendimentoObservacaoMutation
// ====================================================

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_preAtendimento {
  __typename: "AtendimentoPreAtendimento";
  id: string;
  peso: number | null;
  altura: number | null;
  imc: number | null;
  pressaoArterialSistolica: number | null;
  pressaoArterialDiastolica: number | null;
  frequenciaCardiaca: number | null;
  frequenciaRespiratoria: number | null;
  temperatura: number | null;
  glicemiaCapilar: number | null;
  dataUltimaMenstruacao: any | null;
  observacao: string | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_hipoteseDiagnostica_cids {
  __typename: "AtendimentoHipoteseDiagnosticaCid";
  id: string;
  codigo: string;
  descricao: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_hipoteseDiagnostica {
  __typename: "AtendimentoHipoteseDiagnostica";
  id: string;
  cids: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_hipoteseDiagnostica_cids[];
  observacao: string | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_prescricoes_medicamentos {
  __typename: "AtendimentoPrescricaoMedicamento";
  id: string;
  medicamento: string;
  quantidade: string;
  posologia: string | null;
  observacao: string | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_prescricoes {
  __typename: "AtendimentoPrescricao";
  id: string;
  data: any;
  controleEspecial: boolean;
  medicamentos: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_prescricoes_medicamentos[];
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_atestados {
  __typename: "AtendimentoAtestado";
  id: string;
  data: any;
  titulo: string;
  texto: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_exames_items {
  __typename: "AtendimentoExameItem";
  id: string;
  codigo: string;
  nome: string;
  quantidade: number;
  observacao: string | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_exames {
  __typename: "AtendimentoExame";
  id: string;
  data: string;
  indicacaoClinica: string | null;
  tipo: AtendimentoExameTipo;
  items: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_exames_items[];
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_eventos_items {
  __typename: "AtendimentoEventoItem";
  id: string;
  type: AtendimentoEventoItemType;
  label: string;
  valueText: string | null;
  valueNumber: number | null;
  valueList: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_eventos {
  __typename: "AtendimentoEvento";
  id: string;
  nome: string;
  prontuarioSecaoId: string;
  items: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_eventos_items[];
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_observacoes {
  __typename: "AtendimentoObservao";
  id: string;
  profissionalId: string;
  profissionalNome: string;
  texto: string;
  data: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_files {
  __typename: "FileUpload";
  id: string;
  contentType: string;
  description: string | null;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao {
  __typename: "Atendimento";
  id: string;
  dataInicial: any;
  dataFinal: any;
  preAtendimento: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_preAtendimento | null;
  hipoteseDiagnostica: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_hipoteseDiagnostica | null;
  prescricoes: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_prescricoes[] | null;
  atestados: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_atestados[] | null;
  exames: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_exames[] | null;
  eventos: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_eventos[] | null;
  observacoes: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_observacoes[] | null;
  files: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_files[];
  agendamento: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_agendamento | null;
  paciente: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_paciente;
  profissional: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao_profissional;
}

export interface ICreateAtendimentoObservacaoMutation {
  createAtendimentoObservacao: ICreateAtendimentoObservacaoMutation_createAtendimentoObservacao;
}

export interface ICreateAtendimentoObservacaoMutationVariables {
  atendimentoId: string;
  texto: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteAtendimentoCacheMutation
// ====================================================

export interface IDeleteAtendimentoCacheMutation_deleteAtendimentoCache {
  __typename: "OkPayload";
  ok: boolean;
}

export interface IDeleteAtendimentoCacheMutation {
  deleteAtendimentoCache: IDeleteAtendimentoCacheMutation_deleteAtendimentoCache;
}

export interface IDeleteAtendimentoCacheMutationVariables {
  pacienteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ISaveAtendimentoCacheMutation
// ====================================================

export interface ISaveAtendimentoCacheMutation_saveAtendimentoCache {
  __typename: "AtendimentoCache";
  id: string;
  dataInicial: any;
  cachePayload: any;
}

export interface ISaveAtendimentoCacheMutation {
  saveAtendimentoCache: ISaveAtendimentoCacheMutation_saveAtendimentoCache;
}

export interface ISaveAtendimentoCacheMutationVariables {
  data: AtendimentoCacheDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAtestadoModeloQuery
// ====================================================

export interface IAtestadoModeloQuery_atestadoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IAtestadoModeloQuery_atestadoModelo {
  __typename: "AtestadoModelo";
  id: string;
  nome: string;
  titulo: string;
  texto: string;
  profissional: IAtestadoModeloQuery_atestadoModelo_profissional;
}

export interface IAtestadoModeloQuery {
  atestadoModelo: IAtestadoModeloQuery_atestadoModelo | null;
}

export interface IAtestadoModeloQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAtestadosModelosQuery
// ====================================================

export interface IAtestadosModelosQuery_atestadosModelos_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IAtestadosModelosQuery_atestadosModelos_nodes {
  __typename: "AtestadoModelo";
  id: string;
  nome: string;
  titulo: string;
  texto: string;
  profissional: IAtestadosModelosQuery_atestadosModelos_nodes_profissional;
}

export interface IAtestadosModelosQuery_atestadosModelos {
  __typename: "AtestadosModelosResult";
  nodes: IAtestadosModelosQuery_atestadosModelos_nodes[];
}

export interface IAtestadosModelosQuery {
  atestadosModelos: IAtestadosModelosQuery_atestadosModelos;
}

export interface IAtestadosModelosQueryVariables {
  profissionalId: string;
  where?: AtestadoModeloWhereInput | null;
  orderBy?: AtestadoModeloOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateAtestadoModeloMutation
// ====================================================

export interface ICreateAtestadoModeloMutation_createAtestadoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateAtestadoModeloMutation_createAtestadoModelo {
  __typename: "AtestadoModelo";
  id: string;
  nome: string;
  titulo: string;
  texto: string;
  profissional: ICreateAtestadoModeloMutation_createAtestadoModelo_profissional;
}

export interface ICreateAtestadoModeloMutation {
  createAtestadoModelo: ICreateAtestadoModeloMutation_createAtestadoModelo;
}

export interface ICreateAtestadoModeloMutationVariables {
  profissionalId: string;
  data: AtestadoModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteAtestadoModeloMutation
// ====================================================

export interface IDeleteAtestadoModeloMutation_deleteAtestadoModelo {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteAtestadoModeloMutation {
  deleteAtestadoModelo: IDeleteAtestadoModeloMutation_deleteAtestadoModelo;
}

export interface IDeleteAtestadoModeloMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyAtestadosModelosMutation
// ====================================================

export interface IDeleteManyAtestadosModelosMutation_deleteManyAtestadosModelos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyAtestadosModelosMutation {
  deleteManyAtestadosModelos: IDeleteManyAtestadosModelosMutation_deleteManyAtestadosModelos;
}

export interface IDeleteManyAtestadosModelosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateAtestadoModeloMutation
// ====================================================

export interface IUpdateAtestadoModeloMutation_updateAtestadoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateAtestadoModeloMutation_updateAtestadoModelo {
  __typename: "AtestadoModelo";
  id: string;
  nome: string;
  titulo: string;
  texto: string;
  profissional: IUpdateAtestadoModeloMutation_updateAtestadoModelo_profissional;
}

export interface IUpdateAtestadoModeloMutation {
  updateAtestadoModelo: IUpdateAtestadoModeloMutation_updateAtestadoModelo | null;
}

export interface IUpdateAtestadoModeloMutationVariables {
  id: string;
  data: AtestadoModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateExameModeloMutation
// ====================================================

export interface ICreateExameModeloMutation_createExameModelo_items {
  __typename: "ExameModeloItem";
  id: string;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao: string | null;
  ordem: number;
}

export interface ICreateExameModeloMutation_createExameModelo_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateExameModeloMutation_createExameModelo {
  __typename: "ExameModelo";
  id: string;
  nome: string;
  items: ICreateExameModeloMutation_createExameModelo_items[] | null;
  profissional: ICreateExameModeloMutation_createExameModelo_profissional;
}

export interface ICreateExameModeloMutation {
  createExameModelo: ICreateExameModeloMutation_createExameModelo;
}

export interface ICreateExameModeloMutationVariables {
  profissionalId: string;
  data: ExameModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteExameModeloMutation
// ====================================================

export interface IDeleteExameModeloMutation_deleteExameModelo {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteExameModeloMutation {
  deleteExameModelo: IDeleteExameModeloMutation_deleteExameModelo;
}

export interface IDeleteExameModeloMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyExamesModelosMutation
// ====================================================

export interface IDeleteManyExamesModelosMutation_deleteManyExamesModelos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyExamesModelosMutation {
  deleteManyExamesModelos: IDeleteManyExamesModelosMutation_deleteManyExamesModelos;
}

export interface IDeleteManyExamesModelosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IExameModeloQuery
// ====================================================

export interface IExameModeloQuery_exameModelo_items {
  __typename: "ExameModeloItem";
  id: string;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao: string | null;
  ordem: number;
}

export interface IExameModeloQuery_exameModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IExameModeloQuery_exameModelo {
  __typename: "ExameModelo";
  id: string;
  nome: string;
  items: IExameModeloQuery_exameModelo_items[] | null;
  profissional: IExameModeloQuery_exameModelo_profissional;
}

export interface IExameModeloQuery {
  exameModelo: IExameModeloQuery_exameModelo | null;
}

export interface IExameModeloQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IExamesModelosQuery
// ====================================================

export interface IExamesModelosQuery_examesModelos_nodes_items {
  __typename: "ExameModeloItem";
  id: string;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao: string | null;
  ordem: number;
}

export interface IExamesModelosQuery_examesModelos_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IExamesModelosQuery_examesModelos_nodes {
  __typename: "ExameModelo";
  id: string;
  nome: string;
  items: IExamesModelosQuery_examesModelos_nodes_items[] | null;
  profissional: IExamesModelosQuery_examesModelos_nodes_profissional;
}

export interface IExamesModelosQuery_examesModelos {
  __typename: "ExamesModelosResult";
  nodes: IExamesModelosQuery_examesModelos_nodes[];
}

export interface IExamesModelosQuery {
  examesModelos: IExamesModelosQuery_examesModelos;
}

export interface IExamesModelosQueryVariables {
  profissionalId: string;
  where?: ExameModeloWhereInput | null;
  orderBy?: ExameModeloOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateExameModeloMutation
// ====================================================

export interface IUpdateExameModeloMutation_updateExameModelo_items {
  __typename: "ExameModeloItem";
  id: string;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao: string | null;
  ordem: number;
}

export interface IUpdateExameModeloMutation_updateExameModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateExameModeloMutation_updateExameModelo {
  __typename: "ExameModelo";
  id: string;
  nome: string;
  items: IUpdateExameModeloMutation_updateExameModelo_items[] | null;
  profissional: IUpdateExameModeloMutation_updateExameModelo_profissional;
}

export interface IUpdateExameModeloMutation {
  updateExameModelo: IUpdateExameModeloMutation_updateExameModelo | null;
}

export interface IUpdateExameModeloMutationVariables {
  id: string;
  data: ExameModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateListaEsperaMutation
// ====================================================

export interface ICreateListaEsperaMutation_createListaEspera_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
}

export interface ICreateListaEsperaMutation_createListaEspera_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateListaEsperaMutation_createListaEspera_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateListaEsperaMutation_createListaEspera {
  __typename: "ListaEspera";
  id: string;
  nome: string | null;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  observacao: string | null;
  ordem: number;
  paciente: ICreateListaEsperaMutation_createListaEspera_paciente | null;
  convenio: ICreateListaEsperaMutation_createListaEspera_convenio | null;
  profissional: ICreateListaEsperaMutation_createListaEspera_profissional;
}

export interface ICreateListaEsperaMutation {
  createListaEspera: ICreateListaEsperaMutation_createListaEspera;
}

export interface ICreateListaEsperaMutationVariables {
  profissionalId: string;
  data: ListaEsperaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteListaEsperaMutation
// ====================================================

export interface IDeleteListaEsperaMutation_deleteListaEspera {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteListaEsperaMutation {
  deleteListaEspera: IDeleteListaEsperaMutation_deleteListaEspera;
}

export interface IDeleteListaEsperaMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IListaEsperaQuery
// ====================================================

export interface IListaEsperaQuery_listaEspera_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
}

export interface IListaEsperaQuery_listaEspera_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IListaEsperaQuery_listaEspera_profissional {
  __typename: "User";
  id: string;
}

export interface IListaEsperaQuery_listaEspera {
  __typename: "ListaEspera";
  id: string;
  nome: string | null;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  observacao: string | null;
  ordem: number;
  paciente: IListaEsperaQuery_listaEspera_paciente | null;
  convenio: IListaEsperaQuery_listaEspera_convenio | null;
  profissional: IListaEsperaQuery_listaEspera_profissional;
}

export interface IListaEsperaQuery {
  listaEspera: IListaEsperaQuery_listaEspera | null;
}

export interface IListaEsperaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IListasEsperasQuery
// ====================================================

export interface IListasEsperasQuery_listasEsperas_nodes_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
}

export interface IListasEsperasQuery_listasEsperas_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IListasEsperasQuery_listasEsperas_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IListasEsperasQuery_listasEsperas_nodes {
  __typename: "ListaEspera";
  id: string;
  nome: string | null;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  observacao: string | null;
  ordem: number;
  paciente: IListasEsperasQuery_listasEsperas_nodes_paciente | null;
  convenio: IListasEsperasQuery_listasEsperas_nodes_convenio | null;
  profissional: IListasEsperasQuery_listasEsperas_nodes_profissional;
}

export interface IListasEsperasQuery_listasEsperas_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IListasEsperasQuery_listasEsperas {
  __typename: "ListasEsperasResult";
  nodes: IListasEsperasQuery_listasEsperas_nodes[];
  pageInfo: IListasEsperasQuery_listasEsperas_pageInfo;
}

export interface IListasEsperasQuery {
  listasEsperas: IListasEsperasQuery_listasEsperas;
}

export interface IListasEsperasQueryVariables {
  profissionalId: string;
  where?: ListaEsperaWhereInput | null;
  orderBy?: ListaEsperaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateListaEsperaOrdemMutation
// ====================================================

export interface IUpdateListaEsperaOrdemMutation_updateListaEsperaOrdem_nodes {
  __typename: "ListaEspera";
  id: string;
}

export interface IUpdateListaEsperaOrdemMutation_updateListaEsperaOrdem {
  __typename: "ListasEsperasResult";
  nodes: IUpdateListaEsperaOrdemMutation_updateListaEsperaOrdem_nodes[];
}

export interface IUpdateListaEsperaOrdemMutation {
  updateListaEsperaOrdem: IUpdateListaEsperaOrdemMutation_updateListaEsperaOrdem;
}

export interface IUpdateListaEsperaOrdemMutationVariables {
  data: ListaEsperaUpdateOrdemDataInput[];
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreatePrescricaoModeloMutation
// ====================================================

export interface ICreatePrescricaoModeloMutation_createPrescricaoModelo_medicamentos {
  __typename: "PrescricaoModeloMedicamento";
  id: string;
  medicamento: string;
  quantidade: string | null;
  posologia: string | null;
  observacao: string | null;
}

export interface ICreatePrescricaoModeloMutation_createPrescricaoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface ICreatePrescricaoModeloMutation_createPrescricaoModelo {
  __typename: "PrescricaoModelo";
  id: string;
  nome: string;
  medicamentos: ICreatePrescricaoModeloMutation_createPrescricaoModelo_medicamentos[] | null;
  profissional: ICreatePrescricaoModeloMutation_createPrescricaoModelo_profissional;
}

export interface ICreatePrescricaoModeloMutation {
  createPrescricaoModelo: ICreatePrescricaoModeloMutation_createPrescricaoModelo;
}

export interface ICreatePrescricaoModeloMutationVariables {
  profissionalId: string;
  data: PrescricaoModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyPrescricoesModelosMutation
// ====================================================

export interface IDeleteManyPrescricoesModelosMutation_deleteManyPrescricoesModelos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyPrescricoesModelosMutation {
  deleteManyPrescricoesModelos: IDeleteManyPrescricoesModelosMutation_deleteManyPrescricoesModelos;
}

export interface IDeleteManyPrescricoesModelosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeletePrescricaoModeloMutation
// ====================================================

export interface IDeletePrescricaoModeloMutation_deletePrescricaoModelo {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeletePrescricaoModeloMutation {
  deletePrescricaoModelo: IDeletePrescricaoModeloMutation_deletePrescricaoModelo;
}

export interface IDeletePrescricaoModeloMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPrescricaoModeloQuery
// ====================================================

export interface IPrescricaoModeloQuery_prescricaoModelo_medicamentos {
  __typename: "PrescricaoModeloMedicamento";
  id: string;
  medicamento: string;
  quantidade: string | null;
  posologia: string | null;
  observacao: string | null;
}

export interface IPrescricaoModeloQuery_prescricaoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IPrescricaoModeloQuery_prescricaoModelo {
  __typename: "PrescricaoModelo";
  id: string;
  nome: string;
  medicamentos: IPrescricaoModeloQuery_prescricaoModelo_medicamentos[] | null;
  profissional: IPrescricaoModeloQuery_prescricaoModelo_profissional;
}

export interface IPrescricaoModeloQuery {
  prescricaoModelo: IPrescricaoModeloQuery_prescricaoModelo | null;
}

export interface IPrescricaoModeloQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPrescricoesModelosQuery
// ====================================================

export interface IPrescricoesModelosQuery_prescricoesModelos_nodes_medicamentos {
  __typename: "PrescricaoModeloMedicamento";
  id: string;
  medicamento: string;
  quantidade: string | null;
  posologia: string | null;
  observacao: string | null;
}

export interface IPrescricoesModelosQuery_prescricoesModelos_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IPrescricoesModelosQuery_prescricoesModelos_nodes {
  __typename: "PrescricaoModelo";
  id: string;
  nome: string;
  medicamentos: IPrescricoesModelosQuery_prescricoesModelos_nodes_medicamentos[] | null;
  profissional: IPrescricoesModelosQuery_prescricoesModelos_nodes_profissional;
}

export interface IPrescricoesModelosQuery_prescricoesModelos {
  __typename: "PrescricoesModelosResult";
  nodes: IPrescricoesModelosQuery_prescricoesModelos_nodes[];
}

export interface IPrescricoesModelosQuery {
  prescricoesModelos: IPrescricoesModelosQuery_prescricoesModelos;
}

export interface IPrescricoesModelosQueryVariables {
  profissionalId: string;
  where?: PrescricaoModeloWhereInput | null;
  orderBy?: PrescricaoModeloOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdatePrescricaoModeloMutation
// ====================================================

export interface IUpdatePrescricaoModeloMutation_updatePrescricaoModelo_medicamentos {
  __typename: "PrescricaoModeloMedicamento";
  id: string;
  medicamento: string;
  quantidade: string | null;
  posologia: string | null;
  observacao: string | null;
}

export interface IUpdatePrescricaoModeloMutation_updatePrescricaoModelo_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdatePrescricaoModeloMutation_updatePrescricaoModelo {
  __typename: "PrescricaoModelo";
  id: string;
  nome: string;
  medicamentos: IUpdatePrescricaoModeloMutation_updatePrescricaoModelo_medicamentos[] | null;
  profissional: IUpdatePrescricaoModeloMutation_updatePrescricaoModelo_profissional;
}

export interface IUpdatePrescricaoModeloMutation {
  updatePrescricaoModelo: IUpdatePrescricaoModeloMutation_updatePrescricaoModelo | null;
}

export interface IUpdatePrescricaoModeloMutationVariables {
  id: string;
  data: PrescricaoModeloDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateProcedimentoMutation
// ====================================================

export interface ICreateProcedimentoMutation_createProcedimento_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateProcedimentoMutation_createProcedimento_convenios {
  __typename: "ProcedimentoConvenio";
  id: string;
  valor: number;
  tissCodigo: string | null;
  tissNome: string | null;
  tissTabela: TissTabela | null;
  convenio: ICreateProcedimentoMutation_createProcedimento_convenios_convenio;
}

export interface ICreateProcedimentoMutation_createProcedimento_despesas {
  __typename: "ProcedimentoDespesa";
  id: string;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface ICreateProcedimentoMutation_createProcedimento_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateProcedimentoMutation_createProcedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo: string | null;
  valor: number | null;
  convenios: ICreateProcedimentoMutation_createProcedimento_convenios[] | null;
  despesas: ICreateProcedimentoMutation_createProcedimento_despesas[] | null;
  profissional: ICreateProcedimentoMutation_createProcedimento_profissional;
}

export interface ICreateProcedimentoMutation {
  createProcedimento: ICreateProcedimentoMutation_createProcedimento;
}

export interface ICreateProcedimentoMutationVariables {
  profissionalId: string;
  data: ProcedimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyProcedimentosMutation
// ====================================================

export interface IDeleteManyProcedimentosMutation_deleteManyProcedimentos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyProcedimentosMutation {
  deleteManyProcedimentos: IDeleteManyProcedimentosMutation_deleteManyProcedimentos;
}

export interface IDeleteManyProcedimentosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteProcedimentoMutation
// ====================================================

export interface IDeleteProcedimentoMutation_deleteProcedimento {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteProcedimentoMutation {
  deleteProcedimento: IDeleteProcedimentoMutation_deleteProcedimento;
}

export interface IDeleteProcedimentoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IProcedimentoQuery
// ====================================================

export interface IProcedimentoQuery_procedimento_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IProcedimentoQuery_procedimento_convenios {
  __typename: "ProcedimentoConvenio";
  id: string;
  valor: number;
  tissCodigo: string | null;
  tissNome: string | null;
  tissTabela: TissTabela | null;
  convenio: IProcedimentoQuery_procedimento_convenios_convenio;
}

export interface IProcedimentoQuery_procedimento_despesas {
  __typename: "ProcedimentoDespesa";
  id: string;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface IProcedimentoQuery_procedimento_profissional {
  __typename: "User";
  id: string;
}

export interface IProcedimentoQuery_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo: string | null;
  valor: number | null;
  convenios: IProcedimentoQuery_procedimento_convenios[] | null;
  despesas: IProcedimentoQuery_procedimento_despesas[] | null;
  profissional: IProcedimentoQuery_procedimento_profissional;
}

export interface IProcedimentoQuery {
  procedimento: IProcedimentoQuery_procedimento | null;
}

export interface IProcedimentoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IProcedimentosQuery
// ====================================================

export interface IProcedimentosQuery_procedimentos_nodes_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IProcedimentosQuery_procedimentos_nodes_convenios {
  __typename: "ProcedimentoConvenio";
  id: string;
  valor: number;
  tissCodigo: string | null;
  tissNome: string | null;
  tissTabela: TissTabela | null;
  convenio: IProcedimentosQuery_procedimentos_nodes_convenios_convenio;
}

export interface IProcedimentosQuery_procedimentos_nodes_despesas {
  __typename: "ProcedimentoDespesa";
  id: string;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface IProcedimentosQuery_procedimentos_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IProcedimentosQuery_procedimentos_nodes {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo: string | null;
  valor: number | null;
  convenios: IProcedimentosQuery_procedimentos_nodes_convenios[] | null;
  despesas: IProcedimentosQuery_procedimentos_nodes_despesas[] | null;
  profissional: IProcedimentosQuery_procedimentos_nodes_profissional;
}

export interface IProcedimentosQuery_procedimentos_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IProcedimentosQuery_procedimentos {
  __typename: "ProcedimentosResult";
  nodes: IProcedimentosQuery_procedimentos_nodes[];
  pageInfo: IProcedimentosQuery_procedimentos_pageInfo;
}

export interface IProcedimentosQuery {
  procedimentos: IProcedimentosQuery_procedimentos;
}

export interface IProcedimentosQueryVariables {
  profissionalId?: string | null;
  where?: ProcedimentoWhereInput | null;
  orderBy?: ProcedimentoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateProcedimentoMutation
// ====================================================

export interface IUpdateProcedimentoMutation_updateProcedimento_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateProcedimentoMutation_updateProcedimento_convenios {
  __typename: "ProcedimentoConvenio";
  id: string;
  valor: number;
  tissCodigo: string | null;
  tissNome: string | null;
  tissTabela: TissTabela | null;
  convenio: IUpdateProcedimentoMutation_updateProcedimento_convenios_convenio;
}

export interface IUpdateProcedimentoMutation_updateProcedimento_despesas {
  __typename: "ProcedimentoDespesa";
  id: string;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface IUpdateProcedimentoMutation_updateProcedimento_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateProcedimentoMutation_updateProcedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo: string | null;
  valor: number | null;
  convenios: IUpdateProcedimentoMutation_updateProcedimento_convenios[] | null;
  despesas: IUpdateProcedimentoMutation_updateProcedimento_despesas[] | null;
  profissional: IUpdateProcedimentoMutation_updateProcedimento_profissional;
}

export interface IUpdateProcedimentoMutation {
  updateProcedimento: IUpdateProcedimentoMutation_updateProcedimento | null;
}

export interface IUpdateProcedimentoMutationVariables {
  id: string;
  data: ProcedimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateProntuarioSecaoMutation
// ====================================================

export interface ICreateProntuarioSecaoMutation_createProntuarioSecao_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface ICreateProntuarioSecaoMutation_createProntuarioSecao_profissional {
  __typename: "User";
  id: string;
}

export interface ICreateProntuarioSecaoMutation_createProntuarioSecao {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: ICreateProntuarioSecaoMutation_createProntuarioSecao_fields[] | null;
  profissional: ICreateProntuarioSecaoMutation_createProntuarioSecao_profissional;
}

export interface ICreateProntuarioSecaoMutation {
  createProntuarioSecao: ICreateProntuarioSecaoMutation_createProntuarioSecao;
}

export interface ICreateProntuarioSecaoMutationVariables {
  profissionalId: string;
  data: ProntuarioSecaoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyProntuariosSecoesMutation
// ====================================================

export interface IDeleteManyProntuariosSecoesMutation_deleteManyProntuariosSecoes {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyProntuariosSecoesMutation {
  deleteManyProntuariosSecoes: IDeleteManyProntuariosSecoesMutation_deleteManyProntuariosSecoes;
}

export interface IDeleteManyProntuariosSecoesMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteProntuarioSecaoMutation
// ====================================================

export interface IDeleteProntuarioSecaoMutation_deleteProntuarioSecao {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteProntuarioSecaoMutation {
  deleteProntuarioSecao: IDeleteProntuarioSecaoMutation_deleteProntuarioSecao;
}

export interface IDeleteProntuarioSecaoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IProntuarioSecaoQuery
// ====================================================

export interface IProntuarioSecaoQuery_prontuarioSecao_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface IProntuarioSecaoQuery_prontuarioSecao_profissional {
  __typename: "User";
  id: string;
}

export interface IProntuarioSecaoQuery_prontuarioSecao {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: IProntuarioSecaoQuery_prontuarioSecao_fields[] | null;
  profissional: IProntuarioSecaoQuery_prontuarioSecao_profissional;
}

export interface IProntuarioSecaoQuery {
  prontuarioSecao: IProntuarioSecaoQuery_prontuarioSecao | null;
}

export interface IProntuarioSecaoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IProntuariosSecoesQuery
// ====================================================

export interface IProntuariosSecoesQuery_prontuariosSecoes_nodes_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface IProntuariosSecoesQuery_prontuariosSecoes_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IProntuariosSecoesQuery_prontuariosSecoes_nodes {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: IProntuariosSecoesQuery_prontuariosSecoes_nodes_fields[] | null;
  profissional: IProntuariosSecoesQuery_prontuariosSecoes_nodes_profissional;
}

export interface IProntuariosSecoesQuery_prontuariosSecoes {
  __typename: "ProntuariosSecoesResult";
  nodes: IProntuariosSecoesQuery_prontuariosSecoes_nodes[];
}

export interface IProntuariosSecoesQuery {
  prontuariosSecoes: IProntuariosSecoesQuery_prontuariosSecoes;
}

export interface IProntuariosSecoesQueryVariables {
  profissionalId: string;
  where?: ProntuarioSecaoWhereInput | null;
  orderBy?: ProntuarioSecaoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateProntuarioSecaoMutation
// ====================================================

export interface IUpdateProntuarioSecaoMutation_updateProntuarioSecao_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface IUpdateProntuarioSecaoMutation_updateProntuarioSecao_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateProntuarioSecaoMutation_updateProntuarioSecao {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: IUpdateProntuarioSecaoMutation_updateProntuarioSecao_fields[] | null;
  profissional: IUpdateProntuarioSecaoMutation_updateProntuarioSecao_profissional;
}

export interface IUpdateProntuarioSecaoMutation {
  updateProntuarioSecao: IUpdateProntuarioSecaoMutation_updateProntuarioSecao | null;
}

export interface IUpdateProntuarioSecaoMutationVariables {
  id: string;
  data: ProntuarioSecaoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateProntuarioSecaoOrdemMutation
// ====================================================

export interface IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes_profissional {
  __typename: "User";
  id: string;
}

export interface IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes_fields[] | null;
  profissional: IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes_profissional;
}

export interface IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem {
  __typename: "ProntuariosSecoesResult";
  nodes: IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem_nodes[];
}

export interface IUpdateProntuarioSecaoOrdemMutation {
  updateProntuarioSecaoOrdem: IUpdateProntuarioSecaoOrdemMutation_updateProntuarioSecaoOrdem;
}

export interface IUpdateProntuarioSecaoOrdemMutationVariables {
  data: ProntuarioSecaoUpdateOrdemDataInput[];
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICepQuery
// ====================================================

export interface ICepQuery_cep {
  __typename: "Cep";
  id: string;
  cep: string;
  logradouro: string | null;
  complemento: string | null;
  bairro: string | null;
  cidade: string | null;
  uf: string | null;
}

export interface ICepQuery {
  cep: ICepQuery_cep | null;
}

export interface ICepQueryVariables {
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAccountQuery
// ====================================================

export interface IAccountQuery_account {
  __typename: "Account";
  id: string;
  permiteEmail: boolean;
  permiteSms: boolean;
}

export interface IAccountQuery {
  account: IAccountQuery_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdatePermissoesEnvioMutation
// ====================================================

export interface IUpdatePermissoesEnvioMutation_updatePermissoesEnvio {
  __typename: "Account";
  id: string;
  permiteEmail: boolean;
  permiteSms: boolean;
}

export interface IUpdatePermissoesEnvioMutation {
  updatePermissoesEnvio: IUpdatePermissoesEnvioMutation_updatePermissoesEnvio | null;
}

export interface IUpdatePermissoesEnvioMutationVariables {
  data: PermissoesEnvioDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAssinaturaQuery
// ====================================================

export interface IAssinaturaQuery_assinatura_plano {
  __typename: "Plano";
  id: string;
}

export interface IAssinaturaQuery_assinatura_cobrancas {
  __typename: "Cobranca";
  id: string;
}

export interface IAssinaturaQuery_assinatura {
  __typename: "Assinatura";
  id: string;
  validUntil: any;
  cancelado: boolean | null;
  plano: IAssinaturaQuery_assinatura_plano;
  cobrancas: IAssinaturaQuery_assinatura_cobrancas[];
}

export interface IAssinaturaQuery {
  assinatura: IAssinaturaQuery_assinatura | null;
}

export interface IAssinaturaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IAssinaturasQuery
// ====================================================

export interface IAssinaturasQuery_assinaturas_nodes_plano {
  __typename: "Plano";
  id: string;
}

export interface IAssinaturasQuery_assinaturas_nodes_cobrancas {
  __typename: "Cobranca";
  id: string;
}

export interface IAssinaturasQuery_assinaturas_nodes {
  __typename: "Assinatura";
  id: string;
  validUntil: any;
  cancelado: boolean | null;
  plano: IAssinaturasQuery_assinaturas_nodes_plano;
  cobrancas: IAssinaturasQuery_assinaturas_nodes_cobrancas[];
}

export interface IAssinaturasQuery_assinaturas {
  __typename: "AssinaturasResult";
  nodes: IAssinaturasQuery_assinaturas_nodes[];
}

export interface IAssinaturasQuery {
  assinaturas: IAssinaturasQuery_assinaturas;
}

export interface IAssinaturasQueryVariables {
  where?: AssinaturaWhereInput | null;
  orderBy?: AssinaturaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IClinicaQuery
// ====================================================

export interface IClinicaQuery_clinica_account {
  __typename: "Account";
  id: string;
}

export interface IClinicaQuery_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  email: string | null;
  cnes: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  account: IClinicaQuery_clinica_account;
}

export interface IClinicaQuery {
  clinica: IClinicaQuery_clinica | null;
}

export interface IClinicaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IClinicasQuery
// ====================================================

export interface IClinicasQuery_clinicas_nodes_account {
  __typename: "Account";
  id: string;
}

export interface IClinicasQuery_clinicas_nodes {
  __typename: "Clinica";
  id: string;
  nome: string;
  email: string | null;
  cnes: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  account: IClinicasQuery_clinicas_nodes_account;
}

export interface IClinicasQuery_clinicas {
  __typename: "ClinicasResult";
  nodes: IClinicasQuery_clinicas_nodes[];
}

export interface IClinicasQuery {
  clinicas: IClinicasQuery_clinicas;
}

export interface IClinicasQueryVariables {
  where?: ClinicaWhereInput | null;
  orderBy?: ClinicaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateClinicaMutation
// ====================================================

export interface ICreateClinicaMutation_createClinica_account {
  __typename: "Account";
  id: string;
}

export interface ICreateClinicaMutation_createClinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  email: string | null;
  cnes: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  account: ICreateClinicaMutation_createClinica_account;
}

export interface ICreateClinicaMutation {
  createClinica: ICreateClinicaMutation_createClinica;
}

export interface ICreateClinicaMutationVariables {
  data: ClinicaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteClinicaMutation
// ====================================================

export interface IDeleteClinicaMutation_deleteClinica {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteClinicaMutation {
  deleteClinica: IDeleteClinicaMutation_deleteClinica;
}

export interface IDeleteClinicaMutationVariables {
  id: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyClinicasMutation
// ====================================================

export interface IDeleteManyClinicasMutation_deleteManyClinicas {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyClinicasMutation {
  deleteManyClinicas: IDeleteManyClinicasMutation_deleteManyClinicas;
}

export interface IDeleteManyClinicasMutationVariables {
  ids: string[];
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateClinicaMutation
// ====================================================

export interface IUpdateClinicaMutation_updateClinica_account {
  __typename: "Account";
  id: string;
}

export interface IUpdateClinicaMutation_updateClinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  email: string | null;
  cnes: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  account: IUpdateClinicaMutation_updateClinica_account;
}

export interface IUpdateClinicaMutation {
  updateClinica: IUpdateClinicaMutation_updateClinica | null;
}

export interface IUpdateClinicaMutationVariables {
  id: string;
  data: ClinicaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICobrancaQuery
// ====================================================

export interface ICobrancaQuery_cobranca_assinatura {
  __typename: "Assinatura";
  id: string;
}

export interface ICobrancaQuery_cobranca {
  __typename: "Cobranca";
  id: string;
  data: any;
  descricao: string;
  valor: number;
  saldo: number;
  assinatura: ICobrancaQuery_cobranca_assinatura;
}

export interface ICobrancaQuery {
  cobranca: ICobrancaQuery_cobranca | null;
}

export interface ICobrancaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ICobrancasQuery
// ====================================================

export interface ICobrancasQuery_cobrancas_nodes_assinatura {
  __typename: "Assinatura";
  id: string;
}

export interface ICobrancasQuery_cobrancas_nodes {
  __typename: "Cobranca";
  id: string;
  data: any;
  descricao: string;
  valor: number;
  saldo: number;
  assinatura: ICobrancasQuery_cobrancas_nodes_assinatura;
}

export interface ICobrancasQuery_cobrancas {
  __typename: "CobrancasResult";
  nodes: ICobrancasQuery_cobrancas_nodes[];
}

export interface ICobrancasQuery {
  cobrancas: ICobrancasQuery_cobrancas;
}

export interface ICobrancasQueryVariables {
  where?: CobrancaWhereInput | null;
  orderBy?: CobrancaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IContatoQuery
// ====================================================

export interface IContatoQuery_contato {
  __typename: "Contato";
  id: string;
  nome: string;
  email: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  observacao: string | null;
}

export interface IContatoQuery {
  contato: IContatoQuery_contato | null;
}

export interface IContatoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IContatosQuery
// ====================================================

export interface IContatosQuery_contatos_nodes {
  __typename: "Contato";
  id: string;
  nome: string;
  email: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  observacao: string | null;
}

export interface IContatosQuery_contatos {
  __typename: "ContatosResult";
  nodes: IContatosQuery_contatos_nodes[];
}

export interface IContatosQuery {
  contatos: IContatosQuery_contatos;
}

export interface IContatosQueryVariables {
  where?: ContatoWhereInput | null;
  orderBy?: ContatoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateContatoMutation
// ====================================================

export interface ICreateContatoMutation_createContato {
  __typename: "Contato";
  id: string;
  nome: string;
  email: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  observacao: string | null;
}

export interface ICreateContatoMutation {
  createContato: ICreateContatoMutation_createContato;
}

export interface ICreateContatoMutationVariables {
  data: ContatoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteContatoMutation
// ====================================================

export interface IDeleteContatoMutation_deleteContato {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteContatoMutation {
  deleteContato: IDeleteContatoMutation_deleteContato;
}

export interface IDeleteContatoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyContatosMutation
// ====================================================

export interface IDeleteManyContatosMutation_deleteManyContatos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyContatosMutation {
  deleteManyContatos: IDeleteManyContatosMutation_deleteManyContatos;
}

export interface IDeleteManyContatosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateContatoMutation
// ====================================================

export interface IUpdateContatoMutation_updateContato {
  __typename: "Contato";
  id: string;
  nome: string;
  email: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  observacao: string | null;
}

export interface IUpdateContatoMutation {
  updateContato: IUpdateContatoMutation_updateContato | null;
}

export interface IUpdateContatoMutationVariables {
  id: string;
  data: ContatoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateUploadUrlMutation
// ====================================================

export interface ICreateUploadUrlMutation_createUploadUrl_file {
  __typename: "FileUpload";
  id: string;
  createdAt: any;
  updatedAt: any;
  extension: string;
  contentType: string;
  url: string;
  description: string | null;
  thumbnailUrl: string | null;
}

export interface ICreateUploadUrlMutation_createUploadUrl {
  __typename: "FileUploadUrl";
  file: ICreateUploadUrlMutation_createUploadUrl_file;
  url: string;
  fields: any;
}

export interface ICreateUploadUrlMutation {
  createUploadUrl: ICreateUploadUrlMutation_createUploadUrl;
}

export interface ICreateUploadUrlMutationVariables {
  data: CreateUploadUrlDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPlanoQuery
// ====================================================

export interface IPlanoQuery_plano {
  __typename: "Plano";
  id: string;
  nome: string;
  valor: number;
  validoAte: any;
  cancelado: boolean | null;
}

export interface IPlanoQuery {
  plano: IPlanoQuery_plano | null;
}

export interface IPlanoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IPlanosQuery
// ====================================================

export interface IPlanosQuery_planos_nodes {
  __typename: "Plano";
  id: string;
  nome: string;
  valor: number;
  validoAte: any;
  cancelado: boolean | null;
}

export interface IPlanosQuery_planos {
  __typename: "PlanosResult";
  nodes: IPlanosQuery_planos_nodes[];
}

export interface IPlanosQuery {
  planos: IPlanosQuery_planos;
}

export interface IPlanosQueryVariables {
  where?: PlanoWhereInput | null;
  orderBy?: PlanoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateUserMutation
// ====================================================

export interface ICreateUserMutation_createUser_profissionais {
  __typename: "User";
  id: string;
}

export interface ICreateUserMutation_createUser_account {
  __typename: "Account";
  id: string;
}

export interface ICreateUserMutation_createUser_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface ICreateUserMutation_createUser_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: ICreateUserMutation_createUser_clinicas_clinica;
}

export interface ICreateUserMutation_createUser_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface ICreateUserMutation_createUser {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: ICreateUserMutation_createUser_profissionais[] | null;
  account: ICreateUserMutation_createUser_account;
  clinicas: ICreateUserMutation_createUser_clinicas[] | null;
  imagem: ICreateUserMutation_createUser_imagem | null;
}

export interface ICreateUserMutation {
  createUser: ICreateUserMutation_createUser;
}

export interface ICreateUserMutationVariables {
  data: CreateUserDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyUsersMutation
// ====================================================

export interface IDeleteManyUsersMutation_deleteManyUsers {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyUsersMutation {
  deleteManyUsers: IDeleteManyUsersMutation_deleteManyUsers;
}

export interface IDeleteManyUsersMutationVariables {
  ids: string[];
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteUserMutation
// ====================================================

export interface IDeleteUserMutation_deleteUser {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteUserMutation {
  deleteUser: IDeleteUserMutation_deleteUser;
}

export interface IDeleteUserMutationVariables {
  id: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateEquipeMutation
// ====================================================

export interface IUpdateEquipeMutation_updateEquipe_nodes_profissionais {
  __typename: "User";
  id: string;
}

export interface IUpdateEquipeMutation_updateEquipe_nodes_account {
  __typename: "Account";
  id: string;
}

export interface IUpdateEquipeMutation_updateEquipe_nodes_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUpdateEquipeMutation_updateEquipe_nodes_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUpdateEquipeMutation_updateEquipe_nodes_clinicas_clinica;
}

export interface IUpdateEquipeMutation_updateEquipe_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateEquipeMutation_updateEquipe_nodes {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUpdateEquipeMutation_updateEquipe_nodes_profissionais[] | null;
  account: IUpdateEquipeMutation_updateEquipe_nodes_account;
  clinicas: IUpdateEquipeMutation_updateEquipe_nodes_clinicas[] | null;
  imagem: IUpdateEquipeMutation_updateEquipe_nodes_imagem | null;
}

export interface IUpdateEquipeMutation_updateEquipe {
  __typename: "UsersResult";
  nodes: IUpdateEquipeMutation_updateEquipe_nodes[];
}

export interface IUpdateEquipeMutation {
  updateEquipe: IUpdateEquipeMutation_updateEquipe;
}

export interface IUpdateEquipeMutationVariables {
  users: UpdateEquipeDataInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateMeMutation
// ====================================================

export interface IUpdateMeMutation_updateMe_profissionais {
  __typename: "User";
  id: string;
}

export interface IUpdateMeMutation_updateMe_account {
  __typename: "Account";
  id: string;
}

export interface IUpdateMeMutation_updateMe_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUpdateMeMutation_updateMe_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUpdateMeMutation_updateMe_clinicas_clinica;
}

export interface IUpdateMeMutation_updateMe_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateMeMutation_updateMe {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUpdateMeMutation_updateMe_profissionais[] | null;
  account: IUpdateMeMutation_updateMe_account;
  clinicas: IUpdateMeMutation_updateMe_clinicas[] | null;
  imagem: IUpdateMeMutation_updateMe_imagem | null;
}

export interface IUpdateMeMutation {
  updateMe: IUpdateMeMutation_updateMe | null;
}

export interface IUpdateMeMutationVariables {
  data: UpdateMeDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateUserMutation
// ====================================================

export interface IUpdateUserMutation_updateUser_profissionais {
  __typename: "User";
  id: string;
}

export interface IUpdateUserMutation_updateUser_account {
  __typename: "Account";
  id: string;
}

export interface IUpdateUserMutation_updateUser_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUpdateUserMutation_updateUser_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUpdateUserMutation_updateUser_clinicas_clinica;
}

export interface IUpdateUserMutation_updateUser_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUpdateUserMutation_updateUser {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUpdateUserMutation_updateUser_profissionais[] | null;
  account: IUpdateUserMutation_updateUser_account;
  clinicas: IUpdateUserMutation_updateUser_clinicas[] | null;
  imagem: IUpdateUserMutation_updateUser_imagem | null;
}

export interface IUpdateUserMutation {
  updateUser: IUpdateUserMutation_updateUser | null;
}

export interface IUpdateUserMutationVariables {
  id: string;
  data: UpdateUserDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IUserQuery
// ====================================================

export interface IUserQuery_user_profissionais {
  __typename: "User";
  id: string;
}

export interface IUserQuery_user_account {
  __typename: "Account";
  id: string;
}

export interface IUserQuery_user_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUserQuery_user_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUserQuery_user_clinicas_clinica;
}

export interface IUserQuery_user_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUserQuery_user {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUserQuery_user_profissionais[] | null;
  account: IUserQuery_user_account;
  clinicas: IUserQuery_user_clinicas[] | null;
  imagem: IUserQuery_user_imagem | null;
}

export interface IUserQuery {
  user: IUserQuery_user | null;
}

export interface IUserQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IUsersQuery
// ====================================================

export interface IUsersQuery_users_nodes_profissionais {
  __typename: "User";
  id: string;
}

export interface IUsersQuery_users_nodes_account {
  __typename: "Account";
  id: string;
}

export interface IUsersQuery_users_nodes_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUsersQuery_users_nodes_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUsersQuery_users_nodes_clinicas_clinica;
}

export interface IUsersQuery_users_nodes_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUsersQuery_users_nodes {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUsersQuery_users_nodes_profissionais[] | null;
  account: IUsersQuery_users_nodes_account;
  clinicas: IUsersQuery_users_nodes_clinicas[] | null;
  imagem: IUsersQuery_users_nodes_imagem | null;
}

export interface IUsersQuery_users_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IUsersQuery_users {
  __typename: "UsersResult";
  nodes: IUsersQuery_users_nodes[];
  pageInfo: IUsersQuery_users_pageInfo;
}

export interface IUsersQuery {
  users: IUsersQuery_users;
}

export interface IUsersQueryVariables {
  where?: UserWhereInput | null;
  orderBy?: UserOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissGuiaConsultaMutation
// ====================================================

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaConsultaMutation_createTissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora: string | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  pacienteDataNascimento: any | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCpf: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao: string | null;
  agendamento: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_agendamento | null;
  paciente: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_paciente;
  convenio: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_convenio;
  lote: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_lote | null;
  profissional: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta_profissional;
}

export interface ICreateTissGuiaConsultaMutation {
  createTissGuiaConsulta: ICreateTissGuiaConsultaMutation_createTissGuiaConsulta;
}

export interface ICreateTissGuiaConsultaMutationVariables {
  profissionalId: string;
  data: TissGuiaConsultaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissGuiaConsultaMutation
// ====================================================

export interface IDeleteTissGuiaConsultaMutation_deleteTissGuiaConsulta {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissGuiaConsultaMutation {
  deleteTissGuiaConsulta: IDeleteTissGuiaConsultaMutation_deleteTissGuiaConsulta;
}

export interface IDeleteTissGuiaConsultaMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiaConsultaQuery
// ====================================================

export interface ITissGuiaConsultaQuery_tissGuiaConsulta_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ITissGuiaConsultaQuery_tissGuiaConsulta_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaQuery_tissGuiaConsulta_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaQuery_tissGuiaConsulta_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaConsultaQuery_tissGuiaConsulta_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaQuery_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora: string | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  pacienteDataNascimento: any | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCpf: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao: string | null;
  agendamento: ITissGuiaConsultaQuery_tissGuiaConsulta_agendamento | null;
  paciente: ITissGuiaConsultaQuery_tissGuiaConsulta_paciente;
  convenio: ITissGuiaConsultaQuery_tissGuiaConsulta_convenio;
  lote: ITissGuiaConsultaQuery_tissGuiaConsulta_lote | null;
  profissional: ITissGuiaConsultaQuery_tissGuiaConsulta_profissional;
}

export interface ITissGuiaConsultaQuery {
  tissGuiaConsulta: ITissGuiaConsultaQuery_tissGuiaConsulta | null;
}

export interface ITissGuiaConsultaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiasConsultasQuery
// ====================================================

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas_nodes {
  __typename: "TissGuiaConsulta";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora: string | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  pacienteDataNascimento: any | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCpf: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao: string | null;
  agendamento: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_agendamento | null;
  paciente: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_paciente;
  convenio: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_convenio;
  lote: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_lote | null;
  profissional: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes_profissional;
}

export interface ITissGuiasConsultasQuery_tissGuiasConsultas {
  __typename: "TissGuiasConsultasResult";
  nodes: ITissGuiasConsultasQuery_tissGuiasConsultas_nodes[];
}

export interface ITissGuiasConsultasQuery {
  tissGuiasConsultas: ITissGuiasConsultasQuery_tissGuiasConsultas;
}

export interface ITissGuiasConsultasQueryVariables {
  profissionalId: string;
  where?: TissGuiaConsultaWhereInput | null;
  orderBy?: TissGuiaConsultaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissGuiaConsultaMutation
// ====================================================

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora: string | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  pacienteDataNascimento: any | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCpf: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao: string | null;
  agendamento: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_agendamento | null;
  paciente: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_paciente;
  convenio: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_convenio;
  lote: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_lote | null;
  profissional: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta_profissional;
}

export interface IUpdateTissGuiaConsultaMutation {
  updateTissGuiaConsulta: IUpdateTissGuiaConsultaMutation_updateTissGuiaConsulta | null;
}

export interface IUpdateTissGuiaConsultaMutationVariables {
  id: string;
  data: TissGuiaConsultaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissGuiaHonorariosMutation
// ====================================================

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_procedimentosRealizados_profissionais {
  __typename: "TissGuiaHonorariosProcedimentoProfissional";
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_procedimentosRealizados {
  __typename: "TissGuiaHonorariosProcedimentoRealizado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_procedimentosRealizados_profissionais[];
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha: string | null;
  numeroGuiaOperadora: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  observacao: string | null;
  valorTotalHonorarios: number;
  procedimentosRealizados: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_procedimentosRealizados[];
  dataEmissaoGuia: any;
  paciente: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_paciente;
  convenio: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_convenio;
  lote: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_lote | null;
  profissional: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios_profissional;
}

export interface ICreateTissGuiaHonorariosMutation {
  createTissGuiaHonorarios: ICreateTissGuiaHonorariosMutation_createTissGuiaHonorarios;
}

export interface ICreateTissGuiaHonorariosMutationVariables {
  profissionalId: string;
  data: TissGuiaHonorariosDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissGuiaHonorariosMutation
// ====================================================

export interface IDeleteTissGuiaHonorariosMutation_deleteTissGuiaHonorarios {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissGuiaHonorariosMutation {
  deleteTissGuiaHonorarios: IDeleteTissGuiaHonorariosMutation_deleteTissGuiaHonorarios;
}

export interface IDeleteTissGuiaHonorariosMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiaHonorariosQuery
// ====================================================

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_procedimentosRealizados_profissionais {
  __typename: "TissGuiaHonorariosProcedimentoProfissional";
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_procedimentosRealizados {
  __typename: "TissGuiaHonorariosProcedimentoRealizado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: ITissGuiaHonorariosQuery_tissGuiaHonorarios_procedimentosRealizados_profissionais[];
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosQuery_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha: string | null;
  numeroGuiaOperadora: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  observacao: string | null;
  valorTotalHonorarios: number;
  procedimentosRealizados: ITissGuiaHonorariosQuery_tissGuiaHonorarios_procedimentosRealizados[];
  dataEmissaoGuia: any;
  paciente: ITissGuiaHonorariosQuery_tissGuiaHonorarios_paciente;
  convenio: ITissGuiaHonorariosQuery_tissGuiaHonorarios_convenio;
  lote: ITissGuiaHonorariosQuery_tissGuiaHonorarios_lote | null;
  profissional: ITissGuiaHonorariosQuery_tissGuiaHonorarios_profissional;
}

export interface ITissGuiaHonorariosQuery {
  tissGuiaHonorarios: ITissGuiaHonorariosQuery_tissGuiaHonorarios | null;
}

export interface ITissGuiaHonorariosQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiasHonorariosQuery
// ====================================================

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_procedimentosRealizados_profissionais {
  __typename: "TissGuiaHonorariosProcedimentoProfissional";
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_procedimentosRealizados {
  __typename: "TissGuiaHonorariosProcedimentoRealizado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_procedimentosRealizados_profissionais[];
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes {
  __typename: "TissGuiaHonorarios";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha: string | null;
  numeroGuiaOperadora: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  observacao: string | null;
  valorTotalHonorarios: number;
  procedimentosRealizados: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_procedimentosRealizados[];
  dataEmissaoGuia: any;
  paciente: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_paciente;
  convenio: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_convenio;
  lote: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_lote | null;
  profissional: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes_profissional;
}

export interface ITissGuiasHonorariosQuery_tissGuiasHonorarios {
  __typename: "TissGuiasHonorariosResult";
  nodes: ITissGuiasHonorariosQuery_tissGuiasHonorarios_nodes[];
}

export interface ITissGuiasHonorariosQuery {
  tissGuiasHonorarios: ITissGuiasHonorariosQuery_tissGuiasHonorarios;
}

export interface ITissGuiasHonorariosQueryVariables {
  profissionalId: string;
  where?: TissGuiaHonorariosWhereInput | null;
  orderBy?: TissGuiaHonorariosOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissGuiaHonorariosMutation
// ====================================================

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_procedimentosRealizados_profissionais {
  __typename: "TissGuiaHonorariosProcedimentoProfissional";
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_procedimentosRealizados {
  __typename: "TissGuiaHonorariosProcedimentoRealizado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_procedimentosRealizados_profissionais[];
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha: string | null;
  numeroGuiaOperadora: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  observacao: string | null;
  valorTotalHonorarios: number;
  procedimentosRealizados: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_procedimentosRealizados[];
  dataEmissaoGuia: any;
  paciente: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_paciente;
  convenio: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_convenio;
  lote: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_lote | null;
  profissional: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios_profissional;
}

export interface IUpdateTissGuiaHonorariosMutation {
  updateTissGuiaHonorarios: IUpdateTissGuiaHonorariosMutation_updateTissGuiaHonorarios | null;
}

export interface IUpdateTissGuiaHonorariosMutationVariables {
  id: string;
  data: TissGuiaHonorariosDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissGuiaOdontologicaMutation
// ====================================================

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_procedimentosExecutados {
  __typename: "TissGuiaOdontologicaProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente: string | null;
  denteRegiaoCodRegiao: string | null;
  denteFace: string | null;
  qtdProc: number;
  qtdUS: number | null;
  valorProc: number;
  valorFranquia: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_odontoInicial_dentes {
  __typename: "TissGuiaOdontologicaOdontoInicialDente";
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_odontoInicial {
  __typename: "TissGuiaOdontologicaOdontoInicial";
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora: string | null;
  dentes: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_odontoInicial_dentes[];
  doencaPeriodontal: boolean | null;
  alteracaoTecidoMole: boolean | null;
  observacao: string | null;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal: string | null;
  numeroGuiaOperadora: string | null;
  dataAutorizacao: any | null;
  senhaAutorizacao: string | null;
  validadeSenha: any | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  planoBeneficiario: string;
  nomeEmpresa: string | null;
  numeroTelefone: string | null;
  nomeTitular: string | null;
  profissionaisResponsaveisNomeProfSolic: string | null;
  profissionaisResponsaveisCroSolic: string | null;
  profissionaisResponsaveisUfSolic: string | null;
  profissionaisResponsaveisCbosSolic: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2: string | null;
  profissionaisResponsaveisCroExec2: string | null;
  profissionaisResponsaveisUfExec2: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS: number | null;
  valorTotalProc: number;
  valorTotalFranquia: number | null;
  dataTerminoTrat: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  observacao: string | null;
  procedimentosExecutados: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_procedimentosExecutados[];
  odontoInicial: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_odontoInicial | null;
  paciente: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_paciente;
  convenio: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_convenio;
  lote: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_lote | null;
  profissional: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica_profissional;
}

export interface ICreateTissGuiaOdontologicaMutation {
  createTissGuiaOdontologica: ICreateTissGuiaOdontologicaMutation_createTissGuiaOdontologica;
}

export interface ICreateTissGuiaOdontologicaMutationVariables {
  profissionalId: string;
  data: TissGuiaOdontologicaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissGuiaOdontologicaMutation
// ====================================================

export interface IDeleteTissGuiaOdontologicaMutation_deleteTissGuiaOdontologica {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissGuiaOdontologicaMutation {
  deleteTissGuiaOdontologica: IDeleteTissGuiaOdontologicaMutation_deleteTissGuiaOdontologica;
}

export interface IDeleteTissGuiaOdontologicaMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiaOdontologicaQuery
// ====================================================

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_procedimentosExecutados {
  __typename: "TissGuiaOdontologicaProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente: string | null;
  denteRegiaoCodRegiao: string | null;
  denteFace: string | null;
  qtdProc: number;
  qtdUS: number | null;
  valorProc: number;
  valorFranquia: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_odontoInicial_dentes {
  __typename: "TissGuiaOdontologicaOdontoInicialDente";
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_odontoInicial {
  __typename: "TissGuiaOdontologicaOdontoInicial";
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora: string | null;
  dentes: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_odontoInicial_dentes[];
  doencaPeriodontal: boolean | null;
  alteracaoTecidoMole: boolean | null;
  observacao: string | null;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaQuery_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal: string | null;
  numeroGuiaOperadora: string | null;
  dataAutorizacao: any | null;
  senhaAutorizacao: string | null;
  validadeSenha: any | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  planoBeneficiario: string;
  nomeEmpresa: string | null;
  numeroTelefone: string | null;
  nomeTitular: string | null;
  profissionaisResponsaveisNomeProfSolic: string | null;
  profissionaisResponsaveisCroSolic: string | null;
  profissionaisResponsaveisUfSolic: string | null;
  profissionaisResponsaveisCbosSolic: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2: string | null;
  profissionaisResponsaveisCroExec2: string | null;
  profissionaisResponsaveisUfExec2: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS: number | null;
  valorTotalProc: number;
  valorTotalFranquia: number | null;
  dataTerminoTrat: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  observacao: string | null;
  procedimentosExecutados: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_procedimentosExecutados[];
  odontoInicial: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_odontoInicial | null;
  paciente: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_paciente;
  convenio: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_convenio;
  lote: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_lote | null;
  profissional: ITissGuiaOdontologicaQuery_tissGuiaOdontologica_profissional;
}

export interface ITissGuiaOdontologicaQuery {
  tissGuiaOdontologica: ITissGuiaOdontologicaQuery_tissGuiaOdontologica | null;
}

export interface ITissGuiaOdontologicaQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiasOdontologicasQuery
// ====================================================

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_procedimentosExecutados {
  __typename: "TissGuiaOdontologicaProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente: string | null;
  denteRegiaoCodRegiao: string | null;
  denteFace: string | null;
  qtdProc: number;
  qtdUS: number | null;
  valorProc: number;
  valorFranquia: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_odontoInicial_dentes {
  __typename: "TissGuiaOdontologicaOdontoInicialDente";
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_odontoInicial {
  __typename: "TissGuiaOdontologicaOdontoInicial";
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora: string | null;
  dentes: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_odontoInicial_dentes[];
  doencaPeriodontal: boolean | null;
  alteracaoTecidoMole: boolean | null;
  observacao: string | null;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes {
  __typename: "TissGuiaOdontologica";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal: string | null;
  numeroGuiaOperadora: string | null;
  dataAutorizacao: any | null;
  senhaAutorizacao: string | null;
  validadeSenha: any | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  planoBeneficiario: string;
  nomeEmpresa: string | null;
  numeroTelefone: string | null;
  nomeTitular: string | null;
  profissionaisResponsaveisNomeProfSolic: string | null;
  profissionaisResponsaveisCroSolic: string | null;
  profissionaisResponsaveisUfSolic: string | null;
  profissionaisResponsaveisCbosSolic: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2: string | null;
  profissionaisResponsaveisCroExec2: string | null;
  profissionaisResponsaveisUfExec2: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS: number | null;
  valorTotalProc: number;
  valorTotalFranquia: number | null;
  dataTerminoTrat: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  observacao: string | null;
  procedimentosExecutados: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_procedimentosExecutados[];
  odontoInicial: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_odontoInicial | null;
  paciente: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_paciente;
  convenio: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_convenio;
  lote: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_lote | null;
  profissional: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes_profissional;
}

export interface ITissGuiasOdontologicasQuery_tissGuiasOdontologicas {
  __typename: "TissGuiasOdontologicasResult";
  nodes: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas_nodes[];
}

export interface ITissGuiasOdontologicasQuery {
  tissGuiasOdontologicas: ITissGuiasOdontologicasQuery_tissGuiasOdontologicas;
}

export interface ITissGuiasOdontologicasQueryVariables {
  profissionalId: string;
  where?: TissGuiaOdontologicaWhereInput | null;
  orderBy?: TissGuiaOdontologicaOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissGuiaOdontologicaMutation
// ====================================================

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_procedimentosExecutados {
  __typename: "TissGuiaOdontologicaProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente: string | null;
  denteRegiaoCodRegiao: string | null;
  denteFace: string | null;
  qtdProc: number;
  qtdUS: number | null;
  valorProc: number;
  valorFranquia: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_odontoInicial_dentes {
  __typename: "TissGuiaOdontologicaOdontoInicialDente";
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_odontoInicial {
  __typename: "TissGuiaOdontologicaOdontoInicial";
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora: string | null;
  dentes: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_odontoInicial_dentes[];
  doencaPeriodontal: boolean | null;
  alteracaoTecidoMole: boolean | null;
  observacao: string | null;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal: string | null;
  numeroGuiaOperadora: string | null;
  dataAutorizacao: any | null;
  senhaAutorizacao: string | null;
  validadeSenha: any | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  planoBeneficiario: string;
  nomeEmpresa: string | null;
  numeroTelefone: string | null;
  nomeTitular: string | null;
  profissionaisResponsaveisNomeProfSolic: string | null;
  profissionaisResponsaveisCroSolic: string | null;
  profissionaisResponsaveisUfSolic: string | null;
  profissionaisResponsaveisCbosSolic: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2: string | null;
  profissionaisResponsaveisCroExec2: string | null;
  profissionaisResponsaveisUfExec2: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS: number | null;
  valorTotalProc: number;
  valorTotalFranquia: number | null;
  dataTerminoTrat: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  observacao: string | null;
  procedimentosExecutados: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_procedimentosExecutados[];
  odontoInicial: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_odontoInicial | null;
  paciente: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_paciente;
  convenio: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_convenio;
  lote: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_lote | null;
  profissional: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica_profissional;
}

export interface IUpdateTissGuiaOdontologicaMutation {
  updateTissGuiaOdontologica: IUpdateTissGuiaOdontologicaMutation_updateTissGuiaOdontologica | null;
}

export interface IUpdateTissGuiaOdontologicaMutationVariables {
  id: string;
  data: TissGuiaOdontologicaDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissGuiaSpSadtMutation
// ====================================================

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_procedimentosExecutados_equipeSadt {
  __typename: "TissGuiaSpSadtProcedimentoExecutadoEquipeSadt";
  id: string;
  grauPart: string | null;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_procedimentosExecutados {
  __typename: "TissGuiaSpSadtProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_procedimentosExecutados_equipeSadt[] | null;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_outrasDespesas {
  __typename: "TissGuiaSpSadtOutraDepesa";
  id: string;
  sequencialItem: number;
  codigoDespesa: string;
  itemVinculado: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial: any | null;
  servicosHoraFinal: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA: string | null;
  servicosCodigoRefFabricante: string | null;
  servicosAutorizacaoFuncionamento: string | null;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal: string | null;
  autorizacaoNumeroGuiaOperadora: string | null;
  autorizacaoData: any | null;
  autorizacaoSenha: string | null;
  autorizacaoDataValidadeSenha: any | null;
  autorizacaoAusenciaCodValidacao: string | null;
  autorizacaoCodValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  solicitanteCodigoPrestadorNaOperadora: string | null;
  solicitanteCpf: string | null;
  solicitanteCnpj: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica: string | null;
  executanteCodigoPrestadorNaOperadora: string | null;
  executanteCpf: string | null;
  executanteCnpj: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta: string | null;
  atendimentoMotivoEncerramento: string | null;
  observacao: string | null;
  totalValorProcedimentos: number | null;
  totalValorDiarias: number | null;
  totalValorTaxasAlugueis: number | null;
  totalValorMateriais: number | null;
  totalValorMedicamentos: number | null;
  totalValorOPME: number | null;
  totalValorGasesMedicinais: number | null;
  totalValorTotalGeral: number;
  procedimentosExecutados: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_procedimentosExecutados[] | null;
  outrasDespesas: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_outrasDespesas[] | null;
  paciente: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_paciente;
  convenio: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_convenio;
  lote: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_lote | null;
  profissional: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt_profissional;
}

export interface ICreateTissGuiaSpSadtMutation {
  createTissGuiaSpSadt: ICreateTissGuiaSpSadtMutation_createTissGuiaSpSadt;
}

export interface ICreateTissGuiaSpSadtMutationVariables {
  profissionalId: string;
  data: TissGuiaSpSadtDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissGuiaSpSadtMutation
// ====================================================

export interface IDeleteTissGuiaSpSadtMutation_deleteTissGuiaSpSadt {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissGuiaSpSadtMutation {
  deleteTissGuiaSpSadt: IDeleteTissGuiaSpSadtMutation_deleteTissGuiaSpSadt;
}

export interface IDeleteTissGuiaSpSadtMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiaSpSadtQuery
// ====================================================

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_procedimentosExecutados_equipeSadt {
  __typename: "TissGuiaSpSadtProcedimentoExecutadoEquipeSadt";
  id: string;
  grauPart: string | null;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_procedimentosExecutados {
  __typename: "TissGuiaSpSadtProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt: ITissGuiaSpSadtQuery_tissGuiaSpSadt_procedimentosExecutados_equipeSadt[] | null;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_outrasDespesas {
  __typename: "TissGuiaSpSadtOutraDepesa";
  id: string;
  sequencialItem: number;
  codigoDespesa: string;
  itemVinculado: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial: any | null;
  servicosHoraFinal: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA: string | null;
  servicosCodigoRefFabricante: string | null;
  servicosAutorizacaoFuncionamento: string | null;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtQuery_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal: string | null;
  autorizacaoNumeroGuiaOperadora: string | null;
  autorizacaoData: any | null;
  autorizacaoSenha: string | null;
  autorizacaoDataValidadeSenha: any | null;
  autorizacaoAusenciaCodValidacao: string | null;
  autorizacaoCodValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  solicitanteCodigoPrestadorNaOperadora: string | null;
  solicitanteCpf: string | null;
  solicitanteCnpj: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica: string | null;
  executanteCodigoPrestadorNaOperadora: string | null;
  executanteCpf: string | null;
  executanteCnpj: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta: string | null;
  atendimentoMotivoEncerramento: string | null;
  observacao: string | null;
  totalValorProcedimentos: number | null;
  totalValorDiarias: number | null;
  totalValorTaxasAlugueis: number | null;
  totalValorMateriais: number | null;
  totalValorMedicamentos: number | null;
  totalValorOPME: number | null;
  totalValorGasesMedicinais: number | null;
  totalValorTotalGeral: number;
  procedimentosExecutados: ITissGuiaSpSadtQuery_tissGuiaSpSadt_procedimentosExecutados[] | null;
  outrasDespesas: ITissGuiaSpSadtQuery_tissGuiaSpSadt_outrasDespesas[] | null;
  paciente: ITissGuiaSpSadtQuery_tissGuiaSpSadt_paciente;
  convenio: ITissGuiaSpSadtQuery_tissGuiaSpSadt_convenio;
  lote: ITissGuiaSpSadtQuery_tissGuiaSpSadt_lote | null;
  profissional: ITissGuiaSpSadtQuery_tissGuiaSpSadt_profissional;
}

export interface ITissGuiaSpSadtQuery {
  tissGuiaSpSadt: ITissGuiaSpSadtQuery_tissGuiaSpSadt | null;
}

export interface ITissGuiaSpSadtQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissGuiasSpSadtQuery
// ====================================================

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_procedimentosExecutados_equipeSadt {
  __typename: "TissGuiaSpSadtProcedimentoExecutadoEquipeSadt";
  id: string;
  grauPart: string | null;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_procedimentosExecutados {
  __typename: "TissGuiaSpSadtProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_procedimentosExecutados_equipeSadt[] | null;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_outrasDespesas {
  __typename: "TissGuiaSpSadtOutraDepesa";
  id: string;
  sequencialItem: number;
  codigoDespesa: string;
  itemVinculado: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial: any | null;
  servicosHoraFinal: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA: string | null;
  servicosCodigoRefFabricante: string | null;
  servicosAutorizacaoFuncionamento: string | null;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes {
  __typename: "TissGuiaSpSadt";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal: string | null;
  autorizacaoNumeroGuiaOperadora: string | null;
  autorizacaoData: any | null;
  autorizacaoSenha: string | null;
  autorizacaoDataValidadeSenha: any | null;
  autorizacaoAusenciaCodValidacao: string | null;
  autorizacaoCodValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  solicitanteCodigoPrestadorNaOperadora: string | null;
  solicitanteCpf: string | null;
  solicitanteCnpj: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica: string | null;
  executanteCodigoPrestadorNaOperadora: string | null;
  executanteCpf: string | null;
  executanteCnpj: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta: string | null;
  atendimentoMotivoEncerramento: string | null;
  observacao: string | null;
  totalValorProcedimentos: number | null;
  totalValorDiarias: number | null;
  totalValorTaxasAlugueis: number | null;
  totalValorMateriais: number | null;
  totalValorMedicamentos: number | null;
  totalValorOPME: number | null;
  totalValorGasesMedicinais: number | null;
  totalValorTotalGeral: number;
  procedimentosExecutados: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_procedimentosExecutados[] | null;
  outrasDespesas: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_outrasDespesas[] | null;
  paciente: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_paciente;
  convenio: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_convenio;
  lote: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_lote | null;
  profissional: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes_profissional;
}

export interface ITissGuiasSpSadtQuery_tissGuiasSpSadt {
  __typename: "TissGuiasSpSadtResult";
  nodes: ITissGuiasSpSadtQuery_tissGuiasSpSadt_nodes[];
}

export interface ITissGuiasSpSadtQuery {
  tissGuiasSpSadt: ITissGuiasSpSadtQuery_tissGuiasSpSadt;
}

export interface ITissGuiasSpSadtQueryVariables {
  profissionalId: string;
  where?: TissGuiaSpSadtWhereInput | null;
  orderBy?: TissGuiaSpSadtOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissGuiaSpSadtMutation
// ====================================================

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_procedimentosExecutados_equipeSadt {
  __typename: "TissGuiaSpSadtProcedimentoExecutadoEquipeSadt";
  id: string;
  grauPart: string | null;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_procedimentosExecutados {
  __typename: "TissGuiaSpSadtProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_procedimentosExecutados_equipeSadt[] | null;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_outrasDespesas {
  __typename: "TissGuiaSpSadtOutraDepesa";
  id: string;
  sequencialItem: number;
  codigoDespesa: string;
  itemVinculado: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial: any | null;
  servicosHoraFinal: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA: string | null;
  servicosCodigoRefFabricante: string | null;
  servicosAutorizacaoFuncionamento: string | null;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal: string | null;
  autorizacaoNumeroGuiaOperadora: string | null;
  autorizacaoData: any | null;
  autorizacaoSenha: string | null;
  autorizacaoDataValidadeSenha: any | null;
  autorizacaoAusenciaCodValidacao: string | null;
  autorizacaoCodValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  solicitanteCodigoPrestadorNaOperadora: string | null;
  solicitanteCpf: string | null;
  solicitanteCnpj: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica: string | null;
  executanteCodigoPrestadorNaOperadora: string | null;
  executanteCpf: string | null;
  executanteCnpj: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta: string | null;
  atendimentoMotivoEncerramento: string | null;
  observacao: string | null;
  totalValorProcedimentos: number | null;
  totalValorDiarias: number | null;
  totalValorTaxasAlugueis: number | null;
  totalValorMateriais: number | null;
  totalValorMedicamentos: number | null;
  totalValorOPME: number | null;
  totalValorGasesMedicinais: number | null;
  totalValorTotalGeral: number;
  procedimentosExecutados: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_procedimentosExecutados[] | null;
  outrasDespesas: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_outrasDespesas[] | null;
  paciente: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_paciente;
  convenio: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_convenio;
  lote: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_lote | null;
  profissional: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt_profissional;
}

export interface IUpdateTissGuiaSpSadtMutation {
  updateTissGuiaSpSadt: IUpdateTissGuiaSpSadtMutation_updateTissGuiaSpSadt | null;
}

export interface IUpdateTissGuiaSpSadtMutationVariables {
  id: string;
  data: TissGuiaSpSadtDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissLoteMutation
// ====================================================

export interface ICreateTissLoteMutation_createTissLote_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ICreateTissLoteMutation_createTissLote_guiasConsultas {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ICreateTissLoteMutation_createTissLote_guiasHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ICreateTissLoteMutation_createTissLote_guiasSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ICreateTissLoteMutation_createTissLote_guiasOdontologicas {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ICreateTissLoteMutation_createTissLote_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ICreateTissLoteMutation_createTissLote {
  __typename: "TissLote";
  id: string;
  createdAt: any;
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora: string | null;
  prestadorCNPJ: string | null;
  prestadorCPF: string | null;
  registroANS: string;
  numeroLote: string;
  hash: string | null;
  xml: string | null;
  xmlFileName: string | null;
  versao: string;
  convenio: ICreateTissLoteMutation_createTissLote_convenio;
  guiasConsultas: ICreateTissLoteMutation_createTissLote_guiasConsultas[] | null;
  guiasHonorarios: ICreateTissLoteMutation_createTissLote_guiasHonorarios[] | null;
  guiasSpSadt: ICreateTissLoteMutation_createTissLote_guiasSpSadt[] | null;
  guiasOdontologicas: ICreateTissLoteMutation_createTissLote_guiasOdontologicas[] | null;
  profissional: ICreateTissLoteMutation_createTissLote_profissional;
}

export interface ICreateTissLoteMutation {
  createTissLote: ICreateTissLoteMutation_createTissLote;
}

export interface ICreateTissLoteMutationVariables {
  profissionalId: string;
  data: TissLoteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissLoteMutation
// ====================================================

export interface IDeleteTissLoteMutation_deleteTissLote {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissLoteMutation {
  deleteTissLote: IDeleteTissLoteMutation_deleteTissLote;
}

export interface IDeleteTissLoteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissLoteQuery
// ====================================================

export interface ITissLoteQuery_tissLote_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissLoteQuery_tissLote_guiasConsultas {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ITissLoteQuery_tissLote_guiasHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ITissLoteQuery_tissLote_guiasSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ITissLoteQuery_tissLote_guiasOdontologicas {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ITissLoteQuery_tissLote_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissLoteQuery_tissLote {
  __typename: "TissLote";
  id: string;
  createdAt: any;
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora: string | null;
  prestadorCNPJ: string | null;
  prestadorCPF: string | null;
  registroANS: string;
  numeroLote: string;
  hash: string | null;
  xml: string | null;
  xmlFileName: string | null;
  versao: string;
  convenio: ITissLoteQuery_tissLote_convenio;
  guiasConsultas: ITissLoteQuery_tissLote_guiasConsultas[] | null;
  guiasHonorarios: ITissLoteQuery_tissLote_guiasHonorarios[] | null;
  guiasSpSadt: ITissLoteQuery_tissLote_guiasSpSadt[] | null;
  guiasOdontologicas: ITissLoteQuery_tissLote_guiasOdontologicas[] | null;
  profissional: ITissLoteQuery_tissLote_profissional;
}

export interface ITissLoteQuery {
  tissLote: ITissLoteQuery_tissLote | null;
}

export interface ITissLoteQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissLotesQuery
// ====================================================

export interface ITissLotesQuery_tissLotes_nodes_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissLotesQuery_tissLotes_nodes_guiasConsultas {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ITissLotesQuery_tissLotes_nodes_guiasHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ITissLotesQuery_tissLotes_nodes_guiasSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ITissLotesQuery_tissLotes_nodes_guiasOdontologicas {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ITissLotesQuery_tissLotes_nodes_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissLotesQuery_tissLotes_nodes {
  __typename: "TissLote";
  id: string;
  createdAt: any;
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora: string | null;
  prestadorCNPJ: string | null;
  prestadorCPF: string | null;
  registroANS: string;
  numeroLote: string;
  hash: string | null;
  xml: string | null;
  xmlFileName: string | null;
  versao: string;
  convenio: ITissLotesQuery_tissLotes_nodes_convenio;
  guiasConsultas: ITissLotesQuery_tissLotes_nodes_guiasConsultas[] | null;
  guiasHonorarios: ITissLotesQuery_tissLotes_nodes_guiasHonorarios[] | null;
  guiasSpSadt: ITissLotesQuery_tissLotes_nodes_guiasSpSadt[] | null;
  guiasOdontologicas: ITissLotesQuery_tissLotes_nodes_guiasOdontologicas[] | null;
  profissional: ITissLotesQuery_tissLotes_nodes_profissional;
}

export interface ITissLotesQuery_tissLotes {
  __typename: "TissLotesResult";
  nodes: ITissLotesQuery_tissLotes_nodes[];
}

export interface ITissLotesQuery {
  tissLotes: ITissLotesQuery_tissLotes;
}

export interface ITissLotesQueryVariables {
  profissionalId: string;
  where?: TissLoteWhereInput | null;
  orderBy?: TissLoteOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissLoteMutation
// ====================================================

export interface IUpdateTissLoteMutation_updateTissLote_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IUpdateTissLoteMutation_updateTissLote_guiasConsultas {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IUpdateTissLoteMutation_updateTissLote_guiasHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IUpdateTissLoteMutation_updateTissLote_guiasSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IUpdateTissLoteMutation_updateTissLote_guiasOdontologicas {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IUpdateTissLoteMutation_updateTissLote_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IUpdateTissLoteMutation_updateTissLote {
  __typename: "TissLote";
  id: string;
  createdAt: any;
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora: string | null;
  prestadorCNPJ: string | null;
  prestadorCPF: string | null;
  registroANS: string;
  numeroLote: string;
  hash: string | null;
  xml: string | null;
  xmlFileName: string | null;
  versao: string;
  convenio: IUpdateTissLoteMutation_updateTissLote_convenio;
  guiasConsultas: IUpdateTissLoteMutation_updateTissLote_guiasConsultas[] | null;
  guiasHonorarios: IUpdateTissLoteMutation_updateTissLote_guiasHonorarios[] | null;
  guiasSpSadt: IUpdateTissLoteMutation_updateTissLote_guiasSpSadt[] | null;
  guiasOdontologicas: IUpdateTissLoteMutation_updateTissLote_guiasOdontologicas[] | null;
  profissional: IUpdateTissLoteMutation_updateTissLote_profissional;
}

export interface IUpdateTissLoteMutation {
  updateTissLote: IUpdateTissLoteMutation_updateTissLote | null;
}

export interface IUpdateTissLoteMutationVariables {
  id: string;
  data: TissLoteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissNextGuiaQuery
// ====================================================

export interface ITissNextGuiaQuery {
  tissNextGuia: number;
}

export interface ITissNextGuiaQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissNextLoteQuery
// ====================================================

export interface ITissNextLoteQuery {
  tissNextLote: number;
}

export interface ITissNextLoteQueryVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ICreateTissProcedimentoMutation
// ====================================================

export interface ICreateTissProcedimentoMutation_createTissProcedimento {
  __typename: "TissProcedimento";
  id: string;
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

export interface ICreateTissProcedimentoMutation {
  createTissProcedimento: ICreateTissProcedimentoMutation_createTissProcedimento;
}

export interface ICreateTissProcedimentoMutationVariables {
  data: TissProcedimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteManyTissProcedimentosMutation
// ====================================================

export interface IDeleteManyTissProcedimentosMutation_deleteManyTissProcedimentos {
  __typename: "DeleteManyResult";
  ids: string[];
}

export interface IDeleteManyTissProcedimentosMutation {
  deleteManyTissProcedimentos: IDeleteManyTissProcedimentosMutation_deleteManyTissProcedimentos;
}

export interface IDeleteManyTissProcedimentosMutationVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IDeleteTissProcedimentoMutation
// ====================================================

export interface IDeleteTissProcedimentoMutation_deleteTissProcedimento {
  __typename: "DeleteResult";
  id: string;
}

export interface IDeleteTissProcedimentoMutation {
  deleteTissProcedimento: IDeleteTissProcedimentoMutation_deleteTissProcedimento;
}

export interface IDeleteTissProcedimentoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissProcedimentoQuery
// ====================================================

export interface ITissProcedimentoQuery_tissProcedimento {
  __typename: "TissProcedimento";
  id: string;
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

export interface ITissProcedimentoQuery {
  tissProcedimento: ITissProcedimentoQuery_tissProcedimento | null;
}

export interface ITissProcedimentoQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ITissProcedimentosQuery
// ====================================================

export interface ITissProcedimentosQuery_tissProcedimentos_nodes {
  __typename: "TissProcedimento";
  id: string;
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

export interface ITissProcedimentosQuery_tissProcedimentos_pageInfo {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ITissProcedimentosQuery_tissProcedimentos {
  __typename: "TissProcedimentosResult";
  nodes: ITissProcedimentosQuery_tissProcedimentos_nodes[];
  pageInfo: ITissProcedimentosQuery_tissProcedimentos_pageInfo;
}

export interface ITissProcedimentosQuery {
  tissProcedimentos: ITissProcedimentosQuery_tissProcedimentos;
}

export interface ITissProcedimentosQueryVariables {
  where?: TissProcedimentoWhereInput | null;
  orderBy?: TissProcedimentoOrderBy[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IUpdateTissProcedimentoMutation
// ====================================================

export interface IUpdateTissProcedimentoMutation_updateTissProcedimento {
  __typename: "TissProcedimento";
  id: string;
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

export interface IUpdateTissProcedimentoMutation {
  updateTissProcedimento: IUpdateTissProcedimentoMutation_updateTissProcedimento | null;
}

export interface IUpdateTissProcedimentoMutationVariables {
  id: string;
  data: TissProcedimentoDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IMyUserFragment
// ====================================================

export interface IMyUserFragment_account {
  __typename: "Account";
  id: string;
}

export interface IMyUserFragment_clinicas_clinica_profissionais {
  __typename: "ClinicaProfissional";
  id: string;
  nome: string;
}

export interface IMyUserFragment_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  profissionais: IMyUserFragment_clinicas_clinica_profissionais[];
}

export interface IMyUserFragment_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IMyUserFragment_clinicas_clinica;
}

export interface IMyUserFragment_profissionais_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IMyUserFragment_profissionais {
  __typename: "User";
  id: string;
  nome: string;
  imagem: IMyUserFragment_profissionais_imagem | null;
}

export interface IMyUserFragment_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IMyUserFragment {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  tipo: UserTipo;
  signUpIncomplete: boolean;
  account: IMyUserFragment_account;
  clinicas: IMyUserFragment_clinicas[] | null;
  profissionais: IMyUserFragment_profissionais[] | null;
  imagem: IMyUserFragment_imagem | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoAgendaFragment
// ====================================================

export interface IConfiguracaoAgendaFragment_procedimentoPadrao {
  __typename: "Procedimento";
  id: string;
  nome: string;
  duracaoMinutos: number;
}

export interface IConfiguracaoAgendaFragment_procedimentosCarencias {
  __typename: "Procedimento";
  id: string;
}

export interface IConfiguracaoAgendaFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoAgendaFragment {
  __typename: "ConfiguracaoAgenda";
  id: string;
  visualizacaoPadrao: ConfiguracaoAgendaVisualizacaoPadrao;
  alertaPeriodoCarencia: boolean;
  mostrarDiasBloqueados: boolean;
  domingo: boolean;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  horaInicio: any;
  horaFim: any;
  horarioAlmoco: boolean;
  horaAlmocoInicio: any;
  horaAlmocoFim: any;
  observacao: string | null;
  procedimentoPadrao: IConfiguracaoAgendaFragment_procedimentoPadrao;
  procedimentosCarencias: IConfiguracaoAgendaFragment_procedimentosCarencias[] | null;
  profissional: IConfiguracaoAgendaFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoEmailFragment
// ====================================================

export interface IConfiguracaoEmailFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoEmailFragment {
  __typename: "ConfiguracaoEmail";
  id: string;
  envioLembreteConsulta: boolean;
  lembreteConsultaAssunto: string;
  lembreteConsultaMensagem: string;
  recebimentoConsultasConfirmadas: boolean;
  recebimentoConsultaModificada: boolean;
  profissional: IConfiguracaoEmailFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoEmailAniversariantesFragment
// ====================================================

export interface IConfiguracaoEmailAniversariantesFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoEmailAniversariantesFragment {
  __typename: "ConfiguracaoEmailAniversariantes";
  id: string;
  enviar: boolean;
  assunto: string;
  mensagem: string;
  profissional: IConfiguracaoEmailAniversariantesFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoFinanceiraFragment
// ====================================================

export interface IConfiguracaoFinanceiraFragment_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
}

export interface IConfiguracaoFinanceiraFragment_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface IConfiguracaoFinanceiraFragment {
  __typename: "ConfiguracaoFinanceira";
  id: string;
  formaPagamento: FormaPagamento;
  recebimentoLancamento: boolean;
  recebimentoLancarAPartir: ConfiguracaoFinanceiraRecebimentoLancarAPartir;
  contaFinanceira: IConfiguracaoFinanceiraFragment_contaFinanceira;
  centroCusto: IConfiguracaoFinanceiraFragment_centroCusto | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoImpressaoFragment
// ====================================================

export interface IConfiguracaoImpressaoFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoImpressaoFragment_logo {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IConfiguracaoImpressaoFragment {
  __typename: "ConfiguracaoImpressao";
  id: string;
  tamanhoPagina: ConfiguracaoImpressaoTamanhoPagina;
  tamanhoFonte: number;
  margem: ConfiguracaoImpressaoMargem;
  logoPosition: ConfiguracaoImpressaoLogoPosition;
  cabecalho: boolean;
  assinatura: boolean;
  numeracaoAutomatica: boolean;
  rodape: boolean;
  rodapeTexto: string | null;
  profissional: IConfiguracaoImpressaoFragment_profissional;
  logo: IConfiguracaoImpressaoFragment_logo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConfiguracaoSmsFragment
// ====================================================

export interface IConfiguracaoSmsFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IConfiguracaoSmsFragment {
  __typename: "ConfiguracaoSms";
  id: string;
  enviar: boolean;
  periodoEnvio: ConfiguracaoSmsPeriodoEnvio;
  mensagem: string;
  profissional: IConfiguracaoSmsFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConvenioFragment
// ====================================================

export interface IConvenioFragment_planos_profissionais_profissional {
  __typename: "User";
  id: string;
}

export interface IConvenioFragment_planos_profissionais {
  __typename: "ConvenioPlanoProfissional";
  id: string;
  periodoCarenciaDias: number;
  profissional: IConvenioFragment_planos_profissionais_profissional;
}

export interface IConvenioFragment_planos {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
  profissionais: IConvenioFragment_planos_profissionais[] | null;
}

export interface IConvenioFragment_profissionais_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IConvenioFragment_profissionais {
  __typename: "ConvenioProfissional";
  id: string;
  codigoOperadora: string | null;
  profissional: IConvenioFragment_profissionais_profissional;
}

export interface IConvenioFragment {
  __typename: "Convenio";
  id: string;
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  particular: boolean;
  planos: IConvenioFragment_planos[] | null;
  profissionais: IConvenioFragment_profissionais[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IDashboardPacienteFragment
// ====================================================

export interface IDashboardPacienteFragment_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IDashboardPacienteFragment {
  __typename: "Paciente";
  id: string;
  nome: string;
  dataNascimento: any | null;
  celular: string | null;
  telefoneCasa: string | null;
  imagem: IDashboardPacienteFragment_imagem | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ICategoriaFinanceiraFragment
// ====================================================

export interface ICategoriaFinanceiraFragment_parent {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriaFinanceiraFragment_children {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ICategoriaFinanceiraFragment {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
  tipo: TransacaoTipo;
  parent: ICategoriaFinanceiraFragment_parent | null;
  children: ICategoriaFinanceiraFragment_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ICentroCustoFragment
// ====================================================

export interface ICentroCustoFragment {
  __typename: "CentroCusto";
  id: string;
  nome: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IContaFinanceiraFragment
// ====================================================

export interface IContaFinanceiraFragment {
  __typename: "ContaFinanceira";
  id: string;
  nome: string;
  banco: string | null;
  tipo: ContaFinanceiraTipo;
  saldo: number;
  saldoInicial: number;
  dataSaldoInicial: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITransacaoFragment
// ====================================================

export interface ITransacaoFragment_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface ITransacaoFragment_categoria {
  __typename: "CategoriaFinanceira";
  id: string;
  nome: string;
}

export interface ITransacaoFragment_profissional {
  __typename: "User";
  id: string;
}

export interface ITransacaoFragment_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacaoFragment_paciente {
  __typename: "Paciente";
  id: string;
}

export interface ITransacaoFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITransacaoFragment_centroCusto {
  __typename: "CentroCusto";
  id: string;
}

export interface ITransacaoFragment_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface ITransacaoFragment_agendamento_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
}

export interface ITransacaoFragment_agendamento_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: ITransacaoFragment_agendamento_procedimentos_procedimento;
}

export interface ITransacaoFragment_agendamento {
  __typename: "Agendamento";
  id: string;
  desconto: number;
  procedimentos: ITransacaoFragment_agendamento_procedimentos[] | null;
}

export interface ITransacaoFragment {
  __typename: "Transacao";
  id: string;
  descricao: string;
  valor: number;
  tipo: TransacaoTipo;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  dataExtrato: any;
  formaPagamento: FormaPagamento | null;
  recorrenciaIndex: number | null;
  saldoInicial: boolean;
  transferencia: boolean;
  contaFinanceira: ITransacaoFragment_contaFinanceira;
  categoria: ITransacaoFragment_categoria | null;
  profissional: ITransacaoFragment_profissional | null;
  procedimento: ITransacaoFragment_procedimento | null;
  paciente: ITransacaoFragment_paciente | null;
  convenio: ITransacaoFragment_convenio | null;
  centroCusto: ITransacaoFragment_centroCusto | null;
  recorrencia: ITransacaoFragment_recorrencia | null;
  agendamento: ITransacaoFragment_agendamento | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPageInfoFragment
// ====================================================

export interface IPageInfoFragment {
  __typename: "PageInfo";
  skip: number;
  take: number;
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ICid10LookupFragment
// ====================================================

export interface ICid10LookupFragment {
  __typename: "Cid10Lookup";
  id: string;
  capitulo: number | null;
  categoria: string | null;
  codigo: string;
  descricao: string;
  grupo: string | null;
  tipo: Cid10Tipo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IConvenioLookupFragment
// ====================================================

export interface IConvenioLookupFragment {
  __typename: "ConvenioLookup";
  id: string;
  nomeFantasia: string;
  razaoSocial: string;
  registroAns: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITussLookupFragment
// ====================================================

export interface ITussLookupFragment {
  __typename: "TussLookup";
  id: string;
  codigo: string;
  nome: string;
  tabela: TussLookupTabela;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPacienteFragment
// ====================================================

export interface IPacienteFragment_parentes {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
}

export interface IPacienteFragment_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IPacienteFragment_convenios_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IPacienteFragment_convenios {
  __typename: "PacienteConvenio";
  id: string;
  numeroCarteirinha: string;
  convenio: IPacienteFragment_convenios_convenio;
  acomodacao: string | null;
  vencimento: any | null;
  semVencimento: boolean | null;
  plano: IPacienteFragment_convenios_plano | null;
}

export interface IPacienteFragment_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IPacienteFragment {
  __typename: "Paciente";
  id: string;
  createdAt: any;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  codigo: string | null;
  dataNascimento: any | null;
  sexo: Sexo | null;
  email: string | null;
  observacao: string | null;
  comoConheceu: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  telefoneTrabalho: string | null;
  aceitaSms: boolean;
  naturalidade: string | null;
  naturalidadeUf: string | null;
  nacionalidade: string | null;
  etnia: string | null;
  cpf: string | null;
  rg: string | null;
  religiao: string | null;
  estadoCivil: string | null;
  escolaridade: string | null;
  profissao: string | null;
  obito: boolean;
  causaMortis: string | null;
  cns: string | null;
  informacoesAdicionais: string | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  parentes: IPacienteFragment_parentes[] | null;
  convenios: IPacienteFragment_convenios[] | null;
  imagem: IPacienteFragment_imagem | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPacienteConvenioFragment
// ====================================================

export interface IPacienteConvenioFragment_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IPacienteConvenioFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IPacienteConvenioFragment_plano {
  __typename: "ConvenioPlano";
  id: string;
  nome: string;
}

export interface IPacienteConvenioFragment {
  __typename: "PacienteConvenio";
  id: string;
  vencimento: any | null;
  semVencimento: boolean | null;
  numeroCarteirinha: string;
  acomodacao: string | null;
  paciente: IPacienteConvenioFragment_paciente;
  convenio: IPacienteConvenioFragment_convenio;
  plano: IPacienteConvenioFragment_plano | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPacienteParenteFragment
// ====================================================

export interface IPacienteParenteFragment_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IPacienteParenteFragment {
  __typename: "PacienteParente";
  id: string;
  nome: string;
  parentesco: string;
  profissao: string | null;
  paciente: IPacienteParenteFragment_paciente;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAgendamentoFragment
// ====================================================

export interface IAgendamentoFragment_procedimentos_procedimento {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
}

export interface IAgendamentoFragment_procedimentos {
  __typename: "AgendamentoProcedimento";
  id: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  procedimento: IAgendamentoFragment_procedimentos_procedimento;
}

export interface IAgendamentoFragment_paciente_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAgendamentoFragment_paciente {
  __typename: "Paciente";
  id: string;
  incompleto: boolean;
  homonimo: boolean;
  nome: string;
  celular: string | null;
  telefoneCasa: string | null;
  email: string | null;
  sexo: Sexo | null;
  dataNascimento: any | null;
  imagem: IAgendamentoFragment_paciente_imagem | null;
}

export interface IAgendamentoFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IAgendamentoFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentoFragment_createdBy {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAgendamentoFragment_recorrencia {
  __typename: "AgendamentoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentoFragment_transacoes_recorrencia {
  __typename: "TransacaoRecorrencia";
  id: string;
  numero: number;
}

export interface IAgendamentoFragment_transacoes_contaFinanceira {
  __typename: "ContaFinanceira";
  id: string;
}

export interface IAgendamentoFragment_transacoes {
  __typename: "Transacao";
  id: string;
  vencimento: any;
  pago: boolean;
  dataPagamento: any | null;
  formaPagamento: FormaPagamento | null;
  valor: number;
  recorrenciaIndex: number | null;
  recorrencia: IAgendamentoFragment_transacoes_recorrencia | null;
  contaFinanceira: IAgendamentoFragment_transacoes_contaFinanceira;
}

export interface IAgendamentoFragment_atendimento {
  __typename: "Atendimento";
  id: string;
}

export interface IAgendamentoFragment_tissGuiaConsulta {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface IAgendamentoFragment_tissGuiaHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface IAgendamentoFragment_tissGuiaOdontologica {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface IAgendamentoFragment_tissGuiaSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface IAgendamentoFragment {
  __typename: "Agendamento";
  id: string;
  createdAt: any;
  bloqueado: boolean;
  primeiraVez: boolean;
  data: any;
  horaInicial: any;
  horaFinal: any;
  diaTodo: boolean;
  observacao: string | null;
  status: AgendamentoStatus | null;
  subtotal: number;
  desconto: number;
  valorTotal: number;
  procedimentos: IAgendamentoFragment_procedimentos[] | null;
  paciente: IAgendamentoFragment_paciente | null;
  convenio: IAgendamentoFragment_convenio | null;
  profissional: IAgendamentoFragment_profissional;
  createdBy: IAgendamentoFragment_createdBy | null;
  recorrencia: IAgendamentoFragment_recorrencia | null;
  transacoes: IAgendamentoFragment_transacoes[] | null;
  atendimento: IAgendamentoFragment_atendimento | null;
  tissGuiaConsulta: IAgendamentoFragment_tissGuiaConsulta | null;
  tissGuiaHonorarios: IAgendamentoFragment_tissGuiaHonorarios | null;
  tissGuiaOdontologica: IAgendamentoFragment_tissGuiaOdontologica | null;
  tissGuiaSpSadt: IAgendamentoFragment_tissGuiaSpSadt | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAtendimentoFragment
// ====================================================

export interface IAtendimentoFragment_preAtendimento {
  __typename: "AtendimentoPreAtendimento";
  id: string;
  peso: number | null;
  altura: number | null;
  imc: number | null;
  pressaoArterialSistolica: number | null;
  pressaoArterialDiastolica: number | null;
  frequenciaCardiaca: number | null;
  frequenciaRespiratoria: number | null;
  temperatura: number | null;
  glicemiaCapilar: number | null;
  dataUltimaMenstruacao: any | null;
  observacao: string | null;
}

export interface IAtendimentoFragment_hipoteseDiagnostica_cids {
  __typename: "AtendimentoHipoteseDiagnosticaCid";
  id: string;
  codigo: string;
  descricao: string;
}

export interface IAtendimentoFragment_hipoteseDiagnostica {
  __typename: "AtendimentoHipoteseDiagnostica";
  id: string;
  cids: IAtendimentoFragment_hipoteseDiagnostica_cids[];
  observacao: string | null;
}

export interface IAtendimentoFragment_prescricoes_medicamentos {
  __typename: "AtendimentoPrescricaoMedicamento";
  id: string;
  medicamento: string;
  quantidade: string;
  posologia: string | null;
  observacao: string | null;
}

export interface IAtendimentoFragment_prescricoes {
  __typename: "AtendimentoPrescricao";
  id: string;
  data: any;
  controleEspecial: boolean;
  medicamentos: IAtendimentoFragment_prescricoes_medicamentos[];
}

export interface IAtendimentoFragment_atestados {
  __typename: "AtendimentoAtestado";
  id: string;
  data: any;
  titulo: string;
  texto: string;
}

export interface IAtendimentoFragment_exames_items {
  __typename: "AtendimentoExameItem";
  id: string;
  codigo: string;
  nome: string;
  quantidade: number;
  observacao: string | null;
}

export interface IAtendimentoFragment_exames {
  __typename: "AtendimentoExame";
  id: string;
  data: string;
  indicacaoClinica: string | null;
  tipo: AtendimentoExameTipo;
  items: IAtendimentoFragment_exames_items[];
}

export interface IAtendimentoFragment_eventos_items {
  __typename: "AtendimentoEventoItem";
  id: string;
  type: AtendimentoEventoItemType;
  label: string;
  valueText: string | null;
  valueNumber: number | null;
  valueList: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
}

export interface IAtendimentoFragment_eventos {
  __typename: "AtendimentoEvento";
  id: string;
  nome: string;
  prontuarioSecaoId: string;
  items: IAtendimentoFragment_eventos_items[];
}

export interface IAtendimentoFragment_observacoes {
  __typename: "AtendimentoObservao";
  id: string;
  profissionalId: string;
  profissionalNome: string;
  texto: string;
  data: string;
}

export interface IAtendimentoFragment_files {
  __typename: "FileUpload";
  id: string;
  contentType: string;
  description: string | null;
  url: string;
  thumbnailUrl: string | null;
}

export interface IAtendimentoFragment_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface IAtendimentoFragment_paciente {
  __typename: "Paciente";
  id: string;
}

export interface IAtendimentoFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface IAtendimentoFragment {
  __typename: "Atendimento";
  id: string;
  dataInicial: any;
  dataFinal: any;
  preAtendimento: IAtendimentoFragment_preAtendimento | null;
  hipoteseDiagnostica: IAtendimentoFragment_hipoteseDiagnostica | null;
  prescricoes: IAtendimentoFragment_prescricoes[] | null;
  atestados: IAtendimentoFragment_atestados[] | null;
  exames: IAtendimentoFragment_exames[] | null;
  eventos: IAtendimentoFragment_eventos[] | null;
  observacoes: IAtendimentoFragment_observacoes[] | null;
  files: IAtendimentoFragment_files[];
  agendamento: IAtendimentoFragment_agendamento | null;
  paciente: IAtendimentoFragment_paciente;
  profissional: IAtendimentoFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAtendimentoCacheFragment
// ====================================================

export interface IAtendimentoCacheFragment {
  __typename: "AtendimentoCache";
  id: string;
  dataInicial: any;
  cachePayload: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAtestadoModeloFragment
// ====================================================

export interface IAtestadoModeloFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IAtestadoModeloFragment {
  __typename: "AtestadoModelo";
  id: string;
  nome: string;
  titulo: string;
  texto: string;
  profissional: IAtestadoModeloFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IExameModeloFragment
// ====================================================

export interface IExameModeloFragment_items {
  __typename: "ExameModeloItem";
  id: string;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao: string | null;
  ordem: number;
}

export interface IExameModeloFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IExameModeloFragment {
  __typename: "ExameModelo";
  id: string;
  nome: string;
  items: IExameModeloFragment_items[] | null;
  profissional: IExameModeloFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IListaEsperaFragment
// ====================================================

export interface IListaEsperaFragment_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
}

export interface IListaEsperaFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IListaEsperaFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IListaEsperaFragment {
  __typename: "ListaEspera";
  id: string;
  nome: string | null;
  email: string | null;
  celular: string | null;
  telefoneCasa: string | null;
  observacao: string | null;
  ordem: number;
  paciente: IListaEsperaFragment_paciente | null;
  convenio: IListaEsperaFragment_convenio | null;
  profissional: IListaEsperaFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPrescricaoModeloFragment
// ====================================================

export interface IPrescricaoModeloFragment_medicamentos {
  __typename: "PrescricaoModeloMedicamento";
  id: string;
  medicamento: string;
  quantidade: string | null;
  posologia: string | null;
  observacao: string | null;
}

export interface IPrescricaoModeloFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IPrescricaoModeloFragment {
  __typename: "PrescricaoModelo";
  id: string;
  nome: string;
  medicamentos: IPrescricaoModeloFragment_medicamentos[] | null;
  profissional: IPrescricaoModeloFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IProcedimentoFragment
// ====================================================

export interface IProcedimentoFragment_convenios_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface IProcedimentoFragment_convenios {
  __typename: "ProcedimentoConvenio";
  id: string;
  valor: number;
  tissCodigo: string | null;
  tissNome: string | null;
  tissTabela: TissTabela | null;
  convenio: IProcedimentoFragment_convenios_convenio;
}

export interface IProcedimentoFragment_despesas {
  __typename: "ProcedimentoDespesa";
  id: string;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface IProcedimentoFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IProcedimentoFragment {
  __typename: "Procedimento";
  id: string;
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo: string | null;
  valor: number | null;
  convenios: IProcedimentoFragment_convenios[] | null;
  despesas: IProcedimentoFragment_despesas[] | null;
  profissional: IProcedimentoFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IProntuarioSecaoFragment
// ====================================================

export interface IProntuarioSecaoFragment_fields {
  __typename: "ProntuarioSecaoField";
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix: string | null;
  decimalPlaces: number | null;
  items: string[] | null;
}

export interface IProntuarioSecaoFragment_profissional {
  __typename: "User";
  id: string;
}

export interface IProntuarioSecaoFragment {
  __typename: "ProntuarioSecao";
  id: string;
  nome: string;
  ordem: number;
  fields: IProntuarioSecaoFragment_fields[] | null;
  profissional: IProntuarioSecaoFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ICepFragment
// ====================================================

export interface ICepFragment {
  __typename: "Cep";
  id: string;
  cep: string;
  logradouro: string | null;
  complemento: string | null;
  bairro: string | null;
  cidade: string | null;
  uf: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAccountFragment
// ====================================================

export interface IAccountFragment {
  __typename: "Account";
  id: string;
  permiteEmail: boolean;
  permiteSms: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IAssinaturaFragment
// ====================================================

export interface IAssinaturaFragment_plano {
  __typename: "Plano";
  id: string;
}

export interface IAssinaturaFragment_cobrancas {
  __typename: "Cobranca";
  id: string;
}

export interface IAssinaturaFragment {
  __typename: "Assinatura";
  id: string;
  validUntil: any;
  cancelado: boolean | null;
  plano: IAssinaturaFragment_plano;
  cobrancas: IAssinaturaFragment_cobrancas[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IClinicaFragment
// ====================================================

export interface IClinicaFragment_account {
  __typename: "Account";
  id: string;
}

export interface IClinicaFragment {
  __typename: "Clinica";
  id: string;
  nome: string;
  email: string | null;
  cnes: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  account: IClinicaFragment_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ICobrancaFragment
// ====================================================

export interface ICobrancaFragment_assinatura {
  __typename: "Assinatura";
  id: string;
}

export interface ICobrancaFragment {
  __typename: "Cobranca";
  id: string;
  data: any;
  descricao: string;
  valor: number;
  saldo: number;
  assinatura: ICobrancaFragment_assinatura;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IContatoFragment
// ====================================================

export interface IContatoFragment {
  __typename: "Contato";
  id: string;
  nome: string;
  email: string | null;
  telefones: string[] | null;
  enderecoCep: string | null;
  enderecoLogradouro: string | null;
  enderecoNumero: string | null;
  enderecoComplemento: string | null;
  enderecoBairro: string | null;
  enderecoCidade: string | null;
  enderecoUf: string | null;
  enderecoPais: string | null;
  observacao: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IFileUploadFragment
// ====================================================

export interface IFileUploadFragment {
  __typename: "FileUpload";
  id: string;
  createdAt: any;
  updatedAt: any;
  extension: string;
  contentType: string;
  url: string;
  description: string | null;
  thumbnailUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IPlanoFragment
// ====================================================

export interface IPlanoFragment {
  __typename: "Plano";
  id: string;
  nome: string;
  valor: number;
  validoAte: any;
  cancelado: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IUserFragment
// ====================================================

export interface IUserFragment_profissionais {
  __typename: "User";
  id: string;
}

export interface IUserFragment_account {
  __typename: "Account";
  id: string;
}

export interface IUserFragment_clinicas_clinica {
  __typename: "Clinica";
  id: string;
  nome: string;
}

export interface IUserFragment_clinicas {
  __typename: "UserClinica";
  id: string;
  adminClinica: boolean;
  clinica: IUserFragment_clinicas_clinica;
}

export interface IUserFragment_imagem {
  __typename: "FileUpload";
  id: string;
  url: string;
  thumbnailUrl: string | null;
}

export interface IUserFragment {
  __typename: "User";
  id: string;
  nome: string;
  email: string;
  adminAccount: boolean;
  cbo: string | null;
  celular: string | null;
  profissao: string | null;
  conselho: string | null;
  conselhoUf: string | null;
  conselhoRegistro: string | null;
  rqe: string | null;
  sexo: Sexo | null;
  tipo: UserTipo;
  tratamento: string | null;
  profissionais: IUserFragment_profissionais[] | null;
  account: IUserFragment_account;
  clinicas: IUserFragment_clinicas[] | null;
  imagem: IUserFragment_imagem | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissGuiaConsultaFragment
// ====================================================

export interface ITissGuiaConsultaFragment_agendamento {
  __typename: "Agendamento";
  id: string;
}

export interface ITissGuiaConsultaFragment_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaFragment_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaConsultaFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaConsultaFragment {
  __typename: "TissGuiaConsulta";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora: string | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  pacienteDataNascimento: any | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCpf: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao: string | null;
  agendamento: ITissGuiaConsultaFragment_agendamento | null;
  paciente: ITissGuiaConsultaFragment_paciente;
  convenio: ITissGuiaConsultaFragment_convenio;
  lote: ITissGuiaConsultaFragment_lote | null;
  profissional: ITissGuiaConsultaFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissGuiaHonorariosFragment
// ====================================================

export interface ITissGuiaHonorariosFragment_procedimentosRealizados_profissionais {
  __typename: "TissGuiaHonorariosProcedimentoProfissional";
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface ITissGuiaHonorariosFragment_procedimentosRealizados {
  __typename: "TissGuiaHonorariosProcedimentoRealizado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: ITissGuiaHonorariosFragment_procedimentosRealizados_profissionais[];
}

export interface ITissGuiaHonorariosFragment_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosFragment_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaHonorariosFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaHonorariosFragment {
  __typename: "TissGuiaHonorarios";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha: string | null;
  numeroGuiaOperadora: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora: string | null;
  contratadoCnpj: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  observacao: string | null;
  valorTotalHonorarios: number;
  procedimentosRealizados: ITissGuiaHonorariosFragment_procedimentosRealizados[];
  dataEmissaoGuia: any;
  paciente: ITissGuiaHonorariosFragment_paciente;
  convenio: ITissGuiaHonorariosFragment_convenio;
  lote: ITissGuiaHonorariosFragment_lote | null;
  profissional: ITissGuiaHonorariosFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissGuiaOdontologicaFragment
// ====================================================

export interface ITissGuiaOdontologicaFragment_procedimentosExecutados {
  __typename: "TissGuiaOdontologicaProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente: string | null;
  denteRegiaoCodRegiao: string | null;
  denteFace: string | null;
  qtdProc: number;
  qtdUS: number | null;
  valorProc: number;
  valorFranquia: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface ITissGuiaOdontologicaFragment_odontoInicial_dentes {
  __typename: "TissGuiaOdontologicaOdontoInicialDente";
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface ITissGuiaOdontologicaFragment_odontoInicial {
  __typename: "TissGuiaOdontologicaOdontoInicial";
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora: string | null;
  dentes: ITissGuiaOdontologicaFragment_odontoInicial_dentes[];
  doencaPeriodontal: boolean | null;
  alteracaoTecidoMole: boolean | null;
  observacao: string | null;
}

export interface ITissGuiaOdontologicaFragment_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaFragment_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaOdontologicaFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaOdontologicaFragment {
  __typename: "TissGuiaOdontologica";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal: string | null;
  numeroGuiaOperadora: string | null;
  dataAutorizacao: any | null;
  senhaAutorizacao: string | null;
  validadeSenha: any | null;
  ausenciaCodValidacao: string | null;
  codValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  planoBeneficiario: string;
  nomeEmpresa: string | null;
  numeroTelefone: string | null;
  nomeTitular: string | null;
  profissionaisResponsaveisNomeProfSolic: string | null;
  profissionaisResponsaveisCroSolic: string | null;
  profissionaisResponsaveisUfSolic: string | null;
  profissionaisResponsaveisCbosSolic: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2: string | null;
  profissionaisResponsaveisCroExec2: string | null;
  profissionaisResponsaveisUfExec2: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS: number | null;
  valorTotalProc: number;
  valorTotalFranquia: number | null;
  dataTerminoTrat: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  observacao: string | null;
  procedimentosExecutados: ITissGuiaOdontologicaFragment_procedimentosExecutados[];
  odontoInicial: ITissGuiaOdontologicaFragment_odontoInicial | null;
  paciente: ITissGuiaOdontologicaFragment_paciente;
  convenio: ITissGuiaOdontologicaFragment_convenio;
  lote: ITissGuiaOdontologicaFragment_lote | null;
  profissional: ITissGuiaOdontologicaFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissGuiaSpSadtFragment
// ====================================================

export interface ITissGuiaSpSadtFragment_procedimentosExecutados_equipeSadt {
  __typename: "TissGuiaSpSadtProcedimentoExecutadoEquipeSadt";
  id: string;
  grauPart: string | null;
  codigoPrestadorNaOperadora: string | null;
  cpfContratado: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface ITissGuiaSpSadtFragment_procedimentosExecutados {
  __typename: "TissGuiaSpSadtProcedimentoExecutado";
  id: string;
  sequencialItem: number;
  dataExecucao: any;
  horaInicial: any | null;
  horaFinal: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso: string | null;
  tecnicaUtilizada: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt: ITissGuiaSpSadtFragment_procedimentosExecutados_equipeSadt[] | null;
}

export interface ITissGuiaSpSadtFragment_outrasDespesas {
  __typename: "TissGuiaSpSadtOutraDepesa";
  id: string;
  sequencialItem: number;
  codigoDespesa: string;
  itemVinculado: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial: any | null;
  servicosHoraFinal: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA: string | null;
  servicosCodigoRefFabricante: string | null;
  servicosAutorizacaoFuncionamento: string | null;
}

export interface ITissGuiaSpSadtFragment_paciente {
  __typename: "Paciente";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtFragment_lote {
  __typename: "TissLote";
  id: string;
  numeroLote: string;
}

export interface ITissGuiaSpSadtFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissGuiaSpSadtFragment {
  __typename: "TissGuiaSpSadt";
  id: string;
  createdAt: any;
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal: string | null;
  autorizacaoNumeroGuiaOperadora: string | null;
  autorizacaoData: any | null;
  autorizacaoSenha: string | null;
  autorizacaoDataValidadeSenha: any | null;
  autorizacaoAusenciaCodValidacao: string | null;
  autorizacaoCodValidacao: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS: string | null;
  solicitanteCodigoPrestadorNaOperadora: string | null;
  solicitanteCpf: string | null;
  solicitanteCnpj: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica: string | null;
  executanteCodigoPrestadorNaOperadora: string | null;
  executanteCpf: string | null;
  executanteCnpj: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta: string | null;
  atendimentoMotivoEncerramento: string | null;
  observacao: string | null;
  totalValorProcedimentos: number | null;
  totalValorDiarias: number | null;
  totalValorTaxasAlugueis: number | null;
  totalValorMateriais: number | null;
  totalValorMedicamentos: number | null;
  totalValorOPME: number | null;
  totalValorGasesMedicinais: number | null;
  totalValorTotalGeral: number;
  procedimentosExecutados: ITissGuiaSpSadtFragment_procedimentosExecutados[] | null;
  outrasDespesas: ITissGuiaSpSadtFragment_outrasDespesas[] | null;
  paciente: ITissGuiaSpSadtFragment_paciente;
  convenio: ITissGuiaSpSadtFragment_convenio;
  lote: ITissGuiaSpSadtFragment_lote | null;
  profissional: ITissGuiaSpSadtFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissLoteFragment
// ====================================================

export interface ITissLoteFragment_convenio {
  __typename: "Convenio";
  id: string;
  nome: string;
}

export interface ITissLoteFragment_guiasConsultas {
  __typename: "TissGuiaConsulta";
  id: string;
}

export interface ITissLoteFragment_guiasHonorarios {
  __typename: "TissGuiaHonorarios";
  id: string;
}

export interface ITissLoteFragment_guiasSpSadt {
  __typename: "TissGuiaSpSadt";
  id: string;
}

export interface ITissLoteFragment_guiasOdontologicas {
  __typename: "TissGuiaOdontologica";
  id: string;
}

export interface ITissLoteFragment_profissional {
  __typename: "User";
  id: string;
  nome: string;
}

export interface ITissLoteFragment {
  __typename: "TissLote";
  id: string;
  createdAt: any;
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora: string | null;
  prestadorCNPJ: string | null;
  prestadorCPF: string | null;
  registroANS: string;
  numeroLote: string;
  hash: string | null;
  xml: string | null;
  xmlFileName: string | null;
  versao: string;
  convenio: ITissLoteFragment_convenio;
  guiasConsultas: ITissLoteFragment_guiasConsultas[] | null;
  guiasHonorarios: ITissLoteFragment_guiasHonorarios[] | null;
  guiasSpSadt: ITissLoteFragment_guiasSpSadt[] | null;
  guiasOdontologicas: ITissLoteFragment_guiasOdontologicas[] | null;
  profissional: ITissLoteFragment_profissional;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ITissProcedimentoFragment
// ====================================================

export interface ITissProcedimentoFragment {
  __typename: "TissProcedimento";
  id: string;
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgendamentoCancelamentoMotivo {
  DESMARCADO_PELA_CLINICA = "DESMARCADO_PELA_CLINICA",
  DESMARCADO_PELO_PACIENTE = "DESMARCADO_PELO_PACIENTE",
  OUTRO_MOTIVO = "OUTRO_MOTIVO",
  PACIENTE_NAO_COMPARECEU = "PACIENTE_NAO_COMPARECEU",
}

export enum AgendamentoOrderBy {
  bloqueado_ASC = "bloqueado_ASC",
  bloqueado_DESC = "bloqueado_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  data_ASC = "data_ASC",
  data_DESC = "data_DESC",
  horaFinal_ASC = "horaFinal_ASC",
  horaFinal_DESC = "horaFinal_DESC",
  horaInicial_ASC = "horaInicial_ASC",
  horaInicial_DESC = "horaInicial_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  recorrente_ASC = "recorrente_ASC",
  recorrente_DESC = "recorrente_DESC",
  remarcado_ASC = "remarcado_ASC",
  remarcado_DESC = "remarcado_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum AgendamentoStatus {
  AGENDADO = "AGENDADO",
  AGUARDANDO_ATENDIMENTO = "AGUARDANDO_ATENDIMENTO",
  CANCELADO = "CANCELADO",
  CONFIRMADO = "CONFIRMADO",
  DILATANDO_PUPILA = "DILATANDO_PUPILA",
  NAO_COMPARECEU = "NAO_COMPARECEU",
  PACIENTE_ATENDIDO = "PACIENTE_ATENDIDO",
}

export enum AssinaturaOrderBy {
  cancelado_ASC = "cancelado_ASC",
  cancelado_DESC = "cancelado_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  validUntil_ASC = "validUntil_ASC",
  validUntil_DESC = "validUntil_DESC",
}

export enum AtendimentoEventoItemType {
  LIST = "LIST",
  RICH_TEXT = "RICH_TEXT",
  TEXT = "TEXT",
}

export enum AtendimentoExameTipo {
  PARTICULAR = "PARTICULAR",
  SADT = "SADT",
}

export enum AtendimentoOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  dataFinal_ASC = "dataFinal_ASC",
  dataFinal_DESC = "dataFinal_DESC",
  dataInicial_ASC = "dataInicial_ASC",
  dataInicial_DESC = "dataInicial_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum AtestadoModeloOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  texto_ASC = "texto_ASC",
  texto_DESC = "texto_DESC",
  titulo_ASC = "titulo_ASC",
  titulo_DESC = "titulo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum CategoriaFinanceiraOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum CentroCustoOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum Cid10LookupOrderBy {
  capitulo_ASC = "capitulo_ASC",
  capitulo_DESC = "capitulo_DESC",
  categoria_ASC = "categoria_ASC",
  categoria_DESC = "categoria_DESC",
  codigo_ASC = "codigo_ASC",
  codigo_DESC = "codigo_DESC",
  descricao_ASC = "descricao_ASC",
  descricao_DESC = "descricao_DESC",
  grupo_ASC = "grupo_ASC",
  grupo_DESC = "grupo_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
}

export enum Cid10Tipo {
  CAPITULO = "CAPITULO",
  CATEGORIA = "CATEGORIA",
  GRUPO = "GRUPO",
  SUBCATEGORIA = "SUBCATEGORIA",
}

export enum ClinicaOrderBy {
  cnes_ASC = "cnes_ASC",
  cnes_DESC = "cnes_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum CobrancaOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  data_ASC = "data_ASC",
  data_DESC = "data_DESC",
  descricao_ASC = "descricao_ASC",
  descricao_DESC = "descricao_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  saldo_ASC = "saldo_ASC",
  saldo_DESC = "saldo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  valor_ASC = "valor_ASC",
  valor_DESC = "valor_DESC",
}

export enum ConfiguracaoAgendaVisualizacaoPadrao {
  DIA = "DIA",
  SEMANA = "SEMANA",
}

export enum ConfiguracaoEmailAniversariantesImageName {
  BALLOONS = "BALLOONS",
  BEER = "BEER",
  BIRTHDAY = "BIRTHDAY",
  CAKE = "CAKE",
  DANCING = "DANCING",
  GIFT = "GIFT",
  NONE = "NONE",
}

export enum ConfiguracaoFinanceiraRecebimentoLancarAPartir {
  AGENDA = "AGENDA",
  PRONTUARIOS = "PRONTUARIOS",
}

export enum ConfiguracaoImpressaoLogoPosition {
  LEFT = "LEFT",
  NONE = "NONE",
  RIGHT = "RIGHT",
}

export enum ConfiguracaoImpressaoMargem {
  ESTREITA = "ESTREITA",
  LARGA = "LARGA",
  PADRAO = "PADRAO",
}

export enum ConfiguracaoImpressaoTamanhoPagina {
  A4 = "A4",
  A5 = "A5",
}

export enum ConfiguracaoSmsPeriodoEnvio {
  AS_13_HORAS_DIA_ANTERIOR = "AS_13_HORAS_DIA_ANTERIOR",
  AS_18_HORAS_DIA_ANTERIOR = "AS_18_HORAS_DIA_ANTERIOR",
  AS_21_HORAS_DIA_ANTERIOR = "AS_21_HORAS_DIA_ANTERIOR",
  AS_8_HORAS_DIA_ANTERIOR = "AS_8_HORAS_DIA_ANTERIOR",
}

export enum ContaFinanceiraOrderBy {
  banco_ASC = "banco_ASC",
  banco_DESC = "banco_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  dataSaldoInicial_ASC = "dataSaldoInicial_ASC",
  dataSaldoInicial_DESC = "dataSaldoInicial_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  saldoInicial_ASC = "saldoInicial_ASC",
  saldoInicial_DESC = "saldoInicial_DESC",
  saldo_ASC = "saldo_ASC",
  saldo_DESC = "saldo_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ContaFinanceiraTipo {
  CAIXA_INTERNO = "CAIXA_INTERNO",
  CARTAO_CREDITO = "CARTAO_CREDITO",
  CONTA_CORRENTE = "CONTA_CORRENTE",
  CONTA_POUPANCA = "CONTA_POUPANCA",
  INVESTIMENTO = "INVESTIMENTO",
  MEIOS_RECEBIMENTO = "MEIOS_RECEBIMENTO",
  OUTROS = "OUTROS",
}

export enum ContatoOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ConvenioLookupOrderBy {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nomeFantasia_ASC = "nomeFantasia_ASC",
  nomeFantasia_DESC = "nomeFantasia_DESC",
  razaoSocial_ASC = "razaoSocial_ASC",
  razaoSocial_DESC = "razaoSocial_DESC",
  registroAns_ASC = "registroAns_ASC",
  registroAns_DESC = "registroAns_DESC",
}

export enum ConvenioOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  particular_ASC = "particular_ASC",
  particular_DESC = "particular_DESC",
  periodoCarenciaDias_ASC = "periodoCarenciaDias_ASC",
  periodoCarenciaDias_DESC = "periodoCarenciaDias_DESC",
  registroAns_ASC = "registroAns_ASC",
  registroAns_DESC = "registroAns_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ExameModeloOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum FormaPagamento {
  BOLETO = "BOLETO",
  CARTAO_CREDITO = "CARTAO_CREDITO",
  CARTAO_DEBITO = "CARTAO_DEBITO",
  CHEQUE = "CHEQUE",
  DEPOSITO = "DEPOSITO",
  DINHEIRO = "DINHEIRO",
  TRANSFERENCIA_BANCARIA = "TRANSFERENCIA_BANCARIA",
}

export enum ListaEsperaOrderBy {
  celular_ASC = "celular_ASC",
  celular_DESC = "celular_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  ordem_ASC = "ordem_ASC",
  ordem_DESC = "ordem_DESC",
  telefoneCasa_ASC = "telefoneCasa_ASC",
  telefoneCasa_DESC = "telefoneCasa_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum PacienteOrderBy {
  aceitaSms_ASC = "aceitaSms_ASC",
  aceitaSms_DESC = "aceitaSms_DESC",
  causaMortis_ASC = "causaMortis_ASC",
  causaMortis_DESC = "causaMortis_DESC",
  celular_ASC = "celular_ASC",
  celular_DESC = "celular_DESC",
  cns_ASC = "cns_ASC",
  cns_DESC = "cns_DESC",
  codigo_ASC = "codigo_ASC",
  codigo_DESC = "codigo_DESC",
  comoConheceu_ASC = "comoConheceu_ASC",
  comoConheceu_DESC = "comoConheceu_DESC",
  cpf_ASC = "cpf_ASC",
  cpf_DESC = "cpf_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  dataNascimento_ASC = "dataNascimento_ASC",
  dataNascimento_DESC = "dataNascimento_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  escolaridade_ASC = "escolaridade_ASC",
  escolaridade_DESC = "escolaridade_DESC",
  estadoCivil_ASC = "estadoCivil_ASC",
  estadoCivil_DESC = "estadoCivil_DESC",
  etnia_ASC = "etnia_ASC",
  etnia_DESC = "etnia_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nacionalidade_ASC = "nacionalidade_ASC",
  nacionalidade_DESC = "nacionalidade_DESC",
  naturalidadeUf_ASC = "naturalidadeUf_ASC",
  naturalidadeUf_DESC = "naturalidadeUf_DESC",
  naturalidade_ASC = "naturalidade_ASC",
  naturalidade_DESC = "naturalidade_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  obito_ASC = "obito_ASC",
  obito_DESC = "obito_DESC",
  profissao_ASC = "profissao_ASC",
  profissao_DESC = "profissao_DESC",
  religiao_ASC = "religiao_ASC",
  religiao_DESC = "religiao_DESC",
  rg_ASC = "rg_ASC",
  rg_DESC = "rg_DESC",
  sexo_ASC = "sexo_ASC",
  sexo_DESC = "sexo_DESC",
  telefoneCasa_ASC = "telefoneCasa_ASC",
  telefoneCasa_DESC = "telefoneCasa_DESC",
  telefoneTrabalho_ASC = "telefoneTrabalho_ASC",
  telefoneTrabalho_DESC = "telefoneTrabalho_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum PlanoOrderBy {
  cancelado_ASC = "cancelado_ASC",
  cancelado_DESC = "cancelado_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  validoAte_ASC = "validoAte_ASC",
  validoAte_DESC = "validoAte_DESC",
  valor_ASC = "valor_ASC",
  valor_DESC = "valor_DESC",
}

export enum PrescricaoModeloOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ProcedimentoOrderBy {
  cor_ASC = "cor_ASC",
  cor_DESC = "cor_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  duracaoMinutos_ASC = "duracaoMinutos_ASC",
  duracaoMinutos_DESC = "duracaoMinutos_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  valor_ASC = "valor_ASC",
  valor_DESC = "valor_DESC",
}

export enum ProcedimentoTipo {
  CIRURGIA = "CIRURGIA",
  CONSULTA = "CONSULTA",
  EXAME = "EXAME",
  ODONTOLOGIA = "ODONTOLOGIA",
  RETORNO = "RETORNO",
  SESSAO = "SESSAO",
}

export enum ProntuarioSecaoFieldType {
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  GROUP_CHECKBOX = "GROUP_CHECKBOX",
  NUMBER = "NUMBER",
  RADIO = "RADIO",
  RICH_TEXT = "RICH_TEXT",
  SELECT = "SELECT",
  TEXT = "TEXT",
  TIME = "TIME",
}

export enum ProntuarioSecaoOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  ordem_ASC = "ordem_ASC",
  ordem_DESC = "ordem_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum Sexo {
  FEMININO = "FEMININO",
  MASCULINO = "MASCULINO",
}

export enum TipoRepeticao {
  ANUALMENTE = "ANUALMENTE",
  DIARIAMENTE = "DIARIAMENTE",
  MENSALMENTE = "MENSALMENTE",
  QUIZENALMENTE = "QUIZENALMENTE",
  SEMANALMENTE = "SEMANALMENTE",
}

export enum TissDespesaTipo {
  DIARIAS = "DIARIAS",
  GASES_MEDICINAIS = "GASES_MEDICINAIS",
  MATERIAIS = "MATERIAIS",
  MEDICAMENTOS = "MEDICAMENTOS",
  OPME = "OPME",
  TAXAS_E_ALUGUEIS = "TAXAS_E_ALUGUEIS",
}

export enum TissGuiaConsultaOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissGuiaHonorariosOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissGuiaOdontologicaOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissGuiaSpSadtOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissLoteOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissLoteTipo {
  GUIA_CONSULTA = "GUIA_CONSULTA",
  GUIA_HONORARIOS = "GUIA_HONORARIOS",
  GUIA_SP_SADT = "GUIA_SP_SADT",
  GUIA_TRATAMENTO_ODONTOLOGICO = "GUIA_TRATAMENTO_ODONTOLOGICO",
}

export enum TissProcedimentoOrderBy {
  codigo_ASC = "codigo_ASC",
  codigo_DESC = "codigo_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  sexo_ASC = "sexo_ASC",
  sexo_DESC = "sexo_DESC",
  tabela_ASC = "tabela_ASC",
  tabela_DESC = "tabela_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum TissProcedimentoSexo {
  AMBOS = "AMBOS",
  FEMININO = "FEMININO",
  MASCULINO = "MASCULINO",
  NAO_SE_APLICA = "NAO_SE_APLICA",
}

export enum TissProcedimentoTabela {
  TB_00 = "TB_00",
  TB_90 = "TB_90",
  TB_98 = "TB_98",
}

export enum TissTabela {
  TB_00 = "TB_00",
  TB_18 = "TB_18",
  TB_19 = "TB_19",
  TB_20 = "TB_20",
  TB_22 = "TB_22",
  TB_90 = "TB_90",
  TB_98 = "TB_98",
}

export enum TissTipoDocumento {
  CNPJ = "CNPJ",
  CODIGO_NA_OPERADORA = "CODIGO_NA_OPERADORA",
  CPF = "CPF",
}

export enum TissTipoPessoa {
  FISICA = "FISICA",
  JURIDICA = "JURIDICA",
}

export enum TransacaoOrderBy {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  dataExtrato_ASC = "dataExtrato_ASC",
  dataExtrato_DESC = "dataExtrato_DESC",
  dataPagamento_ASC = "dataPagamento_ASC",
  dataPagamento_DESC = "dataPagamento_DESC",
  descricao_ASC = "descricao_ASC",
  descricao_DESC = "descricao_DESC",
  formaPagamento_ASC = "formaPagamento_ASC",
  formaPagamento_DESC = "formaPagamento_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pago_ASC = "pago_ASC",
  pago_DESC = "pago_DESC",
  recorrenciaIndex_ASC = "recorrenciaIndex_ASC",
  recorrenciaIndex_DESC = "recorrenciaIndex_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  valor_ASC = "valor_ASC",
  valor_DESC = "valor_DESC",
  vencimento_ASC = "vencimento_ASC",
  vencimento_DESC = "vencimento_DESC",
}

export enum TransacaoTipo {
  DESPESA = "DESPESA",
  RECEITA = "RECEITA",
}

export enum TussLookupOrderBy {
  codigo_ASC = "codigo_ASC",
  codigo_DESC = "codigo_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  tabela_ASC = "tabela_ASC",
  tabela_DESC = "tabela_DESC",
}

export enum TussLookupTabela {
  TB_18 = "TB_18",
  TB_19 = "TB_19",
  TB_20 = "TB_20",
  TB_22 = "TB_22",
}

export enum UserEquipeTipoAcesso {
  ADMIN_ACCOUNT = "ADMIN_ACCOUNT",
  ADMIN_CLINICA = "ADMIN_CLINICA",
  NONE = "NONE",
}

export enum UserOrderBy {
  adminAccount_ASC = "adminAccount_ASC",
  adminAccount_DESC = "adminAccount_DESC",
  cbo_ASC = "cbo_ASC",
  cbo_DESC = "cbo_DESC",
  celular_ASC = "celular_ASC",
  celular_DESC = "celular_DESC",
  conselhoRegistro_ASC = "conselhoRegistro_ASC",
  conselhoRegistro_DESC = "conselhoRegistro_DESC",
  conselho_ASC = "conselho_ASC",
  conselho_DESC = "conselho_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  nome_ASC = "nome_ASC",
  nome_DESC = "nome_DESC",
  profissao_ASC = "profissao_ASC",
  profissao_DESC = "profissao_DESC",
  sexo_ASC = "sexo_ASC",
  sexo_DESC = "sexo_DESC",
  tipo_ASC = "tipo_ASC",
  tipo_DESC = "tipo_DESC",
  tratamento_ASC = "tratamento_ASC",
  tratamento_DESC = "tratamento_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum UserTipo {
  PROFISSIONAL_SAUDE = "PROFISSIONAL_SAUDE",
  RECEPCIONISTA = "RECEPCIONISTA",
}

export interface AgendamentoCreateDataInput {
  bloqueado: boolean;
  data: any;
  horaInicial?: any | null;
  horaFinal?: any | null;
  diaTodo: boolean;
  observacao?: string | null;
  paciente?: AgendamentoPacienteInput | null;
  procedimentos?: ProcedimentoOnAgendamentoInput[] | null;
  convenioId?: string | null;
}

export interface AgendamentoDeleteRecorreciaInput {
  agendamentoId: string;
  motivo?: AgendamentoCancelamentoMotivo | null;
  all?: boolean | null;
  dataFinal?: any | null;
}

export interface AgendamentoPacienteInput {
  id?: string | null;
  nome?: string | null;
  celular?: string | null;
  telefoneCasa?: string | null;
  email?: string | null;
}

export interface AgendamentoProximoHorarioInput {
  duracaoMinutos: number;
  data: any;
  hora: any;
  profissionalId: string;
}

export interface AgendamentoRecebimentoDataInput {
  procedimentos: AgendamentoRecebimentoProcedimentosInput[];
  desconto: number;
  vencimento: any;
  pago: boolean;
  dataPagamento?: any | null;
  formaPagamento: FormaPagamento;
  contaFinanceiraId: string;
}

export interface AgendamentoRecebimentoParcelasInput {
  numero: number;
  valorEntrada?: number | null;
}

export interface AgendamentoRecebimentoProcedimentosInput {
  procedimentoId: string;
  quantidade: number;
  valorUnitario: number;
}

export interface AgendamentoRecorrenciaDataInput {
  tipo: TipoRepeticao;
  diasSemana?: number[] | null;
  ocorrencias?: number | null;
  dataFinal?: any | null;
}

export interface AgendamentoTimeInput {
  data: any;
  horaInicial?: any | null;
  horaFinal?: any | null;
  diaTodo: boolean;
}

export interface AgendamentoUpdateDataInput {
  bloqueado: boolean;
  observacao?: string | null;
  paciente?: AgendamentoPacienteInput | null;
  procedimentos?: ProcedimentoOnAgendamentoInput[] | null;
  convenioId?: string | null;
}

export interface AgendamentoUpdateInput {
  id: string;
  data?: AgendamentoUpdateDataInput | null;
  time?: AgendamentoTimeInput | null;
  status?: AgendamentoStatus | null;
}

export interface AgendamentoWhereInput {
  OR?: AgendamentoWhereInput[] | null;
  JOIN?: AgendamentoWhereJoinInput | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  bloqueado?: boolean | null;
  data?: any | null;
  data_not?: any | null;
  data_in?: any[] | null;
  data_not_in?: any[] | null;
  data_lt?: any | null;
  data_lte?: any | null;
  data_gt?: any | null;
  data_gte?: any | null;
  horaInicial?: any | null;
  horaInicial_not?: any | null;
  horaInicial_in?: any[] | null;
  horaInicial_not_in?: any[] | null;
  horaInicial_lt?: any | null;
  horaInicial_lte?: any | null;
  horaInicial_gt?: any | null;
  horaInicial_gte?: any | null;
  horaFinal?: any | null;
  horaFinal_not?: any | null;
  horaFinal_in?: any[] | null;
  horaFinal_not_in?: any[] | null;
  horaFinal_lt?: any | null;
  horaFinal_lte?: any | null;
  horaFinal_gt?: any | null;
  horaFinal_gte?: any | null;
  recorrente?: boolean | null;
  remarcado?: boolean | null;
  status?: AgendamentoStatus | null;
  status_not?: AgendamentoStatus | null;
  status_in?: AgendamentoStatus[] | null;
  status_not_in?: AgendamentoStatus[] | null;
  procedimentoId?: string | null;
  pacienteId?: string | null;
  convenioId?: string | null;
  recorrenciaId?: string | null;
}

export interface AgendamentoWhereJoinInput {
  paciente?: PacienteWhereInput | null;
}

export interface AssinaturaWhereInput {
  OR?: AssinaturaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  validUntil?: any | null;
  validUntil_not?: any | null;
  validUntil_in?: any[] | null;
  validUntil_not_in?: any[] | null;
  validUntil_lt?: any | null;
  validUntil_lte?: any | null;
  validUntil_gt?: any | null;
  validUntil_gte?: any | null;
  cancelado?: boolean | null;
  planoId?: string | null;
}

export interface AtendimentoAtestadoInput {
  data: any;
  titulo: string;
  texto: string;
}

export interface AtendimentoCacheDataInput {
  dataInicial: any;
  pacienteId: string;
  payload: any;
}

export interface AtendimentoDataInput {
  dataInicial: any;
  dataFinal: any;
  preAtendimento?: AtendimentoPreAtendimentoInput | null;
  hipoteseDiagnostica?: AtendimentoHipoteseDiagnosticaInput | null;
  prescricoes?: AtendimentoPrescricaoInput[] | null;
  atestados?: AtendimentoAtestadoInput[] | null;
  exames?: AtendimentoExameInput[] | null;
  eventos?: AtendimentoEventoInput[] | null;
  pacienteId: string;
  agendamentoId?: string | null;
  files?: AtendimentoFileInput[] | null;
}

export interface AtendimentoEventoInput {
  nome: string;
  prontuarioSecaoId: string;
  items: AtendimentoEventoItemInput[];
}

export interface AtendimentoEventoItemInput {
  type: AtendimentoEventoItemType;
  label: string;
  valueText?: string | null;
  valueNumber?: number | null;
  valueList?: string[] | null;
  originalType: ProntuarioSecaoFieldType;
  suffix?: string | null;
  decimalPlaces?: number | null;
}

export interface AtendimentoExameInput {
  data: string;
  indicacaoClinica?: string | null;
  tipo: AtendimentoExameTipo;
  items: AtendimentoExameItemInput[];
}

export interface AtendimentoExameItemInput {
  codigo: string;
  nome: string;
  quantidade: number;
  observacao?: string | null;
}

export interface AtendimentoFileInput {
  id: string;
  description?: string | null;
}

export interface AtendimentoHipoteseDiagnosticaCidInput {
  codigo: string;
  descricao: string;
}

export interface AtendimentoHipoteseDiagnosticaInput {
  cids: AtendimentoHipoteseDiagnosticaCidInput[];
  observacao?: string | null;
}

export interface AtendimentoPreAtendimentoInput {
  peso?: number | null;
  altura?: number | null;
  imc?: number | null;
  pressaoArterialSistolica?: number | null;
  pressaoArterialDiastolica?: number | null;
  frequenciaCardiaca?: number | null;
  frequenciaRespiratoria?: number | null;
  temperatura?: number | null;
  glicemiaCapilar?: number | null;
  dataUltimaMenstruacao?: any | null;
  observacao?: string | null;
}

export interface AtendimentoPrescricaoInput {
  data: any;
  controleEspecial: boolean;
  medicamentos: AtendimentoPrescricaoMedicamentoInput[];
}

export interface AtendimentoPrescricaoMedicamentoInput {
  medicamento: string;
  quantidade: string;
  posologia?: string | null;
  observacao?: string | null;
}

export interface AtendimentoWhereInput {
  OR?: AtendimentoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  dataInicial?: any | null;
  dataInicial_not?: any | null;
  dataInicial_lt?: any | null;
  dataInicial_lte?: any | null;
  dataInicial_gt?: any | null;
  dataInicial_gte?: any | null;
  dataFinal?: any | null;
  dataFinal_not?: any | null;
  dataFinal_lt?: any | null;
  dataFinal_lte?: any | null;
  dataFinal_gt?: any | null;
  dataFinal_gte?: any | null;
}

export interface AtestadoModeloDataInput {
  nome: string;
  titulo: string;
  texto: string;
}

export interface AtestadoModeloWhereInput {
  OR?: AtestadoModeloWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  texto?: string | null;
  texto_not?: string | null;
  texto_in?: string[] | null;
  texto_not_in?: string[] | null;
  texto_contains?: string | null;
}

export interface CategoriaFinanceiraDataInput {
  nome: string;
  tipo: TransacaoTipo;
  parentId?: string | null;
}

export interface CategoriaFinanceiraWhereInput {
  OR?: CategoriaFinanceiraWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  tipo?: TransacaoTipo | null;
  tipo_not?: TransacaoTipo | null;
  parentId?: string | null;
  parentId_not?: string | null;
}

export interface CentroCustoDataInput {
  nome: string;
}

export interface CentroCustoWhereInput {
  OR?: CentroCustoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
}

export interface Cid10LookupWhereInput {
  OR?: Cid10LookupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  codigo?: string | null;
  codigo_not?: string | null;
  codigo_in?: string[] | null;
  codigo_not_in?: string[] | null;
  codigo_contains?: string | null;
  descricao?: string | null;
  descricao_not?: string | null;
  descricao_in?: string[] | null;
  descricao_not_in?: string[] | null;
  descricao_contains?: string | null;
  capitulo?: number | null;
  capitulo_not?: number | null;
  capitulo_in?: number[] | null;
  capitulo_not_in?: number[] | null;
  capitulo_lt?: number | null;
  capitulo_lte?: number | null;
  capitulo_gt?: number | null;
  capitulo_gte?: number | null;
  tipo?: Cid10Tipo | null;
  tipo_not?: Cid10Tipo | null;
  tipo_in?: Cid10Tipo[] | null;
  tipo_not_in?: Cid10Tipo[] | null;
  grupo?: string | null;
  grupo_not?: string | null;
  grupo_in?: string[] | null;
  grupo_not_in?: string[] | null;
  grupo_contains?: string | null;
  categoria?: string | null;
  categoria_not?: string | null;
  categoria_in?: string[] | null;
  categoria_not_in?: string[] | null;
  categoria_contains?: string | null;
}

export interface ClinicaDataInput {
  nome: string;
  email?: string | null;
  cnes?: string | null;
  enderecoCep?: string | null;
  enderecoLogradouro?: string | null;
  enderecoNumero?: string | null;
  enderecoComplemento?: string | null;
  enderecoBairro?: string | null;
  enderecoCidade?: string | null;
  enderecoUf?: string | null;
  telefones: string[];
}

export interface ClinicaOnUserInput {
  clinicaId: string;
  adminClinica: boolean;
}

export interface ClinicaWhereInput {
  OR?: ClinicaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_contains?: string | null;
  cnes?: string | null;
  cnes_not?: string | null;
  cnes_in?: string[] | null;
  cnes_not_in?: string[] | null;
  cnes_contains?: string | null;
  enderecoCep?: string | null;
  enderecoCep_not?: string | null;
  enderecoCep_in?: string[] | null;
  enderecoCep_not_in?: string[] | null;
  enderecoCep_contains?: string | null;
  enderecoLogradouro?: string | null;
  enderecoLogradouro_not?: string | null;
  enderecoLogradouro_in?: string[] | null;
  enderecoLogradouro_not_in?: string[] | null;
  enderecoLogradouro_contains?: string | null;
  enderecoNumero?: string | null;
  enderecoNumero_not?: string | null;
  enderecoNumero_in?: string[] | null;
  enderecoNumero_not_in?: string[] | null;
  enderecoNumero_contains?: string | null;
  enderecoComplemento?: string | null;
  enderecoComplemento_not?: string | null;
  enderecoComplemento_in?: string[] | null;
  enderecoComplemento_not_in?: string[] | null;
  enderecoComplemento_contains?: string | null;
  enderecoBairro?: string | null;
  enderecoBairro_not?: string | null;
  enderecoBairro_in?: string[] | null;
  enderecoBairro_not_in?: string[] | null;
  enderecoBairro_contains?: string | null;
  enderecoCidade?: string | null;
  enderecoCidade_not?: string | null;
  enderecoCidade_in?: string[] | null;
  enderecoCidade_not_in?: string[] | null;
  enderecoCidade_contains?: string | null;
  enderecoUf?: string | null;
  enderecoUf_not?: string | null;
  enderecoUf_in?: string[] | null;
  enderecoUf_not_in?: string[] | null;
  enderecoUf_contains?: string | null;
}

export interface CobrancaWhereInput {
  OR?: CobrancaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  data?: any | null;
  data_not?: any | null;
  data_in?: any[] | null;
  data_not_in?: any[] | null;
  data_lt?: any | null;
  data_lte?: any | null;
  data_gt?: any | null;
  data_gte?: any | null;
  descricao?: string | null;
  descricao_not?: string | null;
  descricao_in?: string[] | null;
  descricao_not_in?: string[] | null;
  descricao_contains?: string | null;
  valor?: number | null;
  valor_not?: number | null;
  valor_in?: number[] | null;
  valor_not_in?: number[] | null;
  valor_lt?: number | null;
  valor_lte?: number | null;
  valor_gt?: number | null;
  valor_gte?: number | null;
  saldo?: number | null;
  saldo_not?: number | null;
  saldo_in?: number[] | null;
  saldo_not_in?: number[] | null;
  saldo_lt?: number | null;
  saldo_lte?: number | null;
  saldo_gt?: number | null;
  saldo_gte?: number | null;
  assinaturaId?: string | null;
}

export interface ConfiguracaoAgendaDataInput {
  visualizacaoPadrao: ConfiguracaoAgendaVisualizacaoPadrao;
  alertaPeriodoCarencia: boolean;
  mostrarDiasBloqueados: boolean;
  domingo: boolean;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  horaInicio: any;
  horaFim: any;
  horarioAlmoco: boolean;
  horaAlmocoInicio: any;
  horaAlmocoFim: any;
  observacao?: string | null;
  procedimentoPadraoId: string;
  procedimentosCarenciasIds: string[];
}

export interface ConfiguracaoEmailAniversariantesDataInput {
  enviar: boolean;
  assunto: string;
  mensagem: string;
  imageName: ConfiguracaoEmailAniversariantesImageName;
}

export interface ConfiguracaoEmailDataInput {
  envioLembreteConsulta: boolean;
  lembreteConsultaAssunto: string;
  lembreteConsultaMensagem: string;
  recebimentoConsultasConfirmadas: boolean;
  recebimentoConsultaModificada: boolean;
}

export interface ConfiguracaoFinanceiraDataInput {
  formaPagamento: FormaPagamento;
  recebimentoLancamento: boolean;
  recebimentoLancarAPartir: ConfiguracaoFinanceiraRecebimentoLancarAPartir;
  contaFinanceiraId: string;
  centroCustoId?: string | null;
}

export interface ConfiguracaoImpressaoDataInput {
  tamanhoPagina: ConfiguracaoImpressaoTamanhoPagina;
  tamanhoFonte: number;
  margem: ConfiguracaoImpressaoMargem;
  cabecalho: boolean;
  assinatura: boolean;
  numeracaoAutomatica: boolean;
  rodape: boolean;
  rodapeTexto?: string | null;
  logoPosition: ConfiguracaoImpressaoLogoPosition;
  logoId?: string | null;
}

export interface ConfiguracaoSmsDataInput {
  enviar: boolean;
  periodoEnvio: ConfiguracaoSmsPeriodoEnvio;
  mensagem: string;
}

export interface ContaFinanceiraDataInput {
  nome: string;
  banco?: string | null;
  tipo: ContaFinanceiraTipo;
  saldoInicial: number;
  dataSaldoInicial: any;
}

export interface ContaFinanceiraWhereInput {
  OR?: ContaFinanceiraWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  banco?: string | null;
  banco_not?: string | null;
  banco_in?: string[] | null;
  banco_not_in?: string[] | null;
  banco_contains?: string | null;
  tipo?: ContaFinanceiraTipo | null;
  tipo_not?: ContaFinanceiraTipo | null;
  tipo_in?: ContaFinanceiraTipo[] | null;
  tipo_not_in?: ContaFinanceiraTipo[] | null;
  saldo?: number | null;
  saldo_not?: number | null;
  saldo_in?: number[] | null;
  saldo_not_in?: number[] | null;
  saldo_lt?: number | null;
  saldo_lte?: number | null;
  saldo_gt?: number | null;
  saldo_gte?: number | null;
  saldoInicial?: number | null;
  saldoInicial_not?: number | null;
  saldoInicial_in?: number[] | null;
  saldoInicial_not_in?: number[] | null;
  saldoInicial_lt?: number | null;
  saldoInicial_lte?: number | null;
  saldoInicial_gt?: number | null;
  saldoInicial_gte?: number | null;
  dataSaldoInicial?: any | null;
  dataSaldoInicial_not?: any | null;
  dataSaldoInicial_in?: any[] | null;
  dataSaldoInicial_not_in?: any[] | null;
  dataSaldoInicial_lt?: any | null;
  dataSaldoInicial_lte?: any | null;
  dataSaldoInicial_gt?: any | null;
  dataSaldoInicial_gte?: any | null;
}

export interface ContatoDataInput {
  nome: string;
  email?: string | null;
  enderecoCep?: string | null;
  enderecoLogradouro?: string | null;
  enderecoNumero?: string | null;
  enderecoComplemento?: string | null;
  enderecoBairro?: string | null;
  enderecoCidade?: string | null;
  enderecoUf?: string | null;
  enderecoPais?: string | null;
  observacao?: string | null;
  telefones: string[];
}

export interface ContatoWhereInput {
  OR?: ContatoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_contains?: string | null;
  enderecoCep?: string | null;
  enderecoCep_not?: string | null;
  enderecoCep_in?: string[] | null;
  enderecoCep_not_in?: string[] | null;
  enderecoCep_contains?: string | null;
  enderecoLogradouro?: string | null;
  enderecoLogradouro_not?: string | null;
  enderecoLogradouro_in?: string[] | null;
  enderecoLogradouro_not_in?: string[] | null;
  enderecoLogradouro_contains?: string | null;
  enderecoNumero?: string | null;
  enderecoNumero_not?: string | null;
  enderecoNumero_in?: string[] | null;
  enderecoNumero_not_in?: string[] | null;
  enderecoNumero_contains?: string | null;
  enderecoComplemento?: string | null;
  enderecoComplemento_not?: string | null;
  enderecoComplemento_in?: string[] | null;
  enderecoComplemento_not_in?: string[] | null;
  enderecoComplemento_contains?: string | null;
  enderecoBairro?: string | null;
  enderecoBairro_not?: string | null;
  enderecoBairro_in?: string[] | null;
  enderecoBairro_not_in?: string[] | null;
  enderecoBairro_contains?: string | null;
  enderecoCidade?: string | null;
  enderecoCidade_not?: string | null;
  enderecoCidade_in?: string[] | null;
  enderecoCidade_not_in?: string[] | null;
  enderecoCidade_contains?: string | null;
  enderecoUf?: string | null;
  enderecoUf_not?: string | null;
  enderecoUf_in?: string[] | null;
  enderecoUf_not_in?: string[] | null;
  enderecoUf_contains?: string | null;
  enderecoPais?: string | null;
  enderecoPais_not?: string | null;
  enderecoPais_in?: string[] | null;
  enderecoPais_not_in?: string[] | null;
  enderecoPais_contains?: string | null;
}

export interface ConvenioDataInput {
  nome: string;
  registroAns: string;
  periodoCarenciaDias: number;
  planos: ConvenioPlanoInput[];
  profissionais: ConvenioProfissionalInput[];
}

export interface ConvenioLookupWhereInput {
  OR?: ConvenioLookupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  razaoSocial?: string | null;
  razaoSocial_not?: string | null;
  razaoSocial_in?: string[] | null;
  razaoSocial_not_in?: string[] | null;
  razaoSocial_contains?: string | null;
  nomeFantasia?: string | null;
  nomeFantasia_not?: string | null;
  nomeFantasia_in?: string[] | null;
  nomeFantasia_not_in?: string[] | null;
  nomeFantasia_contains?: string | null;
  registroAns?: string | null;
  registroAns_not?: string | null;
  registroAns_in?: string[] | null;
  registroAns_not_in?: string[] | null;
  registroAns_contains?: string | null;
}

export interface ConvenioPlanoInput {
  id?: string | null;
  nome: string;
  profissionais: ConvenioPlanoProfissionalInput[];
}

export interface ConvenioPlanoProfissionalInput {
  profissionalId: string;
  periodoCarenciaDias: number;
}

export interface ConvenioProfissionalInput {
  profissionalId: string;
  codigoOperadora?: string | null;
}

export interface ConvenioWhereInput {
  OR?: ConvenioWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  registroAns?: string | null;
  registroAns_not?: string | null;
  registroAns_in?: string[] | null;
  registroAns_not_in?: string[] | null;
  registroAns_contains?: string | null;
  periodoCarenciaDias?: number | null;
  periodoCarenciaDias_not?: number | null;
  periodoCarenciaDias_in?: number[] | null;
  periodoCarenciaDias_not_in?: number[] | null;
  periodoCarenciaDias_lt?: number | null;
  periodoCarenciaDias_lte?: number | null;
  periodoCarenciaDias_gt?: number | null;
  periodoCarenciaDias_gte?: number | null;
  particular?: boolean | null;
}

export interface CreateUploadUrlDataInput {
  name: string;
  type: string;
}

export interface CreateUserDataInput {
  nome: string;
  email: string;
  tipo: UserTipo;
  adminAccount: boolean;
  sexo: Sexo;
  celular?: string | null;
  tratamento?: string | null;
  conselho?: string | null;
  conselhoUf?: string | null;
  conselhoRegistro?: string | null;
  rqe?: string | null;
  profissao?: string | null;
  cbo?: string | null;
  imagemId?: string | null;
  clinicas: ClinicaOnUserInput[];
  profissionaisIds: string[];
}

export interface DashboardPacientesAniversariantesWhereInput {
  profissionalId: string;
  dia: number;
  mes: number;
  mesTodo: boolean;
}

export interface DashboardWhereInput {
  profissionalId: string;
  dataInicial: any;
  dataFinal: any;
}

export interface ExameModeloDataInput {
  nome: string;
  items: ExameModeloItemInput[];
}

export interface ExameModeloItemInput {
  id?: string | null;
  nome: string;
  codigo: string;
  quantidade: number;
  observacao?: string | null;
  ordem: number;
}

export interface ExameModeloWhereInput {
  OR?: ExameModeloWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
}

export interface FinancasRelatorioWhereInput {
  dataInicial: any;
  dataFinal: any;
  tipo: TransacaoTipo;
  pago?: boolean | null;
  categoriaId?: string | null;
  contaFinanceiraId?: string | null;
  centroCustoId?: string | null;
  convenioId?: string | null;
  procedimentoId?: string | null;
  profissionalId?: string | null;
  pacienteId?: string | null;
}

export interface FluxoCaixaWhereInput {
  dataInicial: any;
  dataFinal: any;
  contasFinanceirasIds: string[];
}

export interface ListaEsperaDataInput {
  nome?: string | null;
  email?: string | null;
  celular?: string | null;
  telefoneCasa?: string | null;
  observacao?: string | null;
  ordem: number;
  pacienteId?: string | null;
  convenioId?: string | null;
}

export interface ListaEsperaUpdateOrdemDataInput {
  id: string;
  ordem: number;
}

export interface ListaEsperaWhereInput {
  OR?: ListaEsperaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_contains?: string | null;
  celular?: string | null;
  celular_not?: string | null;
  celular_in?: string[] | null;
  celular_not_in?: string[] | null;
  celular_contains?: string | null;
  telefoneCasa?: string | null;
  telefoneCasa_not?: string | null;
  telefoneCasa_in?: string[] | null;
  telefoneCasa_not_in?: string[] | null;
  telefoneCasa_contains?: string | null;
  pacienteId?: string | null;
  convenioId?: string | null;
}

export interface PacienteConvenioDataInput {
  convenioId: string;
  planoId?: string | null;
  acomodacao?: string | null;
  numeroCarteirinha: string;
  vencimento?: any | null;
  semVencimento: boolean;
}

export interface PacienteDataInput {
  nome: string;
  codigo?: string | null;
  dataNascimento: any;
  sexo: Sexo;
  email?: string | null;
  observacao?: string | null;
  comoConheceu?: string | null;
  celular?: string | null;
  telefoneCasa?: string | null;
  telefoneTrabalho?: string | null;
  aceitaSms: boolean;
  naturalidade?: string | null;
  naturalidadeUf?: string | null;
  nacionalidade?: string | null;
  etnia?: string | null;
  cpf?: string | null;
  rg?: string | null;
  religiao?: string | null;
  estadoCivil?: string | null;
  escolaridade?: string | null;
  profissao?: string | null;
  obito: boolean;
  causaMortis?: string | null;
  cns?: string | null;
  informacoesAdicionais?: string | null;
  enderecoCep?: string | null;
  enderecoLogradouro?: string | null;
  enderecoNumero?: string | null;
  enderecoComplemento?: string | null;
  enderecoBairro?: string | null;
  enderecoCidade?: string | null;
  enderecoUf?: string | null;
  enderecoPais?: string | null;
  imagemId?: string | null;
}

export interface PacienteParenteDataInput {
  nome: string;
  parentesco: string;
  profissao?: string | null;
}

export interface PacienteWhereInput {
  OR?: PacienteWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  incompleto?: boolean | null;
  homonimo?: boolean | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  codigo?: string | null;
  codigo_not?: string | null;
  codigo_in?: string[] | null;
  codigo_not_in?: string[] | null;
  codigo_contains?: string | null;
  dataNascimento?: any | null;
  dataNascimento_not?: any | null;
  dataNascimento_in?: any[] | null;
  dataNascimento_not_in?: any[] | null;
  dataNascimento_lt?: any | null;
  dataNascimento_lte?: any | null;
  dataNascimento_gt?: any | null;
  dataNascimento_gte?: any | null;
  ultimaConsulta?: any | null;
  ultimaConsulta_not?: any | null;
  ultimaConsulta_in?: any[] | null;
  ultimaConsulta_not_in?: any[] | null;
  ultimaConsulta_lt?: any | null;
  ultimaConsulta_lte?: any | null;
  ultimaConsulta_gt?: any | null;
  ultimaConsulta_gte?: any | null;
  sexo?: Sexo | null;
  sexo_not?: Sexo | null;
  sexo_in?: Sexo[] | null;
  sexo_not_in?: Sexo[] | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_contains?: string | null;
  comoConheceu?: string | null;
  comoConheceu_not?: string | null;
  comoConheceu_in?: string[] | null;
  comoConheceu_not_in?: string[] | null;
  comoConheceu_contains?: string | null;
  celular?: string | null;
  celular_not?: string | null;
  celular_in?: string[] | null;
  celular_not_in?: string[] | null;
  celular_contains?: string | null;
  telefoneCasa?: string | null;
  telefoneCasa_not?: string | null;
  telefoneCasa_in?: string[] | null;
  telefoneCasa_not_in?: string[] | null;
  telefoneCasa_contains?: string | null;
  telefoneTrabalho?: string | null;
  telefoneTrabalho_not?: string | null;
  telefoneTrabalho_in?: string[] | null;
  telefoneTrabalho_not_in?: string[] | null;
  telefoneTrabalho_contains?: string | null;
  aceitaSms?: boolean | null;
  naturalidade?: string | null;
  naturalidade_not?: string | null;
  naturalidade_in?: string[] | null;
  naturalidade_not_in?: string[] | null;
  naturalidade_contains?: string | null;
  naturalidadeUf?: string | null;
  naturalidadeUf_not?: string | null;
  naturalidadeUf_in?: string[] | null;
  naturalidadeUf_not_in?: string[] | null;
  naturalidadeUf_contains?: string | null;
  nacionalidade?: string | null;
  nacionalidade_not?: string | null;
  nacionalidade_in?: string[] | null;
  nacionalidade_not_in?: string[] | null;
  nacionalidade_contains?: string | null;
  etnia?: string | null;
  etnia_not?: string | null;
  etnia_in?: string[] | null;
  etnia_not_in?: string[] | null;
  etnia_contains?: string | null;
  cpf?: string | null;
  cpf_not?: string | null;
  cpf_in?: string[] | null;
  cpf_not_in?: string[] | null;
  cpf_contains?: string | null;
  rg?: string | null;
  rg_not?: string | null;
  rg_in?: string[] | null;
  rg_not_in?: string[] | null;
  rg_contains?: string | null;
  religiao?: string | null;
  religiao_not?: string | null;
  religiao_in?: string[] | null;
  religiao_not_in?: string[] | null;
  religiao_contains?: string | null;
  estadoCivil?: string | null;
  estadoCivil_not?: string | null;
  estadoCivil_in?: string[] | null;
  estadoCivil_not_in?: string[] | null;
  estadoCivil_contains?: string | null;
  escolaridade?: string | null;
  escolaridade_not?: string | null;
  escolaridade_in?: string[] | null;
  escolaridade_not_in?: string[] | null;
  escolaridade_contains?: string | null;
  profissao?: string | null;
  profissao_not?: string | null;
  profissao_in?: string[] | null;
  profissao_not_in?: string[] | null;
  profissao_contains?: string | null;
  obito?: boolean | null;
  causaMortis?: string | null;
  causaMortis_not?: string | null;
  causaMortis_in?: string[] | null;
  causaMortis_not_in?: string[] | null;
  causaMortis_contains?: string | null;
  cns?: string | null;
  cns_not?: string | null;
  cns_in?: string[] | null;
  cns_not_in?: string[] | null;
  cns_contains?: string | null;
  enderecoCep?: string | null;
  enderecoCep_not?: string | null;
  enderecoCep_in?: string[] | null;
  enderecoCep_not_in?: string[] | null;
  enderecoCep_contains?: string | null;
  enderecoLogradouro?: string | null;
  enderecoLogradouro_not?: string | null;
  enderecoLogradouro_in?: string[] | null;
  enderecoLogradouro_not_in?: string[] | null;
  enderecoLogradouro_contains?: string | null;
  enderecoNumero?: string | null;
  enderecoNumero_not?: string | null;
  enderecoNumero_in?: string[] | null;
  enderecoNumero_not_in?: string[] | null;
  enderecoNumero_contains?: string | null;
  enderecoComplemento?: string | null;
  enderecoComplemento_not?: string | null;
  enderecoComplemento_in?: string[] | null;
  enderecoComplemento_not_in?: string[] | null;
  enderecoComplemento_contains?: string | null;
  enderecoBairro?: string | null;
  enderecoBairro_not?: string | null;
  enderecoBairro_in?: string[] | null;
  enderecoBairro_not_in?: string[] | null;
  enderecoBairro_contains?: string | null;
  enderecoCidade?: string | null;
  enderecoCidade_not?: string | null;
  enderecoCidade_in?: string[] | null;
  enderecoCidade_not_in?: string[] | null;
  enderecoCidade_contains?: string | null;
  enderecoUf?: string | null;
  enderecoUf_not?: string | null;
  enderecoUf_in?: string[] | null;
  enderecoUf_not_in?: string[] | null;
  enderecoUf_contains?: string | null;
}

export interface PermissoesEnvioDataInput {
  permiteSms: boolean;
  permiteEmail: boolean;
}

export interface PlanoWhereInput {
  OR?: PlanoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  valor?: number | null;
  valor_not?: number | null;
  valor_in?: number[] | null;
  valor_not_in?: number[] | null;
  valor_lt?: number | null;
  valor_lte?: number | null;
  valor_gt?: number | null;
  valor_gte?: number | null;
  validoAte?: any | null;
  validoAte_not?: any | null;
  validoAte_in?: any[] | null;
  validoAte_not_in?: any[] | null;
  validoAte_lt?: any | null;
  validoAte_lte?: any | null;
  validoAte_gt?: any | null;
  validoAte_gte?: any | null;
  cancelado?: boolean | null;
}

export interface PrescricaoModeloDataInput {
  nome: string;
  medicamentos: PrescricaoModeloMedicamentoInput[];
}

export interface PrescricaoModeloMedicamentoInput {
  id?: string | null;
  medicamento: string;
  quantidade?: string | null;
  posologia?: string | null;
  observacao?: string | null;
}

export interface PrescricaoModeloWhereInput {
  OR?: PrescricaoModeloWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
}

export interface ProcedimentoConvenioInput {
  convenioId: string;
  valor: number;
  tissCodigo?: string | null;
  tissNome?: string | null;
  tissTabela?: TissTabela | null;
}

export interface ProcedimentoDataInput {
  nome: string;
  cor: string;
  duracaoMinutos: number;
  tipo: ProcedimentoTipo;
  preparo?: string | null;
  valor?: number | null;
  convenios: ProcedimentoConvenioInput[];
  despesas: ProcedimentoDespesaInput[];
}

export interface ProcedimentoDespesaInput {
  id?: string | null;
  valorUnitario: number;
  quantidade: number;
  tissCodigo: string;
  tissNome: string;
  tissTabela: TissTabela;
  tipo: TissDespesaTipo;
  unidade: string;
}

export interface ProcedimentoOnAgendamentoInput {
  procedimentoId: string;
  quantidade: number;
}

export interface ProcedimentoWhereInput {
  OR?: ProcedimentoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  cor?: string | null;
  cor_not?: string | null;
  cor_in?: string[] | null;
  cor_not_in?: string[] | null;
  cor_contains?: string | null;
  duracaoMinutos?: number | null;
  duracaoMinutos_not?: number | null;
  duracaoMinutos_in?: number[] | null;
  duracaoMinutos_not_in?: number[] | null;
  duracaoMinutos_lt?: number | null;
  duracaoMinutos_lte?: number | null;
  duracaoMinutos_gt?: number | null;
  duracaoMinutos_gte?: number | null;
  tipo?: ProcedimentoTipo | null;
  tipo_not?: ProcedimentoTipo | null;
  tipo_in?: ProcedimentoTipo[] | null;
  tipo_not_in?: ProcedimentoTipo[] | null;
  preparo?: string | null;
  preparo_not?: string | null;
  preparo_in?: string[] | null;
  preparo_not_in?: string[] | null;
  preparo_contains?: string | null;
  valor?: number | null;
  valor_not?: number | null;
  valor_in?: number[] | null;
  valor_not_in?: number[] | null;
  valor_lt?: number | null;
  valor_lte?: number | null;
  valor_gt?: number | null;
  valor_gte?: number | null;
}

export interface ProntuarioSecaoDataInput {
  nome: string;
  fields: ProntuarioSecaoFieldInput[];
}

export interface ProntuarioSecaoFieldInput {
  id: string;
  label: string;
  type: ProntuarioSecaoFieldType;
  suffix?: string | null;
  decimalPlaces?: number | null;
  items?: string[] | null;
}

export interface ProntuarioSecaoUpdateOrdemDataInput {
  id: string;
  ordem: number;
}

export interface ProntuarioSecaoWhereInput {
  OR?: ProntuarioSecaoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
}

export interface SignUpPart1DataInput {
  nome: string;
  email: string;
  password: string;
}

export interface SignUpPart2DataInput {
  nome: string;
  tratamento?: string | null;
  profissao: string;
  cbo?: string | null;
  conselho?: string | null;
  conselhoUf?: string | null;
  conselhoRegistro?: string | null;
  telefoneClinica: string;
}

export interface TissGuiaConsultaDataInput {
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaOperadora?: string | null;
  ausenciaCodValidacao?: string | null;
  codValidacao?: string | null;
  pacienteDataNascimento: any;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS?: string | null;
  contratadoTipoPessoa: TissTipoPessoa;
  contratadoTipoDocumento: TissTipoDocumento;
  contratadoCodigoPrestadorNaOperadora?: string | null;
  contratadoCpf?: string | null;
  contratadoCnpj?: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  profissionalNome?: string | null;
  profissionalConselho: string;
  profissionalNumeroConselho: string;
  profissionalUF: string;
  profissionalCBOS: string;
  indicacaoAcidente: string;
  atendimentoData: any;
  atendimentoTipoConsulta: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoValor: number;
  observacao?: string | null;
  agendamentoId?: string | null;
  pacienteId: string;
  convenioId: string;
}

export interface TissGuiaConsultaWhereInput {
  OR?: TissGuiaConsultaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface TissGuiaHonorariosDataInput {
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaSolicInternacao: string;
  senha?: string | null;
  numeroGuiaOperadora?: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioNome: string;
  beneficiarioAtendimentoRN: string;
  contratadoCodigoPrestadorNaOperadora?: string | null;
  contratadoCnpj?: string | null;
  contratadoNome: string;
  contratadoCNES: string;
  contratadoExecutanteCodigoNaOperadora: string;
  contratadoExecutanteNome: string;
  contratadoExecutanteCnes: string;
  internacaoDataInicioFaturamento: any;
  internacaoDataFimFaturamento: any;
  procedimentosRealizados: TissGuiaHonorariosProcedimentoRealizadoInput[];
  observacao?: string | null;
  valorTotalHonorarios: number;
  dataEmissaoGuia: any;
  pacienteId: string;
  convenioId: string;
}

export interface TissGuiaHonorariosProcedimentoProfissionalInput {
  id: string;
  grauParticipacao: string;
  codigoPrestadorNaOperadora?: string | null;
  cpfContratado?: string | null;
  nomeProfissional: string;
  conselhoProfissional: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBO: string;
}

export interface TissGuiaHonorariosProcedimentoRealizadoInput {
  id: string;
  dataExecucao: any;
  horaInicial?: any | null;
  horaFinal?: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso?: string | null;
  tecnicaUtilizada?: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  profissionais: TissGuiaHonorariosProcedimentoProfissionalInput[];
}

export interface TissGuiaHonorariosWhereInput {
  OR?: TissGuiaHonorariosWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface TissGuiaOdontologicaDataInput {
  registroANS: string;
  numeroGuiaPrestador: string;
  numeroGuiaPrincipal?: string | null;
  numeroGuiaOperadora?: string | null;
  dataAutorizacao?: any | null;
  senhaAutorizacao?: string | null;
  validadeSenha?: any | null;
  ausenciaCodValidacao?: string | null;
  codValidacao?: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS?: string | null;
  planoBeneficiario: string;
  nomeEmpresa?: string | null;
  numeroTelefone?: string | null;
  nomeTitular?: string | null;
  profissionaisResponsaveisNomeProfSolic?: string | null;
  profissionaisResponsaveisCroSolic?: string | null;
  profissionaisResponsaveisUfSolic?: string | null;
  profissionaisResponsaveisCbosSolic?: string | null;
  profissionaisResponsaveisCodigoProfExec: string;
  profissionaisResponsaveisNomeProfExec: string;
  profissionaisResponsaveisCroExec: string;
  profissionaisResponsaveisUfExec: string;
  profissionaisResponsaveisCnesExec: string;
  profissionaisResponsaveisNomeProfExec2?: string | null;
  profissionaisResponsaveisCroExec2?: string | null;
  profissionaisResponsaveisUfExec2?: string | null;
  profissionaisResponsaveisCbosExec2: string;
  qtdTotalUS?: number | null;
  valorTotalProc: number;
  valorTotalFranquia?: number | null;
  dataTerminoTrat?: any | null;
  tipoAtendimento: string;
  tipoFaturamento: string;
  procedimentosExecutados: TissGuiaOdontologicaProcedimentoExecutadoInput[];
  odontoInicial?: TissGuiaOdontologicaOdontoInicialInput | null;
  observacao?: string | null;
  pacienteId: string;
  convenioId: string;
}

export interface TissGuiaOdontologicaOdontoInicialDenteInput {
  id: string;
  elementoDentario: string;
  condicaoClinica: string;
}

export interface TissGuiaOdontologicaOdontoInicialInput {
  id: string;
  numeroGuiaAnexo: string;
  numeroGuiaReferenciada: string;
  numeroGuiaOperadora?: string | null;
  dentes: TissGuiaOdontologicaOdontoInicialDenteInput[];
  doencaPeriodontal?: boolean | null;
  alteracaoTecidoMole?: boolean | null;
  observacao?: string | null;
}

export interface TissGuiaOdontologicaProcedimentoExecutadoInput {
  id: string;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  denteRegiaoCodDente?: string | null;
  denteRegiaoCodRegiao?: string | null;
  denteFace?: string | null;
  qtdProc: number;
  qtdUS?: number | null;
  valorProc: number;
  valorFranquia?: number | null;
  autorizado: boolean;
  dataRealizacao: any;
}

export interface TissGuiaOdontologicaWhereInput {
  OR?: TissGuiaOdontologicaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface TissGuiaSpSadtDataInput {
  registroANS: string;
  numeroGuiaPrestador: string;
  guiaPrincipal?: string | null;
  autorizacaoNumeroGuiaOperadora?: string | null;
  autorizacaoData?: any | null;
  autorizacaoSenha?: string | null;
  autorizacaoDataValidadeSenha?: any | null;
  autorizacaoAusenciaCodValidacao?: string | null;
  autorizacaoCodValidacao?: string | null;
  beneficiarioNumeroCarteira: string;
  beneficiarioAtendimentoRN: string;
  beneficiarioNome: string;
  beneficiarioNumeroCNS?: string | null;
  solicitanteCodigoPrestadorNaOperadora?: string | null;
  solicitanteCpf?: string | null;
  solicitanteCnpj?: string | null;
  solicitanteNome: string;
  profissionalSolicitanteNome: string;
  profissionalSolicitanteConselho: string;
  profissionalSolicitanteNumeroConselho: string;
  profissionalSolicitanteUF: string;
  profissionalSolicitanteCBOS: string;
  solicitacaoData?: any | null;
  solicitacaoCaraterAtendimento: string;
  solicitacaoIndicacaoClinica?: string | null;
  executanteCodigoPrestadorNaOperadora?: string | null;
  executanteCpf?: string | null;
  executanteCnpj?: string | null;
  executanteNome: string;
  executanteCNES: string;
  atendimentoTipo: string;
  atendimentoIndicacaoAcidente: string;
  atendimentoTipoConsulta?: string | null;
  atendimentoMotivoEncerramento?: string | null;
  procedimentosExecutados?: TissGuiaSpSadtProcedimentoExecutadoInput[] | null;
  outrasDespesas?: TissGuiaSpSadtOutraDepesaInput[] | null;
  observacao?: string | null;
  totalValorProcedimentos?: number | null;
  totalValorDiarias?: number | null;
  totalValorTaxasAlugueis?: number | null;
  totalValorMateriais?: number | null;
  totalValorMedicamentos?: number | null;
  totalValorOPME?: number | null;
  totalValorGasesMedicinais?: number | null;
  totalValorTotalGeral: number;
  pacienteId: string;
  convenioId: string;
}

export interface TissGuiaSpSadtOutraDepesaInput {
  id: string;
  codigoDespesa: string;
  itemVinculado?: number | null;
  servicosDataExecucao: any;
  servicosHoraInicial?: any | null;
  servicosHoraFinal?: any | null;
  servicosCodigoTabela: string;
  servicosCodigoProcedimento: string;
  servicosQuantidadeExecutada: number;
  servicosUnidadeMedida: string;
  servicosReducaoAcrescimo: number;
  servicosValorUnitario: number;
  servicosValorTotal: number;
  servicosDescricaoProcedimento: string;
  servicosRegistroANVISA?: string | null;
  servicosCodigoRefFabricante?: string | null;
  servicosAutorizacaoFuncionamento?: string | null;
}

export interface TissGuiaSpSadtProcedimentoExecutadoEquipeSadtInput {
  id: string;
  grauPart?: string | null;
  codigoPrestadorNaOperadora?: string | null;
  cpfContratado?: string | null;
  nomeProf: string;
  conselho: string;
  numeroConselhoProfissional: string;
  UF: string;
  CBOS: string;
}

export interface TissGuiaSpSadtProcedimentoExecutadoInput {
  id: string;
  dataExecucao: any;
  horaInicial?: any | null;
  horaFinal?: any | null;
  procedimentoCodigoTabela: string;
  procedimentoCodigo: string;
  procedimentoDescricao: string;
  quantidadeExecutada: number;
  viaAcesso?: string | null;
  tecnicaUtilizada?: string | null;
  reducaoAcrescimo: number;
  valorUnitario: number;
  valorTotal: number;
  equipeSadt?: TissGuiaSpSadtProcedimentoExecutadoEquipeSadtInput[] | null;
}

export interface TissGuiaSpSadtWhereInput {
  OR?: TissGuiaSpSadtWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface TissLoteDataInput {
  tipo: TissLoteTipo;
  prestadorCodigoPrestadorNaOperadora?: string | null;
  prestadorCNPJ?: string | null;
  prestadorCPF?: string | null;
  registroANS: string;
  numeroLote: string;
  versao: string;
  convenioId: string;
  guiasConsultasIds?: string[] | null;
  guiasHonorariosIds?: string[] | null;
  guiasSpSadtIds?: string[] | null;
  guiasOdontologicasIds?: string[] | null;
}

export interface TissLoteWhereInput {
  OR?: TissLoteWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface TissProcedimentoDataInput {
  codigo: string;
  nome: string;
  tabela: TissProcedimentoTabela;
  sexo: TissProcedimentoSexo;
}

export interface TissProcedimentoWhereInput {
  OR?: TissProcedimentoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  codigo?: string | null;
  codigo_not?: string | null;
  codigo_in?: string[] | null;
  codigo_not_in?: string[] | null;
  codigo_contains?: string | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  tabela?: TissProcedimentoTabela | null;
  tabela_not?: TissProcedimentoTabela | null;
  tabela_in?: TissProcedimentoTabela[] | null;
  tabela_not_in?: TissProcedimentoTabela[] | null;
  sexo?: TissProcedimentoSexo | null;
  sexo_not?: TissProcedimentoSexo | null;
  sexo_in?: TissProcedimentoSexo[] | null;
  sexo_not_in?: TissProcedimentoSexo[] | null;
}

export interface TransacaoAgendamentoDataInput {
  procedimentos: AgendamentoRecebimentoProcedimentosInput[];
  desconto: number;
}

export interface TransacaoDataInput {
  descricao: string;
  valor: number;
  vencimento: any;
  pago: boolean;
  dataPagamento?: any | null;
  formaPagamento?: FormaPagamento | null;
  contaFinanceiraId: string;
  categoriaId: string;
  profissionalId?: string | null;
  procedimentoId?: string | null;
  pacienteId?: string | null;
  convenioId?: string | null;
  centroCustoId?: string | null;
}

export interface TransacaoRecorrenciaDataInput {
  tipoRepeticao: TipoRepeticao;
  numero: number;
  valor: number;
  valorEntrada?: number | null;
}

export interface TransacaoWhereInput {
  OR?: TransacaoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  tipo?: TransacaoTipo | null;
  descricao?: string | null;
  descricao_not?: string | null;
  descricao_in?: string[] | null;
  descricao_not_in?: string[] | null;
  descricao_contains?: string | null;
  valor?: number | null;
  valor_not?: number | null;
  valor_in?: number[] | null;
  valor_not_in?: number[] | null;
  valor_lt?: number | null;
  valor_lte?: number | null;
  valor_gt?: number | null;
  valor_gte?: number | null;
  vencimento?: any | null;
  vencimento_not?: any | null;
  vencimento_in?: any[] | null;
  vencimento_not_in?: any[] | null;
  vencimento_lt?: any | null;
  vencimento_lte?: any | null;
  vencimento_gt?: any | null;
  vencimento_gte?: any | null;
  pago?: boolean | null;
  saldoInicial?: boolean | null;
  transferencia?: boolean | null;
  dataPagamento?: any | null;
  dataPagamento_not?: any | null;
  dataPagamento_in?: any[] | null;
  dataPagamento_not_in?: any[] | null;
  dataPagamento_lt?: any | null;
  dataPagamento_lte?: any | null;
  dataPagamento_gt?: any | null;
  dataPagamento_gte?: any | null;
  dataExtrato?: any | null;
  dataExtrato_not?: any | null;
  dataExtrato_in?: any[] | null;
  dataExtrato_not_in?: any[] | null;
  dataExtrato_lt?: any | null;
  dataExtrato_lte?: any | null;
  dataExtrato_gt?: any | null;
  dataExtrato_gte?: any | null;
  recorrenciaIndex?: number | null;
  recorrenciaIndex_not?: number | null;
  recorrenciaIndex_in?: number[] | null;
  recorrenciaIndex_not_in?: number[] | null;
  recorrenciaIndex_lt?: number | null;
  recorrenciaIndex_lte?: number | null;
  recorrenciaIndex_gt?: number | null;
  recorrenciaIndex_gte?: number | null;
  formaPagamento?: FormaPagamento | null;
  formaPagamento_not?: FormaPagamento | null;
  formaPagamento_in?: FormaPagamento[] | null;
  formaPagamento_not_in?: FormaPagamento[] | null;
  contaFinanceiraId?: string | null;
  categoriaId?: string | null;
  profissionalId?: string | null;
  procedimentoId?: string | null;
  procedimentoId_in?: string[] | null;
  pacienteId?: string | null;
  convenioId?: string | null;
  centroCustoId?: string | null;
  recorrenciaId?: string | null;
}

export interface TransferenciaDataInput {
  descricao: string;
  valor: number;
  data: any;
  contaFinanceiraOrigemId: string;
  contaFinanceiraDestinoId: string;
}

export interface TussLookupWhereInput {
  OR?: TussLookupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  codigo?: string | null;
  codigo_not?: string | null;
  codigo_in?: string[] | null;
  codigo_not_in?: string[] | null;
  codigo_contains?: string | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  tabela?: TussLookupTabela | null;
  tabela_not?: TussLookupTabela | null;
  tabela_in?: TussLookupTabela[] | null;
  tabela_not_in?: TussLookupTabela[] | null;
}

export interface UpdateConvenioByProfissionalDataInput {
  convenioId: string;
  codigoOperadora?: string | null;
}

export interface UpdateConvenioPlanoByProfissionalDataInput {
  planoId: string;
  periodoCarenciaDias: number;
}

export interface UpdateEquipeDataInput {
  userId: string;
  tipoAcesso: UserEquipeTipoAcesso;
}

export interface UpdateMeDataInput {
  nome: string;
  email: string;
  sexo: Sexo;
  celular?: string | null;
  tratamento?: string | null;
  conselho?: string | null;
  conselhoUf?: string | null;
  conselhoRegistro?: string | null;
  rqe?: string | null;
  profissao?: string | null;
  cbo?: string | null;
  imagemId?: string | null;
  newPassword?: string | null;
}

export interface UpdateUserDataInput {
  nome: string;
  email: string;
  adminAccount: boolean;
  sexo: Sexo;
  celular?: string | null;
  tratamento?: string | null;
  conselho?: string | null;
  conselhoUf?: string | null;
  conselhoRegistro?: string | null;
  rqe?: string | null;
  profissao?: string | null;
  cbo?: string | null;
  imagemId?: string | null;
  newPassword?: string | null;
  clinicas: ClinicaOnUserInput[];
  profissionaisIds: string[];
}

export interface UserWhereInput {
  OR?: UserWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  nome?: string | null;
  nome_not?: string | null;
  nome_in?: string[] | null;
  nome_not_in?: string[] | null;
  nome_contains?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_contains?: string | null;
  tipo?: UserTipo | null;
  tipo_not?: UserTipo | null;
  tipo_in?: UserTipo[] | null;
  tipo_not_in?: UserTipo[] | null;
  adminAccount?: boolean | null;
  sexo?: Sexo | null;
  sexo_not?: Sexo | null;
  sexo_in?: Sexo[] | null;
  sexo_not_in?: Sexo[] | null;
  celular?: string | null;
  celular_not?: string | null;
  celular_in?: string[] | null;
  celular_not_in?: string[] | null;
  celular_contains?: string | null;
  tratamento?: string | null;
  tratamento_not?: string | null;
  tratamento_in?: string[] | null;
  tratamento_not_in?: string[] | null;
  tratamento_contains?: string | null;
  conselho?: string | null;
  conselho_not?: string | null;
  conselho_in?: string[] | null;
  conselho_not_in?: string[] | null;
  conselho_contains?: string | null;
  conselhoRegistro?: string | null;
  conselhoRegistro_not?: string | null;
  conselhoRegistro_in?: string[] | null;
  conselhoRegistro_not_in?: string[] | null;
  conselhoRegistro_contains?: string | null;
  profissao?: string | null;
  profissao_not?: string | null;
  profissao_in?: string[] | null;
  profissao_not_in?: string[] | null;
  profissao_contains?: string | null;
  cbo?: string | null;
  cbo_not?: string | null;
  cbo_in?: string[] | null;
  cbo_not_in?: string[] | null;
  cbo_contains?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
