import { apolloClient } from '@/plugins/apollo';

import CepQuery from '@/graphql/service/query/cep.graphql';

import { ICepQuery, ICepQueryVariables } from '@/typings';

export const ServiceGraphql = {
  query: {
    CepQuery,
  },

  async cep(variables: ICepQueryVariables) {
    return apolloClient
      .query<ICepQuery>({
        query: CepQuery,
        variables,
      })
      .then(({ data }) => data?.cep);
  },
};
