import CreatePrescricaoModeloMutation from '@/graphql/profissional/prescricaoModelo/createPrescricaoModelo.graphql';
import DeleteManyPrescricoesModelosMutation from '@/graphql/profissional/prescricaoModelo/deleteManyPrescricoesModelos.graphql';
import DeletePrescricaoModeloMutation from '@/graphql/profissional/prescricaoModelo/deletePrescricaoModelo.graphql';
import PrescricaoModeloQuery from '@/graphql/profissional/prescricaoModelo/prescricaoModelo.graphql';
import PrescricoesModelosQuery from '@/graphql/profissional/prescricaoModelo/prescricoesModelos.graphql';
import UpdatePrescricaoModeloMutation from '@/graphql/profissional/prescricaoModelo/updatePrescricaoModelo.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreatePrescricaoModeloMutation,
  ICreatePrescricaoModeloMutationVariables,
  IDeleteManyPrescricoesModelosMutation,
  IDeleteManyPrescricoesModelosMutationVariables,
  IDeletePrescricaoModeloMutation,
  IDeletePrescricaoModeloMutationVariables,
  IPrescricaoModeloQuery,
  IPrescricaoModeloQueryVariables,
  IPrescricoesModelosQuery,
  IPrescricoesModelosQueryVariables,
  IUpdatePrescricaoModeloMutation,
  IUpdatePrescricaoModeloMutationVariables,
} from '@/typings';

export const PrescricaoModeloGraphql = {
  query: {
    PrescricaoModeloQuery,
    PrescricoesModelosQuery,
  },

  async createPrescricaoModelo(
    variables: ICreatePrescricaoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreatePrescricaoModeloMutation>({
        mutation: CreatePrescricaoModeloMutation,
        variables,
      })
      .then(({ data }) => data?.createPrescricaoModelo);
  },

  async deleteManyPrescricoesModelos(
    variables: IDeleteManyPrescricoesModelosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyPrescricoesModelosMutation>({
        mutation: DeleteManyPrescricoesModelosMutation,
        variables,
        refetchQueries: getRefetchQueries([PrescricoesModelosQuery]),
      })
      .then(({ data }) => data?.deleteManyPrescricoesModelos);
  },

  async deletePrescricaoModelo(
    variables: IDeletePrescricaoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeletePrescricaoModeloMutation>({
        mutation: DeletePrescricaoModeloMutation,
        variables,
        refetchQueries: getRefetchQueries([PrescricoesModelosQuery]),
      })
      .then(({ data }) => data?.deletePrescricaoModelo);
  },

  async prescricaoModelo(variables: IPrescricaoModeloQueryVariables) {
    return apolloClient
      .query<IPrescricaoModeloQuery>({
        query: PrescricaoModeloQuery,
        variables,
      })
      .then(({ data }) => data?.prescricaoModelo);
  },

  async prescricoesModelos(variables: IPrescricoesModelosQueryVariables) {
    return apolloClient
      .query<IPrescricoesModelosQuery>({
        query: PrescricoesModelosQuery,
        variables,
      })
      .then(({ data }) => data?.prescricoesModelos);
  },

  async updatePrescricaoModelo(
    variables: IUpdatePrescricaoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdatePrescricaoModeloMutation>({
        mutation: UpdatePrescricaoModeloMutation,
        variables,
      })
      .then(({ data }) => data?.updatePrescricaoModelo);
  },
};
