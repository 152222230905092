import CreateTissGuiaHonorariosMutation from '@/graphql/tiss/tissGuiaHonorarios/createTissGuiaHonorarios.graphql';
import DeleteTissGuiaHonorariosMutation from '@/graphql/tiss/tissGuiaHonorarios/deleteTissGuiaHonorarios.graphql';
import TissGuiaHonorariosQuery from '@/graphql/tiss/tissGuiaHonorarios/tissGuiaHonorarios.graphql';
import TissGuiasHonorariosQuery from '@/graphql/tiss/tissGuiaHonorarios/tissGuiasHonorarios.graphql';
import UpdateTissGuiaHonorariosMutation from '@/graphql/tiss/tissGuiaHonorarios/updateTissGuiaHonorarios.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissGuiaHonorariosMutation,
  ICreateTissGuiaHonorariosMutationVariables,
  IDeleteTissGuiaHonorariosMutation,
  IDeleteTissGuiaHonorariosMutationVariables,
  ITissGuiaHonorariosQuery,
  ITissGuiaHonorariosQueryVariables,
  ITissGuiasHonorariosQuery,
  ITissGuiasHonorariosQueryVariables,
  IUpdateTissGuiaHonorariosMutation,
  IUpdateTissGuiaHonorariosMutationVariables,
} from '@/typings';

export const TissGuiaHonorariosGraphql = {
  query: {
    TissGuiaHonorariosQuery,
    TissGuiasHonorariosQuery,
  },

  async createTissGuiaHonorarios(
    variables: ICreateTissGuiaHonorariosMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTissGuiaHonorariosMutation>({
        mutation: CreateTissGuiaHonorariosMutation,
        variables,
      })
      .then(({ data }) => data?.createTissGuiaHonorarios);
  },

  async deleteTissGuiaHonorarios(
    variables: IDeleteTissGuiaHonorariosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTissGuiaHonorariosMutation>({
        mutation: DeleteTissGuiaHonorariosMutation,
        variables,
        refetchQueries: getRefetchQueries([TissGuiasHonorariosQuery]),
      })
      .then(({ data }) => data?.deleteTissGuiaHonorarios);
  },

  async tissGuiaHonorarios(variables: ITissGuiaHonorariosQueryVariables) {
    return apolloClient
      .query<ITissGuiaHonorariosQuery>({
        query: TissGuiaHonorariosQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiaHonorarios);
  },

  async tissGuiasHonorarios(variables: ITissGuiasHonorariosQueryVariables) {
    return apolloClient
      .query<ITissGuiasHonorariosQuery>({
        query: TissGuiasHonorariosQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiasHonorarios);
  },

  async updateTissGuiaHonorarios(
    variables: IUpdateTissGuiaHonorariosMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTissGuiaHonorariosMutation>({
        mutation: UpdateTissGuiaHonorariosMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissGuiaHonorarios);
  },
};
