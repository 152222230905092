import { createComponent } from '@/lib/vue';

interface IProps {
  title?: string;
}

export const PageTitle = createComponent<IProps>({
  name: 'PageTitle',
  props: {
    title: { type: String },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <h1 class="p-4 truncate text-headline text-coolGray-800">
          {defaultSlot || props.title}
        </h1>
      );
    };
  },
});
