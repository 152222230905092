import {
  IInputItems,
  TissProcedimentoSexo,
  TissProcedimentoTabela,
} from '@/typings';

export const tissProcedimentoTabelas: IInputItems[] = [
  {
    label: '00 - Tabela Própria das Operadoras',
    value: TissProcedimentoTabela.TB_00,
  },
  {
    label: '90 - Tabela Própria Pacote Odontológico',
    value: TissProcedimentoTabela.TB_90,
  },
  {
    label: '98 - Tabela Própria de Pacotes',
    value: TissProcedimentoTabela.TB_98,
  },
];

export const tissProcedimentoSexos: IInputItems[] = [
  {
    label: 'Ambos/Indiferente',
    value: TissProcedimentoSexo.AMBOS,
  },
  {
    label: 'Feminino',
    value: TissProcedimentoSexo.FEMININO,
  },
  {
    label: 'Masculino',
    value: TissProcedimentoSexo.MASCULINO,
  },
  {
    label: 'Não se aplica',
    value: TissProcedimentoSexo.NAO_SE_APLICA,
  },
];
