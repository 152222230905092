import { permissionDeniedError } from '@/routes/utils';
import { userIsAdminAccount } from '@/store/utils/auth';

import { NavigationGuard } from 'vue-router';

export const configuracoesAccountGuard: NavigationGuard = (to, _from, next) => {
  if (!userIsAdminAccount()) {
    return permissionDeniedError(to, next);
  }

  return next();
};
