import { DeleteDialog } from '@/components/dialog/types/DeleteDialog';
import { FormDialog } from '@/components/dialog/types/FormDialog';
import { OptionsDialog } from '@/components/dialog/types/OptionsDialog';
import { PasswordDialog } from '@/components/dialog/types/PasswordDialog';
import {
  PdfDialog,
  PdfDialogToolbarBtns,
} from '@/components/dialog/types/PdfDialog';
import { ImageUploadDialog } from '@/components/upload/images/ImageUploadDialog';
import {
  IDeleteDialogParams,
  IFormDialogParams,
  IImageUploadDialogConfirmation,
  IImageUploadDialogParams,
  IModel,
  IOptionsDialogParams,
  IPasswordConfirmationDialog,
  IPdfDialogParams,
} from '@/typings';
import { showDialog } from './dialog.helpers';

export const SystemDialogs = {
  password() {
    return showDialog<null, IPasswordConfirmationDialog>({
      component: PasswordDialog,
      title: 'Confirmar senha',
      params: null,
    });
  },

  delete(params: IDeleteDialogParams) {
    return showDialog<IDeleteDialogParams>({
      component: DeleteDialog,
      title: 'Confirmação de exclusão',
      params,
    });
  },

  options(title: string, params: IOptionsDialogParams) {
    return showDialog<IOptionsDialogParams>({
      component: OptionsDialog,
      width: 380,
      title,
      params,
    });
  },

  form<T extends IModel>({
    title,
    ...params
  }: { title: string } & IFormDialogParams<T>) {
    return showDialog<IFormDialogParams<T>>({
      component: FormDialog,
      title,
      params,
    });
  },

  pdf({
    title,
    ...params
  }: { title: string } & Omit<IPdfDialogParams, 'nome'>) {
    return showDialog<IPdfDialogParams>({
      component: PdfDialog,
      title,
      fullWidth: true,
      toolbarBtns: PdfDialogToolbarBtns,
      params: {
        ...params,
        nome: title,
      },
    });
  },

  uploadImage(params: IImageUploadDialogParams) {
    return showDialog<IImageUploadDialogParams, IImageUploadDialogConfirmation>(
      {
        component: ImageUploadDialog,
        title: 'Selecionar uma imagem',
        params,
      },
    );
  },
};
