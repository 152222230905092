import { apolloClient } from '@/plugins/apollo';

import CobrancaQuery from '@/graphql/system/cobranca/cobranca.graphql';
import CobrancasQuery from '@/graphql/system/cobranca/cobrancas.graphql';

import {
  ICobrancaQuery,
  ICobrancaQueryVariables,
  ICobrancasQuery,
  ICobrancasQueryVariables,
} from '@/typings';

export const CobrancaGraphql = {
  query: {
    CobrancaQuery,
    CobrancasQuery,
  },

  async cobranca(variables: ICobrancaQueryVariables) {
    return apolloClient
      .query<ICobrancaQuery>({
        query: CobrancaQuery,
        variables,
      })
      .then(({ data }) => data?.cobranca);
  },

  async cobrancas(variables: ICobrancasQueryVariables) {
    return apolloClient
      .query<ICobrancasQuery>({
        query: CobrancasQuery,
        variables,
      })
      .then(({ data }) => data?.cobrancas);
  },
};
