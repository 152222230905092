import { AtestadoModeloGraphql } from '@/graphql/profissional/atestadoModelo/AtestadoModeloGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  AtestadoModeloDataInput,
  IAtestadoModeloModel,
  IServiceOnSuccessProfissionalArgs,
} from '@/typings';

export const AtestadoModeloService = {
  async getById(id: string) {
    try {
      return await AtestadoModeloGraphql.atestadoModelo({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    profissionalId: string;
    model: IAtestadoModeloModel;
  }) {
    try {
      const result = await AtestadoModeloGraphql.createAtestadoModelo({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toAtestadoModeloDataInput(model),
      });

      onSuccess({
        result,
        msg: 'criado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async delete({ id, profissionalId }: { id: string; profissionalId: string }) {
    try {
      await validateDelete({ text: 'este atestado modelo' }, async () => {
        const result = await AtestadoModeloGraphql.deleteAtestadoModelo({ id });

        onSuccess({
          result,
          msg: 'excluído',
          profissionalId,
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os atestados modelos selecionados',
            'o atestado modelo selecionado',
          ),
        },
        async () => {
          const result = await AtestadoModeloGraphql.deleteManyAtestadosModelos(
            { ids },
          );

          if (result) {
            const msg = pluralize(
              ids,
              'Atestados modelos excluídos',
              'Atestado modelo excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    profissionalId,
    model,
  }: {
    id: string;
    profissionalId: string;
    model: IAtestadoModeloModel;
  }) {
    try {
      const result = await AtestadoModeloGraphql.updateAtestadoModelo({
        id: Validate.require(id, 'id'),
        data: toAtestadoModeloDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },
};

function toAtestadoModeloDataInput({
  nome,
  titulo,
  texto,
}: IAtestadoModeloModel): AtestadoModeloDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    titulo: Validate.require(titulo, 'titulo'),
    texto: Validate.require(texto, 'texto'),
  };
}

function onSuccess<T>({
  result,
  msg,
  profissionalId,
}: IServiceOnSuccessProfissionalArgs<T>) {
  if (result) {
    router.push(
      Routes.app.configProfissionais(profissionalId).prontuario.atestados.index,
    );

    toastSuccess(`Atestado modelo ${msg} com sucesso`);
  }
}
