import { AgendamentoDeleteDialog } from '@/modules/agenda/components/dialogs/AgendamentoDeleteDialog';
import { AgendamentoViewDialog } from '@/modules/agenda/components/dialogs/AgendamentoViewDialog';
import {
  IAgendamentoDeleteConfirmationDialog,
  IAgendamentoDeleteDialogParams,
} from '@/typings';
import { showDialog } from './dialog.helpers';

export const AgendamentoDialogs = {
  delete(params: IAgendamentoDeleteDialogParams) {
    return showDialog<
      IAgendamentoDeleteDialogParams,
      IAgendamentoDeleteConfirmationDialog
    >({
      component: AgendamentoDeleteDialog,
      width: 400,
      title: 'Cancelar agendamento',
      params,
    });
  },

  view(bloqueado: boolean) {
    return showDialog({
      component: AgendamentoViewDialog,
      width: bloqueado ? 500 : 700,
      title: 'Detalhes do agendamento',
      params: null,
    });
  },
};
