import CreateUserMutation from '@/graphql/system/user/createUser.graphql';
import DeleteManyUsersMutation from '@/graphql/system/user/deleteManyUsers.graphql';
import DeleteUserMutation from '@/graphql/system/user/deleteUser.graphql';
import UpdateEquipeMutation from '@/graphql/system/user/updateEquipe.graphql';
import UpdateMeMutation from '@/graphql/system/user/updateMe.graphql';
import UpdateUserMutation from '@/graphql/system/user/updateUser.graphql';
import UserQuery from '@/graphql/system/user/user.graphql';
import UsersQuery from '@/graphql/system/user/users.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { AuthLoginService } from '@/lib/services';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateUserMutation,
  ICreateUserMutationVariables,
  IDeleteManyUsersMutation,
  IDeleteManyUsersMutationVariables,
  IDeleteUserMutation,
  IDeleteUserMutationVariables,
  IUpdateEquipeMutation,
  IUpdateEquipeMutationVariables,
  IUpdateMeMutation,
  IUpdateMeMutationVariables,
  IUpdateUserMutation,
  IUpdateUserMutationVariables,
  IUserQuery,
  IUserQueryVariables,
  IUsersQuery,
  IUsersQueryVariables,
} from '@/typings';

export const UserGraphql = {
  query: {
    UserQuery,
    UsersQuery,
  },

  async createUser(variables: ICreateUserMutationVariables) {
    const { data } = await apolloClient.mutate<ICreateUserMutation>({
      mutation: CreateUserMutation,
      variables,
    });

    refetchQueries(data);

    return data?.createUser;
  },

  async deleteManyUsers(variables: IDeleteManyUsersMutationVariables) {
    const { data } = await apolloClient.mutate<IDeleteManyUsersMutation>({
      mutation: DeleteManyUsersMutation,
      variables,
      refetchQueries: getRefetchQueries([UsersQuery]),
    });

    refetchQueries(data);

    return data?.deleteManyUsers;
  },

  async deleteUser(variables: IDeleteUserMutationVariables) {
    const { data } = await apolloClient.mutate<IDeleteUserMutation>({
      mutation: DeleteUserMutation,
      variables,
      refetchQueries: getRefetchQueries([UsersQuery]),
    });

    refetchQueries(data);

    return data?.deleteUser;
  },

  async updateEquipe(variables: IUpdateEquipeMutationVariables) {
    const { data } = await apolloClient.mutate<IUpdateEquipeMutation>({
      mutation: UpdateEquipeMutation,
      variables,
    });

    refetchQueries(data);

    return data?.updateEquipe;
  },

  async updateMe(variables: IUpdateMeMutationVariables) {
    const { data } = await apolloClient.mutate<IUpdateMeMutation>({
      mutation: UpdateMeMutation,
      variables,
    });

    refetchQueries(data);

    return data?.updateMe;
  },

  async updateUser(variables: IUpdateUserMutationVariables) {
    const { data } = await apolloClient.mutate<IUpdateUserMutation>({
      mutation: UpdateUserMutation,
      variables,
    });

    refetchQueries(data);

    return data?.updateUser;
  },

  async user(variables: IUserQueryVariables) {
    const { data } = await apolloClient.query<IUserQuery>({
      query: UserQuery,
      variables,
    });

    return data?.user;
  },

  async users(variables: IUsersQueryVariables) {
    const { data } = await apolloClient.query<IUsersQuery>({
      query: UsersQuery,
      variables,
    });

    return data?.users;
  },
};

function refetchQueries<T>(data: T) {
  if (data) {
    AuthLoginService.loadMyUser(true);
  }
}
