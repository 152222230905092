import CreateTissGuiaSpSadtMutation from '@/graphql/tiss/tissGuiaSpSadt/createTissGuiaSpSadt.graphql';
import DeleteTissGuiaSpSadtMutation from '@/graphql/tiss/tissGuiaSpSadt/deleteTissGuiaSpSadt.graphql';
import TissGuiaSpSadtQuery from '@/graphql/tiss/tissGuiaSpSadt/tissGuiaSpSadt.graphql';
import TissGuiasSpSadtQuery from '@/graphql/tiss/tissGuiaSpSadt/tissGuiasSpSadt.graphql';
import UpdateTissGuiaSpSadtMutation from '@/graphql/tiss/tissGuiaSpSadt/updateTissGuiaSpSadt.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissGuiaSpSadtMutation,
  ICreateTissGuiaSpSadtMutationVariables,
  IDeleteTissGuiaSpSadtMutation,
  IDeleteTissGuiaSpSadtMutationVariables,
  ITissGuiaSpSadtQuery,
  ITissGuiaSpSadtQueryVariables,
  ITissGuiasSpSadtQuery,
  ITissGuiasSpSadtQueryVariables,
  IUpdateTissGuiaSpSadtMutation,
  IUpdateTissGuiaSpSadtMutationVariables,
} from '@/typings';

export const TissGuiaSpSadtGraphql = {
  query: {
    TissGuiaSpSadtQuery,
    TissGuiasSpSadtQuery,
  },

  async createTissGuiaSpSadt(
    variables: ICreateTissGuiaSpSadtMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTissGuiaSpSadtMutation>({
        mutation: CreateTissGuiaSpSadtMutation,
        variables,
      })
      .then(({ data }) => data?.createTissGuiaSpSadt);
  },

  async deleteTissGuiaSpSadt(
    variables: IDeleteTissGuiaSpSadtMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTissGuiaSpSadtMutation>({
        mutation: DeleteTissGuiaSpSadtMutation,
        variables,
        refetchQueries: getRefetchQueries([TissGuiasSpSadtQuery]),
      })
      .then(({ data }) => data?.deleteTissGuiaSpSadt);
  },

  async tissGuiaSpSadt(variables: ITissGuiaSpSadtQueryVariables) {
    return apolloClient
      .query<ITissGuiaSpSadtQuery>({
        query: TissGuiaSpSadtQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiaSpSadt);
  },

  async tissGuiasSpSadt(variables: ITissGuiasSpSadtQueryVariables) {
    return apolloClient
      .query<ITissGuiasSpSadtQuery>({
        query: TissGuiasSpSadtQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiasSpSadt);
  },

  async updateTissGuiaSpSadt(
    variables: IUpdateTissGuiaSpSadtMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTissGuiaSpSadtMutation>({
        mutation: UpdateTissGuiaSpSadtMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissGuiaSpSadt);
  },
};
