import { DateHelpers } from '@/lib/helpers/date.helpers';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import {
  IAtendimentoFragment,
  IAtendimentoFragment_atestados,
} from '@/typings';
import { BtnImprimir } from '../../utils/BtnImprimir';
import { TimelineBodyHtml } from '../body/TimelineBodyHtml';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineAtestados = createComponent<IProps>({
  name: 'TimelineAtestados',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { handleImprimir } = useEvents(props);

    return () => {
      const { atestados } = props.atendimento;

      if (!atestados || !atestados.length) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Atestados</h2>

          <v-card outlined class="mt-2">
            <v-expansion-panels accordion multiple class="elevation-0">
              {atestados.map(v => (
                <v-expansion-panel key={v.id}>
                  <v-expansion-panel-header>
                    <div class="flex items-center">
                      <div class="text-title text-lg">
                        <span>{v.titulo}</span>

                        <span class="ml-2 font-normal">
                          ({DateHelpers.formatDate(v.data)})
                        </span>
                      </div>

                      <BtnImprimir
                        class="mr-6"
                        handleImprimir={() => handleImprimir(v)}
                      />
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <TimelineBodyHtml html={v.texto} />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              ))}
            </v-expansion-panels>
          </v-card>
        </div>
      );
    };
  },
});

function useEvents(props: IProps) {
  function handleImprimir(v: IAtendimentoFragment_atestados) {
    return ProntuarioService.pdf.atestado({
      atendimentoId: props.atendimento.id,
      atestadoId: v.id,
      pacienteId: props.atendimento.paciente.id,
    });
  }

  return { handleImprimir };
}
