import { IDateRangeModel } from '..';

export interface IDashboardState {
  header: IDashboardHeader;
  pacientes: IDashboardPacientesState;
  atendimentos: IDashboardAtendimentosState;
  atendimentosPeriodo: IDashboardResult;
  distribuicaoEtaria: IDashboardResult;
  agendamentoStatus: IAgendamentoStatusState;
  aniversariantes: IDashboardAniversariantes;
}

interface IDashboardPacientesState {
  loading: boolean;
  porSexo: IDashboardResultData[];
  porTipo: IDashboardResultData[];
}

interface IDashboardAtendimentosState {
  loading: boolean;
  duracao: number;
  porConvenios: IDashboardResultData[];
  procedimentosRealizados: IDashboardResultData[];
}

export interface IDashboardHeader {
  periodo: IDateRangeModel | null;
  profissionalId: string | null;
}

export interface IDashboardResult {
  loading: boolean;
  data: IDashboardResultData[];
}

export interface IDashboardResultData {
  label: string;
  value: number;
}

export interface IDashboardAniversariantes {
  loading: boolean;
  params: IDashboardAniversariantesParams;
  data: IDashboardAniversariantesData[];
}

export interface IDashboardAniversariantesParams {
  dia: number | null;
  mes: number | null;
  mesTodo: boolean;
}

export interface IDashboardAniversariantesData {
  id: string;
  nome: string;
  dataNascimento: string;
  telefoneCasa: string | null;
  celular: string | null;
  imagemUrl: string | null;
}

export interface IAgendamentoStatusState {
  faltou: number;
  cancelado: number;
  confirmado: number;
  atendido: number;
  agendado: number;
  loading: boolean;
}

export enum IDashboardStatusLabel {
  AGENDADO = 'AGENDADO',
  ATENDIDO = 'ATENDIDO',
  CANCELADO = 'CANCELADO',
  CONFIRMADO = 'CONFIRMADO',
  FALTOU = 'FALTOU',
}
