import { PrescricaoModeloGraphql } from '@/graphql/profissional/prescricaoModelo/PrescricaoModeloGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IPrescricaoModeloFormModel,
  IServiceOnSuccessProfissionalArgs,
  PrescricaoModeloDataInput,
} from '@/typings';

export const PrescricaoModeloService = {
  async getById(id: string) {
    try {
      return await PrescricaoModeloGraphql.prescricaoModelo({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(
    {
      profissionalId,
      model,
    }: {
      profissionalId: string;
      model: IPrescricaoModeloFormModel;
    },
    goToIndex = true,
  ) {
    try {
      const result = await PrescricaoModeloGraphql.createPrescricaoModelo({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toPrescricaoModeloDataInput(model),
      });

      onSuccess(
        {
          result,
          msg: 'cadastrada',
          profissionalId,
        },
        goToIndex,
      );
    } catch (error) {
      handleError(error);
    }
  },

  async delete({ id, profissionalId }: { id: string; profissionalId: string }) {
    try {
      await validateDelete({ text: 'esta prescrição modelo' }, async () => {
        const result = await PrescricaoModeloGraphql.deletePrescricaoModelo({
          id,
        });

        onSuccess({
          result,
          msg: 'excluída',
          profissionalId,
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'as prescrições modelos selecionadas',
            'a prescrição modelo selecionada',
          ),
        },
        async () => {
          const result =
            await PrescricaoModeloGraphql.deleteManyPrescricoesModelos({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Prescrições modelos excluídas',
              'Prescrição modelo excluída',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    profissionalId,
    model,
  }: {
    id: string;
    profissionalId: string;
    model: IPrescricaoModeloFormModel;
  }) {
    try {
      const result = await PrescricaoModeloGraphql.updatePrescricaoModelo({
        id: Validate.require(id, 'id'),
        data: toPrescricaoModeloDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterada',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },
};

function toPrescricaoModeloDataInput({
  nome,
  medicamentos,
}: IPrescricaoModeloFormModel): PrescricaoModeloDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    medicamentos: medicamentos.map(v => ({
      id: v.new ? null : v.id,
      medicamento: Validate.require(v.medicamento, 'medicamento'),
      quantidade: v.quantidade,
      posologia: v.posologia,
      observacao: v.observacao,
    })),
  };
}

function onSuccess<T>(
  { result, msg, profissionalId }: IServiceOnSuccessProfissionalArgs<T>,
  goToIndex = true,
) {
  if (result) {
    if (goToIndex) {
      router.push(
        Routes.app.configProfissionais(profissionalId).prontuario.prescricoes
          .index,
      );
    }

    toastSuccess(`Prescrição modelo ${msg} com sucesso`);
  }
}
