import { IInputItems } from '@/typings';

export const cbo: IInputItems[] = [
  { label: 'Geneticista', value: '201115' },
  {
    label: 'Pesquisador em biologia de microorganismos e parasitas',
    value: '203015',
  },
  { label: 'Físico médico', value: '213150' },
  { label: 'Biólogo', value: '221105' },
  { label: 'Médico acupunturista', value: '225105' },
  { label: 'Médico alergista e imunologista', value: '225110' },
  { label: 'Médico anatomopatologista', value: '225148' },
  { label: 'Médico angiologista', value: '225115' },
  { label: 'Médico cardiologista', value: '225120' },
  { label: 'Médico cirurgião cardiovascular', value: '225210' },
  { label: 'Médico cirurgião de cabeça e pescoço', value: '225215' },
  { label: 'Médico cirurgião do aparelho digestivo', value: '225220' },
  { label: 'Médico cirurgião geral', value: '225225' },
  { label: 'Médico cirurgião pediátrico', value: '225230' },
  { label: 'Médico cirurgião plástico', value: '225235' },
  { label: 'Médico cirurgião torácico', value: '225240' },
  { label: 'Médico citopatologista', value: '225305' },
  { label: 'Médico clínico', value: '225125' },
  { label: 'Médico de família e comunidade', value: '225130' },
  { label: 'Médico dermatologista', value: '225135' },
  { label: 'Médico do trabalho', value: '225140' },
  { label: 'Médico em endoscopia', value: '225310' },
  { label: 'Médico em medicina de tráfego', value: '225145' },
  { label: 'Médico em medicina intensiva', value: '225150' },
  { label: 'Médico em medicina nuclear', value: '225315' },
  {
    label: 'Médico em radiologia e diagnóstico por imagem',
    value: '225320',
  },
  { label: 'Médico endocrinologista e metabologista', value: '225155' },
  { label: 'Médico fisiatra', value: '225160' },
  { label: 'Médico foniatra', value: '225245' },
  { label: 'Médico gastroenterologista', value: '225165' },
  { label: 'Médico generalista', value: '225170' },
  { label: 'Médico geneticista', value: '225175' },
  { label: 'Médico geriatra', value: '225180' },
  { label: 'Médico ginecologista e obstetra', value: '225250' },
  { label: 'Médico Hematologista', value: '225185' },
  { label: 'Médico Hemoterapeuta', value: '225190' },
  { label: 'Médico Homeopata', value: '225195' },
  { label: 'Médico infectologista', value: '225103' },
  { label: 'Médico legista', value: '225106' },
  { label: 'Médico Mastologista', value: '225255' },
  { label: 'Médico Nefrologista', value: '225109' },
  { label: 'Médico  neurocirurgião', value: '225260' },
  { label: 'Médico neurofisiologista', value: '225350' },
  { label: 'Médico neurologista', value: '225112' },
  { label: 'Médico nutrologista', value: '225118' },
  { label: 'Médico oftalmologista', value: '225265' },
  { label: 'Médico oncologista', value: '225121' },
  { label: 'Médico ortopedista e traumatologista', value: '225270' },
  { label: 'Médico otorrinolaringologista', value: '225275' },
  { label: 'Médico patologista clínico', value: '225325' },
  { label: 'Médico pediatra', value: '225124' },
  { label: 'Médico pneumologista', value: '225127' },
  { label: 'Médico proctologista', value: '225280' },
  { label: 'Médico psiquiatra', value: '225133' },
  { label: 'Médico radioterapeuta', value: '225330' },
  { label: 'Médico reumatologista', value: '225136' },
  { label: 'Médico sanitarista', value: '225139' },
  { label: 'Médico urologista', value: '225285' },
  { label: 'Cirurgião dentista - auditor', value: '223204' },
  { label: 'Cirurgião dentista - clínico geral', value: '223208' },
  { label: 'Cirurgião dentista - endodontista', value: '223212' },
  { label: 'Cirurgião dentista - epidemiologista', value: '223216' },
  { label: 'Cirurgião dentista - estomatologista', value: '223220' },
  { label: 'Cirurgião dentista - implantodontista', value: '223224' },
  { label: 'Cirurgião dentista - odontogeriatra', value: '223228' },
  { label: 'Cirurgião dentista - odontologista legal', value: '223232' },
  { label: 'Cirurgião dentista - odontopediatra', value: '223236' },
  {
    label: 'Cirurgião dentista - ortopedista e ortodontista',
    value: '223240',
  },
  { label: 'Cirurgião dentista - patologista bucal', value: '223244' },
  { label: 'Cirurgião dentista - periodontista', value: '223248' },
  {
    label: 'Cirurgião dentista - protesiólogo bucomaxilofacial',
    value: '223252',
  },
  { label: 'Cirurgião dentista - protesista', value: '223256' },
  { label: 'Cirurgião dentista - radiologista', value: '223260' },
  { label: 'Cirurgião dentista - reabilitador oral', value: '223264' },
  {
    label: 'Cirurgião dentista - traumatologista bucomaxilofacial',
    value: '223268',
  },
  { label: 'Cirurgião dentista de saúde coletiva', value: '223272' },
  { label: 'Enfermeiro', value: '223505' },
  { label: 'Fisioterapeuta geral', value: '223605' },
  { label: 'Ortoptista', value: '223910' },
  { label: 'Peripatologista', value: '223620' },
  { label: 'Terapeuta ocupacional', value: '223905' },
  { label: 'Nutricionista', value: '223710' },
  { label: 'Fonoaudiólogo', value: '223810' },
  { label: 'Psicopedagogo', value: '239425' },
  { label: 'Psicólogo clínico', value: '251510' },
  { label: 'Neuropsicólogo', value: '251545' },
  { label: 'Psicanalista', value: '251550' },
  { label: 'Assistente social', value: '251605' },
  { label: 'Técnico de enfermagem', value: '322205' },
  { label: 'Técnico de enfermagem psiquiátrica', value: '322220' },
  { label: 'Instrumentador cirúrgico', value: '322225' },
  { label: 'Auxiliar de enfermagem', value: '322230' },
  { label: 'Cuidador de idosos', value: '516210' },
  // ********** Incluidos em Dezembro/2013 - versão 3.01.00 ***********
  { label: 'Médico oncologista clínico', value: '225121' },
  { label: 'Médico patologista', value: '225325' },
  {
    label: 'Cirurgião dentista - odontologia do trabalho',
    value: '223276',
  },
  { label: 'Cirurgião dentista - dentística', value: '223280' },
  {
    label: 'Cirurgião dentista - disfunção temporomandibular e dor orofacial',
    value: '223284',
  },
  {
    label:
      'Cirurgião dentista - odontologia para pacientes com necessidades especiais',
    value: '223288',
  },
  {
    label: 'Cirurgião-dentista da estratégia de saúde da família',
    value: '223293',
  },
  { label: 'Médico cancerologista pediátrico', value: '225122' },
  { label: 'Médico da estratégia de saúde da família', value: '225142' },
  { label: 'Médico anestesiologista', value: '225151' },
  { label: 'Médico em cirurgia vascular', value: '225203' },
  { label: 'Médico cancerologista cirúrgico', value: '225290' },
  { label: 'Médico cirurgião da mão', value: '225295' },
  {
    label: 'Médico patologista clínico / medicina laboratorial',
    value: '225335',
  },
  { label: 'Médico hemoterapeuta', value: '225340' },
  { label: 'Médico hiperbarista', value: '225345' },
  // ********** Incluidos em Abril/2016 - versão 3.02.02 ***********
  { label: 'Fisioterapeuta respiratória', value: '223625' },
  { label: 'Fisioterapeuta neurofuncional', value: '223630' },
  {
    label: 'Fisioterapeuta traumato-ortopédica funcional',
    value: '223635',
  },
  { label: 'Fisioterapeuta osteopata', value: '223640' },
  { label: 'Fisioterapeuta quiropraxista', value: '223645' },
  { label: 'Fisioterapeuta acupunturista', value: '223650' },
  { label: 'Fisioterapeuta esportivo', value: '223655' },
  { label: 'Fisioterapeuta  do trabalho', value: '223660' },
  // ********** Incluidos em Maio/2016 0- versão 3.03.00 ***********
  { label: 'Farmacêutico industrial', value: '223435' },
  { label: 'Farmacêutico em saúde pública', value: '223430' },
  { label: 'Farmacêutico analista clínico', value: '223415' },
  { label: 'Farmacêutico', value: '223405' },
  { label: 'Farmacêutico de alimentos', value: '223420' },
  { label: 'Farmacêutico toxicologista', value: '223440' },
  { label: 'Farmacêutico hospitalar e clínico', value: '223445' },
  {
    label: 'Farmacêutico práticas integrativas e complementares',
    value: '223425',
  },
  {
    label: 'Enfermeiro da estratégia de saúde da família',
    value: '223565',
  },
  { label: 'Enfermeiro sanitarista', value: '223560' },
  { label: 'Enfermeiro puericultor e pediátrico', value: '223555' },
  { label: 'Enfermeiro psiquiátrico', value: '223550' },
  { label: 'Enfermeiro obstétrico', value: '223545' },
  { label: 'Enfermeiro neonatologista', value: '223540' },
  { label: 'Enfermeiro nefrologista', value: '223535' },
  { label: 'Enfermeiro do trabalho', value: '223530' },
  { label: 'Enfermeiro de terapia intensiva', value: '223525' },
  { label: 'Enfermeiro de centro cirúrgico', value: '223520' },
  { label: 'Enfermeiro de bordo', value: '223515' },
  { label: 'Enfermeiro auditor', value: '223510' },
  { label: 'Perfusionista', value: '223570' },
  { label: 'Dietista', value: '223705' },
  { label: 'Fonoaudiólogo em voz', value: '223845' },
  { label: 'Fonoaudiólogo em linguagem', value: '223830' },
  { label: 'Fonoaudiólogo em disfagia', value: '223825' },
  { label: 'Fonoaudiólogo em motricidade orofacial', value: '223835' },
  { label: 'Fonoaudiólogo em saúde coletiva', value: '223840' },
  { label: 'Fonoaudiólogo em audiologia', value: '223820' },
  { label: 'Fonoaudiólogo educacional', value: '223815' },
  { label: 'Médico antroposófico', value: '225154' },
  // ********** Incluido em Março/2017 - versão 3.03.02 ***********
  { label: 'Biólogo', value: '221105' },
  //  CBO-S desconhecido ou não informado pelo solicitante
  { label: 'Outro', value: '999999' },
];
