import { useState } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { AgendamentoStatus } from '@/typings';
import { computed, ComputedRef, SetupContext } from '@vue/composition-api';
import { AgendamentoViewActions } from './actions';

export const AgendamentoViewHeader = createComponent({
  name: 'AgendamentoViewHeader',
  setup(props, ctx) {
    const { $dataAgendamento, $createdBy, $agendamento, $isDone } =
      useComputeds(ctx);

    return () => (
      <div class="flex items-center mx-4 mt-4">
        <h1 class="font-normal text-title text-secondary">
          {$dataAgendamento.value}
        </h1>

        {$createdBy.value && (
          <div
            aria-label={$createdBy.value}
            data-balloon-length="medium"
            data-balloon-pos="down"
            class="cursor-auto"
          >
            <v-icon class="ml-2 text-gray-700">{MyIcons.information}</v-icon>
          </div>
        )}

        {!$agendamento.value.bloqueado && actionsIconRow({ $isDone })}
      </div>
    );
  },
});

function useComputeds(ctx: SetupContext) {
  const $agendamento = useState(s => s.agenda.agendamento!);

  const $profissionalId = useState(s => s.agenda.profissionalId!);

  const $createdBy = computed(() => {
    const { createdBy, createdAt } = $agendamento.value;

    if (!createdBy) return null;

    const data = DateHelpers.formatDate(createdAt);
    const hora = DateHelpers.formatHour(createdAt);

    return `Agendado por ${createdBy.nome} em ${data} às ${hora}`;
  });

  const $dataAgendamento = computed(() =>
    DateHelpers.dataAgendamento($agendamento.value),
  );

  const $isDone = useState(
    s =>
      s.agenda.agendamento?.status === AgendamentoStatus.PACIENTE_ATENDIDO ||
      !!s.agenda.agendamento?.atendimento?.id,
  );

  return {
    $agendamento,
    $profissionalId,
    $createdBy,
    $dataAgendamento,
    $isDone,
  };
}

const actionsIconRow = ({ $isDone }: { $isDone: ComputedRef<boolean> }) => (
  <div class="flex">
    <v-spacer />

    {!$isDone.value && (
      <v-btn
        icon
        onClick={AgendamentoViewActions.handleEditar}
        aria-label="Editar agendamento"
        data-balloon-pos="down"
      >
        <v-icon color="secondary">{MyIcons.edit}</v-icon>
      </v-btn>
    )}

    {!$isDone.value && (
      <v-btn
        icon
        aria-label="Excluir agendamento"
        data-balloon-pos="down"
        onClick={AgendamentoViewActions.handleExcluir}
      >
        <v-icon color="secondary">{MyIcons.remove}</v-icon>
      </v-btn>
    )}

    <v-btn
      icon
      onClick={AgendamentoViewActions.handleLog}
      aria-label="Logs do agendamento"
      data-balloon-pos="left"
    >
      <v-icon color="secondary">{MyIcons.historico}</v-icon>
    </v-btn>
  </div>
);
