import {
  IInputProps,
  useInput,
  useInputClearable,
} from '@/lib/composables/inputs/useInput';
import { formatNumber, isNumeric, onlyNumbers } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import { IFormInputNumber } from '@/typings';
import { computed, ref, Ref, SetupContext } from '@vue/composition-api';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';

interface IProps extends IInputProps {
  value: number | null;
  input: IFormInputNumber;
}

interface IEvents {
  onInput: (value: number | null) => void;
}

export const InputNumber = createComponent<IProps, IEvents>({
  name: 'InputNumber',
  props: {
    value: { type: Number },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $integer, $precision, $formattedValue } = useComputeds(props);

    const $inputEl = ref<HTMLInputElement | null>(null);

    const { events } = useEvents({
      props,
      ctx,
      $inputEl,
      $integer,
      $precision,
    });

    const { $label, $isRequired } = useInput(props);

    return () => (
      <v-text-field
        ref={$inputEl}
        value={$formattedValue.value}
        name={props.name}
        outlined
        label={$label.value}
        type="text"
        errorMessages={props.errorMessages}
        required={$isRequired.value}
        prependInnerIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        prefix={props.input.prefix}
        suffix={props.input.suffix}
        disabled={props.input.disabled}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        loading={props.input.loading}
        autofocus={props.input.autofocus}
        clearable={props.input.clearable}
        hideDetails={!!props.input.hideDetails}
        {...events}
      />
    );
  },
});

function useComputeds(props: IProps) {
  const $integer = computed(
    () => !!props.input.integer || props.input.decimalPlaces === 0,
  );

  const $precision = computed(() => {
    if ($integer.value) return 0;

    const { decimalPlaces } = props.input;

    return isNumber(decimalPlaces) ? decimalPlaces : 2;
  });

  const $formattedValue = computed(() =>
    isNumber(props.value)
      ? formatNumber(props.value, {
          money: props.input.type === 'money',
          integer: $integer.value,
          precision: $precision.value,
        })
      : props.value,
  );

  return { $integer, $precision, $formattedValue };
}

function useEvents({
  props,
  ctx,
  $inputEl,
  $integer,
  $precision,
}: {
  props: IProps;
  ctx: SetupContext;
  $inputEl: Ref<HTMLInputElement | null>;
} & Pick<ReturnType<typeof useComputeds>, '$integer' | '$precision'>) {
  const { handleClear } = useInputClearable({ ctx, $inputEl });

  function emitInput(newValue: string) {
    const value = parseValue({
      value: newValue,
      precision: $precision.value,
      isInteger: $integer.value,
    });

    ctx.emit('input', value);
  }

  function handleKeyPress(evt: KeyboardEvent) {
    if (
      !isNumeric(evt.key) ||
      isOverMaxLength({
        props,
        precision: $precision.value,
      })
    ) {
      return evt.preventDefault();
    }
  }

  const events = {
    on: {
      'click:clear': handleClear,
      input: emitInput,
      keypress: handleKeyPress,
    },
  };

  return { events };
}

function isOverMaxLength({
  props,
  precision,
}: {
  props: IProps;
  precision: number;
}) {
  const maxLength = props.input.maxLength || precision + 9;

  return onlyNumbers(props.value).length >= maxLength;
}

function parseValue({
  value,
  precision,
  isInteger: integer,
}: {
  value: string | number;
  precision: number;
  isInteger: boolean;
}) {
  if (!value) return 0;

  value = onlyNumbers(value);

  // Ajustar quantidade de zeros à esquerda
  value = value.padStart(precision + 1, '0');

  // Incluir ponto na casa correta, conforme a precisão configurada
  const integerDigits = value.substring(0, value.length - precision);
  const fractionDigits = value.substring(
    value.length - precision,
    value.length,
  );

  value = `${integerDigits}.${fractionDigits}`;

  if (isNaN(value)) {
    return 0;
  } else if (integer) {
    return parseInt(onlyNumbers(value), 10);
  }

  return parseFloat(value);
}
