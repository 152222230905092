import { checkHasUser } from '@/lib/auth/auth';
import { getProfissionalId, userIsProfissionalSaude } from '@/store/utils/auth';
import { IRouteAgendamentoNewOptions, TransacaoTipo } from '@/typings';
import { Route } from 'vue-router';
import { transacaoTipoToQueryParams } from './utils';

class AuthRoutes {
  esqueceuSenhaPart1 = '/auth/esqueceu-senha';
  login = '/auth';
  clinicas = '/auth/clinicas';
  logout = '/auth/logout';
  signUpPart1 = '/auth/cadastro';
  signUpPart2 = '/auth/cadastro/informacoes';

  convite = (token: string) => `/auth/convite/${token}`;
  esqueceuSenhaPart2 = (token: string) => `/auth/esqueceu-senha/${token}`;

  isAuth = (route: Route) => route.path.includes('/auth');
  isEsqueceuSenhaPart1 = (route: Route) =>
    route.path === this.esqueceuSenhaPart1;
  isEsqueceuSenhaPart2 = (route: Route) =>
    route.path.includes('/auth/esqueceu-senha/');
  isLogin = (route: Route) => route.path === this.login;
  isSignUpPart1 = (route: Route) => route.path === this.signUpPart1;
  isSignUpPart2 = (route: Route) => route.path === this.signUpPart2;
  isClinicas = (route: Route) => route.path === this.clinicas;
}

// tslint:disable:member-ordering
class AppRoutes {
  dashboard = '/dashboard';

  agenda = (profissionalId: string) => ({
    index: `/agenda/${profissionalId}`,
    listaEspera: `/agenda/${profissionalId}/lista-espera`,
    agendamentos: {
      new: (options?: IRouteAgendamentoNewOptions) => {
        const path = `/agenda/${profissionalId}/agendamento/new`;

        if (options) {
          if ('data' in options && 'horaInicial' in options) {
            return {
              path,
              query: {
                dt: options.data,
                hi: options.horaInicial,
              },
            };
          } else if ('pacienteId' in options) {
            return {
              path,
              query: { pid: options.pacienteId },
            };
          } else if ('listaEsperaId' in options) {
            return {
              path,
              query: { leid: options.listaEsperaId },
            };
          }
        }

        return path;
      },
      edit: (agendamentoId: string) =>
        `/agenda/${profissionalId}/agendamento/${agendamentoId}`,
      recebimento: ({
        agendamentoId,
        numTransacoes,
      }: {
        agendamentoId: string;
        numTransacoes: number;
      }) => ({
        path: `/agenda/${profissionalId}/agendamento/${agendamentoId}/recebimento`,
        query: { rec: numTransacoes.toString() },
      }),
    },
  });

  get home() {
    if (!checkHasUser()) return this.logout;

    if (userIsProfissionalSaude()) {
      return this.dashboard;
    }

    const profissionalId = getProfissionalId();

    if (!profissionalId) return this.logout;

    return this.agenda(profissionalId).index;
  }

  get logout() {
    const auth = new AuthRoutes();

    return auth.logout;
  }

  /**
   * Pacientes
   */
  pacientes = {
    index: '/pacientes',
    new: {
      convenios: '/pacientes/new/convenios',
      dadosComplementares: '/pacientes/new/dados-complementares',
      parentes: '/pacientes/new/parentes',
      index: '/pacientes/new',
    },
    edit: {
      convenios: (pacienteId: string) => `/pacientes/${pacienteId}/convenios`,
      dadosComplementares: (pacienteId: string) =>
        `/pacientes/${pacienteId}/dados-complementares`,
      parentes: (pacienteId: string) => `/pacientes/${pacienteId}/parentes`,
      historicoConsultas: (pacienteId: string) =>
        `/pacientes/${pacienteId}/historico-consultas`,
      logAlteracoes: (pacienteId: string) =>
        `/pacientes/${pacienteId}/log-alteracoes`,
      index: (pacienteId: string, query?: { agendamentoId?: string }) => ({
        path: `/pacientes/${pacienteId}`,
        query: query && { aid: query.agendamentoId },
      }),
    },
  };

  /**
   * Prontuários
   */
  prontuario = (pacienteId: string, query?: { agendamentoId?: string }) => ({
    path: `/prontuarios/${pacienteId}`,
    query: query && { aid: query.agendamentoId },
  });

  /**
   * Finanças
   */
  financas = {
    index: '/financas',
    fluxoCaixa: '/financas/fluxo-caixa',
    extrato: '/financas/extrato',
    transferencia: (tipo?: TransacaoTipo | null) => ({
      path: '/financas/transferencia',
      query: transacaoTipoToQueryParams(tipo),
    }),
    receitas: {
      index: '/financas/receitas',
      new: (tipo?: TransacaoTipo | null) => ({
        path: '/financas/receitas/new',
        query: transacaoTipoToQueryParams(tipo),
      }),
      edit: (receitaId: string, tipo?: TransacaoTipo | null) => ({
        path: `/financas/receitas/${receitaId}`,
        query: transacaoTipoToQueryParams(tipo),
      }),
    },
    despesas: {
      index: '/financas/despesas',
      new: (tipo?: TransacaoTipo | null) => ({
        path: '/financas/despesas/new',
        query: transacaoTipoToQueryParams(tipo),
      }),
      edit: (despesaId: string, tipo?: TransacaoTipo | null) => ({
        path: `/financas/despesas/${despesaId}`,
        query: transacaoTipoToQueryParams(tipo),
      }),
    },
    relatorios: {
      receitas: '/financas/relatorios/receitas',
      despesas: '/financas/relatorios/despesas',
    },
  };

  /**
   * Relatórios
   */
  relatorio = {
    atendimento: {
      index: '/relatorios/atendimento',
      retorno: '/relatorios/atendimento/retorno',
      periodo: '/relatorios/atendimento/periodo',
      cid: '/relatorios/atendimento/cid',
      indicacao: '/relatorios/atendimento/indicacao',
      faltas: '/relatorios/atendimento/faltas',
    },
    financas: {
      despesas: '/relatorios/financas/despesas',
      receitas: '/relatorios/financas/receitas',
      fluxoCaixa: '/relatorios/financas/fluxo-caixa',
    },
    aniversariantes: '/relatorios/aniversariantes',
  };

  /**
   * Configurações da Conta
   */
  configAccount = {
    assinatura: '/configuracoes/assinatura',
    historicoCobrancas: '/configuracoes/historico-de-cobrancas',
    clinicas: {
      index: '/configuracoes/clinicas',
      new: '/configuracoes/clinicas/new',
      edit: (clinicaId: string) => `/configuracoes/clinicas/${clinicaId}`,
    },
    usuarios: {
      index: '/configuracoes/usuarios',
      new: '/configuracoes/usuarios/new',
      newProfissional: {
        path: '/configuracoes/usuarios/new',
        query: { tipo: 'profissional' },
      },
      newRecepcionista: {
        path: '/configuracoes/usuarios/new',
        query: { tipo: 'recepcionista' },
      },
      edit: (userId: string) => `/configuracoes/usuarios/${userId}`,
    },
    permissoesEnvio: '/configuracoes/permissoes-de-envio',
    smsEnviados: '/configuracoes/sms-enviados',
  };

  meuPerfil = '/configuracoes/meu-perfil';

  /**
   * Configurações da Clínica
   */
  configClinica = {
    perfilClinica: '/configuracoes/clinica/perfil',
    convenios: {
      index: '/configuracoes/clinica/convenios',
      new: '/configuracoes/clinica/convenios/new',
      edit: (convenioId: string) =>
        `/configuracoes/clinica/convenios/${convenioId}`,
    },
    financas: {
      categoriasFinanceiras: {
        index: '/configuracoes/clinica/categorias-financeiras',
        new: '/configuracoes/clinica/categorias-financeiras/new',
        newChild: (tipo: TransacaoTipo, parentId: string) => ({
          path: '/configuracoes/clinica/categorias-financeiras/new',
          query: { tipo, parentId },
        }),
        edit: (categoriaId: string) =>
          `/configuracoes/clinica/categorias-financeiras/${categoriaId}`,
      },
      centrosCusto: {
        index: '/configuracoes/clinica/centros-de-custo',
        new: '/configuracoes/clinica/centros-de-custo/new',
        edit: (centroId: string) =>
          `/configuracoes/clinica/centros-de-custo/${centroId}`,
      },
      configuracoesFinanceiras:
        '/configuracoes/clinica/configuracoes-financeiras',
      contasFinanceiras: {
        index: '/configuracoes/clinica/contas-financeiras',
        new: '/configuracoes/clinica/contas-financeiras/new',
        edit: (contaFinanceiraId: string) =>
          `/configuracoes/clinica/contas-financeiras/${contaFinanceiraId}`,
      },
    },
    equipe: '/configuracoes/clinica/equipe',
    regrasRepasse: '/configuracoes/clinica/regras-repasse',
    exportarDados: '/configuracoes/clinica/exportar-dados',
  };

  /**
   * Configurações Profissionais
   */
  configProfissionais = (profissionalId: string) => ({
    agenda: `/configuracoes/profissionais/${profissionalId}/agenda`,
    convenios: `/configuracoes/profissionais/${profissionalId}/convenios`,
    procedimentos: {
      index: `/configuracoes/profissionais/${profissionalId}/procedimentos`,
      new: `/configuracoes/profissionais/${profissionalId}/procedimentos/new`,
      edit: (procedimentoId: string) =>
        `/configuracoes/profissionais/${profissionalId}/procedimentos/${procedimentoId}`,
    },
    prontuario: {
      secoes: {
        index: `/configuracoes/profissionais/${profissionalId}/prontuarios`,
        new: `/configuracoes/profissionais/${profissionalId}/prontuarios/new`,
        edit: (protuarioId: string) =>
          `/configuracoes/profissionais/${profissionalId}/prontuarios/${protuarioId}`,
      },
      prescricoes: {
        index: `/configuracoes/profissionais/${profissionalId}/prescricoes`,
        new: `/configuracoes/profissionais/${profissionalId}/prescricoes/new`,
        edit: (prescricaoId: string) =>
          `/configuracoes/profissionais/${profissionalId}/prescricoes/${prescricaoId}`,
      },
      exames: {
        index: `/configuracoes/profissionais/${profissionalId}/exames`,
        new: `/configuracoes/profissionais/${profissionalId}/exames/new`,
        edit: (exameId: string) =>
          `/configuracoes/profissionais/${profissionalId}/exames/${exameId}`,
      },
      atestados: {
        index: `/configuracoes/profissionais/${profissionalId}/atestados`,
        new: `/configuracoes/profissionais/${profissionalId}/atestados/new`,
        edit: (atestadoId: string) =>
          `/configuracoes/profissionais/${profissionalId}/atestados/${atestadoId}`,
      },
      compartilhamento: `/configuracoes/profissionais/${profissionalId}/compartilhamento-de-prontuario`,
    },
    impressao: `/configuracoes/profissionais/${profissionalId}/impressao`,
    alertas: `/configuracoes/profissionais/${profissionalId}/alertas`,
    integracoes: `/configuracoes/profissionais/${profissionalId}/integracoes`,
  });

  /**
   * Logs
   */
  logs = {
    agenda: '/logs/agenda',
    agendamento: (agendamentoId: string) => `/logs/agenda/${agendamentoId}`,
    financas: '/logs/financas',
  };

  /**
   * Tiss
   */
  tiss = (profissionalId: string) => ({
    guiasConsultas: {
      index: `/tiss/${profissionalId}/guias-consultas`,
      new: (query?: { agendamentoId?: string }) => ({
        path: `/tiss/${profissionalId}/guias-consultas/new`,
        query: query && { aid: query.agendamentoId },
      }),
      edit: (guiaId: string) =>
        `/tiss/${profissionalId}/guias-consultas/${guiaId}`,
    },
    guiasHonorarios: {
      index: `/tiss/${profissionalId}/guias-honorarios`,
      new: (query?: { agendamentoId?: string }) => ({
        path: `/tiss/${profissionalId}/guias-honorarios/new`,
        query: query && { aid: query.agendamentoId },
      }),
      edit: (guiaId: string) =>
        `/tiss/${profissionalId}/guias-honorarios/${guiaId}`,
    },
    guiasSpSadt: {
      index: `/tiss/${profissionalId}/guias-sp-sadt`,
      new: (query?: { agendamentoId?: string }) => ({
        path: `/tiss/${profissionalId}/guias-sp-sadt/new`,
        query: query && { aid: query.agendamentoId },
      }),
      edit: (guiaId: string) =>
        `/tiss/${profissionalId}/guias-sp-sadt/${guiaId}`,
    },
    guiasOdontologicas: {
      index: `/tiss/${profissionalId}/guias-odontologicas`,
      new: (query?: { agendamentoId?: string }) => ({
        path: `/tiss/${profissionalId}/guias-odontologicas/new`,
        query: query && { aid: query.agendamentoId },
      }),
      edit: (guiaId: string) =>
        `/tiss/${profissionalId}/guias-odontologicas/${guiaId}`,
    },
    lotes: {
      index: `/tiss/${profissionalId}/lotes`,
      new: `/tiss/${profissionalId}/lotes/new`,
      edit: (loteId: string) => `/tiss/${profissionalId}/lotes/${loteId}`,
    },
    procedimentos: {
      index: `/tiss/${profissionalId}/procedimentos`,
      new: `/tiss/${profissionalId}/procedimentos/new`,
      edit: (tabelaId: string) =>
        `/tiss/${profissionalId}/procedimentos/${tabelaId}`,
    },
  });

  /**
   * Contatos
   */
  contatos = {
    index: '/contatos',
    new: '/contatos/new',
    edit: (contatoId: string) => `/contatos/${contatoId}`,
  };

  /**
   * Cid
   */
  cid = {
    index: '/cid',
    capitulo: (capituloNum: string) => `/cid/capitulo/${capituloNum}`,
    grupo: (capituloNum: string, grupoNum: string) =>
      `/cid/capitulo/${capituloNum}/grupo/${grupoNum}`,
  };
}

class ErrorRoutes {
  permissionDenied = '/erro/403';
  notFound = '/erro/404';
  internalServerError = '/erro/500';
}

export class Routes {
  static auth = new AuthRoutes();

  static app = new AppRoutes();

  static error = new ErrorRoutes();
}
