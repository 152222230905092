import { DateRangePresets } from '@/lib/constants/dateRangePresets';
import { mapDashboardAniversariantes } from '@/lib/helpers/dashboard';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import {
  IAppState,
  IDashboardAniversariantesData,
  IDashboardAniversariantesParams,
  IDashboardAtendimentosCardQuery,
  IDashboardPacientesCardQuery,
  IDashboardPageQuery,
  IDashboardResultData,
  IDashboardState,
  IDashboardStatusLabel,
  IDateRangeModel,
} from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): IDashboardState => ({
  header: {
    periodo: null,
    profissionalId: null,
  },
  atendimentosPeriodo: {
    loading: false,
    data: [],
  },
  atendimentos: {
    loading: false,
    duracao: 0,
    porConvenios: [],
    procedimentosRealizados: [],
  },
  distribuicaoEtaria: {
    loading: false,
    data: [],
  },
  pacientes: {
    loading: false,
    porSexo: [],
    porTipo: [],
  },
  agendamentoStatus: {
    agendado: 0,
    atendido: 0,
    cancelado: 0,
    confirmado: 0,
    faltou: 0,
    loading: false,
  },
  aniversariantes: {
    loading: false,
    params: {
      dia: DateHelpers.today().day,
      mes: DateHelpers.today().month,
      mesTodo: false,
    },
    data: [],
  },
});

export const DashboardModule: Module<IDashboardState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IDashboardState>('dashboard');

export class DashboardState {
  static setProfissional(profissionalId: string) {
    commit(s => {
      s.header.profissionalId = profissionalId;
    });
  }

  static setPeriodo(periodo: IDateRangeModel | null) {
    commit(s => {
      s.header.periodo = periodo || DateRangePresets.ultimos30dias;
    });
  }

  static setLoadingAll(loading = true) {
    commit(s => {
      s.atendimentosPeriodo.loading = loading;
      s.atendimentos.loading = loading;
      s.pacientes.loading = loading;
      s.distribuicaoEtaria.loading = loading;
      s.agendamentoStatus.loading = loading;
    });
  }

  static loadPageQuery(res: IDashboardPageQuery) {
    DashboardState.setAtendimentosPeriodo({
      data: res.atendimentosPeriodo,
    });

    DashboardState.setAtendimentos({
      data: {
        duracao: res.atendimentosDuracao,
        porConvenios: res.atendimentosPorConvenios,
        procedimentosRealizados: res.procedimentosRealizados,
      },
    });

    DashboardState.setDistribuicaoEtaria({
      data: res.distribuicaoEtaria,
    });

    DashboardState.setPacientes({
      data: {
        porSexo: res.pacientesPorSexo,
        porTipo: res.pacientesPorTipo,
      },
    });

    DashboardState.setStatusCount({
      data: res.agendamentoStatus,
    });

    DashboardState.setAniversariantes({
      data: mapDashboardAniversariantes(res.pacientesAniversariantes.nodes),
    });
  }

  static setAtendimentosPeriodo({
    data,
    loading = false,
  }: {
    data?: IDashboardResultData[] | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.atendimentosPeriodo.data = data || s.atendimentosPeriodo.data || [];
      s.atendimentosPeriodo.loading = !!loading;
    });
  }

  static setAtendimentos({
    data,
    loading = false,
  }: {
    data?: IDashboardAtendimentosCardQuery | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.atendimentos.duracao = data?.duracao.duracaoSegundos || 0;

      s.atendimentos.porConvenios = data?.porConvenios || [];

      s.atendimentos.procedimentosRealizados =
        data?.procedimentosRealizados || [];

      s.atendimentos.loading = loading;
    });
  }

  static setPacientes({
    data,
    loading = false,
  }: {
    data?: IDashboardPacientesCardQuery | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.pacientes.porSexo = data?.porSexo || [];

      s.pacientes.porTipo = data?.porTipo || [];

      s.pacientes.loading = loading;
    });
  }

  static setDistribuicaoEtaria({
    data,
    loading = false,
  }: {
    data?: IDashboardResultData[] | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.distribuicaoEtaria.data = data || s.distribuicaoEtaria.data || [];
      s.distribuicaoEtaria.loading = loading;
    });
  }

  static setStatusCount({
    data = [],
    loading = false,
  }: {
    data?: IDashboardResultData[] | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.agendamentoStatus = {
        agendado:
          data?.find(f => f.label === IDashboardStatusLabel.AGENDADO)?.value ||
          0,
        atendido:
          data?.find(f => f.label === IDashboardStatusLabel.ATENDIDO)?.value ||
          0,
        cancelado:
          data?.find(f => f.label === IDashboardStatusLabel.CANCELADO)?.value ||
          0,
        confirmado:
          data?.find(f => f.label === IDashboardStatusLabel.CONFIRMADO)
            ?.value || 0,
        faltou:
          data?.find(f => f.label === IDashboardStatusLabel.FALTOU)?.value || 0,
        loading,
      };
    });
  }

  static setAniversariantes({
    data,
    loading = false,
  }: {
    data?: IDashboardAniversariantesData[] | null;
    loading?: boolean;
  }) {
    commit(s => {
      s.aniversariantes.data = data || s.aniversariantes.data;

      s.aniversariantes.loading = loading;
    });
  }

  static setAniversariantesParams(v: IDashboardAniversariantesParams) {
    commit(s => {
      s.aniversariantes.params = {
        ...v,
        dia: v.mesTodo ? 0 : v.dia,
      };
    });
  }
}
