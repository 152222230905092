import { getAccessToken } from '@/lib/auth/auth';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { REST_URI } from '@/lib/helpers/env';
import { handleError, Validate } from '@/lib/helpers/error';
import { IPdfLambdaEventBody, PdfType } from '@/typings/pdf.t';
import axios from 'axios';

export const ProntuarioPdf = {
  async completoById({
    atendimentoId,
    pacienteId,
  }: {
    atendimentoId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_COMPLETO,
      atendimentosIds: [Validate.require(atendimentoId, 'atendimentoId')],
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Prontuário',
        data,
      });
    }
  },

  async completoAll(pacienteId: string) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_COMPLETO,
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Prontuário',
        data,
      });
    }
  },

  async atestado({
    atendimentoId,
    atestadoId,
    pacienteId,
  }: {
    atendimentoId: string;
    atestadoId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_ATESTADO,
      atendimentoId: Validate.require(atendimentoId, 'atendimentoId'),
      atestadoId: Validate.require(atestadoId, 'atestadoId'),
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Atestado',
        data,
      });
    }
  },

  async exameParticular({
    atendimentoId,
    exameId,
    pacienteId,
  }: {
    atendimentoId: string;
    exameId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_EXAME_PARTICULAR,
      atendimentoId: Validate.require(atendimentoId, 'atendimentoId'),
      exameId: Validate.require(exameId, 'exameId'),
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Exame',
        data,
      });
    }
  },

  async exameSADT({
    atendimentoId,
    exameId,
    pacienteId,
  }: {
    atendimentoId: string;
    exameId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_EXAME_SADT,
      atendimentoId: Validate.require(atendimentoId, 'atendimentoId'),
      exameId: Validate.require(exameId, 'exameId'),
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Exame SADT',
        data,
      });
    }
  },

  async prescricaoEspecial({
    atendimentoId,
    prescricaoId,
    pacienteId,
  }: {
    atendimentoId: string;
    prescricaoId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_PRESCRICAO_ESPECIAL,
      atendimentoId: Validate.require(atendimentoId, 'atendimentoId'),
      prescricaoId: Validate.require(prescricaoId, 'prescricaoId'),
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Prescrição de Controle Especial',
        data,
      });
    }
  },

  async prescricaoNormal({
    atendimentoId,
    prescricaoId,
    pacienteId,
  }: {
    atendimentoId: string;
    prescricaoId: string;
    pacienteId: string;
  }) {
    const data = await fetchPdf({
      type: PdfType.PRONTUARIO_PRESCRICAO_NORMAL,
      atendimentoId: Validate.require(atendimentoId, 'atendimentoId'),
      prescricaoId: Validate.require(prescricaoId, 'prescricaoId'),
      pacienteId: Validate.require(pacienteId, 'pacienteId'),
    });

    if (data) {
      return DialogHelpers.system.pdf({
        title: 'Prescrição',
        data,
      });
    }
  },
};

async function fetchPdf(body: IPdfLambdaEventBody) {
  try {
    const resp = await axios.post(`${REST_URI}/pdf`, body, {
      headers: {
        Accept: 'application/pdf',
        Authorization: getAccessToken(),
      },
      responseType: 'blob',
    });

    const file = new Blob([resp.data], { type: 'application/pdf' });

    return URL.createObjectURL(file);
  } catch (error) {
    handleError(error);
  }
}
