import { AppLoading } from '@/components/loading/AppLoading';
import { useState } from '@/lib/composables';
import { createComponent } from '@/lib/vue';

export const MainLayout = createComponent({
  name: 'Main',
  setup(props, ctx) {
    const $loading = useState(s => s.loading);

    return () => (
      <v-app light id="app">
        <router-view class={{ hidden: $loading.value }} />

        <vue-snotify />

        <AppLoading loading={$loading.value} />
      </v-app>
    );
  },
});
