import { IInputItems } from '@/typings';

export const tipoAtendimentoOdonto: IInputItems[] = [
  // É um subconjunto da tabela tipo de atendimento
  { label: '1 - Tratamento odontologico', value: '1' },
  { label: '2 - Exame radiologico', value: '2' },
  { label: '3 - Ortodontia', value: '3' },
  { label: '4 - Urgencia/emergencia', value: '4' },
  { label: '5 - Auditoria', value: '5' },
];
