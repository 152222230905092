import { Validator } from '@/lib/form/validator';
import {
  IFormInputType,
  IFormInputValidations,
  IFormSchema,
  IFormSubmitted,
  IFormValidationErrors,
  IInputValue,
  IModel,
} from '@/typings';
import isNumber from 'lodash/isNumber';

export function validationSummary({
  schema,
  model,
  form,
  customErrors = [],
}: {
  schema;
  model: IModel;
  form: IFormSubmitted;
  customErrors: readonly IFormValidationErrors[];
}) {
  if (!schema || !model || !form) return [...customErrors];

  return getFormValidations(schema)
    .map(({ name, parent, input, validations, label }) => ({
      label,
      error: mapInputValidationsErrors({
        validations,
        form,
        input,
        value: parent ? model[parent][name] : model[name],
      })[0],
    }))
    .filter(({ error }) => !!error)
    .concat(customErrors)
    .filter(Boolean);
}

interface IFormValidations {
  name: string;
  label: string;
  validations: IFormInputValidations;
  input: IFormInputType;
  parent?: string;
}

function getFormValidations(schema: IFormSchema<any>, parentName?: string) {
  if (!schema) return [];

  return Object.entries(schema).reduce<IFormValidations[]>(
    (formValidations, [name, input]: [string, IFormInputType]) => {
      if (!input || input.disabled || input.hidden) {
        return formValidations;
      }

      if (!input.type) {
        // input is a formSchema
        return [
          ...formValidations,
          ...getFormValidations(input as IFormSchema<any>, name),
        ];
      }

      if (shouldValidate(input)) {
        const { label, validations = {} } = input as IFormInputType;

        return [
          ...formValidations,
          {
            name,
            label: validations.customName || label || '',
            validations,
            input,
            parent: parentName,
          },
        ];
      }

      return formValidations;
    },
    [],
  );
}

export function mapInputValidationsErrors({
  validations,
  form,
  input,
  value,
}: {
  validations: IFormInputValidations | undefined;
  form: IFormSubmitted | null | undefined;
  input: IFormInputType;
  value: IInputValue;
}): Array<string> {
  if (!validations || !form || !shouldValidate(input)) {
    return [];
  }

  const errors: Array<string | null> = [];

  const {
    required,
    confirmPassword,
    minLength,
    gt,
    lte,
    email,
    date,
    maxDate,
    minDate,
    time,
    maxHour,
    minHour,
    cpf,
    cnpj,
    cpfOrCnpj,
  } = validations || {};

  const validator = new Validator(form);

  if (required) {
    errors.push(validator.required(value));
  }

  if (email || input.type === 'email') {
    errors.push(validator.email(value));
  }

  if (date || input.type === 'date') {
    errors.push(validator.date(value));
  }

  if (time || input.type === 'time') {
    errors.push(validator.time(value));
  }

  if (confirmPassword) {
    const valueToConfirm = value?.[confirmPassword.fieldToConfirm];

    errors.push(validator.confirmPassword(value, valueToConfirm));
  }

  if (isNumber(minLength)) {
    errors.push(validator.minLength(value, minLength));
  }

  if (gt) {
    errors.push(validator.gt(value, gt));
  }

  if (lte) {
    errors.push(validator.lte(value, lte));
  }

  if (maxDate) {
    errors.push(validator.maxDate(value, maxDate));
  }

  if (minDate) {
    errors.push(validator.minDate(value, minDate));
  }

  if (maxHour) {
    errors.push(validator.maxHour(value, maxHour));
  }

  if (minHour) {
    errors.push(validator.minHour(value, minHour));
  }

  if (input.type === 'text') {
    if (cpf || input.mask === 'cpf') {
      errors.push(validator.cpf(value));
    }

    if (cnpj || input.mask === 'cnpj') {
      errors.push(validator.cnpj(value));
    }

    if (cpfOrCnpj || input.mask === 'cpfOrCnpj') {
      errors.push(validator.cpfOrCnpj(value));
    }
  }

  return errors.filter(Boolean) as string[];
}

function shouldValidate(input: IFormInputType) {
  const isEmail = input.type === 'email';
  const isDate = input.type === 'date';
  const isTime = input.type === 'time';
  const hasMask = input.type === 'text' && !!input.mask;

  return !!input.validations || isEmail || isDate || isTime || hasMask;
}
