import {
  FormaPagamento,
  IAgendamentoRecebimentoProcedimentoModel,
  IDateRangeModel,
  IFormSchema,
  IModel,
  TipoRepeticao,
  TransacaoTipo,
} from '@/typings';

export interface ITransacaoGeralModel extends IModel {
  descricao: string | null;
  valor: number | null;
  contaFinanceiraId: string | null;
  categoriaId: string | null | undefined;
  vencimento: string | null;
  pago: boolean;
  dataPagamento: string | null;
  formaPagamento: FormaPagamento | null;
  centroCustoId: string | null | undefined;
}

export interface ITransacaoDadosAdicionaisModel extends IModel {
  profissionalId: string | null | undefined;
  procedimentoId: string | null | undefined;
  pacienteId: string | null | undefined;
  convenioId: string | null | undefined;
}

export enum TransacaoRepeticaoModo {
  PARCELAS = 'PARCELAS',
  REPETICAO = 'REPETICAO',
}

export interface IRecorrenciaModel {
  id: string;
  index: number | null;
  numero: number;
}

export interface ITransacaoRepeticaoGeralModel extends IModel {
  repetir: boolean;
  modo: TransacaoRepeticaoModo | null;
  recorrencia?: IRecorrenciaModel | null;
}

export interface ITransacaoRepeticaoOrrenciasModel extends IModel {
  tipoRepeticao: TipoRepeticao | null;
  numeroRepeticoes: number | null;
}

export interface ITransacaoRepeticaoParcelasModel extends IModel {
  numeroParcelas: number | null;
  valorEntrada: number | null;
  valorParcela?: number | null;
}

export interface ITransacaoRepeticaoModel extends IModel {
  geral: ITransacaoRepeticaoGeralModel;
  repeticoes: ITransacaoRepeticaoOrrenciasModel;
  parcelas: ITransacaoRepeticaoParcelasModel;
}

export interface ITransacaoAgendamentoModel extends IModel {
  desconto: number | null;
  procedimentos: IAgendamentoRecebimentoProcedimentoModel[];
}

export interface ITransacaoRepeticaoFormSchema extends IModel {
  geral: IFormSchema<ITransacaoRepeticaoGeralModel>;
  repeticoes: IFormSchema<ITransacaoRepeticaoOrrenciasModel>;
  parcelas: IFormSchema<ITransacaoRepeticaoParcelasModel>;
}

export interface ITransacaoFormSchema extends IModel {
  geral: IFormSchema<ITransacaoGeralModel>;
  dadosAdicionais: IFormSchema<ITransacaoDadosAdicionaisModel>;
}

export interface ITransacaoFormModel extends IModel {
  geral: ITransacaoGeralModel;
  dadosAdicionais: ITransacaoDadosAdicionaisModel;
  repeticao: ITransacaoRepeticaoModel;
  agendamento: ITransacaoAgendamentoModel | null;
}

export interface ITransacaoFilterModel extends IModel {
  data: IDateRangeModel | null;
  descricao: string | null;
  categoriaId: string | null;
  centroCustoId: string | null;
  contaFinanceiraId: string | null;
  convenioId: string | null;
  formaPagamento: FormaPagamento | null;
  pacienteId: string | null;
  procedimentosIds: string[] | null;
  profissionalId: string | null;
  status: boolean | null;
  tipo: TransacaoTipo | null;
}

export type ITransacaoFilter =
  | 'Data'
  | 'Descrição'
  | 'Categoria'
  | 'Centro de custo'
  | 'Conta'
  | 'Convênio'
  | 'Forma de pagamento'
  | 'Paciente'
  | 'Procedimento'
  | 'Profissional'
  | 'Status'
  | 'Tipo';
