import { createComponent } from '@/lib/vue';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { IFormInputSwitch } from '@/typings';

interface IProps extends IInputProps {
  input: IFormInputSwitch;
  value?: boolean | null;
}

interface IEvents {
  onInput: boolean | null;
}

export const InputSwitch = createComponent<IProps, IEvents>({
  name: 'InputSwitch',
  props: {
    value: { type: Boolean, default: false },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    function emitInput(value: boolean | null) {
      ctx.emit('input', value);
    }

    const { $isRequired } = useInput(props);

    return () => (
      <v-switch
        name={props.name}
        label={props.input.label}
        inputValue={props.value}
        errorMessages={props.errorMessages}
        required={$isRequired.value}
        prependIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        disabled={props.input.disabled}
        hideDetails={
          !props.input.validations &&
          !props.input.hint &&
          !props.input.persistentHint
        }
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        onChange={emitInput}
      />
    );
  },
});
