import { permissionDeniedError } from '@/routes/utils';
import { userIsAdminAccount, userIsAdminClinica } from '@/store/utils/auth';

import { NavigationGuard } from 'vue-router';

export const configuracoesClinicaGuard: NavigationGuard = (to, _from, next) => {
  if (!userIsAdminClinica() && !userIsAdminAccount()) {
    return permissionDeniedError(to, next);
  }

  return next();
};
