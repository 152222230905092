import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

export const DashedRow = createComponent({
  name: 'DashedRow',
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div
          class="flex justify-center w-full max-w-screen-lg p-4 mx-auto"
          style={{
            backgroundColor: MyTheme.coolGray100,
            border: `2px dashed ${MyTheme.coolGray300}`,
          }}
        >
          {defaultSlot}
        </div>
      );
    };
  },
});
