import { IInputItems } from '@/typings';

export const unidadeMedida: IInputItems[] = [
  { label: 'AMP - Ampola', value: '001' },
  { label: 'BUI - Bilhões de Unidades Internacionais', value: '002' },
  { label: 'BG - Bisnaga', value: '003' },
  { label: 'BOLS - Bolsa', value: '004' },
  { label: 'CX - Caixa', value: '005' },
  { label: 'CAP - Cápsula', value: '006' },
  { label: 'CARP - Carpule', value: '007' },
  { label: 'COM - Comprimido', value: '008' },
  { label: 'DOSE - Dose', value: '009' },
  { label: 'DRG - Drágea', value: '010' },
  { label: 'ENV - Envelope', value: '011' },
  { label: 'FLAC - Flaconete', value: '012' },
  { label: 'FR - Frasco', value: '013' },
  { label: 'FA - Frasco Ampola', value: '014' },
  { label: 'GAL - Galão', value: '015' },
  { label: 'GLOB - Glóbulo', value: '016' },
  { label: 'GTS - Gotas', value: '017' },
  { label: 'G - Grama', value: '018' },
  { label: 'L - Litro', value: '019' },
  { label: 'MCG - Microgramas', value: '020' },
  { label: 'MUI - Milhões de Unidades Internacionais', value: '021' },
  { label: 'MG - Miligrama', value: '022' },
  { label: 'ML - Mililitro', value: '023' },
  { label: 'OVL - Óvulo', value: '024' },
  { label: 'PAS - Pastilha', value: '025' },
  { label: 'LT - Lata', value: '026' },
  { label: 'PER - Pérola', value: '027' },
  { label: 'PIL - Pílula', value: '028' },
  { label: 'PT - Pote', value: '029' },
  { label: 'KG - Quilograma', value: '030' },
  { label: 'SER - Seringa', value: '031' },
  { label: 'SUP - Supositório', value: '032' },
  { label: 'TABLE - Tablete', value: '033' },
  { label: 'TUB - Tubete', value: '034' },
  { label: 'TB - Tubo', value: '035' },
  { label: 'UN - Unidade', value: '036' },
  { label: 'UI - Unidade Internacional', value: '037' },
  { label: 'CM - Centímetro', value: '038' },
  { label: 'CONJ - Conjunto', value: '039' },
  { label: 'KIT - Kit', value: '040' },
  { label: 'MÇ - Maço', value: '041' },
  { label: 'M - Metro', value: '042' },
  { label: 'PC - Pacote', value: '043' },
  { label: 'PÇ - Peça', value: '044' },
  { label: 'RL - Rolo', value: '045' },
  { label: 'GY - Gray', value: '046' },
  { label: 'CGY - Centgray', value: '047' },
  { label: 'PAR - Par', value: '048' },
  { label: 'ADES - Adesivo Transdérmico', value: '049' },
  { label: 'COM - EFEV Comprimido Efervecente', value: '050' },
  { label: 'COM - MST Comprimido Mastigável', value: '051' },
  { label: 'SACHE - Sachê', value: '052' },
  // *** incluido na versao 3.03.00
  { label: 'M', value: '053' },
  { label: 'M²', value: '054' },
  { label: 'M³', value: '055' },
  { label: 'MG/peso', value: '056' },
  { label: 'MG/M²', value: '057' },
  { label: 'CAL', value: '058' },
  { label: 'UI/M²', value: '059' },
  { label: 'UI/ML', value: '060' },
  { label: 'CM³', value: '061' },
];
