import { ListaEsperaGraphql } from '@/graphql/profissional/listaEspera/ListaEsperaGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IListaEsperaFragment,
  IListaEsperaModel,
  ListaEsperaDataInput,
} from '@/typings';
import isString from 'lodash/isString';

export const ListaEsperaService = {
  async getById(id: string) {
    try {
      return await ListaEsperaGraphql.listaEspera({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
    ordem,
  }: {
    profissionalId: string;
    model: IListaEsperaModel;
    ordem: number;
  }) {
    try {
      const result = await ListaEsperaGraphql.createListaEspera({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toListaEsperaDataInput(model, ordem),
      });

      if (result) {
        router.push(Routes.app.agenda(profissionalId).index);

        toastSuccess('Paciente adicionado à lista de espera');
      }
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      const result = await ListaEsperaGraphql.deleteListaEspera({ id });

      if (result) {
        toastSuccess('Paciente removido da lista de espera');
      }
    } catch (error) {
      handleError(error);
    }
  },

  async updateOrdem({
    items,
    profissionalId,
  }: {
    items: IListaEsperaFragment[];
    profissionalId: string;
  }) {
    try {
      const result = await ListaEsperaGraphql.updateListaEsperaOrdem({
        data: items.map(({ id }, idx) => ({ id, ordem: idx })),
        profissionalId,
      });

      if (result) {
        toastSuccess('Lista de espera alterada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toListaEsperaDataInput(
  {
    email,
    celular,
    telefoneCasa,
    observacao,
    paciente,
    convenioId,
  }: IListaEsperaModel,
  ordem: number,
): ListaEsperaDataInput {
  let nome: string | null = null;
  let pacienteId: string | null = null;

  if (isString(paciente)) {
    nome = paciente;
  } else if (paciente?.id) {
    pacienteId = paciente.id;
    nome = paciente.nome;
  }

  Validate.either([nome, pacienteId], ['nome', 'pacienteId']);
  Validate.either([celular, telefoneCasa], ['celular', 'telefoneCasa']);

  return {
    nome,
    email,
    celular,
    telefoneCasa,
    observacao,
    pacienteId,
    convenioId,
    ordem: Validate.min(ordem, 0, 'ordem'),
  };
}
