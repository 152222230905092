import { ProntuarioFinalizarDialog } from '@/modules/prontuarios/components/dialog/ProntuarioFinalizarDialog';
import { ProntuarioModeloDialog } from '@/modules/prontuarios/components/dialog/ProntuarioModeloDialog';
import { ProntuarioRascunhoDialog } from '@/modules/prontuarios/components/dialog/ProntuarioRascunhoDialog';
import { SalvarModeloDialog } from '@/modules/prontuarios/components/dialog/SalvarModeloDialog';
import {
  IProntuarioModeloDialogParams,
  IProntuarioSalvarModeloDialogConfirmation,
  IProntuarioSalvarModeloDialogParams,
  IProntuarioSalvarModeloItems,
} from '@/typings';
import { showDialog } from './dialog.helpers';

export const ProntuarioDialogs = {
  finalizar() {
    return showDialog({
      component: ProntuarioFinalizarDialog,
      width: 400,
      title: 'Finalizar atendimento',
      params: null,
    });
  },

  modelo({
    title,
    params,
  }: {
    title: string;
    params: IProntuarioModeloDialogParams;
  }) {
    return showDialog<IProntuarioModeloDialogParams>({
      component: ProntuarioModeloDialog,
      width: 400,
      title,
      params,
    });
  },

  salvarModelo<T extends IProntuarioSalvarModeloItems>(
    items: T,
  ): Promise<IProntuarioSalvarModeloDialogConfirmation<T>> {
    return showDialog<
      IProntuarioSalvarModeloDialogParams<T>,
      IProntuarioSalvarModeloDialogConfirmation<T>
    >({
      component: SalvarModeloDialog,
      title: 'Salvar como modelo',
      params: { items },
    });
  },

  rascunho() {
    return showDialog({
      component: ProntuarioRascunhoDialog,
      width: 400,
      title: 'Rascunho',
      params: null,
    });
  },
};
