import { apolloClient } from '@/plugins/apollo';

import PlanoQuery from '@/graphql/system/plano/plano.graphql';
import PlanosQuery from '@/graphql/system/plano/planos.graphql';

import {
  IPlanoQuery,
  IPlanoQueryVariables,
  IPlanosQuery,
  IPlanosQueryVariables,
} from '@/typings';

export const PlanoGraphql = {
  query: {
    PlanoQuery,
    PlanosQuery,
  },

  async plano(variables: IPlanoQueryVariables) {
    return apolloClient
      .query<IPlanoQuery>({
        query: PlanoQuery,
        variables,
      })
      .then(({ data }) => data?.plano);
  },

  async planos(variables: IPlanosQueryVariables) {
    return apolloClient
      .query<IPlanosQuery>({
        query: PlanosQuery,
        variables,
      })
      .then(({ data }) => data?.planos);
  },
};
