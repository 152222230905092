import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { PageTitle } from './PageTitle';

interface IProps {
  title: string;
  form?: boolean;
}

export const MyPage = createComponent<IProps>({
  name: 'MyPage',
  props: {
    title: { type: String, required: true },
    form: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-card
          color={MyTheme.white}
          class={['relative mx-auto w-full', props.form && 'max-w-screen-xl']}
        >
          <PageTitle title={props.title} />

          <v-divider></v-divider>

          {defaultSlot}
        </v-card>
      );
    };
  },
});
