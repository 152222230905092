import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';

export const ProntuarioFinalizarDialog = createComponent({
  name: 'ProntuarioFinalizarDialog',
  setup(props, ctx) {
    return () => (
      <div class="flex flex-col">
        <PageSection>
          <div class="my-2 text-subtitle text-coolGray-800">
            Ao finalizar um atendimento, você não poderá alterá-lo novamente.
            <span class="ml-1 font-medium">Deseja finalizá-lo?</span>
          </div>

          <div class="mb-2 text-body-sm text-coolGray-500">
            Após finalizado, você deverá iniciar um novo atendimento caso seja
            necessário adicionar mais informações.
          </div>
        </PageSection>

        <PageSection divider>
          <div class="flex">
            <v-spacer />

            <v-btn text color="primary" onClick={DialogState.cancel}>
              Cancelar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="ml-2"
              onClick={DialogState.confirm}
            >
              Finalizar
            </v-btn>
          </div>
        </PageSection>
      </div>
    );
  },
});
