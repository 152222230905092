import { formatNumber } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent } from '@/lib/vue';
import {
  AtendimentoEventoItemType,
  IAtendimentoFragment,
  IAtendimentoFragment_eventos_items,
  ProntuarioSecaoFieldType,
} from '@/typings';
import isNumber from 'lodash/isNumber';
import { TimelineBodyItem } from '../body/TimelineBodyItem';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineEventos = createComponent<IProps>({
  name: 'TimelineEventos',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { eventos } = props.atendimento;

      if (!eventos || !eventos.length) {
        return null;
      }

      return (
        <div class="flex flex-col">
          {eventos.map(evento => (
            <div key={evento.id} class="flex flex-col py-4">
              <h2 class="text-headline">{evento.nome}</h2>

              {evento.items.map(item => (
                <div class="flex" key={item.id}>
                  <TimelineBodyItem
                    label={getLabel(item)}
                    text={mapValue(item)}
                    newLine={item.type === AtendimentoEventoItemType.LIST}
                    richText={item.type === AtendimentoEventoItemType.RICH_TEXT}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    };
  },
});

function mapValue({
  type,
  originalType,
  valueNumber,
  valueText,
  valueList,
  decimalPlaces,
  suffix = '',
}: IAtendimentoFragment_eventos_items) {
  if (originalType === ProntuarioSecaoFieldType.NUMBER) {
    return formatNumber(valueNumber || 0, {
      money: false,
      suffix: suffix || '',
      precision: isNumber(decimalPlaces) ? decimalPlaces : 2,
    });
  } else if (originalType === ProntuarioSecaoFieldType.DATE) {
    return DateHelpers.formatDate(valueText);
  } else if (originalType === ProntuarioSecaoFieldType.TIME) {
    return DateHelpers.formatHour(valueText);
  } else if (originalType === ProntuarioSecaoFieldType.CHECKBOX) {
    return `☑️ ${valueText}`;
  } else if (type === AtendimentoEventoItemType.LIST) {
    return valueList?.join('\n');
  }

  return valueText;
}

function getLabel({ label, originalType }: IAtendimentoFragment_eventos_items) {
  if (originalType === ProntuarioSecaoFieldType.CHECKBOX) {
    return null;
  }

  return label;
}
