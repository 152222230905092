import truncate from 'lodash/truncate';

export const wait = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const truncateStr = (str: string, length = 100) =>
  truncate(str, { length });

export const pluralize = (arr: any[], plural: string, singular: string) =>
  arr.length > 1 ? plural : singular;

export const hexToLuma = (color: string) => {
  const hex = color.replace(/#/, '');
  const r = parseInt(hex.substr(0, 2), 16) * 0.299;
  const g = parseInt(hex.substr(2, 2), 16) * 0.587;
  const b = parseInt(hex.substr(4, 2), 16) * 0.114;

  return (r + g + b) / 255;
};

export const hexToFontColor = (color: string) =>
  hexToLuma(color) > 0.5 ? 'black' : 'white';

export const getInitials = (name: string, numberInitials?: number) => {
  let initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  if (!initials) {
    return null;
  }

  if (numberInitials) {
    initials = initials.slice(0, numberInitials);
  }

  return initials.join('');
};

export function assertUnreachable(type: never): never {
  throw new Error(`Unknown type: ${type}`);
}
