import FluxoCaixaQuery from '@/graphql/financas/relatorios/fluxoCaixaQuery.graphql';
import TransacoesPorCategoriaQuery from '@/graphql/financas/relatorios/transacoesPorCategoriaQuery.graphql';
import TransacoesPorCentroCustoQuery from '@/graphql/financas/relatorios/transacoesPorCentroCustoQuery.graphql';
import TransacoesPorContaFinanceiraQuery from '@/graphql/financas/relatorios/transacoesPorContaFinanceiraQuery.graphql';
import TransacoesPorConvenioQuery from '@/graphql/financas/relatorios/transacoesPorConvenioQuery.graphql';
import TransacoesPorPacienteQuery from '@/graphql/financas/relatorios/transacoesPorPacienteQuery.graphql';
import TransacoesPorProcedimentoQuery from '@/graphql/financas/relatorios/transacoesPorProcedimentoQuery.graphql';
import TransacoesPorProfissionalQuery from '@/graphql/financas/relatorios/transacoesPorProfissionalQuery.graphql';
import { apolloClient } from '@/plugins/apollo';
import {
  FluxoCaixaWhereInput,
  IFluxoCaixaQuery,
  IFluxoCaixaQueryVariables,
  ITransacoesPorCategoriaQuery,
  ITransacoesPorCategoriaQueryVariables,
  ITransacoesPorCentroCustoQuery,
  ITransacoesPorCentroCustoQueryVariables,
  ITransacoesPorContaFinanceiraQuery,
  ITransacoesPorContaFinanceiraQueryVariables,
  ITransacoesPorConvenioQuery,
  ITransacoesPorConvenioQueryVariables,
  ITransacoesPorPacienteQuery,
  ITransacoesPorPacienteQueryVariables,
  ITransacoesPorProcedimentoQuery,
  ITransacoesPorProcedimentoQueryVariables,
  ITransacoesPorProfissionalQuery,
  ITransacoesPorProfissionalQueryVariables,
} from '@/typings';

export const FinancasRelatoriosGraphql = {
  query: {
    FluxoCaixaQuery,
    TransacoesPorCategoriaQuery,
    TransacoesPorCentroCustoQuery,
    TransacoesPorContaFinanceiraQuery,
    TransacoesPorConvenioQuery,
    TransacoesPorPacienteQuery,
    TransacoesPorProcedimentoQuery,
    TransacoesPorProfissionalQuery,
  },

  async fluxoCaixa(where: FluxoCaixaWhereInput, refetch = false) {
    return apolloClient
      .query<IFluxoCaixaQuery, IFluxoCaixaQueryVariables>({
        query: FluxoCaixaQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.fluxoCaixa);
  },

  async transacoesPorCategoria(
    where: ITransacoesPorCategoriaQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        ITransacoesPorCategoriaQuery,
        ITransacoesPorCategoriaQueryVariables
      >({
        query: TransacoesPorCategoriaQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPorCategoria);
  },

  async transacoesPorCentroCusto(
    where: ITransacoesPorCentroCustoQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        ITransacoesPorCentroCustoQuery,
        ITransacoesPorCentroCustoQueryVariables
      >({
        query: TransacoesPorCentroCustoQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPorCentroCusto);
  },

  async transacoesPorContaFinanceira(
    where: ITransacoesPorContaFinanceiraQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        ITransacoesPorContaFinanceiraQuery,
        ITransacoesPorContaFinanceiraQueryVariables
      >({
        query: TransacoesPorContaFinanceiraQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPorContaFinanceira);
  },

  async transacoesPorConvenio(
    where: ITransacoesPorConvenioQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<ITransacoesPorConvenioQuery, ITransacoesPorConvenioQueryVariables>(
        {
          query: TransacoesPorConvenioQuery,
          variables: { where },
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        },
      )
      .then(({ data }) => data?.transacoesPorConvenio);
  },

  async transacoesPorPaciente(
    where: ITransacoesPorPacienteQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<ITransacoesPorPacienteQuery, ITransacoesPorPacienteQueryVariables>(
        {
          query: TransacoesPorPacienteQuery,
          variables: { where },
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        },
      )
      .then(({ data }) => data?.transacoesPorPaciente);
  },

  async transacoesPorProcedimento(
    where: ITransacoesPorProcedimentoQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        ITransacoesPorProcedimentoQuery,
        ITransacoesPorProcedimentoQueryVariables
      >({
        query: TransacoesPorProcedimentoQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPorProcedimento);
  },

  async transacoesPorProfissional(
    where: ITransacoesPorProfissionalQueryVariables['where'],
    refetch = false,
  ) {
    return apolloClient
      .query<
        ITransacoesPorProfissionalQuery,
        ITransacoesPorProfissionalQueryVariables
      >({
        query: TransacoesPorProfissionalQuery,
        variables: { where },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.transacoesPorProfissional);
  },
};
