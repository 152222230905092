import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const ContatosPage = () =>
  import(
    /* webpackChunkName: "contatos" */ '@/modules/contatos/pages/ContatosPage'
  );
const ContatoFormPage = () =>
  import(
    /* webpackChunkName: "contatos" */ '@/modules/contatos/pages/ContatoFormPage'
  );

export const contatosRoutes: IRouteConfig[] = routeGroup({
  prefix: 'contatos',
  routes: [
    {
      path: '',
      component: ContatosPage,
    },
    {
      path: 'new',
      component: ContatoFormPage,
    },
    {
      path: ':id',
      component: ContatoFormPage,
    },
  ],
});
