import { ContatoGraphql } from '@/graphql/system/contato/ContatoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  ContatoDataInput,
  IContatoFormModel,
  IServiceOnSuccessArgs,
} from '@/typings';

export const ContatoService = {
  async getById(id: string) {
    try {
      return await ContatoGraphql.contato({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IContatoFormModel) {
    try {
      const result = await ContatoGraphql.createContato({
        data: toContatoDataInput(model),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'este contato' }, async () => {
        const result = await ContatoGraphql.deleteContato({ id });

        onSuccess({ result, msg: 'excluído' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os contatos selecionados',
            'o contato selecionado',
          ),
        },
        async () => {
          const result = await ContatoGraphql.deleteManyContatos({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Contatos excluídos',
              'Contato excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: IContatoFormModel) {
    try {
      const result = await ContatoGraphql.updateContato({
        id: Validate.require(id, 'id'),
        data: toContatoDataInput(model),
      });

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },
};

function toContatoDataInput({
  geral: { email, nome },
  telefones,
  endereco: {
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
    enderecoPais,
  },
  outrasInformacoes: { observacao },
}: IContatoFormModel): ContatoDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    email,
    telefones: telefones.filter(v => v.numero).map(v => v.numero!),
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
    enderecoPais,
    observacao,
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.contatos.index);

    toastSuccess(`Contato ${msg} com sucesso`);
  }
}
