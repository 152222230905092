import { createComponent } from '@/lib/vue';
import 'quill/dist/quill.snow.css';

interface IProps {
  html: string | null;
}

export const TimelineBodyHtml = createComponent<IProps>({
  name: 'TimelineBodyHtml',
  props: {
    html: { type: String },
  },
  setup(props, ctx) {
    return () =>
      !!props.html && (
        <div
          {...{
            class: 'ql-editor overflow-hidden min-h-auto text-body-sm',
            domProps: { innerHTML: props.html },
          }}
        />
      );
  },
});
