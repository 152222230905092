import CreateTissProcedimentoMutation from '@/graphql/tiss/tissProcedimento/createTissProcedimento.graphql';
import DeleteManyTissProcedimentosMutation from '@/graphql/tiss/tissProcedimento/deleteManyTissProcedimentos.graphql';
import DeleteTissProcedimentoMutation from '@/graphql/tiss/tissProcedimento/deleteTissProcedimento.graphql';
import TissProcedimentoQuery from '@/graphql/tiss/tissProcedimento/tissProcedimento.graphql';
import TissProcedimentosQuery from '@/graphql/tiss/tissProcedimento/tissProcedimentos.graphql';
import UpdateTissProcedimentoMutation from '@/graphql/tiss/tissProcedimento/updateTissProcedimento.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissProcedimentoMutation,
  ICreateTissProcedimentoMutationVariables,
  IDeleteManyTissProcedimentosMutation,
  IDeleteManyTissProcedimentosMutationVariables,
  IDeleteTissProcedimentoMutation,
  IDeleteTissProcedimentoMutationVariables,
  ITissProcedimentoQuery,
  ITissProcedimentoQueryVariables,
  ITissProcedimentosQuery,
  ITissProcedimentosQueryVariables,
  IUpdateTissProcedimentoMutation,
  IUpdateTissProcedimentoMutationVariables,
} from '@/typings';

export const TissProcedimentoGraphql = {
  query: {
    TissProcedimentoQuery,
    TissProcedimentosQuery,
  },

  async createTissProcedimento(
    variables: ICreateTissProcedimentoMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTissProcedimentoMutation>({
        mutation: CreateTissProcedimentoMutation,
        variables,
      })
      .then(({ data }) => data?.createTissProcedimento);
  },

  async deleteTissProcedimento(
    variables: IDeleteTissProcedimentoMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTissProcedimentoMutation>({
        mutation: DeleteTissProcedimentoMutation,
        variables,
        refetchQueries: getRefetchQueries([TissProcedimentosQuery]),
      })
      .then(({ data }) => data?.deleteTissProcedimento);
  },

  async deleteManyTissProcedimentos(
    variables: IDeleteManyTissProcedimentosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyTissProcedimentosMutation>({
        mutation: DeleteManyTissProcedimentosMutation,
        variables,
        refetchQueries: getRefetchQueries([TissProcedimentosQuery]),
      })
      .then(({ data }) => data?.deleteManyTissProcedimentos);
  },

  async tissProcedimento(variables: ITissProcedimentoQueryVariables) {
    return apolloClient
      .query<ITissProcedimentoQuery>({
        query: TissProcedimentoQuery,
        variables,
      })
      .then(({ data }) => data?.tissProcedimento);
  },

  async tissProcedimentos(variables: ITissProcedimentosQueryVariables) {
    return apolloClient
      .query<ITissProcedimentosQuery>({
        query: TissProcedimentosQuery,
        variables,
      })
      .then(({ data }) => data?.tissProcedimentos);
  },

  async updateTissProcedimento(
    variables: IUpdateTissProcedimentoMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTissProcedimentoMutation>({
        mutation: UpdateTissProcedimentoMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissProcedimento);
  },
};
