import { useValue } from '@/lib/composables';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { createComponent } from '@/lib/vue';
import { IFormInputRichText } from '@/typings';
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  SetupContext,
} from '@vue/composition-api';
import { Quill } from 'quill';
import { VueEditor } from 'vue2-editor';
import { RichTextMacros } from '../custom/RichTextMacros';

interface IProps extends IInputProps {
  value?: string | null;
  input: IFormInputRichText;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputRichText = createComponent<IProps, IEvents>({
  name: 'InputRichText',
  components: { VueEditor },
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const $editorEl = ref<any>(null);

    const { setFocus, $label, $labelClasses, editorToolbar } =
      useComputeds(props);

    const { handleSelectMacro, emitInput } = useEvents({ $editorEl, ctx });

    useListeners({ $editorEl, setFocus });

    return () => (
      <div id="InputRichText" class="flex flex-col">
        {$label.value && (
          <label class={$labelClasses.value}>{$label.value}</label>
        )}

        <div>
          <vue-editor
            ref={$editorEl}
            name={props.name}
            editorToolbar={editorToolbar}
            value={props.value}
            placeholder={props.input.placeholder}
            onInput={emitInput}
          />
        </div>

        <RichTextMacros
          macros={props.input.macros}
          onSelect={handleSelectMacro}
        />
      </div>
    );
  },
});

export default InputRichText;

function useComputeds(props: IProps) {
  const [$focus, setFocus] = useValue(false);

  const { $label, $isValid } = useInput(props);

  const editorToolbar = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['clean'],
  ];

  const $labelClasses = computed(() => [
    'v-label theme--light my-2',
    {
      'text-primary': $focus.value && $isValid.value,
      'text-error': !$isValid.value,
    },
  ]);

  return {
    $focus,
    setFocus,
    $label,
    $isValid,
    editorToolbar,
    $labelClasses,
  };
}

function useEvents({
  $editorEl,
  ctx,
}: {
  $editorEl: Ref<any>;
  ctx: SetupContext;
}) {
  function emitInput(value: string | null) {
    ctx.emit('input', value);
  }

  const getQuill = (): Quill | null => $editorEl.value.quill;

  function handleSelectMacro(text: string) {
    const quill = getQuill();

    if (!quill) return;

    const range = quill.getSelection();
    const index = range?.index;

    const length = quill.getLength();

    quill.insertText(index || length, text);
  }

  return { getQuill, handleSelectMacro, emitInput };
}

function useListeners({
  $editorEl,
  setFocus,
}: {
  $editorEl: Ref<any>;
  setFocus: (v: boolean) => void;
}) {
  const getEditor = () =>
    $editorEl.value?.$el.querySelector('#quill-container .ql-editor');

  function handleFocus() {
    setFocus(true);
  }

  function handleBlur() {
    setFocus(false);
  }

  onMounted(() => {
    const editor = getEditor();

    if (editor) {
      editor.addEventListener('focus', handleFocus);
      editor.addEventListener('blur', handleBlur);
    }
  });

  onUnmounted(() => {
    const editor = getEditor();

    if (editor) {
      editor.removeEventListener('focus', handleFocus);
      editor.removeEventListener('blur', handleBlur);
    }
  });
}
