import { createComponent } from '@/lib/vue';

interface IProps {
  value?: string | null;
}

export const ColorTile = createComponent<IProps>({
  name: 'ColorTile',
  props: {
    value: { type: String },
  },
  setup(props, ctx) {
    return () => {
      if (!props.value) {
        return null;
      }

      return (
        <div
          class="w-6 h-6 rounded cursor-pointer"
          style={{ background: props.value }}
        />
      );
    };
  },
});

export default ColorTile;
