import { IAppState, ILayoutState } from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): ILayoutState => ({
  temporarySidebar: {
    open: false,
  },
  contextSidebar: {
    mini: true,
  },
});

export const LayoutModule: Module<ILayoutState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<ILayoutState>('layout');

export class LayoutState {
  static toggleContext() {
    commit(s => {
      s.contextSidebar.mini = !s.contextSidebar.mini;
    });
  }

  static openContext() {
    commit(s => {
      s.contextSidebar.mini = true;
    });
  }

  static openTemporary() {
    commit(s => {
      s.temporarySidebar.open = true;
      s.contextSidebar.mini = true;
    });
  }

  static updateContext(open: boolean) {
    commit(s => {
      s.contextSidebar.mini = open;
    });
  }

  static updateTemporary(open: boolean) {
    commit(s => {
      s.temporarySidebar.open = open;
    });
  }
}
