import { ConfiguracaoGraphql } from '@/graphql/configuracao/ConfiguracaoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import {
  ConfiguracaoImpressaoLogoPosition,
  IConfiguracaoImpressaoFormModel,
  IConfiguracaoImpressaoQueryVariables,
} from '@/typings';

export const ConfiguracaoImpressaoService = {
  async get(variables: IConfiguracaoImpressaoQueryVariables) {
    try {
      return await ConfiguracaoGraphql.configuracaoImpressao(variables);
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    profissionalId,
    model,
  }: {
    profissionalId: string;
    model: IConfiguracaoImpressaoFormModel;
  }) {
    try {
      const result = await ConfiguracaoGraphql.updateConfiguracaoImpressao({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: {
          tamanhoPagina: Validate.require(
            model.layout.tamanhoPagina,
            'tamanhoPagina',
          ),
          tamanhoFonte: parseInt(
            Validate.require(model.layout.tamanhoFonte, 'tamanhoFonte'),
            10,
          ),
          margem: Validate.require(model.layout.margem, 'margem'),
          logoPosition: !model.cabecalho.logo
            ? ConfiguracaoImpressaoLogoPosition.NONE
            : Validate.require(model.cabecalho.logoPosition, 'logoPosition'),
          cabecalho: model.cabecalho.cabecalho,
          numeracaoAutomatica: model.prescricao.numeracaoAutomatica,
          rodape: model.rodape.rodape,
          assinatura: model.rodape.assinatura,
          rodapeTexto: model.rodape.rodapeTexto,
          logoId: model.logoImagem.id,
        },
      });

      if (result) {
        toastSuccess('Configuração de Impressão atualizada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};
