import Vue from 'vue';
import Snotify from 'vue-snotify';

Vue.use(Snotify, {
  toast: { timeout: 5000 },
  type: {
    success: {
      icon: '/img/toasts/success.svg',
    },
    error: {
      icon: '/img/toasts/error.svg',
    },
    info: {
      icon: '/img/toasts/info.svg',
    },
    warning: {
      icon: '/img/toasts/warning.svg',
    },
  },
});
