import { prescricoesFilterValid } from '@/lib/helpers/models/prontuario/atendimento';
import { uuid } from '@/lib/helpers/uuid';
import { IProntuarioPrescricaoFormModel, IProntuarioState } from '@/typings';
import { ProntuarioState } from '..';
import { todayDate } from '../utils';

export const ProntuarioModelPrescricoesState = {
  add() {
    ProntuarioState.commit(s => {
      s.forms.prescricoes.model.push({
        id: uuid(),
        header: {
          data: todayDate(),
          controleEspecial: false,
        },
        medicamentos: [],
      });

      setHasError(s);
    });
  },

  setModel({ id, ...model }: IProntuarioPrescricaoFormModel) {
    ProntuarioState.commit(s => {
      s.forms.prescricoes.model = s.forms.prescricoes.model.map(v => {
        if (v.id !== id) {
          return v;
        }

        return {
          id,
          ...model,
        };
      });

      setHasError(s);
    });
  },

  remove(id: string) {
    ProntuarioState.commit(s => {
      s.forms.prescricoes.model = s.forms.prescricoes.model.filter(
        f => f.id !== id,
      );

      setHasError(s);
    });
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.forms.prescricoes.submitted = submitted;
    });
  },
};

function setHasError(s: IProntuarioState) {
  s.forms.prescricoes.hasError = checkHasError(s);

  if (s.atendimento.submitted && s.forms.prescricoes.hasError) {
    s.forms.prescricoes.submitted = true;
  }
}

function checkHasError(s: IProntuarioState): boolean {
  const { model } = s.forms.prescricoes;

  const isValid = model
    .filter(prescricoesFilterValid)
    .every(
      v =>
        !!v.header.data &&
        !!v.medicamentos
          .filter(f => !!f.medicamento || !!f.quantidade)
          .every(e => !!e.medicamento && !!e.quantidade),
    );

  return !isValid;
}
