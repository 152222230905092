import { formatNumber } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { calcularIMC } from '@/lib/helpers/models/prontuario/prontuarioPreAtendimento';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment } from '@/typings';
import { computed } from '@vue/composition-api';
import { TimelineBodyItem } from '../body/TimelineBodyItem';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelinePreAtendimento = createComponent<IProps>({
  name: 'TimelinePreAtendimento',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const {
      $peso,
      $altura,
      $imc,
      $paSistolica,
      $paDiastolica,
      $frequenciaCardiaca,
      $frequenciaRespiratoria,
      $temperatura,
      $glicemiaCapilar,
      $dum,
    } = useComputeds(props);

    return () => {
      const { preAtendimento } = props.atendimento;
      if (!preAtendimento) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Pré-atendimento</h2>

          <div class="flex space-x-24">
            <TimelineBodyItem label="Peso" text={$peso.value} />

            <TimelineBodyItem label="Altura" text={$altura.value} />

            <TimelineBodyItem label="IMC" text={$imc.value} />
          </div>

          <div class="flex space-x-24">
            <TimelineBodyItem label="PA Sistólica" text={$paSistolica.value} />

            <TimelineBodyItem
              label="PA Diastólica"
              text={$paDiastolica.value}
            />
          </div>

          <div class="flex space-x-24">
            <TimelineBodyItem
              label="Frequência Cardíaca"
              text={$frequenciaCardiaca.value}
            />

            <TimelineBodyItem
              label="Frequência Respiratória"
              text={$frequenciaRespiratoria.value}
            />
          </div>

          <div class="flex space-x-24">
            <TimelineBodyItem label="Temperatura" text={$temperatura.value} />

            <TimelineBodyItem
              label="Glicemia capilar"
              text={$glicemiaCapilar.value}
            />
          </div>

          <div class="flex">
            <TimelineBodyItem label="DUM" text={$dum.value} />
          </div>

          <div class="flex">
            <TimelineBodyItem
              label="Observação"
              text={preAtendimento.observacao}
              newLine
            />
          </div>
        </div>
      );
    };
  },
});

function useComputeds(props: IProps) {
  const $preAtendimento = computed(() => props.atendimento.preAtendimento);

  const $peso = computed(() =>
    format({
      value: $preAtendimento.value?.peso,
      suffix: 'kg',
      precision: 1,
    }),
  );

  const $altura = computed(() =>
    format({
      value: $preAtendimento.value?.altura,
      suffix: 'm',
    }),
  );

  const $imc = computed(() => {
    const imc = $preAtendimento.value && calcularIMC($preAtendimento.value);

    return (
      imc && `${format({ value: imc.value, precision: 1 })} (${imc.status})`
    );
  });

  const $paSistolica = computed(() =>
    format({
      value: $preAtendimento.value?.pressaoArterialSistolica,
      suffix: 'mmHg',
      integer: true,
    }),
  );

  const $paDiastolica = computed(() =>
    format({
      value: $preAtendimento.value?.pressaoArterialDiastolica,
      suffix: 'mmHg',
      integer: true,
    }),
  );

  const $frequenciaCardiaca = computed(() =>
    format({
      value: $preAtendimento.value?.frequenciaCardiaca,
      suffix: 'bpm',
      integer: true,
    }),
  );

  const $frequenciaRespiratoria = computed(() =>
    format({
      value: $preAtendimento.value?.frequenciaRespiratoria,
      suffix: 'mrm',
      integer: true,
    }),
  );

  const $temperatura = computed(() =>
    format({
      value: $preAtendimento.value?.temperatura,
      suffix: 'ºC',
      precision: 1,
    }),
  );

  const $glicemiaCapilar = computed(() =>
    format({
      value: $preAtendimento.value?.glicemiaCapilar,
      suffix: 'mg/dl',
      integer: true,
    }),
  );

  const $dum = computed(() =>
    DateHelpers.formatDate($preAtendimento.value?.dataUltimaMenstruacao),
  );

  return {
    $preAtendimento,
    $peso,
    $altura,
    $imc,
    $paSistolica,
    $paDiastolica,
    $frequenciaCardiaca,
    $frequenciaRespiratoria,
    $temperatura,
    $glicemiaCapilar,
    $dum,
  };
}

function format({
  value,
  suffix = '',
  integer = false,
  precision = 2,
}: {
  value: number | null | undefined;
  suffix?: string;
  integer?: boolean;
  precision?: number;
}) {
  return value
    ? formatNumber(value, {
        money: false,
        suffix,
        integer,
        precision,
      })
    : null;
}
