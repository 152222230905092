import { IInputItems, TissDespesaTipo } from '@/typings';

export const tissDespesaTipos: IInputItems[] = [
  {
    label: 'Gases medicinais',
    value: TissDespesaTipo.GASES_MEDICINAIS,
  },
  {
    label: 'Medicamentos',
    value: TissDespesaTipo.MEDICAMENTOS,
  },
  {
    label: 'Materiais',
    value: TissDespesaTipo.MATERIAIS,
  },
  {
    label: 'Diárias',
    value: TissDespesaTipo.DIARIAS,
  },
  {
    label: 'Taxas e aluguéis',
    value: TissDespesaTipo.TAXAS_E_ALUGUEIS,
  },
  {
    label: 'OPME',
    value: TissDespesaTipo.OPME,
  },
];
