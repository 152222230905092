import { IInputItems, TissLoteTipo } from '@/typings';

export const loteTipo: IInputItems[] = [
  { label: 'Consulta', value: TissLoteTipo.GUIA_CONSULTA },
  { label: 'SP/SADT', value: TissLoteTipo.GUIA_SP_SADT },
  { label: 'Honorário', value: TissLoteTipo.GUIA_HONORARIOS },
  {
    label: 'Odontológico (GTO)',
    value: TissLoteTipo.GUIA_TRATAMENTO_ODONTOLOGICO,
  },
];
