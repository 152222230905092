import { IInputItems, UserEquipeTipoAcesso, UserTipo } from '@/typings';

export const userTipos: IInputItems[] = [
  { label: 'Profissional de saúde', value: UserTipo.PROFISSIONAL_SAUDE },
  { label: 'Recepcionista', value: UserTipo.RECEPCIONISTA },
];

export const userEquipeTiposAcessos: IInputItems[] = [
  {
    label: 'Adminstrador da Clínica',
    value: UserEquipeTipoAcesso.ADMIN_CLINICA,
  },
  { label: 'Acesso restrito', value: UserEquipeTipoAcesso.NONE },
];
