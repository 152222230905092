import {
  AtendimentoExameTipo,
  ConfiguracaoAgendaVisualizacaoPadrao,
  IEstado,
  IInputItems,
  IPais,
  Sexo,
  TipoRepeticao,
} from '@/typings';
import {
  agendamentoCancelamentoMotivos,
  agendamentoRecorrenciaTerminaEmTipos,
  agendamentoStatus,
} from './agendamento';
import {
  configuracaoImpressaoLogoPositions,
  configuracaoImpressaoMargens,
  configuracaoImpressaoTamanhosPaginas,
} from './configuracaoImpressao';
import {
  configuracaoFinanceiraRecebimentoLancarAPartir,
  contaFinanceiraTipos,
  financasRelatoriosGroupBy,
  fluxoCaixaTypes,
  formasPagamento,
} from './financas';
import { procedimentoTipos } from './procedimento';
import { TissConstants } from './tiss';
import {
  transacaoRepeticaoModos,
  transacaoStatus,
  transacaoTipos,
} from './transacao';
import { userEquipeTiposAcessos, userTipos } from './user';

const tiposRepeticoes: IInputItems[] = [
  { label: 'Diariamente', value: TipoRepeticao.DIARIAMENTE },
  { label: 'Semanalmente', value: TipoRepeticao.SEMANALMENTE },
  { label: 'Quizenalmente', value: TipoRepeticao.QUIZENALMENTE },
  { label: 'Mensalmente', value: TipoRepeticao.MENSALMENTE },
  { label: 'Anualmente', value: TipoRepeticao.ANUALMENTE },
];

const configuracaoAgendaVisualizacaoPadrao: IInputItems[] = [
  { label: 'Dia', value: ConfiguracaoAgendaVisualizacaoPadrao.DIA },
  { label: 'Semana', value: ConfiguracaoAgendaVisualizacaoPadrao.SEMANA },
];

const sexos: IInputItems[] = [
  { label: 'Feminino', value: Sexo.FEMININO },
  { label: 'Masculino', value: Sexo.MASCULINO },
];

const calendarViews: IInputItems[] = [
  {
    label: 'Semana',
    value: 'timeGridWeek',
  },
  {
    label: 'Dia',
    value: 'timeGridDay',
  },
];

const prontuarioExameTipos: IInputItems[] = [
  {
    label: 'SADT',
    value: AtendimentoExameTipo.SADT,
  },
  {
    label: 'Particular',
    value: AtendimentoExameTipo.PARTICULAR,
  },
];

const meses: IInputItems[] = [
  { label: 'Janeiro', value: '1' },
  { label: 'Fevereiro', value: '2' },
  { label: 'Março', value: '3' },
  { label: 'Abril', value: '4' },
  { label: 'Maio', value: '5' },
  { label: 'Junho', value: '6' },
  { label: 'Julho', value: '7' },
  { label: 'Agosto', value: '8' },
  { label: 'Setembro', value: '9' },
  { label: 'Outubro', value: '10' },
  { label: 'Novembro', value: '11' },
  { label: 'Dezembro', value: '12' },
];

export const ConstantsHelper = {
  bancos: require('../data/bancos.json') as IInputItems[],

  estados: require('../data/estados.json') as IEstado[],

  paises: require('../data/paises.json') as IPais[],

  estadosCivis: [
    'Solteiro',
    'Casado',
    'Separado',
    'Divorciado',
    'Viúvo',
    'União estável',
  ],

  escolaridades: [
    'Nenhuma',
    'Ensino fundamental',
    'Ensino fundamental incompleto',
    'Ensino médio',
    'Ensino médio incompleto',
    'Ensino superior',
    'Ensino superior incompleto',
    'Pós graduação',
    'Mestrado',
    'Doutorado',
  ],

  etnias: ['Amarela', 'Branca', 'Negra', 'Parda'],

  parentescos: [
    'Avó',
    'Avô',
    'Esposa',
    'Esposo',
    'Filha',
    'Filho',
    'Irmã',
    'Irmão',
    'Mãe',
    'Neta',
    'Neto',
    'Pai',
    'Prima',
    'Primo',
    'Sobrinha',
    'Sobrinho',
    'Tia',
    'Tio',
  ],

  comoConheceu: [
    'Encaminhamento médico',
    'Guia médico',
    'Hospital',
    'Indicação de paciente',
    'Internet',
    'Outro',
  ],

  tratamentos: ['Dr.', 'Dra.', 'Sr.', 'Sra.'],

  profissoesMedicina: [
    'Médico',
    'Recepcionista',
    'Dentista',
    'Administrador(a) da clínica',
    'Consultor(a) de TI/Negócios',
    'Outro profissional de saúde',
    'Outros',
  ],

  contratadoTiposDocumentos: [
    'Código do prestador na operadora',
    'CPF',
    'CNPJ',
  ],

  indicacaoAcidente: [
    '0 - Trabalho',
    '1 - Trânsito',
    '2 - Outros',
    '9 - Não acidente',
  ],

  tipoConsulta: [
    '1 - Primeira Consulta',
    '2 - Retorno',
    '3 - Pré-natal',
    '4 - Por encaminhamento',
  ],

  prescricaoQuantidades: [
    'Uso contínuo',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
  ],

  agendamentoTipos: ['Agendamento', 'Horário bloqueado'],

  configuracaoImpressaoTamanhosFontes: [
    '10',
    '11',
    '12',
    '14',
    '16',
    '18',
    '20',
  ],

  configuracaoAgendaVisualizacaoPadrao,
  tiposRepeticoes,
  agendamentoCancelamentoMotivos,
  agendamentoRecorrenciaTerminaEmTipos,
  agendamentoStatus,
  calendarViews,
  configuracaoFinanceiraRecebimentoLancarAPartir,
  configuracaoImpressaoLogoPositions,
  configuracaoImpressaoMargens,
  configuracaoImpressaoTamanhosPaginas,
  contaFinanceiraTipos,
  financasRelatoriosGroupBy,
  fluxoCaixaTypes,
  formasPagamento,
  meses,
  procedimentoTipos,
  prontuarioExameTipos,
  sexos,
  tiss: TissConstants,
  transacaoRepeticaoModos,
  transacaoStatus,
  transacaoTipos,
  userEquipeTiposAcessos,
  userTipos,
};
