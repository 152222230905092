import { useValue } from '@/lib/composables';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IFormInputPassword } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IInputProps {
  value?: string | null;
  input: IFormInputPassword;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputPassword = createComponent<IProps, IEvents>({
  name: 'InputPassword',
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const { $showPassword, setShowPassword, $type, $appendIcon, $prependIcon } =
      useComputeds(props);

    const { events } = useEvents({ ctx, $showPassword, setShowPassword });

    return () => (
      <v-text-field
        name={props.name}
        outlined
        label={$label.value}
        type={$type.value}
        errorMessages={props.errorMessages}
        value={props.value}
        required={$isRequired.value}
        prependInnerIcon={$prependIcon.value}
        appendIcon={$appendIcon.value}
        disabled={props.input.disabled}
        loading={props.input.loading}
        autofocus={props.input.autofocus}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        hideDetails={!!props.input.hideDetails}
        {...events}
      />
    );
  },
});

function useComputeds(props: IProps) {
  const [$showPassword, setShowPassword] = useValue(false);

  const $type = computed(() => ($showPassword.value ? 'text' : 'password'));

  const $appendIcon = computed(() =>
    $showPassword.value ? MyIcons.passwordHide : MyIcons.passwordShow,
  );

  const $prependIcon = computed(
    () => props.input.prependIcon || MyIcons.password,
  );

  return {
    $showPassword,
    setShowPassword,
    $type,
    $appendIcon,
    $prependIcon,
  };
}

function useEvents({
  ctx,
  $showPassword,
  setShowPassword,
}: {
  ctx: SetupContext;
  $showPassword: Ref<boolean>;
  setShowPassword: (v: boolean) => void;
}) {
  const events = {
    on: {
      'click:append': () => setShowPassword(!$showPassword.value),
      input: emitInput,
    },
  };

  function emitInput(value: string | null) {
    ctx.emit('input', value);
  }

  return { events, emitInput };
}
