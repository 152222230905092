import {
  mdiAccount,
  mdiAccountCancel,
  mdiAccountCheck,
  mdiAccountCircle,
  mdiAccountConvert,
  mdiAccountDetails,
  mdiAccountMultiple,
  mdiAccountOff,
  mdiAccountPlus,
  mdiAlert,
  mdiAlertBox,
  mdiAlertCircle,
  mdiApps,
  mdiArrowLeft,
  mdiAt,
  mdiBackburger,
  mdiBackupRestore,
  mdiBank,
  mdiBankOutline,
  mdiBookmarkOutline,
  mdiBriefcaseMinus,
  mdiBriefcasePlus,
  mdiBuffer,
  mdiCakeVariant,
  mdiCalendar,
  mdiCalendarAlert,
  mdiCalendarClock,
  mdiCalendarPlus,
  mdiCalendarRange,
  mdiCalendarSearch,
  mdiCalendarStar,
  mdiCalendarTextOutline,
  mdiCamera,
  mdiCancel,
  mdiCardAccountDetails,
  mdiCashMultiple,
  mdiCellphoneAndroid,
  mdiChartAreaspline,
  mdiChartBar,
  mdiCheckBold,
  mdiCheckbook,
  mdiCheckboxMarkedCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMultipleMarked,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClipboardAccount,
  mdiClipboardPlayOutline,
  mdiClipboardPulseOutline,
  mdiClipboardText,
  mdiClipboardTextOutline,
  mdiClock,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCloudUpload,
  mdiCog,
  mdiContacts,
  mdiContentSaveAll,
  mdiCreditCardOutline,
  mdiDatabaseCogOutline,
  mdiDelete,
  mdiDoctor,
  mdiDotsVertical,
  mdiDownload,
  mdiDrag,
  mdiEmail,
  mdiEmailMarkAsUnread,
  mdiExitToApp,
  mdiEye,
  mdiEyeOff,
  mdiFile,
  mdiFileChart,
  mdiFileClockOutline,
  mdiFileDocumentOutline,
  mdiFileExcel,
  mdiFileExportOutline,
  mdiFileMusic,
  mdiFilePdf,
  mdiFilePowerpoint,
  mdiFileTree,
  mdiFileVideo,
  mdiFileWord,
  mdiFilter,
  mdiFilterMinus,
  mdiFilterPlus,
  mdiFinance,
  mdiFolderOpen,
  mdiFolderZip,
  mdiFormatListCheckbox,
  mdiForwardburger,
  mdiHistory,
  mdiHomeMapMarker,
  mdiHospitalBox,
  mdiHospitalBoxOutline,
  mdiHospitalBuilding,
  mdiHospitalMarker,
  mdiHumanGreeting,
  mdiHumanMaleFemale,
  mdiImage,
  mdiImageEdit,
  mdiImagePlus,
  mdiImageRemove,
  mdiInboxMultiple,
  mdiInformationOutline,
  mdiLayers,
  mdiLock,
  mdiLockReset,
  mdiMagnify,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiMenuDown,
  mdiMessage,
  mdiMessageSettingsOutline,
  mdiMessageTextOutline,
  mdiNoteText,
  mdiNumeric1Circle,
  mdiNumeric2Circle,
  mdiNumeric3Circle,
  mdiPalette,
  mdiPen,
  mdiPencil,
  mdiPhone,
  mdiPhoneInTalk,
  mdiPiggyBank,
  mdiPlay,
  mdiPlaylistPlus,
  mdiPlus,
  mdiPlusCircle,
  mdiPrinter,
  mdiRefresh,
  mdiRestore,
  mdiShareVariant,
  mdiSourceMerge,
  mdiStethoscope,
  mdiStop,
  mdiSwapHorizontal,
  mdiTable,
  mdiTableLarge,
  mdiTableSearch,
  mdiTagOutline,
  mdiTextBox,
  mdiTextBoxMultipleOutline,
  mdiTimer,
  mdiTimerOutline,
  mdiTimetable,
  mdiToothOutline,
  mdiTrendingDown,
  mdiTrendingUp,
  mdiViewDashboard,
  mdiViewQuilt,
  mdiWallet,
  mdiWebcam,
} from '@mdi/js';

const agendamentoStatus = {
  agendado: mdiCalendar,
  confirmado: mdiCheckboxMarkedCircleOutline,
  dilatandoPupila: mdiEye,
  aguardandoAtendimento: mdiClockOutline,
  cancelado: mdiCancel,
  pacienteAtendido: mdiCheckBold,
  pacienteFaltou: mdiAccountCancel,
};

const contaFinanceiraTipo = {
  caixaInterno: mdiWallet,
  cartaoCredito: mdiCreditCardOutline,
  contaCorrente: mdiBank,
  contaPoupanca: mdiPiggyBank,
  investimento: mdiChartAreaspline,
  meiosRecebimento: mdiWallet,
  outros: mdiBank,
};

export const MyIcons = {
  account: mdiAccount,
  agenda: mdiCalendarClock,
  agendamentoNew: mdiCalendarPlus,
  agendamentoStatus,
  alertError: mdiAlert,
  alertInfo: mdiAlertCircle,
  alertSuccess: mdiCheckboxMarkedCircle,
  alertWarning: mdiAlertBox,
  alertas: mdiEmailMarkAsUnread,
  analiseDespesas: mdiTrendingDown,
  analiseReceitas: mdiTrendingUp,
  aniversariantes: mdiCakeVariant,
  assinatura: mdiPen,
  at: mdiAt,
  atestado: mdiFileDocumentOutline,
  back: mdiArrowLeft,
  camera: mdiCamera,
  carencia: mdiCalendarAlert,
  categoriaFinanceira: mdiTagOutline,
  cellphone: mdiCellphoneAndroid,
  centroCusto: mdiBookmarkOutline,
  chevronDown: mdiChevronDown,
  chevronLeft: mdiChevronLeft,
  chevronRight: mdiChevronRight,
  chevronUp: mdiChevronUp,
  cid10: mdiBuffer,
  circle: mdiCircle,
  clinica: mdiHospitalBuilding,
  clinicaBox: mdiHospitalBoxOutline,
  clinicaMarker: mdiHospitalMarker,
  clipboard: mdiClipboardText,
  close: mdiClose,
  compartilhar: mdiShareVariant,
  configuracaoAccount: mdiCog,
  configuracaoFinanceira: mdiDatabaseCogOutline,
  contaFinanceira: mdiBankOutline,
  contaFinanceiraTipo,
  contatos: mdiPhoneInTalk,
  convenio: mdiHospitalBox,
  convenioPlanos: mdiFormatListCheckbox,
  cor: mdiPalette,
  dashboard: mdiViewQuilt,
  date: mdiCalendar,
  dateRange: mdiCalendarRange,
  dateRangePeriodo: mdiCalendarSearch,
  dateRangeRapido: mdiCalendarStar,
  despesa: mdiBriefcaseMinus,
  document: mdiTextBox,
  download: mdiDownload,
  drag: mdiDrag,
  edit: mdiPencil,
  email: mdiEmail,
  exame: mdiTextBoxMultipleOutline,
  exportar: mdiFileExportOutline,
  extrato: mdiTimetable,
  file: mdiFile,
  fileAudio: mdiFileMusic,
  fileChart: mdiFileChart,
  fileMsExcel: mdiFileExcel,
  fileMsPowerPoint: mdiFilePowerpoint,
  fileMsWord: mdiFileWord,
  filePdf: mdiFilePdf,
  fileTree: mdiFileTree,
  fileVideo: mdiFileVideo,
  fileZip: mdiFolderZip,
  filter: mdiFilter,
  filterMinus: mdiFilterMinus,
  filterPlus: mdiFilterPlus,
  financas: mdiCashMultiple,
  financasDashboard: mdiViewDashboard,
  fluxoCaixa: mdiFinance,
  folderOpen: mdiFolderOpen,
  historico: mdiHistory,
  homeMarker: mdiHomeMapMarker,
  image: mdiImage,
  imageEdit: mdiImageEdit,
  imageInsert: mdiImagePlus,
  imageRemove: mdiImageRemove,
  imprimir: mdiPrinter,
  information: mdiInformationOutline,
  integracao: mdiSourceMerge,
  layers: mdiLayers,
  listaEspera: mdiClock,
  listaEsperaAdd: mdiPlaylistPlus,
  logs: mdiFolderOpen,
  menuDown: mdiMenuDown,
  meuPerfil: mdiAccountDetails,
  money: mdiCashMultiple,
  new: mdiPlus,
  note: mdiNoteText,
  number1Circle: mdiNumeric1Circle,
  number2Circle: mdiNumeric2Circle,
  number3Circle: mdiNumeric3Circle,
  outrasInformacoes: mdiCheckbook,
  pacienteDadosComplementares: mdiClipboardText,
  pacienteDadosPessoais: mdiCardAccountDetails,
  pacienteLogAlteracoes: mdiBackupRestore,
  pacienteNew: mdiAccountPlus,
  pacienteParentes: mdiHumanMaleFemale,
  pacientes: mdiContacts,
  password: mdiLock,
  passwordHide: mdiEyeOff,
  passwordReset: mdiLockReset,
  passwordShow: mdiEye,
  permissoesEnvio: mdiMessageSettingsOutline,
  person: mdiAccount,
  phone: mdiPhone,
  play: mdiPlay,
  plus: mdiPlus,
  plusCircle: mdiPlusCircle,
  prescricao: mdiClipboardTextOutline,
  procedimento: mdiStethoscope,
  profissional: mdiDoctor,
  prontuarioEmpty: mdiClipboardPulseOutline,
  prontuarioPlay: mdiClipboardPlayOutline,
  prontuarioSecao: mdiBuffer,
  prontuarioSecaoInputTipo: mdiApps,
  prontuarioSecaoNewField: mdiPlaylistPlus,
  receita: mdiBriefcasePlus,
  recepcionista: mdiHumanGreeting,
  refresh: mdiRefresh,
  regrasRepasse: mdiInboxMultiple,
  relatorioAtendimentosRealizados: mdiAccountCheck,
  relatorioFaltasPaciente: mdiAccountOff,
  relatorioPacientesIndicacao: mdiAccountConvert,
  relatorioPacientesPeriodo: mdiCalendarRange,
  relatorioPacientesRetorno: mdiClipboardAccount,
  relatorios: mdiChartBar,
  remove: mdiDelete,
  reset: mdiRestore,
  sair: mdiExitToApp,
  saveAll: mdiContentSaveAll,
  search: mdiMagnify,
  selectedRows: mdiCheckboxMultipleMarked,
  settings: mdiDotsVertical,
  sidebarCollapse: mdiBackburger,
  sidebarExpand: mdiForwardburger,
  smallAdd: mdiPlusCircle,
  smallRemove: mdiCloseCircle,
  sms: mdiMessage,
  smsEnviados: mdiMessageTextOutline,
  stop: mdiStop,
  tableLarge: mdiTableLarge,
  tableSearch: mdiTableSearch,
  time: mdiClock,
  timer: mdiTimer,
  timerOutline: mdiTimerOutline,
  tissGuiaConsulta: mdiClipboardPulseOutline,
  tissGuiaHonorarios: mdiFileClockOutline,
  tissGuiaSpSadt: mdiCalendarTextOutline,
  tissGuiaOdontologica: mdiToothOutline,
  tissLote: mdiTextBoxMultipleOutline,
  tissProcedimento: mdiTable,
  transacaoNotPaid: mdiClockOutline,
  transacaoPaid: mdiCheckboxMarkedCircle,
  transferencia: mdiSwapHorizontal,
  upload: mdiCloudUpload,
  userAccount: mdiAccountCircle,
  users: mdiAccountMultiple,
  webcam: mdiWebcam,
  zoomIn: mdiMagnifyPlusOutline,
  zoomOut: mdiMagnifyMinusOutline,
} as const;
