import { ConfiguracaoGraphql } from '@/graphql/configuracao/ConfiguracaoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import {
  ConfiguracaoAgendaVisualizacaoPadrao,
  IConfiguracaoAgendaFormModel,
} from '@/typings';

export const ConfiguracaoAgendaService = {
  async get(profissionalId: string) {
    try {
      return await ConfiguracaoGraphql.configuracaoAgenda({ profissionalId });
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    profissionalId,
    model,
  }: {
    profissionalId: string;
    model: IConfiguracaoAgendaFormModel;
  }) {
    try {
      const result = await ConfiguracaoGraphql.updateConfiguracaoAgenda({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: {
          visualizacaoPadrao: Validate.require(
            model.padroes.visualizacaoPadrao,
            'visualizacaoPadrao',
          ) as ConfiguracaoAgendaVisualizacaoPadrao,
          alertaPeriodoCarencia: model.carencia.alertaPeriodoCarencia,
          mostrarDiasBloqueados: model.diasBloqueados.mostrarDiasBloqueados,
          domingo: model.diasAtendimento.domingo,
          segunda: model.diasAtendimento.segunda,
          terca: model.diasAtendimento.terca,
          quarta: model.diasAtendimento.quarta,
          quinta: model.diasAtendimento.quinta,
          sexta: model.diasAtendimento.sexta,
          sabado: model.diasAtendimento.sabado,
          horaInicio: Validate.time(
            model.horarioAtendimento.horaInicio,
            'horaInicio',
          ),
          horaFim: Validate.time(model.horarioAtendimento.horaFim, 'horaFim'),
          horarioAlmoco: model.horarioAlmoco.horarioAlmoco,
          horaAlmocoInicio: Validate.time(
            model.horarioAlmoco.horaAlmocoInicio,
            'horaAlmocoInicio',
          ),
          horaAlmocoFim: Validate.time(
            model.horarioAlmoco.horaAlmocoFim,
            'horaAlmocoFim',
          ),
          observacao: model.outrasInformacoes.observacao,
          procedimentoPadraoId: Validate.require(
            model.padroes.procedimentoPadraoId,
            'procedimentoPadraoId',
          ),
          procedimentosCarenciasIds: model.procedimentosCarenciasIds,
        },
      });

      if (result) {
        toastSuccess('Configuração da Agenda atualizada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};
