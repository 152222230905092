import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { handleError } from '@/lib/helpers/error';
import { IConfirmacaoCheckAgendamentoMutationVariables } from '@/typings';

export const AgendamentoConfirmacao = {
  async confirmacaoCheckAgendamento(
    data: IConfirmacaoCheckAgendamentoMutationVariables,
  ) {
    try {
      return await AgendamentoGraphql.confirmacaoCheckAgendamento(data);
    } catch (error) {
      handleError(error);

      return null;
    }
  },

  async confirmacaoCancelAgendamento(confirmacaoToken: string) {
    try {
      return await AgendamentoGraphql.confirmacaoCancelAgendamento({
        confirmacaoToken,
      });
    } catch (error) {
      handleError(error);

      return null;
    }
  },
};
