import { useValue } from '@/lib/composables';
import { FileHelpers } from '@/lib/helpers/file.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { FileUploadService } from '@/lib/services/system/fileUpload.service';
import { createComponent, watchRun } from '@/lib/vue';
import { IFileUploadInfo } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { Alert } from '../../alerts/Alert';
import { FilesDropzone } from './FilesDropzone';
import { FilesList } from './FilesList';

interface IProps {}

interface IEvents {
  onInput: (value: IFileUploadInfo[]) => void;
}
export const FileUpload = createComponent<IProps, IEvents>({
  name: 'FileUpload',
  setup(props, ctx) {
    const {
      $largeFiles,
      $showAlert,
      handleCloseAlert,
      setLargeFiles,
      setCloseAlert,
    } = useLargeFiles();

    const { $files, handleDropzoneInput } = useUploadFiles({
      ctx,
      setLargeFiles,
      setCloseAlert,
    });

    return () => (
      <div class="flex flex-col">
        <FilesDropzone onInput={handleDropzoneInput} class="mb-4" />

        <Alert
          type="warning"
          show={$showAlert.value}
          onClose={handleCloseAlert}
          class="mb-4"
        >
          <p>Arquivos que ultrapassaram o tamanho máximo de 10 MB:</p>

          <ul>
            {$largeFiles.value.map(v => (
              <li key={v.name}>
                {v.name} ({FileHelpers.formatSize(v.size)})
              </li>
            ))}
          </ul>
        </Alert>

        <FilesList v-model={$files.value} />
      </div>
    );
  },
});

function useLargeFiles() {
  const [$largeFiles, setLargeFiles] = useValue<File[]>([]);

  const [$closedAlert, setCloseAlert] = useValue(false);

  const $showAlert = computed(
    () => $largeFiles.value.length > 0 && !$closedAlert.value,
  );

  function handleCloseAlert() {
    setCloseAlert(true);
  }

  return {
    $largeFiles,
    setLargeFiles,
    $showAlert,
    handleCloseAlert,
    setCloseAlert,
  };
}

function useUploadFiles({
  ctx,
  setLargeFiles,
  setCloseAlert,
}: {
  ctx: SetupContext;
  setLargeFiles: (v: File[]) => void;
  setCloseAlert: (v: boolean) => void;
}) {
  const [$files, setFiles] = useValue<IFileUploadInfo[]>([]);

  function emitInput(value: IFileUploadInfo[]) {
    ctx.emit('input', value);
  }

  async function uploadFiles() {
    $files.value
      .filter(f => !f.id)
      .forEach(async v => {
        const id = await FileUploadService.upload({
          name: v.file.name,
          type: v.file.type,
          file: v.file,
        });

        if (id) return setFileId({ id, uuid: v.uuid });
      });
  }

  function setFileId({ id, uuid }: { id: string; uuid: string }) {
    setFiles(
      $files.value.map(v => {
        if (v.uuid !== uuid) return v;

        return { ...v, id };
      }),
    );
  }

  function handleDropzoneInput(files: File[]) {
    const maxAllowedSize = 10 * 1024 * 1024; // 10 MB

    setLargeFiles(files.filter(f => f.size > maxAllowedSize));
    setCloseAlert(false);

    setFiles([
      ...$files.value,
      ...files
        .filter(f => f.size <= maxAllowedSize)
        .map(file => ({
          uuid: uuid(),
          id: null,
          file,
          description: null,
        })),
    ]);

    return uploadFiles();
  }

  watchRun($files, emitInput, { deep: true });

  return {
    $files,
    handleDropzoneInput,
  };
}
