import { createComponent } from '@/lib/vue';

export const ProntuarioCardForm = createComponent({
  name: 'ProntuarioCardForm',
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-card
          id="ProntuarioCardForm"
          class="flex flex-col w-full max-w-screen-lg mx-auto"
        >
          {defaultSlot}
        </v-card>
      );
    };
  },
});
