import { FormFields } from '@/components/form/fields/FormFields';
import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import {
  IFormSchema,
  IInputItems,
  IProntuarioState,
  ITimelineFilterModel,
  TimelineFilterItem,
} from '@/typings';
import { computed, ComputedRef, Ref, SetupContext } from '@vue/composition-api';
import uniqBy from 'lodash/uniqBy';
import { BtnImprimir } from '../../utils/BtnImprimir';

export const TimelineFilter = createComponent({
  name: 'TimelineFilter',
  setup(props, ctx) {
    const { $schema, $state } = useData(ctx);

    const { handleInput, handleImprimir } = useEvents($state);

    return () => (
      <div class="flex items-center justify-end">
        <BtnImprimir large class="mt-2 mr-6" handleImprimir={handleImprimir} />

        <FormFields
          schema={$schema.value}
          value={$state.value}
          maxWidth={312}
          onInput={handleInput}
        />
      </div>
    );
  },
});

function useData(ctx: SetupContext) {
  const $state = useState(s => s.prontuario);

  const $schema = computed<IFormSchema<ITimelineFilterModel>>(() => ({
    filter: {
      label: 'Filtrar por',
      type: 'select',
      items: getFilterItems($state),
      clearable: true,
      prependIcon: MyIcons.filter,
      hideDetails: true,
      layout: { width: 306 },
    },
  }));

  return { $schema, $state };
}

function useEvents($state: ComputedRef<IProntuarioState>) {
  function handleInput({ filter }: ITimelineFilterModel) {
    ProntuarioState.setFilter(filter);
  }

  function handleImprimir() {
    const pacienteId = $state.value.paciente?.data.id;

    if (pacienteId) {
      return ProntuarioService.pdf.completoAll(pacienteId);
    }
  }

  return { handleInput, handleImprimir };
}

function getFilterItems($state: Ref<IProntuarioState>): IInputItems[] {
  const items: IInputItems[] = [];

  $state.value.events.forEach(ev => {
    if (ev.preAtendimento) {
      items.push({
        label: 'Pré-atendimento',
        value: TimelineFilterItem.preAtendimento,
      });
    }

    if (ev.hipoteseDiagnostica) {
      items.push({
        label: 'Hipótese diagnóstica',
        value: TimelineFilterItem.hipoteseDiagnostica,
      });
    }

    if (ev.prescricoes?.length) {
      items.push({
        label: 'Prescrições',
        value: TimelineFilterItem.prescricoes,
      });
    }

    if (ev.atestados?.length) {
      items.push({
        label: 'Atestados',
        value: TimelineFilterItem.atestados,
      });
    }

    if (ev.exames?.length) {
      items.push({
        label: 'Solicitação de exames',
        value: TimelineFilterItem.exames,
      });
    }

    if (ev.files?.length) {
      items.push({
        label: 'Imagens e anexos',
        value: TimelineFilterItem.files,
      });
    }

    if (ev.eventos?.length) {
      ev.eventos.forEach(({ nome, prontuarioSecaoId }) => {
        items.push({
          label: nome,
          value: prontuarioSecaoId,
        });
      });
    }
  });

  return uniqBy(items, v => v.value).sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    } else if (labelA > labelB) {
      return 1;
    }

    return 0;
  });
}
