import { MyTheme } from '@/lib/helpers/MyTheme';
import Vue from 'vue';
import { VuetifyUseOptions } from 'vuetify';
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VAvatar,
  VBtn,
  VBtnToggle,
  VCard,
  VCardText,
  VCardTitle,
  VCheckbox,
  VChip,
  VCol,
  VColorPicker,
  VCombobox,
  VContainer,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpandTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFadeTransition,
  VFlex,
  VForm,
  VHover,
  VIcon,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VNavigationDrawer,
  VOverlay,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSlideGroup,
  VSlideItem,
  VSlideYTransition,
  VSpacer,
  VSubheader,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTextarea,
  VTextField,
  VTimeline,
  VTimelineItem,
  VTimePicker,
  VToolbarItems,
  VToolbarTitle,
  VTooltip,
  VTreeview,
} from 'vuetify/lib';

const options: VuetifyUseOptions = {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VAvatar,
    VBtn,
    VBtnToggle,
    VCard,
    VCardText,
    VCardTitle,
    VCheckbox,
    VChip,
    VCol,
    VColorPicker,
    VCombobox,
    VContainer,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VExpandTransition,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFadeTransition,
    VFlex,
    VForm,
    VHover,
    VIcon,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VMain,
    VMenu,
    VNavigationDrawer,
    VOverlay,
    VProgressCircular,
    VProgressLinear,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSheet,
    VSlideGroup,
    VSlideItem,
    VSlideYTransition,
    VSpacer,
    VSubheader,
    VSwitch,
    VTab,
    VTabItem,
    VTabs,
    VTextField,
    VTextarea,
    VTimePicker,
    VTimeline,
    VTimelineItem,
    VToolbarItems,
    VToolbarTitle,
    VTooltip,
    VTreeview,
  },
};

Vue.use(Vuetify, options);

const theme = {
  primary: MyTheme.primary,
  accent: MyTheme.accent,
  secondary: MyTheme.secondary,
  error: MyTheme.error,
  success: MyTheme.success,
  warning: MyTheme.warning,
  info: MyTheme.info,
};

export const vuetify = new Vuetify({
  icons: { iconfont: 'mdiSvg' },
  theme: {
    themes: {
      light: theme,
      dark: theme,
    },
  },
  breakpoint: {
    thresholds: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
  },
  lang: {
    locales: {
      br: {
        close: 'Fechar',
        dataIterator: {
          noResultsText: 'Nenhum resultado encontrado',
          loadingText: '',
        },
        dataTable: {
          sortBy: 'Ordenar por',
          itemsPerPageText: 'Itens por página:',
          ariaLabel: {
            sortDescending: ': Ordenação descendente',
            sortAscending: ': Ordenação ascendente',
            sortNone: ': Não ordenado',
            activateNone: 'Ative para remover a ordenação',
            activateDescending: 'Ative para ordenar decrescente',
            activateAscending: 'Ative para ordenar crescente',
          },
        },
        dataFooter: {
          itemsPerPageText: 'Itens por página:',
          itemsPerPageAll: 'Todos',
          nextPage: 'Próxima página',
          prevPage: 'Página anterior',
          firstPage: 'Primeira página',
          lastPage: 'Última página',
          pageText: '{0}-{1} de {2}',
        },
        noDataText: 'Nenhum resultado encontrado',
        carousel: {
          prev: 'Anterior',
          next: 'Próximo',
        },
        datePicker: {
          itemsSelected: '{0} selecionado(s)',
          nextMonthAriaLabel: 'Próximo mês',
          nextYearAriaLabel: 'Próximo ano',
          prevMonthAriaLabel: 'Mês anterior',
          prevYearAriaLabel: 'Ano anterior',
        },
      },
    },
    current: 'br',
  },
  options: { variations: false },
});
