export class CNPJ {
  static blacklist = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  static verifyDigit(value: string) {
    let index = 2;
    const reverse: number[] = value
      .split('')
      .reduce((buffer, number) => [parseInt(number, 10), ...buffer], []);

    const sum = reverse.reduce((buffer, number) => {
      buffer += number * index;
      index = index === 9 ? 2 : index + 1;
      return buffer;
    }, 0);

    const mod = sum % 11;
    return mod < 2 ? 0 : 11 - mod;
  }

  static format(value: string) {
    return unmaskNumbers(value || '').replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5',
    );
  }

  static isValid(value: string | number) {
    const stripped = unmaskNumbers(value);

    if (
      // CNPJ must be defined
      !stripped ||
      // CNPJ must have 14 chars
      stripped.length !== 14 ||
      // CNPJ can't be blacklisted
      this.blacklist.includes(stripped)
    ) {
      return false;
    }

    let numbers = stripped.substr(0, 12);
    numbers += this.verifyDigit(numbers);
    numbers += this.verifyDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  }

  static generate(formatted = false) {
    let numbers = '';

    for (let i = 0; i < 12; i++) {
      numbers += Math.floor(Math.random() * 9);
    }

    numbers += this.verifyDigit(numbers);
    numbers += this.verifyDigit(numbers);

    return formatted ? this.format(numbers) : numbers;
  }
}

export function unmaskNumbers(value: string | number) {
  return (value || '').toString().replace(/[^\d]/g, '');
}
