import { useValue } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, modifiers } from '@/lib/vue';
import { IForm, IFormRef } from '@/typings';
import { computed, ref, Ref, SetupContext } from '@vue/composition-api';
import { Alert } from '../alerts/Alert';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import { PageSection } from '../page/PageSection';
import { FormSummary } from './utils/FormSummary';

interface IProps {
  form: IForm<any>;
  noDivider?: boolean;
  noActions?: boolean;
  noSummary?: boolean;
  noCancel?: boolean;
  cancelTo?: string | null;
  dialog?: boolean;
  submitLabel?: string;
  noDelete?: boolean;
}

interface IEvents {
  onSubmit: (validate: () => boolean) => void;
  onCancel: () => void;
  onDelete: (id?: string | null) => void;
}

interface IScopedSlots {
  default: { form: IForm<any> };
}

export const MyForm = createComponent<IProps, IEvents, IScopedSlots>({
  name: 'MyForm',
  props: {
    form: { type: Object, required: true },
    noDivider: { type: Boolean, default: false },
    noActions: { type: Boolean, default: false },
    noSummary: { type: Boolean, default: false },
    noCancel: { type: Boolean, default: false },
    cancelTo: { type: String },
    dialog: { type: Boolean, default: false },
    submitLabel: { type: String, default: 'Salvar' },
    noDelete: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const [$valid] = useValue(true);
    const [$formSummary, setFormSummary] = useValue(false);
    const [$alertError, setAlertError] = useValue(false);

    const $formEl = ref<IFormRef | null>(null);

    const {
      $error,
      $summary,
      $submitting,
      $showSummary,
      $showError,
      $showDelete,
    } = useComputeds({
      props,
      ctx,
      $formSummary,
      $alertError,
    });

    const {
      handleSubmit,
      handleCancel,
      handleCloseSummary,
      handleCloseAlertError,
      handleDelete,
    } = useEvents({
      props,
      ctx,
      $formEl,
      setFormSummary,
      setAlertError,
    });

    return () => {
      const defaultSlot = ctx.slots.default?.({ form: props.form });
      const actionsSectionSlot = ctx.slots.actions_section?.();

      return (
        <v-form
          id="MyForm"
          v-model={$valid.value}
          ref={$formEl}
          lazy-validation
          onSubmit={modifiers.prevent(handleSubmit)}
          class="flex flex-col"
        >
          <FormSummary
            summary={$summary.value}
            show={$showSummary.value}
            onClose={handleCloseSummary}
          />

          <Alert
            show={$showError.value}
            text={$error.value}
            type="error"
            onClose={handleCloseAlertError}
          />

          <LoadingOverlay loading={props.form.loading} />

          {defaultSlot}

          {actionsSectionSlot ||
            actionsSection({
              props,
              ctx,
              $submitting,
              $showDelete,
              handleCancel,
              handleDelete,
            })}
        </v-form>
      );
    };
  },
});

function useComputeds({
  props,
  ctx,
  $formSummary,
  $alertError,
}: {
  props: IProps;
  ctx: SetupContext;
  $formSummary: Ref<boolean>;
  $alertError: Ref<boolean>;
}) {
  const $error = computed(() => props.form.page.error);

  const $summary = computed(() => props.form.validationErrors);

  const $submitting = computed(() => props.form.page.submitting);

  const $showSummary = computed(
    () => $formSummary.value && !props.noSummary && !props.dialog,
  );

  const $showError = computed(() => $alertError.value && !!$error.value);

  const $showDelete = computed(
    () =>
      !props.noDelete &&
      !!(ctx as any).listeners.delete &&
      !!props.form.page.id,
  );

  return {
    $error,
    $summary,
    $submitting,
    $showSummary,
    $showError,
    $showDelete,
  };
}

function useEvents({
  props,
  ctx,
  $formEl,
  setFormSummary,
  setAlertError,
}: {
  props: IProps;
  ctx: SetupContext;
  $formEl: Ref<IFormRef | null>;
  setFormSummary: (v: boolean) => void;
  setAlertError: (v: boolean) => void;
}) {
  function validate() {
    if ($formEl.value) {
      return $formEl.value.validate();
    }
  }

  function handleSubmit() {
    setFormSummary(true);
    setAlertError(true);

    ctx.emit('submit', validate);
  }

  function handleCancel() {
    if (props.cancelTo) {
      return useRouter().push(props.cancelTo);
    }

    return ctx.emit('cancel');
  }

  function handleCloseSummary() {
    setFormSummary(false);
  }

  function handleCloseAlertError() {
    setAlertError(false);
  }

  function handleDelete() {
    return ctx.emit('delete', props.form.page.id);
  }

  return {
    handleSubmit,
    handleCancel,
    handleCloseSummary,
    handleCloseAlertError,
    handleDelete,
  };
}

const actionsSection = ({
  props,
  ctx,
  $submitting,
  $showDelete,
  handleCancel,
  handleDelete,
}: {
  props: IProps;
  ctx: SetupContext;
  $submitting: Ref<boolean>;
  $showDelete: Ref<boolean>;
  handleCancel: () => any;
  handleDelete: () => void;
}) => {
  const actionsSlot = ctx.slots.actions?.();

  return (
    !props.noActions && (
      <PageSection divider={!props.noDivider}>
        {actionsSlot || (
          <div class="flex">
            {$showDelete.value && (
              <v-btn
                color="primary"
                text
                disabled={$submitting.value}
                onClick={handleDelete}
              >
                <v-icon>{MyIcons.remove}</v-icon>
                Excluir
              </v-btn>
            )}

            <v-spacer />

            {!props.noCancel && (
              <v-btn
                text
                type="button"
                color="primary"
                loading={$submitting.value}
                disabled={$submitting.value}
                onClick={handleCancel}
              >
                Cancelar
              </v-btn>
            )}

            <v-btn
              type="submit"
              color="primary"
              class="ml-2"
              loading={$submitting.value}
              disabled={$submitting.value}
            >
              {props.submitLabel}
            </v-btn>
          </div>
        )}
      </PageSection>
    )
  );
};
