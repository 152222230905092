import {
  IInputProps,
  useInput,
  useInputClearable,
} from '@/lib/composables/inputs/useInput';
import { isNumeric } from '@/lib/form';
import { maskValue, removeMask } from '@/lib/form/mask';
import { createComponent } from '@/lib/vue';
import { IFormInputText, IFormInputTextMask } from '@/typings';
import { computed, ref, Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IInputProps {
  // model
  value?: string | null;
  input: IFormInputText;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputText = createComponent<IProps, IEvents>({
  name: 'InputText',
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const { $currentMask, $formatted, $maxLength } = useComputeds(props);

    const $inputEl = ref<HTMLInputElement | null>(null);

    const { events } = useEvents({ ctx, $currentMask, $inputEl });

    return () => (
      <v-text-field
        ref={$inputEl}
        name={props.name}
        type="text"
        outlined
        label={$label.value}
        placeholder={props.input.placeholder}
        errorMessages={props.errorMessages}
        value={$formatted.value}
        prependInnerIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        prefix={props.input.prefix}
        suffix={props.input.suffix}
        disabled={props.input.disabled}
        loading={props.input.loading}
        autofocus={props.input.autofocus}
        required={$isRequired.value}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        hideDetails={!!props.input.hideDetails}
        clearable={props.input.clearable}
        maxlength={$maxLength.value}
        {...events}
      />
    );
  },
});

function useComputeds(props: IProps) {
  const masks: { [key in IFormInputTextMask]: string } = {
    cep: '#####-###',
    cpf: '###.###.###-##',
    cnpj: '##.###.###/####-##',
    cpfOrCnpj: '###.###.###-###',
    telefone: '(##) ####-#####',
    celular: '(##) # ####-####',
    cartaoCredito: '#### #### #### ####',
    mesAno: '##/####',
    cvv: '####',
    tissProcedimentoCodigo: '########',
    numbers6: '######',
    numbers12: '############',
    numbers20: '####################',
  };

  const $currentMask = computed(() => {
    if (!props.input.mask) return null;

    if (props.input.mask === 'telefone') {
      if (props.value?.length === 11) {
        return masks.celular;
      }

      return masks.telefone;
    } else if (props.input.mask === 'cpfOrCnpj') {
      if (props.value && props.value.length > 11) {
        return masks.cnpj;
      }

      return masks.cpfOrCnpj;
    }

    return masks[props.input.mask] || null;
  });

  const $formatted = computed(() => maskValue(props.value, $currentMask.value));

  const $maxLength = computed(
    () => $currentMask.value?.length || props.input.maxLength,
  );

  return {
    masks,
    $currentMask,
    $formatted,
    $maxLength,
  };
}

function useEvents({
  ctx,
  $currentMask,
  $inputEl,
}: {
  ctx: SetupContext;
  $currentMask: Ref<string | null>;
  $inputEl: Ref<HTMLInputElement | null>;
}) {
  function emitInput(newValue: string | null) {
    const value = $currentMask.value
      ? removeMask(newValue)
      : (newValue || '').trim();

    ctx.emit('input', value);
  }

  const { handleClear } = useInputClearable({ ctx, $inputEl });

  function handleKeyPress(evt: KeyboardEvent) {
    if ($currentMask.value && !isNumeric(evt.key)) {
      return evt.preventDefault();
    }
  }

  const events = {
    on: {
      'click:clear': handleClear,
      input: emitInput,
      keypress: handleKeyPress,
    },
  };

  return { events };
}
