import { permissionDeniedError } from '@/routes/utils';
import { userIsProfissionalSaude } from '@/store/utils/auth';

import { NavigationGuard } from 'vue-router';

export const dashboardGuard: NavigationGuard = (to, _from, next) => {
  if (!userIsProfissionalSaude()) {
    return permissionDeniedError(to, next);
  }

  return next();
};
