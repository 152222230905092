import { checkIsAuthenticated } from '@/lib/services/auth/utils';
import { Routes } from '@/routes/routes';
import { userIsProfissionalSaude } from '@/store/utils/auth';

import { IRouteConfig, TransacaoTipo } from '@/typings';
import { NavigationGuard, Route } from 'vue-router';
import { router } from '.';

const PacientesDiaSidebar = () =>
  import('@/modules/agenda/components/sidebars/PacientesDiaSidebar');
const AgendaProfissionaisSidebar = () =>
  import('@/modules/agenda/components/sidebars/AgendaProfissionaisSidebar');

export const sidebarAgenda = () =>
  userIsProfissionalSaude()
    ? PacientesDiaSidebar()
    : AgendaProfissionaisSidebar();

export const routeGroup = ({
  prefix,
  routes,
  beforeEnter,
}: {
  prefix: string;
  routes: IRouteConfig[];
  beforeEnter?: NavigationGuard;
}) =>
  routes.map(route => {
    route.path = `${prefix}/${route.path}`;

    if (beforeEnter) {
      route.beforeEnter = beforeEnter;
    }

    return route;
  });

export const permissionDeniedError = (
  to: Route,
  next: (to?: string) => void,
) => {
  console.error(`Permission denied: "${to.fullPath}"`);

  return next(Routes.error.permissionDenied);
};

export const redirectHome: NavigationGuard = async (to, from, next) => {
  if (await checkIsAuthenticated()) {
    return next(Routes.app.home);
  }

  return next(Routes.auth.login);
};

export const transacaoTipoToQueryParams = (tipo?: TransacaoTipo | null) => {
  if (tipo === TransacaoTipo.DESPESA) {
    return { idx: 'd' };
  } else if (tipo === TransacaoTipo.RECEITA) {
    return { idx: 'r' };
  }

  return { idx: '' };
};

export function redirectError(errorType: 403 | 404 | 500) {
  if (errorType === 403) {
    return router.replace(Routes.error.permissionDenied);
  } else if (errorType === 404) {
    return router.replace(Routes.error.notFound);
  } else if (errorType === 500) {
    return router.replace(Routes.error.internalServerError);
  }
}

export function goHome() {
  return router.replace(Routes.app.home);
}
