import {
  IInputProps,
  useInput,
  useInputClearable,
} from '@/lib/composables/inputs/useInput';
import { createComponent } from '@/lib/vue';
import { IFormInputEmail } from '@/typings';
import { Ref, ref, SetupContext } from '@vue/composition-api';

interface IProps extends IInputProps {
  value?: string | null;
  input: IFormInputEmail;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputEmail = createComponent<IProps, IEvents>({
  name: 'InputEmail',
  props: {
    name: { type: String },
    value: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const $inputEl = ref<HTMLInputElement | null>(null);

    const { events } = useEvents({ ctx, $inputEl });

    return () => (
      <v-text-field
        ref={$inputEl}
        name={props.name}
        outlined
        type="email"
        label={$label.value}
        errorMessages={props.errorMessages}
        value={props.value}
        required={$isRequired.value}
        prependInnerIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        disabled={props.input.disabled}
        loading={props.input.loading}
        autofocus={props.input.autofocus}
        readonly={props.input.readonly}
        clearable={props.input.clearable}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        hideDetails={!!props.input.hideDetails}
        {...events}
      />
    );
  },
});

function useEvents({
  ctx,
  $inputEl,
}: {
  ctx: SetupContext;
  $inputEl: Ref<HTMLInputElement | null>;
}) {
  const { handleClear } = useInputClearable({ ctx, $inputEl });

  function handleChange(newValue: string | null) {
    emitInput((newValue || '').trim());
  }

  function emitInput(value: string | null) {
    ctx.emit('input', value);
  }

  const events = {
    on: {
      'click:clear': handleClear,
      input: handleChange,
    },
  };

  return { events };
}
