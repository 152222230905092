// tslint:disable: trailing-comma
import { configuracoesClinicaGuard } from '@/routes/guards/configuracoesClinica.guard';
import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const ConfiguracaoClinicaSidebar = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/components/ConfiguracaoClinicaSidebar'
  );
const CategoriasFinanceirasPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/categoria/CategoriasFinanceirasPage'
  );
const CategoriaFinanceiraFormPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/categoria/CategoriaFinanceiraFormPage'
  );
const CentrosCustosPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/centroCusto/CentrosCustosPage'
  );
const CentroCustoFormPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/centroCusto/CentroCustoFormPage'
  );
const ContasFinanceirasPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/conta/ContasFinanceirasPage'
  );
const ContaFinanceiraFormPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/conta/ContaFinanceiraFormPage'
  );
const ConveniosPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/convenio/ConveniosPage'
  );
const ConvenioFormPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/convenio/ConvenioFormPage'
  );
const ClinicaPerfilPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/ClinicaPerfilPage'
  );
const ConfiguracoesFinanceirasPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/financas/pages/ConfiguracoesFinanceirasPage'
  );
const EquipePage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/EquipePage'
  );
const RegrasRepassePage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/RegrasRepassePage'
  );
const ExportarDadosPage = () =>
  import(
    /* webpackChunkName: "config-clinica" */ '@/modules/configuracoes/clinica/pages/ExportarDadosPage'
  );

export const configuracoesClinicaRoutes: IRouteConfig[] = routeGroup({
  prefix: 'configuracoes/clinica',
  beforeEnter: configuracoesClinicaGuard,
  routes: [
    ...routeGroup({
      prefix: 'categorias-financeiras',
      routes: [
        {
          path: '',
          components: {
            default: CategoriasFinanceirasPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: CategoriaFinanceiraFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: CategoriaFinanceiraFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'centros-de-custo',
      routes: [
        {
          path: '',
          components: {
            default: CentrosCustosPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: CentroCustoFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: CentroCustoFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'contas-financeiras',
      routes: [
        {
          path: '',
          components: {
            default: ContasFinanceirasPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ContaFinanceiraFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ContaFinanceiraFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
      ],
    }),

    ...routeGroup({
      prefix: 'convenios',
      routes: [
        {
          path: '',
          components: {
            default: ConveniosPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: 'new',
          components: {
            default: ConvenioFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
        {
          path: ':id',
          components: {
            default: ConvenioFormPage,
            sidebar: ConfiguracaoClinicaSidebar,
          },
        },
      ],
    }),

    {
      path: 'perfil',
      components: {
        default: ClinicaPerfilPage,
        sidebar: ConfiguracaoClinicaSidebar,
      },
    },
    {
      path: 'configuracoes-financeiras',
      components: {
        default: ConfiguracoesFinanceirasPage,
        sidebar: ConfiguracaoClinicaSidebar,
      },
    },
    {
      path: 'equipe',
      components: {
        default: EquipePage,
        sidebar: ConfiguracaoClinicaSidebar,
      },
    },
    {
      path: 'regras-repasse',
      components: {
        default: RegrasRepassePage,
        sidebar: ConfiguracaoClinicaSidebar,
      },
    },
    {
      path: 'exportar-dados',
      components: {
        default: ExportarDadosPage,
        sidebar: ConfiguracaoClinicaSidebar,
      },
    },
  ],
});
