import Vue from 'vue';
import { ProntuarioState, prontuarioStateFn } from '..';
import { prontuarioPages } from '../utils';

export const ProntuarioAtendimentoState = {
  start() {
    ProntuarioState.commit(s => {
      s.atendimento.playing = true;
      s.atendimento.startAt = new Date();
      s.atendimento.endAt = null;
      s.atendimento.submitted = false;

      Vue.set(s, 'forms', prontuarioStateFn().forms);
    });
  },

  end() {
    ProntuarioState.commit(s => {
      s.atendimento.playing = false;
      s.atendimento.endAt = new Date();
      s.atendimento.submitted = false;

      s.page = prontuarioPages.historicoClinico;
    });
  },

  timer: {
    toggle() {
      ProntuarioState.commit(s => {
        s.atendimento.showTimer = !s.atendimento.showTimer;
      });
    },
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.atendimento.submitted = submitted;

      if (submitted) {
        // PreAtendimento
        s.forms.preAtendimento.submitted =
          s.forms.preAtendimento.hasError || s.forms.preAtendimento.submitted;
        // Exames
        s.forms.exames.submitted =
          s.forms.exames.hasError || s.forms.exames.submitted;
        // Prescrições
        s.forms.prescricoes.submitted =
          s.forms.prescricoes.hasError || s.forms.prescricoes.submitted;
        // Atestados
        s.forms.atestados.submitted =
          s.forms.atestados.hasError || s.forms.atestados.submitted;
        // HipoteseDiagnostica
        s.forms.hipoteseDiagnostica.submitted =
          s.forms.hipoteseDiagnostica.hasError ||
          s.forms.hipoteseDiagnostica.submitted;
      }
    });
  },
};
