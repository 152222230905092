import { CentroCustoGraphql } from '@/graphql/financas/centroCusto/CentroCustoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { ICentroCustoModel, IServiceOnSuccessArgs } from '@/typings';

export const CentroCustoService = {
  async getById(id: string) {
    try {
      return await CentroCustoGraphql.centroCusto({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({ nome }: ICentroCustoModel) {
    try {
      const result = await CentroCustoGraphql.createCentroCusto({
        data: {
          nome: Validate.require(nome, 'nome'),
        },
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete(
        {
          text: 'este centro de custo',
        },
        async () => {
          const result = await CentroCustoGraphql.deleteCentroCusto({ id });

          onSuccess({ result, msg: 'excluído' });
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os centros de custos selecionados',
            'o centro de custo selecionado',
          ),
        },
        async () => {
          const result = await CentroCustoGraphql.deleteManyCentrosCustos({
            ids,
          });

          if (result) {
            const msg = pluralize(
              ids,
              'Centros de custos excluídos',
              'Centro de custo excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, { nome }: ICentroCustoModel) {
    try {
      const result = await CentroCustoGraphql.updateCentroCusto({
        id: Validate.require(id, 'id'),
        data: {
          nome: Validate.require(nome, 'nome'),
        },
      });

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },
};

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configClinica.financas.centrosCusto.index);

    toastSuccess(`Centro de custo ${msg} com sucesso`);
  }
}
