import { getAccessTokenPayload } from '@/lib/auth/auth';
import { TokenService } from '@/lib/auth/token';
import { Validate } from '@/lib/helpers/error';
import { AuthLoginService } from '@/lib/services';
import {
  checkIsAuthenticated,
  loadClinicaFromUser,
} from '@/lib/services/auth/utils';
import { Routes } from '@/routes/routes';
import { store } from '@/store';
import { AuthState } from '@/store/modules/auth.store';
import { IMyUserFragment } from '@/typings';
import { NavigationGuard } from 'vue-router';

export const requireAuthGuard: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = await checkIsAuthenticated();

  if (!isAuthenticated) {
    // remove old tokens
    TokenService.clearAll();
    AuthState.setAuthPayload(null);

    return next(Routes.auth.login);
  }

  const user = await loadUser();

  if (user.signUpIncomplete) {
    if (!Routes.auth.isSignUpPart2(to)) {
      return next(Routes.auth.signUpPart2);
    }

    return next();
  }

  const { clinicaId } = getAccessTokenPayload()!;

  const clinica = loadClinica({ user, clinicaId });

  if (!clinica || !clinicaId || clinica.allowSelect) {
    if (!Routes.auth.isClinicas(to)) {
      return next(Routes.auth.clinicas);
    }

    return next();
  }

  if (Routes.auth.isAuth(to)) {
    return next(Routes.app.home);
  }

  return next();
};

async function loadUser() {
  const stateUser = store.state.auth.user;
  if (stateUser) {
    return stateUser;
  }

  const tokenUser = TokenService.user.get();
  if (tokenUser) {
    AuthState.setUser(tokenUser);

    return tokenUser;
  }

  const myUser = await AuthLoginService.loadMyUser();

  return Validate.require(myUser, 'user');
}

function loadClinica({
  user,
  clinicaId,
}: {
  user: IMyUserFragment;
  clinicaId: string | null;
}) {
  const stateClinica = store.state.auth.clinica;
  if (stateClinica) {
    return stateClinica;
  }

  const tokenClinica = TokenService.clinica.get();
  if (tokenClinica) {
    AuthState.setClinica(tokenClinica);

    return tokenClinica;
  }

  return loadClinicaFromUser({ user, clinicaId });
}
