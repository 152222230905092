import { ProntuarioSecaoGraphql } from '@/graphql/profissional/prontuarioSecao/ProntuarioSecaoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { uuid } from '@/lib/helpers/uuid';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IProntuarioSecaoFormModel,
  IServiceOnSuccessProfissionalArgs,
  ProntuarioSecaoDataInput,
  ProntuarioSecaoOrderBy,
  ProntuarioSecaoUpdateOrdemDataInput,
} from '@/typings';

export const ProntuarioSecaoService = {
  async getById(id: string) {
    try {
      return await ProntuarioSecaoGraphql.prontuarioSecao({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async getAll({ profissionalId }: { profissionalId: string }) {
    try {
      return await ProntuarioSecaoGraphql.prontuariosSecoes({
        profissionalId,
        orderBy: [ProntuarioSecaoOrderBy.ordem_ASC],
      });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    profissionalId: string;
    model: IProntuarioSecaoFormModel;
  }) {
    try {
      const result = await ProntuarioSecaoGraphql.createProntuarioSecao({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toProntuarioSecaoDataInput(model),
      });

      onSuccess({
        result,
        msg: 'cadastrada',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async delete({ id, profissionalId }: { id: string; profissionalId: string }) {
    try {
      await validateDelete({ text: 'esta seção do prontuário' }, async () => {
        const result = await ProntuarioSecaoGraphql.deleteProntuarioSecao({
          id,
        });

        onSuccess({
          result,
          msg: 'excluída',
          profissionalId,
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'as seções do prontuário selecionadas',
            'a seção do prontuário selecionada',
          ),
        },
        async () => {
          const result =
            await ProntuarioSecaoGraphql.deleteManyProntuariosSecoes({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Seções do prontuário excluídas',
              'Seção do prontuário excluída',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    profissionalId,
    model,
  }: {
    id: string;
    profissionalId: string;
    model: IProntuarioSecaoFormModel;
  }) {
    try {
      const result = await ProntuarioSecaoGraphql.updateProntuarioSecao({
        id: Validate.require(id, 'id'),
        data: toProntuarioSecaoDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterada',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateOrdem({
    data,
    profissionalId,
  }: {
    profissionalId: string;
    data: ProntuarioSecaoUpdateOrdemDataInput[];
  }) {
    try {
      const result = await ProntuarioSecaoGraphql.updateProntuarioSecaoOrdem({
        data,
        profissionalId,
      });

      if (result) {
        toastSuccess('Seções do prontuário alteradas com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toProntuarioSecaoDataInput({
  nome,
  fields,
}: IProntuarioSecaoFormModel): ProntuarioSecaoDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    fields: fields.map(v => ({
      id: v.id || uuid(),
      label: Validate.require(v.label, 'label'),
      type: Validate.require(v.type, 'type'),
      suffix: v.suffix,
      decimalPlaces: v.decimalPlaces,
      items: v.items,
    })),
  };
}

function onSuccess<T>({
  result,
  msg,
  profissionalId,
}: IServiceOnSuccessProfissionalArgs<T>) {
  if (result) {
    router.push(
      Routes.app.configProfissionais(profissionalId).prontuario.secoes.index,
    );

    toastSuccess(`Seção do prontuário ${msg} com sucesso`);
  }
}
