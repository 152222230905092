import { ConvenioGraphql } from '@/graphql/convenio/ConvenioGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  ConvenioDataInput,
  IConvenioFormModel,
  IConvenioProfissionalPlano,
  IConvenioUpdatePlanosModel,
  IServiceOnSuccessArgs,
  IUpdateConveniosByProfissionalMutationVariables,
} from '@/typings';
import flatten from 'lodash/flatten';
import { appMainScrollToTop } from '../helpers/scroll';
import { validateDelete } from '../helpers/services';
import { pluralize } from '../helpers/utils';

export const ConvenioService = {
  async getById(id: string) {
    try {
      return await ConvenioGraphql.convenio({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async getParticular() {
    try {
      const resp = await ConvenioGraphql.convenios({
        where: {
          particular: true,
        },
      });
      if (!resp || !resp.nodes.length) {
        return null;
      }

      return resp.nodes[0];
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IConvenioFormModel) {
    try {
      const result = await ConvenioGraphql.createConvenio({
        data: toConvenioDataInput(model),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'este convênio' }, async () => {
        const result = await ConvenioGraphql.deleteConvenio({ id });

        onSuccess({ result, msg: 'excluído' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os convênios selecionados',
            'o convênio selecionado',
          ),
        },
        async () => {
          const result = await ConvenioGraphql.deleteManyConvenios({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Convênios excluídos',
              'Convênio excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: IConvenioFormModel) {
    try {
      const result = await ConvenioGraphql.updateConvenio({
        id: Validate.require(id, 'id'),
        data: toConvenioDataInput(model),
      });

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },

  async updateConvenios({
    convenios,
    profissionalId,
  }: IUpdateConveniosByProfissionalMutationVariables) {
    try {
      const result = await ConvenioGraphql.updateConveniosByProfissional({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        convenios,
      });

      if (result) {
        toastSuccess('Convênios alterados com sucesso');

        appMainScrollToTop();
      }
    } catch (error) {
      handleError(error);
    }
  },

  async updatePlanos({
    profissionalId,
    convenioId,
    codigoOperadora,
    planos,
  }: IConvenioUpdatePlanosModel) {
    try {
      const result = await ConvenioGraphql.updateConvenioPlanosByProfissional({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        convenioId: Validate.require(convenioId, 'convenioId'),
        codigoOperadora,
        planos: planos.map(v => ({
          planoId: Validate.require(v.planoId, 'planoId'),
          periodoCarenciaDias: Validate.require(
            v.periodoCarenciaDias,
            'periodoCarenciaDias',
          ),
        })),
      });

      if (result) {
        toastSuccess('Planos alterados com sucesso');

        appMainScrollToTop();
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toConvenioDataInput({
  convenio: { nome, registroAns, periodoCarenciaDias },
  planos,
  profissionais,
}: IConvenioFormModel): ConvenioDataInput {
  const profissionaisPlanos: IConvenioProfissionalPlano[] = flatten(
    profissionais.map(profissional =>
      (profissional.planos || []).map(plano => ({
        profissionalId: Validate.require(
          profissional.profissionalId,
          'profissionalId',
        ),
        planoId: Validate.require(plano.planoId, 'planoId'),
        periodoCarenciaDias: Validate.require(
          plano.periodoCarenciaDias,
          'periodoCarenciaDias',
        ),
      })),
    ),
  );

  return {
    nome: Validate.require(nome, 'nome'),
    registroAns: Validate.require(registroAns, 'registroAns'),
    periodoCarenciaDias: Validate.require(
      periodoCarenciaDias,
      'periodoCarenciaDias',
    ),
    planos: planos.map(v => ({
      id: v.new ? null : v.id,
      nome: Validate.require(v.nome, 'nome'),
      profissionais: profissionaisPlanos
        .filter(f => f.planoId === v.id)
        .map(p => ({
          profissionalId: p.profissionalId,
          periodoCarenciaDias: p.periodoCarenciaDias,
        })),
    })),
    profissionais: profissionais.map(v => ({
      profissionalId: Validate.require(v.profissionalId, 'profissionalId'),
      codigoOperadora: Validate.require(v.codigoOperadora, 'codigoOperadora'),
    })),
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configClinica.convenios.index);

    toastSuccess(`Convênio ${msg} com sucesso`);
  }
}
