import { useState } from '@/lib/composables';
import { useBreakpoints } from '@/lib/composables/utils/useBreakpoints';
import { createComponent } from '@/lib/vue';
import { computed, ComputedRef, SetupContext } from '@vue/composition-api';
import { FormHeader } from '../typography/FormHeader';
import { ExpandTransition } from '../utils/ExpandTransition';

interface IProps {
  divider?: boolean;
  hide?: boolean;
  title?: string;
  text?: string;
  titleMinWidth?: number;
}

export const PageSection = createComponent<IProps>({
  name: 'PageSection',
  props: {
    divider: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    title: String,
    text: String,
    titleMinWidth: { type: Number },
  },
  setup(props: IProps, ctx) {
    const { $sm } = useCalcBreakpoints(props, ctx);

    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div class="flex">
          <ExpandTransition hide={props.hide}>
            {props.divider && <v-divider />}

            <div class="flex flex-col p-4">
              {defaultSlot || (
                <div class="flex flex-wrap">
                  {titleColumn({ props, ctx, $sm })}

                  {fieldsColumn({ ctx, $sm })}
                </div>
              )}
            </div>
          </ExpandTransition>
        </div>
      );
    };
  },
});

function useCalcBreakpoints(props: IProps, ctx: SetupContext) {
  const $sidebarOpen = useState(s => !s.layout.contextSidebar.mini);

  const $sm = computed(() => {
    const width = useBreakpoints().$width.value;

    const calcBreakpoint = (breakpoint: number) =>
      // sidebarOpen +300 (width of sidebar)
      $sidebarOpen.value ? breakpoint + 300 : breakpoint;

    if (props.titleMinWidth) {
      return width - props.titleMinWidth > calcBreakpoint(620);
    }

    return width >= calcBreakpoint(800);
  });

  return { $sm };
}

const titleColumn = ({
  props,
  ctx,
  $sm,
}: {
  props: IProps;
  ctx: SetupContext;
  $sm: ComputedRef<boolean>;
}) => {
  const titleSlot = ctx.slots.title?.();

  const mWidth = props.titleMinWidth;

  return (
    <div
      style={{ minWidth: mWidth ? `${mWidth}px` : null }}
      class={['w-full pr-4', { 'sm:w-1/3': $sm.value }]}
    >
      {titleSlot || (
        <FormHeader title={props.title} text={props.text} class="pb-4" />
      )}
    </div>
  );
};

const fieldsColumn = ({
  ctx,
  $sm,
}: {
  ctx: SetupContext;
  $sm: ComputedRef<boolean>;
}) => {
  const fieldsSlot = ctx.slots.fields?.();

  return <div class={['w-full', { 'sm:w-2/3': $sm.value }]}>{fieldsSlot}</div>;
};
