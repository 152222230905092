import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';

export const SearchRow = createComponent({
  name: 'SearchRow',
  props: {
    icon: { type: String, default: MyIcons.search },
    value: String,
  },
  setup(props, ctx) {
    function handleInput(value) {
      ctx.emit('input', value);
    }
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div class="flex items-center">
          {defaultSlot}

          <v-spacer />

          <v-text-field
            placeholder="Pesquisar..."
            hide-details
            clearable
            append-icon={props.icon}
            value={props.value}
            onInput={handleInput}
            class="max-w-sm p-0 m-1"
          />
        </div>
      );
    };
  },
});
