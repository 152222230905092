import { useValueMap } from '@/lib/composables';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { useMenu } from '@/lib/composables/inputs/useMenu';
import { maskValue, removeMask } from '@/lib/form/mask';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, watchRun } from '@/lib/vue';
import { IFormInputDate } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IProps extends IInputProps {
  // model
  value?: string | null;
  input: IFormInputDate;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputDate = createComponent<IProps, IEvents>({
  name: 'InputDate',
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const { $pos, $menu, setMenu, handleOpenMenu } = useMenu();

    const { $formatted, $isoDate, emitInput } = useInputData(props, ctx);

    const events = {
      on: {
        'click:prepend-inner': handleOpenMenu,
        input: emitInput,
      },
    };

    return () => (
      <div id="InputDate">
        <v-text-field
          name={props.name}
          outlined
          clearable
          label={$label.value}
          value={$formatted.value}
          errorMessages={props.errorMessages}
          required={$isRequired.value}
          prependInnerIcon={props.input.prependIcon || MyIcons.date}
          appendIcon={props.input.appendIcon}
          disabled={props.input.disabled}
          hint={props.input.hint}
          persistentHint={props.input.persistentHint}
          hideDetails={!!props.input.hideDetails}
          maxlength={10} // "dd/mm/yyyy" => 10 characters
          {...events}
        />

        <InputDateMenu
          value={$isoDate.value}
          posX={$pos.value.x}
          posY={$pos.value.y}
          show={$menu.value}
          input={props.input}
          onMenuChange={setMenu}
          onInput={emitInput}
        />
      </div>
    );
  },
});

interface IPropsDateMenu {
  value?: string | null;
  posX: number;
  posY: number;
  show: boolean;
  input: IFormInputDate;
}

interface IEventsDateMenu {
  onMenuChange: (show: boolean) => any;
  onInput: (value: string | null) => void;
}

const InputDateMenu = createComponent<IPropsDateMenu, IEventsDateMenu>({
  name: 'InputDateMenu',
  props: {
    value: { type: String },
    posX: { type: Number, required: true },
    posY: { type: Number, required: true },
    show: { type: Boolean, default: false },
    input: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $maxDate = computed(() =>
      DateHelpers.toISODate(props.input.validations?.maxDate),
    );
    const $minDate = computed(() =>
      DateHelpers.toISODate(props.input.validations?.minDate),
    );

    function emitMenuChange(show: boolean) {
      ctx.emit('menuChange', show);
    }

    function emitInput(value: string | null) {
      ctx.emit('input', value);

      emitMenuChange(false);
    }

    return () => (
      <v-menu
        fullWidth
        closeOnContentClick={false}
        transition="scale-transition"
        maxWidth="290px"
        minWidth="290px"
        openOnClick={!props.input.disabled}
        absolute
        positionX={props.posX}
        positionY={props.posY}
        value={props.show}
        onInput={emitMenuChange}
      >
        <v-date-picker
          locale="pt-br"
          value={props.value}
          min={$minDate.value}
          max={$maxDate.value}
          noTitle={props.input.noTitle}
          onInput={emitInput}
        />
      </v-menu>
    );
  },
});

function useInputData(props: IProps, ctx: SetupContext) {
  const [$raw, setRaw] = useValueMap<string | null | undefined>(
    props.value,
    v => {
      // incompleto (digitado)
      if (!v || v.length < 10) return v;

      // yyyy-mm-dd => dd/mm/yyyy
      if (v.includes('-')) {
        return removeMask(DateHelpers.formatDate(v));
      }

      // dd/mm/yyyy
      return v;
    },
  );

  watchRun(() => props.value, setRaw);

  const $formatted = computed(() => maskValue($raw.value, '##/##/####'));

  const $isoDate = computed(() =>
    DateHelpers.fromFormat($formatted.value, 'dd/MM/yyyy')?.toISODate(),
  );

  function emitInput(value: string | null) {
    setRaw(value);

    // $isoDate => valid date
    // $raw => invalid date
    ctx.emit('input', $isoDate.value || $raw.value);
  }

  return { $formatted, $isoDate, emitInput };
}
