import { useValue } from '..';

export function useMenu(elementId?: string) {
  const [$pos, setPos] = useValue({ x: 0, y: 0 });

  const [$menu, setMenu] = useValue(false);

  function handleOpenMenu(e: MouseEvent) {
    setPosition(e);

    setMenu(true);
  }

  function setPosition(e: MouseEvent) {
    const el = document.getElementById(elementId || '');

    if (!elementId || !el) {
      return setPos({ x: e.pageX, y: e.pageY });
    }

    const rect = el.getBoundingClientRect();

    const nudge = 16;

    return setPos({
      x: rect.x + nudge,
      y: rect.y + nudge,
    });
  }

  return { $pos, $menu, setMenu, handleOpenMenu };
}
