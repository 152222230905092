import { ICategoriaFinanceiraFragment, TransacaoTipo } from '@/typings';
import { FluxoCaixaState } from '.';

export const FluxoCaixaCategoriasState = {
  setLoading(loading: boolean) {
    FluxoCaixaState.commit(s => {
      s.categorias.loading = loading;
    });
  },

  setData(c: ICategoriaFinanceiraFragment[]) {
    FluxoCaixaState.commit(s => {
      s.categorias.despesas = c.filter(f => f.tipo === TransacaoTipo.DESPESA);

      s.categorias.receitas = c.filter(f => f.tipo === TransacaoTipo.RECEITA);

      s.categorias.loading = false;
    });
  },
};
