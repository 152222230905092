import {
  SetupContext,
  watch,
  WatchCallback,
  WatchSource,
} from '@vue/composition-api';
import Vue, { ComponentOptions } from 'vue';
import { NavigationGuard } from 'vue-router';
import { modifiers } from 'vue-tsx-support';
import { component } from 'vue-tsx-support/lib/vca';
import { RecordPropsDefinition } from 'vue/types/options';

export { Vue, modifiers };

type IRenderFunction = () =>
  | VueTsxSupport.JSX.Element
  | null
  | undefined
  | false;

export function createComponent<
  TProps,
  TEvents = {},
  TScopedSlots = {},
  TOn = {},
>(options: {
  name: ComponentOptions<Vue>['name'];
  props?: RecordPropsDefinition<TProps>;
  components?: ComponentOptions<Vue>['components'];
  setup: (
    this: void,
    props: TProps,
    ctx: SetupContext,
  ) => object | null | IRenderFunction;
  model?: ComponentOptions<Vue>['model'];
  beforeRouteUpdate?: NavigationGuard;
}) {
  return component<
    TProps,
    RecordPropsDefinition<TProps>,
    TEvents,
    TScopedSlots,
    TOn
  >(options as any);
}

export function watchRun<T>(
  source: WatchSource<T>,
  cb: WatchCallback<T, T | undefined>,
  options?: { deep?: boolean },
) {
  return watch(source, cb, { ...options, immediate: true });
}

export function watchLazy<T>(
  source: WatchSource<T>,
  cb: WatchCallback<T, T>,
  options?: { deep?: boolean },
) {
  return watch(source, cb, { ...options, immediate: false });
}
