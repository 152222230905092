import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

interface IProps {}

interface IEvents {
  onInput: (files: File[]) => void;
}

export const FilesDropzone = createComponent<IProps, IEvents>({
  name: 'FilesDropzone',
  setup(props, ctx) {
    function emitInput(files: File[]) {
      ctx.emit('input', files);
    }

    async function handleChange(e: Event) {
      const inputEl = e.target as HTMLInputElement;

      emitInput(Array.from(inputEl.files || []));
    }

    return () => (
      <div
        class="relative flex items-center text-center"
        style={{
          backgroundColor: MyTheme.coolGray100,
          border: `2px dashed ${MyTheme.coolGray300}`,
          borderRadius: '8px',
          height: '180px',
        }}
      >
        <div class="flex flex-col">
          <div class="text-title text-coolGray-700">
            Arraste arquivos para cá
          </div>

          <div class="mb-2 font-medium text-coolGray-600">ou</div>

          <v-btn outlined color="secondary" class="mb-2">
            <v-icon left>{MyIcons.folderOpen}</v-icon>
            Selecionar arquivos
          </v-btn>

          <div class="text-small text-coolGray-500">
            Cada arquivo deve ter no máximo 10MB
          </div>
        </div>

        <input
          onChange={handleChange}
          type="file"
          multiple
          class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          title=""
        />
      </div>
    );
  },
});
