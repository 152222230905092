import { MyDraggable } from '@/components/data/MyDraggable';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { IDataTableHeader } from '@/typings';
import { ComputedRef, Ref } from '@vue/composition-api';
import { Td } from '../columns/Td';
import { TdActions } from '../columns/TdActions';
import { IDataTableProps, IDataTableUseEventsReturn } from '../DataTable';

export function getDataTableSlots({
  props,
  $headers,
  $showEdit,
  $showRemove,
  handleEdit,
  handleRemove,
  emitReorder,
  checkIsSelected,
  selectItem,
}: {
  props: IDataTableProps;
  $headers: ComputedRef<Array<IDataTableHeader<any>>>;
  $showEdit: Ref<boolean>;
  $showRemove: Ref<boolean>;
} & Pick<
  IDataTableUseEventsReturn,
  | 'handleEdit'
  | 'handleRemove'
  | 'emitReorder'
  | 'checkIsSelected'
  | 'selectItem'
>) {
  return props.draggable
    ? getDraggableSlot({
        $showEdit,
        $showRemove,
        handleEdit,
        handleRemove,
        emitReorder,
        checkIsSelected,
        selectItem,
      })
    : getHeadersSlots({
        $headers,
        $showEdit,
        $showRemove,
        handleEdit,
        handleRemove,
      });
}

function getDraggableSlot({
  $showEdit,
  $showRemove,
  handleEdit,
  handleRemove,
  emitReorder,
  checkIsSelected,
  selectItem,
}: {
  $showEdit: Ref<boolean>;
  $showRemove: Ref<boolean>;
} & Pick<
  IDataTableUseEventsReturn,
  | 'handleEdit'
  | 'handleRemove'
  | 'emitReorder'
  | 'checkIsSelected'
  | 'selectItem'
>) {
  return {
    body: ({
      items,
      headers,
    }: {
      items: any[];
      headers: Array<IDataTableHeader<any>>;
    }) => {
      return (
        <MyDraggable value={items} onInput={emitReorder} tag="tbody">
          {items.map(item => {
            if (!item) {
              return null;
            }

            const isSelected = checkIsSelected(item);

            return (
              <tr
                key={item.id}
                class={{ 'v-data-table__selected': isSelected }}
              >
                {headers
                  .map(header => {
                    if (header.value === 'data-table-select') {
                      return (
                        <v-checkbox
                          hide-details
                          color="#0000008a"
                          value={isSelected}
                          onChange={() => selectItem(item)}
                        />
                      );
                    } else if (header.value === 'actions') {
                      return (
                        <TdActions
                          showEdit={$showEdit.value}
                          showRemove={$showRemove.value}
                          item={item}
                          onEdit={handleEdit}
                          onRemove={handleRemove}
                        />
                      );
                    } else if (header.value === 'draggable') {
                      return (
                        <v-icon x-large class="drag-icon">
                          {MyIcons.drag}
                        </v-icon>
                      );
                    }

                    if (header.slot) {
                      return header.slot({ header, item });
                    }

                    return <Td header={header} item={item} />;
                  })
                  .map(v => (
                    <td>{v}</td>
                  ))}
              </tr>
            );
          })}
        </MyDraggable>
      );
    },
  };
}

function getHeadersSlots({
  $headers,
  $showEdit,
  $showRemove,
  handleEdit,
  handleRemove,
}: {
  $headers: ComputedRef<Array<IDataTableHeader<any>>>;
  $showEdit: Ref<boolean>;
  $showRemove: Ref<boolean>;
} & Pick<IDataTableUseEventsReturn, 'handleEdit' | 'handleRemove'>) {
  return $headers.value
    .map(header => {
      const columnName = `item.${header.value}`;

      if (header.value === 'actions') {
        return {
          columnName,
          slot: ({ item }) => (
            <TdActions
              showEdit={$showEdit.value}
              showRemove={$showRemove.value}
              item={item}
              onEdit={handleEdit}
              onRemove={handleRemove}
            />
          ),
        };
      }

      if (header.slot) {
        return {
          columnName,
          slot: header.slot,
        };
      }

      return {
        columnName,
        slot: ({ header, item }) => <Td header={header} item={item} />,
      };
    })
    .reduce(
      (obj, { columnName, slot }) => ({ ...obj, [columnName]: slot }),
      {},
    );
}
