import { FormFields } from '@/components/form/fields/FormFields';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IForm, IFormSchema } from '@/typings';
import isUndefined from 'lodash/isUndefined';

interface IObservacaoModel {
  observacao: string | null;
  hideObs?: boolean | undefined;
}

interface IProps {
  value: IObservacaoModel;
  form?: IForm<any>;
}

interface IEvents {
  onInput: (value: any) => void;
  onToggle: () => void;
}

export const ObservacaoField = createComponent<IProps, IEvents>({
  name: 'ObservacaoField',
  props: {
    value: { type: Object, required: true },
    form: { type: Object, required: true },
  },
  setup(props, ctx) {
    const schema: IFormSchema<IObservacaoModel> = {
      observacao: {
        label: 'Observação',
        type: 'textarea',
        hideDetails: true,
      },
    };

    const emitInput = value => ctx.emit('input', value);
    const emitToggle = () => ctx.emit('toggle');

    return () => {
      const isHidden =
        !props.value.observacao &&
        (props.value.hideObs || isUndefined(props.value.hideObs));

      return (
        <div class="flex flex-col mb-4">
          <v-btn
            text
            x-small
            color="accent"
            class="self-start px-0 ml-2"
            disabled={!!props.value.observacao}
            onClick={emitToggle}
          >
            Observação
            <v-icon small class="ml-1">
              {isHidden ? MyIcons.chevronDown : MyIcons.chevronUp}
            </v-icon>
          </v-btn>

          <FormFields
            form={props.form}
            schema={schema}
            value={props.value}
            onInput={emitInput}
            hide={isHidden}
          />
        </div>
      );
    };
  },
});
