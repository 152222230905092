import { InputSelect } from '@/components/form/inputs/InputSelect';
import { useValue } from '@/lib/composables';
import { FileHelpers } from '@/lib/helpers/file.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  onMounted,
  onUnmounted,
  ref,
  Ref,
  SetupContext,
} from '@vue/composition-api';
import { WebCam as VueWebCam } from 'vue-web-cam';

interface IProps {}

interface IEvents {
  onInput(image: File): void;
}

export const MyWebcam = createComponent<IProps, IEvents>({
  name: 'MyWebcam',
  components: {
    'vue-web-cam': VueWebCam,
  },
  setup(props, ctx) {
    const $webcamEl = ref(null);

    const {
      $camera,
      setCamera,
      $devices,
      $error,
      handleCapture,
      handleCamerasLoad,
      handleError,
    } = useEvents({ ctx, $webcamEl });

    return () => {
      if ($error.value) {
        return (
          <div class="p-6 text-center text-error text-title">
            {$error.value}
          </div>
        );
      }

      return (
        <div class="flex flex-col py-2">
          <div class="flex justify-center">
            <vue-web-cam
              ref={$webcamEl}
              deviceId={$camera.value}
              height={250}
              onError={handleError}
              onCameras={handleCamerasLoad}
              onCameraChange={setCamera}
            />
          </div>

          <InputSelect
            input={{
              label: 'Câmera',
              type: 'select',
              items: $devices.value,
              itemValue: 'deviceId',
              hideDetails: true,
              noAttach: true,
            }}
            v-model={$camera.value}
            class="my-2"
          />

          <v-btn block color="primary" onClick={handleCapture}>
            <v-icon left>{MyIcons.camera}</v-icon>
            Tirar foto
          </v-btn>
        </div>
      );
    };
  },
});

interface ICameraDevice {
  deviceId: string;
  label: string;
}

function useEvents({
  ctx,
  $webcamEl,
}: {
  ctx: SetupContext;
  $webcamEl: Ref<any>;
}) {
  const [$camera, setCamera] = useValue<string | null>(null);

  const [$devices, setDevices] = useValue<ICameraDevice[]>([]);

  const [$error, setError] = useValue<string | null>(null);

  onMounted(() => $webcamEl.value?.start());

  onUnmounted(() => $webcamEl.value?.stop());

  function emitInput(image: File) {
    ctx.emit('input', image);
  }

  return {
    $camera,
    setCamera,
    $devices,
    $error,
    handleCapture() {
      const dataURI: string | undefined = $webcamEl.value?.capture();
      if (!dataURI) return;

      const image = FileHelpers.dataURItoFile({ dataURI });

      if (image) {
        emitInput(image);
      }
    },
    handleCamerasLoad(devices: ICameraDevice[]) {
      setDevices(devices);

      if (devices.length) {
        setCamera(devices[0].deviceId);
      }
    },
    handleError(error: Error) {
      if (error.message.includes('device not found')) {
        return setError('Nenhuma Webcam foi encontrada');
      } else if (error.message.includes('permission')) {
        return setError(
          'Não foi possivel acessar a Webcam. Você deve liberar o acesso no navegador.',
        );
      }

      setError(error.message);

      console.log('Webcam error:', error);
    },
  };
}
