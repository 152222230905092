import { TypePolicy } from '@apollo/client';

export const ApolloHelpers = {
  cacheArrayMerge(fieldName: string): TypePolicy {
    return {
      fields: {
        [fieldName]: {
          merge: (existing, incoming) => incoming,
        },
      },
    };
  },
};
