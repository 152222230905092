import { DataTable } from '@/components/datatable/DataTable';
import { QueryGraphql } from '@/graphql/query';
import { useState } from '@/lib/composables';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { TissTussLookupService } from '@/lib/services/tiss/tissTussLookup.service';
import { createComponent } from '@/lib/vue';
import {
  IDataTableHeader,
  ITissProcedimentosQuery,
  ITissProcedimentosQueryVariables,
  ITussLookupSelectedState,
  ITussLookupsQuery,
  ITussLookupsQueryVariables,
  TissProcedimentoTabela,
  TissTabela,
  TussLookupTabela,
} from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {}

interface IEvents {}

export const TussLookupTable = createComponent<IProps, IEvents>({
  name: 'TussLookupTable',
  setup(props, ctx) {
    const headers = useHeaders();

    const { $items, $loading } = useQueries();

    return () => (
      <div id="TussLookupTable">
        <DataTable
          noSearch
          headers={headers}
          items={$items.value}
          loading={$loading.value}
          height="calc(100vh - 340px)"
          clickableRow
          onRowClick={TissTussLookupService.select}
        />
      </div>
    );
  },
});

function useHeaders(): IDataTableHeader<ITussLookupSelectedState>[] {
  return [
    {
      text: 'Código',
      value: 'codigo',
      mapValue: v => v.codigo,
    },
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
  ];
}

function useQueries() {
  const $search = useState(s => s.tiss.tussLookup.search);

  const $isTabelaPropria = useState(s =>
    [TissTabela.TB_00, TissTabela.TB_90, TissTabela.TB_98].includes(
      s.tiss.tussLookup.search.tabela!,
    ),
  );

  const tussLookupQuery = useQueryConfig<
    ITussLookupsQuery,
    ITussLookupSelectedState[],
    ITussLookupsQueryVariables
  >({
    query: QueryGraphql.TussLookupsQuery,
    enabledFn: () => !!$search.value.tabela && !$isTabelaPropria.value,
    variables: () => ({
      where: {
        OR: [
          {
            tabela: $search.value.tabela as TussLookupTabela | null,
            codigo_contains: $search.value.text,
          },
          {
            tabela: $search.value.tabela as TussLookupTabela | null,
            nome_contains: $search.value.text,
          },
        ],
      },
      take: 100,
    }),
    mapData: result =>
      result?.tussLookups.nodes.map(v => ({
        id: v.id,
        tabela: mapTussToTissTabela(v.tabela),
        codigo: v.codigo,
        nome: v.nome,
      })) || [],
  });

  const tissProcedimentoQuery = useQueryConfig<
    ITissProcedimentosQuery,
    ITussLookupSelectedState[],
    ITissProcedimentosQueryVariables
  >({
    query: QueryGraphql.TissProcedimentosQuery,
    enabledFn: () => !!$search.value.tabela && $isTabelaPropria.value,
    variables: () => ({
      where: {
        OR: [
          {
            tabela: $search.value.tabela as TissProcedimentoTabela | null,
            codigo_contains: $search.value.text,
          },
          {
            tabela: $search.value.tabela as TissProcedimentoTabela | null,
            nome_contains: $search.value.text,
          },
        ],
      },
      take: 100,
    }),
    mapData: result =>
      result?.tissProcedimentos.nodes.map(v => ({
        id: v.id,
        tabela: mapProcedimentoToTissTabela(v.tabela),
        codigo: v.codigo,
        nome: v.nome,
      })) || [],
  });

  const $items = computed(() => {
    if (!$search.value.tabela) return [];

    const items = $isTabelaPropria.value
      ? tissProcedimentoQuery.$data.value
      : tussLookupQuery.$data.value;

    return items || [];
  });

  const $loading = computed(
    () =>
      tussLookupQuery.$loading.value || tissProcedimentoQuery.$loading.value,
  );

  return { $items, $loading };
}

function mapTussToTissTabela(t: TussLookupTabela): TissTabela {
  switch (t) {
    case TussLookupTabela.TB_18:
      return TissTabela.TB_18;
    case TussLookupTabela.TB_19:
      return TissTabela.TB_19;
    case TussLookupTabela.TB_20:
      return TissTabela.TB_20;
    case TussLookupTabela.TB_22:
      return TissTabela.TB_22;
  }
}

function mapProcedimentoToTissTabela(t: TissProcedimentoTabela): TissTabela {
  switch (t) {
    case TissProcedimentoTabela.TB_00:
      return TissTabela.TB_00;
    case TissProcedimentoTabela.TB_90:
      return TissTabela.TB_90;
    case TissProcedimentoTabela.TB_98:
      return TissTabela.TB_98;
  }
}
