import { PacienteGraphql } from '@/graphql/paciente/PacienteGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IMergePacientesMutationVariables,
  IPacienteConvenioFormModel,
  IPacienteFormModel,
  IPacienteParenteModel,
  IServiceOnSuccessArgs,
  PacienteConvenioDataInput,
  PacienteDataInput,
  PacienteParenteDataInput,
} from '@/typings';
import { RawLocation } from 'vue-router';
import { DateHelpers } from '../helpers/date.helpers';
import { validateDelete } from '../helpers/services';
import { pluralize } from '../helpers/utils';

export const PacienteService = {
  async getById(id: string) {
    try {
      return await PacienteGraphql.paciente({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async getHomonimos(nome: string) {
    try {
      return await PacienteGraphql.pacientesHomonimos({ nome });
    } catch (error) {
      handleError(error);
    }
  },

  async getStats({
    pacienteId,
    profissionalId,
  }: {
    pacienteId: string;
    profissionalId: string;
  }) {
    try {
      return await PacienteGraphql.pacienteStats({
        pacienteId,
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IPacienteFormModel) {
    try {
      const result = await PacienteGraphql.createPaciente({
        data: toPacienteDataInput(model),
        convenios: model.convenios.map(toPacienteConvenioDataInput),
        parentes: model.parentes.map(toPacienteParenteDataInput),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete(
        {
          text: 'este paciente',
          aviso: 'agendamentos e prontuários',
          requirePassword: true,
        },
        async password => {
          const result = await PacienteGraphql.deletePaciente({ id, password });

          onSuccess({ result, msg: 'excluído' });
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'os pacientes selecionados',
            'o paciente selecionado',
          ),
          aviso: 'agendamentos e prontuários',
          requirePassword: true,
        },
        async password => {
          const result = await PacienteGraphql.deleteManyPacientes({
            ids,
            password,
          });

          if (result) {
            const msg = pluralize(
              ids,
              'Pacientes excluídos',
              'Paciente excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    model,
    agendamentoId,
  }: {
    id: string;
    model: IPacienteFormModel;
    agendamentoId?: string | null;
  }) {
    try {
      const result = await PacienteGraphql.updatePaciente({
        id: Validate.require(id, 'id'),
        data: toPacienteDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterado',
        route: agendamentoId && Routes.app.prontuario(id, { agendamentoId }),
      });
    } catch (error) {
      handleError(error);
    }
  },

  async merge({ keepId, mergeId }: IMergePacientesMutationVariables) {
    try {
      const result = await PacienteGraphql.mergePacientes({
        keepId: Validate.require(keepId, 'keepId'),
        mergeId: Validate.require(mergeId, 'mergeId'),
      });

      return result;
    } catch (error) {
      handleError(error);
    }
  },

  async createParente(pacienteId: string, model: IPacienteParenteModel) {
    try {
      return await PacienteGraphql.createPacienteParente({
        pacienteId: Validate.require(pacienteId, 'pacienteId'),
        data: toPacienteParenteDataInput(model),
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateParente(id: string, model: IPacienteParenteModel) {
    try {
      return await PacienteGraphql.updatePacienteParente({
        id: Validate.require(id, 'id'),
        data: toPacienteParenteDataInput(model),
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteManyParentes(ids: string[], allIds: string[]) {
    try {
      const result = await validateDelete(
        {
          text: pluralize(
            allIds,
            'os parentes selecionados',
            'o parente selecionado',
          ),
        },
        async () => {
          if (!ids.length) {
            return true;
          }

          const data = await PacienteGraphql.deleteManyPacienteParentes({
            ids,
          });

          if (data) {
            const msg = pluralize(
              allIds,
              'Parentes excluídos',
              'Parente excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }

          return true;
        },
      );

      return !!result;
    } catch (error) {
      handleError(error);

      return false;
    }
  },

  async createConvenio(pacienteId: string, model: IPacienteConvenioFormModel) {
    try {
      return await PacienteGraphql.createPacienteConvenio({
        pacienteId: Validate.require(pacienteId, 'pacienteId'),
        data: toPacienteConvenioDataInput(model),
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateConvenio(id: string, model: IPacienteConvenioFormModel) {
    try {
      return await PacienteGraphql.updatePacienteConvenio({
        id: Validate.require(id, 'id'),
        data: toPacienteConvenioDataInput(model),
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteManyConvenios(ids: string[], allIds: string[]) {
    try {
      const result = await validateDelete(
        {
          text: pluralize(
            allIds,
            'os convênios selecionados do paciente',
            'o convênio selecionado do paciente',
          ),
        },
        async () => {
          if (!ids.length) {
            return true;
          }

          const data = await PacienteGraphql.deleteManyPacienteConvenios({
            ids,
          });

          if (data) {
            const msg = pluralize(
              allIds,
              'Convênios do paciente excluídos',
              'Convênio do paciente excluído',
            );

            toastSuccess(`${msg} com sucesso`);
          }

          return true;
        },
      );

      return !!result;
    } catch (error) {
      handleError(error);

      return false;
    }
  },
};

function toPacienteDataInput({
  geral: {
    nome,
    email,
    codigo,
    comoConheceu,
    dataNascimento,
    sexo,
    cpf,
    rg,
    observacao,
  },
  telefones: { telefoneCasa, celular, aceitaSms, telefoneTrabalho },
  endereco: {
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
    enderecoPais,
  },
  dadosComplementares: {
    naturalidade,
    naturalidadeUf,
    nacionalidade,
    etnia,
    religiao,
    estadoCivil,
    escolaridade,
    profissao,
    obito,
    causaMortis,
  },
  outrasInformacoes: { cns, informacoesAdicionais },
  imagem: { imagemId },
}: IPacienteFormModel): PacienteDataInput {
  Validate.require(celular || telefoneCasa, 'celular or telefoneCasa');

  return {
    nome: Validate.require(nome, 'nome'),
    email,
    dataNascimento: Validate.require(dataNascimento, 'dataNascimento'),
    sexo: Validate.require(sexo, 'sexo'),
    codigo,
    comoConheceu,
    imagemId,
    observacao,
    celular,
    telefoneCasa,
    telefoneTrabalho,
    aceitaSms,
    enderecoCep,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoUf,
    enderecoPais,
    etnia,
    causaMortis,
    religiao,
    rg,
    cpf,
    escolaridade,
    estadoCivil,
    nacionalidade,
    naturalidade,
    naturalidadeUf,
    obito,
    profissao,
    cns,
    informacoesAdicionais,
  };
}

function toPacienteParenteDataInput({
  nome,
  parentesco,
  profissao,
}: IPacienteParenteModel): PacienteParenteDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    parentesco: Validate.require(parentesco, 'parentesco'),
    profissao,
  };
}

function toPacienteConvenioDataInput({
  convenio: { acomodacao, convenioId, planoId },
  carteirinha: { numeroCarteirinha, vencimento, semVencimento },
}: IPacienteConvenioFormModel): PacienteConvenioDataInput {
  if (!semVencimento) {
    Validate.require(vencimento, 'vencimento');
  }

  return {
    convenioId: Validate.require(convenioId, 'convenioId'),
    planoId,
    acomodacao,
    numeroCarteirinha: Validate.require(numeroCarteirinha, 'numeroCarteirinha'),
    vencimento: DateHelpers.toISODate(vencimento),
    semVencimento,
  };
}

function onSuccess<T>({
  result,
  msg,
  route,
}: IServiceOnSuccessArgs<T> & { route?: RawLocation | null }) {
  if (result) {
    router.push(route || Routes.app.pacientes.index);

    toastSuccess(`Paciente ${msg} com sucesso`);
  }
}
