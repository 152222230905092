import { validationSummary } from '@/lib/form';
import { appMainScrollToTop } from '@/lib/helpers/scroll';
import { watchRun } from '@/lib/vue';
import { IForm, IFormPage, IFormValidationErrors, IModel } from '@/typings';
import {
  computed,
  onMounted,
  Ref,
  ref,
  SetupContext,
} from '@vue/composition-api';
import cloneDeep from 'lodash/cloneDeep';

export interface IFormProps {
  page: IFormPage;
}

export interface IFormEvents<TValue extends IModel> {
  onSubmit: (model: TValue) => void;
  onCancel: () => void;
  onDelete: (id: string, model?: IModel) => void;
}

interface IUseFormArgs<TValue extends IModel, TSchema> {
  page?: IFormPage;
  initialValue: TValue;
  mapSchema: (model: TValue, $form: Ref<IForm<TValue>>) => TSchema;
  ctx: SetupContext;
  mapCustomErrors?: (value: TValue) => IFormValidationErrors[];
  loadEditCallback?: (args: {
    id: string;
    setFormModel: (model: TValue) => void;
  }) => any;
  mapSchemaToValidate?: ($schema: Ref<TSchema>) => any;
  mapValueToValidate?: (value: TValue) => any;
  scrollOnValidation?: boolean;
  validationErrorCallback?: () => any;
  noId?: boolean;
}

export function useFormConfig<TModel extends IModel, TSchema>({
  page = {
    id: null,
    submitting: false,
    error: null,
  },
  initialValue,
  mapSchema,
  ctx,
  mapCustomErrors = () => [],
  loadEditCallback,
  mapSchemaToValidate = $s => $s.value,
  mapValueToValidate = value => value,
  scrollOnValidation = true,
  validationErrorCallback,
  noId,
}: IUseFormArgs<TModel, TSchema>) {
  const $form = ref<IForm<TModel>>({
    model: initialValue,
    submitted: false,
    loading: false,
    validationErrors: [],
    page,
  }) as Ref<IForm<TModel>>;

  const $schema = computed<TSchema>(() => mapSchema($form.value.model, $form));

  onMounted(async () => {
    if (($form.value.page.id || noId) && loadEditCallback) {
      $form.value.loading = true;

      await loadEditCallback({
        id: $form.value.page.id!,
        setFormModel,
      });

      $form.value.loading = false;
    }
  });

  const $customErrors = computed(() => mapCustomErrors($form.value.model));
  const $valueToValidate = computed(() =>
    mapValueToValidate($form.value.model),
  );

  watchRun($customErrors, valid);
  watchRun($valueToValidate, valid, { deep: true });

  function valid() {
    if (!$schema || !$customErrors) {
      return true;
    }

    $form.value.validationErrors = validationSummary({
      schema: mapSchemaToValidate($schema),
      model: $valueToValidate.value,
      form: $form.value,
      customErrors: $customErrors.value,
    });

    return $form.value.validationErrors.length === 0;
  }

  async function emitSubmit() {
    $form.value.submitted = true;

    if (valid()) {
      ctx.emit('submit', $form.value.model);
    } else {
      validationErrorCallback?.();

      if (scrollOnValidation) {
        appMainScrollToTop();
      }
    }
  }

  function emitCancel() {
    ctx.emit('cancel');
  }

  function emitDelete() {
    if ($form.value.page.id) {
      ctx.emit('delete', $form.value.page.id, $form.value);
    }
  }

  function setFormModel(model: TModel) {
    $form.value.model = cloneDeep(model);
  }

  return {
    $form,
    $schema,
    isEdit: !!$form.value.page.id,
    valid,
    emitSubmit,
    emitCancel,
    emitDelete,
    setFormModel,
  };
}

export interface IDialogFormProps<TValue extends IModel> extends IFormProps {
  initialValue: TValue;
}

export function useDialogFormConfig<TValue extends IModel, TSchema>(
  args: IUseFormArgs<TValue, TSchema>,
) {
  return useFormConfig<TValue, TSchema>({
    ...args,
    scrollOnValidation: false,
  });
}
