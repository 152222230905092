// enable for production
// import '@babel/polyfill';

import Vue from 'vue';

import { MainLayout } from '@/layouts/MainLayout';

import { apolloClient } from '@/plugins/apollo';
import '@/plugins/composition-api';
import '@/plugins/snotify';
import { vuetify } from '@/plugins/vuetify';
import { store } from '@/store';
import { router } from './routes';

import '@/lib/registerServiceWorker';

import 'balloon-css/balloon.min.css';
import './styles/main.scss';

import { DefaultApolloClient } from '@vue/apollo-composable';
import { provide } from '@vue/composition-api';

Vue.config.productionTip = false;

export const vm = new Vue({
  router,
  store,
  vuetify,
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: h => h(MainLayout),
} as any).$mount('#root');
