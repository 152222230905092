import { ProcedimentoGraphql } from '@/graphql/profissional/procedimento/ProcedimentoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import {
  IProcedimentoFormModel,
  IServiceOnSuccessProfissionalArgs,
  ProcedimentoDataInput,
} from '@/typings';
import { ConfiguracaoAgendaService } from '../configuracao/configuracaoAgenda.service';

export const ProcedimentoService = {
  async getById(id: string) {
    try {
      return await ProcedimentoGraphql.procedimento({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    profissionalId: string;
    model: IProcedimentoFormModel;
  }) {
    try {
      const result = await ProcedimentoGraphql.createProcedimento({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toProcedimentoDataInput(model),
      });

      onSuccess({
        result,
        msg: 'criado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async delete({ id, profissionalId }: { id: string; profissionalId: string }) {
    try {
      const text = 'este procedimento';

      const configuracao = await ConfiguracaoAgendaService.get(profissionalId);
      if (configuracao && configuracao.procedimentoPadrao.id === id) {
        return FlashMessagesState.alertWarning({
          text: `Não foi possível excluir ${text}. "${configuracao.procedimentoPadrao.nome}" é o procedimento padrão na Configuração da agenda.`,
        });
      }

      await validateDelete({ text }, async () => {
        const result = await ProcedimentoGraphql.deleteProcedimento({ id });

        onSuccess({
          result,
          msg: 'excluído',
          profissionalId,
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany({
    ids,
    profissionalId,
  }: {
    ids: string[];
    profissionalId: string;
  }) {
    try {
      const text = pluralize(
        ids,
        'os procedimentos selecionados',
        'o procedimento selecionado',
      );

      const configuracao = await ConfiguracaoAgendaService.get(profissionalId);
      if (configuracao && ids.includes(configuracao.procedimentoPadrao.id)) {
        return FlashMessagesState.alertWarning({
          text: `Não foi possível excluir ${text}. "${configuracao.procedimentoPadrao.nome}" é o procedimento padrão na Configuração da agenda.`,
        });
      }

      await validateDelete({ text }, async () => {
        const result = await ProcedimentoGraphql.deleteManyProcedimentos({
          ids,
        });

        if (result) {
          const msg = pluralize(
            ids,
            'Procedimentos excluídos',
            'Procedimento excluído',
          );

          toastSuccess(`${msg} com sucesso`);
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    profissionalId,
    model,
  }: {
    id: string;
    profissionalId: string;
    model: IProcedimentoFormModel;
  }) {
    try {
      const result = await ProcedimentoGraphql.updateProcedimento({
        id: Validate.require(id, 'id'),
        data: toProcedimentoDataInput(model),
      });

      onSuccess({
        result,
        msg: 'alterado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },
};

function toProcedimentoDataInput({
  procedimento: { nome, cor, duracaoMinutos, tipo, valor },
  preparo: { preparo },
  convenios,
  despesas,
}: IProcedimentoFormModel): ProcedimentoDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    cor: Validate.require(cor, 'cor'),
    duracaoMinutos: Validate.require(duracaoMinutos, 'duracaoMinutos'),
    tipo: Validate.require(tipo, 'tipo'),
    preparo,
    valor,
    convenios: convenios.map(v => ({
      convenioId: Validate.require(v.convenio.convenioId, 'convenioId'),
      valor: Validate.require(v.convenio.valor, 'valor'),
      tissCodigo: v.tiss.codigo,
      tissNome: v.tiss.nome,
      tissTabela: v.tiss.tabela,
    })),
    despesas: despesas.map(v => ({
      id: v.id,
      valorUnitario: Validate.require(v.despesa.valorUnitario, 'valorUnitario'),
      quantidade: Validate.require(v.despesa.quantidade, 'quantidade'),
      tipo: Validate.require(v.despesa.tipo, 'tipo'),
      unidade: Validate.require(v.despesa.unidade, 'unidade'),
      tissCodigo: Validate.require(v.tiss.codigo, 'tissCodigo'),
      tissNome: Validate.require(v.tiss.nome, 'tissNome'),
      tissTabela: Validate.require(v.tiss.tabela, 'tissTabela'),
    })),
  };
}

function onSuccess<T>({
  result,
  msg,
  profissionalId,
}: IServiceOnSuccessProfissionalArgs<T>) {
  if (result) {
    router.push(
      Routes.app.configProfissionais(profissionalId).procedimentos.index,
    );

    toastSuccess(`Procedimento ${msg} com sucesso`);
  }
}
