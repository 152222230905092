import { createComponent } from '@/lib/vue';

interface IProps {
  loading: boolean;
  size?: number;
  noPadding?: boolean;
  hideOnLeave?: boolean;
}

export const CircularLoading = createComponent<IProps>({
  name: 'CircularLoading',
  props: {
    loading: { type: Boolean, required: true },
    size: { type: Number, default: 100 },
    noPadding: { type: Boolean, default: false },
    hideOnLeave: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-fade-transition mode="out-in" hide-on-leave={props.hideOnLeave}>
          {props.loading ? (
            <div class={['flex justify-center', !props.noPadding && 'py-4']}>
              <v-progress-circular
                indeterminate
                size={props.size}
                color="primary"
              />
            </div>
          ) : (
            defaultSlot
          )}
        </v-fade-transition>
      );
    };
  },
});
