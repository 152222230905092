import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';

interface IProps {
  icon?: string;
  add?: boolean;
  remove?: boolean;
  tooltip: string;
}

interface IEvents {
  onClick: () => void;
}

export const BtnSmall = createComponent<IProps, IEvents>({
  name: 'BtnSmall',
  props: {
    icon: { type: String },
    add: { type: Boolean, default: false },
    remove: { type: Boolean, default: false },
    tooltip: { type: String, required: true },
  },
  setup(props, ctx) {
    const $icon = computed(() => {
      if (props.add) {
        return MyIcons.smallAdd;
      } else if (props.remove) {
        return MyIcons.smallRemove;
      } else if (props.icon) {
        return props.icon;
      }

      return MyIcons.smallAdd;
    });

    return () => (
      <v-btn
        icon
        small
        text
        aria-label={props.tooltip}
        data-balloon-pos="down"
        class="mx-1"
        style={{ width: '32px', height: '32px' }}
        onClick={() => ctx.emit('click')}
      >
        <v-icon color="accent">{$icon.value}</v-icon>
      </v-btn>
    );
  },
});
