import { useState } from '@/lib/composables';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { ProntuarioAtestadosForm } from '../../components/forms/ProntuarioAtestadosForm';
import { DashedRow } from '../../components/utils/DashedRow';

export default createComponent({
  name: 'ProntuarioAtestadosPage',
  setup(props, ctx) {
    const $atestados = useState(s => s.prontuario.forms.atestados.model);

    return () => (
      <div class="flex flex-col">
        {$atestados.value.map(v => (
          <ProntuarioAtestadosForm
            key={v.id}
            value={v}
            class="mb-6"
            onSubmit={() => ProntuarioService.cache.save()}
          />
        ))}

        <DashedRow>
          <v-btn
            outlined
            onClick={ProntuarioState.model.atestados.add}
            color={MyTheme.coolGray700}
          >
            Adicionar Atestado
          </v-btn>
        </DashedRow>
      </div>
    );
  },
});
