import { createComponent } from '@/lib/vue';
import { TussLookupForm } from './TussLookupForm';
import { TussLookupTable } from './TussLookupTable';

// TODO: Test Form
// TODO: Select envent (on table row click)
export const TussLookupDialog = createComponent({
  name: 'TussLookupDialog',
  setup(props, ctx) {
    return () => (
      <div id="TussLookupDialog" class="p-4">
        <TussLookupForm />

        <TussLookupTable />
      </div>
    );
  },
});
