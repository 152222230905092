import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment, ITimelineObservacaoFormModel } from '@/typings';
import { TimelineObservacaoForm } from './TimelineObservacaoForm';
import { TimelineObservacoesList } from './TimelineObservacoesList';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineObservacoes = createComponent<IProps>({
  name: 'TimelineObservacoes',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const [$hideForm, setHideForm] = useValue(true);

    const { showForm, hideForm, handleSalvar } = useEvents({
      props,
      setHideForm,
    });

    return () => (
      <div class="flex flex-col p-4 bg-coolGray-100">
        <TimelineObservacoesList observacoes={props.atendimento.observacoes} />

        <TimelineObservacaoForm
          hide={$hideForm.value}
          onCancel={hideForm}
          onSubmit={handleSalvar}
        />

        {$hideForm.value && (
          <v-btn color="primary" class="self-start" outlined onClick={showForm}>
            <v-icon left>{MyIcons.note}</v-icon>
            Adicionar observação
          </v-btn>
        )}
      </div>
    );
  },
});

function useEvents({
  props,
  setHideForm,
}: {
  props: IProps;
  setHideForm: (v: boolean) => void;
}) {
  function showForm() {
    setHideForm(false);
  }

  function hideForm() {
    setHideForm(true);
  }

  function handleSalvar({ observacao }: ITimelineObservacaoFormModel) {
    ProntuarioService.atendimento.createObservacao({
      atendimentoId: props.atendimento.id,
      texto: observacao,
    });

    hideForm();
  }

  return { showForm, hideForm, handleSalvar };
}
