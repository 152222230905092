import { permissionDeniedError } from '@/routes/utils';
import {
  userIsAdminAccount,
  userIsAdminClinica,
  userIsOwner,
  getClinica,
} from '@/store/utils/auth';

import { NavigationGuard } from 'vue-router';

export const configuracoesProfissionaisGuard: NavigationGuard = (
  to,
  _from,
  next,
) => {
  const { profissionalId } = to.params;

  const profissionalInClinica = getClinica()
    .profissionais.map(v => v.id)
    .includes(profissionalId);

  // allow profissionalInClinica AND myUser
  if (profissionalInClinica && userIsOwner(profissionalId)) {
    return next();
  }
  // allow profissionalInClinica AND (adminAccount OR adminClinica)
  else if (
    profissionalInClinica &&
    (userIsAdminClinica() || userIsAdminAccount())
  ) {
    return next();
  }

  return permissionDeniedError(to, next);
};
