import { ContaFinanceiraGraphql } from '@/graphql/financas/contaFinanceira/ContaFinanceiraGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import {
  ContaFinanceiraDataInput,
  ContaFinanceiraTipo,
  IContaFinanceiraModel,
  IServiceOnSuccessArgs,
} from '@/typings';
import { ConfiguracaoFinanceiraService } from '../configuracao/configuracaoFinanceira.service';

export const ContaFinanceiraService = {
  async getById(id: string) {
    try {
      return await ContaFinanceiraGraphql.contaFinanceira({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create(model: IContaFinanceiraModel) {
    try {
      const result = await ContaFinanceiraGraphql.createContaFinanceira({
        data: toContaFinanceiraDataInput(model),
      });

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      const text = 'esta conta financeira';

      const configuracao = await ConfiguracaoFinanceiraService.get();
      if (configuracao && configuracao.contaFinanceira.id === id) {
        return FlashMessagesState.alertWarning({
          text: `Não foi possível excluir ${text}. "${configuracao.contaFinanceira.nome}" é a conta financeira padrão nas Configurações financeiras.`,
        });
      }

      await validateDelete({ text }, async () => {
        const result = await ContaFinanceiraGraphql.deleteContaFinanceira({
          id,
        });

        onSuccess({ result, msg: 'excluída' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      const text = pluralize(
        ids,
        'as contas financeiras selecionadas',
        'a conta financeira selecionada',
      );

      const configuracao = await ConfiguracaoFinanceiraService.get();
      if (configuracao && ids.includes(configuracao.contaFinanceira.id)) {
        return FlashMessagesState.alertWarning({
          text: `Não foi possível excluir ${text}. "${configuracao.contaFinanceira.nome}" é a conta financeira padrão nas Configurações financeiras.`,
        });
      }

      await validateDelete({ text }, async () => {
        const result = await ContaFinanceiraGraphql.deleteManyContasFinanceiras(
          { ids },
        );

        if (result) {
          const msg = pluralize(
            ids,
            'Contas financeiras excluídas',
            'Conta financeira excluída',
          );

          toastSuccess(`${msg} com sucesso`);
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: IContaFinanceiraModel) {
    try {
      const result = await ContaFinanceiraGraphql.updateContaFinanceira({
        id: Validate.require(id, 'id'),
        data: toContaFinanceiraDataInput(model),
      });

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function toContaFinanceiraDataInput({
  nome,
  tipo,
  banco,
  saldoInicial,
  dataSaldoInicial,
}: IContaFinanceiraModel): ContaFinanceiraDataInput {
  return {
    nome: Validate.require(nome, 'nome'),
    tipo: Validate.require(tipo, 'tipo'),
    banco:
      tipo !== ContaFinanceiraTipo.CAIXA_INTERNO
        ? Validate.require(banco, 'banco')
        : null,
    saldoInicial: Validate.require(saldoInicial, 'saldoInicial'),
    dataSaldoInicial: Validate.date(dataSaldoInicial, 'dataSaldoInicial'),
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configClinica.financas.contasFinanceiras.index);

    toastSuccess(`Conta financeira ${msg} com sucesso`);
  }
}
