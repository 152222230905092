import AgendamentoQuery from '@/graphql/profissional/agendamento/agendamento.graphql';
import AgendamentoProximoHorarioQuery from '@/graphql/profissional/agendamento/agendamentoProximoHorario.graphql';
import AgendamentosQuery from '@/graphql/profissional/agendamento/agendamentos.graphql';
import ConfirmacaoCancelAgendamentoMutation from '@/graphql/profissional/agendamento/confirmacaoCancelAgendamento.graphql';
import ConfirmacaoCheckAgendamentoMutation from '@/graphql/profissional/agendamento/confirmacaoCheckAgendamento.graphql';
import CreateAgendamentoMutation from '@/graphql/profissional/agendamento/createAgendamento.graphql';
import CreateAgendamentoRecebimentoMutation from '@/graphql/profissional/agendamento/createAgendamentoRecebimento.graphql';
import CreateAgendamentoRecorrenciaMutation from '@/graphql/profissional/agendamento/createAgendamentoRecorrencia.graphql';
import DeleteAgendamentoMutation from '@/graphql/profissional/agendamento/deleteAgendamento.graphql';
import DeleteAgendamentoRecorrenciaMutation from '@/graphql/profissional/agendamento/deleteAgendamentoRecorrencia.graphql';
import UpdateAgendamentoMutation from '@/graphql/profissional/agendamento/updateAgendamento.graphql';
import UpdateAgendamentoRecebimentoMutation from '@/graphql/profissional/agendamento/updateAgendamentoRecebimento.graphql';
import UpdateAgendamentoRecebimentoStatusMutation from '@/graphql/profissional/agendamento/updateAgendamentoRecebimentoStatus.graphql';
import UpdateAgendamentoTimeMutation from '@/graphql/profissional/agendamento/updateAgendamentoTime.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { AgendamentoService } from '@/lib/services';
import { apolloClient } from '@/plugins/apollo';
import {
  IAgendamentoProximoHorarioQuery,
  IAgendamentoProximoHorarioQueryVariables,
  IAgendamentoQuery,
  IAgendamentoQueryVariables,
  IAgendamentosQuery,
  IAgendamentosQueryVariables,
  IConfirmacaoCancelAgendamentoMutation,
  IConfirmacaoCancelAgendamentoMutationVariables,
  IConfirmacaoCheckAgendamentoMutation,
  IConfirmacaoCheckAgendamentoMutationVariables,
  ICreateAgendamentoMutation,
  ICreateAgendamentoMutationVariables,
  ICreateAgendamentoRecebimentoMutation,
  ICreateAgendamentoRecebimentoMutationVariables,
  ICreateAgendamentoRecorrenciaMutation,
  ICreateAgendamentoRecorrenciaMutationVariables,
  IDeleteAgendamentoMutation,
  IDeleteAgendamentoMutationVariables,
  IDeleteAgendamentoRecorrenciaMutation,
  IDeleteAgendamentoRecorrenciaMutationVariables,
  IUpdateAgendamentoMutation,
  IUpdateAgendamentoMutationVariables,
  IUpdateAgendamentoRecebimentoMutation,
  IUpdateAgendamentoRecebimentoMutationVariables,
  IUpdateAgendamentoRecebimentoStatusMutation,
  IUpdateAgendamentoRecebimentoStatusMutationVariables,
  IUpdateAgendamentoTimeMutation,
  IUpdateAgendamentoTimeMutationVariables,
} from '@/typings';

export const AgendamentoGraphql = {
  query: {
    AgendamentoQuery,
    AgendamentoProximoHorarioQuery,
    AgendamentosQuery,
  },

  async agendamento(variables: IAgendamentoQueryVariables) {
    return apolloClient
      .query<IAgendamentoQuery>({
        query: AgendamentoQuery,
        variables,
      })
      .then(({ data }) => data?.agendamento);
  },

  async agendamentoProximoHorario(
    variables: IAgendamentoProximoHorarioQueryVariables,
  ) {
    return apolloClient
      .query<IAgendamentoProximoHorarioQuery>({
        query: AgendamentoProximoHorarioQuery,
        variables,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data?.agendamentoProximoHorario);
  },

  async agendamentos(variables: IAgendamentosQueryVariables, refetch = false) {
    return apolloClient
      .query<IAgendamentosQuery>({
        query: AgendamentosQuery,
        variables,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.agendamentos);
  },

  async confirmacaoCancelAgendamento(
    variables: IConfirmacaoCancelAgendamentoMutationVariables,
  ) {
    return apolloClient
      .mutate<IConfirmacaoCancelAgendamentoMutation>({
        mutation: ConfirmacaoCancelAgendamentoMutation,
        variables,
      })
      .then(({ data }) => data?.confirmacaoCancelAgendamento);
  },

  async confirmacaoCheckAgendamento(
    variables: IConfirmacaoCheckAgendamentoMutationVariables,
  ) {
    return apolloClient
      .mutate<IConfirmacaoCheckAgendamentoMutation>({
        mutation: ConfirmacaoCheckAgendamentoMutation,
        variables,
      })
      .then(({ data }) => data?.confirmacaoCheckAgendamento);
  },

  async createAgendamento(variables: ICreateAgendamentoMutationVariables) {
    const { data } = await apolloClient.mutate<ICreateAgendamentoMutation>({
      mutation: CreateAgendamentoMutation,
      variables,
    });

    refetchQueries(data);

    return data?.createAgendamento;
  },

  async createAgendamentoRecebimento(
    variables: ICreateAgendamentoRecebimentoMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateAgendamentoRecebimentoMutation>({
        mutation: CreateAgendamentoRecebimentoMutation,
        variables,
      })
      .then(({ data }) => data?.createAgendamentoRecebimento);
  },

  async createAgendamentoRecorrencia(
    variables: ICreateAgendamentoRecorrenciaMutationVariables,
  ) {
    const { data } =
      await apolloClient.mutate<ICreateAgendamentoRecorrenciaMutation>({
        mutation: CreateAgendamentoRecorrenciaMutation,
        variables,
      });

    refetchQueries(data);

    return data?.createAgendamentoRecorrencia;
  },

  async deleteAgendamento(variables: IDeleteAgendamentoMutationVariables) {
    const { data } = await apolloClient.mutate<IDeleteAgendamentoMutation>({
      mutation: DeleteAgendamentoMutation,
      variables,
      refetchQueries: getRefetchQueries([AgendamentosQuery]),
    });

    refetchQueries(data);

    return data?.deleteAgendamento;
  },

  async deleteAgendamentoRecorrencia(
    variables: IDeleteAgendamentoRecorrenciaMutationVariables,
  ) {
    const { data } =
      await apolloClient.mutate<IDeleteAgendamentoRecorrenciaMutation>({
        mutation: DeleteAgendamentoRecorrenciaMutation,
        variables,
        refetchQueries: getRefetchQueries([AgendamentosQuery]),
      });

    refetchQueries(data);

    return data?.deleteAgendamentoRecorrencia;
  },

  async updateAgendamento(variables: IUpdateAgendamentoMutationVariables) {
    const { data } = await apolloClient.mutate<IUpdateAgendamentoMutation>({
      mutation: UpdateAgendamentoMutation,
      variables,
    });

    refetchQueries(data);

    return data?.updateAgendamento;
  },

  async updateAgendamentoRecebimento(
    variables: IUpdateAgendamentoRecebimentoMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateAgendamentoRecebimentoMutation>({
        mutation: UpdateAgendamentoRecebimentoMutation,
        variables,
      })
      .then(({ data }) => data?.updateAgendamentoRecebimento);
  },

  async updateAgendamentoRecebimentoStatus(
    variables: IUpdateAgendamentoRecebimentoStatusMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateAgendamentoRecebimentoStatusMutation>({
        mutation: UpdateAgendamentoRecebimentoStatusMutation,
        variables,
      })
      .then(({ data }) => data?.updateAgendamentoRecebimentoStatus);
  },

  async updateAgendamentoTime(
    variables: IUpdateAgendamentoTimeMutationVariables,
  ) {
    const { data } = await apolloClient.mutate<IUpdateAgendamentoTimeMutation>({
      mutation: UpdateAgendamentoTimeMutation,
      variables,
    });

    refetchQueries(data);

    return data?.updateAgendamento;
  },
};

function refetchQueries<T>(data: T) {
  if (data) {
    AgendamentoService.loadAgendamentosDia();
  }
}
