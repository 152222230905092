import {
  AtendimentoCacheDataInput,
  IProntuarioCacheState,
  IProntuarioState,
} from '@/typings';
import { Validate } from './error';

export function mapAtendimentoCacheData(
  state: IProntuarioState,
): AtendimentoCacheDataInput {
  return {
    dataInicial: Validate.dateTime(
      state.atendimento.startAt,
      'atendimento.startAt',
    ),
    pacienteId: Validate.require(state.paciente?.data.id, 'paciente.id'),
    payload: mapAtendimentoCachePayload(state),
  };
}

function mapAtendimentoCachePayload(
  state: IProntuarioState,
): IProntuarioCacheState {
  return {
    atendimento: state.atendimento,
    model: {
      preAtendimento: state.forms.preAtendimento.model,
      exames: state.forms.exames.model,
      prescricoes: state.forms.prescricoes.model,
      atestados: state.forms.atestados.model,
      hipoteseDiagnostica: state.forms.hipoteseDiagnostica.model,
    },
    tabs: state.tabs.reduce((obj, v) => ({ ...obj, [v.id]: v.model }), {}),
  };
}
