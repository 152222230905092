import {
  PacienteConvenio,
  PacienteEmail,
  PacienteFaltasChip,
  PacienteFones,
  PacienteIdade,
} from '@/components/paciente/PacienteComponents';
import { PageSection } from '@/components/page/PageSection';
import { Avatar } from '@/components/utils/Avatar';
import { useState } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent } from '@/lib/vue';
import { IAgendamentoFragment_paciente } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import capitalize from 'lodash/capitalize';

interface IProps {}

interface IEvents {
  onNomeClick: (routePacienteEdit: string) => void;
}

export const AgendamentoViewPaciente = createComponent<IProps, IEvents>({
  name: 'AgendamentoViewPaciente',
  props: {
    value: { type: String },
  },
  setup(props, ctx) {
    const { $paciente, $convenio, $sexo, $ultimoAtendimento, $faltas } =
      useComputeds(ctx);

    const { handleNomeClick } = useEvents(ctx);

    return () => {
      if (!$paciente.value) {
        return null;
      }

      const { imagem, nome } = $paciente.value;

      return (
        <PageSection>
          <div class="flex">
            <Avatar imgSrc={imagem?.thumbnailUrl} nome={nome} class="mr-4" />

            <div class="flex flex-col">
              <div class="flex items-center mb-2">
                {pacienteNomeLink({
                  paciente: $paciente.value,
                  handleNomeClick,
                })}

                <PacienteFaltasChip faltas={$faltas.value} />
              </div>

              <div class="flex">
                {pacienteRows({
                  paciente: $paciente.value,
                  $sexo,
                  $ultimoAtendimento,
                })}

                <PacienteConvenio
                  convenio={$convenio.value}
                  classes="text-gray-700"
                />
              </div>
            </div>
          </div>
        </PageSection>
      );
    };
  },
});

function useComputeds(ctx: SetupContext) {
  const $paciente = useState(s => s.agenda.agendamento?.paciente);

  const $profissionalId = useState(s => s.agenda.profissionalId);

  const $convenio = useState(s => s.agenda.agendamento?.convenio?.nome);

  const $sexo = computed(() => {
    const sexo = $paciente.value?.sexo;

    if (!sexo) return null;

    return capitalize(sexo);
  });

  const $stats = useState(s => s.agenda.pacienteStats);

  const $faltas = computed(() => $stats.value?.faltas || 0);

  const $ultimoAtendimento = computed(() =>
    DateHelpers.humanize($stats.value?.dataUltimoAtendimento),
  );

  return {
    $paciente,
    $profissionalId,
    $convenio,
    $sexo,
    $faltas,
    $ultimoAtendimento,
  };
}

function useEvents(ctx: SetupContext) {
  function handleNomeClick() {
    ctx.emit('nomeClick');
  }

  return { handleNomeClick };
}

const pacienteNomeLink = ({
  paciente: { nome, incompleto },
  handleNomeClick,
}: {
  paciente: IAgendamentoFragment_paciente;
  handleNomeClick: () => void;
}) => {
  const redText = incompleto ? 'text-error' : '';
  const tooltip = incompleto ? 'Cadastro incompleto' : 'Editar paciente';

  return (
    <a
      onClick={handleNomeClick}
      class={['text-title mr-2', redText]}
      aria-label={tooltip}
      data-balloon-pos="down"
    >
      {nome}
    </a>
  );
};

const pacienteRows = ({
  paciente: { dataNascimento, email, celular, telefoneCasa },
  $sexo,
  $ultimoAtendimento,
}: {
  paciente: IAgendamentoFragment_paciente;
  $sexo: Ref<string | null>;
  $ultimoAtendimento: Ref<string | null>;
}) => {
  return (
    <div class="flex flex-col flex-none mr-10 text-gray-700 text-body-sm">
      <PacienteFones
        celular={celular}
        telefoneCasa={telefoneCasa}
        classes="mb-2"
      />

      <PacienteEmail email={email} classes="mb-2" />

      <PacienteIdade dataNascimento={dataNascimento} classes="mb-2" />

      <div class="flex mb-2">
        {$sexo.value && <div class="mr-4">Sexo: {$sexo.value}</div>}

        {$ultimoAtendimento.value && (
          <div class="mr-4">Último atendimento: {$ultimoAtendimento.value}</div>
        )}
      </div>
    </div>
  );
};
