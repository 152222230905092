export const unmask = (value: string) =>
  value ? value.replace(/\D+/g, '') : value;

export const formatTelefone = (value: string | null | undefined) => {
  if (!value) {
    return value;
  }

  const ddd = value.substr(0, 2); // ex: 19

  // celular
  if (value.length === 11) {
    const digito = value.substr(2, 1); // ex: 9
    const part1 = value.substr(3, 4); // ex: 8782
    const part2 = value.substr(7, 4); // ex: 6689

    return `(${ddd}) ${digito} ${part1}-${part2}`;
  }

  // telefone normal
  const part1 = value.substr(2, 4); // ex: 8782
  const part2 = value.substr(6, 4); // ex: 6689
  return `(${ddd}) ${part1}-${part2}`;
};

export const formatCep = (value: string | null | undefined) => {
  if (!value) {
    return value;
  }

  return `${value.substr(0, 2)}.${value.substr(2, 3)}-${value.substr(5, 3)}`;
};

const filterAndJoin = (array: any[], strToJoin: string) =>
  array.filter(value => !!value).join(strToJoin);

export const formatEndereco = ({
  cep,
  logradouro,
  numero,
  complemento,
  bairro,
  cidade,
  uf,
}: {
  cep?: string | null;
  logradouro?: string | null;
  numero?: string | null;
  complemento?: string | null;
  bairro?: string | null;
  cidade?: string | null;
  uf?: string | null;
}) => {
  cep = formatCep(cep);

  const logradouroAndNumeroAndComplemento = filterAndJoin(
    [logradouro, numero, complemento],
    ', ',
  );

  const cidadeAndUf = filterAndJoin([cidade, uf], '/');

  const bairroAndCidadeAndUf = filterAndJoin([bairro, cidadeAndUf], ' - ');

  const cepAndLogradouroAndNumeroAndComplemento = filterAndJoin(
    [cep, logradouroAndNumeroAndComplemento],
    ' - ',
  );

  return filterAndJoin(
    [cepAndLogradouroAndNumeroAndComplemento, bairroAndCidadeAndUf],
    ', ',
  );
};

export const formatCpf = (value: string | null | undefined) => {
  if (!value) {
    return value;
  }

  const p1 = value.substr(0, 3);
  const p2 = value.substr(3, 3);
  const p3 = value.substr(6, 3);
  const p4 = value.substr(9, 2);

  return `${p1}.${p2}.${p3}-${p4}`;
};
