import { createComponent } from '@/lib/vue';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { IFormInputGroupCheckbox } from '@/typings';

interface IProps extends IInputProps {
  // model
  value?: string[];
  input: IFormInputGroupCheckbox;
}

interface IEvents {
  onInput: (value: boolean) => void;
}

export const InputGroupCheckbox = createComponent<IProps, IEvents>({
  name: 'InputGroupCheckbox',
  props: {
    value: { type: Array, default: () => [] },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props: IProps, ctx) {
    const hasValue = (item: string) => !!props.value?.includes(item);

    function emitInput(value: string[]) {
      ctx.emit('input', value);
    }

    function handleChange(item: string, checked: boolean) {
      const value = (props.value || []).filter(f => f !== item);

      if (checked) {
        value.push(item);
      }

      emitInput(value);
    }

    const { $label } = useInput(props);

    return () => (
      <div class="flex flex-col">
        <div class="flex my-2 v-label theme--light">{$label.value}</div>

        <div class="flex">
          {props.input.items.map(item => (
            <div class="flex-none mb-2 mr-8">
              <v-checkbox
                class="dense-input"
                label={item}
                inputValue={hasValue(item)}
                hideDetails
                onChange={checked => handleChange(item, checked)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  },
});
