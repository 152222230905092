import { IPacienteFragment, PacienteWhereInput } from '@/typings';

export function pacienteWhere(search: string | null): PacienteWhereInput {
  const where: PacienteWhereInput = { incompleto: false };

  if (!search) {
    return where;
  }

  return {
    OR: [
      { nome_contains: search, ...where },
      { cpf_contains: search, ...where },
      { codigo_contains: search, ...where },
      { celular_contains: search, ...where },
      { telefoneCasa_contains: search, ...where },
      { telefoneTrabalho_contains: search, ...where },
    ],
  };
}

export function getPacienteConvenio(paciente: IPacienteFragment) {
  if (!paciente.convenios) {
    return null;
  }

  const foundConvenio = paciente.convenios.find(
    f => !!f.semVencimento || new Date(f.vencimento) > new Date(),
  );

  if (foundConvenio) {
    return foundConvenio.convenio.id;
  }

  return null;
}
