import { FileHelpers } from '@/lib/helpers/file.helpers';
import { truncateStr } from '@/lib/helpers/utils';
import { createComponent } from '@/lib/vue';
import { IFileUploadInfo, IRequireField } from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { BtnSmall } from '../../buttons/BtnSmall';
import { InputTextarea } from '../../form/inputs/InputTextarea';
import { CircularLoading } from '../../loading/CircularLoading';
import { FileIcon } from './FileIcon';

interface IJsxProps {
  value?: IFileUploadInfo[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: IFileUploadInfo[]) => void;
}

export const FilesList = createComponent<IJsxProps, IEvents>({
  name: 'FilesList',
  props: {
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    const { handleRemove } = useActions(props, ctx);
    return () => {
      if (!props.value.length) return null;

      return (
        <div class="flex flex-col">
          <div class="mb-4 text-coolGray-700 text-title">
            Arquivos selecionados
          </div>

          {props.value.map((v, index) => (
            <div class="flex flex-col">
              {index > 0 && <v-divider class="my-4 dashed" />}

              <div class="flex items-center" key={v.uuid}>
                <div class="flex flex-col w-3/12">
                  <div class="flex justify-center">
                    <CircularLoading loading={!v.id} size={60}>
                      <FileIcon
                        name={v.file.name}
                        type={v.file.type}
                        file={v.file}
                      />
                    </CircularLoading>
                  </div>

                  <div class="flex justify-center text-center text-small">
                    {truncateStr(v.file.name, 50)} (
                    {FileHelpers.formatSize(v.file.size)})
                  </div>
                </div>

                <div class="w-8/12 px-4">
                  <InputTextarea
                    input={{
                      label: `Observações ${
                        FileHelpers.isImage(v.file.type)
                          ? 'desta imagem'
                          : 'deste arquivo'
                      }`,
                      type: 'textarea',
                      rows: 3,
                      hideDetails: true,
                    }}
                    v-model={v.description}
                  />
                </div>

                <div class="w-1/12">
                  <BtnSmall
                    remove
                    tooltip="Remover arquivo"
                    onClick={() => handleRemove(v.uuid)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
  },
});

function useActions(props: IProps, ctx: SetupContext) {
  function emitInput(value: IFileUploadInfo[]) {
    ctx.emit('input', value);
  }

  function handleRemove(uuid: string) {
    emitInput(props.value.filter(f => f.uuid !== uuid));
  }

  return { emitInput, handleRemove };
}
