import { store } from '@/store';
import { ProntuarioAtendimento } from './prontuarioAtendimento';
import { ProntuarioCache } from './prontuarioCache';
import { ProntuarioModelo } from './prontuarioModelos';
import { ProntuarioPdf } from './prontuarioPdf.service';
import { ProntuarioServiceState } from './prontuarioState';

export const getProntuarioState = () => store.state.prontuario;

export const ProntuarioService = {
  atendimento: ProntuarioAtendimento,
  cache: ProntuarioCache,
  modelo: ProntuarioModelo,
  pdf: ProntuarioPdf,
  ...ProntuarioServiceState,
};
