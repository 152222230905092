import { useValue } from '@/lib/composables';
import { FileHelpers } from '@/lib/helpers/file.helpers';
import { FileUploadService } from '@/lib/services/system/fileUpload.service';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { IImageUploadDialogParams } from '@/typings';
import { Cropper } from './Cropper';
import { ImageDropzone } from './ImageDropzone';
import { SelectImageTabs } from './SelectImageTabs';

interface IProps {
  params: IImageUploadDialogParams;
}

export const ImageUploadDialog = createComponent<IProps>({
  name: 'ImageUploadDialog',
  props: {
    params: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $image, setImage, handleCrop, handleBack } = useActions();

    return () => {
      const selectImage =
        props.params.mode === 'tabs' ? (
          <SelectImageTabs onInput={setImage} />
        ) : (
          <ImageDropzone onInput={setImage} />
        );

      return (
        <div class="p-4">
          <v-fade-transition mode="out-in">
            {$image.value ? (
              <Cropper
                image={$image.value}
                onCrop={handleCrop}
                onBack={handleBack}
              />
            ) : (
              selectImage
            )}
          </v-fade-transition>
        </div>
      );
    };
  },
});

function useActions() {
  const [$image, setImage] = useValue<File | null>(null);

  return {
    $image,
    setImage,

    handleCrop(image: string) {
      const file = FileHelpers.dataURItoFile({
        name: $image.value?.name,
        dataURI: image,
      });

      if (!$image.value || !file) return;

      return DialogState.confirm({
        image,
        upload: FileUploadService.upload({
          name: $image.value.name,
          type: $image.value.type,
          file,
        }),
      });
    },

    handleBack() {
      setImage(null);
    },
  };
}
