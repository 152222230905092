import { routeGroup } from '@/routes/utils';
import { IRouteConfig } from '@/typings';

const NotFoundPage = () =>
  import(
    /* webpackChunkName: "errors" */ '@/modules/errors/pages/NotFoundPage'
  );
const PermissionDeniedPage = () =>
  import(
    /* webpackChunkName: "errors" */ '@/modules/errors/pages/PermissionDeniedPage'
  );
const InternalServerErrorPage = () =>
  import(
    /* webpackChunkName: "errors" */ '@/modules/errors/pages/InternalServerErrorPage'
  );

export const errorsRoutes: IRouteConfig[] = routeGroup({
  prefix: 'erro',
  routes: [
    {
      path: '403',
      component: PermissionDeniedPage,
    },
    {
      path: '404',
      component: NotFoundPage,
    },
    {
      path: '500',
      component: InternalServerErrorPage,
    },
  ],
});
