import { validationSummary } from '@/lib/form';
import { prontuarioPreAtendimentoSchema } from '@/modules/prontuarios/components/forms/shared/forms.utils';
import {
  IProntuarioPreAtendimentoFormModel,
  IProntuarioState,
} from '@/typings';
import { ProntuarioState } from '..';

export const ProntuarioPreAtendimentoState = {
  setModel(model: IProntuarioPreAtendimentoFormModel) {
    ProntuarioState.commit(s => {
      s.forms.preAtendimento.model = model;

      setHasError(s);
    });
  },

  setImc(imc: number | null | undefined) {
    ProntuarioState.commit(s => {
      s.forms.preAtendimento.model.imc = imc || null;
    });
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.forms.preAtendimento.submitted = submitted;
    });
  },
};

function setHasError(s: IProntuarioState) {
  s.forms.preAtendimento.hasError = checkHasError(s);

  if (s.atendimento.submitted && s.forms.preAtendimento.hasError) {
    s.forms.preAtendimento.submitted = true;
  }
}

function checkHasError(s: IProntuarioState): boolean {
  const errors = validationSummary({
    form: { submitted: true },
    schema: prontuarioPreAtendimentoSchema(null),
    model: s.forms.preAtendimento.model,
    customErrors: [],
  });

  return errors.length > 0;
}
