import { IInputItems } from '@/typings';

export const motivoSaidaObito: IInputItems[] = [
  {
    label:
      '41 - Óbito com declaração de óbito fornecida pelo médico assistente',
    value: '41',
  },
  {
    label:
      '42 - Óbito com declaração de Óbito fornecida pelo Instituto Médico Legal - IML',
    value: '42',
  },
  {
    label:
      '43 - Óbito com declaração de Óbito fornecida pelo Serviço de Verificação de Óbito - SVO',
    value: '43',
  },
];
