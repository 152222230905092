import { PageSection } from '@/components/page/PageSection';
import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { AgendamentoStatus, ProcedimentoTipo } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { AgendamentoViewActions } from './actions';

export const AgendamentoBottomRow = createComponent({
  name: 'AgendamentoBottomRow',
  setup(props, ctx) {
    const { $lancarRecebimentoLabel, $showGerarGuia, $isDone } =
      useComputeds(ctx);

    return () => (
      <PageSection divider>
        <div class="flex flex-col items-center justify-end md:flex-row">
          {$showGerarGuia.value && (
            <v-btn
              text
              color="secondary"
              onClick={AgendamentoViewActions.handleGerarGuia}
            >
              <v-icon left>{MyIcons.tissGuiaConsulta}</v-icon>
              Gerar guia
            </v-btn>
          )}

          <v-btn
            class="my-2 md:mx-2"
            outlined
            color="secondary"
            onClick={AgendamentoViewActions.handleLancarRecebimento}
          >
            <v-icon left>{MyIcons.money}</v-icon>

            {$lancarRecebimentoLabel.value}
          </v-btn>

          {!$isDone.value && (
            <v-btn
              color="primary"
              onClick={AgendamentoViewActions.handleIniciarAtendimento}
            >
              <v-icon left>{MyIcons.prontuarioPlay}</v-icon>
              Iniciar atendimento
            </v-btn>
          )}
        </div>
      </PageSection>
    );
  },
});

function useComputeds(ctx: SetupContext) {
  const $transacoes = useState(s => s.agenda.agendamento!.transacoes);

  const $showGerarGuia = useState(
    s =>
      !!s.agenda.agendamento?.procedimentos?.every(
        p => p.procedimento.tipo !== ProcedimentoTipo.RETORNO,
      ) &&
      !s.agenda.agendamento?.tissGuiaConsulta?.id &&
      !s.agenda.agendamento?.tissGuiaHonorarios?.id &&
      !s.agenda.agendamento?.tissGuiaOdontologica?.id &&
      !s.agenda.agendamento?.tissGuiaSpSadt?.id,
  );

  const $hasRecebimento = computed(() => !!$transacoes.value?.length);

  const $lancarRecebimentoLabel = computed(() =>
    $hasRecebimento.value ? 'Editar recebimento' : 'Lançar recebimento',
  );

  const $isDone = useState(
    s =>
      s.agenda.agendamento?.status === AgendamentoStatus.PACIENTE_ATENDIDO ||
      !!s.agenda.agendamento?.atendimento?.id,
  );

  return {
    $transacoes,
    $showGerarGuia,
    $hasRecebimento,
    $lancarRecebimentoLabel,
    $isDone,
  };
}
