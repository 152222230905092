import { IPageInfoFragment } from '@/typings';
import { ErrorPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { DocumentNode } from 'apollo-link';
import { useValue } from '.';
import { watchRun } from '../vue';

export interface IQueryConfig<TResult, TData, TVariables> {
  query: DocumentNode;
  variables: () => TVariables;
  mapData: (result: TResult | null) => TData;
  mapPageInfo?: (result: TResult | null) => IPageInfoFragment;
  enabledFn?: () => boolean;
  throttle?: number;
  debounce?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  errorPolicy?: ErrorPolicy;
}

export function useQueryConfig<TResult, TData, TVariables>({
  query,
  variables,
  debounce,
  throttle = 500,
  fetchPolicy = 'cache-and-network',
  errorPolicy = 'all',
  enabledFn,
  mapData,
  mapPageInfo,
}: IQueryConfig<TResult, TData, TVariables>) {
  const [$data, setData] = useValue<TData | null>(null);
  const [$pageInfo, setPageInfo] = useValue<IPageInfoFragment | null>(null);

  const {
    result: $result,
    loading: $loading,
    refetch,
    fetchMore,
  } = useQuery<TResult, () => TVariables>(query, variables, () => ({
    enabled: enabledFn ? enabledFn() : true,
    throttle,
    debounce,
    fetchPolicy,
    errorPolicy,
  }));

  watchRun($result, result => {
    setPageInfo(mapPageInfo ? mapPageInfo(result || null) : null);

    setData(mapData(result || null));
  });

  return {
    $data,
    setData,
    $loading,
    $pageInfo,
    refetch,
    fetchMore,
  };
}
