import { CircularLoading } from '@/components/loading/CircularLoading';
import {
  PacienteCidade,
  PacienteDataNascimento,
  PacienteFones,
} from '@/components/paciente/PacienteComponents';
import { PageSection } from '@/components/page/PageSection';
import { Avatar } from '@/components/utils/Avatar';
import { useValue } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { PacienteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { DialogState } from '@/store/modules/dialog.store';
import {
  IPacienteFragment,
  IPacienteHomonimosDialogGoTo,
  IPacienteHomonimosDialogParams,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  params: IPacienteHomonimosDialogParams;
}

export const PacienteHomonimosDialog = createComponent<IProps>({
  name: 'PacienteHomonimosDialog',
  props: {
    params: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { handleMerge, handleNovoPaciente } = useEvents({
      props,
      ctx,
    });

    const { $homonimos, $loading } = useHomonimosQuery({
      props,
      handleNovoPaciente,
    });

    return () => (
      <div class="flex flex-col">
        <PageSection>
          <CircularLoading loading={$loading.value}>
            <div class="flex flex-col">
              <div class="flex text-coolGray-600 text-subtitle">
                <span>Foram encontrados pacientes com </span>

                <span class="ml-1 font-bold">nomes parecidos</span>

                <span> ao que você está cadastrando:</span>
              </div>

              <div class="flex flex-col">
                {$homonimos.value.map((v, idx) => [
                  idx > 0 && <v-divider class="dashed" />,

                  pacienteToSelect(v, handleMerge),
                ])}
              </div>
            </div>
          </CircularLoading>
        </PageSection>

        {adicionarNovoPacienteRow(handleNovoPaciente)}
      </div>
    );
  },
});

function useHomonimosQuery({
  props,
  handleNovoPaciente,
}: {
  props: IProps;
  handleNovoPaciente: () => void;
}) {
  const [$loading, setLoading] = useValue(true);

  const [$homonimos, setHomonimos] = useValue<IPacienteFragment[]>([]);

  async function loadHomonimos() {
    const nome = props.params.agendamento.paciente!.nome;

    const result = await PacienteService.getHomonimos(nome);

    setLoading(false);

    if (result?.nodes.length) {
      setHomonimos(result.nodes);
    } else {
      handleNovoPaciente();
    }
  }

  loadHomonimos();

  return { $homonimos, $loading };
}

function useEvents({ props, ctx }: { props: IProps; ctx: SetupContext }) {
  const mergeId = props.params.agendamento.paciente!.id;

  function getRoute(goTo: IPacienteHomonimosDialogGoTo, pacienteId: string) {
    const { id: agendamentoId } = props.params.agendamento;

    switch (goTo) {
      case 'cadastro':
        return Routes.app.pacientes.edit.index(pacienteId, { agendamentoId });
      case 'prontuário':
        return Routes.app.prontuario(pacienteId, { agendamentoId });
    }
  }

  function goToRoute(goTo: IPacienteHomonimosDialogGoTo, pacienteId: string) {
    useRouter().push(getRoute(goTo, pacienteId));

    DialogState.confirm();
  }

  function handleMerge(keepId: string) {
    PacienteService.merge({ mergeId, keepId });

    return goToRoute(props.params.goTo, keepId);
  }

  function handleNovoPaciente() {
    return goToRoute('cadastro', mergeId);
  }

  return { handleMerge, handleNovoPaciente };
}

const pacienteToSelect = (
  {
    id,
    nome,
    imagem,
    celular,
    telefoneCasa,
    dataNascimento,
    enderecoCidade,
  }: IPacienteFragment,
  handleMerge: (keepId: string) => void,
) => (
  <div class="flex items-center mt-4">
    <div class="flex">
      <Avatar imgSrc={imagem?.thumbnailUrl} nome={nome} class="mr-2" />

      <div class="flex flex-col text-gray-700 text-body-sm">
        <div class="mb-2 text-title">{nome}</div>

        <PacienteFones celular={celular} telefoneCasa={telefoneCasa} />

        <PacienteDataNascimento dataNascimento={dataNascimento} />

        <PacienteCidade cidade={enderecoCidade} />
      </div>
    </div>

    <v-spacer />

    <v-btn color="primary" outlined onClick={() => handleMerge(id)}>
      Selecionar
    </v-btn>
  </div>
);

const adicionarNovoPacienteRow = (handleNovoPaciente: () => void) => (
  <PageSection divider>
    <div class="flex">
      <v-spacer />

      <v-btn color="primary" dark class="ml-2" onClick={handleNovoPaciente}>
        Adicionar novo paciente
      </v-btn>
    </div>
  </PageSection>
);
