import { QueryGraphql } from '@/graphql/query';
import {
  Cid10Tipo,
  ConvenioOrderBy,
  ICategoriasFinanceirasQuery,
  ICategoriasFinanceirasQueryVariables,
  ICentroCustoFragment,
  ICentrosCustosQuery,
  ICentrosCustosQueryVariables,
  ICid10LookupFragment,
  ICid10LookupsQuery,
  ICid10LookupsQueryVariables,
  IContasFinanceirasQuery,
  IContasFinanceirasQueryVariables,
  IConvenioFragment,
  IConvenioFragment_planos,
  IConvenioLookupFragment,
  IConvenioLookupsQuery,
  IConvenioLookupsQueryVariables,
  IConvenioQuery,
  IConvenioQueryVariables,
  IConveniosQuery,
  IConveniosQueryVariables,
  ILookup,
  IPacienteFragment,
  IPacientesQuery,
  IPacientesQueryVariables,
  IProcedimentoFragment,
  IProcedimentosQuery,
  IProcedimentosQueryVariables,
  ITissProcedimentosQuery,
  ITissProcedimentosQueryVariables,
  ITussLookupItem,
  ITussLookupsQuery,
  ITussLookupsQueryVariables,
  IUserFragment,
  IUsersQuery,
  IUsersQueryVariables,
  TissTabela,
  TransacaoTipo,
  UserTipo,
} from '@/typings';
import { SERVER_MAX_TAKE } from '../constants/server';
import {
  getTissProcedimentosQueryVariables,
  getTussLookupsQueryVariables,
  isTussLookup,
} from './utils/lookup.utils';

export const LookupsConfigs = {
  convenio({
    noParticular = false,
    ...config
  }: {
    onModelChange?: (model: IConvenioFragment | null) => any;
    noParticular?: boolean;
  } = {}): ILookup {
    return {
      query: QueryGraphql.ConveniosQuery,
      getVariables: (searchValue): IConveniosQueryVariables => ({
        where: {
          nome_contains: searchValue,
          ...(noParticular && { particular: false }),
        },
        orderBy: [ConvenioOrderBy.particular_DESC, ConvenioOrderBy.nome_ASC],
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: IConveniosQuery) => data.convenios.nodes,
      mapPageInfo: (data: IConveniosQuery) => data.convenios.pageInfo,
      ...config,
    };
  },

  convenioPlano({
    convenioId,
    ...config
  }: {
    convenioId: string;
    onModelChange?: (model: IConvenioFragment_planos | null) => any;
    noCache?: boolean;
  }): ILookup {
    return {
      query: QueryGraphql.ConvenioQuery,
      getVariables: (): IConvenioQueryVariables => ({
        id: convenioId,
      }),
      mapData(data: IConvenioQuery) {
        if (!data.convenio || !data.convenio.planos) {
          return [];
        }

        return data.convenio.planos;
      },
      mapPageInfo: (data: IConvenioQuery) => null,
      ...config,
    };
  },

  paciente(
    config: {
      onModelChange?: (model: IPacienteFragment | null) => any;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.PacientesQuery,
      getVariables: (searchValue): IPacientesQueryVariables => ({
        where: {
          nome_contains: searchValue,
          incompleto: false,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: IPacientesQuery) => data.pacientes.nodes,
      mapPageInfo: (data: IPacientesQuery) => data.pacientes.pageInfo,
      ...config,
    };
  },

  user: {
    profissionais(
      config: {
        onModelChange?: (model: IUserFragment | null) => any;
        mapData?: (data: IUsersQuery) => IUserFragment[];
      } = {},
    ): ILookup {
      return {
        query: QueryGraphql.UsersQuery,
        getVariables: (searchValue): IUsersQueryVariables => ({
          where: {
            nome_contains: searchValue,
            tipo: UserTipo.PROFISSIONAL_SAUDE,
          },
          take: SERVER_MAX_TAKE,
        }),
        mapData: (data: IUsersQuery) => data.users.nodes,
        mapPageInfo: (data: IUsersQuery) => data.users.pageInfo,
        ...config,
      };
    },
  },

  centroCusto(
    config: {
      onModelChange?: (model: ICentroCustoFragment | null) => any;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.CentrosCustosQuery,
      getVariables: (searchValue): ICentrosCustosQueryVariables => ({
        where: {
          nome_contains: searchValue,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: ICentrosCustosQuery) => data.centrosCustos.nodes,
      mapPageInfo: (data: ICentrosCustosQuery) => data.centrosCustos.pageInfo,
      ...config,
    };
  },

  cid10Lookup(
    config: {
      onModelChange?: (model: ICid10LookupFragment | null) => any;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.Cid10LookupsQuery,
      getVariables: (searchValue): ICid10LookupsQueryVariables => ({
        where: {
          descricao_contains: searchValue,
          tipo: Cid10Tipo.SUBCATEGORIA,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: ICid10LookupsQuery) => data.cid10Lookups.nodes,
      mapPageInfo: (data: ICid10LookupsQuery) => data.cid10Lookups.pageInfo,
      fetchPolicy: 'cache-first',
      ...config,
    };
  },

  contaFinanceira(
    config: {
      getVariables?: (searchValue: any) => IContasFinanceirasQueryVariables;
      noCache?: boolean;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.ContasFinanceirasQuery,
      getVariables: (searchValue): IContasFinanceirasQueryVariables => ({
        where: {
          nome_contains: searchValue,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: IContasFinanceirasQuery) => data.contasFinanceiras.nodes,
      mapPageInfo: (data: IContasFinanceirasQuery) =>
        data.contasFinanceiras.pageInfo,
      ...config,
    };
  },

  categoriaFinanceira(
    config: {
      tipo?: TransacaoTipo | null;
      getVariables?: (searchValue: any) => ICategoriasFinanceirasQueryVariables;
      noCache?: boolean;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.CategoriasFinanceirasQuery,
      getVariables: (searchValue): ICategoriasFinanceirasQueryVariables => ({
        where: {
          nome_contains: searchValue,
          tipo: config.tipo,
          parentId_not: null,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: ICategoriasFinanceirasQuery) =>
        data.categoriasFinanceiras.nodes.map(v => ({
          id: v.id,
          nome: v.parent ? `${v.nome} - ${v.parent.nome}` : v.nome,
        })),
      mapPageInfo: (data: ICategoriasFinanceirasQuery) =>
        data.categoriasFinanceiras.pageInfo,
      ...config,
    };
  },

  procedimento({
    profissionalId,
    ...config
  }: {
    profissionalId?: string | null;
    mapData?: (data: IProcedimentosQuery) => any[];
    onModelChange?: (model: IProcedimentoFragment | null) => any;
    noCache?: boolean;
  } = {}): ILookup {
    return {
      query: QueryGraphql.ProcedimentosQuery,
      getVariables: (searchValue): IProcedimentosQueryVariables => ({
        profissionalId,
        where: {
          nome_contains: searchValue,
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: IProcedimentosQuery) => data.procedimentos.nodes,
      mapPageInfo: (data: IProcedimentosQuery) => data.procedimentos.pageInfo,
      ...config,
    };
  },

  convenioLookups(
    config: {
      onModelChange?: (model: IConvenioLookupFragment | null) => any;
    } = {},
  ): ILookup {
    return {
      query: QueryGraphql.ConvenioLookupsQuery,
      getVariables: (searchValue): IConvenioLookupsQueryVariables => ({
        where: {
          OR: [
            { razaoSocial_contains: searchValue },
            { nomeFantasia_contains: searchValue },
          ],
        },
        take: SERVER_MAX_TAKE,
      }),
      mapData: (data: IConvenioLookupsQuery) => data.convenioLookups.nodes,
      mapPageInfo: (data: IConvenioLookupsQuery) =>
        data.convenioLookups.pageInfo,
      fetchPolicy: 'cache-first',
      ...config,
    };
  },

  tissProcedimento({
    tabela,
    codigo,
    ...config
  }: {
    tabela: TissTabela | null;
    codigo: string | null | undefined;
    onModelChange?: (model: ITussLookupItem | null) => any;
  }): ILookup {
    const isTuss = isTussLookup(tabela);

    const query = isTuss
      ? QueryGraphql.TussLookupsQuery
      : QueryGraphql.TissProcedimentosQuery;

    return {
      query,
      getVariables: (
        searchValue,
      ): ITussLookupsQueryVariables | ITissProcedimentosQueryVariables =>
        isTuss
          ? getTussLookupsQueryVariables({ tabela, codigo, searchValue })
          : getTissProcedimentosQueryVariables({ tabela, codigo, searchValue }),
      mapData: (
        data: ITussLookupsQuery | ITissProcedimentosQuery,
      ): ITussLookupItem[] => {
        if ('tussLookups' in data) {
          return data.tussLookups.nodes.map(v => ({
            descricao: `${v.codigo} - ${v.nome}`,
            nome: v.nome,
            codigo: v.codigo,
          }));
        } else if ('tissProcedimentos' in data) {
          return data.tissProcedimentos.nodes.map(v => ({
            descricao: `${v.codigo} - ${v.nome}`,
            nome: v.nome,
            codigo: v.codigo,
          }));
        }

        return [];
      },
      mapPageInfo: (data: ITussLookupsQuery | ITissProcedimentosQuery) =>
        'tussLookups' in data
          ? data.tussLookups.pageInfo
          : data.tissProcedimentos.pageInfo,
      noCache: true,
      fetchPolicy: 'cache-first',
      ...config,
    };
  },
};
