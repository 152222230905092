import { IInputItems } from '@/typings';

export enum TissLoteVersao {
  V3_05_00 = '3.05.00',
  V3_04_01 = '3.04.01',
  V3_04_00 = '3.04.00',
  V3_03_03 = '3.03.03',
  V3_03_02 = '3.03.02',
  V3_03_01 = '3.03.01',
  V3_03_00 = '3.03.00',
  V3_02_02 = '3.02.02',
  V3_02_01 = '3.02.01',
  V3_02_00 = '3.02.00',
}

export const loteVersao: IInputItems[] = [
  { label: '3.05.00', value: TissLoteVersao.V3_05_00 },
  { label: '3.04.01', value: TissLoteVersao.V3_04_01 },
  { label: '3.04.00', value: TissLoteVersao.V3_04_00 },
  { label: '3.03.03', value: TissLoteVersao.V3_03_03 },
  { label: '3.03.02', value: TissLoteVersao.V3_03_02 },
  { label: '3.03.01', value: TissLoteVersao.V3_03_01 },
  { label: '3.03.00', value: TissLoteVersao.V3_03_00 },
  { label: '3.02.02', value: TissLoteVersao.V3_02_02 },
  { label: '3.02.01', value: TissLoteVersao.V3_02_01 },
  { label: '3.02.00', value: TissLoteVersao.V3_02_00 },
];
