import { checkIsAuthenticated } from '@/lib/services/auth/utils';
import { Routes } from '@/routes/routes';

import { NavigationGuard } from 'vue-router';

export const notAuthenticatedGuard: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const isAuthenticated = await checkIsAuthenticated();

  return isAuthenticated ? next(Routes.app.home) : next();
};
