import { DateHelpers, IDateTypes } from '@/lib/helpers/date.helpers';
import { IFluxoCaixaType } from '@/typings';

export function mapFluxoCaixaDates(
  { type, current }: { type: IFluxoCaixaType; current?: string | null } = {
    type: 'month',
  },
) {
  const date = DateHelpers.parse(current) || DateHelpers.today();

  if (type === 'month') {
    return {
      current: date.toISODate(),
      start: date.startOf('month').toISODate(),
      end: date.plus({ months: 6 }).endOf('month').toISODate(),
    };
  }

  return {
    current: date.toISODate(),
    start: DateHelpers.weekStart(date)!.toISODate(),
    end: DateHelpers.weekEnd(date)!.toISODate(),
  };
}

export function formatFluxoCaixaDate({
  value,
  type,
}: {
  value: IDateTypes;
  type: IFluxoCaixaType;
}) {
  return type === 'month'
    ? DateHelpers.formatMonthAndYearShort(value)
    : DateHelpers.formatDate(value);
}
