import { IInputItems, TransacaoRepeticaoModo, TransacaoTipo } from '@/typings';

export const transacaoRepeticaoModos: IInputItems[] = [
  {
    label: 'Parcelas',
    value: TransacaoRepeticaoModo.PARCELAS,
  },
  {
    label: 'Repetição',
    value: TransacaoRepeticaoModo.REPETICAO,
  },
];

export const transacaoStatus: IInputItems[] = [
  { label: 'Pago', value: true },
  { label: 'Não pago', value: false },
];

export const transacaoTipos: IInputItems[] = [
  { label: 'Despesa', value: TransacaoTipo.DESPESA },
  { label: 'Receita', value: TransacaoTipo.RECEITA },
];
