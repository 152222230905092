import {
  ICreatePacienteConvenioMutation_createPacienteConvenio,
  IPacienteConvenioFormModel,
  IPacienteDadosPessoaisFormSchema,
  IPacienteFragment_convenios,
  IUpdatePacienteConvenioMutation_updatePacienteConvenio,
} from '@/typings';
import { Ref } from '@vue/composition-api';
import { ConstantsHelper } from '../constants/helper';

export const pacienteDadosPessoaisForm = (
  $requireFones: Ref<boolean>,
): IPacienteDadosPessoaisFormSchema => ({
  geral: {
    nome: {
      label: 'Nome',
      type: 'text',
      validations: { required: true },
    },
    dataNascimento: {
      label: 'Data de nascimento',
      type: 'date',
      layout: { width: 270 },
      validations: {
        required: true,
        maxDate: new Date(),
      },
    },
    codigo: {
      label: 'Código',
      type: 'text',
      layout: { width: 180 },
    },
    email: {
      label: 'Email',
      type: 'email',
      layout: { sm: 8 },
    },
    sexo: {
      label: 'Sexo',
      type: 'select',
      items: ConstantsHelper.sexos,
      layout: {
        width: 180,
        sm: 4,
      },
      validations: { required: true },
    },
    cpf: {
      label: 'CPF',
      type: 'text',
      mask: 'cpf',
      layout: { width: 260 },
    },
    rg: {
      label: 'RG',
      type: 'text',
      layout: { width: 200 },
    },
    comoConheceu: {
      label: 'Como conheceu?',
      type: 'autocomplete',
      list: ConstantsHelper.comoConheceu,
      layout: { width: 300 },
    },
    observacao: {
      label: 'Observação',
      type: 'textarea',
    },
  },
  telefones: {
    celular: {
      label: 'Celular',
      type: 'text',
      mask: 'celular',
      layout: { sm: 6 },
      validations: {
        customName: 'Telefone celular',
        required: $requireFones.value,
      },
    },
    telefoneCasa: {
      label: 'Casa',
      type: 'text',
      mask: 'telefone',
      layout: { sm: 6 },
      validations: {
        customName: 'Telefone casa',
        required: $requireFones.value,
      },
    },
    telefoneTrabalho: {
      label: 'Trabalho',
      type: 'text',
      mask: 'telefone',
      layout: { sm: 6 },
    },
    aceitaSms: {
      label: 'Aceita receber SMS',
      type: 'checkbox',
      layout: { sm: 6 },
    },
  },
});

export const pacienteConvenioToFormModel = (
  v:
    | IPacienteFragment_convenios
    | ICreatePacienteConvenioMutation_createPacienteConvenio
    | IUpdatePacienteConvenioMutation_updatePacienteConvenio,
): IPacienteConvenioFormModel => {
  return {
    id: v.id,
    convenio: {
      convenioId: v.convenio.id,
      convenioNome: v.convenio.nome,
      planoId: v.plano?.id,
      planoNome: v.plano?.nome,
      acomodacao: v.acomodacao,
    },
    carteirinha: {
      numeroCarteirinha: v.numeroCarteirinha,
      vencimento: v.vencimento,
      semVencimento: !!v.semVencimento,
    },
  };
};
