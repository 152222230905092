import { PageSection } from '@/components/page/PageSection';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { IDeleteDialogParams } from '@/typings';
import { PropType } from 'vue';

export const DeleteDialog = createComponent({
  name: 'DeleteDialog',
  props: {
    params: { type: Object as PropType<IDeleteDialogParams>, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { text, aviso } = props.params;

      return (
        <div class="flex flex-col">
          <PageSection>
            <div class="my-2 text-subtitle text-coolGray-800 font-medium">
              Tem certeza de que deseja excluir {text} permanentemente?
            </div>

            {aviso && (
              <div class="mb-2 text-subtitle text-coolGray-600">
                Ao excluir {text}, todos os seus dados relacionados serão
                removidos permanentemente, incluindo {aviso}.
              </div>
            )}
          </PageSection>

          <PageSection divider>
            <div class="flex">
              <v-spacer />

              <v-btn text color="primary" onClick={DialogState.cancel}>
                Cancelar
              </v-btn>

              <v-btn
                color={MyTheme.red500}
                dark
                class="ml-2"
                onClick={DialogState.confirm}
              >
                Excluir
              </v-btn>
            </div>
          </PageSection>
        </div>
      );
    };
  },
});
