import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  AgendamentoCreateDataInput,
  AgendamentoRecorrenciaDataInput,
  IAgendamentoFormModel,
  IAgendamentoRecorrenciaFormModel,
} from '@/typings';
import isNumber from 'lodash/isNumber';
import { agendamentoOnSuccess } from './agendamento.service';

export const AgendamentoCreate = {
  async create({
    profissionalId,
    model,
    listaEsperaId,
  }: {
    profissionalId: string;
    model: Omit<IAgendamentoFormModel, 'recorrencia'>;
    listaEsperaId?: string | null;
  }) {
    try {
      const result = await AgendamentoGraphql.createAgendamento({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toAgendamentoCreateDataInput(model),
        listaEsperaId,
      });

      agendamentoOnSuccess({
        result,
        msg: 'criado',
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async createRecorrencia({
    profissionalId,
    model,
    recorrencia,
  }: {
    profissionalId: string;
    model: Omit<IAgendamentoFormModel, 'recorrencia'>;
    recorrencia: IAgendamentoRecorrenciaFormModel;
  }) {
    try {
      const result = await AgendamentoGraphql.createAgendamentoRecorrencia({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: toAgendamentoCreateDataInput(model),
        recorrencia: toAgendamentoRecorrenciaDataInput(recorrencia),
      });

      if (result) {
        router.push(Routes.app.agenda(profissionalId).index);

        toastSuccess('Agendamentos criados com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};

export function toAgendamentoCreateDataInput({
  tipo: { tipo },
  procedimentos,
  time: { data, horaInicial, horaFinal, diaTodo },
  paciente: { nome, pacienteId, celular, convenioId, telefoneCasa, email },
  outrasInformacoes: { observacao },
}: Omit<IAgendamentoFormModel, 'recorrencia'>): AgendamentoCreateDataInput {
  const bloqueado = tipo === 'Horário bloqueado';

  if (!bloqueado && !pacienteId) {
    Validate.require(nome, 'paciente.nome');
    Validate.either(
      [celular, telefoneCasa],
      ['paciente.celular, paciente.telefoneCasa'],
    );
  }

  return {
    bloqueado,
    procedimentos: procedimentos?.map(v => ({
      procedimentoId: Validate.require(v.procedimentoId, 'procedimentoId'),
      quantidade: Validate.require(v.quantidade, 'quantidade'),
    })),
    data: Validate.date(data, 'data'),
    horaInicial: DateHelpers.toISOTime(horaInicial),
    horaFinal: DateHelpers.toISOTime(horaFinal),
    diaTodo: bloqueado && diaTodo,
    paciente: {
      id: pacienteId,
      nome,
      celular,
      telefoneCasa,
      email,
    },
    observacao,
    convenioId,
  };
}

export function toAgendamentoRecorrenciaDataInput({
  repetir: { tipo },
  terminaEm: { ocorrencias, dataFinal },
  diasSemana: { domingo, segunda, terca, quarta, quinta, sexta, sabado },
}: IAgendamentoRecorrenciaFormModel): AgendamentoRecorrenciaDataInput {
  Validate.require(ocorrencias || dataFinal, 'ocorrencias or dataFinal');

  return {
    tipo: Validate.require(tipo, 'tipo'),
    ocorrencias,
    dataFinal,
    diasSemana: [domingo, segunda, terca, quarta, quinta, sexta, sabado]
      .map((v, idx) => (v ? idx : null))
      .filter(isNumber),
  };
}
