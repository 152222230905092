import { configuracoesAccountGuard } from '@/routes/guards/configuracoesAccount.guard';
import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const ConfiguracaoContaSidebar = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/components/ConfiguracaoContaSidebar'
  );
const ClinicasPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/clinica/ClinicasPage'
  );
const ClinicaFormPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/clinica/ClinicaFormPage'
  );
const UsersPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/user/UsersPage'
  );
const UserFormPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/user/UserFormPage'
  );
const AssinaturaPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/AssinaturaPage'
  );
const HistoricoCobrancasPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/HistoricoCobrancasPage'
  );
const MeuPerfilPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/user/MeuPerfilPage'
  );
const PermissoesEnvioPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/PermissoesEnvioPage'
  );
const SmsEnviadosPage = () =>
  import(
    /* webpackChunkName: "config-account" */ '@/modules/configuracoes/account/pages/SmsEnviadosPage'
  );

export const configuracoesAccountRoutes: IRouteConfig[] = [
  {
    path: 'configuracoes/meu-perfil',
    component: MeuPerfilPage,
  },
  ...routeGroup({
    prefix: 'configuracoes',
    beforeEnter: configuracoesAccountGuard,
    routes: [
      ...routeGroup({
        prefix: 'clinicas',
        routes: [
          {
            path: '',
            components: {
              default: ClinicasPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
          {
            path: 'new',
            components: {
              default: ClinicaFormPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
          {
            path: ':id',
            components: {
              default: ClinicaFormPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
        ],
      }),

      ...routeGroup({
        prefix: 'usuarios',
        routes: [
          {
            path: '',
            components: {
              default: UsersPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
          {
            path: 'new',
            components: {
              default: UserFormPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
          {
            path: ':id',
            components: {
              default: UserFormPage,
              sidebar: ConfiguracaoContaSidebar,
            },
          },
        ],
      }),

      {
        path: 'assinatura',
        components: {
          default: AssinaturaPage,
          sidebar: ConfiguracaoContaSidebar,
        },
      },
      {
        path: 'historico-de-cobrancas',
        components: {
          default: HistoricoCobrancasPage,
          sidebar: ConfiguracaoContaSidebar,
        },
      },
      {
        path: 'permissoes-de-envio',
        components: {
          default: PermissoesEnvioPage,
          sidebar: ConfiguracaoContaSidebar,
        },
      },
      {
        path: 'sms-enviados',
        components: {
          default: SmsEnviadosPage,
          sidebar: ConfiguracaoContaSidebar,
        },
      },
    ],
  }),
];
