import { AgendamentoGraphql } from '@/graphql/profissional/agendamento/AgendamentoGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import { AgendamentoRecebimentoDataInput } from '@/typings';
import { IAgendamentoRecebimentoFormModel } from '@/typings/model/profissional/agenda/agendamentoRecebimento.t';
import { agendamentoOnSuccess } from './agendamento.service';

export const AgendamentoRecebimento = {
  async createRecebimento({
    agendamentoId,
    model: { parcelas, ...model },
    profissionalId,
  }: {
    agendamentoId: string;
    model: IAgendamentoRecebimentoFormModel;
    profissionalId: string;
  }) {
    try {
      const result = await AgendamentoGraphql.createAgendamentoRecebimento({
        agendamentoId: Validate.require(agendamentoId, 'agendamentoId'),
        data: toAgendamentoRecebimentoDataInput(model),
        parcelas: parcelas.parcelar
          ? {
              valorEntrada: parcelas.valorEntrada,
              numero: Validate.min(parcelas.numero, 1, 'parcelas.numero'),
            }
          : null,
      });

      agendamentoOnSuccess({
        result,
        msg: parcelas
          ? 'Recebimentos criados com sucesso.'
          : 'Recebimento criado com sucesso.',
        full: true,
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateRecebimento({
    agendamentoId,
    model,
    profissionalId,
  }: {
    agendamentoId: string;
    model: IAgendamentoRecebimentoFormModel;
    profissionalId: string;
  }) {
    try {
      const result = await AgendamentoGraphql.updateAgendamentoRecebimento({
        agendamentoId: Validate.require(agendamentoId, 'agendamentoId'),
        data: toAgendamentoRecebimentoDataInput(model),
      });

      agendamentoOnSuccess({
        result,
        msg: 'Recebimento alterado com sucesso.',
        full: true,
        profissionalId,
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateRecebimentoStatus({
    agendamentoId,
    transacaoId,
    pago,
    profissionalId,
  }: {
    agendamentoId: string;
    transacaoId: string;
    pago: boolean;
    profissionalId: string;
  }) {
    try {
      const result =
        await AgendamentoGraphql.updateAgendamentoRecebimentoStatus({
          agendamentoId: Validate.require(agendamentoId, 'agendamentoId'),
          transacaoId: Validate.require(transacaoId, 'transacaoId'),
          pago,
        });

      if (result) {
        toastSuccess('Recebimento alterado com sucesso.');
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toAgendamentoRecebimentoDataInput({
  procedimentos,
  agendamento: { desconto },
  transacao: {
    contaFinanceiraId,
    formaPagamento,
    vencimento,
    pago,
    dataPagamento,
  },
}: Omit<
  IAgendamentoRecebimentoFormModel,
  'parcelas'
>): AgendamentoRecebimentoDataInput {
  return {
    procedimentos: procedimentos.map(v => ({
      procedimentoId: v.procedimentoId,
      quantidade: Validate.require(v.quantidade, 'procedimento.quantidade'),
      valorUnitario: Validate.require(
        v.valorUnitario,
        'procedimento.valorUnitario',
      ),
    })),
    desconto: desconto || 0,
    pago,
    dataPagamento,
    vencimento: Validate.require(vencimento, 'vencimento'),
    formaPagamento: Validate.require(formaPagamento, 'formaPagamento'),
    contaFinanceiraId: Validate.require(contaFinanceiraId, 'contaFinanceiraId'),
  };
}
