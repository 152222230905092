import { AtendimentoGraphql } from '@/graphql/profissional/atendimento/AtendimentoGraphql';
import { handleError } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';
import {
  AtendimentoCacheDataInput,
  AtendimentoDataInput,
  AtendimentoOrderBy,
} from '@/typings';

export const AtendimentoService = {
  async getAll({
    pacienteId,
    refetch = false,
  }: {
    pacienteId: string;
    refetch?: boolean;
  }) {
    try {
      return await AtendimentoGraphql.atendimentos(
        {
          pacienteId,
          orderBy: [AtendimentoOrderBy.dataInicial_DESC],
        },
        refetch,
      );
    } catch (error) {
      handleError(error);
    }
  },

  async create(data: AtendimentoDataInput) {
    try {
      const result = await AtendimentoGraphql.createAtendimento({ data });

      if (result) {
        toastSuccess('Atendimento criado com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },

  async getCache(pacienteId: string) {
    try {
      return await AtendimentoGraphql.atendimentoCache({ pacienteId });
    } catch (error) {
      handleError(error);
    }
  },

  async saveCache(data: AtendimentoCacheDataInput) {
    try {
      return await AtendimentoGraphql.saveAtendimentoCache({ data });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteCache({ pacienteId }: { pacienteId: string }) {
    try {
      const result = await AtendimentoGraphql.deleteAtendimentoCache({
        pacienteId,
      });

      return result?.ok;
    } catch (error) {
      handleError(error);
    }
  },

  async createObservacao({
    atendimentoId,
    texto,
  }: {
    atendimentoId: string;
    texto: string;
  }) {
    try {
      const result = await AtendimentoGraphql.createAtendimentoObservacao({
        atendimentoId,
        texto,
      });

      if (result) {
        toastSuccess('Observação criada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};
