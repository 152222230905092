import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { getInitials } from '@/lib/helpers/utils';
import { createComponent } from '@/lib/vue';
import { computed, Ref } from '@vue/composition-api';

interface IProps {
  imgSrc?: string | null;
  nome?: string | null;
  small?: boolean;
}

export const Avatar = createComponent<IProps>({
  name: 'Avatar',
  props: {
    imgSrc: { type: String },
    nome: { type: String },
    small: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const $initials = computed(() =>
      props.nome ? getInitials(props.nome, 2) : null,
    );

    const size = props.small ? 32 : 64;

    return () => (
      <div>
        <v-avatar size={size} color={MyTheme.coolGray400}>
          {avatarContent({ props, $initials })}
        </v-avatar>
      </div>
    );
  },
});

const avatarContent = ({
  props,
  $initials,
}: {
  props: IProps;
  $initials: Ref<string | null>;
}) => {
  if (props.imgSrc) {
    return <img src={props.imgSrc} alt="avatar" />;
  } else if ($initials.value) {
    return (
      <span
        class={['text-white', props.small ? 'text-subtitle' : 'text-headline']}
      >
        {$initials.value}
      </span>
    );
  }

  return (
    <v-icon dark size={props.small ? 16 : 32}>
      {MyIcons.account}
    </v-icon>
  );
};
