import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IItem } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  showEdit?: boolean;
  showRemove?: boolean;
  item: IItem;
  iconEdit?: string;
  iconRemove?: string;
}

interface IEvents {
  onEdit: ({ id: string, item: any }) => void;
  onRemove: ({ id: string, item: any }) => void;
}

export const TdActions = createComponent<IProps, IEvents>({
  name: 'TdActions',
  props: {
    showEdit: { type: Boolean, default: false },
    showRemove: { type: Boolean, default: false },
    item: Object,
    iconEdit: { type: String, default: MyIcons.edit },
    iconRemove: { type: String, default: MyIcons.remove },
  },
  setup(props, ctx) {
    return () => {
      if (!props.item || (!props.showEdit && !props.showRemove)) {
        return null;
      }

      return (
        <div key="actions" class="text-center actions">
          {!props.item.noActions && [
            btnEdit(props, ctx),
            btnRemove(props, ctx),
          ]}
        </div>
      );
    };
  },
});

const btnEdit = (props: IProps, ctx: SetupContext) =>
  props.showEdit && (
    <v-btn
      aria-label="Editar"
      data-balloon-pos="up"
      class="mx-1"
      icon
      onClick={() => ctx.emit('edit', { id: props.item.id, item: props.item })}
    >
      <v-icon>{props.iconEdit}</v-icon>
    </v-btn>
  );

const btnRemove = (props: IProps, ctx: SetupContext) =>
  props.showRemove && (
    <v-btn
      aria-label="Remover"
      data-balloon-pos="up"
      class="mx-1"
      icon
      onClick={() =>
        ctx.emit('remove', { id: props.item.id, item: props.item })
      }
    >
      <v-icon>{props.iconRemove}</v-icon>
    </v-btn>
  );
