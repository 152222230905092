import { unmaskNumbers } from './cnpj';

export class CPF {
  static blacklist = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    '12345678909',
  ];

  static verifyDigit(value: string) {
    const numbers = value.split('').map(num => parseInt(num, 10));

    const modulus = numbers.length + 1;

    const multiplied = numbers.map(
      (number, index) => number * (modulus - index),
    );

    const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

    return mod < 2 ? 0 : 11 - mod;
  }

  static format(value: string) {
    return unmaskNumbers(value || '').replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4',
    );
  }

  static isValid(value: string | number) {
    const stripped = unmaskNumbers(value);

    if (
      // CPF must be defined
      !stripped ||
      // CPF must have 11 chars
      stripped.length !== 11 ||
      // CPF can't be blacklisted
      this.blacklist.includes(stripped)
    ) {
      return false;
    }

    let numbers = stripped.substr(0, 9);
    numbers += this.verifyDigit(numbers);
    numbers += this.verifyDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  }

  static generate(formatted = false) {
    let numbers = '';

    for (let i = 0; i < 9; i++) {
      numbers += Math.floor(Math.random() * 9);
    }

    numbers += this.verifyDigit(numbers);
    numbers += this.verifyDigit(numbers);

    return formatted ? this.format(numbers) : numbers;
  }
}
