import { FileHelpers } from '@/lib/helpers/file.helpers';
import { createComponent } from '@/lib/vue';

interface IProps {
  name: string | null;
  type: string;
  file?: File;
  imageUrl?: string | null;
  size?: number;
}

export const FileIcon = createComponent<IProps>({
  name: 'FileIcon',
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    file: { type: File },
    imageUrl: { type: String },
    size: { type: Number, default: 80 },
  },
  setup(props, ctx) {
    return () => {
      if (FileHelpers.isImage(props.type)) {
        return (
          <img
            src={props.file ? URL.createObjectURL(props.file) : props.imageUrl!}
            alt={props.name!}
            height={props.size}
            width={props.size}
            class="object-cover"
          />
        );
      }

      return (
        <v-icon size={props.size}>{FileHelpers.getIcon(props.type)}</v-icon>
      );
    };
  },
});
