export enum PdfType {
  PRONTUARIO_COMPLETO = 'PRONTUARIO_COMPLETO',
  PRONTUARIO_ATESTADO = 'PRONTUARIO_ATESTADO',
  PRONTUARIO_EXAME_PARTICULAR = 'PRONTUARIO_EXAME_PARTICULAR',
  PRONTUARIO_EXAME_SADT = 'PRONTUARIO_EXAME_SADT',
  PRONTUARIO_PRESCRICAO_ESPECIAL = 'PRONTUARIO_PRESCRICAO_ESPECIAL',
  PRONTUARIO_PRESCRICAO_NORMAL = 'PRONTUARIO_PRESCRICAO_NORMAL',
}

export interface IPdfLambdaEventBody {
  type: PdfType;
  atendimentoId?: string;
  atendimentosIds?: string[];
  pacienteId?: string;
  atestadoId?: string;
  prescricaoId?: string;
  exameId?: string;
}
