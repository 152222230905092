import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { mapAtendimentoDataInput } from '@/lib/helpers/models/prontuario/mapAtendimentoDataInput';
import { watchRun } from '@/lib/vue';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { ProntuarioState } from '@/store/modules/prontuario';
import { prontuarioPages } from '@/store/modules/prontuario/utils';
import { IProntuarioPageState } from '@/typings';
import debounce from 'lodash/debounce';
import { AtendimentoService } from '../../atendimento.service';
import { getProntuarioState, ProntuarioService } from './prontuario.service';

export const ProntuarioAtendimento = {
  async start() {
    watchModelsToSaveCache();

    ProntuarioState.atendimento.start();

    if (getProntuarioState().cache) {
      const { confirmed } = await DialogHelpers.prontuario.rascunho();

      if (confirmed) {
        ProntuarioState.cache.loadState();
      }
    }

    addDefaults();
  },

  async end() {
    ProntuarioState.atendimento.setSubmitted(true);

    if (checkHasError()) return;

    const { confirmed } = await DialogHelpers.prontuario.finalizar();

    if (confirmed) {
      ProntuarioState.atendimento.end();

      const data = mapAtendimentoDataInput(getProntuarioState());

      if (data) {
        clearAgendamento(data.pacienteId);

        await AtendimentoService.create(data);

        return ProntuarioService.refresh();
      }
    }
  },

  async createObservacao({
    atendimentoId,
    texto,
  }: {
    atendimentoId: string;
    texto: string | null;
  }) {
    if (texto) {
      await AtendimentoService.createObservacao({
        atendimentoId,
        texto,
      });

      ProntuarioService.refresh();
    }
  },
};

function checkHasError(): boolean {
  const {
    forms: {
      atestados,
      exames,
      hipoteseDiagnostica,
      preAtendimento,
      prescricoes,
    },
  } = getProntuarioState();

  let errorPage: IProntuarioPageState | null = null;

  if (preAtendimento.hasError) {
    errorPage = prontuarioPages.preAtendimento;
  } else if (exames.hasError) {
    errorPage = prontuarioPages.exames;
  } else if (prescricoes.hasError) {
    errorPage = prontuarioPages.prescricoes;
  } else if (hipoteseDiagnostica.hasError) {
    errorPage = prontuarioPages.hipoteseDiagnostica;
  } else if (atestados.hasError) {
    errorPage = prontuarioPages.atestados;
  }

  if (errorPage) {
    ProntuarioState.setPage(errorPage);
  }

  return !!errorPage;
}

function clearAgendamento(pacienteId: string) {
  ProntuarioState.setAgendamento(null);

  // clear query params replacing with the same route
  router
    .replace(Routes.app.prontuario(pacienteId))
    // avoid NavigationDuplicated error
    .catch(() => null);
}

function addDefaults() {
  const { exames, prescricoes, atestados } = getProntuarioState().forms;

  if (!exames.model.length) {
    ProntuarioState.model.exames.add();
  }

  if (!prescricoes.model.length) {
    ProntuarioState.model.prescricoes.add();
  }

  if (!atestados.model.length) {
    ProntuarioState.model.atestados.add();
  }
}

function watchModelsToSaveCache() {
  const debouncedSaveCache = debounce(
    () => ProntuarioService.cache.save(false),
    20000,
  );

  watchRun(
    () => [
      getProntuarioState().forms.atestados.model,
      getProntuarioState().forms.exames.model,
      getProntuarioState().forms.hipoteseDiagnostica.model,
      getProntuarioState().forms.preAtendimento.model,
      getProntuarioState().forms.prescricoes.model,
      getProntuarioState().tabs,
    ],
    debouncedSaveCache,
    { deep: true },
  );
}
