import { store } from '@/store';

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const makeCommit =
  <T>(module: string) =>
  (callback: (s: T) => any) =>
    store.commit(module ? `${module}/mutate` : 'mutate', {
      name: isChrome
        ? new Error().stack
            ?.split('\n')[2]
            ?.trim()
            ?.split(' ')[1]
            ?.split('.')[1] || ''
        : '',
      callback,
    });

export function createMutation() {
  return {
    mutate(state, payload: { name: string; callback: (s) => any }) {
      payload.callback(state);
    },
  };
}
