import { IInputItems, ProcedimentoTipo } from '@/typings';

export const procedimentoTipos: IInputItems[] = [
  {
    label: 'CONSULTA (GUIA DE CONSULTA)',
    value: ProcedimentoTipo.CONSULTA,
  },
  {
    label: 'RETORNO (NÃO FATURADO)',
    value: ProcedimentoTipo.RETORNO,
  },
  {
    label: 'EXAME (SP/SADT)',
    value: ProcedimentoTipo.EXAME,
  },
  {
    label: 'CIRURGIA (HONORÁRIO)',
    value: ProcedimentoTipo.CIRURGIA,
  },
  {
    label: 'SESSÃO (SP/SADT)',
    value: ProcedimentoTipo.SESSAO,
  },
  {
    label: 'ODONTOLOGIA (GTO)',
    value: ProcedimentoTipo.ODONTOLOGIA,
  },
];
