import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { AgendamentoService } from '@/lib/services';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { store } from '@/store';
import {
  IAgendamentoFragment_procedimentos,
  ProcedimentoTipo,
} from '@/typings';

const getState = () => store.state.agenda;

export const AgendamentoViewActions = {
  closeViewDialog() {
    AgendamentoService.closeViewDialog();
  },

  handleExcluir() {
    const { agendamento, profissionalId } = getState();
    if (!agendamento || !profissionalId) {
      return;
    }

    const { id, bloqueado, data, recorrencia } = agendamento;

    return AgendamentoService.delete({
      id,
      profissionalId,
      bloqueado,
      dataFinal: data,
      hasRecorrencia: !!recorrencia?.id,
    });
  },

  handleEditar() {
    const { agendamento, profissionalId } = getState();
    if (!agendamento || !profissionalId) {
      return;
    }

    router.push(
      Routes.app.agenda(profissionalId).agendamentos.edit(agendamento.id),
    );

    AgendamentoViewActions.closeViewDialog();
  },

  handleLog() {
    const { agendamento } = getState();

    if (!agendamento) return;

    router.push(Routes.app.logs.agendamento(agendamento.id));

    AgendamentoViewActions.closeViewDialog();
  },

  goToPacienteCadastro() {
    const paciente = getState().agendamento?.paciente;

    if (!paciente) return;

    router.push(Routes.app.pacientes.edit.index(paciente.id));

    AgendamentoViewActions.closeViewDialog();
  },

  handleNomeClick() {
    const { agendamento } = getState();

    if (!agendamento || !agendamento.paciente) {
      return;
    }

    const isHomonimo = !!agendamento.paciente.homonimo;

    if (isHomonimo) {
      return DialogHelpers.paciente.homonimos({
        agendamento,
        goTo: 'cadastro',
      });
    }

    return AgendamentoViewActions.goToPacienteCadastro();
  },

  handleIniciarAtendimento() {
    AgendamentoService.goToAtendimento(getState().agendamento);

    AgendamentoViewActions.closeViewDialog();
  },

  handleLancarRecebimento() {
    const { agendamento, profissionalId } = getState();
    if (!agendamento || !profissionalId) {
      return;
    }

    const { id: agendamentoId, transacoes } = agendamento;

    router.push(
      Routes.app.agenda(profissionalId).agendamentos.recebimento({
        agendamentoId,
        numTransacoes: transacoes?.length || 0,
      }),
    );

    AgendamentoViewActions.closeViewDialog();
  },

  handleGerarGuia() {
    const { agendamento } = getState();

    if (!agendamento || !agendamento.procedimentos?.length) return;

    const { id: agendamentoId, procedimentos } = agendamento;

    const { profissionalId } = useRouteParams();

    const route = Routes.app.tiss(profissionalId);

    if (procIsConsulta(procedimentos)) {
      // Guia de consulta
      router.push(route.guiasConsultas.new({ agendamentoId }));
    } else if (procIsOdonto(procedimentos)) {
      // Guia odontológica
      router.push(route.guiasOdontologicas.new({ agendamentoId }));
    } else if (procIsHonorarios(procedimentos)) {
      // Guia de honorários
      router.push(route.guiasHonorarios.new({ agendamentoId }));
    } else {
      // Guia SP/SADT
      router.push(route.guiasSpSadt.new({ agendamentoId }));
    }

    AgendamentoViewActions.closeViewDialog();
  },
};

function procIsConsulta(procs: IAgendamentoFragment_procedimentos[]) {
  return (
    procs.length === 1 &&
    procs[0].procedimento.tipo === ProcedimentoTipo.CONSULTA
  );
}

function procIsOdonto(procs: IAgendamentoFragment_procedimentos[]) {
  return procs.every(p => p.procedimento.tipo === ProcedimentoTipo.ODONTOLOGIA);
}

function procIsHonorarios(procs: IAgendamentoFragment_procedimentos[]) {
  return procs.every(p => p.procedimento.tipo === ProcedimentoTipo.CIRURGIA);
}
