import { InputSelect } from '@/components/form/inputs/InputSelect';
import { InputText } from '@/components/form/inputs/InputText';
import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { createComponent } from '@/lib/vue';
import { TissState } from '@/store/modules/tiss.store';
import { IFormInputSelect, TissTabela } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {}

interface IEvents {}

export const TussLookupForm = createComponent<IProps, IEvents>({
  name: 'TussLookupForm',
  setup(props, ctx) {
    const $inputSelect = useInputSelect();

    const $model = useState(s => s.tiss.tussLookup.search);

    return () => (
      <div id="TussLookupForm" class="flex flex-row items-center p-4 space-x-4">
        <InputSelect
          input={$inputSelect.value}
          value={$model.value.tabela}
          onInput={handleTabelaChange}
          class="w-24"
        />

        <InputText
          input={{
            label: '',
            type: 'text',
            placeholder: 'Pesquisar por nome ou código...',
            hideDetails: true,
          }}
          value={$model.value.text}
          onInput={handleTextChange}
        />
      </div>
    );
  },
});

function useInputSelect() {
  const $tabelas = useState(s => s.tiss.tussLookup.tabelas);

  return computed<IFormInputSelect>(() => ({
    label: 'Tabela',
    type: 'select',
    hideDetails: true,
    items: $tabelas.value.length
      ? ConstantsHelper.tiss.tabelas.filter(f =>
          $tabelas.value.includes(f.value as TissTabela),
        )
      : ConstantsHelper.tiss.tabelas,
  }));
}

function handleTabelaChange(tabela: TissTabela | null) {
  TissState.tussLookup.setSearch({ tabela });
}

function handleTextChange(text: string | null) {
  TissState.tussLookup.setSearch({ text });
}
