import {
  IAppState,
  IPacienteFormModel,
  IPacienteState,
  IPacienteImagemModel,
} from '@/typings';
import Vue from 'vue';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

const stateFn = (): IPacienteState => ({
  modelChanged: false,
  hasError: false,
  model: {
    id: null,
    createdAt: null,
    incompleto: true,
    parentes: [],
    convenios: [],
    geral: {
      nome: null,
      codigo: null,
      dataNascimento: null,
      sexo: null,
      email: null,
      cpf: null,
      rg: null,
      observacao: null,
      comoConheceu: null,
    },
    telefones: {
      celular: null,
      telefoneCasa: null,
      telefoneTrabalho: null,
      aceitaSms: true,
    },
    endereco: {
      enderecoCep: null,
      enderecoLogradouro: null,
      enderecoNumero: null,
      enderecoComplemento: null,
      enderecoBairro: null,
      enderecoCidade: null,
      enderecoUf: null,
      enderecoPais: 'Brasil',
    },
    dadosComplementares: {
      naturalidade: null,
      naturalidadeUf: null,
      nacionalidade: null,
      etnia: null,
      religiao: null,
      estadoCivil: null,
      escolaridade: null,
      profissao: null,
      obito: false,
      causaMortis: null,
    },
    outrasInformacoes: {
      cns: null,
      informacoesAdicionais: null,
    },
    imagem: {
      imagemId: null,
      url: null,
    },
  },
});

export const PacienteModule: Module<IPacienteState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IPacienteState>('paciente');

export class PacienteState {
  static resetModel() {
    commit(s => {
      Vue.set(s, 'model', stateFn().model);

      s.modelChanged = stateFn().modelChanged;
      s.hasError = stateFn().hasError;
    });
  }

  static setModel({
    model,
    modelChanged,
  }: {
    model: IPacienteFormModel;
    modelChanged: boolean;
  }) {
    commit(s => {
      Vue.set(s, 'model', model);

      s.modelChanged = modelChanged;
    });
  }

  static setId(id: string | null) {
    commit(s => {
      s.model.id = id;
    });
  }

  static setImagem({ imagemId: id, url }: IPacienteImagemModel) {
    commit(s => {
      s.model.imagem.imagemId = id;
      s.model.imagem.url = url;
    });
  }

  static setHasError(hasError: boolean) {
    commit(s => {
      s.hasError = hasError;
    });
  }
}
