import { useState } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { filterTimelineEvents } from '@/lib/helpers/models/prontuario/timelineFilter';
import { createComponent } from '@/lib/vue';
import { computed, SetupContext } from '@vue/composition-api';
import { TimelineBody } from './body/TimelineBody';
import { TimelineFilter } from './filter/TimelineFilter';

export const ProntuarioTimeline = createComponent({
  name: 'ProntuarioTimeline',
  setup(props, ctx) {
    const { $filteredEvents } = useComputeds(ctx);
    return () => (
      <div id="ProntuarioTimeline" class="flex flex-col">
        <TimelineFilter />

        <v-timeline align-top dense class="pt-4">
          {$filteredEvents.value.map(v => (
            <v-timeline-item key={v.id} small>
              <div slot="icon">{timelineIcon(v.dataInicial)}</div>

              <TimelineBody atendimento={v} />
            </v-timeline-item>
          ))}
        </v-timeline>
      </div>
    );
  },
});

export default ProntuarioTimeline;

function useComputeds(ctx: SetupContext) {
  const $state = useState(s => s.prontuario);

  const $filteredEvents = computed(() => filterTimelineEvents($state.value));

  return { $state, $filteredEvents };
}

function timelineIcon(dataInicial: string) {
  const date = DateHelpers.parse(dataInicial);
  if (!date) {
    return null;
  }

  return (
    <div id="timeline-icon">
      <div class="text-headline day">{date.day}</div>

      <div class="text-subtitle month">
        {date.toFormat('MMM/yyyy').replace('.', '')}
      </div>
    </div>
  );
}
