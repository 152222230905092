import { FormFields } from '@/components/form/fields/FormFields';
import { useValue } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import {
  IAgendamentoDeleteDialogModel,
  IAgendamentoDeleteDialogParams,
  IFormSchema,
  IFormSubmitted,
  IOptionsDialogOption,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { PropType } from 'vue';

interface IProps {
  params: IAgendamentoDeleteDialogParams;
}

export const AgendamentoDeleteDialog = createComponent<IProps>({
  name: 'AgendamentoDeleteDialog',
  props: {
    params: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, $model } = useData();

    const { deleteOne, deleteUntil, deleteAll } = useEvents({
      props,
      $form,
      $model,
    });

    const { $schema, options } = useComputeds({
      deleteOne,
      deleteUntil,
      deleteAll,
    });

    return () => (
      <div class="flex flex-col">
        <MotivoSelect
          bloqueado={props.params.bloqueado}
          form={$form.value}
          schema={$schema.value}
          v-model={$model.value}
        />

        <RecorrenciaOptions
          hasRecorrencia={props.params.hasRecorrencia}
          options={options}
        />

        <OneConfirmation
          hasRecorrencia={props.params.hasRecorrencia}
          deleteOne={deleteOne}
        />
      </div>
    );
  },
});

function useData() {
  const [$form] = useValue<IFormSubmitted>({ submitted: false });

  const [$model] = useValue<IAgendamentoDeleteDialogModel>({ motivo: null });

  return { $form, $model };
}

function useComputeds({
  deleteOne,
  deleteUntil,
  deleteAll,
}: Pick<
  ReturnType<typeof useEvents>,
  'deleteOne' | 'deleteUntil' | 'deleteAll'
>) {
  const $schema = computed<IFormSchema<IAgendamentoDeleteDialogModel>>(() => ({
    motivo: {
      label: 'Motivo do cancelamento',
      type: 'select',
      items: ConstantsHelper.agendamentoCancelamentoMotivos,
      noAttach: true,
      validations: {
        required: true,
      },
    },
  }));

  const options: IOptionsDialogOption[] = [
    {
      text: 'cancelar apenas este',
      handleClick: deleteOne,
      btnColor: MyTheme.red500,
    },
    {
      text: 'cancelar este e os próximos',
      handleClick: deleteUntil,
      btnColor: MyTheme.red500,
    },
    {
      text: 'cancelar todos',
      handleClick: deleteAll,
      btnColor: MyTheme.red500,
    },
    {
      text: 'não excluir',
      handleClick: DialogState.cancel,
    },
  ];

  return { $schema, options };
}

function useEvents({
  props,
  $form,
  $model,
}: {
  props: IProps;
} & Pick<ReturnType<typeof useData>, '$form' | '$model'>) {
  function submit(deleteMode: 'one' | 'all' | 'until') {
    const { bloqueado } = props.params;
    if (bloqueado) {
      return DialogState.confirm({
        confirmed: true,
        deleteMode,
        motivo: null,
      });
    }

    $form.value.submitted = true;

    const { motivo } = $model.value;
    if (motivo) {
      return DialogState.confirm({
        confirmed: true,
        deleteMode,
        motivo,
      });
    }
  }

  function deleteOne() {
    submit('one');
  }

  function deleteUntil() {
    submit('until');
  }

  function deleteAll() {
    submit('all');
  }

  return {
    submit,
    deleteOne,
    deleteUntil,
    deleteAll,
  };
}

const subtitle = (text: string) => (
  <div class="text-coolGray-600 text-subtitle">{text}</div>
);

const MotivoSelect = createComponent<
  {
    form: IFormSubmitted;
    schema: IFormSchema<IAgendamentoDeleteDialogModel>;
    value: IAgendamentoDeleteDialogModel;
    bloqueado: boolean;
  },
  {
    onInput: (value: IAgendamentoDeleteDialogModel) => void;
  }
>({
  name: 'AgendamentoDeleteMotivoSelect',
  props: {
    form: { type: Object as PropType<IFormSubmitted>, required: true },
    schema: {
      type: Object as PropType<IFormSchema<IAgendamentoDeleteDialogModel>>,
      required: true,
    },
    value: {
      type: Object as PropType<IAgendamentoDeleteDialogModel>,
      required: true,
    },
    bloqueado: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    function emitInput(value: IAgendamentoDeleteDialogModel) {
      ctx.emit('input', value);
    }

    return () =>
      !props.bloqueado && (
        <div class="flex flex-col px-4 pt-4">
          {subtitle('Selecione o motivo do cancelamento:')}

          <FormFields
            class="mt-2"
            form={props.form}
            schema={props.schema}
            value={props.value}
            onInput={emitInput}
          />
        </div>
      );
  },
});

const RecorrenciaOptions = createComponent<{
  hasRecorrencia: boolean;
  options: IOptionsDialogOption[];
}>({
  name: 'AgendamentoDeleteDialogRecorrenciaOptions',
  props: {
    hasRecorrencia: { type: Boolean, required: true },
    options: { type: Array, required: true },
  },
  setup(props, ctx) {
    return () =>
      props.hasRecorrencia && (
        <div class="flex flex-col px-4 pb-4">
          {subtitle('Este agendamento se repete em outras datas:')}

          <div class="flex flex-col mt-2">{props.options.map(optionBtn)}</div>
        </div>
      );
  },
});

const optionBtn = (opt: IOptionsDialogOption) => (
  <v-btn
    key={opt.text}
    color={opt.btnColor || 'primary'}
    outlined
    class="m-2"
    onClick={opt.handleClick}
  >
    {opt.text}
  </v-btn>
);

const OneConfirmation = createComponent<{
  hasRecorrencia: boolean;
  deleteOne: () => void;
}>({
  name: 'AgendamentoDeleteDialogOneConfirmation',
  props: {
    hasRecorrencia: { type: Boolean, required: true },
    deleteOne: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () =>
      !props.hasRecorrencia && (
        <div class="flex flex-col px-4 pb-4">
          {subtitle('Deseja cancelar este agendamento?')}

          <div class="flex flex-col mt-2">
            {optionBtn({
              text: 'Confirmar cancelamento',
              btnColor: MyTheme.red500,
              handleClick: props.deleteOne,
            })}

            {optionBtn({
              text: 'Não cancelar',
              handleClick: DialogState.cancel,
            })}
          </div>
        </div>
      );
  },
});
