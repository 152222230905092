import { IAuthClinicaState, IMyUserFragment } from '@/typings';

const AUTH_REFRESH_TOKEN = 'refresh_token';
const AUTH_ACCESS_TOKEN = 'access_token';
const AUTH_CLINICA = 'clinica';
const AUTH_USER = 'user';

export const TokenService = {
  accessToken: {
    get() {
      return localStorage.getItem(AUTH_ACCESS_TOKEN);
    },

    save(token: string | null) {
      return localStorage.setItem(AUTH_ACCESS_TOKEN, token || '');
    },

    clear() {
      return localStorage.removeItem(AUTH_ACCESS_TOKEN);
    },
  },

  refreshToken: {
    get() {
      return localStorage.getItem(AUTH_REFRESH_TOKEN);
    },

    save(token: string | null) {
      return localStorage.setItem(AUTH_REFRESH_TOKEN, token || '');
    },

    clear() {
      return localStorage.removeItem(AUTH_REFRESH_TOKEN);
    },
  },

  clinica: {
    get() {
      const clinica = localStorage.getItem(AUTH_CLINICA);
      if (!clinica) {
        return null;
      }

      return JSON.parse(clinica) as IAuthClinicaState;
    },

    save(clinica: IAuthClinicaState | null) {
      return localStorage.setItem(
        AUTH_CLINICA,
        (clinica && JSON.stringify(clinica)) || '',
      );
    },

    clear() {
      return localStorage.removeItem(AUTH_CLINICA);
    },
  },

  user: {
    get() {
      const user = localStorage.getItem(AUTH_USER);
      if (!user) {
        return null;
      }

      return JSON.parse(user) as IMyUserFragment;
    },

    save(user: IMyUserFragment | null) {
      return localStorage.setItem(
        AUTH_USER,
        (user && JSON.stringify(user)) || '',
      );
    },

    clear() {
      return localStorage.removeItem(AUTH_USER);
    },
  },

  clearAll() {
    TokenService.refreshToken.clear();
    TokenService.clinica.clear();
    TokenService.user.clear();
    TokenService.accessToken.clear();
  },
};
