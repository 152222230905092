import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { LookupsConfigs } from '@/lib/form/lookups';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IForm,
  IFormSchema,
  IProntuarioExameItemModel,
  IRequireField,
  TissTabela,
} from '@/typings';
import { computed, onMounted, SetupContext } from '@vue/composition-api';
import { FieldButtons } from '../shared/FieldButtons';
import { ObservacaoField } from '../shared/ObservacaoField';

interface IJsxProps {
  // model
  value?: IProntuarioExameItemModel[];
  form: IForm<any>;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (items: IProntuarioExameItemModel[]) => void;
}

export const ProntuarioExameItems = createComponent<IJsxProps, IEvents>({
  name: 'ProntuarioExameItems',
  props: {
    value: { type: Array, required: true },
    form: { type: Object, required: true },
  },
  setup(props: IProps, ctx) {
    const {
      showAddBtn,
      handleAdd,
      handleRemove,
      handleItemInput,
      handleToggleObs,
    } = useEvents(props, ctx);

    const { $showRemoveBtn, getSchema } = useComputeds({
      props,
      handleItemInput,
    });

    return () => (
      <PageSection divider>
        <FormHeader title="Exames e procedimentos" />

        <div class="flex flex-col">
          {props.value.map((item, index) => (
            <div class="flex flex-col" key={item.id}>
              {index > 0 && <v-divider class="mb-4 dashed" />}

              <div class="flex justify-start">
                <div class="flex flex-col" style={{ maxWidth: '680px' }}>
                  <FormFields
                    form={props.form}
                    schema={getSchema(index)}
                    value={item}
                    onInput={handleItemInput}
                  />

                  <ObservacaoField
                    class="-mt-3"
                    form={props.form}
                    value={item}
                    onInput={handleItemInput}
                    onToggle={() => handleToggleObs(item.id)}
                  />
                </div>

                <FieldButtons
                  showAdd={showAddBtn(index)}
                  showRemove={$showRemoveBtn.value}
                  onAdd={handleAdd}
                  onRemove={() => handleRemove(item.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </PageSection>
    );
  },
});

type ISchema = IFormSchema<Omit<IProntuarioExameItemModel, 'observacao'>>;

function useComputeds({
  props,
  handleItemInput,
}: {
  props: IProps;
  handleItemInput: () => void;
}) {
  function getSchema(index: number): ISchema {
    return {
      codigo: {
        label: 'Nome',
        type: 'autocomplete',
        itemLabel: 'descricao',
        itemValue: 'codigo',
        lookup: LookupsConfigs.tissProcedimento({
          tabela: TissTabela.TB_22,
          codigo: props.value[index].codigo,
          onModelChange(value) {
            props.value[index].nome = value ? value.nome : null;

            handleItemInput();
          },
        }),
        validations: { required: true },
        layout: {
          minWidth: 320,
          maxWidth: 500,
        },
      },
      quantidade: {
        label: 'Quantidade',
        type: 'number',
        integer: true,
        clearable: true,
        validations: {
          gt: 0,
          required: true,
        },
        layout: {
          width: 180,
        },
      },
    };
  }

  const $showRemoveBtn = computed(() => props.value.length > 1);

  return { getSchema, $showRemoveBtn };
}

function useEvents(props: IProps, ctx: SetupContext) {
  function showAddBtn(index: number) {
    return index + 1 === props.value.length && !!props.value[index].nome;
  }

  function handleAdd() {
    emitInput([
      ...props.value,
      {
        id: uuid(),
        nome: null,
        quantidade: null,
        codigo: null,
        observacao: null,
        hideObs: true,
      },
    ]);
  }

  function handleRemove(id: string | undefined) {
    emitInput(props.value.filter(v => v.id !== id));
  }

  function emitInput(items: IProntuarioExameItemModel[]) {
    ctx.emit('input', items);
  }

  function handleItemInput() {
    emitInput(props.value);
  }

  function handleToggleObs(id: string | undefined) {
    emitInput(
      props.value.map(v =>
        v.id === id
          ? {
              ...v,
              hideObs: !v.hideObs,
            }
          : v,
      ),
    );
  }

  onMounted(() => {
    if (props.value.length === 0) {
      handleAdd();
    }
  });

  return {
    showAddBtn,
    handleAdd,
    handleRemove,
    emitInput,
    handleItemInput,
    handleToggleObs,
  };
}
