import { store } from '@/store';
import { IAuthAccessTokenPayload, IAuthRefreshTokenPayload } from '@/typings';
import jwt_decode from 'jwt-decode';

export const getAccessToken = () => store.state.auth.accessToken;

function jwtDecode<T>(token: string) {
  return jwt_decode(token) as T | null;
}

export function getAccessTokenPayload(accessToken?: string | null) {
  const token = accessToken || store.state.auth.accessToken;

  return token ? jwtDecode<IAuthAccessTokenPayload>(token) : null;
}

export function getRefreshTokenPayload(refreshToken?: string | null) {
  const token = refreshToken || store.state.auth.refreshToken;

  return token ? jwtDecode<IAuthRefreshTokenPayload>(token) : null;
}

export function checkIsAccessTokenValidOrUndefined() {
  const payload = getAccessTokenPayload();

  if (!payload) {
    // isUndefined
    return true;
  }

  const dateInSeconds = new Date().getTime() / 1000;

  // Check isValid => whether the current time is past the
  // accessToken's expiry time
  return dateInSeconds < payload.exp;
}

export function checkIsRefreshTokenValid() {
  const payload = getRefreshTokenPayload();
  if (!payload) {
    return false;
  }

  const dateInSeconds = new Date().getTime() / 1000;

  // Check isValid => whether the current time is past the
  // refreshToken's expiry time
  return dateInSeconds < payload.exp;
}

export const checkHasUser = () => !!store.state.auth.user;
