import { TokenService } from '@/lib/auth/token';
import {
  IAppState,
  IAuthClinicaState,
  IAuthPayloadResult,
  IAuthState,
  IMyUserFragment,
} from '@/typings';
import Vue from 'vue';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../utils/commit';

export const stateFn = (): IAuthState => ({
  accessToken: null,
  refreshToken: null,
  user: null,
  clinica: null,
});

export const AuthModule: Module<IAuthState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IAuthState>('auth');

export class AuthState {
  static setClinica(clinica: IAuthClinicaState | null) {
    commit(s => {
      Vue.set(s, 'clinica', clinica);

      TokenService.clinica.save(clinica);
    });
  }

  static setClinicaSelect(allow: boolean) {
    commit(s => {
      if (!s.clinica) return;

      s.clinica.allowSelect = allow;
    });
  }

  static setAuthPayload(payload: IAuthPayloadResult | null) {
    AuthState.setAccessToken(payload ? payload.accessToken : null);

    AuthState.setRefreshToken(payload ? payload.refreshToken : null);

    AuthState.setUser(payload ? payload.user : null);
  }

  static setAccessToken(accessToken: string | null) {
    commit(s => {
      s.accessToken = accessToken;

      TokenService.accessToken.save(accessToken);
    });
  }

  static setRefreshToken(refreshToken: string | null) {
    commit(s => {
      s.refreshToken = refreshToken;

      TokenService.refreshToken.save(refreshToken);
    });
  }

  static setUser(user: IMyUserFragment | null) {
    commit(s => {
      Vue.set(s, 'user', user);

      TokenService.user.save(user);
    });
  }
}
