import { IDialogModel } from '@/typings';

export function getEditValue<T extends IDialogModel>(
  id: string | null | undefined,
  values: T[],
): T | null {
  if (!id) {
    return null;
  }
  const value = values.find(v => v.id === id);

  return value ? { ...value } : null;
}
