import { useValue } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { createComponent, watchRun } from '@/lib/vue';
import { IDataTableActions, IDataTableHeader, IItem } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { FormHeader } from '../typography/FormHeader';
import { getDataTableSlots } from './lib/getDataTableSlots';
import { SearchRow } from './SearchRow';

export type IDataTableProps = IProps;

interface IProps {
  headers: readonly IDataTableHeader[];
  items?: readonly any[] | null;
  selectedItems?: any[];
  showSelect?: boolean;
  loading?: boolean;
  showAll?: boolean;
  noSearch?: boolean;
  title?: string;
  actions?: IDataTableActions | null;
  noMarginBottom?: boolean;
  sortBy?: string;
  sortDesc?: boolean;
  draggable?: boolean;
  hideHeaders?: boolean;
  height?: number | string;
  noDataText?: string;
  clickableRow?: boolean;
  outlined?: boolean;
  noHover?: boolean;
}

interface IEvents {
  onSelect: (value: any[] | null) => void;
  onReorder: (items: any[]) => void;
  onRowClick: (item: any) => void;
}

interface IScopedSlots {
  [key: string]: { header: IDataTableHeader; item: any };
}

const rowsPerPageItems = [10, 25, 50, 100];

export const DataTable = createComponent<IProps, IEvents, IScopedSlots>({
  name: 'DataTable',
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    showSelect: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showAll: { type: Boolean, default: false },
    noSearch: { type: Boolean, default: false },
    noMarginBottom: { type: Boolean, default: false },
    title: String,
    actions: Object,
    sortBy: String,
    sortDesc: Boolean,
    draggable: { type: Boolean, default: false },
    hideHeaders: { type: Boolean, default: false },
    height: { type: [String, Number] },
    noDataText: { type: String, default: 'Nenhum registro encontrado' },
    clickableRow: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    noHover: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const [$searchValue] = useValue('');

    const { $headers, $search, $showFooter, $showEdit, $showRemove } =
      useComputeds(props);

    const {
      handleEdit,
      handleRemove,
      emitReorder,
      checkIsSelected,
      selectItem,
      events,
    } = useEvents(props, ctx);

    return () => (
      <v-sheet>
        <FormHeader title={props.title} />

        {$search.value && <SearchRow v-model={$searchValue.value} />}

        <v-data-table
          noDataText={props.noDataText}
          fixed-header
          headers={$headers.value}
          items={props.items || []}
          loading={props.loading}
          disablePagination={props.showAll}
          rowsPerPageItems={rowsPerPageItems}
          hideDefaultFooter={!$showFooter.value}
          hideDefaultHeader={props.hideHeaders}
          search={$searchValue.value}
          showSelect={props.showSelect}
          sortBy={props.sortBy}
          sortDesc={props.sortDesc}
          value={props.selectedItems}
          height={props.height}
          class={{
            'mb-4': !$showFooter.value && !props.noMarginBottom,
            'clickable-row': props.clickableRow,
            outlined: props.outlined,
            'no-hover': props.noHover,
          }}
          scopedSlots={getDataTableSlots({
            props,
            $headers,
            $showEdit,
            $showRemove,
            handleEdit,
            handleRemove,
            emitReorder,
            checkIsSelected,
            selectItem,
          })}
          {...events}
        />
      </v-sheet>
    );
  },
});

function useComputeds(props: IProps) {
  const $showEdit = computed(
    () => !!props.actions?.editRoute || !!props.actions?.edit,
  );

  const $showRemove = computed(() => !!props.actions?.remove);

  const $headers = computed<IDataTableHeader[]>(() => {
    const headers = [...props.headers];

    if ($showEdit.value || $showRemove.value) {
      headers.push({
        text: '',
        value: 'actions',
        sortable: false,
        width: $showEdit.value && $showRemove.value ? 120 : 80,
      });
    }

    if (props.draggable) {
      headers.push({
        text: '',
        value: 'draggable',
        sortable: false,
        width: 80,
      });
    }

    return headers
      .filter(f => !f.hide)
      .map(v => ({
        ...v,
        align: v.align || 'start',
      }));
  });

  const $search = computed(
    () =>
      !!props.items &&
      props.items?.length >= rowsPerPageItems[0] &&
      !props.noSearch,
  );

  const $showFooter = computed(
    () =>
      !props.showAll &&
      !!props.items &&
      props.items.length > rowsPerPageItems[0],
  );

  return {
    $showEdit,
    $showRemove,
    $headers,
    $search,
    $showFooter,
  };
}

export type IDataTableUseEventsReturn = ReturnType<typeof useEvents>;

function useEvents(props: IProps, ctx: SetupContext) {
  function handleEdit({ id, item }: { id: string; item: IItem }) {
    if (!props.actions) return;

    const action = props.actions.edit;
    const routeFn = props.actions.editRoute;

    if (action) {
      return action(id, item);
    } else if (routeFn) {
      return useRouter().push(routeFn(id, item));
    }
  }

  function handleRemove({ id, item }: { id: string; item: IItem }) {
    if (!props.actions) return;

    const action = props.actions.remove;

    if (action) return action(id, item);
  }

  function emitSelect(selectedItems: any[]) {
    ctx.emit('select', selectedItems);
  }

  function emitReorder(items: any[]) {
    ctx.emit('reorder', items);
  }

  function emitRowClick(item: any) {
    ctx.emit('rowClick', item);
  }

  function checkIsSelected(item: any) {
    return !!(props.selectedItems || []).find(f => f.id === item.id);
  }

  function selectItem(item: any) {
    const selected = props.selectedItems || [];

    const hasItem = !!selected.find(f => f.id === item.id);

    return hasItem
      ? emitSelect(selected.filter(f => f.id !== item.id))
      : emitSelect([...selected, item]);
  }

  watchRun(
    () => props.items,
    () => emitSelect([]),
    { deep: true },
  );

  const events = {
    on: {
      input: emitSelect,
      'click:row': emitRowClick,
    },
  };

  return {
    handleEdit,
    handleRemove,
    emitSelect,
    emitReorder,
    emitRowClick,
    checkIsSelected,
    selectItem,
    events,
  };
}
