import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const CidPage = () =>
  import(/* webpackChunkName: "cid" */ '@/modules/cid/pages/CidPage');
const CidCapituloPage = () =>
  import(/* webpackChunkName: "cid" */ '@/modules/cid/pages/CidCapituloPage');
const CidGrupoPage = () =>
  import(/* webpackChunkName: "cid" */ '@/modules/cid/pages/CidGrupoPage');

export const cidRoutes: IRouteConfig[] = routeGroup({
  prefix: 'cid',
  routes: [
    {
      path: '',
      component: CidPage,
    },

    ...routeGroup({
      prefix: 'capitulo',
      routes: [
        {
          path: ':capituloId',
          component: CidCapituloPage,
        },
        {
          path: ':capituloId/grupo/:grupoId',
          component: CidGrupoPage,
        },
      ],
    }),
  ],
});
