import { PageSection } from '@/components/page/PageSection';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { AgendamentoViewActions } from './actions';
import { AgendamentoViewHeader } from './AgendamentoViewHeader';

export const AgendamentoBloqueado = createComponent({
  name: 'AgendamentoBloqueado',
  setup(props, ctx) {
    return () => (
      <div class="flex flex-col">
        <AgendamentoViewHeader />

        <PageSection class="mt-4">
          <div class="flex">
            <v-btn
              text
              color="primary"
              onClick={AgendamentoViewActions.handleExcluir}
            >
              <v-icon left>{MyIcons.remove}</v-icon>
              Excluir
            </v-btn>

            <v-spacer />

            <v-btn
              outlined
              color="primary"
              onClick={AgendamentoViewActions.handleEditar}
            >
              <v-icon left>{MyIcons.edit}</v-icon>
              Editar
            </v-btn>
          </div>
        </PageSection>
      </div>
    );
  },
});
