import {
  IAutocompleteEvents,
  IAutocompleteProps,
  useAutocomplete,
} from '@/lib/composables/inputs/useAutocomplete';
import { useInput } from '@/lib/composables/inputs/useInput';
import { createComponent } from '@/lib/vue';
import { ref } from '@vue/composition-api';

export const InputAutocomplete = createComponent<
  IAutocompleteProps,
  IAutocompleteEvents
>({
  name: 'InputAutocomplete',
  props: {
    name: { type: String },
    value: { type: [String, Object] },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const $inputEl = ref<HTMLInputElement | null>(null);

    const {
      $searchValue,
      $items,
      $itemText,
      $itemValue,
      $loading,
      filter,
      $menuProps,
      $cache,
      $classObj,
      $returnObject,
      events,
      scopedSlots,
      $appendIcon,
    } = useAutocomplete({ props, ctx, $inputEl, isCombobox: false });

    return () => (
      <v-autocomplete
        ref={$inputEl}
        name={props.name}
        outlined
        hideSelected
        // attach
        clearable={!props.input.disabled}
        search-input={$searchValue.value}
        label={$label.value}
        items={$items.value}
        itemText={$itemText.value}
        itemValue={$itemValue.value}
        loading={$loading.value}
        value={props.value}
        errorMessages={props.errorMessages}
        required={$isRequired.value}
        prependInnerIcon={props.input.prependIcon}
        appendIcon={$appendIcon.value}
        filter={filter}
        menuProps={$menuProps.value}
        cacheItems={$cache.value}
        class={$classObj.value}
        disabled={props.input.disabled}
        returnObject={$returnObject.value}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        hideDetails={!!props.input.hideDetails}
        scopedSlots={scopedSlots()}
        {...events}
      />
    );
  },
});
