import { createComponent } from '@/lib/vue';
import { ITab } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  value?: number;
  items: ITab[];
}

interface IEvents {
  onInput: (value: number) => any;
}

export const Tabs = createComponent<IProps, IEvents>({
  name: 'Tabs',
  props: {
    value: { type: Number },
    items: { type: Array, required: true },
  },
  setup(props, ctx) {
    function handleChange(value: number) {
      ctx.emit('input', value);
    }

    return () => (
      <v-tabs
        id="Tabs"
        grow
        showArrows={true}
        iconsAndText
        value={props.value}
        onChange={handleChange}
      >
        {props.items.map(item => [tabs(item), tabsItems(item, ctx)])}
      </v-tabs>
    );
  },
});

export default Tabs;

const tabs = (item: ITab) => (
  <v-tab key={`tab${item.id}`}>
    {item.text}

    <v-icon>{item.icon}</v-icon>
  </v-tab>
);

const tabsItems = (item: ITab, ctx: SetupContext) => {
  const slotItem = ctx.slots[item.id]?.();

  return (
    <v-tab-item key={`tabItem${item.id}`}>{slotItem || item.text}</v-tab-item>
  );
};
