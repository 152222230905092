import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';

export async function validateDelete<T>(
  {
    text,
    aviso,
    requirePassword,
  }: {
    text: string;
    aviso?: string;
    requirePassword?: boolean;
  },
  cb: (password: string) => T,
) {
  let password = '';
  if (requirePassword) {
    const result = await DialogHelpers.system.password();
    if (!result.confirmed || !result.password) {
      return null;
    }
    password = result.password;
  }

  const { confirmed } = await DialogHelpers.system.delete({
    text,
    aviso,
  });
  if (!confirmed) {
    return null;
  }

  return cb(password);
}
