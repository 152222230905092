import { IAlertState } from '@/typings';

export const alertOnlineObj: IAlertState = {
  id: 'online',
  text: 'Sua <b>conexão com a internet</b> foi restabelecida',
  type: 'info',
  important: false,
};

export const alertOfflineObj: IAlertState = {
  id: 'offline',
  text: 'Você parece estar <b>sem conexão com a internet</b>. Para que o sistema funcione corretamente, você deve estar conectado.',
  type: 'error',
  important: true,
};
