import { handleError } from '@/lib/helpers/error';
import { toastSuccess } from '@/lib/helpers/toast';

import { AccountGraphql } from '@/graphql/system/account/AccountGraphql';

import { IContaPermissoesEnvioModel } from '@/typings';

export const AccountService = {
  async getPermissoesEnvio() {
    try {
      return await AccountGraphql.account();
    } catch (error) {
      handleError(error);
    }
  },

  async updatePermissoesEnvio({
    permiteEmail,
    permiteSms,
  }: IContaPermissoesEnvioModel) {
    try {
      const result = await AccountGraphql.updatePermissoesEnvio({
        data: {
          permiteEmail,
          permiteSms,
        },
      });

      if (result) {
        toastSuccess('Permissões de envio alteradas com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },
};
