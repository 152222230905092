export interface ITimelineFilterModel {
  filter: TimelineFilterItem | string | null;
}

export enum TimelineFilterItem {
  preAtendimento = 'preAtendimento',
  hipoteseDiagnostica = 'hipoteseDiagnostica',
  prescricoes = 'prescricoes',
  atestados = 'atestados',
  exames = 'exames',
  files = 'files',
}
