import CreateExameModeloMutation from '@/graphql/profissional/exameModelo/createExameModelo.graphql';
import DeleteExameModeloMutation from '@/graphql/profissional/exameModelo/deleteExameModelo.graphql';
import DeleteManyExamesModelosMutation from '@/graphql/profissional/exameModelo/deleteManyExamesModelos.graphql';
import ExameModeloQuery from '@/graphql/profissional/exameModelo/exameModelo.graphql';
import ExamesModelosQuery from '@/graphql/profissional/exameModelo/examesModelos.graphql';
import UpdateExameModeloMutation from '@/graphql/profissional/exameModelo/updateExameModelo.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateExameModeloMutation,
  ICreateExameModeloMutationVariables,
  IDeleteExameModeloMutation,
  IDeleteExameModeloMutationVariables,
  IDeleteManyExamesModelosMutation,
  IDeleteManyExamesModelosMutationVariables,
  IExameModeloQuery,
  IExameModeloQueryVariables,
  IExamesModelosQuery,
  IExamesModelosQueryVariables,
  IUpdateExameModeloMutation,
  IUpdateExameModeloMutationVariables,
} from '@/typings';

export const ExameModeloGraphql = {
  query: {
    ExameModeloQuery,
    ExamesModelosQuery,
  },

  async createExameModelo(variables: ICreateExameModeloMutationVariables) {
    return apolloClient
      .mutate<ICreateExameModeloMutation>({
        mutation: CreateExameModeloMutation,
        variables,
      })
      .then(({ data }) => data?.createExameModelo);
  },

  async deleteManyExamesModelos(
    variables: IDeleteManyExamesModelosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyExamesModelosMutation>({
        mutation: DeleteManyExamesModelosMutation,
        variables,
        refetchQueries: getRefetchQueries([ExamesModelosQuery]),
      })
      .then(({ data }) => data?.deleteManyExamesModelos);
  },

  async deleteExameModelo(variables: IDeleteExameModeloMutationVariables) {
    return apolloClient
      .mutate<IDeleteExameModeloMutation>({
        mutation: DeleteExameModeloMutation,
        variables,
        refetchQueries: getRefetchQueries([ExamesModelosQuery]),
      })
      .then(({ data }) => data?.deleteExameModelo);
  },

  async exameModelo(variables: IExameModeloQueryVariables) {
    return apolloClient
      .query<IExameModeloQuery>({
        query: ExameModeloQuery,
        variables,
      })
      .then(({ data }) => data?.exameModelo);
  },

  async examesModelos(variables: IExamesModelosQueryVariables) {
    return apolloClient
      .query<IExamesModelosQuery>({
        query: ExamesModelosQuery,
        variables,
      })
      .then(({ data }) => data?.examesModelos);
  },

  async updateExameModelo(variables: IUpdateExameModeloMutationVariables) {
    return apolloClient
      .mutate<IUpdateExameModeloMutation>({
        mutation: UpdateExameModeloMutation,
        variables,
      })
      .then(({ data }) => data?.updateExameModelo);
  },
};
