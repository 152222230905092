import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { useState } from '@/lib/composables';
import { IFormEvents } from '@/lib/composables/form/useFormConfig';
import { calcularIMC } from '@/lib/helpers/models/prontuario/prontuarioPreAtendimento';
import { createComponent, watchRun } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import {
  IForm,
  IFormSchema,
  IProntuarioPreAtendimentoFormModel,
  IProntuarioPreAtendimentoIMC,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { prontuarioPreAtendimentoSchema } from './shared/forms.utils';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {}

interface IEvents extends IFormEvents<any> {}

export const ProntuarioPreAtendimentoForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioPreAtendimentoForm',
  setup(props, ctx) {
    const { $model, $imc } = useComputeds();

    const { $form, $schema, emitSubmit } = usePreAtendimentoForm({
      $model,
      $imc,
      ctx,
    });

    return () => (
      <ProntuarioCardForm>
        <MyForm
          form={$form.value}
          noCancel
          noDelete
          noSummary
          onSubmit={emitSubmit}
        >
          <FormFields
            form={$form.value}
            schema={$schema.value}
            class="m-4"
            value={$model.value}
            onInput={ProntuarioState.model.preAtendimento.setModel}
          />
        </MyForm>
      </ProntuarioCardForm>
    );
  },
});

function useComputeds() {
  const $model = useState(s => s.prontuario.forms.preAtendimento.model);

  const { $imc } = useImc($model);

  return { $model, $imc };
}

function useImc($model: Ref<IProntuarioPreAtendimentoFormModel>) {
  const $imc = computed(() => calcularIMC($model.value));

  watchRun($imc, imc =>
    ProntuarioState.model.preAtendimento.setImc(imc?.value),
  );

  return { $imc };
}

function usePreAtendimentoForm({
  $model,
  $imc,
  ctx,
}: {
  $model: Ref<IProntuarioPreAtendimentoFormModel>;
  $imc: Ref<IProntuarioPreAtendimentoIMC | null>;
  ctx: SetupContext;
}) {
  const $form = useState<IForm<any>>(s => ({
    id: null,
    model: $model.value,
    submitted: s.prontuario.forms.preAtendimento.submitted,
    loading: false,
    validationErrors: [],
    page: {
      id: null,
      submitting: false,
      error: null,
    },
  }));

  const $schema = computed<IFormSchema<IProntuarioPreAtendimentoFormModel>>(
    () => prontuarioPreAtendimentoSchema($imc.value),
  );

  function emitSubmit() {
    ProntuarioState.model.preAtendimento.setSubmitted(true);

    ctx.emit('submit');
  }

  return { $form, $schema, emitSubmit };
}
