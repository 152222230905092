import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioPreAtendimentoForm } from '../../components/forms/ProntuarioPreAtendimentoForm';

export default createComponent({
  name: 'ProntuarioPreAtendimentoPage',
  setup(props, ctx) {
    return () => (
      <div class="pt-2">
        <ProntuarioPreAtendimentoForm
          onSubmit={() => ProntuarioService.cache.save()}
        />
      </div>
    );
  },
});
