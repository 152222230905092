import { permissionDeniedError } from '@/routes/utils';
import { userAtendeProfissional, userIsOwner } from '@/store/utils/auth';
import { NavigationGuard } from 'vue-router';

export const recepcionistaOrOwnerGuard: NavigationGuard = (to, _from, next) => {
  if (
    !userIsOwner(to.params.profissionalId) &&
    !userAtendeProfissional(to.params.profissionalId)
  ) {
    return permissionDeniedError(to, next);
  }

  return next();
};
