import { InputNumber } from '@/components/form/inputs/InputNumber';
import { formatMoney } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import { IAgendamentoFragment_transacoes } from '@/typings';
import { PropType } from 'vue';
import { IAgendamentoProcedimentosTableSchema } from '../AgendamentoProcedimentosTable';
import { AgendamentoProcedimentosTableRecebidoIcon } from './AgendamentoProcedimentosTableRecebidoIcon';

export const AgendamentoProcedimentosTableTotalsRow = createComponent({
  name: 'AgendamentoProcedimentosTableTotalsRow',
  props: {
    isNone: { type: Boolean, required: true },
    subtotal: { type: Number, required: true },
    isValores: { type: Boolean, required: true },
    desconto: { type: Number, required: true },
    valorTotal: { type: Number, required: true },
    schema: {
      type: Object as PropType<IAgendamentoProcedimentosTableSchema>,
      required: true,
    },
    transacoes: {
      type: Array as PropType<IAgendamentoFragment_transacoes[] | null>,
    },
    handleDescontoChange: {
      type: Function as PropType<(desconto: number | null) => void>,
      required: true,
    },
  },
  setup(props, ctx) {
    return () =>
      !props.isNone && (
        <div class="flex mr-2">
          <v-spacer />

          <div
            class="flex flex-col text-right text-body-sm"
            style="max-width: 200px"
          >
            <div class="flex my-2">
              <div>Subtotal:</div>

              <v-spacer />

              <div>{formatMoney(props.subtotal)}</div>
            </div>

            <div class="flex items-center">
              <div>Desconto:</div>

              <v-spacer />

              {props.isValores ? (
                <div>{formatMoney(props.desconto)}</div>
              ) : (
                <div class="dense-input" style="max-width: 116px">
                  <InputNumber
                    input={props.schema.desconto}
                    value={props.desconto}
                    onInput={props.handleDescontoChange}
                  />
                </div>
              )}
            </div>

            <v-divider class="my-2" />

            <div class="flex items-center font-bold">
              <div>Total:</div>

              <AgendamentoProcedimentosTableRecebidoIcon
                transacoes={props.transacoes}
                show={props.isValores}
              />
              <v-spacer />

              <div>{formatMoney(props.valorTotal)}</div>
            </div>
          </div>
        </div>
      );
  },
});
