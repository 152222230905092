import { IDateRangeModel, TransacaoTipo } from '..';

export interface IFinancasRelatoriosState {
  groupBy: FinancasRelatoriosGroupBy;
  periodo: IDateRangeModel | null;
  tipo: TransacaoTipo | null;
  where: IFinancasRelatoriosWhere;
  loading: boolean;
  showTable: boolean;
  data: IFinancasRelatoriosData[];
}

export interface IFinancasRelatoriosWhere {
  pago: boolean | null;
  categoriaId: string | null;
  contaFinanceiraId: string | null;
  centroCustoId: string | null;
  convenioId: string | null;
  procedimentoId: string | null;
  profissionalId: string | null;
  pacienteId: string | null;
}

export interface IFinancasRelatoriosResult {
  label: string | null;
  profissional?: string | null;
  value: number;
  percentage: number;
}

export interface IFinancasRelatoriosData {
  label: string | null;
  profissional: string | null;
  isTotal: boolean;
  value: number;
  percentage: number;
}

export enum FinancasRelatoriosGroupBy {
  CATEGORIA = 'CATEGORIA',
  CENTRO_CUSTO = 'CENTRO_CUSTO',
  CONTA_FINANCEIRA = 'CONTA_FINANCEIRA',
  CONVENIO = 'CONVENIO',
  PACIENTE = 'PACIENTE',
  PROFISSIONAL = 'PROFISSIONAL',
  PROCEDIMENTO = 'PROCEDIMENTO',
}
