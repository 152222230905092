import CentroCustoQuery from '@/graphql/financas/centroCusto/centroCusto.graphql';
import CentrosCustosQuery from '@/graphql/financas/centroCusto/centrosCustos.graphql';
import CreateCentroCustoMutation from '@/graphql/financas/centroCusto/createCentroCusto.graphql';
import DeleteCentroCustoMutation from '@/graphql/financas/centroCusto/deleteCentroCusto.graphql';
import DeleteManyCentrosCustosMutation from '@/graphql/financas/centroCusto/deleteManyCentrosCustos.graphql';
import UpdateCentroCustoMutation from '@/graphql/financas/centroCusto/updateCentroCusto.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICentroCustoQuery,
  ICentroCustoQueryVariables,
  ICentrosCustosQuery,
  ICentrosCustosQueryVariables,
  ICreateCentroCustoMutation,
  ICreateCentroCustoMutationVariables,
  IDeleteCentroCustoMutation,
  IDeleteCentroCustoMutationVariables,
  IDeleteManyCentrosCustosMutation,
  IDeleteManyCentrosCustosMutationVariables,
  IUpdateCentroCustoMutation,
  IUpdateCentroCustoMutationVariables,
} from '@/typings';

export const CentroCustoGraphql = {
  query: {
    CentroCustoQuery,
    CentrosCustosQuery,
  },

  async centroCusto(variables: ICentroCustoQueryVariables) {
    return apolloClient
      .query<ICentroCustoQuery>({
        query: CentroCustoQuery,
        variables,
      })
      .then(({ data }) => data?.centroCusto);
  },

  async centrosCustos(variables: ICentrosCustosQueryVariables) {
    return apolloClient
      .query<ICentrosCustosQuery>({
        query: CentrosCustosQuery,
        variables,
      })
      .then(({ data }) => data?.centrosCustos);
  },

  async createCentroCusto(variables: ICreateCentroCustoMutationVariables) {
    return apolloClient
      .mutate<ICreateCentroCustoMutation>({
        mutation: CreateCentroCustoMutation,
        variables,
      })
      .then(({ data }) => data?.createCentroCusto);
  },

  async deleteCentroCusto(variables: IDeleteCentroCustoMutationVariables) {
    return apolloClient
      .mutate<IDeleteCentroCustoMutation>({
        mutation: DeleteCentroCustoMutation,
        variables,
        refetchQueries: getRefetchQueries([CentrosCustosQuery]),
      })
      .then(({ data }) => data?.deleteCentroCusto);
  },

  async deleteManyCentrosCustos(
    variables: IDeleteManyCentrosCustosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyCentrosCustosMutation>({
        mutation: DeleteManyCentrosCustosMutation,
        variables,
        refetchQueries: getRefetchQueries([CentrosCustosQuery]),
      })
      .then(({ data }) => data?.deleteManyCentrosCustos);
  },

  async updateCentroCusto(variables: IUpdateCentroCustoMutationVariables) {
    return apolloClient
      .mutate<IUpdateCentroCustoMutation>({
        mutation: UpdateCentroCustoMutation,
        variables,
      })
      .then(({ data }) => data?.updateCentroCusto);
  },
};
