import { FileUploadGraphql } from '@/graphql/system/fileUpload/FileUploadGraphql';

export const FileUploadService = {
  async upload({
    name,
    type,
    file,
  }: {
    name?: string;
    type?: string;
    file: File;
  }) {
    const uploadUrl = await FileUploadGraphql.createUploadUrl({
      data: {
        name: name || file.name,
        type: type || file.type,
      },
    });

    if (uploadUrl) {
      const { status } = await FileUploadGraphql.uploadFile({
        ...uploadUrl,
        file,
      });

      return status === 204 ? uploadUrl.file.id : null;
    }

    return null;
  },
};
