import { useValue } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { IOptionsDialogOption, IOptionsDialogParams } from '@/typings';
import { Ref } from '@vue/composition-api';
import { PropType } from 'vue';

export const OptionsDialog = createComponent({
  name: 'OptionsDialog',
  props: {
    params: { type: Object as PropType<IOptionsDialogParams>, required: true },
  },
  setup(props, ctx) {
    const [$chosen, setChosen] = useValue<string | null>(null);

    async function handleClick(opt: IOptionsDialogOption) {
      setChosen(opt.text);

      if (!opt.async || !opt.handleClick) {
        DialogState.confirm();
      }

      if (opt.handleClick) {
        return opt.handleClick(DialogState);
      }
    }

    return () => {
      const { text, options } = props.params;

      return (
        <div class="flex flex-col">
          {subtitle(text)}

          <div class="flex flex-col px-4 pb-4">
            {options.map(opt => optionBtn({ opt, handleClick, $chosen }))}
          </div>
        </div>
      );
    };
  },
});

const subtitle = (text: string | null) =>
  text && <div class="p-4 text-coolGray-600 text-subtitle">{text}</div>;

const optionBtn = ({
  opt,
  handleClick,
  $chosen,
}: {
  opt: IOptionsDialogOption;
  handleClick: (option: IOptionsDialogOption) => any;
  $chosen: Ref<string | null>;
}) => (
  <v-btn
    block
    key={opt.text}
    color={opt.btnColor || 'primary'}
    outlined
    class="mb-4"
    loading={$chosen.value === opt.text}
    disabled={!!$chosen.value}
    onClick={() => handleClick(opt)}
  >
    {opt.text}
  </v-btn>
);
