import { DateHelpers } from '@/lib/helpers/date.helpers';
import ProntuarioAtestadosPage from '@/modules/prontuarios/pages/tabs/ProntuarioAtestadosPage';
import ProntuarioExamesPage from '@/modules/prontuarios/pages/tabs/ProntuarioExamesPage';
import ProntuarioFilesPage from '@/modules/prontuarios/pages/tabs/ProntuarioFilesPage';
import ProntuarioHipoteseDiagnosticaPage from '@/modules/prontuarios/pages/tabs/ProntuarioHipoteseDiagnosticaPage';
import ProntuarioHistoricoClinicoPage from '@/modules/prontuarios/pages/tabs/ProntuarioHistoricoClinicoPage';
import ProntuarioPreAtendimentoPage from '@/modules/prontuarios/pages/tabs/ProntuarioPreAtendimentoPage';
import ProntuarioPrescricoesPage from '@/modules/prontuarios/pages/tabs/ProntuarioPrescricoesPage';
import { IProntuarioPages } from '@/typings';

export function todayDate() {
  return DateHelpers.today().toISODate()!;
}

export const prontuarioPages: IProntuarioPages = {
  historicoClinico: {
    id: 'historio_clinico',
    title: 'Histórico clínico',
    component: ProntuarioHistoricoClinicoPage,
  },
  preAtendimento: {
    id: 'pre_atendimento',
    title: 'Pré-atendimento',
    component: ProntuarioPreAtendimentoPage,
  },
  exames: {
    id: 'exames',
    title: 'Solicitação de Exames',
    component: ProntuarioExamesPage,
  },
  prescricoes: {
    id: 'prescricoes',
    title: 'Prescrições',
    component: ProntuarioPrescricoesPage,
  },
  hipoteseDiagnostica: {
    id: 'hipotese_diagnostica',
    title: 'Hipótese diagnóstica',
    component: ProntuarioHipoteseDiagnosticaPage,
  },
  atestados: {
    id: 'atestados',
    title: 'Atestados',
    component: ProntuarioAtestadosPage,
  },
  files: {
    id: 'files',
    title: 'Imagens e anexos',
    component: ProntuarioFilesPage,
  },
};
