import { handleError } from '@/lib/helpers/error';

import { ServiceGraphql } from '@/graphql/service/ServiceGraphql';

export const CepService = {
  async cep(value: string) {
    try {
      return await ServiceGraphql.cep({ value });
    } catch (error) {
      handleError(error);
    }
  },
};
