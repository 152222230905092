import { AuthGraphql } from './auth/AuthGraphql';
import { ConfiguracaoGraphql } from './configuracao/ConfiguracaoGraphql';
import { ConvenioGraphql } from './convenio/ConvenioGraphql';
import { DashboardGraphql } from './dashboard/DashboardGraphql';
import { CategoriaFinanceiraGraphql } from './financas/categoriaFinanceira/CategoriaFinanceiraGraphql';
import { CentroCustoGraphql } from './financas/centroCusto/CentroCustoGraphql';
import { ContaFinanceiraGraphql } from './financas/contaFinanceira/ContaFinanceiraGraphql';
import { FinancasDashboardGraphql } from './financas/dashboard/FinancasDashboardGraphql';
import { FinancasRelatoriosGraphql } from './financas/relatorios/FinancasRelatoriosGraphql';
import { TransacaoGraphql } from './financas/transacao/TransacaoGraphql';
import { LookupsGraphql } from './lookups/LookupsGraphql';
import { PacienteGraphql } from './paciente/PacienteGraphql';
import { AgendamentoGraphql } from './profissional/agendamento/AgendamentoGraphql';
import { AtendimentoGraphql } from './profissional/atendimento/AtendimentoGraphql';
import { AtestadoModeloGraphql } from './profissional/atestadoModelo/AtestadoModeloGraphql';
import { ExameModeloGraphql } from './profissional/exameModelo/ExameModeloGraphql';
import { ListaEsperaGraphql } from './profissional/listaEspera/ListaEsperaGraphql';
import { PrescricaoModeloGraphql } from './profissional/prescricaoModelo/PrescricaoModeloGraphql';
import { ProcedimentoGraphql } from './profissional/procedimento/ProcedimentoGraphql';
import { ProntuarioSecaoGraphql } from './profissional/prontuarioSecao/ProntuarioSecaoGraphql';
import { ServiceGraphql } from './service/ServiceGraphql';
import { AccountGraphql } from './system/account/AccountGraphql';
import { AssinaturaGraphql } from './system/assinatura/AssinaturaGraphql';
import { ClinicaGraphql } from './system/clinica/ClinicaGraphql';
import { CobrancaGraphql } from './system/cobranca/CobrancaGraphql';
import { ContatoGraphql } from './system/contato/ContatoGraphql';
import { PlanoGraphql } from './system/plano/PlanoGraphql';
import { UserGraphql } from './system/user/UserGraphql';
import { TissGuiaConsultaGraphql } from './tiss/tissGuiaConsulta/TissGuiaConsultaGraphql';
import { TissGuiaHonorariosGraphql } from './tiss/tissGuiaHonorarios/TissGuiaHonorariosGraphql';
import { TissGuiaOdontologicaGraphql } from './tiss/tissGuiaOdontologica/TissGuiaOdontologicaGraphql';
import { TissGuiaSpSadtGraphql } from './tiss/tissGuiaSpSadt/TissGuiaSpSadtGraphql';
import { TissLoteGraphql } from './tiss/tissLote/TissLoteGraphql';
import { TissProcedimentoGraphql } from './tiss/tissProcedimento/TissProcedimentoGraphql';

export const QueryGraphql = {
  ...AuthGraphql.query,

  ...DashboardGraphql.query,

  ...ConfiguracaoGraphql.query,

  ...ConvenioGraphql.query,

  // Financas
  ...CategoriaFinanceiraGraphql.query,
  ...CentroCustoGraphql.query,
  ...ContaFinanceiraGraphql.query,
  ...FinancasDashboardGraphql.query,
  ...FinancasRelatoriosGraphql.query,
  ...TransacaoGraphql.query,

  ...LookupsGraphql.query,

  ...PacienteGraphql.query,

  // Profissional
  ...AgendamentoGraphql.query,
  ...AtendimentoGraphql.query,
  ...AtestadoModeloGraphql.query,
  ...ExameModeloGraphql.query,
  ...ListaEsperaGraphql.query,
  ...PrescricaoModeloGraphql.query,
  ...ProcedimentoGraphql.query,
  ...ProntuarioSecaoGraphql.query,

  ...ServiceGraphql.query,

  // System
  ...AccountGraphql.query,
  ...AssinaturaGraphql.query,
  ...ClinicaGraphql.query,
  ...CobrancaGraphql.query,
  ...ContatoGraphql.query,
  ...PlanoGraphql.query,
  ...UserGraphql.query,

  // Tiss
  ...TissGuiaConsultaGraphql.query,
  ...TissGuiaHonorariosGraphql.query,
  ...TissGuiaOdontologicaGraphql.query,
  ...TissGuiaSpSadtGraphql.query,
  ...TissLoteGraphql.query,
  ...TissProcedimentoGraphql.query,
};
