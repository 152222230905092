import { FileIcon } from '@/components/upload/files/FileIcon';
import { tooltipSize } from '@/lib/helpers/tooltip';
import { createComponent } from '@/lib/vue';
import { IAtendimentoFragment } from '@/typings';

interface IProps {
  atendimento: IAtendimentoFragment;
}

export const TimelineFiles = createComponent<IProps>({
  name: 'TimelineFiles',
  props: {
    atendimento: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { files } = props.atendimento;

      if (!files || !files.length) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h2 class="text-headline">Imagens e anexos</h2>

          <div class="flex justify-around my-4">
            {files.map(v => (
              <div class="flex-none px-6 my-4">
                <a
                  key={v.id}
                  href={v.url}
                  aria-label={v.description}
                  data-balloon-pos="down"
                  data-balloon-length={tooltipSize(v.description)}
                  target="_blank"
                  class="flex"
                >
                  <FileIcon
                    name={v.description}
                    type={v.contentType}
                    imageUrl={v.thumbnailUrl}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      );
    };
  },
});
