import { IInputItems } from '@/typings';

export const conselhoProfissional: IInputItems[] = [
  // Conselho Regional de Assistência Social
  { label: 'CRAS', value: '01' },
  //  Conselho Regional de Enfermagem
  { label: 'COREN', value: '02' },
  //  Conselho Regional de Farmácia
  { label: 'CRF', value: '03' },
  //  Conselho Regional de Fonoaudiologia
  { label: 'CRFA', value: '04' },
  // Conselho Regional de Fisioterapia e Terapia Ocupacional
  { label: 'CREFITO', value: '05' },
  //  Conselho Regional de Medicina
  { label: 'CRM', value: '06' },
  //  Conselho Regional de Nutrição
  { label: 'CRN', value: '07' },
  //  Conselho Regional de Odontologia
  { label: 'CRO', value: '08' },
  //  Conselho Regional de Psicologia
  { label: 'CRP', value: '09' },
  //  Outros Conselhos
  { label: 'OUT', value: '10' },
];
