import CreateProntuarioSecaoMutation from '@/graphql/profissional/prontuarioSecao/createProntuarioSecao.graphql';
import DeleteManyProntuariosSecoesMutation from '@/graphql/profissional/prontuarioSecao/deleteManyProntuariosSecoes.graphql';
import DeleteProntuarioSecaoMutation from '@/graphql/profissional/prontuarioSecao/deleteProntuarioSecao.graphql';
import ProntuarioSecaoQuery from '@/graphql/profissional/prontuarioSecao/prontuarioSecao.graphql';
import ProntuariosSecoesQuery from '@/graphql/profissional/prontuarioSecao/prontuariosSecoes.graphql';
import UpdateProntuarioSecaoMutation from '@/graphql/profissional/prontuarioSecao/updateProntuarioSecao.graphql';
import UpdateProntuarioSecaoOrdemMutation from '@/graphql/profissional/prontuarioSecao/updateProntuarioSecaoOrdem.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateProntuarioSecaoMutation,
  ICreateProntuarioSecaoMutationVariables,
  IDeleteManyProntuariosSecoesMutation,
  IDeleteManyProntuariosSecoesMutationVariables,
  IDeleteProntuarioSecaoMutation,
  IDeleteProntuarioSecaoMutationVariables,
  IProntuarioSecaoQuery,
  IProntuarioSecaoQueryVariables,
  IProntuariosSecoesQuery,
  IProntuariosSecoesQueryVariables,
  IUpdateProntuarioSecaoMutation,
  IUpdateProntuarioSecaoMutationVariables,
  IUpdateProntuarioSecaoOrdemMutation,
  IUpdateProntuarioSecaoOrdemMutationVariables,
} from '@/typings';

export const ProntuarioSecaoGraphql = {
  query: {
    ProntuarioSecaoQuery,
    ProntuariosSecoesQuery,
  },

  async createProntuarioSecao(
    variables: ICreateProntuarioSecaoMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateProntuarioSecaoMutation>({
        mutation: CreateProntuarioSecaoMutation,
        variables,
      })
      .then(({ data }) => data?.createProntuarioSecao);
  },

  async deleteManyProntuariosSecoes(
    variables: IDeleteManyProntuariosSecoesMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyProntuariosSecoesMutation>({
        mutation: DeleteManyProntuariosSecoesMutation,
        variables,
        refetchQueries: getRefetchQueries([ProntuariosSecoesQuery]),
      })
      .then(({ data }) => data?.deleteManyProntuariosSecoes);
  },

  async deleteProntuarioSecao(
    variables: IDeleteProntuarioSecaoMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteProntuarioSecaoMutation>({
        mutation: DeleteProntuarioSecaoMutation,
        variables,
        refetchQueries: getRefetchQueries([ProntuariosSecoesQuery]),
      })
      .then(({ data }) => data?.deleteProntuarioSecao);
  },

  async prontuarioSecao(variables: IProntuarioSecaoQueryVariables) {
    return apolloClient
      .query<IProntuarioSecaoQuery>({
        query: ProntuarioSecaoQuery,
        variables,
      })
      .then(({ data }) => data?.prontuarioSecao);
  },

  async prontuariosSecoes(variables: IProntuariosSecoesQueryVariables) {
    return apolloClient
      .query<IProntuariosSecoesQuery>({
        query: ProntuariosSecoesQuery,
        variables,
      })
      .then(({ data }) => data?.prontuariosSecoes);
  },

  async updateProntuarioSecao(
    variables: IUpdateProntuarioSecaoMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateProntuarioSecaoMutation>({
        mutation: UpdateProntuarioSecaoMutation,
        variables,
      })
      .then(({ data }) => data?.updateProntuarioSecao);
  },

  async updateProntuarioSecaoOrdem(
    variables: IUpdateProntuarioSecaoOrdemMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateProntuarioSecaoOrdemMutation>({
        mutation: UpdateProntuarioSecaoOrdemMutation,
        variables,
      })
      .then(({ data }) => data?.updateProntuarioSecaoOrdem);
  },
};
