import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';

interface IProps {
  macros?: string[] | null;
}

interface IEvents {
  onSelect: (macro: string) => void;
}

export const RichTextMacros = createComponent<IProps, IEvents>({
  name: 'RichTextMacros',
  props: {
    macros: { type: Array },
  },
  setup(props, ctx) {
    const $show = computed(() => !!props.macros?.length);

    const $computedMacros = computed(() =>
      (props.macros || []).map(v => `#${v.toUpperCase()}#`),
    );

    function handleSelect(macro: string) {
      ctx.emit('select', macro);
    }

    return () => {
      if (!$show.value) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <h3 class="mt-2 text-coolGray-600 text-subtitle">Macros</h3>

          <div class="flex flex-wrap">
            {$computedMacros.value.map(macro =>
              macroBtn({ macro, handleSelect }),
            )}
          </div>
        </div>
      );
    };
  },
});

const macroBtn = ({
  macro,
  handleSelect,
}: {
  macro: string;
  handleSelect: (macro: string) => void;
}) => (
  <v-btn
    key={macro}
    outlined
    rounded
    small
    color="accent"
    class="m-2"
    onClick={() => handleSelect(macro)}
  >
    {macro}
  </v-btn>
);
