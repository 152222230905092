import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { goHome } from '@/routes/utils';
import { store } from '@/store';
import { AgendaState } from '@/store/modules/agenda.store';
import { DialogState } from '@/store/modules/dialog.store';
import { getUser, userIsProfissionalSaude } from '@/store/utils/auth';
import {
  AgendamentoStatus,
  IAgendamentoFragment,
  IAgendamentoFragment_paciente,
} from '@/typings';
import { ConfiguracaoAgendaService } from '../../configuracao/configuracaoAgenda.service';
import { PacienteService } from '../../paciente.service';
import { AgendamentoService } from './agendamento.service';

const getState = () => store.state.agenda;

export const AgendamentoState = {
  async loadAgenda({ profissionalId }: { profissionalId: string }) {
    if (getState().config && getState().profissionalId) {
      return;
    }

    const config = await ConfiguracaoAgendaService.get(profissionalId);

    if (!config) return goHome();

    AgendaState.initialLoad({
      profissionalId,
      config,
    });
  },

  openViewDialog(agendamento: IAgendamentoFragment) {
    AgendaState.setAgendamento(agendamento);

    AgendamentoState.loadPacienteStats(agendamento.paciente);

    return DialogHelpers.agendamento.view(agendamento.bloqueado);
  },

  closeViewDialog() {
    AgendaState.setAgendamento(null);

    DialogState.cancel();
  },

  async loadPacienteStats(paciente: IAgendamentoFragment_paciente | null) {
    const { profissionalId } = getState();

    if (paciente && profissionalId) {
      const stats = await PacienteService.getStats({
        pacienteId: paciente.id,
        profissionalId,
      });

      AgendaState.setPacienteStats(stats);
    }
  },

  goToAtendimento(agendamento: IAgendamentoFragment | null) {
    const paciente = agendamento?.paciente;
    if (!agendamento || !paciente) {
      return;
    }

    const agendamentoId =
      agendamento.status !== AgendamentoStatus.PACIENTE_ATENDIDO
        ? agendamento.id
        : undefined;

    if (paciente.homonimo) {
      return DialogHelpers.paciente.homonimos({
        agendamento,
        goTo: 'prontuário',
      });
    } else if (paciente.incompleto) {
      return router.push(
        Routes.app.pacientes.edit.index(paciente.id, { agendamentoId }),
      );
    }

    return router.push(Routes.app.prontuario(paciente.id, { agendamentoId }));
  },

  async loadAgendamentosDia() {
    if (!userIsProfissionalSaude()) return;

    const result = await AgendamentoService.getAgendamentosDia(getUser().id);

    return AgendaState.setAgendamentosDia(result?.nodes || []);
  },
};
