import {
  IInputProps,
  useInput,
  useInputClearable,
} from '@/lib/composables/inputs/useInput';
import { createComponent, modifiers } from '@/lib/vue';
import { IFormInputSelect } from '@/typings';
import { computed, Ref, ref, SetupContext } from '@vue/composition-api';
import isString from 'lodash/isString';

interface IProps extends IInputProps {
  // model
  value?: string | string[] | boolean | null;
  input: IFormInputSelect;
}

interface IEvents {
  onInput: (value: string | boolean | string[] | null) => void;
}

export const InputSelect = createComponent<IProps, IEvents>({
  name: 'InputSelect',
  props: {
    value: { type: [String, Boolean, Array] },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const $inputEl = ref<HTMLInputElement | null>(null);

    const { events } = useEvents({ ctx, $inputEl });

    const { $menuProps, scopedSlots, $itemText, $itemValue } =
      useComputeds(props);

    return () => (
      <v-select
        ref={$inputEl}
        name={props.name}
        outlined
        label={$label.value}
        items={props.input.items || props.input.list}
        itemText={$itemText.value}
        itemValue={$itemValue.value}
        loading={props.input.loading}
        value={props.value}
        errorMessages={props.errorMessages}
        required={$isRequired.value}
        prependInnerIcon={mapPrependInnerIcon(props)}
        appendIcon={props.input.appendIcon}
        menuProps={$menuProps.value}
        disabled={props.input.disabled}
        readonly={props.input.readonly}
        hint={props.input.hint}
        persistentHint={props.input.persistentHint}
        placeholder={props.input.placeholder}
        persistentPlaceholder={props.input.persistentPlaceholder}
        hideDetails={!!props.input.hideDetails}
        // attach={!props.input.noAttach}
        clearable={props.input.clearable}
        multiple={props.input.multiple}
        // singleLine={!!props.input.placeholder}
        scopedSlots={scopedSlots()}
        {...events}
      />
    );
  },
});

function useComputeds(props: IProps) {
  const $menuProps = computed(() => {
    const inputMenu = props.input.menuProps || {};

    return { nudgeBottom: 8, ...inputMenu };
  });

  function scopedSlots() {
    if (props.input.multiple) return {};

    return { item: props.input.itemSlot?.() || defaultItemSlot(props) };
  }

  const $hasItems = computed(() => !!props.input.items);

  const $itemText = computed(() =>
    $hasItems.value ? props.input.itemLabel || 'label' : undefined,
  );
  const $itemValue = computed(() =>
    $hasItems.value ? props.input.itemValue || 'value' : undefined,
  );

  return { $menuProps, scopedSlots, $itemText, $itemValue };
}

function useEvents({
  ctx,
  $inputEl,
}: {
  ctx: SetupContext;
  $inputEl: Ref<HTMLInputElement | null>;
}) {
  const { handleClear } = useInputClearable({ ctx, $inputEl });

  function emitInput(value: string | boolean | null) {
    ctx.emit('input', value);
  }

  const events = {
    on: {
      'click:clear': handleClear,
      input: emitInput,
    },
    nativeOn: {
      keydown: modifiers.enter.prevent,
    },
  };

  return { events };
}

function defaultItemSlot(props: IProps) {
  const hasList = !!props.input.list;

  const labelKey = props.input.itemLabel || 'label';

  const iconKey = props.input.itemIcon || 'icon';

  return ({ item }: { item: undefined | null | object | string }) => {
    if (!item) return null;

    if (hasList || isString(item)) return <div>{item}</div>;

    return (
      <div class="flex flex-auto items-center space-x-4">
        {item[iconKey] && <v-icon>{item[iconKey]}</v-icon>}

        <div>{item[labelKey]}</div>
      </div>
    );
  };
}

function mapPrependInnerIcon(props: IProps): string | undefined {
  const { prependIcon, items, itemIcon } = props.input;

  return (
    prependIcon || items?.find(f => f.value === props.value)?.[itemIcon || '']
  );
}
