import { DateRangePresets } from '@/lib/constants/dateRangePresets';
import { createMutation, makeCommit } from '@/store/utils/commit';
import {
  FinancasRelatoriosGroupBy,
  IAppState,
  IDateRangeModel,
  IFinancasRelatoriosResult,
  IFinancasRelatoriosState,
  IFinancasRelatoriosWhere,
  TransacaoTipo,
} from '@/typings';
import { Module } from 'vuex';

const stateFn = (): IFinancasRelatoriosState => ({
  groupBy: FinancasRelatoriosGroupBy.CATEGORIA,
  periodo: null,
  tipo: null,
  where: {
    pago: null,
    categoriaId: null,
    contaFinanceiraId: null,
    centroCustoId: null,
    convenioId: null,
    procedimentoId: null,
    profissionalId: null,
    pacienteId: null,
  },
  loading: false,
  showTable: false,
  data: [],
});

export const FinancasRelatoriosModule: Module<
  IFinancasRelatoriosState,
  IAppState
> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IFinancasRelatoriosState>('financas/relatorios');

export class FinancasRelatoriosState {
  static setGroupBy(g: FinancasRelatoriosGroupBy) {
    commit(s => {
      s.groupBy = g;

      s.data = [];

      s.showTable = false;
    });
  }

  static setLoading(l = false) {
    commit(s => {
      s.loading = l;

      s.showTable = true;
    });
  }

  static setPeriodo(p?: IDateRangeModel | null) {
    commit(s => {
      s.periodo = p || DateRangePresets.ultimos30dias;

      s.data = [];

      s.showTable = false;
    });
  }

  static setTipo(t: TransacaoTipo) {
    commit(s => {
      s.tipo = t;

      s.data = [];
      s.loading = false;

      s.showTable = false;
    });
  }

  static setWhere(w?: IFinancasRelatoriosWhere) {
    commit(s => {
      s.where = w || stateFn().where;

      s.data = [];

      s.showTable = false;
    });
  }

  static setData(d: IFinancasRelatoriosResult[]) {
    commit(s => {
      const data = d.map(v => ({
        ...v,
        label: v.label || 'Indefinido',
        profissional: v.profissional || null,
        isTotal: false,
      }));

      s.data = data.length
        ? [
            ...data,
            {
              label: 'Total',
              profissional: null,
              isTotal: true,
              value: data.reduce((total, v) => (total += v.value), 0),
              percentage: 100,
            },
          ]
        : [];

      s.loading = false;

      s.showTable = true;
    });
  }
}
