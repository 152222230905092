import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { ConstantsHelper } from '@/lib/constants/helper';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IForm,
  IFormSchema,
  IProntuarioPrescricaoMedicamentoModel,
  IRequireField,
} from '@/typings';
import { computed, onMounted, Ref, SetupContext } from '@vue/composition-api';
import { FieldButtons } from '../shared/FieldButtons';
import { ObservacaoField } from '../shared/ObservacaoField';

interface IJsxProps {
  // model
  value?: IProntuarioPrescricaoMedicamentoModel[];
  form?: IForm<any>;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (medicamentos: IProntuarioPrescricaoMedicamentoModel[]) => void;
}

export const ProntuarioPrescricaoMedicamentos = createComponent<
  IJsxProps,
  IEvents
>({
  name: 'ProntuarioPrescricaoMedicamentos',
  props: {
    form: { type: Object, required: true },
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    const { $schema, $showRemoveBtn, showAddBtn } = useComputeds(props);

    const { handleAdd, handleRemove, handleInput, handleToggleObs } = useEvents(
      props,
      ctx,
    );

    return () => (
      <PageSection divider>
        <FormHeader title="Medicamentos" />

        {props.value.map((medicamento, index) =>
          fields({
            medicamento,
            index,
            props,
            $schema,
            $showRemoveBtn,
            showAddBtn,
            handleAdd,
            handleInput,
            handleRemove,
            handleToggleObs,
          }),
        )}
      </PageSection>
    );
  },
});

type ISchema = IFormSchema<
  Omit<IProntuarioPrescricaoMedicamentoModel, 'observacao'>
>;

function useComputeds(props: IProps) {
  const $schema = computed<ISchema>(() => ({
    medicamento: {
      label: 'Medicamento',
      type: 'text',
      validations: {
        required: true,
      },
    },
    quantidade: {
      label: 'Quantidade',
      type: 'combobox',
      list: ConstantsHelper.prescricaoQuantidades,
      clearable: true,
      validations: {
        required: true,
      },
      layout: {
        width: 200,
        sm: 6,
      },
    },
    posologia: {
      label: 'Posologia',
      type: 'text',
      layout: {
        sm: 6,
      },
    },
  }));

  const $showRemoveBtn = computed(() => props.value.length > 1);

  const showAddBtn = (index: number) =>
    index + 1 === props.value.length && !!props.value[index].medicamento;

  return {
    $schema,
    $showRemoveBtn,
    showAddBtn,
  };
}

function useEvents(props: IProps, ctx: SetupContext) {
  function handleAdd() {
    emitInput([
      ...props.value,
      {
        id: uuid(),
        medicamento: null,
        quantidade: null,
        posologia: null,
        observacao: null,
        hideObs: true,
      },
    ]);
  }

  function handleRemove(id: string | undefined) {
    emitInput(props.value.filter(v => v.id !== id));
  }

  function handleInput() {
    emitInput(props.value);
  }

  function handleToggleObs(id: string | undefined) {
    emitInput(
      props.value.map(v =>
        v.id === id
          ? {
              ...v,
              hideObs: !v.hideObs,
            }
          : v,
      ),
    );
  }

  function emitInput(medicamentos: IProntuarioPrescricaoMedicamentoModel[]) {
    ctx.emit('input', medicamentos);
  }

  onMounted(() => props.value.length === 0 && handleAdd());

  return {
    handleAdd,
    handleRemove,
    handleInput,
    handleToggleObs,
    emitInput,
  };
}

const fields = ({
  medicamento,
  index,
  props,
  $schema,
  $showRemoveBtn,
  showAddBtn,
  handleAdd,
  handleInput,
  handleRemove,
  handleToggleObs,
}: {
  medicamento: IProntuarioPrescricaoMedicamentoModel;
  index: number;
  props: IProps;
  $schema: Ref<ISchema>;
  $showRemoveBtn: Ref<boolean>;
  showAddBtn: (index: number) => boolean;
  handleAdd: () => void;
  handleInput: () => void;
  handleRemove: (id: string | undefined) => void;
  handleToggleObs: (id: string | undefined) => void;
}) => {
  const value = props.value[index];

  return (
    <div class="flex flex-col" key={medicamento.id}>
      {index > 0 && <v-divider class="mb-4 dashed" />}

      <div class="flex justify-start max-w-xl">
        <div class="flex flex-col w-5/6">
          <FormFields
            form={props.form}
            schema={$schema.value}
            value={value}
            onInput={handleInput}
          />

          <ObservacaoField
            class="-mt-2"
            form={props.form}
            value={value}
            onInput={handleInput}
            onToggle={() => handleToggleObs(value.id)}
          />
        </div>

        <FieldButtons
          showAdd={showAddBtn(index)}
          showRemove={$showRemoveBtn.value}
          onAdd={handleAdd}
          onRemove={() => handleRemove(medicamento.id)}
        />
      </div>
    </div>
  );
};
