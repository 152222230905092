import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { ExpandTransition } from '@/components/utils/ExpandTransition';
import {
  IFormEvents,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IFormSchema, ITimelineObservacaoFormModel } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  hide: boolean;
}

interface IEvents extends IFormEvents<ITimelineObservacaoFormModel> {}

export const TimelineObservacaoForm = createComponent<IProps, IEvents>({
  name: 'TimelineObservacaoForm',
  props: {
    hide: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    const { $form, setFormModel, $schema, emitSubmit, emitCancel } =
      useObservacaoForm(ctx);

    const { handleCancel, handleSubmit } = useEvents({
      setFormModel,
      emitCancel,
      emitSubmit,
    });

    return () => (
      <ExpandTransition hide={props.hide}>
        <MyForm
          form={$form.value}
          noDelete
          noSummary
          noDivider
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        >
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </MyForm>
      </ExpandTransition>
    );
  },
});

function useObservacaoForm(ctx: SetupContext) {
  return useFormConfig<
    ITimelineObservacaoFormModel,
    IFormSchema<ITimelineObservacaoFormModel>
  >({
    initialValue: { observacao: null },
    mapSchema: () => ({
      observacao: {
        label: 'Observação',
        type: 'rich-text',
      },
    }),
    ctx,
  });
}

function useEvents({
  emitCancel,
  emitSubmit,
  setFormModel,
}: {
  emitCancel: () => void;
  emitSubmit: () => Promise<void>;
  setFormModel: (model: ITimelineObservacaoFormModel) => void;
}) {
  function handleCancel() {
    setFormModel({ observacao: null });
    emitCancel();
  }

  function handleSubmit() {
    emitSubmit();
    setFormModel({ observacao: null });
  }

  return { handleCancel, handleSubmit };
}
