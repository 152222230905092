import { IInputItems } from '@/typings';

export enum TissSimNao {
  SIM = 'S',
  NAO = 'N',
}

export const simNao: IInputItems[] = [
  { label: 'SIM', value: TissSimNao.SIM },
  { label: 'NÃO', value: TissSimNao.NAO },
];
