import { AuthGraphql } from '@/graphql/auth/AuthGraphql';
import { Validate } from '@/lib/helpers/error';
import { authFailed, setTokensAndRedirect } from '@/lib/services/auth/utils';
import { AppState } from '@/store/modules/root.store';
import { IAuthSignUpPart1Model, IAuthSignUpPart2Model } from '@/typings';

export const AuthSignUpService = {
  async signUpPart1({ nome, email, password }: IAuthSignUpPart1Model) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.signUpPart1({
        data: {
          nome: Validate.require(nome, 'nome'),
          email: Validate.require(email, 'email'),
          password: Validate.require(password, 'password'),
        },
      });

      setTokensAndRedirect(result);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },

  async signUpPart2({
    nome: { nome, tratamento },
    profissao: { profissao },
    profissionalSaude: { cbo, conselho, conselhoUf, conselhoRegistro },
    clinica: { telefoneClinica },
  }: IAuthSignUpPart2Model) {
    try {
      AppState.loadingOn();

      const result = await AuthGraphql.signUpPart2({
        data: {
          nome: Validate.require(nome, 'nome'),
          tratamento,
          profissao: Validate.require(profissao, 'profissao'),
          cbo,
          conselho,
          conselhoUf,
          conselhoRegistro,
          telefoneClinica: Validate.require(telefoneClinica, 'telefoneClinica'),
        },
      });

      setTokensAndRedirect(result);
    } catch (error) {
      AppState.loadingOff();

      authFailed(error);
    }
  },
};
