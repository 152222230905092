import {
  IFormSchema,
  IProntuarioPreAtendimentoFormModel,
  IProntuarioPreAtendimentoIMC,
} from '@/typings';

export function prontuarioPreAtendimentoSchema(
  imc: IProntuarioPreAtendimentoIMC | null,
): IFormSchema<IProntuarioPreAtendimentoFormModel> {
  return {
    peso: {
      label: 'Peso',
      type: 'number',
      suffix: 'kg',
      decimalPlaces: 1,
      maxLength: 5,
      layout: {
        width: 220,
        sm: 4,
      },
      validations: {
        gt: 0,
        lte: 999,
      },
    },
    altura: {
      label: 'Altura',
      type: 'number',
      suffix: 'm',
      maxLength: 5,
      layout: {
        width: 220,
        sm: 4,
      },
      validations: {
        gt: 1,
        lte: 3,
      },
    },
    imc: {
      label: 'IMC',
      type: 'number',
      readonly: true,
      decimalPlaces: 1,
      hint: imc?.status,
      persistentHint: !!imc,
      layout: {
        width: 180,
      },
    },
    pressaoArterialSistolica: {
      label: 'PA Sistólica',
      type: 'number',
      integer: true,
      suffix: 'mmHg',
      layout: {
        sm: 6,
      },
      validations: {
        gt: 0,
        lte: 300,
      },
    },
    pressaoArterialDiastolica: {
      label: 'PA Diastólica',
      type: 'number',
      integer: true,
      suffix: 'mmHg',
      layout: {
        sm: 6,
      },
      validations: {
        gt: 0,
        lte: 200,
      },
    },
    frequenciaCardiaca: {
      label: 'Frequência Cardíaca',
      type: 'number',
      integer: true,
      suffix: 'bpm',
      maxLength: 3,
      layout: {
        sm: 6,
      },
      validations: {
        gt: 0,
        lte: 300,
      },
    },
    frequenciaRespiratoria: {
      label: 'Frequência Respiratória',
      type: 'number',
      integer: true,
      suffix: 'mrm',
      maxLength: 3,
      layout: {
        sm: 6,
      },
      validations: {
        gt: 0,
        lte: 100,
      },
    },
    temperatura: {
      label: 'Temperatura',
      type: 'number',
      suffix: 'ºC',
      decimalPlaces: 1,
      maxLength: 5,
      layout: {
        sm: 4,
      },
    },
    glicemiaCapilar: {
      label: 'Glicemia capilar',
      type: 'number',
      integer: true,
      suffix: 'mg/dl',
      maxLength: 3,
      layout: {
        minWidth: 210,
        sm: 4,
      },
      validations: {
        gt: 0,
        lte: 999,
      },
    },
    dataUltimaMenstruacao: {
      label: 'DUM',
      type: 'date',
      layout: { sm: 4 },
    },
    observacao: {
      label: 'Observação',
      type: 'textarea',
    },
  };
}
