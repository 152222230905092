import { formatMoney } from '@/lib/form';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IAgendamentoFragment_transacoes } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  transacoes?: IAgendamentoFragment_transacoes[] | null;
  show: boolean;
}

export const AgendamentoProcedimentosTableRecebidoIcon =
  createComponent<IProps>({
    name: 'AgendamentoProcedimentosTableRecebidoIcon',
    props: {
      transacoes: { type: Array },
      show: { type: Boolean, default: true },
    },
    setup(props, ctx) {
      const { $icon } = useComputeds(props);

      return () =>
        $icon.value && (
          <span
            class="ml-2"
            aria-label={$icon.value.tooltip}
            data-balloon-pos="up"
            data-balloon-wrap
          >
            <v-icon size={16} color={$icon.value.color}>
              {$icon.value.icon}
            </v-icon>
          </span>
        );
    },
  });

function useComputeds(props: IProps) {
  const $icon = computed(() => {
    if (!props.transacoes || !props.transacoes.length || !props.show) {
      return null;
    } else if (props.transacoes.length > 1) {
      const result = calcPagamentos(props.transacoes);

      const pago = `Valor pago: ${formatMoney(result.pago)}`;
      const pagar = `Valor a pagar: ${formatMoney(result.pagar)}`;
      const vencido = `Valor vencido: ${formatMoney(result.vencido)}`;

      return {
        icon: MyIcons.information,
        color: MyTheme.gray600,
        tooltip: `${pago} \n ${pagar} \n ${vencido}`,
      };
    }
    const transacao = props.transacoes[0];

    if (transacao.pago) {
      return {
        icon: MyIcons.transacaoPaid,
        color: MyTheme.green400,
        tooltip: 'Recebido',
      };
    }

    return {
      icon: MyIcons.transacaoNotPaid,
      color: MyTheme.red400,
      tooltip: 'Não recebido',
    };
  });

  return { $icon };
}

function calcPagamentos(transacoes: IAgendamentoFragment_transacoes[]) {
  return transacoes.reduce(
    (obj, { pago, valor, vencimento }) => {
      if (pago) {
        return { ...obj, pago: obj.pago + valor };
      }
      const isVencido = new Date(vencimento) < new Date();
      if (isVencido) {
        return { ...obj, vencido: obj.vencido + valor };
      }
      return { ...obj, pagar: obj.pagar + valor };
    },
    {
      pago: 0,
      pagar: 0,
      vencido: 0,
    },
  );
}
