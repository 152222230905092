import { apolloClient } from '@/plugins/apollo';

import AccountQuery from '@/graphql/system/account/account.graphql';
import UpdatePermissoesEnvioMutation from '@/graphql/system/account/updatePermissoesEnvio.graphql';

import {
  IAccountQuery,
  IUpdatePermissoesEnvioMutation,
  IUpdatePermissoesEnvioMutationVariables,
} from '@/typings';

export const AccountGraphql = {
  query: {
    AccountQuery,
  },

  async account() {
    return apolloClient
      .query<IAccountQuery>({ query: AccountQuery })
      .then(({ data }) => data?.account);
  },

  async updatePermissoesEnvio(
    variables: IUpdatePermissoesEnvioMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdatePermissoesEnvioMutation>({
        mutation: UpdatePermissoesEnvioMutation,
        variables,
      })
      .then(({ data }) => data?.updatePermissoesEnvio);
  },
};
