import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { getUser } from '@/store/utils/auth';
import {
  IProntuarioExameItemModel,
  IProntuarioPrescricaoMedicamentoModel,
} from '@/typings';
import { ExameModeloService } from '../../exameModelo.service';
import { PrescricaoModeloService } from '../../prescricaoModelo.service';

export const ProntuarioModelo = {
  async saveExame(exameItems: IProntuarioExameItemModel[]) {
    const { confirmed, ...model } = await DialogHelpers.prontuario.salvarModelo(
      exameItems,
    );

    if (confirmed) {
      ExameModeloService.create(
        {
          profissionalId: getUser().id,
          model,
        },
        false,
      );
    }
  },

  async savePrescricao(medicamentos: IProntuarioPrescricaoMedicamentoModel[]) {
    const { confirmed, nome, items } =
      await DialogHelpers.prontuario.salvarModelo(medicamentos);

    if (confirmed) {
      PrescricaoModeloService.create(
        {
          profissionalId: getUser().id,
          model: {
            nome,
            medicamentos: items,
          },
        },
        false,
      );
    }
  },
};
