import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { IFormDialogParams, IModel } from '@/typings';
import { h } from '@vue/composition-api';
import { PropType } from 'vue';

export const FormDialog = createComponent({
  name: 'FormDialog',
  props: {
    params: {
      type: Object as PropType<IFormDialogParams>,
      required: true,
    },
  },
  setup(props, ctx) {
    function handleSubmit(model: IModel) {
      DialogState.confirm();

      return props.params.onSubmit(model);
    }

    function handleCancel() {
      DialogState.cancel();

      if (props.params.onCancel) {
        props.params.onCancel();
      }
    }

    return () => {
      const { form, initialValue, page, onSubmit, onCancel, ...params } =
        props.params;

      return h(form, {
        props: { page, initialValue, ...params },
        on: {
          submit: handleSubmit,
          cancel: handleCancel,
        },
      });
    };
  },
});
