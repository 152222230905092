import { AuthLayout } from '@/routes/modules/layout';

import { IRouteConfig } from '@/typings';

const ConfirmarAgendamentoPage = () =>
  import(
    /* webpackChunkName: "confirmar-agendamento" */ '@/modules/auth/pages/ConfirmarAgendamentoPage'
  );

export const confirmarAgendamentoRoutes: IRouteConfig[] = [
  {
    path: '/agendamento',
    component: AuthLayout,
    children: [
      {
        path: 'confirmar/:token',
        component: ConfirmarAgendamentoPage,
      },
      {
        path: 'cancelar/:token',
        component: ConfirmarAgendamentoPage,
      },
    ],
  },
];
