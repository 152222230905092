import { IModel } from '@/typings';

export * from './guiaConsulta.t';
export * from './guiaHonorarios.t';
export * from './guiaOdontologica.t';
export * from './guiaSpSadt.t';
export * from './lote.t';
export * from './tissProcedimento.t';

export enum TissGuiaTipo {
  CONSULTA = 'CONSULTA',
  SP_SADT = 'SP_SADT',
  HONORARIOS = 'HONORARIOS',
  ODONTOLOGICA = 'ODONTOLOGICA',
}

export interface ITissGuiaProcedimentoModel extends IModel {
  id: string;
  tabela: string;
  codigo: string;
  descricao: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
}
