import flatten from 'lodash/flatten';
import sample from 'lodash/sample';

export const materialColorPalette = [
  // red
  ['#B71C1C', '#D32F2F', '#F44336', '#E57373', '#FFCDD2'],
  // pink
  ['#880E4F', '#C2185B', '#E91E63', '#F06292', '#F8BBD0'],
  // purple
  ['#4A148C', '#7B1FA2', '#9C27B0', '#BA68C8', '#E1BEE7'],
  // deepPurple
  ['#311B92', '#512DA8', '#673AB7', '#9575CD', '#D1C4E9'],
  // indigo
  ['#1A237E', '#303F9F', '#3F51B5', '#7986CB', '#C5CAE9'],
  // blue
  ['#0D47A1', '#1976D2', '#2196F3', '#64B5F6', '#BBDEFB'],
  // lightBlue
  ['#01579B', '#0288D1', '#03A9F4', '#4FC3F7', '#B3E5FC'],
  // cyan
  ['#006064', '#0097A7', '#00BCD4', '#4DD0E1', '#B2EBF2'],
  // teal
  ['#004D40', '#0E7C86', '#009688', '#4DB6AC', '#B2DFDB'],
  // green
  ['#1B5E20', '#388E3C', '#4CAF50', '#81C784', '#C8E6C9'],
  // lightGreen
  ['#33691E', '#689F38', '#8BC34A', '#AED581', '#DCEDC8'],
  // lime
  ['#827717', '#AFB42B', '#CDDC39', '#DCE775', '#F0F4C3'],
  // yellow
  ['#F57F17', '#FBC02D', '#FFEB3B', '#FFF176', '#FFF9C4'],
  // amber
  ['#FF6F00', '#FFA000', '#FFC107', '#FFD54F', '#FFECB3'],
  // orange
  ['#E65100', '#F57C00', '#FF9800', '#FFB74D', '#FFE0B2'],
  // deepOrange
  ['#BF360C', '#E64A19', '#FF5722', '#FF8A65', '#FFCCBC'],
  // brown
  ['#3E2723', '#5D4037', '#795548', '#A1887F', '#D7CCC8'],
  // blueGrey
  ['#263238', '#455A64', '#607D8B', '#90A4AE', '#CFD8DC'],
  // black
  ['#000000'],
];

export function randomColor() {
  const allColors = flatten(materialColorPalette);

  return sample(allColors) || allColors[0];
}

// colors from https://github.com/nagix/chartjs-plugin-colorschemes/blob/master/src/colorschemes/colorschemes.tableau.js
export const ChartColorPalletes = {
  tableau20: [
    '#4E79A7',
    '#A0CBE8',
    '#F28E2B',
    '#FFBE7D',
    '#59A14F',
    '#8CD17D',
    '#B6992D',
    '#F1CE63',
    '#499894',
    '#86BCB6',
    '#E15759',
    '#FF9D9A',
    '#79706E',
    '#BAB0AC',
    '#D37295',
    '#FABFD2',
    '#B07AA1',
    '#D4A6C8',
    '#9D7660',
    '#D7B5A6',
  ],
  classic20: [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5',
  ],
  hueCircle19: [
    '#1ba3c6',
    '#2cb5c0',
    '#30bcad',
    '#21B087',
    '#33a65c',
    '#57a337',
    '#a2b627',
    '#d5bb21',
    '#f8b620',
    '#f89217',
    '#f06719',
    '#e03426',
    '#f64971',
    '#fc719e',
    '#eb73b3',
    '#ce69be',
    '#a26dc2',
    '#7873c0',
    '#4f7cba',
  ],
};
