import { TissLoteGraphql } from '@/graphql/tiss/tissLote/TissLoteGraphql';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import {
  IServiceOnSuccessArgs,
  ITissLoteFormModel,
  TissLoteDataInput,
  TissLoteTipo,
} from '@/typings';

export const TissLoteService = {
  async getById(id: string) {
    try {
      return await TissLoteGraphql.tissLote({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    profissionalId,
    model,
  }: {
    model: ITissLoteFormModel;
    profissionalId: string;
  }) {
    try {
      const result = await TissLoteGraphql.createTissLote({
        profissionalId: Validate.require(profissionalId, 'profissionalId'),
        data: mapTissLoteDataInput(model),
      });

      onSuccess({ result, msg: 'criado' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete({ text: 'este lote' }, async () => {
        const result = await TissLoteGraphql.deleteTissLote({ id });

        onSuccess({ result, msg: 'excluído' });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async update(id: string, model: ITissLoteFormModel) {
    try {
      const result = await TissLoteGraphql.updateTissLote({
        id: Validate.require(id, 'id'),
        data: mapTissLoteDataInput(model),
      });

      onSuccess({ result, msg: 'alterado' });
    } catch (error) {
      handleError(error);
    }
  },
};

function mapTissLoteDataInput({
  lote: {
    tipo,
    prestadorCodigoNaOperadora,
    registroANS,
    numeroLote,
    versao,
    convenioId,
  },
  guiasIds,
}: ITissLoteFormModel): TissLoteDataInput {
  return {
    tipo,
    prestadorCodigoPrestadorNaOperadora: Validate.require(
      prestadorCodigoNaOperadora,
      'prestadorCodigoNaOperadora',
    ),
    // prestadorCNPJ,
    // prestadorCPF,
    registroANS: Validate.require(registroANS, 'registroANS'),
    numeroLote: Validate.require(numeroLote, 'numeroLote'),
    versao: Validate.require(versao, 'versao'),
    convenioId: Validate.require(convenioId, 'convenioId'),
    guiasConsultasIds: tipo === TissLoteTipo.GUIA_CONSULTA ? guiasIds : null,
    guiasHonorariosIds: tipo === TissLoteTipo.GUIA_CONSULTA ? guiasIds : null,
    guiasSpSadtIds: tipo === TissLoteTipo.GUIA_CONSULTA ? guiasIds : null,
    guiasOdontologicasIds:
      tipo === TissLoteTipo.GUIA_CONSULTA ? guiasIds : null,
  };
}

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    const { profissionalId } = useRouteParams();

    router.push(Routes.app.tiss(profissionalId).lotes.index);

    toastSuccess(`Lote ${msg} com sucesso`);
  }
}
