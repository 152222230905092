import AtestadoModeloQuery from '@/graphql/profissional/atestadoModelo/atestadoModelo.graphql';
import AtestadosModelosQuery from '@/graphql/profissional/atestadoModelo/atestadosModelos.graphql';
import CreateAtestadoModeloMutation from '@/graphql/profissional/atestadoModelo/createAtestadoModelo.graphql';
import DeleteAtestadoModeloMutation from '@/graphql/profissional/atestadoModelo/deleteAtestadoModelo.graphql';
import DeleteManyAtestadosModelosMutation from '@/graphql/profissional/atestadoModelo/deleteManyAtestadosModelos.graphql';
import UpdateAtestadoModeloMutation from '@/graphql/profissional/atestadoModelo/updateAtestadoModelo.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  IAtestadoModeloQuery,
  IAtestadoModeloQueryVariables,
  IAtestadosModelosQuery,
  ICreateAtestadoModeloMutation,
  ICreateAtestadoModeloMutationVariables,
  IDeleteAtestadoModeloMutation,
  IDeleteAtestadoModeloMutationVariables,
  IDeleteManyAtestadosModelosMutation,
  IDeleteManyAtestadosModelosMutationVariables,
  IUpdateAtestadoModeloMutation,
  IUpdateAtestadoModeloMutationVariables,
} from '@/typings';

export const AtestadoModeloGraphql = {
  query: {
    AtestadoModeloQuery,
    AtestadosModelosQuery,
  },

  async atestadoModelo(variables: IAtestadoModeloQueryVariables) {
    return apolloClient
      .query<IAtestadoModeloQuery>({
        query: AtestadoModeloQuery,
        variables,
      })
      .then(({ data }) => data?.atestadoModelo);
  },

  async atestadosModelos(variables: IAtestadosModelosQuery) {
    return apolloClient
      .query<IAtestadosModelosQuery>({
        query: AtestadosModelosQuery,
        variables,
      })
      .then(({ data }) => data?.atestadosModelos);
  },

  async createAtestadoModelo(
    variables: ICreateAtestadoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateAtestadoModeloMutation>({
        mutation: CreateAtestadoModeloMutation,
        variables,
      })
      .then(({ data }) => data?.createAtestadoModelo);
  },

  async deleteAtestadoModelo(
    variables: IDeleteAtestadoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteAtestadoModeloMutation>({
        mutation: DeleteAtestadoModeloMutation,
        variables,
        refetchQueries: getRefetchQueries([AtestadosModelosQuery]),
      })
      .then(({ data }) => data?.deleteAtestadoModelo);
  },

  async deleteManyAtestadosModelos(
    variables: IDeleteManyAtestadosModelosMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteManyAtestadosModelosMutation>({
        mutation: DeleteManyAtestadosModelosMutation,
        variables,
        refetchQueries: getRefetchQueries([AtestadosModelosQuery]),
      })
      .then(({ data }) => data?.deleteManyAtestadosModelos);
  },

  async updateAtestadoModelo(
    variables: IUpdateAtestadoModeloMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateAtestadoModeloMutation>({
        mutation: UpdateAtestadoModeloMutation,
        variables,
      })
      .then(({ data }) => data?.updateAtestadoModelo);
  },
};
