import { Alert } from '@/components/alerts/Alert';
import { createComponent } from '@/lib/vue';
import { IFormValidationErrors } from '@/typings';

interface IProps {
  summary: readonly IFormValidationErrors[];
  show: boolean;
}

interface IEvents {
  onClose: () => void;
}

export const FormSummary = createComponent<IProps, IEvents>({
  name: 'FormSummary',
  props: {
    summary: Array,
    show: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    function handleClose() {
      ctx.emit('close');
    }

    return () => {
      const show = props.show && !!props.summary && props.summary.length > 0;

      return (
        <Alert
          id="FormSummary"
          noIcon
          show={show}
          type="error"
          onClose={handleClose}
          class="m-4"
        >
          {props.summary.map((error: IFormValidationErrors) => (
            <div key={error.label}>
              <strong>{error.label}:</strong> {error.error}
            </div>
          ))}
        </Alert>
      );
    };
  },
});
