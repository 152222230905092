import { TransacaoGraphql } from '@/graphql/financas/transacao/TransacaoGraphql';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import {
  ITransacaoFormModel,
  ITransferenciaModel,
  IUpdateManyTransacoesStatusMutationVariables,
  IUpdateTransacaoStatusMutationVariables,
  TipoRepeticao,
  TransacaoDataInput,
  TransacaoRecorrenciaDataInput,
  TransacaoRepeticaoModo,
  TransacaoTipo,
  TransferenciaDataInput,
} from '@/typings';
import { RawLocation } from 'vue-router';

export const TransacaoService = {
  async getById(id: string) {
    try {
      return await TransacaoGraphql.transacao({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    model,
    indexRoute,
    tipo,
  }: {
    model: ITransacaoFormModel;
    indexRoute: RawLocation;
    tipo: TransacaoTipo;
  }) {
    if (isCreateRecorrencia(model)) {
      return TransacaoService.createTransacaoRecorrencia({
        model,
        indexRoute,
        tipo,
      });
    }

    return TransacaoService.createTransacao({
      model,
      indexRoute,
      tipo,
    });
  },

  async createTransacao({
    model,
    indexRoute,
    tipo,
  }: {
    model: ITransacaoFormModel;
    indexRoute: RawLocation;
    tipo: TransacaoTipo;
  }) {
    try {
      const result = await TransacaoGraphql.createTransacao({
        data: toTransacaoDataInput(model),
        tipo,
      });

      onSuccess({
        result,
        indexRoute,
        tipo,
        msg: 'cadastrada',
      });
    } catch (error) {
      handleError(error);
    }
  },

  async createTransacaoRecorrencia({
    model,
    indexRoute,
    tipo,
  }: {
    model: ITransacaoFormModel;
    indexRoute: RawLocation;
    tipo: TransacaoTipo;
  }) {
    try {
      const result = await TransacaoGraphql.createTransacaoRecorrencia({
        data: toTransacaoDataInput(model),
        tipo,
        recorrencia: toTransacaoRecorrenciaInput(model),
      });

      onSuccess({
        result,
        indexRoute,
        tipo,
        msg: 'cadastrada',
      });
    } catch (error) {
      handleError(error);
    }
  },

  async createTransferencia({
    model,
    indexRoute,
  }: {
    model: ITransferenciaModel;
    indexRoute: RawLocation;
  }) {
    try {
      const result = await TransacaoGraphql.createTransferencia({
        data: toTransferenciaDataInput(model),
      });

      if (result) {
        router.push(indexRoute);

        toastSuccess('Transferência cadastrada com sucesso');
      }
    } catch (error) {
      handleError(error);
    }
  },

  async delete({
    id,
    tipo,
    indexRoute,
  }: {
    id: string;
    tipo: TransacaoTipo;
    indexRoute: RawLocation;
  }) {
    try {
      const tipoName = toTipoName(tipo);

      await validateDelete({ text: `esta ${tipoName}` }, async () => {
        const result = await TransacaoGraphql.deleteTransacao({ id });

        onSuccess({
          result,
          indexRoute,
          tipo,
          msg: 'excluída',
        });
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteTransacao({
    id,
    tipo,
    indexRoute,
  }: {
    id: string;
    tipo: TransacaoTipo;
    indexRoute: RawLocation;
  }) {
    try {
      const result = await TransacaoGraphql.deleteTransacao({ id });

      onSuccess({
        result,
        indexRoute,
        tipo,
        msg: 'excluída',
      });
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'as transações selecionadas',
            'a transação selecionada',
          ),
        },
        async () => {
          const result = await TransacaoGraphql.deleteManyTransacoes({ ids });

          if (result) {
            const msg = pluralize(
              ids,
              'Transações excluídas',
              'Transação excluída',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteRecorrencia({
    recorrenciaId,
    all,
    index,
    indexRoute,
  }: {
    recorrenciaId: string;
    all: boolean;
    index?: number | null;
    indexRoute: RawLocation;
  }) {
    try {
      const result = await TransacaoGraphql.deleteTransacaoRecorrencia({
        recorrenciaId,
        all,
        index,
      });

      if (result) {
        const msg = pluralize(
          result.ids,
          'Transações excluídas',
          'Transação excluída',
        );

        router.push(indexRoute);

        toastSuccess(`${msg} com sucesso`);
      }
    } catch (error) {
      handleError(error);
    }
  },

  async update({
    id,
    model,
    indexRoute,
    tipo,
    updateRecorrencia,
  }: {
    id: string;
    model: ITransacaoFormModel;
    indexRoute: string;
    tipo: TransacaoTipo;
    updateRecorrencia: boolean;
  }) {
    const recorrencia = model.repeticao.geral.recorrencia;

    if (recorrencia && recorrencia.index && updateRecorrencia) {
      return TransacaoService.updateTransacaoRecorrencia({
        model,
        indexRoute,
        tipo,
        recorrenciaId: recorrencia.id,
        recorrenciaIndex: recorrencia.index,
      });
    }

    return TransacaoService.updateTransacao({
      id,
      model,
      indexRoute,
      tipo,
    });
  },

  async updateTransacao({
    id,
    model: { agendamento, ...model },
    indexRoute,
    tipo,
  }: {
    id: string;
    model: ITransacaoFormModel;
    indexRoute: string;
    tipo: TransacaoTipo;
  }) {
    try {
      const result = await TransacaoGraphql.updateTransacao({
        id: Validate.require(id, 'id'),
        data: toTransacaoDataInput(model),
        agendamento: agendamento && {
          desconto: agendamento.desconto || 0,
          procedimentos: agendamento.procedimentos.map(v => ({
            procedimentoId: v.procedimentoId,
            quantidade: Validate.require(
              v.quantidade,
              'procedimento.quantidade',
            ),
            valorUnitario: Validate.require(
              v.valorUnitario,
              'procedimento.valorUnitario',
            ),
          })),
        },
      });

      onSuccess({
        result,
        indexRoute,
        tipo,
        msg: 'alterada',
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateTransacaoRecorrencia({
    recorrenciaId,
    model,
    indexRoute,
    tipo,
    recorrenciaIndex,
  }: {
    recorrenciaId: string;
    model: ITransacaoFormModel;
    indexRoute: RawLocation;
    tipo: TransacaoTipo;
    recorrenciaIndex: number;
  }) {
    try {
      const result = await TransacaoGraphql.updateTransacaoRecorrencia({
        recorrenciaId: Validate.require(recorrenciaId, 'recorrenciaId'),
        data: toTransacaoDataInput(model),
        index: Validate.require(recorrenciaIndex, 'recorrenciaIndex'),
      });

      onSuccess({
        result,
        indexRoute,
        tipo,
        msg: 'alterada',
      });
    } catch (error) {
      handleError(error);
    }
  },

  async updateTransacaoStatus(
    variables: IUpdateTransacaoStatusMutationVariables,
  ) {
    try {
      const result = await TransacaoGraphql.updateTransacaoStatus(variables);

      if (result) {
        toastSuccess(`${toTipoName(result.tipo)} alterada com sucesso`);
      }
    } catch (error) {
      handleError(error);
    }
  },

  async updateManyTransacoesStatus({
    ids,
    pago,
  }: IUpdateManyTransacoesStatusMutationVariables) {
    try {
      if (!ids.length) return;

      const result = await TransacaoGraphql.updateManyTransacoesStatus({
        ids,
        pago,
      });

      if (result) {
        const msg = pluralize(
          ids,
          'Transações alteradas',
          'Transação alterada',
        );

        toastSuccess(`${msg} com sucesso`);
      }
    } catch (error) {
      handleError(error);
    }
  },
};

function toTipoName(tipo: TransacaoTipo) {
  switch (tipo) {
    case TransacaoTipo.DESPESA:
      return 'Despesa';
    case TransacaoTipo.RECEITA:
      return 'Receita';
  }
}

function toTransacaoDataInput({
  geral: {
    descricao,
    valor,
    vencimento,
    pago,
    dataPagamento,
    contaFinanceiraId,
    categoriaId,
    formaPagamento,
    centroCustoId,
  },
  dadosAdicionais: { profissionalId, procedimentoId, pacienteId, convenioId },
}: Omit<ITransacaoFormModel, 'agendamento'>): TransacaoDataInput {
  return {
    descricao: Validate.require(descricao, 'descricao'),
    valor: Validate.require(valor, 'valor'),
    vencimento: Validate.date(vencimento, 'vencimento'),
    pago,
    dataPagamento: DateHelpers.toISODate(dataPagamento),
    formaPagamento: Validate.require(formaPagamento, 'formaPagamento'),
    contaFinanceiraId: Validate.require(contaFinanceiraId, 'contaFinanceiraId'),
    categoriaId: Validate.require(categoriaId, 'categoriaId'),
    profissionalId,
    procedimentoId,
    pacienteId,
    convenioId,
    centroCustoId,
  };
}

function isCreateRecorrencia(model: ITransacaoFormModel) {
  const repeticaoGeral = model.repeticao.geral;

  const isParcelas = repeticaoGeral.modo === TransacaoRepeticaoModo.PARCELAS;
  const isRepeticoes = repeticaoGeral.modo === TransacaoRepeticaoModo.REPETICAO;

  const numero = isParcelas
    ? model.repeticao.parcelas.numeroParcelas
    : model.repeticao.repeticoes.numeroRepeticoes;
  const hasNumero = !!numero && numero > 0;

  const hasTipoRepeticao = isParcelas
    ? true
    : isRepeticoes && !!model.repeticao.repeticoes.tipoRepeticao;

  return (
    repeticaoGeral.repetir &&
    !!repeticaoGeral.modo &&
    hasNumero &&
    hasTipoRepeticao
  );
}

function toTransacaoRecorrenciaInput({
  geral: { valor },
  repeticao: { geral: repeticaoGeral, parcelas, repeticoes },
}: ITransacaoFormModel): TransacaoRecorrenciaDataInput {
  const isParcelas = repeticaoGeral.modo === TransacaoRepeticaoModo.PARCELAS;

  return {
    tipoRepeticao: isParcelas
      ? TipoRepeticao.MENSALMENTE
      : repeticoes.tipoRepeticao!,
    numero: isParcelas
      ? parcelas.numeroParcelas!
      : repeticoes.numeroRepeticoes!,
    valor: isParcelas ? parcelas.valorParcela! : valor!,
    valorEntrada: isParcelas ? parcelas.valorEntrada : null,
  };
}

function toTransferenciaDataInput({
  descricao,
  valor,
  data,
  contaFinanceiraOrigemId,
  contaFinanceiraDestinoId,
}: ITransferenciaModel): TransferenciaDataInput {
  return {
    descricao: Validate.require(descricao, 'descricao'),
    valor: Validate.require(valor, 'valor'),
    data: Validate.date(data, 'data'),
    contaFinanceiraOrigemId: Validate.require(
      contaFinanceiraOrigemId,
      'contaFinanceiraOrigemId',
    ),
    contaFinanceiraDestinoId: Validate.require(
      contaFinanceiraDestinoId,
      'contaFinanceiraDestinoId',
    ),
  };
}

function onSuccess<T>({
  result,
  indexRoute,
  tipo,
  msg,
}: {
  result: T;
  indexRoute: RawLocation;
  tipo: TransacaoTipo;
  msg: string;
}) {
  if (result) {
    router.push(indexRoute);

    toastSuccess(`${toTipoName(tipo)} ${msg} com sucesso`);
  }
}
