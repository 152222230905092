import {
  IAgendamentoFragment,
  IAppState,
  IAtendimentoFragment,
  IProntuarioPacienteState,
  IProntuarioPageState,
  IProntuarioState,
  ITimelineFilterModel,
} from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../../utils/commit';
import { ProntuarioAtendimentoState } from './state/atendimento';
import { ProntuarioCacheState } from './state/cache';
import { ProntuarioFilesState } from './state/files';
import { ProntuarioModelAtestadosState } from './state/modelAtestados';
import { ProntuarioModelExamesState } from './state/modelExames';
import { ProntuarioModelHipoteseDiagnosticaState } from './state/modelHipoteseDiagnostica';
import { ProntuarioPreAtendimentoState } from './state/modelPreAtendimento';
import { ProntuarioModelPrescricoesState } from './state/modelPrescricoes';
import { ProntuarioTabsState } from './state/tabs';
import { prontuarioPages } from './utils';

export const prontuarioStateFn = (): IProntuarioState => ({
  loading: true,
  paciente: null,
  events: [],
  atendimento: {
    playing: false,
    startAt: null,
    endAt: null,
    showTimer: true,
    submitted: false,
  },
  tabs: [],
  agendamento: null,
  page: prontuarioPages.historicoClinico,
  forms: {
    preAtendimento: {
      submitted: false,
      hasError: false,
      model: {
        peso: null,
        altura: null,
        imc: null,
        pressaoArterialSistolica: null,
        pressaoArterialDiastolica: null,
        frequenciaCardiaca: null,
        frequenciaRespiratoria: null,
        temperatura: null,
        glicemiaCapilar: null,
        dataUltimaMenstruacao: null,
        observacao: null,
      },
    },
    exames: {
      submitted: false,
      hasError: false,
      model: [],
    },
    prescricoes: {
      submitted: false,
      hasError: false,
      model: [],
    },
    atestados: {
      submitted: false,
      hasError: false,
      model: [],
    },
    hipoteseDiagnostica: {
      submitted: false,
      hasError: false,
      model: {
        cids: [],
        observacao: null,
      },
    },
  },
  files: [],
  cache: null,
  filter: null,
});

export const ProntuarioModule: Module<IProntuarioState, IAppState> = {
  namespaced: true,
  state: prontuarioStateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IProntuarioState>('prontuario');

export class ProntuarioState {
  static commit = commit;

  static model = {
    preAtendimento: ProntuarioPreAtendimentoState,
    exames: ProntuarioModelExamesState,
    prescricoes: ProntuarioModelPrescricoesState,
    atestados: ProntuarioModelAtestadosState,
    hipoteseDiagnostica: ProntuarioModelHipoteseDiagnosticaState,
  };

  static atendimento = ProntuarioAtendimentoState;

  static tabs = ProntuarioTabsState;

  static cache = ProntuarioCacheState;

  static files = ProntuarioFilesState;

  static setAgendamento(agendamento: IAgendamentoFragment | null) {
    commit(s => {
      s.agendamento = agendamento;
    });
  }

  static setEvents(events: IAtendimentoFragment[]) {
    ProntuarioState.commit(s => {
      s.events = events;
    });
  }

  static setLoading(loading: boolean) {
    commit(s => {
      s.loading = loading;
    });
  }

  static setPaciente({ data, stats }: IProntuarioPacienteState) {
    commit(s => {
      s.paciente = { data, stats };
    });
  }

  static setPage(page: IProntuarioPageState) {
    commit(s => {
      s.page = page;
    });
  }

  static setFilter(filter: ITimelineFilterModel['filter']) {
    commit(s => {
      s.filter = filter;
    });
  }
}
