import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { useMenu } from '@/lib/composables/inputs/useMenu';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IDateRangeModel, IFormInputDateRange } from '@/typings';
import { computed } from '@vue/composition-api';
import { DateRange } from '../custom/DateRange';

interface IProps extends IInputProps {
  // model
  value?: IDateRangeModel | null;
  input: IFormInputDateRange;
}

interface IEvents {
  onInput: (value: IDateRangeModel | null) => void;
}

export const InputDateRange = createComponent<IProps, IEvents>({
  name: 'InputDateRange',
  props: {
    value: { type: Object },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const { $formattedDate, $prependIcon } = useComputeds(props);

    const id = 'InputDateRange';

    const { $pos, $menu, setMenu, handleOpenMenu } = useMenu(id);

    function emitInput(value: IDateRangeModel | null) {
      ctx.emit('input', value);
    }

    return () => (
      <div id={id} onClick={handleOpenMenu}>
        <v-text-field
          name={props.name}
          outlined
          readonly
          label={$label.value}
          value={$formattedDate.value}
          errorMessages={props.errorMessages}
          required={$isRequired.value}
          prependInnerIcon={$prependIcon.value}
          appendIcon={props.input.appendIcon}
          disabled={props.input.disabled}
          hint={props.input.hint}
          persistentHint={props.input.persistentHint}
          hideDetails={!!props.input.hideDetails}
        />

        <InputDateRangeMenu
          value={props.value}
          posX={$pos.value.x}
          posY={$pos.value.y}
          show={$menu.value}
          input={props.input}
          onMenuChange={setMenu}
          onInput={emitInput}
        />
      </div>
    );
  },
});

interface IPropsRangeMenu {
  value?: IDateRangeModel | null;
  posX: number;
  posY: number;
  show: boolean;
  input: IFormInputDateRange;
}

interface IEventsRangeMenu {
  onMenuChange: (show: boolean) => any;
  onInput: (value: IDateRangeModel | null) => void;
}

const InputDateRangeMenu = createComponent<IPropsRangeMenu, IEventsRangeMenu>({
  name: 'InputDateRangeMenu',
  props: {
    value: { type: Object },
    posX: { type: Number, required: true },
    posY: { type: Number, required: true },
    show: { type: Boolean, default: false },
    input: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $max = computed(() => DateHelpers.toISODate(props.input.max));

    const $min = computed(() => DateHelpers.toISODate(props.input.min));

    function emitMenuChange(show: boolean) {
      ctx.emit('menuChange', show);
    }

    function emitInput(value: IDateRangeModel | null) {
      ctx.emit('input', value);

      emitMenuChange(false);
    }

    return () => (
      <v-menu
        fullWidth
        closeOnContentClick={false}
        transition="scale-transition"
        maxWidth="320px"
        minWidth="320px"
        openOnClick={!props.input.disabled}
        absolute
        positionX={props.posX}
        positionY={props.posY}
        value={props.show}
        onInput={emitMenuChange}
      >
        <DateRange
          value={props.value}
          presets={props.input.presets}
          max={$max.value}
          min={$min.value}
          onInput={emitInput}
        />
      </v-menu>
    );
  },
});

function useComputeds(props: IProps) {
  const $formattedDate = computed(() => props.value?.label);

  const $prependIcon = computed(
    () => props.input.prependIcon || MyIcons.dateRange,
  );

  return { $formattedDate, $prependIcon };
}
