import { wait } from '@/lib/helpers/utils';
import { uuid } from '@/lib/helpers/uuid';
import { IAlertPayload, IAppState, IFlashMessagesState } from '@/typings';
import { Module } from 'vuex';
import { createMutation, makeCommit } from '../../utils/commit';
import { alertOfflineObj, alertOnlineObj } from './utils';

const stateFn = (): IFlashMessagesState => ({
  alerts: [],
});

export const FlashMessagesModule: Module<IFlashMessagesState, IAppState> = {
  namespaced: true,
  state: stateFn(),
  mutations: createMutation(),
};

const commit = makeCommit<IFlashMessagesState>('flashMessages');

export class FlashMessagesState {
  static alertError({ text, important }: IAlertPayload) {
    commit(s => {
      s.alerts.push({
        id: uuid(),
        text,
        type: 'error',
        important: !!important,
      });
    });
  }

  static alertInfo({ text, important }: IAlertPayload) {
    commit(s => {
      s.alerts.push({
        id: uuid(),
        text,
        type: 'info',
        important: !!important,
      });
    });
  }

  static alertSuccess({ text, important }: IAlertPayload) {
    commit(s => {
      s.alerts.push({
        id: uuid(),
        text,
        type: 'success',
        important: !!important,
      });
    });
  }

  static alertWarning({ text, important }: IAlertPayload) {
    commit(s => {
      s.alerts.push({
        id: uuid(),
        text,
        type: 'warning',
        important: !!important,
      });
    });
  }

  static alertOnline() {
    commit(s => {
      const noOnlineAlert = !s.alerts.find(f => f.id === alertOnlineObj.id);

      if (noOnlineAlert) {
        s.alerts = [
          ...s.alerts.filter(f => f.id !== alertOfflineObj.id),
          alertOnlineObj,
        ];
      }
    });
  }

  static alertOffline() {
    commit(s => {
      const noOfflineAlert = !s.alerts.find(f => f.id === alertOfflineObj.id);

      if (noOfflineAlert) {
        s.alerts = [
          ...s.alerts.filter(f => f.id !== alertOnlineObj.id),
          alertOfflineObj,
        ];
      }
    });
  }

  static close(id: string) {
    commit(s => {
      s.alerts = s.alerts.filter(f => f.id !== id);
    });
  }

  static closeAll(removeImportant = false) {
    commit(s => {
      s.alerts = removeImportant ? [] : s.alerts.filter(f => f.important);
    });

    // wait until all exit transitions to be finished
    return wait(600);
  }
}
