import { DashboardGraphql } from '@/graphql/dashboard/DashboardGraphql';
import { goHome } from '@/routes/utils';
import { store } from '@/store';
import { DashboardState } from '@/store/modules/dashboard.store';
import { getProfissionalId } from '@/store/utils/auth';
import { mapDashboardAniversariantes } from '../helpers/dashboard';
import { Validate } from '../helpers/error';

export const DashboardService = {
  async init() {
    DashboardService.changeProfissional(null);

    DashboardState.setPeriodo(null);

    DashboardService.loadPage();
  },

  async loadPage(refetch = false) {
    DashboardState.setLoadingAll();

    const data = await DashboardGraphql.dashboardPage(getParams(), refetch);

    DashboardState.loadPageQuery(data);
  },

  async atendimentosPeriodo() {
    DashboardState.setAtendimentosPeriodo({ loading: true });

    const data = await DashboardGraphql.agendamentoProcedimentoPeriodo(
      getParams(),
      true,
    );

    DashboardState.setAtendimentosPeriodo({ data });
  },

  async atendimentosCard() {
    DashboardState.setAtendimentos({ loading: true });

    const data = await DashboardGraphql.atendimentosCard(getParams(), true);

    DashboardState.setAtendimentos({ data });
  },

  async distribuicaoEtaria() {
    DashboardState.setDistribuicaoEtaria({ loading: true });

    const data = await DashboardGraphql.agendamentosDistribuicaoEtaria(
      getParams(),
      true,
    );

    DashboardState.setDistribuicaoEtaria({ data });
  },

  async pacientesCard() {
    DashboardState.setPacientes({ loading: true });

    const data = await DashboardGraphql.pacientesCard(getParams(), true);

    DashboardState.setPacientes({ data });
  },

  async agendamentosStatus() {
    DashboardState.setStatusCount({ loading: true });

    const data = await DashboardGraphql.agendamentosStatusCount(
      getParams(),
      true,
    );

    DashboardState.setStatusCount({ data });
  },

  async pacientesAniversariantes() {
    DashboardState.setAniversariantes({ loading: true });

    const resp = await DashboardGraphql.pacientesAniversariantes(
      getParams(),
      true,
    );

    DashboardState.setAniversariantes({
      data: mapDashboardAniversariantes(resp),
    });
  },

  async changeProfissional(id: string | null) {
    const profissionalId = id || getProfissionalId();

    if (!profissionalId) return goHome();

    DashboardState.setProfissional(profissionalId);
  },
};

function getParams() {
  const {
    header: { periodo, profissionalId },
    aniversariantes: {
      params: { dia, mes, mesTodo },
    },
  } = store.state.dashboard;

  return {
    profissionalId: Validate.require(profissionalId, 'profissionalId'),
    dataInicial: Validate.require(periodo?.startDate, 'dataInicial'),
    dataFinal: Validate.require(periodo?.endDate, 'dataFinal'),
    dia: Validate.require(dia, 'dia'),
    mes: Validate.require(mes, 'mes'),
    mesTodo,
  };
}
