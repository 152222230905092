import { InputAutocomplete } from '@/components/form/inputs/InputAutocomplete';
import { useState, useValue } from '@/lib/composables';
import { LookupsConfigs } from '@/lib/form/lookups';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { ICid10LookupFragment, IFormInputAutocomplete } from '@/typings';
import { computed } from '@vue/composition-api';
import { CidsList } from '../../utils/CidsList';

export const CidsInput = createComponent({
  name: 'CidsInput',
  setup(props, ctx) {
    const [$model] = useValue<string | null>(null);

    const $input = computed<IFormInputAutocomplete>(() => ({
      label: 'Diagnóstico',
      type: 'autocomplete',
      itemLabel: 'descricao',
      lookup: LookupsConfigs.cid10Lookup({
        onModelChange(value: ICid10LookupFragment | null) {
          if (value) {
            ProntuarioState.model.hipoteseDiagnostica.addCid({
              id: uuid(),
              codigo: value.codigo,
              descricao: value.descricao,
            });
          }

          $model.value = null;
        },
      }),
      hideDetails: true,
    }));

    const $cids = useState(
      s => s.prontuario.forms.hipoteseDiagnostica.model.cids,
    );

    return () => (
      <div class="flex flex-col px-4 pt-4">
        <div class="flex">
          <InputAutocomplete input={$input.value} v-model={$model.value} />
        </div>

        <CidsList
          value={$cids.value}
          remove
          onRemove={ProntuarioState.model.hipoteseDiagnostica.removeCid}
        />
      </div>
    );
  },
});
