import { PacienteService } from '@/lib/services';
import { goHome } from '@/routes/utils';
import { ProntuarioState } from '@/store/modules/prontuario';
import { getUser } from '@/store/utils/auth';
import { AgendamentoService } from '../../agendamento/agendamento.service';
import { AtendimentoService } from '../../atendimento.service';
import { ProntuarioSecaoService } from '../prontuarioSecao.service';
import { getProntuarioState } from './prontuario.service';
import { ProntuarioCache } from './prontuarioCache';

export const ProntuarioServiceState = {
  async loadAll({
    pacienteId,
    agendamentoId,
  }: {
    pacienteId: string;
    agendamentoId: string | null;
  }) {
    ProntuarioState.setLoading(true);

    await Promise.all([
      getEvents({ pacienteId }),
      getAgendamento(agendamentoId),
      getPaciente({ pacienteId }),
      getTabs(),
      ProntuarioCache.load(pacienteId),
    ]);

    ProntuarioState.setLoading(false);
  },

  async refresh() {
    const pacienteId = getProntuarioState().paciente?.data.id;
    if (pacienteId) {
      getEvents({ pacienteId, refetch: true });
      getPaciente({ pacienteId });
      ProntuarioCache.load(pacienteId);
    }

    const agendamentoId = getProntuarioState().agendamento?.id;
    if (agendamentoId) {
      getAgendamento(agendamentoId);
    }

    getTabs();
  },
};

async function getEvents({
  pacienteId,
  refetch = false,
}: {
  pacienteId: string;
  refetch?: boolean;
}) {
  const result = await AtendimentoService.getAll({ pacienteId, refetch });
  if (!result) {
    return goHome();
  }

  ProntuarioState.setEvents(result.nodes);
}

async function getAgendamento(agendamentoId: string | null) {
  if (!agendamentoId) {
    return;
  }

  const agendamento = await AgendamentoService.getById(agendamentoId);
  if (!agendamento) {
    return goHome();
  }

  ProntuarioState.setAgendamento(agendamento);
}

async function getPaciente({ pacienteId }: { pacienteId: string }) {
  const paciente = await PacienteService.getById(pacienteId);
  if (!paciente) {
    return goHome();
  }

  const stats = await PacienteService.getStats({
    pacienteId,
    profissionalId: getUser().id,
  });

  ProntuarioState.setPaciente({
    data: paciente,
    stats: {
      faltas: stats?.faltas || 0,
      atendimentos: stats?.atendimentos || 0,
      dataUltimoAtendimento: stats?.dataUltimoAtendimento,
    },
  });
}

async function getTabs() {
  const results = await ProntuarioSecaoService.getAll({
    profissionalId: getUser().id,
  });

  if (results) {
    ProntuarioState.tabs.init(results.nodes);
  }
}
