import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { QueryGraphql } from '@/graphql/query';
import { useState, useValue } from '@/lib/composables';
import { IFormEvents } from '@/lib/composables/form/useFormConfig';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { ProntuarioState } from '@/store/modules/prontuario';
import { getUser } from '@/store/utils/auth';
import {
  IForm,
  IFormSchema,
  IPrescricaoModeloFragment,
  IPrescricoesModelosQuery,
  IPrescricoesModelosQueryVariables,
  IProntuarioPrescricaoFormModel,
  IProntuarioPrescricaoHeaderModel,
  IProntuarioPrescricaoMedicamentoModel,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProntuarioPrescricaoMedicamentos } from './prontuarioPrescricoesForm/PrescricaoModeloMedicamentosFields';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {
  value: IProntuarioPrescricaoFormModel;
}

interface IEvents extends IFormEvents<IProntuarioPrescricaoFormModel> {}

export const ProntuarioPrescricoesForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioPrescricoesForm',
  props: {
    value: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $showDelete, $schema, emitSubmit, $submitting, $form } =
      useComputeds(props, ctx);

    const {
      handleMedicamentosInput,
      handleSalvarModelo,
      handleUsarModelo,
      handleValueInput,
    } = useEvents(props);

    return () => (
      <ProntuarioCardForm>
        <MyForm form={$form.value} noCancel noSummary onSubmit={emitSubmit}>
          <TopActionsRow
            id={props.value.id}
            showDelete={$showDelete.value}
            submitting={$submitting.value}
            handleUsarModelo={handleUsarModelo}
          />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            class="m-4"
            value={props.value.header}
            onInput={handleValueInput}
          />

          <ProntuarioPrescricaoMedicamentos
            form={$form.value}
            value={props.value.medicamentos}
            onInput={handleMedicamentosInput}
          />

          <BottomActionsRow
            slot="actions"
            submitting={$submitting.value}
            handleSalvarModelo={handleSalvarModelo}
          />
        </MyForm>
      </ProntuarioCardForm>
    );
  },
});

function useComputeds(props: IProps, ctx: SetupContext) {
  const $showDelete = useState(
    s => s.prontuario.forms.prescricoes.model.length > 1,
  );

  const [$submitting] = useValue(false);

  const $form = useState<IForm<any>>(s => ({
    id: null,
    model: props.value,
    submitted: s.prontuario.forms.prescricoes.submitted,
    loading: false,
    validationErrors: [],
    page: {
      id: null,
      submitting: false,
      error: null,
    },
  }));

  const $schema = computed<IFormSchema<IProntuarioPrescricaoHeaderModel>>(
    () => ({
      data: {
        label: 'Data',
        type: 'date',
        layout: { width: 180 },
        validations: { required: true },
      },
      controleEspecial: {
        label: 'Receituário controle especial',
        type: 'checkbox',
        layout: { width: 260 },
      },
    }),
  );

  function emitSubmit() {
    ProntuarioState.model.prescricoes.setSubmitted(true);

    ctx.emit('submit');
  }

  return {
    $showDelete,
    $form,
    $schema,
    $submitting,
    emitSubmit,
  };
}

function useEvents(props: IProps) {
  const profissionalId = getUser().id;

  function handleMedicamentosInput(
    medicamentos: IProntuarioPrescricaoMedicamentoModel[],
  ) {
    ProntuarioState.model.prescricoes.setModel({
      ...props.value,
      medicamentos,
    });
  }

  function handleValueInput(header: IProntuarioPrescricaoHeaderModel) {
    ProntuarioState.model.prescricoes.setModel({
      ...props.value,
      header,
    });
  }

  function handleUsarModelo() {
    DialogHelpers.prontuario.modelo({
      title: 'Modelos de prescrições',
      params: {
        mapQueryConfig: $search => ({
          query: QueryGraphql.PrescricoesModelosQuery,
          variables(): IPrescricoesModelosQueryVariables {
            return {
              where: { nome_contains: $search.value },
              profissionalId,
            };
          },
          mapData(result: IPrescricoesModelosQuery) {
            return result?.prescricoesModelos.nodes;
          },
        }),
        onSelect(v: IPrescricaoModeloFragment) {
          ProntuarioState.model.prescricoes.setModel({
            ...props.value,
            medicamentos: (v.medicamentos || []).map(v => ({
              ...v,
              hideObs: !v.observacao,
            })),
          });
        },
        cadastroRoute:
          Routes.app.configProfissionais(profissionalId).prontuario.prescricoes
            .new,
      },
    });
  }

  const { handleSalvarModelo } = useSalvarModelo(props);

  return {
    handleMedicamentosInput,
    handleSalvarModelo,
    handleUsarModelo,
    handleValueInput,
  };
}

function useSalvarModelo(props: IProps) {
  function validateForm() {
    ProntuarioState.model.prescricoes.setSubmitted(true);

    const {
      header: { data },
      medicamentos,
    } = props.value;

    return !!data && medicamentos.every(m => !!m.medicamento && !!m.quantidade);
  }

  function handleSalvarModelo() {
    if (validateForm()) {
      return ProntuarioService.modelo.savePrescricao(props.value.medicamentos);
    }
  }

  return { handleSalvarModelo };
}

const TopActionsRow = createComponent({
  name: 'ProntuarioPrescricoesFormTopActionsRow',
  props: {
    id: { type: String, required: true },
    showDelete: { type: Boolean, required: true },
    submitting: { type: Boolean, required: true },
    handleUsarModelo: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center px-4 pt-4 pb-2">
        <v-btn
          outlined
          color="secondary"
          disabled={props.submitting}
          onClick={props.handleUsarModelo}
        >
          <v-icon left>{MyIcons.document}</v-icon>
          Usar modelo
        </v-btn>

        <v-spacer />

        {props.showDelete && (
          <v-btn
            color="secondary"
            icon
            small
            class="ml-3"
            aria-label="Remover"
            data-balloon-pos="up"
            onClick={() => ProntuarioState.model.prescricoes.remove(props.id)}
          >
            <v-icon>{MyIcons.remove}</v-icon>
          </v-btn>
        )}
      </div>
    );
  },
});

const BottomActionsRow = createComponent({
  name: 'ProntuarioPrescricoesFormBottomActionsRow',
  props: {
    submitting: { type: Boolean, required: true },
    handleSalvarModelo: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex items-center">
        <v-btn
          outlined
          color="primary"
          disabled={props.submitting}
          onClick={props.handleSalvarModelo}
        >
          <v-icon left>{MyIcons.saveAll}</v-icon>
          Salvar como modelo
        </v-btn>

        <v-spacer />

        <v-btn
          type="submit"
          color="primary"
          loading={props.submitting}
          disabled={props.submitting}
        >
          Salvar
        </v-btn>
      </div>
    );
  },
});
