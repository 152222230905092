import { apolloClient } from '@/plugins/apollo';

import ConfirmEmailMutation from '@/graphql/auth/confirmEmail/confirmEmail.graphql';
import ResendConfirmationEmailMutation from '@/graphql/auth/confirmEmail/resendConfirmationEmail.graphql';
import CheckConviteQuery from '@/graphql/auth/convite/checkConvite.graphql';
import ConfirmConviteMutation from '@/graphql/auth/convite/confirmConvite.graphql';
import CheckEsqueceuSenhaQuery from '@/graphql/auth/esqueceuSenha/checkEsqueceuSenha.graphql';
import EsqueceuSenhaPart1Mutation from '@/graphql/auth/esqueceuSenha/esqueceuSenhaPart1.graphql';
import EsqueceuSenhaPart2Mutation from '@/graphql/auth/esqueceuSenha/esqueceuSenhaPart2.graphql';
import LoginMutation from '@/graphql/auth/login/login.graphql';
import MeQuery from '@/graphql/auth/me/me.graphql';
import RefreshTokenMutation from '@/graphql/auth/refreshToken/refreshToken.graphql';
import SignUpPart1Mutation from '@/graphql/auth/signUp/signUpPart1.graphql';
import SignUpPart2Mutation from '@/graphql/auth/signUp/signUpPart2.graphql';

import {
  ICheckConviteQuery,
  ICheckConviteQueryVariables,
  ICheckEsqueceuSenhaQuery,
  ICheckEsqueceuSenhaQueryVariables,
  IConfirmConviteMutation,
  IConfirmConviteMutationVariables,
  IConfirmEmailMutation,
  IConfirmEmailMutationVariables,
  IEsqueceuSenhaPart1Mutation,
  IEsqueceuSenhaPart1MutationVariables,
  IEsqueceuSenhaPart2Mutation,
  IEsqueceuSenhaPart2MutationVariables,
  ILoginMutation,
  ILoginMutationVariables,
  IMeQuery,
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables,
  IResendConfirmationEmailMutation,
  IResendConfirmationEmailMutationVariables,
  ISignUpPart1Mutation,
  ISignUpPart1MutationVariables,
  ISignUpPart2Mutation,
  ISignUpPart2MutationVariables,
} from '@/typings';

export const AuthGraphql = {
  query: {
    CheckConviteQuery,
    MeQuery,
  },

  async confirmEmail(variables: IConfirmEmailMutationVariables) {
    return apolloClient
      .mutate<IConfirmEmailMutation>({
        mutation: ConfirmEmailMutation,
        variables,
      })
      .then(({ data }) => data?.confirmEmail);
  },

  checkConvite(variables: ICheckConviteQueryVariables) {
    return apolloClient
      .query<ICheckConviteQuery>({
        query: CheckConviteQuery,
        variables,
      })
      .then(({ data }) => data?.checkConvite);
  },

  confirmConvite(variables: IConfirmConviteMutationVariables) {
    return apolloClient
      .mutate<IConfirmConviteMutation>({
        mutation: ConfirmConviteMutation,
        variables,
      })
      .then(({ data }) => data?.confirmConvite);
  },

  checkEsqueceuSenha(variables: ICheckEsqueceuSenhaQueryVariables) {
    return apolloClient
      .query<ICheckEsqueceuSenhaQuery>({
        query: CheckEsqueceuSenhaQuery,
        variables,
      })
      .then(({ data }) => data?.checkEsqueceuSenha);
  },

  esqueceuSenhaPart1(variables: IEsqueceuSenhaPart1MutationVariables) {
    return apolloClient
      .mutate<IEsqueceuSenhaPart1Mutation>({
        mutation: EsqueceuSenhaPart1Mutation,
        variables,
      })
      .then(({ data }) => data?.esqueceuSenhaPart1);
  },

  esqueceuSenhaPart2(variables: IEsqueceuSenhaPart2MutationVariables) {
    return apolloClient
      .mutate<IEsqueceuSenhaPart2Mutation>({
        mutation: EsqueceuSenhaPart2Mutation,
        variables,
      })
      .then(({ data }) => data?.esqueceuSenhaPart2);
  },

  login(variables: ILoginMutationVariables) {
    return apolloClient
      .mutate<ILoginMutation>({
        mutation: LoginMutation,
        variables,
      })
      .then(({ data }) => data?.login);
  },

  me(refetch = false) {
    return apolloClient
      .query<IMeQuery>({
        query: MeQuery,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })
      .then(({ data }) => data?.me);
  },

  refreshToken(variables: IRefreshTokenMutationVariables) {
    return apolloClient
      .mutate<IRefreshTokenMutation>({
        mutation: RefreshTokenMutation,
        variables,
      })
      .then(({ data }) => data?.refreshToken);
  },

  resendConfirmationEmail(
    variables: IResendConfirmationEmailMutationVariables,
  ) {
    return apolloClient
      .mutate<IResendConfirmationEmailMutation>({
        mutation: ResendConfirmationEmailMutation,
        variables,
      })
      .then(({ data }) => data?.resendConfirmationEmail);
  },

  signUpPart1(variables: ISignUpPart1MutationVariables) {
    return apolloClient
      .mutate<ISignUpPart1Mutation>({
        mutation: SignUpPart1Mutation,
        variables,
      })
      .then(({ data }) => data?.signUpPart1);
  },

  signUpPart2(variables: ISignUpPart2MutationVariables) {
    return apolloClient
      .mutate<ISignUpPart2Mutation>({
        mutation: SignUpPart2Mutation,
        variables,
      })
      .then(({ data }) => data?.signUpPart2);
  },
};
