import { CategoriaFinanceiraGraphql } from '@/graphql/financas/categoriaFinanceira/CategoriaFinanceiraGraphql';
import { handleError, Validate } from '@/lib/helpers/error';
import { validateDelete } from '@/lib/helpers/services';
import { toastSuccess } from '@/lib/helpers/toast';
import { pluralize } from '@/lib/helpers/utils';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { ICategoriaFinanceiraModel, IServiceOnSuccessArgs } from '@/typings';

export const CategoriaFinanceiraService = {
  async getById(id: string) {
    try {
      return await CategoriaFinanceiraGraphql.categoriaFinanceira({ id });
    } catch (error) {
      handleError(error);
    }
  },

  async create({
    nome,
    tipo,
    isSubcategoria,
    parentId,
  }: ICategoriaFinanceiraModel) {
    try {
      const result = await CategoriaFinanceiraGraphql.createCategoriaFinanceira(
        {
          data: {
            nome: Validate.require(nome, 'nome'),
            tipo: Validate.require(tipo, 'tipo'),
            parentId: isSubcategoria ? parentId : null,
          },
        },
      );

      onSuccess({ result, msg: 'cadastrada' });
    } catch (error) {
      handleError(error);
    }
  },

  async delete(id: string) {
    try {
      await validateDelete(
        {
          text: 'esta categoria financeira',
        },
        async () => {
          const result =
            await CategoriaFinanceiraGraphql.deleteCategoriaFinanceira({ id });

          onSuccess({ result, msg: 'excluída' });
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async deleteMany(ids: string[]) {
    try {
      await validateDelete(
        {
          text: pluralize(
            ids,
            'as categorias financerias selecionadas',
            'a categoria financeira selecionada',
          ),
        },
        async () => {
          const result =
            await CategoriaFinanceiraGraphql.deleteManyCategoriasFinanceiras({
              ids,
            });

          if (result) {
            const msg = pluralize(
              ids,
              'Categorias financeiras excluídas',
              'Categoria financeira excluída',
            );

            toastSuccess(`${msg} com sucesso`);
          }
        },
      );
    } catch (error) {
      handleError(error);
    }
  },

  async update(
    id: string,
    { nome, tipo, parentId }: ICategoriaFinanceiraModel,
  ) {
    try {
      const result = await CategoriaFinanceiraGraphql.updateCategoriaFinanceira(
        {
          id: Validate.require(id, 'id'),
          data: {
            nome: Validate.require(nome, 'nome'),
            tipo: Validate.require(tipo, 'tipo'),
            parentId,
          },
        },
      );

      onSuccess({ result, msg: 'alterada' });
    } catch (error) {
      handleError(error);
    }
  },
};

function onSuccess<T>({ result, msg }: IServiceOnSuccessArgs<T>) {
  if (result) {
    router.push(Routes.app.configClinica.financas.categoriasFinanceiras.index);

    toastSuccess(`Categoria financeira ${msg} com sucesso`);
  }
}
