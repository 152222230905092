import { createComponent } from '@/lib/vue';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { IFormInputTextarea } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IInputProps {
  value?: string | null;
  input: IFormInputTextarea;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputTextarea = createComponent<IProps, IEvents>({
  name: 'InputTextarea',
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const { $label, $isRequired } = useInput(props);

    const { handleChange } = useEvents(ctx);

    return () => (
      <v-textarea
        name={props.name}
        label={$label.value}
        outlined
        errorMessages={props.errorMessages}
        value={props.value}
        required={$isRequired.value}
        prependInnerIcon={props.input.prependIcon}
        appendIcon={props.input.appendIcon}
        disabled={props.input.disabled}
        loading={props.input.loading}
        autofocus={props.input.autofocus}
        readonly={props.input.readonly}
        hint={props.input.hint}
        rows={props.input.rows || 5}
        persistentHint={props.input.persistentHint}
        hideDetails={!!props.input.hideDetails}
        onInput={handleChange}
      />
    );
  },
});

function useEvents(ctx: SetupContext) {
  function handleChange(newValue: string | null) {
    const value = (newValue || '').trim();

    emitInput(value);
  }

  function emitInput(value: string | null) {
    ctx.emit('input', value);
  }

  return { handleChange, emitInput };
}
