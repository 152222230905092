import { CircularLoading } from '@/components/loading/CircularLoading';
import { useValue } from '@/lib/composables';
import { useElementRef } from '@/lib/composables/utils/useElementRef';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { onMounted, SetupContext } from '@vue/composition-api';
import CropperJs from 'cropperjs';
import 'cropperjs/dist/cropper.css';

interface IProps {
  image: File;
}

interface IEvents {
  onBack(): void;
  onCrop(image: string): void;
}

export const Cropper = createComponent<IProps, IEvents>({
  name: 'Cropper',
  props: {
    image: { type: File, required: true },
  },
  setup(props, ctx) {
    const { imgId, $imgSrc, cropImage, reset, zoom, emitBack } = useActions(
      props,
      ctx,
    );

    return () => (
      <div class="flex flex-col">
        <div class="flex justify-center">
          <div
            style={{
              maxHeight: '400px',
              width: '460px',
            }}
          >
            <img
              id={imgId}
              src={$imgSrc.value || undefined}
              alt="imagem"
              class={['w-full h-full', { hidden: !$imgSrc.value }]}
            />
          </div>
        </div>

        <CircularLoading loading={!$imgSrc.value}>
          <div class="flex flex-col">
            <div class="flex">
              <v-btn icon onClick={() => zoom(0.2)}>
                <v-icon>{MyIcons.zoomIn}</v-icon>
              </v-btn>

              <v-btn icon onClick={() => zoom(-0.2)}>
                <v-icon>{MyIcons.zoomOut}</v-icon>
              </v-btn>

              <v-btn icon onClick={reset}>
                <v-icon>{MyIcons.reset}</v-icon>
              </v-btn>
            </div>

            <div class="flex justify-between">
              <v-btn text color="secondary" onClick={emitBack}>
                <v-icon left>{MyIcons.back}</v-icon>
                Voltar
              </v-btn>

              <v-btn color="primary" onClick={cropImage}>
                <v-icon left>{MyIcons.image}</v-icon>
                Usar esta imagem
              </v-btn>
            </div>
          </div>
        </CircularLoading>
      </div>
    );
  },
});

function useActions(props: IProps, ctx: SetupContext) {
  let cropper: Cropper | null = null;

  const [$imgSrc, setImgSrc] = useValue<string | null>(null);

  const { elementId: imgId, getElement: getImgEl } =
    useElementRef<HTMLImageElement>();

  onMounted(() => {
    cropper = new CropperJs(getImgEl()!, {
      viewMode: 3,
      aspectRatio: 1,
      dragMode: 'move',
      zoomOnTouch: false,
      toggleDragModeOnDblclick: false,
    });

    setImage();
  });

  function setImage() {
    const reader = new FileReader();

    reader.onload = event => {
      const result = event.target?.result as string;

      setImgSrc(result);

      // rebuild cropperjs with the updated source
      cropper?.replace(result);
    };

    reader.readAsDataURL(props.image);
  }

  function emitCrop(image: string) {
    ctx.emit('crop', image);
  }

  function emitBack() {
    ctx.emit('back');
  }

  return {
    imgId,
    $imgSrc,
    emitBack,
    cropImage() {
      if (!cropper) return;

      emitCrop(
        // get image data for post processing, e.g. upload or setting image src
        cropper.getCroppedCanvas().toDataURL(props.image.type),
      );
    },
    reset() {
      cropper?.reset();
    },
    zoom(percent: number) {
      cropper?.zoom(percent);
    },
  };
}
