import { IProntuarioFileState } from '@/typings';
import { ProntuarioState } from '..';

export const ProntuarioFilesState = {
  set(files: IProntuarioFileState[] | undefined | null) {
    ProntuarioState.commit(s => {
      s.files = files || [];
    });
  },
};
