import {
  checkIsAccessTokenValidOrUndefined,
  checkIsRefreshTokenValid,
  getAccessTokenPayload,
} from '@/lib/auth/auth';
import { TokenService } from '@/lib/auth/token';
import { GRAPHQL_URI } from '@/lib/helpers/env';
import { getGraphqlErrorMessage, Validate } from '@/lib/helpers/error';
import { AuthLoginService } from '@/lib/services/auth/login.service';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { store } from '@/store';
import { AuthState } from '@/store/modules/auth.store';
import {
  IAuthClinicaState,
  IAuthPayloadResult,
  IMyUserFragment,
} from '@/typings';

export async function checkIsAuthenticated() {
  if (!getRefreshToken() || !checkIsRefreshTokenValid()) {
    return false;
  }

  if (!store.state.auth.accessToken) {
    await setAccessToken();
  }

  return checkIsAccessTokenValidOrUndefined();
}

function getRefreshToken() {
  if (store.state.auth.refreshToken) {
    return store.state.auth.refreshToken;
  }

  const refreshToken = TokenService.refreshToken.get();
  if (!refreshToken) {
    return null;
  }

  AuthState.setRefreshToken(refreshToken);

  return refreshToken;
}

export function fetchAccessToken() {
  const refreshToken = Validate.require(
    TokenService.refreshToken.get(),
    'refreshToken',
  );

  const clinicaId = TokenService.clinica.get();

  const clinicaVariable = clinicaId ? `clinicaId: "${clinicaId}"` : '';

  const query = `mutation {
      refreshToken(
        ${clinicaVariable}
        refreshToken: "${refreshToken}"
      ) {
        accessToken
      }
    }`;

  return fetch(GRAPHQL_URI!, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  });
}

export async function handleAccessTokenResponse(response: Response) {
  const result = await response.json();

  if (result?.data) {
    return {
      accessToken: result.data.refreshToken.accessToken as string,
    };
  }
}

async function setAccessToken() {
  try {
    const accessToken = TokenService.accessToken.get();

    if (accessToken) {
      return AuthState.setAccessToken(accessToken);
    }

    const response = await fetchAccessToken();

    if (!response) return null;

    const result = await handleAccessTokenResponse(response);

    if (result) {
      AuthState.setAccessToken(result.accessToken);
    }
  } catch (error) {
    authFailed(error);
  }
}

export async function setTokensAndRedirect(
  result: IAuthPayloadResult | null | undefined,
) {
  if (!result) {
    throw 'Auth failed';
  }

  AuthState.setAuthPayload(result);

  const tokenPayload = getAccessTokenPayload();

  const user = await AuthLoginService.loadMyUser();

  if (user && tokenPayload) {
    if (user.signUpIncomplete) {
      return router.replace(Routes.auth.signUpPart2);
    }

    if (user.clinicas?.length) {
      if (tokenPayload.clinicaId) {
        const foundUserClinica = user.clinicas.find(
          f => f.clinica.id === tokenPayload.clinicaId,
        );

        if (foundUserClinica) {
          return AuthLoginService.selectClinica(foundUserClinica);
        }
      }

      if (user.clinicas.length === 1) {
        return AuthLoginService.selectClinica(user.clinicas[0]);
      } else if (user.clinicas.length > 1) {
        return router.replace(Routes.auth.clinicas);
      }
    }
  }
}

export function loadClinicaFromUser({
  user: { clinicas },
  clinicaId,
}: {
  user: IMyUserFragment;
  clinicaId: string | null;
}) {
  const clinicaState = (clinicas || []).find(f => f.clinica.id === clinicaId);

  if (clinicaState) {
    const clinica: IAuthClinicaState = {
      ...clinicaState.clinica,
      adminClinica: clinicaState.adminClinica,
    };

    AuthState.setClinica(clinica);

    return clinica;
  }
}

export function authFailed(error) {
  const graphqlError: string = getGraphqlErrorMessage(error);
  if (graphqlError) {
    if (graphqlError.includes('user already signed up')) {
      AuthLoginService.logout();
    }
    throw graphqlError;
  }
  console.error('Auth failed: ', error);
}
