import CreateTissGuiaConsultaMutation from '@/graphql/tiss/tissGuiaConsulta/createTissGuiaConsulta.graphql';
import DeleteTissGuiaConsultaMutation from '@/graphql/tiss/tissGuiaConsulta/deleteTissGuiaConsulta.graphql';
import TissGuiaConsultaQuery from '@/graphql/tiss/tissGuiaConsulta/tissGuiaConsulta.graphql';
import TissGuiasConsultasQuery from '@/graphql/tiss/tissGuiaConsulta/tissGuiasConsultas.graphql';
import UpdateTissGuiaConsultaMutation from '@/graphql/tiss/tissGuiaConsulta/updateTissGuiaConsulta.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  ICreateTissGuiaConsultaMutation,
  ICreateTissGuiaConsultaMutationVariables,
  IDeleteTissGuiaConsultaMutation,
  IDeleteTissGuiaConsultaMutationVariables,
  ITissGuiaConsultaQuery,
  ITissGuiaConsultaQueryVariables,
  ITissGuiasConsultasQuery,
  ITissGuiasConsultasQueryVariables,
  IUpdateTissGuiaConsultaMutation,
  IUpdateTissGuiaConsultaMutationVariables,
} from '@/typings';

export const TissGuiaConsultaGraphql = {
  query: {
    TissGuiaConsultaQuery,
    TissGuiasConsultasQuery,
  },

  async createTissGuiaConsulta(
    variables: ICreateTissGuiaConsultaMutationVariables,
  ) {
    return apolloClient
      .mutate<ICreateTissGuiaConsultaMutation>({
        mutation: CreateTissGuiaConsultaMutation,
        variables,
      })
      .then(({ data }) => data?.createTissGuiaConsulta);
  },

  async deleteTissGuiaConsulta(
    variables: IDeleteTissGuiaConsultaMutationVariables,
  ) {
    return apolloClient
      .mutate<IDeleteTissGuiaConsultaMutation>({
        mutation: DeleteTissGuiaConsultaMutation,
        variables,
        refetchQueries: getRefetchQueries([TissGuiasConsultasQuery]),
      })
      .then(({ data }) => data?.deleteTissGuiaConsulta);
  },

  async tissGuiaConsulta(variables: ITissGuiaConsultaQueryVariables) {
    return apolloClient
      .query<ITissGuiaConsultaQuery>({
        query: TissGuiaConsultaQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiaConsulta);
  },

  async tissGuiasConsultas(variables: ITissGuiasConsultasQueryVariables) {
    return apolloClient
      .query<ITissGuiasConsultasQuery>({
        query: TissGuiasConsultasQuery,
        variables,
      })
      .then(({ data }) => data?.tissGuiasConsultas);
  },

  async updateTissGuiaConsulta(
    variables: IUpdateTissGuiaConsultaMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateTissGuiaConsultaMutation>({
        mutation: UpdateTissGuiaConsultaMutation,
        variables,
      })
      .then(({ data }) => data?.updateTissGuiaConsulta);
  },
};
