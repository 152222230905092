import { ColorTile } from '@/components/utils/ColorTile';
import { useValue } from '@/lib/composables';
import { IInputProps, useInput } from '@/lib/composables/inputs/useInput';
import { materialColorPalette } from '@/lib/constants/color';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IFormInputColor } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import isObject from 'lodash/isObject';

interface IProps extends IInputProps {
  // model
  value?: string | null;
  input: IFormInputColor;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const InputColor = createComponent<IProps, IEvents>({
  name: 'InputColor',
  props: {
    value: { type: String },
    name: { type: String },
    input: { type: Object, required: true },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const [$menu, setMenu] = useValue(false);
    const $prependIcon = computed(() => props.input.prependIcon || MyIcons.cor);

    const { $label, $isRequired } = useInput(props);

    const { handleChange } = useEvents({ ctx, setMenu });

    return () => (
      <v-menu
        offsetY
        fullWidth
        closeOnContentClick={false}
        transition="scale-transition"
        nudgeRight={10}
        nudgeTop={70}
        v-model={$menu.value}
        scopedSlots={{
          activator: menuActivator({
            props,
            $prependIcon,
            $label,
            $isRequired,
          }),
        }}
      >
        <v-color-picker
          hide-canvas
          hide-inputs
          show-swatches
          swatches-max-height={200}
          swatches={materialColorPalette}
          value={props.value}
          onInput={handleChange}
        />
      </v-menu>
    );
  },
});

interface IColorObj {
  alpha: string;
  hex: string;
  hexa: string;
  hsla: string;
  hsva: string;
  hue: string;
  rgba: string;
}

function useEvents({
  ctx,
  setMenu,
}: {
  ctx: SetupContext;
  setMenu: (v: boolean) => void;
}) {
  function handleChange(color: string | IColorObj | null) {
    const value = isObject(color) ? color.hex : color;

    emitInput(value);

    setMenu(!value);
  }

  function emitInput(value: string | null) {
    ctx.emit('input', value);
  }

  return { handleChange, emitInput };
}

const menuActivator =
  ({
    props,
    $prependIcon,
    $label,
    $isRequired,
  }: {
    props: IProps;
    $prependIcon: Ref<string>;
    $label: Ref<string | null>;
    $isRequired: Ref<boolean>;
  }) =>
  ({ on }) =>
    (
      <div {...{ on }}>
        <v-text-field
          name={props.name}
          outlined
          readonly
          color={props.value}
          label={$label.value}
          value={props.value}
          errorMessages={props.errorMessages}
          required={$isRequired.value}
          prependInnerIcon={$prependIcon.value}
          appendIcon={props.input.appendIcon}
          disabled={props.input.disabled}
          hint={props.input.hint}
          persistentHint={props.input.persistentHint}
          hideDetails={!!props.input.hideDetails}
        >
          <ColorTile slot="append" value={props.value} />
        </v-text-field>
      </div>
    );
