import { IProntuarioHipoteseDiagnosticaCidModel } from '@/typings';
import { ProntuarioState } from '..';

export const ProntuarioModelHipoteseDiagnosticaState = {
  setObservacao(observacao: string | null) {
    ProntuarioState.commit(s => {
      s.forms.hipoteseDiagnostica.model.observacao = observacao;
    });
  },

  addCid(cid: IProntuarioHipoteseDiagnosticaCidModel) {
    ProntuarioState.commit(s => {
      s.forms.hipoteseDiagnostica.model.cids.push(cid);
    });
  },

  removeCid(id: string) {
    ProntuarioState.commit(s => {
      s.forms.hipoteseDiagnostica.model.cids =
        s.forms.hipoteseDiagnostica.model.cids.filter(f => f.id !== id);
    });
  },

  setSubmitted(submitted: boolean) {
    ProntuarioState.commit(s => {
      s.forms.hipoteseDiagnostica.submitted = submitted;
    });
  },
};
