import { apolloClient } from '@/plugins/apollo';

import ConfiguracaoAgendaQuery from '@/graphql/configuracao/configuracaoAgenda/configuracaoAgenda.graphql';
import UpdateConfiguracaoAgendaMutation from '@/graphql/configuracao/configuracaoAgenda/updateConfiguracaoAgenda.graphql';
import ConfiguracaoEmailQuery from '@/graphql/configuracao/configuracaoEmail/configuracaoEmail.graphql';
import UpdateConfiguracaoEmailMutation from '@/graphql/configuracao/configuracaoEmail/updateConfiguracaoEmail.graphql';
import ConfiguracaoEmailAniversariantesQuery from '@/graphql/configuracao/configuracaoEmailAniversariantes/configuracaoEmailAniversariantes.graphql';
import UpdateConfiguracaoEmailAniversariantesMutation from '@/graphql/configuracao/configuracaoEmailAniversariantes/updateConfiguracaoEmailAniversariantes.graphql';
import ConfiguracaoFinanceiraQuery from '@/graphql/configuracao/configuracaoFinanceira/configuracaoFinanceira.graphql';
import UpdateConfiguracaoFinanceiraMutation from '@/graphql/configuracao/configuracaoFinanceira/updateConfiguracaoFinanceira.graphql';
import ConfiguracaoImpressaoQuery from '@/graphql/configuracao/configuracaoImpressao/configuracaoImpressao.graphql';
import UpdateConfiguracaoImpressaoMutation from '@/graphql/configuracao/configuracaoImpressao/updateConfiguracaoImpressao.graphql';
import ConfiguracaoSmsQuery from '@/graphql/configuracao/configuracaoSms/configuracaoSms.graphql';
import UpdateConfiguracaoSmsMutation from '@/graphql/configuracao/configuracaoSms/updateConfiguracaoSms.graphql';

import {
  IConfiguracaoAgendaQuery,
  IConfiguracaoAgendaQueryVariables,
  IConfiguracaoEmailAniversariantesQuery,
  IConfiguracaoEmailAniversariantesQueryVariables,
  IConfiguracaoEmailQuery,
  IConfiguracaoEmailQueryVariables,
  IConfiguracaoFinanceiraQuery,
  IConfiguracaoImpressaoQuery,
  IConfiguracaoImpressaoQueryVariables,
  IConfiguracaoSmsQuery,
  IConfiguracaoSmsQueryVariables,
  IUpdateConfiguracaoAgendaMutation,
  IUpdateConfiguracaoAgendaMutationVariables,
  IUpdateConfiguracaoEmailAniversariantesMutation,
  IUpdateConfiguracaoEmailAniversariantesMutationVariables,
  IUpdateConfiguracaoEmailMutation,
  IUpdateConfiguracaoEmailMutationVariables,
  IUpdateConfiguracaoFinanceiraMutation,
  IUpdateConfiguracaoFinanceiraMutationVariables,
  IUpdateConfiguracaoImpressaoMutation,
  IUpdateConfiguracaoImpressaoMutationVariables,
  IUpdateConfiguracaoSmsMutation,
  IUpdateConfiguracaoSmsMutationVariables,
} from '@/typings';

export const ConfiguracaoGraphql = {
  query: {
    ConfiguracaoAgendaQuery,
    ConfiguracaoEmailQuery,
    ConfiguracaoEmailAniversariantesQuery,
    ConfiguracaoFinanceiraQuery,
    ConfiguracaoImpressaoQuery,
    ConfiguracaoSmsQuery,
  },

  async configuracaoAgenda(variables: IConfiguracaoAgendaQueryVariables) {
    return apolloClient
      .query<IConfiguracaoAgendaQuery>({
        query: ConfiguracaoAgendaQuery,
        variables,
      })
      .then(({ data }) => data?.configuracaoAgenda);
  },

  async updateConfiguracaoAgenda(
    variables: IUpdateConfiguracaoAgendaMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoAgendaMutation>({
        mutation: UpdateConfiguracaoAgendaMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoAgenda);
  },

  async configuracaoEmail(variables: IConfiguracaoEmailQueryVariables) {
    return apolloClient
      .query<IConfiguracaoEmailQuery>({
        query: ConfiguracaoEmailQuery,
        variables,
      })
      .then(({ data }) => data?.configuracaoEmail);
  },

  async updateConfiguracaoEmail(
    variables: IUpdateConfiguracaoEmailMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoEmailMutation>({
        mutation: UpdateConfiguracaoEmailMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoEmail);
  },

  async configuracaoEmailAniversariantes(
    variables: IConfiguracaoEmailAniversariantesQueryVariables,
  ) {
    return apolloClient
      .query<IConfiguracaoEmailAniversariantesQuery>({
        query: ConfiguracaoEmailAniversariantesQuery,
        variables,
      })
      .then(({ data }) => data?.configuracaoEmailAniversariantes);
  },

  async updateConfiguracaoEmailAniversariantes(
    variables: IUpdateConfiguracaoEmailAniversariantesMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoEmailAniversariantesMutation>({
        mutation: UpdateConfiguracaoEmailAniversariantesMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoEmailAniversariantes);
  },

  async configuracaoFinanceira() {
    return apolloClient
      .query<IConfiguracaoFinanceiraQuery>({
        query: ConfiguracaoFinanceiraQuery,
      })
      .then(({ data }) => data?.configuracaoFinanceira);
  },

  async updateConfiguracaoFinanceira(
    variables: IUpdateConfiguracaoFinanceiraMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoFinanceiraMutation>({
        mutation: UpdateConfiguracaoFinanceiraMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoFinanceira);
  },

  async configuracaoImpressao(variables: IConfiguracaoImpressaoQueryVariables) {
    return apolloClient
      .query<IConfiguracaoImpressaoQuery>({
        query: ConfiguracaoImpressaoQuery,
        variables,
      })
      .then(({ data }) => data?.configuracaoImpressao);
  },

  async updateConfiguracaoImpressao(
    variables: IUpdateConfiguracaoImpressaoMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoImpressaoMutation>({
        mutation: UpdateConfiguracaoImpressaoMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoImpressao);
  },

  async configuracaoSms(variables: IConfiguracaoSmsQueryVariables) {
    return apolloClient
      .query<IConfiguracaoSmsQuery>({
        query: ConfiguracaoSmsQuery,
        variables,
      })
      .then(({ data }) => data?.configuracaoSms);
  },

  async updateConfiguracaoSms(
    variables: IUpdateConfiguracaoSmsMutationVariables,
  ) {
    return apolloClient
      .mutate<IUpdateConfiguracaoSmsMutation>({
        mutation: UpdateConfiguracaoSmsMutation,
        variables,
      })
      .then(({ data }) => data?.updateConfiguracaoSms);
  },
};
