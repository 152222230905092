import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { createComponent } from '@/lib/vue';
import { AgendamentoStatus, IRequireField } from '@/typings';
import { IAgendamentoStatusInputItem } from '@/typings/items/agendamento.t';
import { computed, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: AgendamentoStatus;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: AgendamentoStatus) => void;
}

export const AgendamentoStatusSelect = createComponent<IJsxProps, IEvents>({
  name: 'AgendamentoStatusSelect',
  props: {
    value: { type: String as any, required: true },
  },
  setup(props: IProps, ctx) {
    function emitInput(value: AgendamentoStatus) {
      ctx.emit('input', value);
    }

    const { $maxWidth, $color, $isDisabled } = useComputeds(props, ctx);

    return () => (
      <div class="mx-4 mt-2" style={{ maxWidth: $maxWidth.value }}>
        <v-select
          outlined
          rounded
          itemText="label"
          itemValue="value"
          value={props.value}
          items={ConstantsHelper.agendamentoStatus}
          color={$color.value}
          hideDetails
          disabled={$isDisabled.value}
          onChange={emitInput}
          class="rounded-input"
          scopedSlots={{
            item: itemSlot,
            selection: itemSlot,
          }}
        />
      </div>
    );
  },
});

function useComputeds(props: IProps, ctx: SetupContext) {
  const $current = computed(() =>
    ConstantsHelper.agendamentoStatus.find(f => f.value === props.value),
  );

  const $color = computed(() => $current.value?.color);

  const $maxWidth = computed(() => {
    switch (props.value) {
      case AgendamentoStatus.AGENDADO:
      case AgendamentoStatus.CANCELADO:
        return '190px';

      case AgendamentoStatus.CONFIRMADO:
        return '200px';

      case AgendamentoStatus.DILATANDO_PUPILA:
      case AgendamentoStatus.NAO_COMPARECEU:
        return '230px';

      case AgendamentoStatus.PACIENTE_ATENDIDO:
        return '240px';

      case AgendamentoStatus.AGUARDANDO_ATENDIMENTO:
      default:
        return '290px';
    }
  });

  const $isDisabled = useState(s => !!s.agenda.agendamento?.atendimento?.id);

  return { $current, $color, $maxWidth, $isDisabled };
}

const itemSlot = ({ item }: { item: IAgendamentoStatusInputItem }) => (
  <div class="flex flex-auto items-center" style={{ color: item.color }}>
    <v-icon color={item.color} class="mr-2">
      {item.icon}
    </v-icon>

    <div>{item.label}</div>
  </div>
);
