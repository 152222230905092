import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IPdfDialogParams } from '@/typings';
import { PropType } from 'vue';

export const PdfDialog = createComponent({
  name: 'PdfDialog',
  props: {
    params: { type: Object as PropType<IPdfDialogParams>, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="w-full" style="height: 85vh">
        <iframe
          id="pdf-file"
          // src={`data:application/pdf;base64,${props.params.data}`}
          name={props.params.nome}
          src={props.params.data}
          // type="application/pdf"
          class="w-full h-full border-none"
        ></iframe>
      </div>
    );
  },
});

export const PdfDialogToolbarBtns = createComponent({
  name: 'PdfDialogToolbarBtns',
  setup(props, ctx) {
    const getPdfFile = () =>
      document.getElementById('pdf-file') as HTMLIFrameElement | null;

    function handleDownload() {
      const pdf = getPdfFile();

      if (pdf) {
        const fileLink = document.createElement('a');
        fileLink.href = pdf.src;
        fileLink.download = pdf.name;
        fileLink.click();
      }
    }

    function handleImprimir() {
      const pdf = getPdfFile();

      if (pdf) {
        pdf.contentWindow?.print();
      }
    }

    return () => (
      <div class="flex justify-end">
        <v-btn icon text color={MyTheme.coolGray900} onClick={handleDownload}>
          <v-icon>{MyIcons.download}</v-icon>
        </v-btn>

        <v-btn icon text color={MyTheme.coolGray900} onClick={handleImprimir}>
          <v-icon>{MyIcons.imprimir}</v-icon>
        </v-btn>
      </div>
    );
  },
});
