import { SERVER_MAX_TAKE } from '@/lib/constants/server';
import {
  ITissProcedimentosQueryVariables,
  ITussLookupsQueryVariables,
  TissTabela,
  TissProcedimentoTabela,
  TussLookupTabela,
} from '@/typings';

export const isTussLookup = (tabela: TissTabela | null) => {
  switch (tabela) {
    case TissTabela.TB_90:
    case TissTabela.TB_98:
    case TissTabela.TB_00:
      return false;

    case TissTabela.TB_18:
    case TissTabela.TB_19:
    case TissTabela.TB_20:
    case TissTabela.TB_22:
    default:
      return true;
  }
};

export const tussLookupTabela = (tabela: TissTabela | null) => {
  switch (tabela) {
    // TussLookupTabela
    case TissTabela.TB_18:
      return TussLookupTabela.TB_18;
    case TissTabela.TB_19:
      return TussLookupTabela.TB_19;
    case TissTabela.TB_20:
      return TussLookupTabela.TB_20;
    case TissTabela.TB_22:
      return TussLookupTabela.TB_22;

    default:
      return null;
  }
};

export const procedimentoTissTabela = (tabela: TissTabela | null) => {
  switch (tabela) {
    // TissProcedimentoTabela
    case TissTabela.TB_90:
      return TissProcedimentoTabela.TB_90;
    case TissTabela.TB_98:
      return TissProcedimentoTabela.TB_98;
    case TissTabela.TB_00:
      return TissProcedimentoTabela.TB_00;

    default:
      return null;
  }
};

export function getTussLookupsQueryVariables({
  tabela,
  codigo,
  searchValue,
}: {
  tabela: TissTabela | null;
  codigo: string | null | undefined;
  searchValue: string | null;
}): ITussLookupsQueryVariables {
  const lookupTabela = tussLookupTabela(tabela);

  if (codigo) {
    return {
      where: {
        codigo_contains: codigo,
        tabela: lookupTabela,
      },
    };
  }

  return {
    where: {
      OR: [
        {
          nome_contains: searchValue,
          tabela: lookupTabela,
        },
        {
          codigo_contains: searchValue,
          tabela: lookupTabela,
        },
      ],
    },
    take: SERVER_MAX_TAKE,
  };
}

export function getTissProcedimentosQueryVariables({
  tabela,
  codigo,
  searchValue,
}: {
  tabela: TissTabela | null;
  codigo: string | null | undefined;
  searchValue: string | null;
}): ITissProcedimentosQueryVariables {
  const tissTabela = procedimentoTissTabela(tabela);

  if (codigo) {
    return {
      where: {
        codigo_contains: codigo,
        tabela: tissTabela,
      },
    };
  }

  return {
    where: {
      OR: [
        {
          nome_contains: searchValue,
          tabela: tissTabela,
        },
        {
          codigo_contains: searchValue,
          tabela: tissTabela,
        },
      ],
    },
    take: SERVER_MAX_TAKE,
  };
}
