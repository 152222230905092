import { IFormSubmitted, IInputValue } from '@/typings';
import isNumber from 'lodash/isNumber';
import { DateTime } from 'luxon';
import { DateHelpers } from '../helpers/date.helpers';
import { CNPJ, unmaskNumbers } from './cnpj';
import { CPF } from './cpf';

export class Validator {
  constructor(public form: IFormSubmitted | undefined | null = null) {}

  get submitted() {
    if (this.form) return this.form.submitted;

    return true;
  }

  required(value: IInputValue) {
    if (!value && !isNumber(value) && this.submitted) {
      return 'não pode estar em branco';
    }

    return null;
  }

  email(value: IInputValue) {
    if (!value || isNumber(value) || !this.submitted) {
      return null;
    }

    const EMAIL_REGEXP =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!EMAIL_REGEXP.test(value)) {
      return 'email inválido';
    }

    return null;
  }

  confirmPassword(value: IInputValue, valueToConfirm: IInputValue) {
    if (!value || isNumber(value) || !this.submitted) {
      return null;
    } else if (valueToConfirm && value !== valueToConfirm) {
      return 'senha precisa ser a mesma';
    }

    return null;
  }

  minLength(value: IInputValue, min: number) {
    if (!value || isNumber(value) || !this.submitted) {
      return null;
    } else if (value && value.length < min) {
      return `é muito curta (mínimo ${min} caracteres)`;
    }

    return null;
  }

  gt(value: IInputValue, gtNum: number) {
    if (!this.submitted || !isNumber(value)) {
      return null;
    } else if (value <= gtNum) {
      return `deve ser maior do que ${gtNum}`;
    }

    return null;
  }

  lte(value: IInputValue, lteNum: number) {
    if (!this.submitted || !isNumber(value)) {
      return null;
    } else if (value > lteNum) {
      return `deve ser menor ou igual a ${lteNum}`;
    }

    return null;
  }

  date(value: IInputValue) {
    if (!value || !this.submitted) return null;

    if (
      isNumber(value) ||
      value.length < 10 ||
      !DateHelpers.isDateValid(value)
    ) {
      return 'data inválida';
    }

    return null;
  }

  maxDate(
    value: IInputValue,
    maxDate: string | Date | DateTime | undefined | null,
  ) {
    if (!value || isNumber(value) || !maxDate || !this.submitted) {
      return null;
    }

    const diff = DateHelpers.dateDiff(value, maxDate, 'seconds');
    if (diff && diff.seconds <= 0) {
      return `deve ser antes de ${DateHelpers.formatDate(maxDate)}`;
    }

    return null;
  }

  minDate(
    value: IInputValue,
    minDate: string | Date | DateTime | undefined | null,
  ) {
    if (!value || isNumber(value) || !minDate || !this.submitted) {
      return null;
    }

    const diff = DateHelpers.dateDiff(minDate, value, 'seconds');
    if (diff && diff.seconds <= 0) {
      return `deve ser depois de ${DateHelpers.formatDate(minDate)}`;
    }

    return null;
  }

  time(value: IInputValue) {
    if (!value || !this.submitted) return null;

    if (
      isNumber(value) ||
      value.length < 5 ||
      !DateHelpers.isHourValid(value)
    ) {
      return 'hora inválida';
    }

    return null;
  }

  maxHour(value: IInputValue, maxHour: string | null) {
    if (!value || isNumber(value) || !maxHour || !this.submitted) {
      return null;
    }

    const diff = DateHelpers.dateDiff(value, maxHour, 'seconds');
    if (diff && diff.seconds <= 0) {
      return `deve ser menor do que ${maxHour}`;
    }

    return null;
  }

  minHour(value: IInputValue, minHour: string | null) {
    if (!value || isNumber(value) || !minHour || !this.submitted) {
      return null;
    }

    const diff = DateHelpers.dateDiff(minHour, value, 'seconds');
    if (diff && diff.seconds <= 0) {
      return `deve ser maior do que ${minHour}`;
    }

    return null;
  }

  cpf(value: IInputValue) {
    if (!value || !this.submitted) {
      return null;
    }

    value = unmaskNumbers(value);

    const minLength = this.minLength(value, 11);

    if (minLength) return minLength;

    return CPF.isValid(value) ? null : 'CPF inválido';
  }

  cnpj(value: IInputValue) {
    if (!value || !this.submitted) {
      return null;
    }

    value = unmaskNumbers(value);

    const minLength = this.minLength(value, 14);

    if (minLength) return minLength;

    return CNPJ.isValid(value) ? null : 'CNPJ inválido';
  }

  cpfOrCnpj(value: IInputValue) {
    if (!value || !this.submitted) {
      return null;
    }

    value = unmaskNumbers(value);
    const isCnpj = value.length > 11;

    const minLength = this.minLength(value, isCnpj ? 14 : 11);

    if (minLength) return minLength;

    if (isCnpj) {
      return CNPJ.isValid(value) ? null : 'CNPJ inválido';
    } else {
      // isCpf
      return CPF.isValid(value) ? null : 'CPF inválido';
    }
  }
}
