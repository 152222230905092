import { routeGroup } from '@/routes/utils';

import { IRouteConfig } from '@/typings';

const ProntuarioSidebar = () =>
  import(
    /* webpackChunkName: "prontuarios" */ '@/modules/prontuarios/components/sidebar/ProntuarioSidebar'
  );
const ProntuarioPage = () =>
  import(
    /* webpackChunkName: "prontuarios" */ '@/modules/prontuarios/pages/ProntuarioPage'
  );

export const prontuariosRoutes: IRouteConfig[] = routeGroup({
  prefix: 'prontuarios',
  routes: [
    {
      path: ':id',
      components: {
        default: ProntuarioPage,
        sidebar: ProntuarioSidebar,
      },
    },
  ],
});
