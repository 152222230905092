import ContatoQuery from '@/graphql/system/contato/contato.graphql';
import ContatosQuery from '@/graphql/system/contato/contatos.graphql';
import CreateContatoMutation from '@/graphql/system/contato/createContato.graphql';
import DeleteContatoMutation from '@/graphql/system/contato/deleteContato.graphql';
import DeleteManyContatosMutation from '@/graphql/system/contato/deleteManyContatos.graphql';
import UpdateContatoMutation from '@/graphql/system/contato/updateContato.graphql';
import { getRefetchQueries } from '@/graphql/utils';
import { apolloClient } from '@/plugins/apollo';
import {
  IContatoQuery,
  IContatoQueryVariables,
  IContatosQuery,
  IContatosQueryVariables,
  ICreateContatoMutation,
  ICreateContatoMutationVariables,
  IDeleteContatoMutation,
  IDeleteContatoMutationVariables,
  IDeleteManyContatosMutation,
  IDeleteManyContatosMutationVariables,
  IUpdateContatoMutation,
  IUpdateContatoMutationVariables,
} from '@/typings';

export const ContatoGraphql = {
  query: {
    ContatoQuery,
    ContatosQuery,
  },

  async contato(variables: IContatoQueryVariables) {
    return apolloClient
      .query<IContatoQuery>({
        query: ContatoQuery,
        variables,
      })
      .then(({ data }) => data?.contato);
  },

  async contatos(variables: IContatosQueryVariables) {
    return apolloClient
      .query<IContatosQuery>({
        query: ContatosQuery,
        variables,
      })
      .then(({ data }) => data?.contatos);
  },

  async createContato(variables: ICreateContatoMutationVariables) {
    return apolloClient
      .mutate<ICreateContatoMutation>({
        mutation: CreateContatoMutation,
        variables,
      })
      .then(({ data }) => data?.createContato);
  },

  async deleteContato(variables: IDeleteContatoMutationVariables) {
    return apolloClient
      .mutate<IDeleteContatoMutation>({
        mutation: DeleteContatoMutation,
        variables,
        refetchQueries: getRefetchQueries([ContatosQuery]),
      })
      .then(({ data }) => data?.deleteContato);
  },

  async deleteManyContatos(variables: IDeleteManyContatosMutationVariables) {
    return apolloClient
      .mutate<IDeleteManyContatosMutation>({
        mutation: DeleteManyContatosMutation,
        variables,
        refetchQueries: getRefetchQueries([ContatosQuery]),
      })
      .then(({ data }) => data?.deleteManyContatos);
  },

  async updateContato(variables: IUpdateContatoMutationVariables) {
    return apolloClient
      .mutate<IUpdateContatoMutation>({
        mutation: UpdateContatoMutation,
        variables,
      })
      .then(({ data }) => data?.updateContato);
  },
};
